import * as React from 'react';
import { Dispatch, SetStateAction } from 'react';
import ButtonPrimary from '../../../components/Buttons/ButtonPrimary';
import ButtonWhite from '../../../components/Buttons/ButtonWhite';
import InputSimple from '../../../components/Inputs/InputSimple';
import Modal from '../../../components/Modals/Modal';
import formatCurrency from '../../../utils/formatCurrency';
import { ReactNode } from 'react-markdown/lib/ast-to-react';
import { Link } from 'react-router-dom';

interface Props {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  setShowOverlay: Dispatch<SetStateAction<boolean>>;
  totals: {
    totalRevenue: number;
    totalVatRecovered: number;
    totalCost: number;
    totalVatOwed: number;
    totalGrossMargin: number;
    totalVatBalance: number;
  };
  onClick: () => void;
  invoiceNumber: number;
  setInvoiceNumber: React.Dispatch<React.SetStateAction<string | null>>;
  CreateAccountsReceivableLink: ReactNode;
}

function CreateAccountsReceivableModal({
  show,
  setShow,
  setShowOverlay,
  totals,
  onClick,
  invoiceNumber,
  setInvoiceNumber,
  CreateAccountsReceivableLink,
}: Props) {
  const hide = () => {
    setShow(false);
    setShowOverlay(true);
  };

  return (
    <Modal show={show} hide={() => hide()} title="Summary" size="lg" className={null} noPadding={null}>
      <div className="border border-gray-300 rounded-md mt-4 px-2">
        <table className="border-collapse w-full px-4 mt-2">
          <thead className="">
            <tr className="">
              <th className=" self-center " />
              <th className=" text-center">Allocations</th>
              <th className=" text-center">VAT</th>
              <th className=" text-center">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr className="text-sm my-1 h-8">
              <td className="font-semibold  pl-2 self-center text-base">Revenue</td>
              <td className="text-center ">{formatCurrency(totals?.totalRevenue)}</td>
              <td className="text-center ">{formatCurrency(totals?.totalVatOwed) || '-'}</td>
              <td className="text-center  ">{formatCurrency(totals?.totalRevenue + totals?.totalVatOwed)}</td>
            </tr>
            <tr className="text-sm my-1 h-10">
              <td className="font-semibold  pl-2 self-center text-base">Cost</td>
              <td className="text-center ">{formatCurrency(totals?.totalCost)}</td>
              <td className="text-center ">{formatCurrency(totals?.totalVatRecovered) || '-'}</td>
              <td className="text-center ">{formatCurrency(totals?.totalCost + totals?.totalVatRecovered)}</td>
            </tr>
            <tr className="border-t border-gray-300 text-sm my-1 h-10">
              <td className="font-semibold  pl-2 self-center text-base">Balance</td>
              <td className="text-center  text-thaleria-blue-500 font-bold">{formatCurrency(totals?.totalGrossMargin)}</td>
              <td className="text-center  text-thaleria-blue-500 font-bold">{formatCurrency(totals?.totalVatBalance)}</td>
              <td className=" " />
            </tr>
          </tbody>
        </table>
      </div>

      <div className="mt-4 w-full flex justify-end space-x-2">
        <ButtonWhite
          text="Cancel"
          onClick={() => {
            setShow(false);
            setShowOverlay(true);
          }}
          icon={null}
          active={null}
          style={null}
          disabled={null}
          children={null}
        />
        {CreateAccountsReceivableLink}
      </div>
    </Modal>
  );
}

export default CreateAccountsReceivableModal;
