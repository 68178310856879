import { addDays, getDate, setDate, addMonths } from 'date-fns';

function getNext7thAfter21Days(currentDate) {
  // Step 1: Add 21 days to the current date
  const dateAfter21Days = addDays(currentDate, 21);

  // Step 2: Get the day of the month for the date after 21 days
  const dayOfMonth = getDate(dateAfter21Days);

  // Step 3: Calculate the next 7th of the month from that date
  let next7th;
  if (dayOfMonth < 7) {
    next7th = setDate(dateAfter21Days, 7);
  } else {
    next7th = setDate(addMonths(dateAfter21Days, 1), 7);
  }

  return next7th;
}

export default getNext7thAfter21Days;
