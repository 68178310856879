import React, { useEffect, useState } from 'react';
import AnnouncementCard from '../../../components/Cards/AnnouncementCard';
import ButtonAdd from '../../../components/Buttons/ButtonAdd';
import { useHistory } from 'react-router-dom';
import { ACCOUNT_TYPE } from '../../../helpers/enum/accounts';
import { useSelector } from 'react-redux';
import SimpleAlert from '../../../components/Modals/SimpleAlert';
import { NewspaperIcon, PlusIcon } from '@heroicons/react/outline';
import ButtonSecondary from '../../../components/Buttons/ButtonSecondary';
import ButtonPrimary from '../../../components/Buttons/ButtonPrimary';
import { ArrowDownIcon } from '@heroicons/react/outline';
import useApi from '../../../hooks/useApi';
import Loader from '../../../components/Loading/Loader';

const CompanyAnnouncement = () => {
  const history = useHistory();

  const {
    announcements: { deleteAnnouncement, getAllAnnouncement },
  } = useApi();

  const [show, setShow] = useState({
    status: false,
    announcementId: null,
  });

  const [announcementData, setAnnouncementData] = useState({ allAnnouncement: [], totalPages: null });
  const [scrollSetting, setScrollSetting] = useState({ hasMore: true, page: 1 });
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const currentUser = useSelector(state => state && state.auth.currentUser);
  const isAdmin = ACCOUNT_TYPE.ROOT.id == currentUser.account_type_id || ACCOUNT_TYPE.ADMIN.id == currentUser.account_type_id;

  const onReadMeHandler = id => history.push(`/announcement/${id}`);

  const onCreateAnnouncementHandler = () => history.push('/announcement/create');

  const onAnnouncementHandler = (id, event) => event.target.tagName === 'DIV' && history.push(`/announcement/${id}`);

  const onDeleteAnnouncementHandler = id => setShow({ status: true, announcementId: id });

  const fetchAnnouncements = page => {
    if (!isLoading) setIsLoading(true);
    getAllAnnouncement(page)
      .then(res => {
        setIsLoading(false);
        if (res.totalPages === 1 || res.totalPages === 0) setScrollSetting(pre => ({ ...pre, hasMore: false }));
        setAnnouncementData({
          totalPages: res.totalPages,
          allAnnouncement: res.rows,
        });
      })
      .catch(e => setIsLoading(false));
  };

  const onDeleteAlertHandler = () => {
    deleteAnnouncement(show.announcementId).then(() => {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      setAnnouncementData(pre => ({ ...pre, allAnnouncement: [] }));
      setScrollSetting({ hasMore: true, page: 1 });
      fetchAnnouncements(1);

      setShow({ status: false });
    });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    fetchAnnouncements(1);
  }, []);

  const onEditHandler = id => history.push(`/announcement/${id}/edit`);

  const fetchMore = () => {
    setIsLoadingMore(true);
    fetchData();
  };

  const fetchData = _ => {
    const page = scrollSetting.page;

    getAllAnnouncement(page + 1).then(res => {
      if (page + 1 >= announcementData.totalPages) {
        setScrollSetting(pre => ({ ...pre, hasMore: false }));
      } else {
        setScrollSetting(pre => ({ ...pre, hasMore: true }));
      }
      setAnnouncementData(pre => ({
        totalPages: res.totalPages,
        allAnnouncement: pre.allAnnouncement.concat(res.rows),
      }));
      setScrollSetting(pre => ({ ...pre, page: page + 1 }));
      setIsLoadingMore(false);
    });
  };

  const announcement = announcementData.allAnnouncement.map(announcement => {
    return (
      <AnnouncementCard
        key={announcement.id}
        onDeleteClick={onDeleteAnnouncementHandler.bind(this, announcement.id)}
        onEditClick={onEditHandler.bind(this, announcement.id)}
        showDeleteButton={isAdmin}
        showEditButton={isAdmin}
        onClick={onAnnouncementHandler.bind(this, announcement.id)}
        readMeOnClick={onReadMeHandler.bind(this, announcement.id)}
        title={announcement.title}
        text={announcement.body}
        creatorImage={announcement.created_by?.profilepic_url}
        preferredName={announcement.created_by?.preferred_name}
        creatorFirstName={announcement.created_by?.first_names}
        creatorLastName={announcement.created_by?.last_names}
        date={announcement.created_at}
        thumbnail={announcement.thumbnail}
      />
    );
  });

  return (
    <div className="w-full h-full">
      <div className="bg-gray-100 flex flex-row justify-between items-center p-1">
        <h2 className="font-semibold text-base lg:text-xl">Announcements</h2>
        {isAdmin && <ButtonAdd text="Add" onClick={onCreateAnnouncementHandler} />}
      </div>
      <Loader isLoading={isLoading}>
        {announcementData.allAnnouncement.length ? (
          <div className="overflow-y-auto h-max space-y-4" id="scrollableDiv">
            {announcement}
            <Loader isLoading={isLoadingMore} />
            <div className="flex justify-start w-full">
              {scrollSetting.hasMore ? (
                <div className="w-full flex items-start mt-4">
                  <ButtonPrimary
                    text="View more"
                    style="ml-1"
                    icon={<ArrowDownIcon className="h-4 w-4 mr-3 p-auto" />}
                    onClick={fetchMore}
                  />
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        ) : (
          <div className="mt-16 text-center">
            <NewspaperIcon className="mx-auto h-12 w-12 text-gray-400" />
            <h3 className="mt-2 text-sm font-medium text-gray-900">No announcements</h3>
            {isAdmin && (
              <>
                <p className="mt-1 text-sm text-gray-500">Get started by creating a new announcement.</p>
                <div className="mt-6 mb-4">
                  <ButtonSecondary size="xs" text={<PlusIcon className="h-5 w-5" />} onClick={onCreateAnnouncementHandler} />
                </div>
              </>
            )}
          </div>
        )}
      </Loader>
      <SimpleAlert
        errorTitle="Delete Announcement"
        errorMsg="Are you sure you want to Delete announcement ?"
        onAcceptText="Delete"
        onDeclineText="Cancel"
        onAcceptClick={onDeleteAlertHandler}
        show={show.status}
        hide={() => setShow(prev => ({ ...prev, status: false }))}
      />
    </div>
  );
};

export default CompanyAnnouncement;
