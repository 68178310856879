import React, { useState, useMemo, useEffect } from 'react';
import ReactSelect from '../../components/Inputs/ReactSelect';
import ButtonPrimary from '../../components/Buttons/ButtonPrimary';

const EditTimesheetTags = ({ file, activeStaffOrders, onApply, currentSelection }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (currentSelection) {
      setSelectedOption(currentSelection);
    }
  }, [currentSelection]);

  const handleChange = selectedOption => {
    setSelectedOption(selectedOption);
    onApply(file.resource, { staffOrderId: selectedOption.value, orderRef: selectedOption.label });
  };

  const poOptions = useMemo(
    () =>
      activeStaffOrders.map(so => ({
        value: so.id,
        label: so.order_ref,
      })),
    [activeStaffOrders],
  );

  return (
    <div className="mt-6 flex justify-between items-center w-full px-1">
      <div className="flex space-x-4 items-center">
        <h3 className="text-lg font-medium leading-6 text-gray-900">Staff order tag</h3>
        <div className="w-60">
          <ReactSelect
            value={selectedOption}
            onChange={handleChange}
            options={poOptions}
            placeholder="Select a staff order tag..."
            selectedOption={[selectedOption]}
            menuPlacement="auto"
          />
        </div>
      </div>
    </div>
  );
};

export default EditTimesheetTags;
