import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ButtonPrimary from '../../../components/Buttons/ButtonPrimary';
import ButtonWhite from '../../../components/Buttons/ButtonWhite';
import ReactSelect from '../../../components/Inputs/ReactSelect';
import Modal from '../../../components/Modals/Modal';
import { AccountType } from '../../../helpers/enum/accounts';
import useApi from '../../../hooks/useApi';
import Checkbox from '../../../components/Checkbox/Checkbox';
import Error from '../../../components/Error/Error';
const AddViewerReminder = ({
  show,
  title,
  acceptedAccountTypes,
  AccountTypesPlaceholder,
  onAccountTypesChange,
  acceptedContractTypes,
  contractTypePlaceholder,
  onContractTypesChange,
  onCountriesChange,
  countriesDefaultId,
  countriesPlaceholder,
  onCancelClick,
  onSuccessClick,
  successButtonName,
  isAdmin,
}) => {
  const [allCountries, setAllCountries] = useState([]);
  const [allContractTypes, setAllContractTypes] = useState([]);

  const [allUserTypes, setAllUserTypes] = useState([]);
  const {
    userTypes: { getAllUserTypes },
    contractTypes: { getAllContractTypes },
    countries: { getCountriesUsedInSC },
  } = useApi();

  const countries = allCountries.map(country => ({ value: country.id, label: country.name }));
  const contractTypes = allContractTypes.map(contractType => ({ value: contractType.id, label: contractType.contract_type }));
  let userTypes = [];
  allUserTypes.map(userType => {
    if (userType.id == AccountType.USER || userType.id == AccountType.STAFF || userType.id == AccountType.ADMIN) {
      userTypes.push({ value: userType.id, label: userType.account_type });
    }
  });

  useEffect(() => {
    getCountriesUsedInSC().then(res => {
      setAllCountries(res);
    });
    getAllContractTypes().then(res => setAllContractTypes(res));
    getAllUserTypes().then(res => setAllUserTypes(res));
  }, []);

  const contractTypeError = !acceptedContractTypes?.length;

  return (
    <>
      <h2 className="pb-8">{title} Reminder</h2>

      <h4 className="text-md-center pb-4">Who can view this reminder?</h4>
      <div className="mb-4">
        <label className={`block text-sm font-medium text-gray-700 mr-2 mb-2`}>Account types</label>
        <div className="flex justify-start items-center px-3">
          <Checkbox title={'Admins'} value={true} disabled={true} horizontal />
          <Checkbox
            title={'Staff'}
            value={acceptedAccountTypes.includes(AccountType.STAFF) || acceptedAccountTypes.length === 0}
            id={'staff'}
            onChange={e => onAccountTypesChange(e)}
            horizontal
          />
          <Checkbox
            title={'User'}
            horizontal
            id={'user'}
            value={acceptedAccountTypes.includes(AccountType.USER) || !acceptedAccountTypes.length}
            onChange={e => onAccountTypesChange(e)}
          />
        </div>
      </div>
      <div className="mb-4">
        <div className="flex space-x-2 mr-2 mb-2">
          <label className={`block text-sm font-medium text-gray-700 `}>Contract types</label>
          {contractTypeError && <Error message="Must have at least one option selected" />}
        </div>
        <div className="flex justify-start items-center px-3">
          {contractTypes.map(type => {
            return (
              <Checkbox
                title={type.label}
                horizontal
                id={type.label.toLowerCase()}
                value={acceptedContractTypes.includes(type.value)}
                onChange={e => onContractTypesChange(e)}
              />
            );
          })}
        </div>
      </div>
      <div className="mb-4">
        <ReactSelect
          selectedOptionsIds={countriesDefaultId}
          options={countries}
          isMulti={true}
          label="Countries"
          placeholder={countriesPlaceholder}
          onChange={onCountriesChange}
        />
      </div>

      <div className="pt-7 flex justify-end">
        <ButtonWhite text="Cancel" onClick={onCancelClick} />

        <ButtonPrimary text={successButtonName} style="ml-2" onClick={onSuccessClick} />
      </div>
    </>
  );
};

AddViewerReminder.prototype = {
  show: PropTypes.bool,
  onSuccessClick: PropTypes.func,
  onCancelClick: PropTypes.func,
  onCountriesChange: PropTypes.func,
  countriesDefaultId: PropTypes.array,
  onContractTypesChange: PropTypes.func,
  acceptedContractTypes: PropTypes.array,
  onAccountTypesChange: PropTypes.func,
  acceptedAccountTypes: PropTypes.array,
  countriesPlaceholder: PropTypes.string,
  contractTypePlaceholder: PropTypes.string,
  AccountTypesPlaceholder: PropTypes.string,
  title: PropTypes.string,
  successButtonName: PropTypes.string,
};

export default AddViewerReminder;
