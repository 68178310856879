import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import AddFolder from '../../../../containers/Documents/AddFolder';
import AdminDocumentHeader from '../../../../containers/Documents/AdminDocumentHeader';
import MultiDocuments from '../../../../containers/Documents/MultiDocuments';
import { downloadBase64File } from '../../../../helpers';
import { DocumentType } from '../../../../helpers/enum/documents';
import useApi from '../../../../hooks/useApi';
import HrManager from '../HrManager';
import Loader from '../../../../components/Loading/Loader';
import moment from 'moment';
import UploadFileModal from '../../../../containers/Documents/UploadFileModal';
import EditAccess from '../../../../containers/Documents/EditAccess';
import FileSideBar from '../../../../containers/Documents/FileSideBar';
import SimpleAlert from '../../../../components/Modals/SimpleAlert';
import PreviewBox from '../../../../containers/PDFViewer/PreviewBox';
import checkFileName from '../../../../utils/checkFileName.ts';
import AdminSidebar from '../../AdminSidebar';
import format from 'date-fns/format';
// import PreviewBox from '../../../../containers/PDFViewer/PreviewBox';

const items = [
  { value: 0, label: 'Organisation documents', type: DocumentType.THALERIA },
  { value: 1, label: 'Personal documents', type: DocumentType.USER },
];

const DocumentManager = () => {
  const currentUser = useSelector(state => state.auth.currentUser);
  const {
    files: {
      createFolder,
      deleteObject,
      documentsFilesOnFolder,
      downloadUserDocumentFiles,
      uploadDocumentFile,
      uploadMultipleDocumentFiles,
      userDocumentsFilesOnFolder,
      editDocumentAccess,
      editDocumentName,
      downloadDocumentsFiles,
    },
    users: { getAllUsers },
  } = useApi();
  const [filesInfo, setFilesInfo] = useState({ path: '/', files: [] });
  const [newFolderName, setNewFolderName] = useState('');
  const [openAddFolder, _setOpenAddFolder] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isLoadingUsers, setIsLoadingUsers] = useState(true);
  const [item, setItem] = useState(items[0]);
  const [selectedUser, setSelectedUser] = useState();
  const [pages, setPages] = useState([
    { name: 'HR Manager', href: '/admin-panel/hr/user-directory', current: false },
    { name: 'Manage Documents', href: '/admin-panel/hr/documents', current: false },
  ]);
  const [users, setUsers] = useState([]);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [parentFolder, setParentFolder] = useState();
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [listDisplay, setListDisplay] = useState(true);
  const [editNameInput, setEditNameInput] = useState(false);
  const [loadingDocs, setLoadingDocs] = useState(false);
  const [showDuplicateAlert, setShowDuplicateAlert] = useState(false);
  const [showInvalidNamesAlert, setShowInvalidNamesAlert] = useState(false);
  const [preparingPreview, setPreparingPreview] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const initialUrl = '/admin-panel/hr/documents';

  const history = useHistory();
  const location = useLocation();

  const setOpenAddFolder = () => _setOpenAddFolder(!openAddFolder);
  const checkIsRootDirectory = filesInfo.path !== '/';

  useEffect(() => {
    getAllUsers().then(data => {
      let array = [];
      data.forEach(user => {
        array.push({ id: user.id, name: user.full_name, ...user });
      });
      setUsers(array);
    });
  }, []);

  const changeUserDirectory = (userId, redirectTo) => {
    setLoading(true);
    setSelectedFile();
    setFilesInfo({ path: redirectTo, files: [] });
    userDocumentsFilesOnFolder(userId, redirectTo).then(res => {
      setParentFolder(res.data.find(file => file.key === redirectTo));
      const files = res.data.filter(file => file.key !== redirectTo);
      const filesInfo = files.map(file => {
        return prepareObjectInfo(file.key, file.name, file?.size, userId, null, file.uploadedAt, file.uploadedBy);
      });

      setFilesInfo({ path: redirectTo, files: filesInfo });
      setLoading(false);
    });
  };

  useEffect(() => {
    if (users.length) {
      setIsLoadingUsers(false);
    }
  }, [users]);

  const changeDirectory = (type, redirectTo) => {
    setLoading(true);
    setSelectedFile();
    setFilesInfo({ path: redirectTo, files: [] });
    documentsFilesOnFolder(type, redirectTo).then(res => {
      setParentFolder(res.data.find(file => file.key === redirectTo));
      const files = res.data.filter(file => file.key !== redirectTo);
      const filesInfo = files
        .map(file => {
          const isFolder = file.key.slice(-1) === '/';
          let preparedFileOrFolder = {
            path: file.key,
            name: file.name,
            type: isFolder ? 'folder' : 'file',
            capacity: isFolder ? null : file.size,
            onDoubleClick: isFolder
              ? () => {
                  history.push(`${initialUrl}/organisation${file.key}`);
                }
              : console.log.bind(this, 'Cannot double click on files'),
            onDownloadClick: isFolder
              ? console.log.bind(this, `Cannot download folder`)
              : downloadThaleriaDocument.bind(this, file.key),
            onDeleteClick: () => removeObject(file.key),
          };
          if (file?.uploadedAt) preparedFileOrFolder.uploadedAt = file.uploadedAt;
          if (file?.uploadedBy) preparedFileOrFolder.uploadedBy = file.uploadedBy;
          if (file?.restrictions) preparedFileOrFolder.restrictions = file.restrictions;

          return preparedFileOrFolder;
        })
        .filter(file => file.key != redirectTo);

      setFilesInfo({ path: redirectTo, files: filesInfo });
      setLoading(false);
    });
  };

  const editAccessHandler = data => {
    setLoadingDocs({ editAccess: data.path });
    editDocumentAccess(data).then(res => {
      setSelectedFile(prev => ({
        ...prev,
        restrictions: data.restrictions,
      }));
      const newFiles = filesInfo.files.map(file => {
        if (file.path == data.path) {
          file.restrictions = data.restrictions;
        }
        return file;
      });
      setFilesInfo(prev => ({
        path: prev.path,
        files: newFiles,
      }));
      setShowEditModal(false);
      setLoadingDocs(false);
    });
  };

  const changeItem = item => {
    history.push(`${item.value == 0 ? `${initialUrl}/organisation` : `${initialUrl}/personal`}`);
    setSelectedUser();
  };

  const changeUser = item => {
    history.push(`${initialUrl}/personal/${item.value}`);
  };

  const CreateNewFolder = restrictions => {
    const _selectedUserId = selectedUser?.id;
    const folderName = newFolderName;
    const newFolderPath = filesInfo.path + folderName + '/';
    if (filesInfo.files.some(file => file.path == newFolderPath)) {
      setOpenAddFolder();
      return setShowDuplicateAlert(true);
    }
    setLoadingDocs('createFolder');
    createFolder(item.type, newFolderPath, _selectedUserId, restrictions)
      .then(res => {
        setFilesInfo(previous => ({
          path: previous.path,
          files: [
            ...previous.files,
            prepareObjectInfo(
              newFolderPath,
              folderName,
              null,
              _selectedUserId,
              restrictions,
              format(new Date(), 'yyyy-MM-dd'),
              currentUser.id,
            ),
          ],
        }));
        setOpenAddFolder();
        setLoadingDocs(false);
      })
      .catch(err => {
        setLoadingDocs(false);
        throw err;
      });
  };

  const onFolderNameChange = e => {
    const text = e.target.value;
    setNewFolderName(text);
  };

  const fileUploadHandler = async allFiles => {
    const duplicateNames = [];
    const invalidNames = [];
    allFiles.forEach(newFile => {
      if (filesInfo.files.some(file => file.name == newFile.name)) {
        duplicateNames.push(newFile.name);
      }
      if (!checkFileName(newFile.name)) invalidNames.push(newFile.name);
    });
    if (duplicateNames.length) {
      setShowUploadModal(false);
      return setShowDuplicateAlert(true);
    }
    if (invalidNames.length) {
      setShowUploadModal(false);
      return setShowInvalidNamesAlert(true);
    }

    if (allFiles.some(file => file.size > 50000000)) {
      alert(`Maximum file size is 50mb`);
      return null;
    }

    setLoadingDocs('uploadFile');
    let files = allFiles;
    let restrictions = null;
    if (files[0].acceptedCountries || files[0].acceptedAccountTypes || files[0].acceptedContractTypes) {
      restrictions = {
        acceptedAccountTypes: files[0]?.acceptedAccountTypes,
        acceptedContractTypes: files[0]?.acceptedContractTypes,
      };
      if (files[0]?.acceptedCountries) restrictions.acceptedCountries = files[0].acceptedCountries;
    }

    await uploadMultipleDocumentFiles(
      files,
      item.type,
      filesInfo.path,
      selectedUser ? selectedUser.id : '',
      restrictions && restrictions,
    )
      .then(res => {
        for (let r of res) {
          const { key, name } = r;
          setFilesInfo(previous => ({
            path: previous.path,
            files: [
              ...previous.files,
              prepareObjectInfo(
                key,
                name,
                files.find(f => f.name === name)?.size,
                selectedUser ? selectedUser.id : '',
                restrictions && restrictions,
                r?.uploadedAt || new Date(),
                r?.uploadedBy,
              ),
            ],
          }));
        }
        setShowUploadModal(false);
        setLoadingDocs(false);
      })
      .catch(err => {
        setLoadingDocs(false);
        throw err;
      });
  };
  const prepareObjectInfo = (path, name, size, userId, restrictions, uploadedAt, uploadedBy) => {
    const isFolder = path.slice(-1) === '/';
    let fileData = {
      path: path,
      name: name,
      type: isFolder ? 'folder' : 'file',
      capacity: isFolder ? null : size,
      onDoubleClick: isFolder
        ? item?.value == 1
          ? () => {
              history.push(`${initialUrl}/personal/${userId}${path}`);
            }
          : () => {
              history.push(`${initialUrl}/organisation${path}`);
            }
        : console.log.bind(this, `Can not double click on files`),
      deleteAccess: true,
      onDeleteClick: item?.value == 1 ? () => removeObject(path, userId) : () => removeObject(path),
      onDownloadClick: isFolder ? console.log.bind(this, `Can not download folder`) : downloadThaleriaDocument.bind(this, path),
    };
    if (restrictions) {
      fileData.restrictions = restrictions;
    }
    if (uploadedAt) fileData.uploadedAt = uploadedAt;
    if (uploadedBy) fileData.uploadedBy = uploadedBy;
    return fileData;
  };

  //Code below deselects files if they were deleted
  useEffect(() => {
    if (selectedFile && !filesInfo.files.find(f => f.path === selectedFile.path)) setSelectedFile();
  }, [filesInfo]);

  const removeObject = (path, userId) => {
    setLoadingDocs({ deleteObj: path });
    return new Promise((resolve, reject) => {
      deleteObject(item.type, path, userId)
        .then(res => {
          setFilesInfo(previous => ({
            path: previous.path,
            files: previous.files.filter(item => item.path !== path),
          }));
          setLoadingDocs(false);
          resolve();
        })
        .catch(err => {
          setLoadingDocs(false);
          throw err;
        });
    });
  };

  const downloadThaleriaDocument = filePath => {
    setLoadingDocs({ downloadFile: filePath });
    const fileName = filePath.split('/')[filePath.split('/').length - 1];
    if (item.type == 'thaleria-document') {
      downloadDocumentsFiles(item.type, filePath)
        .then(response => {
          downloadBase64File(response.data, fileName);
          setLoadingDocs(false);
        })
        .catch(err => {
          setLoadingDocs(false);
          throw err;
        });
    }
    if (item.type == 'user-document') {
      downloadUserDocumentFiles(selectedUser.id, filePath)
        .then(res => {
          downloadBase64File(res.data, fileName);
          setLoadingDocs(false);
        })
        .catch(err => {
          setLoadingDocs(false);
          throw err;
        });
    }
  };

  const previewHandler = filePath => {
    if (item.type == 'thaleria-document') {
      downloadDocumentsFiles(item.type, filePath)
        .then(response => {
          setPreparingPreview(false);
          setShowPreview({
            url: response.data,
            show: true,
            fileType: filePath.split('.')[filePath.split('.').length - 1],
            title: filePath.split('.')[filePath.split('.').length - 2].replace('/', ''),
          });
        })
        .catch(() => {
          setPreparingPreview(false);
        });
    }
    if (item.type == 'user-document') {
      downloadUserDocumentFiles(selectedUser.id, filePath)
        .then(response => {
          setPreparingPreview(false);
          setShowPreview({
            url: response.data,
            show: true,
            fileType: filePath.split('.')[filePath.split('.').length - 1],
            title: filePath.split('.')[filePath.split('.').length - 2].replace('/', ''),
          });
        })
        .catch(() => {
          setPreparingPreview(false);
        });
    }
  };

  useEffect(() => {
    if (location.pathname.split('/').length < 5) history.push(`${initialUrl}/organisation`);
  }, []);

  useEffect(() => {
    const pathNameArray = location.pathname.split('/');
    const typeOfFile = pathNameArray[4];
    if (typeOfFile != 'organisation' && typeOfFile != 'personal') {
      return history.push(`${initialUrl}/organisation`);
    }
    let pages = [
      { name: 'HR Manager', href: '/admin-panel/hr/user-directory', current: false },
      { name: 'Manage Documents', href: '/admin-panel/hr/documents', current: false },
    ];

    //Manage breadcrumbs

    let breadcrumbArray = [];
    pathNameArray.forEach((path, index) => {
      const isTypeOfFile = index == 4;
      const elementNotInBreadcrumb = index < 4 || path == '';
      breadcrumbArray.push(path);
      if (elementNotInBreadcrumb) return;
      if (typeOfFile == 'personal' && index == 5 && users.length) {
        // return;
        //Breadcrumbs if it's the users id
        const userId = path;
        const selectedUserName = users ? users.find(user => user.id == userId).name : userId;
        pages.push({
          name: selectedUserName,
          href: breadcrumbArray.join('/') + '/',
          current: false,
        });
      } else if (isTypeOfFile) {
        //Breadcrumb for type of file
        pages.push({
          name: path == 'personal' ? 'Personal documents' : 'Organisation documents',
          href: breadcrumbArray.join('/'),
          current: false,
        });
      } else {
        //Breadcrumbs for any other case
        pages.push({
          name: path,
          href: breadcrumbArray.join('/') + '/',
          current: false,
        });
      }
    });

    //Manage fetch of organisation docs
    if (typeOfFile == 'organisation') {
      const hasFolderPath = pathNameArray.length > 6;
      const path = [];
      if (hasFolderPath) {
        //Extract part of the url that is the path to the folder
        for (let i = 5; i < pathNameArray.length; i++) {
          if (!pathNameArray[i] == '') path.push(pathNameArray[i]);
        }
        const redirectPath = `/${path.join('/')}/`;
        changeDirectory(DocumentType.THALERIA, redirectPath);
      } else {
        changeDirectory(DocumentType.THALERIA, '/');
      }
    }

    //Manage fetch of personal docs and sets user if possible
    if (typeOfFile == 'personal') {
      const hasSelectedUser = pathNameArray.length > 5;
      const userId = pathNameArray[5];
      const firstFolder = pathNameArray[6];
      const hasFolderPath = pathNameArray.length > 6 && pathNameArray[6] != '';
      const path = [];
      if (hasFolderPath) {
        //Extract part of the url that is the path to the folder
        for (let i = 6; i < pathNameArray.length; i++) {
          if (!pathNameArray[i] == '') path.push(pathNameArray[i]);
        }
        const redirectPath = `/${path.join('/')}/`;
        users.length && setSelectedUser(users.find(user => user.id == userId));
        changeUserDirectory(userId, redirectPath);
      } else if (hasSelectedUser) {
        if (users.length && users.some(user => user.id == userId)) {
          setSelectedUser(users.find(user => user.id == userId));
          changeUserDirectory(userId, '/');
        } else {
          setSelectedUser();
          history.push(`${initialUrl}/personal`);
        }
      } else {
        setSelectedUser();
        setLoading(false);
        setFilesInfo({ path: '/', files: [] });
      }
    }

    //Set current = true on the last breadcrumb created
    pages[pages.length - 1].current = true;
    setPages(pages);
    //Sets item state to personal if /personal in URL, any other case sets to organisation
    typeOfFile == 'personal' ? setItem(items[1]) : setItem(items[0]);
  }, [location.pathname, isLoadingUsers]);

  const editNameOnClick = data => {
    //FIXME: Currently required because modal is not properly catching all issues. Validation is done on backend bad request response
    // if (!checkFileName(data.newName)) return setShowInvalidNamesAlert(true);
    setLoadingDocs({ editName: data.path });
    const pathNameArray = location.pathname.split('/');
    const userId = pathNameArray[5];
    let prefix = item.type == 'user-document' ? `documents/users/${userId}` : 'documents/thaleria';

    let newNameData = {
      path: prefix + data.path,
      newPath: prefix + data.newPath,
    };
    editDocumentName(newNameData)
      .then(res => {
        setSelectedFile(prev => ({
          ...prev,
          name: data.newName,
          path: data.newPath,
        }));
        const updatedFiles = filesInfo.files.map(file => {
          if (file.path == data.path) {
            file.path = data.newPath;
            file.name = data.newName;
            return file;
          } else {
            return file;
          }
        });
        setFilesInfo(prev => ({
          ...prev,
          filesInfo: updatedFiles,
        }));
        setLoadingDocs(false);
      })
      .catch(err => {
        setLoadingDocs(false);
        throw err;
      });
  };

  return (
    <AdminSidebar pages={pages} noPadding>
      <SimpleAlert
        errorTitle={'Unable to create folder/upload file'}
        errorMsg="Folder/file name already exists"
        onAcceptClick={() => setShowDuplicateAlert(false)}
        onAcceptText={'Close'}
        show={showDuplicateAlert}
        hide={() => setShowDuplicateAlert(false)}
      />
      <SimpleAlert
        errorTitle={'Unable to create upload file(s)'}
        errorMsg="File name contains special characters that are not accepted. Please remove all special characters before uploading."
        onAcceptClick={() => setShowInvalidNamesAlert(false)}
        onAcceptText={'Close'}
        show={showInvalidNamesAlert}
        hide={() => setShowInvalidNamesAlert(false)}
      />
      <PreviewBox
        filePath={showPreview.url}
        fileType={showPreview.fileType}
        showPreview={showPreview.show}
        handleHide={() => setShowPreview(false)}
        title={showPreview.title}
      />
      <div className="flex">
        <div className="grow">
          <AdminDocumentHeader
            path={item?.name + filesInfo.path}
            items={items}
            item={item}
            onListChange={changeItem}
            onAddFolderClick={setOpenAddFolder}
            showArrowLeftIcon={checkIsRootDirectory}
            users={users}
            selectedUser={selectedUser}
            onUserChange={changeUser}
            setShowUploadModal={setShowUploadModal}
            setListDisplay={setListDisplay}
            listDisplay={listDisplay}
            loading={loading}
          />
          <Loader isLoading={loading}>
            <MultiDocuments
              files={filesInfo.files}
              deleteAccess={true}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              listDisplay={listDisplay}
              small={true}
              forAdmins
              loading={loadingDocs}
              downloadFile={downloadThaleriaDocument}
              preparingPreview={preparingPreview}
              setPreparingPreview={setPreparingPreview}
              previewHandler={previewHandler}
            />
          </Loader>
          <AddFolder
            onFolderNameChange={onFolderNameChange}
            folderNameValue={newFolderName}
            setFolderNameValue={setNewFolderName}
            onCreateClick={CreateNewFolder}
            open={openAddFolder}
            hide={() => setOpenAddFolder()}
            isOrgDocument={item.type == 'thaleria-document'}
            parentFolder={parentFolder}
            loading={loadingDocs}
          />
          <UploadFileModal
            show={showUploadModal}
            title="Upload file(s)"
            hide={() => setShowUploadModal(false)}
            onUploadClick={() => fileUploadHandler(filesToUpload)}
            onCancelText="Cancel"
            selectedFiles={filesToUpload}
            setSelectedFiles={setFilesToUpload}
            isOrgDocument={item.type == 'thaleria-document'}
            parentFolder={parentFolder}
            currentUser={currentUser}
            loading={loadingDocs}
          />
          {showEditModal && (
            <EditAccess
              show={showEditModal}
              title="Edit access"
              hide={() => setShowEditModal(false)}
              onEditClick={editAccessHandler}
              onCancelText="Cancel"
              isOrgDocument={item.type == 'thaleria-document'}
              parentFolder={parentFolder}
              currentUser={currentUser}
              selectedFile={selectedFile}
              loading={loadingDocs}
            />
          )}
        </div>
        <FileSideBar
          file={selectedFile}
          downloadFile={downloadThaleriaDocument}
          users={users}
          setShowEditModal={setShowEditModal}
          editDocumentName={editNameOnClick}
          editNameInput={editNameInput}
          setEditNameInput={setEditNameInput}
          loading={loadingDocs}
          isOrgDocument={item.type == 'thaleria-document'}
        />
      </div>
    </AdminSidebar>
  );
};

export default DocumentManager;
