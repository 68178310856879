import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import useApi from '../../../../hooks/useApi';
import PageWrapper from '../../../../containers/App/PageWrapper';
import AddOrEditBankAccount from '../../../../containers/AdminManager/AddOrEditBankAccount';
import { capitalizeFirstLetter } from '../../../../utils/capitalizeFirstLetter';
export default function AddBankAccounts() {
  const history = useHistory();
  const { id } = useParams();

  const {
    bankAccounts: { getBankAccountById, createBankAccount, editBankAccount },
  } = useApi();

  const [bankAccount, setBankAccount] = useState();

  useEffect(() => {
    if (id && id !== 'create') {
      getBankAccountById(id).then(res => {
        setBankAccount(res);
      });
    } else if (id === 'create') {
      setBankAccount({
        name: null,
        iban: null,
        bic_swift: null,
      });
    }
  }, [id]);

  const handleClick = form => {
    if (id === 'create') {
      createBankAccount(form).then(data => {
        data ? history.push('/admin-panel/admin/bank-accounts') : alert('Error while saving the details');
      });
    } else {
      editBankAccount(id, form).then(res => {
        res ? history.push(`/admin-panel/admin/bank-accounts`) : alert('Error while saving the details.');
      });
    }
  };

  const pages = [
    { name: 'Contracts Manager', href: '/admin-panel/contracts/purchase-orders', current: false },
    { name: 'Bank Accounts', href: '/admin-panel/admin/bank-accounts', current: false },
    { name: capitalizeFirstLetter(id), href: '/admin-panel/admin/bank-accounts', current: false },
    { name: id === 'create' ? undefined : 'Edit', href: '', current: true },
  ];

  return (
    <PageWrapper pages={pages}>
      <AddOrEditBankAccount id={id} bankAccount={bankAccount} handleSave={handleClick} />
    </PageWrapper>
  );
}
