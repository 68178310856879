import React, { useState, useEffect } from 'react';
import ButtonPrimary from '../../../components/Buttons/ButtonPrimary';
import InputSimple from '../../../components/Inputs/InputSimple';
import TextArea from '../../../components/Inputs/TextArea';
import TwoColumnForm from '../../../components/Layouts/TwoColumnForm';
import PageWrapper from '../../../containers/App/PageWrapper';
import Attachments from '../../../containers/Attachments/Attachments';
import useApi from '../../../hooks/useApi';
import { useDispatch } from 'react-redux';
import { showSuccessNotification } from '../../../store/app/actions';

function BugReportPage() {
  const dispatch = useDispatch();

  const [description, setDescription] = useState('');
  const [subject, setSubject] = useState('');
  const [documents, setDocuments] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const {
    bugReport: { sendBugReport },
  } = useApi();

  const uploadFileHandler = fileInfo => {
    if (!fileInfo) return;

    if (fileInfo.size > 1000000) {
      alert(`Maximum file size is 1mb`);
      setUploading(false);
      return null;
    }

    if (documents.length + 1 > 3) {
      alert('Maximum 3 files are allowed');
      setUploading(false);
      return null;
    }

    if (!documents.find(d => d.resource === fileInfo.name))
      setDocuments(preValue => [
        ...preValue,
        {
          id: new Date().getTime(),
          resource: fileInfo.name,
          rawFile: fileInfo,
        },
      ]);
    setUploading(false);
  };

  const RemoveFileHandler = id => {
    setDocuments(pre => [...pre.filter(document => document.id != id)]);
  };

  const checkErrors = (fieldsToCheck, array) => {
    if (fieldsToCheck?.includes('description') || !fieldsToCheck) {
      if (!description) {
        setErrors(prev => ({ ...prev, description: 'This field is required' }));
        if (array !== undefined) array.push('description');
      } else {
        removeKeyFromErrors('description');
      }
    }
    if (fieldsToCheck?.includes('subject') || !fieldsToCheck) {
      if (!subject) {
        setErrors(prev => ({ ...prev, subject: 'This field is required' }));
        if (array !== undefined) array.push('subject');
      } else {
        removeKeyFromErrors('subject');
      }
    }
    if (array !== undefined) return array;
  };

  const removeKeyFromErrors = key => {
    setErrors(current => {
      const { [key]: value, ...rest } = current;
      return rest;
    });
  };

  const resetForm = () => {
    setDescription('');
    setSubject('');
    setDocuments([]);
    setUploading(false);
    setErrors({});
  };

  const handleSubmit = () => {
    let errorChecks = [];
    checkErrors(null, errorChecks);
    if (errorChecks.length) return;
    setIsLoading(true);
    sendBugReport(subject, description, documents)
      .then(r => {
        resetForm();
        dispatch(showSuccessNotification('Your bug report was submitted successfully. Thanks!'));
        setIsLoading(false);
      })
      .catch(e => setIsLoading(false));
  };

  useEffect(() => {
    if (errors?.subject) checkErrors(['subject']);
    if (errors?.description) checkErrors(['description']);
  }, [subject, description]);

  const pages = [{ name: 'Report bug', href: `/report-bug`, current: true }];

  return (
    <PageWrapper pages={pages}>
      <TwoColumnForm
        label="Report a bug"
        description="Help MyThaleria grow by reporting any technical issues found within the app and also by making any open suggestions that our technical team can use to improve and grow the platform"
      >
        <InputSimple label={'Subject*'} error={errors?.subject} value={subject} onChange={e => setSubject(e.target.value)} />
        <TextArea
          rows={7}
          label={'Description*'}
          error={errors?.description}
          value={description}
          onChange={e => setDescription(e.target.value)}
        />
      </TwoColumnForm>
      <TwoColumnForm description="Add helpful screenshots that show the bug (max 3 files, max 1mb each)" onlyLayout style="mt-4">
        <Attachments
          accept="image/x-png,image/gif,image/jpeg, image/jpg"
          uploadHandler={uploadFileHandler}
          enableDownload={false}
          removeHandler={RemoveFileHandler}
          documents={documents}
          uploading={uploading}
          setUploading={setUploading}
        />
      </TwoColumnForm>
      <ButtonPrimary isLoading={isLoading} style="ml-auto mt-4" text="Submit" onClick={handleSubmit} />
    </PageWrapper>
  );
}

export default BugReportPage;
