const billingUnits = {
  numbers: {
    days: 1,
    hours: 2,
  },
  strings: {
    1: 'days',
    2: 'hours',
  },
};

export default billingUnits;
