import React from 'react';
import { CheckCircleIcon, ChevronRightIcon, MailIcon } from '@heroicons/react/solid';
import { DocumentIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';

function TimesheetItem({ timesheet }) {
  return (
    <li key={timesheet.id}>
      <Link to={`/timesheets/${timesheet.id}`} className="group block">
        <div className="flex items-center py-5 px-4 sm:py-6 sm:px-0">
          <div className="min-w-0 flex-1 flex items-center">
            <DocumentIcon className="flex-shrink-0 h-12 w-12 rounded-full group-hover:opacity-75" />
            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
              <div>
                <p className="text-sm font-medium text-thaleria-blue-600 truncate">{timesheet.year + ' ' + timesheet.month}</p>
              </div>
              <div className="hidden md:block">
                {/* <div>
                  <p className="text-sm text-gray-900">
                    Applied on <time dateTime={timesheet.appliedDatetime}>{timesheet.applied}</time>
                  </p>
                  <p className="mt-2 flex items-center text-sm text-gray-500">
                    <CheckCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400" aria-hidden="true" />
                    {timesheet.status}
                  </p>
                </div> */}
              </div>
            </div>
          </div>
          <div>
            <ChevronRightIcon className="h-5 w-5 text-gray-400 group-hover:text-gray-700" aria-hidden="true" />
          </div>
        </div>
      </Link>
    </li>
  );
}

export default TimesheetItem;
