import React, { useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router';
import Table from '../../../../containers/ServerSideTable';
import AdminManager from '../AdminManager';
import { ExternalLinkIcon, PencilIcon } from '@heroicons/react/outline';
import useApi from '../../../../hooks/useApi';
import clientTypes from '../../../../helpers/enum/clientTypes';
import SelectColumnFilter from '../../../../components/TableItems/SelectColumnFilter';
import AdminSidebar from '../../AdminSidebar';

function Clients() {
  const [typeOptions, setTypeOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);

  const history = useHistory();

  const {
    clients: { getAllClientsPaginated, getClientOptions },
  } = useApi();

  useEffect(() => {
    getClientOptions().then(r => {
      setTypeOptions(r?.typeOptions);
      setCountryOptions(r?.countryOptions);
    });
  }, []);

  const fetchClients = async (page = 0, filters = [], sortByArray = [], globalFilter, downloadToCsv, source) => {
    const name = filters.find(filter => filter.id === 'name')?.value;
    const types = filters.find(filter => filter.id === 'type')?.value;
    const countries = filters.find(filter => filter.id === 'country')?.value;
    const description = filters.find(filter => filter.id === 'description')?.value;
    const sortBy = sortByArray.length ? `${sortByArray[0].id.toString()},${sortByArray[0].desc.toString()}` : undefined;

    if (downloadToCsv) {
      let csvData = [];
      await getAllClientsPaginated(page + 1, name, types, countries, description, sortBy, globalFilter, downloadToCsv).then(
        res => {
          res.forEach(client => {
            csvData.push({
              id: client.id,
              name: client.name,
              type: clientTypes.strings[client.type],
              country: client?.country_id ? client?.country?.name : '-',
              description: client.description,
            });
          });
        },
      );

      return csvData;
    }

    getAllClientsPaginated(page + 1, name, types, countries, description, sortBy, globalFilter, null, source).then(d => {
      setClients(d);
    });
  };

  const [clients, setClients] = useState([]);

  const tableData = useMemo(() => {
    let _tableData = [];
    if (clients && clients?.rows?.length) {
      clients.rows.map(e => {
        _tableData.push({
          id: e.id,
          name: e.name,
          type: clientTypes.strings[e.type],
          country: e?.country_id ? e?.country?.name : '-',
          description: e.description,
        });
      });
    }
    return _tableData;
  }, [clients]);

  const columns = useMemo(() => {
    const _columns = [
      {
        Header: 'ID',
        accessor: 'id',
        disableFilters: true,
      },
      {
        Header: 'Client Name',
        accessor: 'name',
      },
      {
        Header: 'Type',
        accessor: 'type',
        Filter: SelectColumnFilter,
        filter: 'includes',
        filterOptions: typeOptions
          ? typeOptions.map(o => ({
              label: clientTypes.strings[o.type],
              value: o.type,
            }))
          : null,
      },
      {
        Header: 'Country',
        accessor: 'country',
        Filter: SelectColumnFilter,
        filter: 'includes',
        filterOptions: countryOptions.length
          ? countryOptions.map(c => ({
              label: c.name,
              value: c.id,
            }))
          : null,
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
    ];
    return _columns;
  }, [typeOptions, countryOptions]);

  const pages = [
    { name: 'Admin Manager', href: '/admin-panel/admin/clients', current: false },
    { name: `Clients`, href: '/admin-panel/admin/clients', current: false },
  ];

  let renderRowMenu = row => {
    return [
      [
        {
          text: 'View/edit details',
          onClick: () => history.push(`/admin-panel/admin/clients/${row.original.id}`),
          show: true,
          icon: PencilIcon,
        },
        {
          text: 'View/edit details in new tab',
          onClick: () => window.open(`/admin-panel/admin/clients/${row.original.id}`),
          show: true,
          icon: ExternalLinkIcon,
        },
      ],
    ];
  };

  const addButton = {
    link: '/admin-panel/admin/clients/create',
  };

  const rowOnClick = row => {
    history.push(`/admin-panel/admin/clients/${row.values.id}`);
  };

  return (
    <AdminSidebar noPadding pages={pages}>
      <Table
        columns={columns}
        data={tableData}
        customContextMenu
        contextMenuOptions={renderRowMenu}
        addButton={addButton}
        rowOnClick={rowOnClick}
        pageCount={clients?.totalPages}
        fetchData={fetchClients}
        totalItems={clients?.totalItems}
        tableName="clients"
      />
    </AdminSidebar>
  );
}

export default Clients;
