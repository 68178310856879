import PropTypes from 'prop-types';
import React from 'react';
import ButtonPrimary from '../../../components/Buttons/ButtonPrimary';
import ButtonWhite from '../../../components/Buttons/ButtonWhite';
import TextArea from '../../../components/Inputs/TextArea';
import InputSimple from '../../../components/Inputs/InputSimple';
const AddBodyReminder = ({
  show,
  title,
  inputValue,
  inputErrorMessage,
  onInputChange,
  textAreaValue,
  onTextAreaChange,
  bodyErrorMessage,
  onCancelClick,
  onSuccessClick,
}) => {
  const showComponents = show ? 'fixed' : 'hidden';

  return (
    <>
      <h2 className="pb-10">{title} Reminder</h2>
      <div className="space-y-4">
        <InputSimple label="Title of reminder" value={inputValue} errorMessage={inputErrorMessage} onChange={onInputChange} />
        <TextArea
          label="What do you want to say? (max 180 characters)"
          value={textAreaValue}
          onChange={onTextAreaChange}
          errorMessage={bodyErrorMessage}
        />
      </div>
      <div className="pt-7 flex justify-end">
        <ButtonWhite text="Cancel" delete={true} onClick={onCancelClick} />
        <ButtonPrimary text="Next" style="ml-2" onClick={onSuccessClick} />
      </div>
    </>
  );
};

AddBodyReminder.prototype = {
  show: PropTypes.bool,
  onSuccessClick: PropTypes.func,
  onCancelClick: PropTypes.func,
  onTextAreaChange: PropTypes.func,
  onInputChange: PropTypes.func,
  textAreaValue: PropTypes.string,
  inputValue: PropTypes.string,
  bodyErrorMessage: PropTypes.string,
  inputErrorMessage: PropTypes.string,
  title: PropTypes.string,
};

export default AddBodyReminder;
