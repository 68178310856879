import React from 'react';
import { Controller } from 'react-hook-form';

import TwoColumnForm from '../../../components/Layouts/TwoColumnForm';
import InputSimple from '../../../components/Inputs/InputSimple';
import ReactSelect from '../../../components/Inputs/ReactSelect';

function AdminInfo({
  onChange,
  newUser,
  accountTypeListBoxData,
  selectedAccountType,
  setSelectedAccountType,
  pwRecipientListBoxData,
  selectedPwRecipient,
  setSelectedPwRecipient,
  accountStatusListBoxData,
  selectedAccountStatus,
  setSelectedAccountStatus,
  control,
  errors,
}) {
  return (
    <TwoColumnForm saveButton label="Administrative Info" description="Adminitrative information about user and his account.">
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-3">
          <InputSimple
            label={'HR contact'}
            onChange={(e, key) => onChange(key, e.target.value)}
            dataLabel={'hr_contact'}
            value={newUser.data.hr_contact}
          />
        </div>
        <div className="col-span-6 sm:col-span-3">
          <InputSimple
            label={'Sales contact'}
            autocomplete="off"
            onChange={(e, key) => onChange(key, e.target.value)}
            dataLabel={'sales_contact'}
            value={newUser.data.sales_contact}
          />
        </div>

        <div className="col-span-6 sm:col-span-3">
          <Controller
            control={control}
            name={'account_type_id'}
            render={({ field: { onChange, value, ref } }) => (
              <ReactSelect
                isSearchable={false}
                label={'Account Type*'}
                options={accountTypeListBoxData}
                selectedOptions={selectedAccountType}
                onChange={c => {
                  onChange(c.value);
                  setSelectedAccountType(c);
                }}
                error={errors['account_type_id']?.message}
              />
            )}
          />
        </div>
        <div className="col-span-6 sm:col-span-3">
          <Controller
            control={control}
            name={'active'}
            render={({ field: { onChange, value, ref } }) => (
              <ReactSelect
                label={'Account Status*'}
                isSearchable={false}
                options={accountStatusListBoxData}
                selectedOptions={selectedAccountStatus}
                onChange={c => {
                  onChange(c.value);
                  setSelectedAccountStatus(c);
                }}
                error={errors['active']?.message}
              />
            )}
          />
        </div>
        {!newUser.data.id && (
          <>
            <div className="col-span-6 sm:col-span-3">
              <ReactSelect
                label={'Account password'}
                options={pwRecipientListBoxData}
                isSearchable={false}
                selectedOptions={selectedPwRecipient}
                onChange={setSelectedPwRecipient}
              />
            </div>
            {selectedPwRecipient.value == 3 && (
              <div className="col-span-6 sm:col-span-3">
                <InputSimple
                  type="password"
                  label={'Password'}
                  onChange={(e, key) => onChange(key, e.target.value)}
                  dataLabel={'password'}
                  value={newUser.data.password}
                />
              </div>
            )}
          </>
        )}
      </div>
    </TwoColumnForm>
  );
}

export default AdminInfo;
