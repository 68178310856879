import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from '../../components/Modals/Modal';
import InputSimple from '../../components/Inputs/InputSimple';
import ButtonPrimary from '../../components/Buttons/ButtonPrimary';
import ButtonWhite from '../../components/Buttons/ButtonWhite';
import FileAccessManagement from './FileAccessManagement';
import contractTypes from '../../helpers/enum/contractTypeIDs';
import { AccountType } from '../../helpers/enum/accounts';
import ClipLoader from 'react-spinners/ClipLoader';

const AddFolder = ({
  open,
  hide,
  onCreateClick,
  folderNameValue,
  setFolderNameValue,
  onFolderNameChange,
  isOrgDocument,
  parentFolder,
  loading,
}) => {
  const [acceptedAccountTypes, setAcceptedAccountTypes] = useState([AccountType.ADMIN, AccountType.STAFF, AccountType.USER]);
  const [acceptedContractTypes, setAcceptedContractTypes] = useState([
    contractTypes.company,
    contractTypes.employee,
    contractTypes.freelancer,
  ]);
  const [acceptedCountries, setAcceptedCountries] = useState();
  const [showRestrictions, setShowRestrictions] = useState(false);
  const [countryOptions, setCountryOptions] = useState(null);
  const [disabledCheckboxes, setDisabledCheckboxes] = useState([]);
  const currentUser = useSelector(state => state.auth.currentUser);

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        setFolderNameValue('');
        setShowRestrictions(false);
      }, 500);
    }
  }, [open]);

  useEffect(() => {
    if (parentFolder?.restrictions) {
      let disabledCheckboxes = [];
      Object.keys(contractTypes).forEach(key => {
        !parentFolder.restrictions?.acceptedContractTypes.includes(contractTypes[key]) && disabledCheckboxes.push(key.toString());
      });
      if (!parentFolder.restrictions.acceptedAccountTypes.includes(AccountType.USER)) disabledCheckboxes.push('user');
      if (!parentFolder.restrictions.acceptedAccountTypes.includes(AccountType.STAFF)) disabledCheckboxes.push('staff');
      setDisabledCheckboxes(disabledCheckboxes);
      setAcceptedAccountTypes(parentFolder.restrictions.acceptedAccountTypes);
      setAcceptedContractTypes(parentFolder.restrictions.acceptedContractTypes);
      if (parentFolder.restrictions?.acceptedCountries) {
        setCountryOptions(parentFolder.restrictions.acceptedCountries);
        setAcceptedCountries(parentFolder.restrictions.acceptedCountries);
      } else {
        setCountryOptions(null);
        setAcceptedCountries();
      }
    } else {
      setCountryOptions(null);
      setAcceptedCountries();
    }
  }, [parentFolder, showRestrictions]);

  const addRestrictionsAndCreate = () => {
    let restrictions = null;
    if (isOrgDocument) {
      restrictions = {
        acceptedAccountTypes: acceptedAccountTypes,
        acceptedContractTypes: acceptedContractTypes,
      };
      if (acceptedCountries?.length) restrictions.acceptedCountries = acceptedCountries;
    }

    onCreateClick(restrictions);
  };

  const onCountriesInputChangeHandler = event => {
    let newCountries;
    if (!event) newCountries = [];
    else newCountries = event.map(country => country.value);

    setAcceptedCountries(newCountries);
  };

  const onContractTypesChangeHandler = event => {
    if (event.target.id == 'employee') {
      event.target.checked
        ? setAcceptedContractTypes(prev => [...prev, contractTypes.employee])
        : setAcceptedContractTypes(acceptedContractTypes.filter(id => id != contractTypes.employee));
    }
    if (event.target.id == 'freelancer') {
      event.target.checked
        ? setAcceptedContractTypes(prev => [...prev, contractTypes.freelancer])
        : setAcceptedContractTypes(acceptedContractTypes.filter(id => id != contractTypes.freelancer));
    }
    if (event.target.id == 'company') {
      event.target.checked
        ? setAcceptedContractTypes(prev => [...prev, contractTypes.company])
        : setAcceptedContractTypes(acceptedContractTypes.filter(id => id != contractTypes.company));
    }
  };

  const onAccountTypesChangeHandler = event => {
    if (event.target.id == 'user') {
      event.target.checked
        ? setAcceptedAccountTypes(prev => [...prev, AccountType.USER])
        : setAcceptedAccountTypes(acceptedAccountTypes.filter(id => id != AccountType.USER));
    }
    if (event.target.id == 'staff') {
      event.target.checked
        ? setAcceptedAccountTypes(prev => [...prev, AccountType.STAFF])
        : setAcceptedAccountTypes(acceptedAccountTypes.filter(id => id != AccountType.STAFF));
    }
    // let newAccountTypes;
    // if (!event) newAccountTypes = [];
    // else newAccountTypes = event.map(country => country.value);

    // setAcceptedAccountTypes(newAccountTypes);
  };

  return (
    <Modal show={open} hide={hide}>
      <div className="sm:flex flex-col sm:items-start w-full">
        <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
          <h2 className="leading-6 text-gray-900 pb-8" id="modal-title">
            Add folder
          </h2>
          {showRestrictions ? (
            <FileAccessManagement
              acceptedCountries={acceptedCountries}
              acceptedContractTypes={acceptedContractTypes}
              acceptedAccountTypes={acceptedAccountTypes}
              onCountriesChange={onCountriesInputChangeHandler}
              onContractTypesChange={onContractTypesChangeHandler}
              onAccountTypesChange={onAccountTypesChangeHandler}
              isAdmin={currentUser.account_type_id == AccountType.ADMIN}
              countryOptions={countryOptions}
              countriesPlaceholder={'All countries'}
              disabledCheckboxes={disabledCheckboxes}
            />
          ) : (
            <InputSimple value={folderNameValue} onChange={onFolderNameChange} label="Enter your folder's name" />
          )}
        </div>
        <div className="pt-7 flex justify-end w-full">
          <ButtonWhite
            text={showRestrictions ? 'Back' : 'Cancel'}
            onClick={!loading && (showRestrictions ? () => setShowRestrictions(false) : hide)}
          />
          <ButtonPrimary
            text={
              loading == 'createFolder' ? (
                <ClipLoader className="mr-2" size={17} color={'#ffff'} />
              ) : !isOrgDocument ? (
                'Create'
              ) : showRestrictions ? (
                'Create'
              ) : (
                'Next'
              )
            }
            disable={folderNameValue == '' || !acceptedContractTypes?.length || (!acceptedCountries?.length && countryOptions)}
            style="ml-2"
            onClick={
              !loading &&
              (!isOrgDocument
                ? () => addRestrictionsAndCreate()
                : !showRestrictions
                ? () => setShowRestrictions(true)
                : () => addRestrictionsAndCreate())
            }
          />
        </div>
      </div>
    </Modal>
  );
};

export default AddFolder;

AddFolder.prototype = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  onCancelClick: PropTypes.func,
  onCreateClick: PropTypes.func,
  onFolderNameChange: PropTypes.func,
};
