import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
import { useCallback } from 'react';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonWhite from '../../components/Buttons/ButtonWhite';
import { DescriptionListItem } from '../../components/DescriptionEntries/DescriptionList';
import ModalImage from '../../components/Modals/ModalImage';
import ProfilePicture from '../../components/ProfilePicture/ProfilePicture';
import UserImagePlaceholder from '../../components/UserImagePlaceholder/UserImagePlaceholder';
import { getCroppedImg } from '../../helpers/index';
//actions
import { deleteMyProfilePicture, updateProfilePicture } from '../../store/auth/actions';
import UpdatePasswordModal from '../../containers/MyProfile/UpdatePasswordModal';
import ButtonPrimary from '../../components/Buttons/ButtonPrimary';
import { updateMyself } from '../../store/auth/actions';
import { showSuccessNotification } from '../../store/app/actions';
import ClipLoader from 'react-spinners/ClipLoader';

function PersonalInfo() {
  //redux state
  const currentUser = useSelector(state => state.auth.currentUser);

  const [edit, setEdit] = useState(false);
  const [editedData, setEditedData] = useState({
    email: null,
    phoneNb: null,
    preferredName: null,
    address: null,
  });

  const [errors, setErrors] = useState([]);

  const [isUploading, setIsUploading] = useState(false);

  //redux actions
  const dispatch = useDispatch();

  const inputFile = useRef(null);

  const [showEditProfilePictureButton, setShowEditProfilePictureButton] = useState(false);
  const [showModalEditPicture, setShowModalEditPicture] = useState(false);
  const [showUpdatePassword, setShowUpdatePassword] = useState(false);
  const [newPicture, setNewPicture] = useState(null);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(newPicture, croppedAreaPixels, rotation);
      let newImage = new File([croppedImage], 'profile_picture', {
        type: croppedImage.type,
      });
      setCroppedImage(newImage);
      setShowModalEditPicture(false);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);

  function readFile(file) {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }
  const onChangeFile = async event => {
    event.stopPropagation();
    event.preventDefault();
    let file = event?.target?.files[0];

    if (!file) return;

    file = await readFile(file);
    setNewPicture(file);
    setShowModalEditPicture(true);
  };

  const deleteProfilePictureHandler = () => {
    setCroppedImage('deleted');
  };

  const handleEditClick = () => {
    setShowEditProfilePictureButton(!showEditProfilePictureButton);
    setCroppedImage(null);
    setEdit(!edit);
    setEditedData({
      email: currentUser.email,
      phoneNb: currentUser.phone_nb,
      preferredName: currentUser.preferred_name,
      address: currentUser.address,
    });
    setErrors([]);
  };

  const handleSubmitEdit = async () => {
    if (
      editedData.email == currentUser.email &&
      editedData.phoneNb == currentUser.phone_nb &&
      editedData.secondaryPhoneNb == currentUser.secondary_phone_nb &&
      editedData.preferredName == currentUser.preferred_name &&
      editedData.address == currentUser.address &&
      croppedImage == null
    )
      return handleEditClick();

    const emailRegex = RegExp(/^\S+@\S+\.\S+$/);
    const addressRegex = RegExp(/[0-9a-zA-Z, ]{6,}/);
    let _errors = [];
    if (!emailRegex.test(editedData.email)) _errors.push({ field: 'Email', msg: 'Email format invalid' });
    if (!addressRegex.test(editedData.address))
      _errors.push({ field: 'Address', msg: 'Address must contain at least 5 characters' });
    if (!editedData.phoneNb) _errors.push({ field: 'Phone number', msg: 'Please include  a phone number' });
    if (!editedData?.address || editedData?.address === '')
      _errors.push({ field: 'Address', msg: 'Please include a valid address' });
    if (editedData.phoneNb && editedData.phoneNb != '' && !editedData.phoneNb.match(new RegExp(/^[0-9 +]+$/)))
      _errors.push({ field: 'Phone number', msg: 'Not a valid phone number' });
    if (
      editedData.secondaryPhoneNb &&
      editedData.secondaryPhoneNb != '' &&
      !editedData.secondaryPhoneNb.match(new RegExp(/^[0-9 +]+$/))
    )
      _errors.push({ field: 'Secondary phone number', msg: 'Not a valid phone number' });
    if (_errors.length) return setErrors(_errors);

    setIsUploading(true);

    await dispatch(updateMyself(editedData))
      .then(res => {
        if (croppedImage == 'deleted') {
          dispatch(deleteMyProfilePicture());
        } else if (croppedImage) {
          dispatch(updateProfilePicture(croppedImage));
        }
        dispatch(showSuccessNotification('Your details were edited successfully'));
      })
      .catch(err => {
        setIsUploading(false);
      });
    handleEditClick();
    setIsUploading(false);
  };

  return (
    <form className="divide-y divide-gray-200 lg:col-span-9">
      <div className="py-6 px-4 sm:p-6 lg:pb-8">
        {showUpdatePassword && <UpdatePasswordModal open={showUpdatePassword} setOpen={setShowUpdatePassword} />}
        <div className="">
          <h2 className="text-lg leading-6 font-medium text-gray-900">Profile</h2>
          <p className="mt-1 text-sm text-gray-500">Personal details and information.</p>
        </div>
        <div className="mt-6">
          <DescriptionListItem tag={'First name(s)'} data={currentUser.first_names || '-'} />
          <DescriptionListItem tag={'Last name(s)'} data={currentUser.last_names || '-'} />
          {(edit || currentUser.preferred_name) && (
            <DescriptionListItem
              edit={edit}
              editedData={editedData.preferredName}
              onChange={e => setEditedData(prev => ({ ...prev, preferredName: e.target.value }))}
              tag={'Preferred name'}
              data={currentUser.preferred_name}
            />
          )}
          <DescriptionListItem
            edit={edit}
            editedData={editedData.email}
            onChange={e => setEditedData(prev => ({ ...prev, email: e.target.value }))}
            tag={'Email'}
            data={currentUser.email}
            errors={errors}
          />
          <DescriptionListItem
            edit={edit}
            editedData={editedData.phoneNb}
            onChange={e => setEditedData(prev => ({ ...prev, phoneNb: e?.target?.value }))}
            tag={'Phone number'}
            data={currentUser.phone_nb}
            errors={errors}
          />
          <DescriptionListItem
            edit={edit}
            editedData={editedData.secondaryPhoneNb}
            onChange={e => setEditedData(prev => ({ ...prev, secondaryPhoneNb: e?.target?.value }))}
            tag={'Secondary phone number'}
            data={currentUser.secondary_phone_nb}
            errors={errors}
          />
          <div className="flex flex-row justify-between">
            <div className="w-full">
              <DescriptionListItem
                tag="Profile picture"
                data={
                  <ProfilePicture
                    src={croppedImage ? null : currentUser.profilepic_url}
                    alt="profile"
                    className="h-16 w-16 rounded-full ring-4 ring-white sm:h-16 sm:w-16"
                    defaultimage={
                      !croppedImage || croppedImage === 'deleted' ? (
                        <UserImagePlaceholder className={`h-16 w-16 rounded-full ring-4 ring-white sm:h-16 sm:w-16`} />
                      ) : (
                        <img
                          className={`h-16 w-16 rounded-full ring-4 ring-white sm:h-16 sm:w-16`}
                          alt="cropped"
                          src={URL.createObjectURL(croppedImage)}
                        />
                      )
                    }
                  />
                }
                optional={
                  <div className="flex flex-col sm:flex-row items-center justify-center space-x-2 space-y-2">
                    <input type="file" id="file" accept="image/*" ref={inputFile} onChange={onChangeFile} className="hidden" />
                    <ButtonWhite
                      style={`${showEditProfilePictureButton || 'hidden'} w-full sm:w-auto`}
                      text="Change picture"
                      onClick={() => inputFile.current.click()}
                    />
                    <ButtonWhite
                      style={`${showEditProfilePictureButton || 'hidden'} w-full sm:w-auto`}
                      text={'Delete picture'}
                      onClick={deleteProfilePictureHandler}
                    />
                  </div>
                }
              />
            </div>
          </div>
          <DescriptionListItem
            edit={edit}
            tag={'Address'}
            data={currentUser.address || '-'}
            editedData={editedData.address}
            onChange={e => setEditedData(prev => ({ ...prev, address: e?.target?.value }))}
            errors={errors}
          />
          <DescriptionListItem tag={'Birthday'} data={dayjs(currentUser.birthday).format('DD/MM/YYYY') || '-'} />
          <DescriptionListItem tag={'HR Contact'} data={currentUser.hr_contact || '-'} />
          <DescriptionListItem tag={'Sales Contact'} data={currentUser.sales_contact || '-'} />
          <DescriptionListItem tag={'ID number'} data={currentUser.id_nb || '-'} />
          <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium text-gray-500">Password</dt>
            <dd className="mt-1 flex flex-col sm:flex-row text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <ButtonWhite style="w-40" text="Update Password" onClick={() => setShowUpdatePassword(true)} />
            </dd>
          </div>
        </div>
        <div className="flex flex-col md:flex-row-reverse md:gap-x-4 h-10">
          {edit && (
            <ButtonPrimary
              text={isUploading ? <ClipLoader className="mr-10 h-5 w-5" size={18} color={'#FFFF'} /> : 'Save'}
              onClick={handleSubmitEdit}
            />
          )}
          <ButtonWhite text={edit ? 'Cancel' : 'Edit'} onClick={handleEditClick} />
        </div>
        <ModalImage
          image={newPicture}
          title="Crop image"
          show={showModalEditPicture}
          onCancel={setShowModalEditPicture.bind(this, false)}
          onCropComplete={onCropComplete}
          onSuccess={showCroppedImage}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          aspect={2 / 2}
          setCrop={setCrop}
          setRotation={setRotation}
          setZoom={setZoom}
          isUploading={isUploading}
        />
      </div>
    </form>
  );
}

export default PersonalInfo;
