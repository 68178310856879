import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import InputSimple from '../../components/Inputs/InputSimple';
import { useForm } from 'react-hook-form';
import { useYupValidationResolver } from '../../utils/hooks/useYupValidationResolver';
import * as Yup from 'yup';
import { VALIDATION_MSG } from '../../helpers/enum/errorValidationMsgs';
import MyThaleriaLogo from '../../components/Icons/MyThaleriaLogo';
import ButtonPrimary from '../../components/Buttons/ButtonPrimary';
import img from '../../resources/eu-commission.jpeg';
import useApi from '../../hooks/useApi';
import { getCurrentUser } from '../../store/auth/actions';

function ResetPasswordSplitScreen(props) {
  const history = useHistory();

  const dispatch = useDispatch();

  const currentUser = useSelector(state => state.auth.currentUser);

  const {
    users: { removeTemporaryPassword },
  } = useApi();

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
      .matches(/[0-9]/, 'Password must contain at least one number')
      .matches(/[!@#$%^&*()+_\-=}{[\]|:;'"/?.><,`«»~€£¥₽\\]/, 'Password must contain at least one symbol')
      .required(VALIDATION_MSG),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], 'Your passwords do not match.')
      .required(VALIDATION_MSG),
  });

  const formOptions = { resolver: useYupValidationResolver(validationSchema) };

  const {
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm(formOptions);

  const handleKeyPress = e => {
    if (e.key === 'Enter' && e.shiftKey === false) {
      return handleSubmit(onSubmit)();
    }
  };

  const onSubmit = data => {
    removeTemporaryPassword({
      password: data.password,
    }).then(res => {
      dispatch(getCurrentUser());
      history.push('/home');
    });
  };

  return (
    <div className="min-h-screen bg-thaleria-blue-700 flex justify-center items-center px-6">
      <div className="mx-auto w-full max-w-sm lg:w-96">
        <div>
          <div>
            <MyThaleriaLogo white size="xl" />
          </div>
          <h2 className="text-3xl font-extrabold text-white">Hi {currentUser.first_names}, please reset your password</h2>
          <p className="mb-6 text-white text-sm">
            Password must be minimum 8 characters, include upper and lower case letters, a number and a special character.
          </p>
        </div>
        <div className="grid grid-cols-1 gap-y-6 w-full mx-auto">
          <InputSimple
            onKeyPress={handleKeyPress}
            type="password"
            error={errors.password?.message}
            register={register('password')}
            label={'New password'}
            dataLabel={'password'}
            whiteLabel
          />
          <InputSimple
            onKeyPress={handleKeyPress}
            type="password"
            error={errors.confirmPassword?.message}
            register={register('confirmPassword')}
            label={'Confirm password'}
            dataLabel={'confirmPassword'}
            whiteLabel
          />
          <ButtonPrimary text="Save" onClick={handleSubmit(onSubmit)} />
        </div>
      </div>
    </div>
  );
}

export default ResetPasswordSplitScreen;
