import React, { useState } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/outline';

function WarningTooltip({ msg, children, iconClassName }) {
  return (
    <div className="relative group z-50">
      <ExclamationCircleIcon className={`${iconClassName ? iconClassName : 'w-5 h-5 text-red-500 ml-2'} cursor-pointer`} />
      <div className="ml-2 hidden group-hover:block absolute shadow-lg text-sm p-2 bg-gray-400 text-white translate-x-6 -translate-y-10 rounded-md ">
        {msg && <div className="w-80 h-max">{msg}</div>}
        {children}
      </div>
    </div>
  );
}

export default WarningTooltip;
