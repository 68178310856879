import React, { useEffect, useRef, useState } from 'react';
import ButtonWhite from '../../components/Buttons/ButtonWhite';
import FileAdd from '../../components/Icons/FileAdd';
import FolderAdd from '../../components/Icons/FolderAdd';
import PropTypes from 'prop-types';
import ReactSelect from '../../components/Inputs/ReactSelect';
import { MenuIcon, TemplateIcon } from '@heroicons/react/solid';
import BarsIcon from '../../components/Icons/4BarsIcon';
import SquaresIcon from '../../components/Icons/SquaresIcon';

const AdminDocumentHeader = ({
  item,
  items,
  onListChange,
  onAddFolderClick,
  users,
  selectedUser,
  onUserChange,
  setShowUploadModal,
  setListDisplay,
  listDisplay,
  loading,
}) => {
  const inputFile = useRef(null);
  const [selectedUserOption, setSelectedUserOption] = useState(null);

  const userOptions = users.map(user => ({
    value: user?.id,
    label: user?.name,
  }));

  useEffect(() => {
    if (!selectedUser) return setSelectedUserOption(null);
    setSelectedUserOption({
      value: selectedUser?.id,
      label: selectedUser?.name,
    });
  }, [selectedUser]);

  return (
    <>
      <div
        className="flex flex-row justify-center sm:justify-between flex-wrap items-center p-8 bg-banner-pattern border-b border-gray-200 gap-y-2"
        id="document-header"
      >
        <div className="flex w-full sm:w-max items-center">
          <div className="w-60">
            <ReactSelect
              options={items}
              selectedOptions={[item]}
              onChange={onListChange}
              disabled={loading}
              orderOptions={false}
            />
          </div>
          {item?.value == 1 && (
            <div className="w-44 ml-4">
              <ReactSelect
                isSearchable
                options={userOptions}
                selectedOptions={selectedUserOption || { label: 'Select user' }}
                onChange={onUserChange}
                disabled={loading}
              />
            </div>
          )}
        </div>
        <div className="flex justify-end sm:justify-between space-x-4">
          <div className="hidden items-center rounded-lg bg-gray-200 p-0.5 sm:flex">
            <button
              type="button"
              className={`${
                listDisplay
                  ? 'ml-0.5 rounded-md bg-white p-1.5 text-gray-400 shadow-sm'
                  : 'rounded-md p-1.5 text-gray-400 hover:bg-white hover:shadow-sm'
              } focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"`}
              onClick={() => setListDisplay(true)}
            >
              <BarsIcon className="h-7 w-7" />
              <span className="sr-only">Use list view</span>
            </button>
            <button
              type="button"
              className={`${
                !listDisplay
                  ? 'ml-0.5 rounded-md bg-white p-1.5 text-gray-400 shadow-sm'
                  : 'rounded-md p-1.5 text-gray-400 hover:bg-white hover:shadow-sm'
              } focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"`}
              onClick={() => setListDisplay(false)}
            >
              <SquaresIcon className="h-7 w-7" />
              <span className="sr-only">Use grid view</span>
            </button>
          </div>
          <div className="flex justify-center mt-2 lg:m-0 space-x-2">
            <ButtonWhite
              text="Add folder"
              icon={<FolderAdd />}
              onClick={onAddFolderClick}
              disabled={item.value == 1 && !selectedUser}
            />
            <ButtonWhite
              text="Upload file"
              icon={<FileAdd />}
              onClick={() => setShowUploadModal(true)}
              disabled={item.value == 1 && !selectedUser}
            />
          </div>
        </div>
      </div>
    </>
  );
};

AdminDocumentHeader.prototype = {
  path: PropTypes.string,
  onAddFolderClick: PropTypes.func,
  uploadHandler: PropTypes.func,
  onBackClick: PropTypes.func,
  showArrowLeftIcon: PropTypes.bool,
};

export default AdminDocumentHeader;
