import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useApi from '../../../../hooks/useApi';
import AdminSidebar from '../../AdminSidebar';
import UserInvoiceDetails from '../../../../containers/Finance/AccountsPayable/UserInvoiceDetails';
import UserInvoiceEdit from '../../../../containers/Finance/AccountsPayable/UserInvoiceEdit';
import ButtonWhite from '../../../../components/Buttons/ButtonWhite';
import ButtonPrimary from '../../../../components/Buttons/ButtonPrimary';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showSuccessNotification } from '../../../../store/app/actions';
import paidStatus from '../../../../helpers/enum/paidStatus';
import PayInvoice from '../../../../components/Modals/PayInvoice';
import SimpleAlert from '../../../../components/Modals/SimpleAlert';
import { lineItemTypesV2 } from '../../../../helpers/enum/lineItemTypes';
import * as contractTypes from '../../../../helpers/enum/contractTypeIDs';

function AccountsPayableDetails() {
  const [invoice, setInvoice] = useState({});
  const [lineItems, setLineItems] = useState([]);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [edit, setEdit] = useState(false);
  const [loadingFile, setLoadingFile] = useState(true);
  const [invoiceFile, setInvoiceFile] = useState(null);
  const [loadingAP, setLoadingAP] = useState(true);
  const [invoiceToEdit, setInvoiceToEdit] = useState({ lineItems: [] });
  const [formErrors, setFormErrors] = useState([]);
  const [activeStaffOrders, setActiveStaffOrders] = useState([]);
  const [showStaffContractChangeDetected, setShowStaffContractChangeDetected] = useState(false);
  const [showPayModal, setShowPayModal] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [saving, setSaving] = useState(false);
  const {
    accountsPayable: {
      getAccountsPayableById,
      getAccountsPayableFilesOnFolder,
      editAccountsPayable,
      updateApprovedStatus,
      updatePaidStatus,
      deleteAccountsPayable,
    },
    purchaseOrders: { getActiveStaffOrders },
    lineItems: { getAvailableAPLines },
  } = useApi();

  const { id } = useParams();

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    getAccountsPayableById(id)
      .then(res => {
        setLoadingAP(false);
        const { lineItems, ...invoiceData } = res;
        setInvoice(invoiceData);
        setLineItems(
          lineItems?.length
            ? lineItems
                .map(lineItem => ({
                  ...lineItem,
                  metadata: lineItem.metadata ? JSON.parse(lineItem.metadata) : null, // Parse metadata here
                }))
                .sort((a, b) => {
                  //order line items by type (allocations and milestones first, then adjustment lines and finally custom lines) and by created_at date
                  const typeOrder = [
                    'projectAllocations',
                    'unapprovedProjectAllocations',
                    'milestone',
                    'projectAllocationCreditNoteAdjustment',
                    'projectAllocationAccountReceivableAdjustment',
                    'customLineReinvoicingOfExpenses',
                    'customLineRecruitmentFee',
                    'customLineOther',
                  ];

                  const orderedTypeValues = typeOrder.map(type => lineItemTypesV2.accountsPayable.numbers[type]);

                  function getTypeOrderValue(type) {
                    return orderedTypeValues.indexOf(type);
                  }

                  const aTypeOrderValue = getTypeOrderValue(a.type);
                  const bTypeOrderValue = getTypeOrderValue(b.type);

                  // First, compare by type order
                  if (aTypeOrderValue !== bTypeOrderValue) {
                    return aTypeOrderValue - bTypeOrderValue;
                  }

                  // If types are the same, compare by createdAt date
                  return new Date(a.created_at) - new Date(b.created_at);
                })
            : [],
        );
      })
      .catch(err => {
        history.replace('/admin-panel/finance/accounts-payable');
        setLoadingAP(false);
        throw err;
      });
  }, [edit]);

  useEffect(() => {
    getAccountsPayableFilesOnFolder(id)
      .then(res => {
        setLoadingFile(false);
        const files = res.data.map(obj => ({
          ...obj,
          id: obj.key,
          resource: obj.key,
        }));
        if (files?.length) {
          setInvoiceFile(files[0]);
        } else {
          return;
        }
      })
      .catch(err => {
        setLoadingFile(false);
        return err;
      });
  }, []);

  const invoiceFileReplaced = useMemo(() => {
    //this checks whether the invoiceFile is just a regular object (that comes from backend) or a File object that the user uploaded
    return invoiceFile instanceof File;
  }, [invoiceFile]);

  const pages = [
    { name: 'Finance Manager', href: '/admin-panel/finance/invoicing-items', current: false },
    { name: `Accounts Payable`, href: '/admin-panel/finance/accounts-payable', current: false },
    { name: `User invoice details`, href: `/admin-panel/finance/accounts-payable/${id}`, current: true },
  ];

  const handleEditClick = () => {
    let lines = lineItems.map(li => ({
      checked: true,
      ...li,
    }));
    getAvailableAPLines(invoice.user_id).then(res => {
      if (res.length) {
        lines = [
          ...lines,
          ...res.map(line => ({
            ...line,
            metadata: JSON.parse(line.metadata),
            checked: false,
          })),
        ];
      }

      setInvoiceToEdit({
        iban: invoice.iban,
        bic_swift: invoice.bic_swift,
        company_name: invoice.company_name,
        invoice_number: invoice.invoice_number,
        amount_match: invoice.amount_match,
        due_date: new Date(invoice.due_date),
        user_id: invoice.user_id,
        lineItems: lines,
      });
    });
    getActiveStaffOrders(invoice.user_id).then(res => {
      setActiveStaffOrders(res);
    });
    setEdit(true);
  };

  const checkFormErrors = () => {
    let errors = [];
    if (!invoiceToEdit.staff_contract_id)
      errors.push({
        field: 'staffContract',
        msg: 'This field is required',
      });
    if (invoiceToEdit?.staff_contract_id && !invoiceToEdit?.iban) {
      errors.push({
        field: 'iban',
        msg: 'This field is required. Please update Staff contract',
      });
    }
    if (
      invoiceToEdit?.staff_contract_id &&
      invoiceToEdit.contract_type_id === contractTypes?.company &&
      !invoiceToEdit?.company_name
    ) {
      errors.push({
        field: 'companyName',
        msg: 'This field is required. Please update Staff contract',
      });
    }
    if (!invoiceToEdit.invoice_number)
      errors.push({
        field: 'invoiceNumber',
        msg: 'This field is required',
      });
    if (!invoiceToEdit.lineItems.find(li => li.checked))
      errors.push({
        field: 'invoiceLines',
        msg: 'At least one line item must be checked to create the invoice request',
      });
    if (!invoiceFile) {
      errors.push({
        field: 'invoiceFile',
        msg: 'An invoice file is required',
      });
    }
    if (invoiceToEdit.amount_match === null) {
      errors.push({
        field: 'isAMatch',
        msg: 'This field is required.',
      });
    }

    return errors;
  };

  const handleSaveClick = skipStaffContractChangeCheck => {
    setSaving(true);
    if (skipStaffContractChangeCheck) setShowStaffContractChangeDetected(false);
    const errors = checkFormErrors();
    if (errors?.length) {
      setFormErrors(errors);
      const editContainer = document.getElementById('userInvoiceEdit');
      if (editContainer) {
        editContainer.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      }
      return;
    }

    setFormErrors([]);

    const staffContractDataChangeDetected =
      invoice?.iban !== invoiceToEdit?.iban ||
      invoice?.bic_swift !== invoiceToEdit?.bic_swift ||
      invoice?.company_name !== invoiceToEdit.company_name
        ? true
        : false;

    if (staffContractDataChangeDetected && !skipStaffContractChangeCheck) {
      setShowStaffContractChangeDetected(true);
      return;
    }

    const lineItemsToAdd = [];

    invoiceToEdit.lineItems.forEach(li => {
      if (li?.checked) {
        if (li?.id) {
          let lineItem = { id: li.id };
          //if decimal places were changed, send it with the line items
          if (li?.metadata?.new_decimal_places) {
            lineItem.metadata = { decimal_places: Number(li?.metadata?.new_decimal_places) };
          }
          lineItemsToAdd.push(lineItem);
        }
        if (li?.uuid) {
          const po = activeStaffOrders.find(po => po.id === li?.staff_order_id);
          lineItemsToAdd.push({
            description: li?.description,
            units: li?.units,
            uom: li?.uom,
            price: li?.price,
            vat_rate: li?.vat_rate,
            type: li?.type,
            staff_order_id: li?.staff_order_id,
            framework_contract_id: po?.framework_contract_id,
            partner_contract_id: po?.partner_contract_id,
            sales_order_id: po?.sales_order_id,
            user_id: invoice?.user_id,
          });
        }
      }
    });

    const apData = {
      invoice_number: invoiceToEdit.invoice_number,
      staff_contract_id: invoiceToEdit.staff_contract_id,
      due_date: invoiceToEdit.due_date,
      amount_match: invoiceToEdit.amount_match,
      lineItemsToAdd: lineItemsToAdd,
    };

    const fileToUpdate = invoiceFileReplaced ? invoiceFile : null;

    editAccountsPayable(invoice.id, apData, fileToUpdate).then(res => {
      dispatch(showSuccessNotification('Invoice updated!'));
      setEdit(false);
      setInvoiceToEdit({ lineItems: [] });
      setSaving(false);
    });
  };

  const editApprovedStatus = () => {
    const newApprovedStatus = invoice?.approved ? false : true;
    updateApprovedStatus(invoice?.id, { approved: newApprovedStatus })
      .then(res => {
        setInvoice(prev => ({ ...prev, approved: newApprovedStatus }));
      })
      .catch(err => {
        throw err;
      });
  };

  const updateInvoicePaid = async (id, invoicePaid, date, paidAmount) => {
    let dataToUpdate = {
      paid: invoicePaid,
      payment_date: invoicePaid === paidStatus.numbers.unpaid ? null : date,
      paid_amount: invoicePaid === paidStatus.numbers.unpaid ? null : paidAmount,
    };
    updatePaidStatus(id, dataToUpdate).then(res => {
      dispatch(showSuccessNotification('Sucessfully updated paid status'));
      setInvoice(prev => ({ ...prev, ...dataToUpdate }));
      setShowPayModal(false);
    });
  };

  const deleteHandler = () => {
    deleteAccountsPayable(invoice.id).then(res => {
      dispatch(showSuccessNotification('Invoice deleted!'));
      history.replace('/admin-panel/finance/accounts-payable');
    });
  };

  const resetInvoiceFile = () => {
    setUploading(true);
    getAccountsPayableFilesOnFolder(id)
      .then(res => {
        const files = res.data.map(obj => ({
          ...obj,
          id: obj.key,
          resource: obj.key,
        }));
        if (files?.length) {
          setInvoiceFile(files[0]);
          setUploading(false);
        } else {
          setUploading(false);
          return;
        }
      })
      .catch(err => {
        setLoadingFile(false);
        return err;
      });
  };

  return (
    <AdminSidebar noPadding pages={pages}>
      <SimpleAlert
        errorTitle={'Delete invoice?'}
        errorMsg={
          'By continuing you will permanently delete this invoice including the uploaded file, any custom lines that may have been created and all the comments. Other line items like allocations and milestones will still be available to invoice again.'
        }
        show={showDeleteAlert}
        hide={() => setShowDeleteAlert(false)}
        onAcceptText={'Delete'}
        onAcceptClick={() => deleteHandler()}
        onDeclineText={'Cancel'}
        onDeclineClick={() => setShowDeleteAlert(false)}
      />
      <SimpleAlert
        errorTitle="Staff contract changes detected"
        errorMsg={
          <>
            <span>The following changes were made to the banking information of this invoice:</span>
            <div className="mt-2 flex justify-between w-full px-2">
              <ul>
                <li className="mb-1 font-bold">Old</li>
                <li>{invoice?.iban || '-'}</li>
                <li>{invoice?.bic_swift || '-'}</li>
                <li>{invoice?.company_name || '-'}</li>
              </ul>
              <ul>
                <ul>
                  <li className="mb-1 font-bold">New</li>
                  <li>{invoiceToEdit?.iban || '-'}</li>
                  <li>{invoiceToEdit?.bic_swift || '-'}</li>
                  <li>{invoiceToEdit?.company_name || '-'}</li>
                </ul>
              </ul>
            </div>
          </>
        }
        show={showStaffContractChangeDetected}
        hide={() => setShowStaffContractChangeDetected(false)}
        onAcceptText={'Continue'}
        //by adding true to handleSaveClick, it skips checking for Staff contract changes
        onAcceptClick={() => handleSaveClick(true)}
        onDeclineText={'Cancel'}
        onDeclineClick={() => setShowStaffContractChangeDetected(false)}
      />
      <PayInvoice
        show={showPayModal}
        setShow={setShowPayModal}
        id={invoice?.id}
        _paid={invoice?.paid}
        _paidAmount={invoice?.paid_amount}
        _paymentDate={invoice?.payment_date}
        onConfirm={updateInvoicePaid}
        totalAmount={invoice?.cost_and_vat}
        _dueDate={invoice?.due_date}
      />
      <div id="userInvoiceEdit" className="overflow-y-auto h-full bg-white">
        {edit ? (
          <UserInvoiceEdit
            invoice={invoiceToEdit}
            setInvoice={setInvoiceToEdit}
            file={invoiceFile}
            setFile={setInvoiceFile}
            formErrors={formErrors}
            activeStaffOrders={activeStaffOrders}
            edit={edit}
            setEdit={setEdit}
            handleEditClick={handleEditClick}
            handleSaveClick={handleSaveClick}
            setShowDeleteAlert={setShowDeleteAlert}
            invoiceFileReplaced={invoiceFileReplaced}
            resetInvoiceFile={resetInvoiceFile}
            uploading={uploading}
            setUploading={setUploading}
            saving={saving}
            setSaving={setSaving}
          />
        ) : (
          <UserInvoiceDetails
            invoice={invoice}
            lineItems={lineItems}
            loadingFile={loadingFile}
            file={invoiceFile}
            loadingAP={loadingAP}
            editApproved={editApprovedStatus}
            setShowPayModal={setShowPayModal}
            edit={edit}
            setEdit={setEdit}
            handleEditClick={handleEditClick}
            handleSaveClick={handleSaveClick}
            setShowDeleteAlert={setShowDeleteAlert}
          />
        )}
      </div>
    </AdminSidebar>
  );
}

export default AccountsPayableDetails;
