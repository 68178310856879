import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import TwoColumnForm from '../../components/Layouts/TwoColumnForm';
import InputSimple from '../../components/Inputs/InputSimple';
import SimpleAlert from '../../components/Modals/SimpleAlert';
import NewTabOpener from '../../components/NewTabOpener/NewTabOpener';
import RefreshButton from '../../components/Buttons/RefreshButton';
import { useForm, Controller } from 'react-hook-form';
import { useYupValidationResolver } from '../../utils/hooks/useYupValidationResolver';
import * as Yup from 'yup';
import { VALIDATION_MSG } from '../../helpers/enum/errorValidationMsgs';
import useApi from '../../hooks/useApi';
import ReactSelect from '../../components/Inputs/ReactSelect';
import extractObjectDifferences from '../../helpers/extractObjectDifferences';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../store/app/actions';
import notifications from '../../helpers/enum/notifications';

function AddPointOfContact({ contacts, clients, id, handleSave, fetchClients }) {
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState(false);

  const dispatch = useDispatch();

  const {
    pointOfContacts: { deleteContact },
  } = useApi();

  const history = useHistory();

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required(VALIDATION_MSG).trim(),
    last_name: Yup.string().required(VALIDATION_MSG).trim(),
    client_id: Yup.number().required(VALIDATION_MSG),
    email: Yup.string().required(VALIDATION_MSG),
  });

  const formOptions = { resolver: useYupValidationResolver(validationSchema) };

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm(formOptions);

  useEffect(() => {
    if (id && id !== 'create') {
      contacts.length &&
        contacts.forEach(p => {
          if (p.id == id) {
            setValue('id', p.id);
            setValue('first_name', p.first_name);
            setValue('last_name', p.last_name);
            setValue('email', p.email);
            setValue('phone_no', p.phone_no);
            setValue('client_id', p.client_id);
          }
        });
    }
  }, [id, contacts]);

  const formLabels = {
    first_name: 'First Name*',
    last_name: 'Last Name*',
    email: 'Email*',
    phone_no: 'Phone Number',
    client_id: 'Client*',
  };

  const labelArray = Object.keys(formLabels);

  const handleRemoveClick = () => {
    deleteContact(id)
      .then(data => {
        history.goBack();
        setShowAlert(false);
      })
      .catch(e => {
        setError(e.response.data.message);
        setShowAlert(false);
      });
  };

  let clientsListboxData = [];

  if (clients.length) {
    clients.map(c => {
      clientsListboxData.push({
        value: c.id,
        label: c.name,
      });
    });
  }

  const onSubmit = data => {
    let dataToSend = data;

    if (id != 'create') {
      dataToSend = extractObjectDifferences(
        data,
        contacts.find(contact => contact.id == id),
      );
    }
    if (!dataToSend) {
      dispatch(
        setNotification(
          notifications.WARNING_YELLOW,
          'Unable to update Contact',
          'No changes to the point of contact were detected.',
        ),
      );
    } else {
      handleSave(dataToSend);
    }
  };

  return (
    <TwoColumnForm
      onClick={handleSubmit(onSubmit)}
      button={true}
      buttonText={'Save'}
      buttonRed={id && id !== 'create' ? true : false}
      buttonRedText={'Delete'}
      onClickRed={setShowAlert.bind(this, true)}
      label="Contact Info"
      description=""
    >
      <InputSimple
        label={formLabels[labelArray[0]]}
        dataLabel={labelArray[0]}
        register={register(labelArray[0])}
        error={errors[labelArray[0]]?.message}
      />
      <InputSimple
        label={formLabels[labelArray[1]]}
        dataLabel={labelArray[1]}
        register={register(labelArray[1])}
        error={errors[labelArray[1]]?.message}
      />
      <InputSimple
        type="email"
        label={formLabels[labelArray[2]]}
        dataLabel={labelArray[2]}
        register={register(labelArray[2])}
        error={errors[labelArray[2]]?.message}
      />
      <InputSimple
        type="tel"
        label={formLabels[labelArray[3]]}
        dataLabel={labelArray[3]}
        register={register(labelArray[3])}
        error={errors[labelArray[3]]?.message}
      />
      <Controller
        control={control}
        name={labelArray[4]}
        render={({ field: { onChange, value, ref } }) => (
          <div className="flex flex-col">
            <div className="flex items-end">
              <ReactSelect
                label={'Client*'}
                isSearchable={true}
                selectedOptionsIds={clients.length && [clients.find(u => u.id === value)?.id]}
                onChange={c => onChange(c.value)}
                options={clientsListboxData}
                error={errors[labelArray[4]]?.message}
              />
              <RefreshButton onClick={fetchClients} />
            </div>

            <NewTabOpener link={'/admin-panel/admin/clients/create'} title="Add New Client" />
          </div>
        )}
      />
      <SimpleAlert
        errorTitle="Warning"
        errorMsg="Removing this will completely remove this point of contact, and will delete it from the application. Are you sure you want to proceed?"
        onAcceptText="Proceed"
        onAcceptClick={handleRemoveClick}
        onDeclineText="Cancel"
        show={showAlert}
        hide={() => setShowAlert(false)}
      />
      <SimpleAlert
        errorTitle="Error"
        errorMsg={error}
        onAcceptText="Ok"
        onAcceptClick={() => setError(false)}
        show={error}
        hide={() => setError(false)}
      />
    </TwoColumnForm>
  );
}

export default AddPointOfContact;
