import React, { useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import InputSimple from '../../../components/Inputs/InputSimple';
import TwoColumnForm from '../../../components/Layouts/TwoColumnForm';
import { useDispatch } from 'react-redux';
import SimpleAlert from '../../../components/Modals/SimpleAlert';
import InputDate from '../../../components/Inputs/InputDate/InputDate';
import Checkbox from '../../../components/Checkbox/Checkbox';
import { useForm, Controller } from 'react-hook-form';
import { useYupValidationResolver } from '../../../utils/hooks/useYupValidationResolver';
import * as Yup from 'yup';
import { VALIDATION_MSG, DATE_VALIDATION_MSG } from '../../../helpers/enum/errorValidationMsgs';
import NewTabOpener from '../../../components/NewTabOpener/NewTabOpener';
import RefreshButton from '../../../components/Buttons/RefreshButton';
import ReactSelect from '../../../components/Inputs/ReactSelect';
import dayjs from 'dayjs';
import { fwcTypeLabels, fwcTypes } from '../../../helpers/enum/fwcTypes';
import { showSuccessNotification } from '../../../store/app/actions';
import dateObjectsToString from '../../../utils/dateObjectsToString';
import useApi from '../../../hooks/useApi';
import extractObjectDifferences from '../../../helpers/extractObjectDifferences';
import SimpleEntry from '../../../components/DescriptionEntries/SimpleEntry';

function AddOrEditFrameworkContract({
  frameworkContracts,
  partners,
  banks,
  id,
  handleSave,
  fetchPartners,
  allRefs,
  fetchAllBanks,
}) {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    frameworkContracts: { deleteContract },
    purchaseOrders: { getStaffOrderAssociations },
  } = useApi();

  const [showAlert, setShowAlert] = useState(false);
  const [openEnded, setOpenEnded] = useState(false);
  const [staffOrders, setStaffOrders] = useState([]);

  const [startMax, endMin, startErrMsg, endErrMsg] = React.useMemo(() => {
    let FwcStartMax;
    let FwcEndMin;
    let startErrMsg;
    let endErrMsg;

    if (!staffOrders?.length) {
      FwcStartMax = null;
      FwcEndMin = null;
      startErrMsg = null;
      endErrMsg = null;
    } else {
      var minSpecificContractStart = new Date(
        Math.min.apply(
          null,
          staffOrders.map(e => new Date(e.specificContract.order_start)),
        ),
      );

      var maxSpecificContractEnd = Math.max.apply(
        null,
        staffOrders.map(e => new Date(e.specificContract.order_end)),
      );

      //In case order_ends are all null (open ended), do not create date object for validation, instead just pass null
      if (maxSpecificContractEnd != 0) {
        maxSpecificContractEnd = new Date(maxSpecificContractEnd);
      } else {
        maxSpecificContractEnd = null;
      }

      FwcStartMax = minSpecificContractStart;
      FwcEndMin = maxSpecificContractEnd;
      startErrMsg = `Start date cannot be after ${dayjs(FwcStartMax).format(
        'DD/MM/YYYY',
      )} due to conflict with specific contracts`;
      endErrMsg = `End date cannot be before ${dayjs(FwcEndMin).format('DD/MM/YYYY')} due to conflict with specific contracts`;
    }

    const startMax = FwcStartMax;
    const endMin = FwcEndMin;
    return [startMax, endMin, startErrMsg, endErrMsg];
  }, [staffOrders]);

  const frameworkContractRefs = React.useMemo(() => {
    let _frameworkContractRefs;
    if (id !== 'create') {
      _frameworkContractRefs = allRefs.filter(ref => ref !== frameworkContracts.find(fwc => fwc.id === Number(id))?.contract_ref);
    } else {
      _frameworkContractRefs = allRefs;
    }
    return _frameworkContractRefs;
  }, [allRefs]);

  const validationSchema = Yup.object().shape({
    contract_ref: Yup.string().required(VALIDATION_MSG).trim().notOneOf(frameworkContractRefs, 'Reference name already in use'),
    contract_start: staffOrders?.length
      ? Yup.date().required(VALIDATION_MSG).nullable().max(startMax, startErrMsg)
      : Yup.date().required(VALIDATION_MSG).nullable(),
    contract_end: openEnded
      ? ''
      : staffOrders?.length
      ? Yup.date().required(VALIDATION_MSG).nullable().min(endMin, endErrMsg)
      : Yup.date()
          .required(VALIDATION_MSG)
          .nullable()
          .when('contract_start', (contract_start, schema) => contract_start && schema.min(contract_start, DATE_VALIDATION_MSG)),
    clients: Yup.array().required(VALIDATION_MSG),
    type: Yup.number().required(VALIDATION_MSG),
    bank_account_id: Yup.number().required(VALIDATION_MSG),
  });

  const formOptions = { resolver: useYupValidationResolver(validationSchema) };

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    control,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm(formOptions);

  let partnersListboxData = [];

  if (partners.length) {
    partners.map(type => {
      partnersListboxData.push({
        value: type.id,
        label: type.name,
      });
    });
  }

  let banksListBoxData = useMemo(() => {
    let _banks = [];
    if (banks?.length) {
      banks.map(bank => {
        _banks.push({
          value: bank?.id,
          label: `${bank?.name} - ${bank?.iban}`,
        });
      });
    }
    return _banks;
  }, [banks]);

  let typesListboxData = [
    {
      value: fwcTypes.END_CLIENT,
      label: fwcTypeLabels[1],
    },
    {
      value: fwcTypes.SUBCO,
      label: fwcTypeLabels[2],
    },
  ];

  useEffect(() => {
    if (id && id !== 'create') {
      frameworkContracts.forEach(p => {
        if (p.id == id) {
          if (!p.contract_end) {
            setOpenEnded(true);
          }
          setValue('contract_ref', p.contract_ref);
          setValue('id', p.id);
          setValue('description', p.description);
          setValue('contract_start', p.contract_start);
          setValue('contract_end', p.contract_end);
          setValue(
            'clients',
            p.clients.map(i => i.id),
          );
          setValue('type', p.type);
          setValue('bank_account_id', p.bank_account_id);
        }
      });
    }
  }, [id, frameworkContracts]);

  useEffect(() => {
    if (id !== 'create') {
      getStaffOrderAssociations(id).then(res => {
        setStaffOrders(res.data);
      });
    }
  }, [id]);

  const onSubmit = data => {
    const dataWithFixedDates = dateObjectsToString(data);
    let initialFwC;
    let dataToSend = dataWithFixedDates;
    if (id && id !== 'create') {
      initialFwC = frameworkContracts.find(p => p.id == id);
    }

    if (initialFwC) {
      dataToSend = extractObjectDifferences(dataWithFixedDates, initialFwC);
    }
    handleSave(dataToSend);
  };

  //IMPORTANT: DONT CHANGE ORDER - ITS ORDER SENSITIVE
  const formLabels = {
    contract_ref: 'Contract reference*',
    description: 'Description',
    contract_start: 'Contract start*',
    contract_end: 'Contract end*',
    type: 'Type',
    clients: 'Clients*',
    bank_account_id: 'Bank account*',
  };

  const labelArray = Object.keys(formLabels);

  const handleRemoveClick = () => {
    deleteContract(id)
      .then(d => {
        dispatch(showSuccessNotification('Framework Contract deleted'));
        history.push(`/admin-panel/contracts/framework-contracts`);
        setShowAlert(false);
      })
      .catch(e => {
        setError(e.response.data.message);
        setShowAlert(false);
        // setShowAlert(e.response.data.message);
      });
  };

  const updateOpenEnded = e => {
    if (e.target.checked) {
      setValue('contract_end', null);
      clearErrors('contract_end');
    }
    setOpenEnded(e.target.checked);
  };

  const titleDescription =
    id == 'create'
      ? 'Add info for new framework contract to be created.'
      : 'Edit info for existing framework contract to be updated.';

  return (
    <TwoColumnForm
      onClick={handleSubmit(onSubmit)}
      button={true}
      buttonText={'Save'}
      label="Framework contract"
      description={titleDescription}
      onClickRed={() => setShowAlert(true)}
      buttonRed={id && id !== 'create' ? true : false}
      buttonRedText={'Delete'}
    >
      <SimpleAlert
        errorTitle="Delete Framework Contract?"
        errorMsg="Deleting will completely remove this Framework Contract from the application. Are you sure you want to proceed?"
        onAcceptText="Proceed"
        onAcceptClick={handleRemoveClick}
        onDeclineText="Cancel"
        show={showAlert}
        hide={() => setShowAlert(false)}
      />
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-1">
        <Controller
          control={control}
          name={labelArray[4]}
          render={({ field: { onChange, value, ref } }) => (
            <div className="flex flex-col">
              <div className="flex items-end">
                <ReactSelect
                  label={formLabels[labelArray[4]]}
                  options={typesListboxData}
                  selectedOptions={typesListboxData.filter(i => value == i.value)}
                  onChange={option => {
                    onChange(option.value);
                  }}
                  error={errors[labelArray[4]]?.message}
                />
              </div>
            </div>
          )}
        />
        <InputSimple
          label={formLabels[labelArray[0]]}
          dataLabel={labelArray[0]}
          error={errors[labelArray[0]]?.message}
          register={register(labelArray[0])}
        />

        <InputSimple
          label={formLabels[labelArray[1]]}
          dataLabel={labelArray[1]}
          error={errors[labelArray[1]]?.message}
          register={register(labelArray[1])}
        />

        <Controller
          control={control}
          name={labelArray[2]}
          render={({ field: { onChange, value, ref } }) => (
            <InputDate
              inputRef={ref}
              className="w-1/2"
              label={formLabels[labelArray[2]]}
              register={register(labelArray[2])}
              onChange={value => {
                onChange(value);
              }}
              selected={value && new Date(value)}
              error={errors[labelArray[2]]?.message}
            />
          )}
        />

        <div className="flex">
          <Controller
            control={control}
            name={labelArray[3]}
            render={({ field: { onChange, value, ref } }) => (
              <InputDate
                inputRef={ref}
                className="w-1/2"
                disabled={openEnded}
                label={formLabels[labelArray[3]]}
                register={register(labelArray[3])}
                onChange={value => {
                  onChange(value);
                }}
                selected={value && new Date(value)}
                error={errors[labelArray[3]]?.message}
              />
            )}
          />
          <Checkbox className="ml-4" value={openEnded} onChange={updateOpenEnded} title="Open Ended" />
        </div>

        <Controller
          control={control}
          name={labelArray[5]}
          render={({ field: { onChange, value, ref } }) => (
            <div className="flex flex-col">
              <div className="flex items-end">
                <ReactSelect
                  label={formLabels[labelArray[5]]}
                  isMulti
                  isSearchable
                  options={partnersListboxData}
                  selectedOptions={partnersListboxData.filter(i => {
                    return value?.some(id => id === i.value);
                  })}
                  onChange={c => {
                    onChange(c?.map(option => option.value));
                  }}
                  error={errors[labelArray[5]]?.message}
                />
                <RefreshButton onClick={fetchPartners} />
              </div>

              <NewTabOpener link={'/admin-panel/admin/clients/create'} title="Add New Client" />
            </div>
          )}
        />
        <Controller
          control={control}
          name={labelArray[6]}
          render={({ field: { onChange, value, ref } }) => (
            <div className="flex flex-col">
              <div className="flex items-end">
                <ReactSelect
                  label={formLabels[labelArray[6]]}
                  options={banksListBoxData}
                  selectedOptions={banksListBoxData.filter(i => value == i.value)}
                  onChange={option => {
                    onChange(option.value);
                  }}
                  error={errors[labelArray[6]]?.message}
                />
                <RefreshButton onClick={() => fetchAllBanks()} />
              </div>
              <NewTabOpener link={'/admin-panel/admin/bank-accounts/create'} title="Add new Bank account" />
            </div>
          )}
        />
      </dl>
    </TwoColumnForm>
  );
}

export default AddOrEditFrameworkContract;
