import { useMemo, useRef } from 'react';
import { lineItemTypesV2 } from '../../helpers/enum/lineItemTypes';
import formatCurrency from '../../utils/formatCurrency';
import formatNumber from '../../utils/formatNumber';
import ButtonPrimary from '../Buttons/ButtonPrimary';
import ButtonWhite from '../Buttons/ButtonWhite';
import Checkbox from '../Checkbox/Checkbox';
import InputSimple from '../Inputs/InputSimple';
import ReactSelect from '../Inputs/ReactSelect';
import TextArea from '../Inputs/TextArea';
import * as countryID from '../../helpers/enum/CountryIDs';
import Error from '../Error/Error';
import PillShapedButton from '../Buttons/PillShapedButton';
import { PlusIcon, XCircleIcon } from '@heroicons/react/solid';
import allocationTypes from '../../helpers/enum/allocationTypes';
import { useLocation } from 'react-router-dom';

const AccountsPayableLineItemEdits = ({
  availableLines,
  totals,
  customLine,
  setCustomLine,
  formErrors,
  handleCheckAll,
  handleCheckLine,
  saveCustomLine,
  activeStaffOrders,
  checkAll,
  setShowEditDecimalPlaces,
  handleDeleteAdjustmentLine,
}) => {
  const location = useLocation();
  const isAdminPage = useMemo(() => {
    return location.pathname.includes('my') ? false : true;
  }, [location]);
  const checkAllRef = useRef();
  const customLineCanBeAdded = useMemo(() => {
    let canBeAdded = false;
    if (
      customLine?.description &&
      customLine?.staff_order_id &&
      customLine?.price &&
      customLine?.units &&
      customLine?.uom &&
      customLine?.vat_rate !== null &&
      customLine?.vat_rate !== undefined
    )
      canBeAdded = true;
    return canBeAdded;
  }, [customLine]);

  const orderRefChangeHandler = e => {
    const po = activeStaffOrders.find(so => so.id === e?.value);

    setCustomLine(prev => ({
      ...prev,
      vat_rate: po ? (po.staffContract.country_id === countryID.belgium ? 21 : 0) : null,
      staffOrder: {
        ...po,
      },
      staff_order_id: e?.value || null,
    }));
  };

  function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  const customChangeHandler = (e, key) => {
    setCustomLine(prev => ({ ...prev, [key]: e?.value || e?.target?.value }));
  };

  const canChangeLineItemDecimalPlaces = li => {
    let acceptedType =
      li.type === lineItemTypesV2.accountsPayable.numbers.projectAllocations ||
      li.type === lineItemTypesV2.accountsPayable.numbers.projectAllocationAccountReceivableAdjustment ||
      li.type === lineItemTypesV2.accountsPayable.numbers.projectAllocationCreditNoteAdjustment ||
      li.type === lineItemTypesV2.accountsPayable.numbers.unapprovedProjectAllocations;

    return acceptedType && li?.uom !== 'hours' && isAdminPage;
  };

  const canBeDeleted = li => {
    let isAdjustmentLine =
      li.type === lineItemTypesV2.accountsPayable.numbers.projectAllocationAccountReceivableAdjustment ||
      li.type === lineItemTypesV2.accountsPayable.numbers.projectAllocationCreditNoteAdjustment;

    let hasNoAP = !li.accounts_payable_id;
    return isAdjustmentLine && isAdminPage && hasNoAP;
  };

  const deleteAdjustmentLine = li => {
    if (canBeDeleted(li)) {
      handleDeleteAdjustmentLine(li.id);
    }
  };

  return (
    <>
      <div className="w-full flex justify-between items-center mb-4">
        <span className="text-lg font-medium leading-6 text-gray-900 flex gap-x-2 ">
          Invoice lines{' '}
          {formErrors?.find(e => e.field === 'invoiceLines') && (
            <Error message={formErrors?.find(e => e.field === 'invoiceLines')?.msg} />
          )}
        </span>
        <PillShapedButton
          text="Add line"
          size="s"
          icon={<PlusIcon className="w-4 h-4" />}
          disable={customLine !== null}
          onClick={() =>
            setCustomLine({
              uuid: generateUUID(),
              staffOrder: {
                order_ref: null,
              },
              units: null,
              uom: null,
              price: null,
              total: 0,
              vat: 0,
              vat_rate: null,
              total_and_vat: 0,
              staff_order_id: null,
              type: allocationTypes.billable,
              checked: true,
            })
          }
        />
      </div>
      <div className="border border-gray-300 rounded-md">
        <div className="table-wrapper overflow-x-auto">
          <table className="w-full ap-table">
            <thead>
              <tr className="text-md">
                <th className="ap-table-head-th text-center">
                  <Checkbox
                    ref={checkAllRef}
                    value={checkAll}
                    indeterminate={availableLines.some(line => line.checked) && !checkAll}
                    onChange={() => handleCheckAll()}
                    horizontal
                    className="ml-1"
                  />
                </th>
                <th className="ap-table-head-th text-center">Description</th>
                <th className="ap-table-head-th text-center">Purchase order</th>
                <th className="ap-table-head-th text-center">Type</th>
                <th className="ap-table-head-th text-center">Units</th>
                <th className="ap-table-head-th text-center">UoM</th>
                <th className="ap-table-head-th text-center">Price</th>
                <th className="ap-table-head-th text-center">VAT rate</th>
                <th className="ap-table-head-th text-center">Total Excl VAT</th>
                <th className="ap-table-head-th text-center">VAT</th>
                <th className="ap-table-head-th text-center">Total Incl VAT</th>
                {/* Just used for column alignment purposes */}
                {availableLines.some(li => canBeDeleted(li)) && (
                  <th className="ap-table-head-th text-center w-0">
                    <XCircleIcon className="w-5 h-5 invisible" />
                  </th>
                )}
              </tr>
            </thead>
            <tbody className="text-sm">
              {availableLines?.length ? (
                availableLines.map((li, i) => (
                  <tr className={`${li.checked ? 'rounded-md bg-thaleria-orange-100' : ''} my-1 h-8`} key={li?.id || li?.uuid}>
                    <td className="ap-table-body-td text-center whitespace-nowrap flex">
                      <Checkbox
                        value={li.checked}
                        horizontal
                        id={li.id || li.uuid}
                        onChange={e => handleCheckLine(i)}
                        className="ml-1"
                      />
                    </td>
                    <td className="ap-table-body-td text-center whitespace-nowrap">{li.description}</td>
                    <td className="ap-table-body-td text-center whitespace-nowrap">{li.staffOrder.order_ref}</td>
                    <td className="ap-table-body-td text-center whitespace-nowrap">
                      {lineItemTypesV2.accountsPayable.strings[li.type]}
                    </td>
                    <td
                      className={`text-center whitespace-nowrap ${
                        canChangeLineItemDecimalPlaces(li) && 'hover:underline text-blue-500 hover:text-blue-300 cursor-pointer'
                      } items-center`}
                      onClick={canChangeLineItemDecimalPlaces(li) ? () => setShowEditDecimalPlaces(i) : undefined}
                    >
                      {formatNumber(li.units, true, 6)}{' '}
                    </td>
                    <td className="ap-table-body-td text-center whitespace-nowrap">{li.uom}</td>
                    <td className="ap-table-body-td text-center whitespace-nowrap">{formatCurrency(li.price)}</td>
                    <td className="ap-table-body-td text-center whitespace-nowrap">{formatNumber(li.vat_rate, true) + '%'}</td>
                    <td className="ap-table-body-td text-center whitespace-nowrap">{formatCurrency(li.total)}</td>
                    <td className="ap-table-body-td text-center whitespace-nowrap">{formatCurrency(li.vat)}</td>
                    <td className="ap-table-body-td text-center whitespace-nowrap">{formatCurrency(li.total_and_vat)}</td>
                    {availableLines.some(li => canBeDeleted(li)) && (
                      <td className="ap-table-body-td text-center whitespace-nowrap">
                        {canBeDeleted(li) && (
                          <XCircleIcon
                            className="cursor-pointer w-5 h-5 text-gray-400 hover:text-red-400"
                            onClick={() => deleteAdjustmentLine(li)}
                          />
                        )}
                      </td>
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={11} className="text-center py-1">
                    No available lines to invoice
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {customLine && (
          <div className="custom-line-form mt-4">
            <form className="grid grid-cols-1 gap-4 px-4">
              <div className="flex flex-col">
                <TextArea
                  label="Description*"
                  value={customLine.description}
                  onChange={e => customChangeHandler(e, 'description')}
                />
              </div>
              <div className="flex flex-col">
                <ReactSelect
                  label="Purchase order*"
                  selectedOptionsIds={[customLine.staff_order_id]}
                  options={activeStaffOrders.map(so => ({
                    label: so?.order_ref,
                    value: so?.id,
                  }))}
                  onChange={e => orderRefChangeHandler(e, 0)}
                  isSearchable
                />
              </div>
              <div className="flex flex-col">
                <ReactSelect
                  label="Type*"
                  selectedOptionsIds={[customLine.type]}
                  options={lineItemTypesV2.accountsPayable.reactSelectOptions}
                  onChange={e => customChangeHandler(e, 'type')}
                  isSearchable
                />
              </div>
              <div className="flex flex-col">
                <InputSimple
                  type="number"
                  label="Units*"
                  value={customLine.units}
                  onChange={e => customChangeHandler(e, 'units')}
                />
              </div>
              <div className="flex flex-col">
                <InputSimple
                  label="UoM* (units of measurement)"
                  value={customLine.uom}
                  onChange={e => customChangeHandler(e, 'uom')}
                />
              </div>
              <div className="flex flex-col">
                <InputSimple
                  label="Price*"
                  type="number"
                  value={customLine.price}
                  onChange={e => customChangeHandler(e, 'price')}
                />
              </div>
              <div className="flex flex-col">
                <InputSimple
                  type="number"
                  label="VAT rate*"
                  value={customLine.vat_rate}
                  onChange={e => customChangeHandler(e, 'vat_rate')}
                  disabled={!isAdminPage}
                />
              </div>
            </form>
            <div className="flex justify-end items-end space-x-3 col-span-1 p-4">
              <div className="flex flex-col gap-y-6">
                <div className="flex gap-x-2 mt-4 justify-end">
                  <span>
                    <span className="font-semibold">Price:</span> {formatCurrency(customLine?.units * customLine?.price || 0)}
                  </span>
                  <span>
                    <span className="font-semibold">VAT:</span>{' '}
                    {formatCurrency(customLine?.units * customLine?.price * (customLine?.vat_rate / 100) || 0)}
                  </span>
                  <span>
                    <span className="font-semibold">Total incl VAT:</span>{' '}
                    {formatCurrency(
                      customLine?.units * customLine?.price +
                        customLine?.units * customLine?.price * (customLine?.vat_rate / 100) || 0,
                    )}
                  </span>
                </div>
                <div className="flex gap-x-4 justify-end">
                  <ButtonWhite text={'Cancel'} onClick={() => setCustomLine(null)} />
                  <ButtonPrimary text="Add" onClick={() => saveCustomLine()} disable={!customLineCanBeAdded} />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="flex flex-col gap-y-2 mt-4">
        <span className="font-semibold text-lg my-2">Summary</span>
        <span>
          <span className="font-semibold">Total:</span> {formatCurrency(totals?.total)}
        </span>
        <span>
          <span className="font-semibold">VAT:</span> {formatCurrency(totals?.vat)}
        </span>
        <span>
          <span className="font-semibold">Grand total:</span> {formatCurrency(totals?.total_and_vat)}
        </span>
      </div>
    </>
  );
};

export default AccountsPayableLineItemEdits;
