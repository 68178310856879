import React from 'react';
import SimpleEntry from '../../../components/DescriptionEntries/SimpleEntry';
import dayjs from 'dayjs';

function UserProfile({ user }) {
  return (
    <dl className="grid grid-cols-1 gap-y-8 sm:grid-cols-2 py-6 px-8">
      <SimpleEntry label={'First names'} data={user.first_names || '-'} />
      <SimpleEntry label={'Last names'} data={user.last_names || '-'} />
      <SimpleEntry label={'Preferred name'} data={user.preferred_name || '-'} />
      <SimpleEntry label={'Email'} data={user.email || '-'} />
      <SimpleEntry label={'Phone'} data={user.phone_nb || '-'} />
      <SimpleEntry label={'Secondary phone'} data={user.secondary_phone_nb || '-'} />
      <SimpleEntry label={'Birthday'} data={user?.birthday ? dayjs(user.birthday).format('MMMM D, YYYY') : '-'} />
      <SimpleEntry label={'Address'} data={user.address || '-'} />
      <SimpleEntry label={'Country'} data={user.country || '-'} />
      <SimpleEntry label={'ID number'} data={user.id_nb || '-'} />
      <SimpleEntry label={'HR contact'} data={user.hr_contact || '-'} />
      <SimpleEntry label={'Account type'} data={user.accountType ? user.accountType.account_type : '-'} />
      <SimpleEntry label={'Status'} data={user.active ? 'Active' : 'Not active'} />
    </dl>
  );
}

export default UserProfile;
