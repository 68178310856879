import React from 'react';
import dayjs from 'dayjs';
import useApi from '../../hooks/useApi';
import { Link } from 'react-router-dom';

const NotificationItemForTable = ({ index, item, setToRead, setNotificationAsRead }) => {
  let resources = JSON.parse(item.resources);

  const myLink = React.forwardRef(({ navigate, ...props }, ref) => {
    return (
      <a href={ref} {...props} onAuxClick={() => setNotificationAsRead(item.id)} onClick={() => setNotificationAsRead(item.id)}>
        {props.children}
      </a>
    );
  });

  return (
    <li className={`group hover:bg-gray-100 mb-[-2px] flex items-center px-2 py-2`}>
      <div className="w-5 self-start mt-[5px] flex flex-col justify-center">
        {!item.read && (
          <div
            className={`rounded-full w-2.5 h-2.5 mt-4 ${
              item.read ? 'hidden' : 'bg-thaleria-orange-700'
            } hover:bg-thaleria-orange-500 cursor-pointer`}
            onClick={() => setNotificationAsRead(item.id)}
          />
        )}
      </div>
      <Link to={resources.href} component={myLink} className={` text-gray-600 text-sm w-full`}>
        {/* {index !== 0 && <hr className="border-t border-gray-100"></hr>} */}
        <div className={` flex flex-col md:flex-row justify-between w-full space-y-0.5 py-4`}>
          <p className="flex-grow ">{item.message}</p>
          <p className="flex flex-shrink-0 justify-start text-xs text-gray-400">
            {dayjs(item.created_at).format('MMM DD, YYYY') + ' at ' + dayjs(item.created_at).format('HH:mm')}
          </p>
        </div>
      </Link>
    </li>
  );
};

export default NotificationItemForTable;
