import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import InputSimple from '../../../components/Inputs/InputSimple';
import TwoColumnForm from '../../../components/Layouts/TwoColumnForm';
import { useDispatch } from 'react-redux';
import SimpleAlert from '../../../components/Modals/SimpleAlert';
import InputDate from '../../../components/Inputs/InputDate/InputDate';
import Checkbox from '../../../components/Checkbox/Checkbox';
import { useForm, Controller } from 'react-hook-form';
import { useYupValidationResolver } from '../../../utils/hooks/useYupValidationResolver';
import * as Yup from 'yup';
import {
  DATE_VALIDATION_MSG,
  VALIDATION_MSG,
  MAX_START_VALIDATION_MSG,
  MIN_END_VALIDATION_MSG,
} from '../../../helpers/enum/errorValidationMsgs';
import NewTabOpener from '../../../components/NewTabOpener/NewTabOpener';
import RefreshButton from '../../../components/Buttons/RefreshButton';
import { showSuccessNotification } from '../../../store/app/actions';
import { isAfter, isBefore, format, endOfDay, startOfDay } from 'date-fns';
import dateObjectsToString from '../../../utils/dateObjectsToString';
import useApi from '../../../hooks/useApi';
import extractObjectDifferences from '../../../helpers/extractObjectDifferences';
import ReactSelect from '../../../components/Inputs/ReactSelect';
import { staffContractType } from '../../../helpers/enum/staffContractType';
import InputRadio from '../../../components/Inputs/InputRadio';
import countryID from '../../../helpers/enum/CountryIDs';
import Toggle from '../../../components/Toggles/Toggle';
import SimpleEntry from '../../../components/DescriptionEntries/SimpleEntry';
import DividerWithLabel from '../../../components/Dividers/DividerWithLabel';
import TextArea from '../../../components/Inputs/TextArea';
import formatStringToIban from '../../../helpers/formatStringToIban';

function AddStaffContract({ staffContract, users, id, handleSave, contractTypes, updateCountries, countries }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    staffContracts: { deleteContract, fetchAssociatedPOs, fetchEstimatedLeaveDayBalance },
  } = useApi();

  const isCreate = id === 'create';
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState(false);
  const [openEnded, setOpenEnded] = useState(false);
  const [isCompany, setIsCompany] = useState(false);
  const [isNotEmployee, setIsNotEmployee] = useState(undefined);
  const [maxStartDate, setmaxStartDate] = useState();
  const [minEndDate, setMinEndDate] = useState();
  const [isBelgium, setIsBelgium] = useState();
  const [customizeLeaveDayBalance, setCustomizeLeaveDayBalance] = useState(false);
  const [shouldSeeLeaveDayBalance, setShouldSeeLeaveDayBalance] = useState(false);
  const [latestBalance, setLatestBalance] = useState(false);
  const [internalTeam, setInternalTeam] = useState(false);
  const validationSchema = Yup.object().shape({
    contract_type_id: Yup.number().required(VALIDATION_MSG),
    user_id: Yup.string().required(VALIDATION_MSG),
    country_id: Yup.number().required(VALIDATION_MSG),
    calendar_country_id: Yup.number().when('contract_type_id', {
      is: value => value !== staffContractType.EMPLOYEE,
      then: Yup.number().required(VALIDATION_MSG),
      otherwise: Yup.number().nullable(true), // If it's EMPLOYEE, allow null
    }),
    contract_ref: Yup.string().required(VALIDATION_MSG).trim(),
    contract_start: maxStartDate
      ? Yup.date().max(maxStartDate, MAX_START_VALIDATION_MSG).required(VALIDATION_MSG).nullable()
      : Yup.date().required(VALIDATION_MSG).nullable(),
    contract_end: openEnded
      ? ''
      : minEndDate
      ? Yup.date().min(minEndDate, MIN_END_VALIDATION_MSG).required(VALIDATION_MSG).nullable()
      : Yup.date()
          .required(VALIDATION_MSG)
          .nullable()
          .when('contract_start', (contract_start, schema) => {
            return contract_start && schema.min(contract_start, DATE_VALIDATION_MSG);
          }),
    position: Yup.string().required(VALIDATION_MSG).trim(),
    hours_per_week: Yup.number().integer().min(38).max(40).nullable(),
    //IBAN should consist of 2-letter country code followed by 2 digits, and additional bank and account numbers.
    iban: Yup.string()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .nullable(true),

    //SWIFT code should consist of 8 or 11 alphanumeric characters.
    bic_swift: Yup.string()
      .matches(/^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/, 'Invalid SWIFT Code')
      .transform((curr, orig) => (orig === '' ? null : curr))
      .nullable(true),
    //VAT number should start with 2-letter country code followed by alphanumeric characters.
    vat_nb: Yup.string()
      .matches(/^(?:[A-Za-z]{2}\s?)?[0-9A-Za-z\-\.]{2,15}$/, 'Invalid VAT Number')
      .transform((curr, orig) => (orig === '' ? null : curr))
      .nullable(true),
    internal_team: Yup.boolean().nullable(),
  });

  const formOptions = { resolver: useYupValidationResolver(validationSchema) };

  const {
    register,
    handleSubmit,
    setValue,
    control,
    clearErrors,
    getValues,
    formState: { errors },
    watch,
  } = useForm(formOptions);

  useEffect(() => {
    const input = watch('iban');
    if (!input) return;
    if (input !== formatStringToIban(input)) setValue('iban', formatStringToIban(input));
  }, [watch('iban')]);

  useEffect(() => {
    if (id && !isCreate && staffContract) {
      if (staffContract.id == id) {
        if (!staffContract.contract_end) {
          setOpenEnded(true);
        }
        setIsBelgium(staffContract.country_id == countryID.belgium);
        setIsNotEmployee(staffContract.contract_type_id !== staffContractType.EMPLOYEE);
        setIsCompany(staffContract.contract_type_id !== staffContractType.COMPANY ? false : true);
        if (staffContract.leave_day_balance_log.length) setLatestBalance(true);
        setValue('contract_ref', staffContract.contract_ref);
        setValue('id', staffContract.id);
        setValue('user_id', staffContract.userId);
        setValue('position', staffContract.position);
        setValue('contract_start', staffContract.contract_start);
        setValue('contract_end', staffContract.contract_end);
        setValue('country_id', staffContract.country_id);
        setValue('contract_type_id', staffContract.contract_type_id);
        setValue('vat_nb', staffContract.vat_nb);
        setValue('iban', staffContract.iban);
        setValue('bic_swift', staffContract.bic_swift);
        setValue('company_name', staffContract.company_name);
        setValue('company_address', staffContract.company_address);
        setValue('hours_per_week', staffContract.hours_per_week);
        setValue('internal_team', staffContract.internal_team);
        setInternalTeam(staffContract?.internal_team ? staffContract.internal_team : false);
        if (staffContract.leave_day_balance_log.length) {
          setValue('leave_days', staffContract.leave_day_balance_log[0]?.leave_days_balance);
          setValue('recuperation_days', staffContract.leave_day_balance_log[0]?.recuperation_days_balance);
        }
        if (staffContract.contract_type_id !== staffContractType.EMPLOYEE) {
          setValue('calendar_country_id', staffContract.calendar_country_id);
        }

        fetchAssociatedPOs(id).then(res => {
          if (!res?.length) return;
          let maxStartDate = res[0].order_start;
          let minEndDate = res[0].order_end;
          res.forEach(order => {
            if (isBefore(new Date(order.order_start), new Date(maxStartDate))) maxStartDate = order.order_start;
            if (isAfter(new Date(order.order_end), new Date(minEndDate))) minEndDate = order.order_end;
          });
          setmaxStartDate(endOfDay(new Date(maxStartDate)));
          setMinEndDate(minEndDate ? startOfDay(new Date(minEndDate)) : null);
        });
      }
    }
  }, [id, staffContract]);

  //we cant estimate leave day balance if there are missing required fields or if the contract has already ended
  const cannotEstimateLeaveDayBalance =
    !getValues('country_id') || !getValues('contract_start') || (!openEnded && !getValues('contract_end'));
  const contractIsInPast = !openEnded && new Date(getValues('contract_end')) < new Date();

  const estimateLeaveDayBalance = () => {
    const hoursPerWeek = getValues('hours_per_week');
    const countryId = getValues('country_id');
    const contractStart = getValues('contract_start');
    const contractEnd = getValues('contract_end');

    //we don't want to estimate balances for contracts that have already ended or for those without required info
    if (cannotEstimateLeaveDayBalance || contractIsInPast) {
      setValue('leave_days', null);
      setValue('recuperation_days', null);
      return;
    }

    fetchEstimatedLeaveDayBalance({
      country_id: countryId,
      contract_start: format(new Date(contractStart), 'yyyy-MM-dd'),
      contract_end: openEnded ? null : format(new Date(contractEnd), 'yyyy-MM-dd'),
      hours_per_week: isBelgium && hoursPerWeek == null ? 40 : hoursPerWeek,
    }).then(res => {
      setValue('leave_days', res.leave_days_balance);
      setValue('recuperation_days', res.recuperation_days_balance);
    });
  };

  useEffect(() => {
    if (isNotEmployee === false) {
      setShouldSeeLeaveDayBalance(true);
    } else {
      setValue('leave_days', null);
      setValue('recuperation_days', null);
      return setShouldSeeLeaveDayBalance(false);
    }

    if (customizeLeaveDayBalance) return;

    if (isCreate) {
      //estimate new contract initial balance
      estimateLeaveDayBalance();
    } else {
      //pre-existing contract
      if (latestBalance) {
        //add existing balance

        setValue('leave_days', staffContract.leave_day_balance_log[0].leave_days_balance);
        setValue('recuperation_days', staffContract.leave_day_balance_log[0].recuperation_days_balance);
      } else {
        //estimate new balance
        estimateLeaveDayBalance();
      }
    }
  }, [
    getValues('country_id'),
    getValues('hours_per_week'),
    watch().contract_start,
    watch().contract_end,
    openEnded,
    isNotEmployee,
    customizeLeaveDayBalance,
  ]);

  const formLabels = {
    user_id: 'User',
    contract_type_id: 'Contract Type*',
    country_id: 'Country*',
    position: 'Position*',
    contract_ref: 'Contract reference*',
    contract_start: 'Contract start*',
    contract_end: 'Contract end*',
    vat_nb: 'Vat No',
    iban: 'IBAN',
    bic_swift: 'BIC Swift',
    company_name: 'Company Name',
    company_address: 'Company Address',
    hours_per_week: 'Hours per week',
    leave_days: 'Leave days',
    recuperation_days: 'Recuperation days',
    comment: 'Comment',
    calendar_country_id: 'Holiday calendar*',
    internal_team: 'Internal team',
  };

  const labelArray = Object.keys(formLabels);

  const handleRemoveClick = () => {
    deleteContract(id)
      .then(d => {
        dispatch(showSuccessNotification('Staff Contract deleted'));
        history.push(`/admin-panel/contracts/staff-contracts`);
        setShowAlert(false);
      })
      .catch(e => {
        setError(e.response.data.message);
        setShowAlert(false);
      });
  };

  const countriesListboxData = useMemo(() => {
    let array = [];
    if (countries?.length) {
      countries.forEach(c => {
        array.push({
          value: c.id,
          label: c.name,
        });
      });
    }
    return array;
  }, [countries]);

  const contractsListboxData = useMemo(() => {
    let array = [];
    if (contractTypes?.length) {
      contractTypes.forEach(type => {
        array.push({
          value: type.id,
          label: type.contract_type,
        });
      });
    }
    return array;
  }, [contractTypes]);

  const usersListboxData = useMemo(() => {
    let array = [];
    if (users?.length) {
      users.forEach(user => {
        array.push({
          value: user.id,
          label: user.full_name,
        });
      });
    }
    return array;
  }, [users]);

  const hoursPerWeekOptions = [38, 39, 40];

  const updateOpenEnded = e => {
    if (e.target.checked) {
      setValue('contract_end', null);
      clearErrors('contract_end');
    }
    setOpenEnded(e.target.checked);
  };
  const updateInternalTeam = e => {
    if (e.target.checked) {
      setValue('internal_team', true);
      clearErrors('internal_team');
    }
    setInternalTeam(e.target.checked);
  };

  const onSubmit = data => {
    const dataWithFixedDates = dateObjectsToString(data);
    let initialStaffContract;
    let dataToSend = dataWithFixedDates;
    if (id && !isCreate) {
      initialStaffContract = staffContract;
    }

    if (initialStaffContract) {
      dataToSend = extractObjectDifferences(dataWithFixedDates, initialStaffContract);
    }

    if (!customizeLeaveDayBalance || isNotEmployee) {
      delete dataToSend.leave_days;
      delete dataToSend.recuperation_days;
      delete dataToSend.comment;
    } else {
      if (dataToSend.comment == '' || dataToSend.comment == null) {
        delete dataToSend.comment;
      }
    }

    if (isNotEmployee == false && isBelgium && dataToSend.hours_per_week == null) {
      dataToSend.hours_per_week = 40;
    }

    if (Object.keys(dataToSend).length) {
      handleSave(dataToSend);
    } else {
      alert('No changes have been made.');
    }
  };

  let leaveDaysTooltipMsg = '';
  let recupDaysTooltipMsg = '';

  if (isBelgium) {
    leaveDaysTooltipMsg = (
      <ol>
        <p className="text-md font-semibold my-1">Belgium</p>
        <li>&#8226; 20 leave days per year</li>
        <li>&#8226; Calculated pro-rata based on days worked the year prior</li>
      </ol>
    );
    recupDaysTooltipMsg = (
      <ol>
        <p className="text-md font-semibold my-1">Belgium</p>
        <li>&#8226; 1 recuperation day per month</li>
        <li>&#8226; Calculated based on hours worked per week</li>
        <li>&#8226; 40 hours grants 1 day</li>
        <li>&#8226; 39 hours grants 0.5 day</li>
      </ol>
    );
  } else {
    leaveDaysTooltipMsg = (
      <ol>
        <p className="text-md font-semibold my-1">Default</p>
        <li>&#8226; 25 leave days per year</li>
        <li>&#8226; Calculated pro-rata based on days worked that same year</li>
      </ol>
    );
    recupDaysTooltipMsg = (
      <ol>
        <p className="text-md font-semibold my-1">Default</p>
        <li>&#8226; No recuperation days awarded outside Belgium</li>
      </ol>
    );
  }

  const titleDescription =
    id == 'create' ? 'Add info for new staff contract to be created.' : 'Edit info for existing staff contract to be updated.';

  return (
    <TwoColumnForm
      onClick={handleSubmit(onSubmit)}
      button={true}
      buttonText={'Save'}
      label="Staff contract"
      description={titleDescription}
      onClickRed={() => setShowAlert(true)}
      buttonRed={id && id !== 'create' ? true : false}
      buttonRedText={'Delete'}
    >
      <SimpleAlert
        errorTitle="Delete Staff Contract?"
        errorMsg="Deleting will permanently remove this staff contract from the application. Are you sure you want to proceed?"
        onAcceptText="Proceed"
        onAcceptClick={handleRemoveClick}
        onDeclineText="Cancel"
        show={showAlert}
        hide={() => setShowAlert(false)}
      />
      <SimpleAlert
        errorTitle="Error"
        errorMsg={error}
        onAcceptText="Ok"
        onAcceptClick={() => setError(false)}
        show={error ? true : false}
        hide={() => setError(false)}
      />
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        <Controller
          control={control}
          name={labelArray[0]}
          render={({ field: { onChange, value } }) => (
            <ReactSelect
              isSearchable
              label={'User*'}
              disabled={!isCreate}
              placeholder="Select user"
              options={usersListboxData}
              selectedOptions={usersListboxData?.find(u => u.value === value)}
              onChange={c => onChange(c.value)}
              error={errors[labelArray[0]]?.message}
            />
          )}
        />
        <div className="flex justify-between">
          <Controller
            control={control}
            name={labelArray[1]}
            render={({ field: { onChange, value } }) => (
              <ReactSelect
                label={'Contract Type*'}
                disabled={!isCreate}
                placeholder="Select contract type"
                options={contractsListboxData}
                selectedOptions={contractsListboxData?.find(u => u.value === value)}
                onChange={c => {
                  setIsCompany(false);
                  setIsNotEmployee(false);
                  onChange(c.value);
                  if (c.value == staffContractType.COMPANY) {
                    setIsCompany(true);
                  }
                  if (c.value != staffContractType.EMPLOYEE) {
                    setIsNotEmployee(true);
                  }
                }}
                error={errors[labelArray[1]]?.message}
              />
            )}
          />
          <Checkbox
            disabled={!isCreate}
            className="ml-4"
            value={internalTeam}
            onChange={updateInternalTeam}
            title="Internal team"
          />
        </div>
        <Controller
          control={control}
          name={labelArray[2]}
          render={({ field: { onChange, value } }) => (
            <div className="flex flex-col">
              <div className="flex items-end">
                <ReactSelect
                  isSearchable
                  label={`${isNotEmployee ? 'Billing country*' : 'Country*'}`}
                  disabled={!isCreate && !isNotEmployee}
                  placeholder="Select country"
                  options={countriesListboxData}
                  selectedOptions={countriesListboxData?.find(u => u.value === value)}
                  onChange={c => {
                    onChange(c.value);
                    setIsBelgium(c.value == countryID.belgium);
                  }}
                  error={errors[labelArray[2]]?.message}
                />
                <RefreshButton onClick={updateCountries} />
              </div>

              <NewTabOpener link={'/admin-panel/admin/countries/create'} title="Add New Country" />
            </div>
          )}
        />
        {isNotEmployee && (
          <Controller
            control={control}
            name={labelArray[16]}
            render={({ field: { onChange, value } }) => (
              <div className="flex flex-col">
                <div className="flex items-end">
                  <ReactSelect
                    isSearchable
                    label={'Holiday calendar*'}
                    disabled={!isCreate && !isNotEmployee}
                    placeholder="Select country"
                    options={countriesListboxData}
                    selectedOptions={countriesListboxData?.find(u => u.value === value)}
                    onChange={c => {
                      onChange(c ? c.value : null);
                    }}
                    error={errors[labelArray[16]]?.message}
                    isClearable
                  />
                  <RefreshButton onClick={updateCountries} />
                </div>

                <NewTabOpener link={'/admin-panel/admin/countries/create'} title="Add New Country" />
              </div>
            )}
          />
        )}

        <InputSimple
          label={formLabels[labelArray[3]]}
          dataLabel={labelArray[3]}
          error={errors[labelArray[3]]?.message}
          register={register(labelArray[3])}
        />

        <InputSimple
          label={formLabels[labelArray[4]]}
          dataLabel={labelArray[4]}
          error={errors[labelArray[4]]?.message}
          register={register(labelArray[4])}
        />
        <Controller
          control={control}
          name={labelArray[5]}
          render={({ field: { onChange, value } }) => (
            <InputDate
              className="w-full"
              label={formLabels[labelArray[5]]}
              onChange={value => {
                onChange(value);
              }}
              selected={value && new Date(value)}
              error={errors[labelArray[5]]?.message}
            />
          )}
        />

        <div className="flex justify-between">
          <Controller
            control={control}
            name={labelArray[6]}
            render={({ field: { onChange, value } }) => (
              <InputDate
                className="w-full"
                disabled={openEnded}
                label={formLabels[labelArray[6]]}
                onChange={value => {
                  onChange(value);
                }}
                selected={value && new Date(value)}
                error={errors[labelArray[6]]?.message}
              />
            )}
          />
          <Checkbox className="ml-4" value={openEnded} onChange={updateOpenEnded} title="Open Ended" />
        </div>
        <InputSimple
          label={formLabels[labelArray[8]]}
          error={errors[labelArray[8]]?.message}
          register={register(labelArray[8])}
          dataLabel={labelArray[8]}
        />
        <InputSimple
          label={formLabels[labelArray[9]]}
          error={errors[labelArray[9]]?.message}
          register={register(labelArray[9])}
          dataLabel={labelArray[9]}
        />
        {isNotEmployee && (
          <InputSimple
            label={formLabels[labelArray[7]]}
            error={errors[labelArray[7]]?.message}
            register={register(labelArray[7])}
            dataLabel={labelArray[7]}
          />
        )}
        {isCompany && (
          <>
            <InputSimple
              label={formLabels[labelArray[10]]}
              error={errors[labelArray[10]]?.message}
              register={register(labelArray[10])}
              dataLabel={labelArray[10]}
            />
            <InputSimple
              label={formLabels[labelArray[11]]}
              error={errors[labelArray[11]]?.message}
              register={register(labelArray[11])}
              dataLabel={labelArray[11]}
            />
          </>
        )}
        {isNotEmployee == false && isBelgium && (
          <Controller
            control={control}
            name={labelArray[12]}
            render={({ field: { onChange, value } }) => (
              <InputRadio
                label={formLabels[labelArray[12]]}
                selectedValue={value || 40}
                options={hoursPerWeekOptions}
                onChange={e => {
                  onChange(e.target.value);
                }}
                tooltip
                tooltipMsg={
                  <>
                    <p>
                      In Belgium, employees working above 38 hours per week are granted monthly recuperation days. 0.5 days for 39
                      hours and 1 day for 40 hours per week.
                    </p>
                    <br></br>
                    <p>The user's recuperation day balance will be updated on the 1st of every month.</p>
                  </>
                }
              />
            )}
          />
        )}
        {shouldSeeLeaveDayBalance && (latestBalance || (!cannotEstimateLeaveDayBalance && !contractIsInPast)) && (
          <>
            <DividerWithLabel
              label={`${latestBalance ? 'Current leave day balance' : 'Initial leave day balance'}`}
              className="col-span-2"
            />
            <Toggle
              onChange={() => setCustomizeLeaveDayBalance(!customizeLeaveDayBalance)}
              label={`${latestBalance ? 'Edit current leave day balance' : 'Customize initial leave day balance'}`}
              enable={customizeLeaveDayBalance}
              className={'col-span-2 mr-auto'}
            />
            {!customizeLeaveDayBalance ? (
              <>
                <SimpleEntry label="Leave days" data={watch().leave_days} tooltip tooltipMsg={leaveDaysTooltipMsg} />
                {isBelgium && (
                  <SimpleEntry
                    label="Recuperation days"
                    data={watch().recuperation_days}
                    tooltip
                    tooltipMsg={recupDaysTooltipMsg}
                  />
                )}
              </>
            ) : (
              <>
                <InputSimple
                  type="number"
                  label="Leave days"
                  register={register(labelArray[13])}
                  dataLabel={labelArray[13]}
                  tooltip
                  tooltipMsg={leaveDaysTooltipMsg}
                />
                {isBelgium && (
                  <InputSimple
                    type="number"
                    label="Recuperation days"
                    register={register(labelArray[14])}
                    dataLabel={labelArray[14]}
                    tooltip
                    tooltipMsg={recupDaysTooltipMsg}
                  />
                )}
                <div className="col-span-2">
                  <Controller
                    control={control}
                    name={labelArray[15]}
                    render={({ field: { onChange, value } }) => <TextArea value={value} label={'Comment'} onChange={onChange} />}
                  />
                </div>
              </>
            )}
          </>
        )}
      </dl>
    </TwoColumnForm>
  );
}

export default AddStaffContract;
