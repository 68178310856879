import React from 'react';
import AdminSidebar from '../../AdminSidebar';
import CreateUserInvoiceForm from '../../../../containers/Finance/AccountsPayable/CreateUserInvoiceForm';

const CreateAccountsPayable = () => {
  const pages = [
    { name: 'Finance Manager', href: '/admin-panel/finance/invoicing-items', current: false },
    { name: `Accounts Payable`, href: '/admin-panel/finance/accounts-payable', current: false },
    { name: `Create user invoice`, href: `/admin-panel/finance/accounts-payable/create`, current: true },
  ];

  return (
    <AdminSidebar noPadding pages={pages}>
      <div id={'createUserInvoiceForm'} className="p-6 overflow-auto">
        <CreateUserInvoiceForm />
      </div>
    </AdminSidebar>
  );
};

export default CreateAccountsPayable;
