import React, { useEffect, useState, useMemo } from 'react';
import ProfilePicture from '../../components/ProfilePicture/ProfilePicture';
import UserImagePlaceholder from '../../components/UserImagePlaceholder/UserImagePlaceholder';
import { TimeSheetStatus } from '../../helpers/enum/timeSheet';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { useHistory } from 'react-router';
import { useQuery } from '../../hooks/useQuery';
import InputDate from '../../components/Inputs/InputDate/InputDate';
import ReactSelect from '../../components/Inputs/ReactSelect';
import { useLocation } from 'react-router-dom';
import PopOutMenu from '../../components/Menus/PopOutMenu';
import { ExternalLinkIcon } from '@heroicons/react/outline';
import Loader from '../../components/Loading/Loader';
import { EyeIcon } from '@heroicons/react/solid';
import formatNumber from '../../utils/formatNumber';

function DaysLog({ logs, fetchData, scrollSetting, typeChange, selectedType, isLoading }) {
  const contractTypeOptions = useMemo(() => {
    const _contractTypeOptions = [
      {
        value: 1,
        label: 'Purchase order',
      },
      {
        value: 2,
        label: 'Sales order',
      },
      {
        value: 3,
        label: 'Specific contract',
      },
    ];
    return _contractTypeOptions;
  }, []);
  const history = useHistory();
  const location = useLocation();
  const hash = location;

  dayjs.extend(advancedFormat);

  const query = useQuery();

  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filteredStatus, setFilteredStatus] = useState([]);
  const [filteredFromDate, setFilteredFromDate] = useState(null);
  const [filteredToDate, setFilteredToDate] = useState(null);
  const [filterChanged, setFilterChanged] = useState(false);
  const [goToPageValue, setGoToPageValue] = useState();
  const [canNextPage, setCanNextPage] = useState(false);
  const [canPreviousPage, setCanPreviousPage] = useState(false);
  const minDate = useMemo(() => (logs?.dates?.min ? new Date(logs.dates.min) : undefined), [logs]);
  const maxDate = useMemo(() => (logs?.dates?.max ? new Date(logs.dates.max) : undefined), [logs]);

  useEffect(() => {
    if (!scrollSetting?.page) return;
    setGoToPageValue(scrollSetting?.page);
    setCanPreviousPage(scrollSetting?.page > 1 || !scrollSetting?.page);
    setCanNextPage(scrollSetting?.page < scrollSetting?.totalPages);
  }, [scrollSetting]);

  useEffect(() => {
    if (query.get('users')) {
      setFilteredUsers(query.get('users').split(','));
    } else {
      setFilteredUsers([]);
    }
    if (query.get('status')) {
      setFilteredStatus(
        query
          .get('status')
          .split(',')
          .map(status => parseInt(status, 10)),
      );
    } else {
      setFilteredStatus([]);
    }
    if (query.get('fromDate')) {
      setFilteredFromDate(new Date(query.get('fromDate')));
    } else {
      setFilteredFromDate(null);
    }
    if (query.get('toDate')) {
      setFilteredToDate(new Date(query.get('toDate')));
    } else {
      setFilteredToDate(null);
    }
  }, [query]);

  useEffect(() => {
    if (!filterChanged) return;
    let allQueries = [];
    let fullQuery = undefined;
    setFilterChanged(false);
    if (filteredUsers?.length) allQueries.push(`users=${filteredUsers.toString()}`);
    if (filteredStatus?.length) allQueries.push(`status=${filteredStatus}`);
    if (filteredFromDate) allQueries.push(`fromDate=${dayjs(filteredFromDate).format('YYYY-MM-DD')}`);
    if (filteredToDate) allQueries.push(`toDate=${dayjs(filteredToDate).format('YYYY-MM-DD')}`);

    fullQuery = `${history.location.pathname}?${allQueries.join('&')}`;
    history.push(fullQuery);
  }, [filterChanged]);

  const filterByName = e => {
    setFilterChanged(true);
    let users;
    !e
      ? setFilteredUsers([])
      : (users = e.map((e, index) => {
          return e.value;
        }));
    setFilteredUsers(users);
    return;
  };

  const filterByStatus = e => {
    setFilterChanged(true);
    !e ? setFilteredStatus([]) : setFilteredStatus([e.value]);
  };

  const filterByFromDate = e => {
    setFilterChanged(true);
    !e ? setFilteredFromDate(null) : setFilteredFromDate(e);
  };

  const filterByToDate = e => {
    setFilterChanged(true);
    !e ? setFilteredToDate(null) : setFilteredToDate(e);
  };

  const userNameOptions = logs?.userNames?.length
    ? logs.userNames.map(user => {
        return {
          label: user.name,
          value: user.id,
        };
      })
    : [];

  const statusOptions = [
    {
      value: 3,
      label: 'Pre-approved',
    },
    {
      value: 4,
      label: 'Approved',
    },
  ];

  if (!logs) return <div></div>;

  const getTimesheetStatus = id => {
    if (TimeSheetStatus.APPROVED == id) {
      return <div className="bg-green-400 text-green-100 py-1 px-2 rounded-xl text-xs">Approved</div>;
    } else if (TimeSheetStatus.PREAPPROVE == id) {
      return <span className="bg-orange-400 text-orange-100 py-1 px-2 rounded-xl text-xs">Pre-approved</span>;
    } else {
      return <div></div>;
    }
  };

  const clearFilters = () => {
    setFilteredFromDate(null);
    setFilteredToDate(null);
    setFilteredUsers([]);
    setFilteredStatus([]);
    setFilterChanged(true);
  };

  const allLogs = logs.data.map(log => {
    const days = formatNumber(new Number(log.totalHours / 8));
    const daysRemainingBefore = logs.days_ordered
      ? formatNumber(new Number(log.daysRemainingUntilThisDate))
      : formatNumber(new Number(log.daysConsumedUntilThisDate));
    const daysRemainingAfter = logs.days_ordered
      ? formatNumber(new Number(log?.daysRemainingUntilThisDate) - days) || ''
      : formatNumber(new Number(log?.daysConsumedUntilThisDate) + days) || '';
    return (
      <tr>
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
          <div className="flex items-center">
            <div className="h-11 w-11 flex-shrink-0">
              <ProfilePicture
                src={log.user?.profilepic_url}
                className="h-10 w-15 rounded-full"
                alt=""
                defaultimage={<UserImagePlaceholder className="h-10 w-15 rounded-full" />}
              />
            </div>
            <div className="ml-4">
              <div className="font-medium text-gray-900">
                <span className="truncate ml-1">{log.user?.full_name}</span>
              </div>
            </div>
          </div>
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          <div className="flex items-center">
            {dayjs(new Date(log.timesheet_period)).format('MMMM YYYY')}
            <span className="text-xs flex ml-1 text-gray-400 hover:text-thaleria-orange-700 cursor-pointer">
              <ExternalLinkIcon className="h-5 w-5" onClick={() => window.open(`/admin-panel/hr/timesheets/${log.id}`)} />
            </span>
          </div>
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          <div className="flex justify-start">{getTimesheetStatus(log.status)}</div>
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
          <div className=" inline-flex">{daysRemainingBefore === 'NaN' ? '' : daysRemainingBefore}</div>
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
          {formatNumber(((log.totalHours / 8) * 100) / 100)}
        </td>
        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6">
          <div className="inline-flex">{daysRemainingAfter === 'NaN' ? '' : daysRemainingAfter}</div>
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
          <PopOutMenu
            key={'allocations'}
            title={'Allocations'}
            openButton={<EyeIcon className="w-4 h-4 text-thaleria-orange-700 hover:text-thaleria-orange-500 cursor-pointer" />}
          >
            <ul className="divide-y divide-gray-200 pr-4 h-80 overflow-y-auto">
              {log.timesheetEntries
                .sort((a, b) => new Date(a.date) - new Date(b.date))
                .map(entry => {
                  const hasAdjustment = entry.adjusted_hours || entry.adjusted_hours === 0;
                  return (
                    <li className="py-2 flex items-center w-full justify-between">
                      <span>
                        <p className="font-medium text-sm text-gray-400">{dayjs(entry.date).format('dddd Do')}</p>
                      </span>
                      <p className="px-2 py-1 inline-flex text-sm leading-5 text-gray-600">
                        {hasAdjustment ? formatNumber(entry.adjusted_hours, true) : formatNumber(entry.hours, true)} hours
                        {hasAdjustment ? '*' : ''}
                      </p>
                    </li>
                  );
                })}
            </ul>
          </PopOutMenu>
        </td>
      </tr>
    );
  });

  const StatCard = ({ title, content }) => (
    <div className="bg-white overflow-hidden shadow rounded-md p-5 flex flex-col md:flex-row items-center md:justify-between">
      <span className="text-sm font-medium text-gray-500">{title}</span>
      <span className="text-2xl font-medium ml-2">{content}</span>
    </div>
  );
  return (
    <div>
      <div className="w-full mb-5">
        <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-3 lg:grid-cols-3">
          <StatCard
            title={`${contractTypeOptions.find(item => item.value === selectedType[0]).label}`}
            content={logs.order_ref}
          />
          <StatCard title={'Days ordered'} content={logs?.days_ordered ? formatNumber(logs.days_ordered, true) : 'FTE'} />
          <StatCard title={`${logs.days_ordered ? 'Remaining days' : 'Days consumed'}`} content={logs.days_remaining} />
        </div>
      </div>
      {!logs.data.length && history.location.search == '' ? (
        <></>
      ) : (
        <div className="w-full mb-5 bg-white rounded-md shadow p-5 ">
          <div className="flex w-full justify-between">
            <div className="mt-[-2px] mr-2 text-sm font-medium text-gray-500">Filter results</div>
            <div className="flex text-sm cursor-pointer" onClick={() => clearFilters()}>
              <p className="text-blue-400">Clear all</p>
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:justify-between space-y-2 md:space-y-0 md:space-x-6 mt-2">
            <div className="w-full md:w-[400px]">
              <ReactSelect label="Type" onChange={typeChange} options={contractTypeOptions} selectedOptionsIds={selectedType} />
            </div>
            {userNameOptions.length >= 2 && (
              <div className={`w-full md:w-[600px]`}>
                <ReactSelect
                  isSearchable
                  label="User"
                  onChange={filterByName}
                  isMulti
                  options={userNameOptions}
                  selectedOptionsIds={filteredUsers}
                />
              </div>
            )}
            <div className="w-full md:w-[400px]">
              <ReactSelect
                label="Status"
                isClearable
                onChange={filterByStatus}
                options={statusOptions}
                selectedOptionsIds={filteredStatus}
              />
            </div>
            <div className="flex justify-start space-x-6 w-full md:w-[800px]">
              <div className="">
                <InputDate
                  className=""
                  label="From date"
                  onChange={filterByFromDate}
                  placeholder={dayjs(minDate).format('DD/MM/YYYY')}
                  selected={filteredFromDate}
                  minDate={minDate}
                  maxDate={maxDate}
                />
              </div>
              <div className="">
                <InputDate
                  className=""
                  label="To date"
                  onChange={filterByToDate}
                  placeholder={dayjs(maxDate).format('DD/MM/YYYY')}
                  selected={filteredToDate}
                  minDate={minDate}
                  maxDate={maxDate}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="flow-root" id={'scrollableDiv'}>
        {logs.data.length ? (
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="py-4 pl-4 pr-3 text-left text-sm text-gray-600 sm:pl-6">
                        User
                      </th>
                      <th scope="col" className="px-3 py-4 text-left text-sm text-gray-600">
                        Period
                      </th>
                      <th scope="col" className="px-3 py-4 text-left text-sm text-gray-600">
                        Status
                      </th>
                      <th scope="col" className="px-3 py-4 text-left text-sm text-gray-600">
                        Days {logs.days_ordered ? 'remaining' : 'consumed'} before
                      </th>
                      <th scope="col" className="px-3 py-4 text-left text-sm text-gray-600">
                        Days worked
                      </th>
                      <th scope="col" className="py-4 pl-3 pr-4 sm:pr-6 text-left text-sm text-gray-600">
                        {' '}
                        Days {logs.days_ordered ? 'remaining' : 'consumed'} after
                      </th>
                      <th scope="col" className="py-4 pl-3 pr-4 sm:pr-6 text-left text-sm text-gray-600">
                        Allocations
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    <tr>
                      {isLoading ? (
                        <td colSpan={7}>
                          <Loader isLoading={isLoading} />
                        </td>
                      ) : (
                        ''
                      )}
                    </tr>
                    {isLoading ? '' : allLogs}
                  </tbody>
                </table>
                <div className="w-full bg-gray-50">
                  <nav
                    className="w-full flex-shrink-0 sm:px-0 border-t border-gray-300 flex items-center justify-between"
                    aria-label="Pagination"
                  >
                    <div className="w-full flex space-x-4 justify-end px-4 py-1">
                      <div className="flex items-center">
                        <span className="mr-2 text-gray-500">Go to page:</span>
                        <input
                          className="mr-2 w-16 h-11 pl-1 text-gray-500 border-2 border-gray-100 rounded-md"
                          type="number"
                          min={1}
                          max={scrollSetting?.totalPages}
                          value={scrollSetting?.page}
                          onChange={e => {
                            const page = Number(e.target.value) || null;
                            setGoToPageValue(page);
                            fetchData(page);
                          }}
                        />
                      </div>
                      <div className="flex items-center">
                        <button
                          className="flex items-center cursor-pointer justify-center rounded-md px-2 h-6 mr-1 bg-gray-200 hover:bg-primary icon-container"
                          onClick={() => fetchData(1)}
                          disabled={!canPreviousPage}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" className="icon-style" viewBox="0 0 20 20">
                            <path
                              fillRule="evenodd"
                              d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                        <button
                          className="flex items-center cursor-pointer justify-center rounded-md px-2 h-6 mr-1 bg-gray-200 hover:bg-primary icon-container"
                          onClick={() => fetchData(scrollSetting?.page - 1)}
                          disabled={!canPreviousPage}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" className="icon-style" viewBox="0 0 20 20">
                            <path
                              fillRule="evenodd"
                              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                        <button
                          className="flex items-center cursor-pointer justify-center rounded-md px-2 h-6 mr-1 bg-gray-200 hover:bg-primary icon-container"
                          onClick={() => {
                            fetchData(scrollSetting?.page + 1);
                          }}
                          disabled={!canNextPage}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" className="icon-style" viewBox="0 0 20 20">
                            <path
                              fillRule="evenodd"
                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                        <button
                          className="flex items-center cursor-pointer justify-center rounded-md px-2 h-6 mr-1 bg-gray-200 hover:bg-primary icon-container"
                          onClick={() => fetchData(scrollSetting?.totalPages)}
                          disabled={!canNextPage}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" className="icon-style" viewBox="0 0 20 20">
                            <path
                              fillRule="evenodd"
                              d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                              clipRule="evenodd"
                            />
                            <path
                              fillRule="evenodd"
                              d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                        <span className="px-2 text-center text-gray-500">
                          Page
                          <strong> {scrollSetting?.page}</strong> of <strong>{scrollSetting?.totalPages}</strong>
                        </span>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Loader isLoading={isLoading}>
            {history.location.search == '' ? (
              <p className="w-full text-center">There are no allocations yet.</p>
            ) : (
              <p className="w-full text-center">No results match.</p>
            )}
          </Loader>
        )}
      </div>
    </div>
  );
}

export default DaysLog;
