import * as React from 'react';
import { Menu, Transition } from '@headlessui/react';
import classNames from '../../utils/classNames';
import { DotsHorizontalIcon, DotsVerticalIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';
import { usePopper } from 'react-popper';
import { Portal } from 'react-portal';

export default function DropdownMenuDots({ horizontal, items, dotsClassName, id, xOffset = 0 }) {
  const popperElRef = React.useRef(null);
  const menuButtonRef = React.useRef(null);
  const menuItemsRef = React.useRef(null);
  const [targetElement, setTargetElement] = React.useState(null);
  const [popperElement, setPopperElement] = React.useState(null);
  const [popperPosition, setPopperPosition] = React.useState('bottom');

  const offset = React.useMemo(() => {
    let _offset;
    switch (popperPosition) {
      case 'bottom':
        _offset = [20 + xOffset, 0];
        break;
      case 'top':
        _offset = [20 + xOffset, 85];
        break;
      default:
        break;
    }
    return _offset;
  }, [popperPosition]);

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: popperPosition,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: offset,
        },
      },
    ],
  });

  React.useEffect(() => {
    //Check if click happened close to the bottom or right edge, than add offset to anchorpoint
    let menuButtonXPosition = menuButtonRef?.current?.getBoundingClientRect().left;
    let menuButtonYPosition = menuButtonRef?.current?.getBoundingClientRect().top;
    let menuHeight = menuItemsRef?.current?.clientHeight + 40;
    let menuWidth = menuItemsRef?.current?.clientWidth;
    if (menuButtonXPosition + menuWidth > window?.window?.innerWidth) {
      //TODO: If needed, add a position to 'left' side to offset against right edge
    }

    if (menuButtonYPosition + menuHeight > window?.window?.innerHeight) {
      return setPopperPosition('top');
    } else {
      return setPopperPosition('bottom');
    }
  });

  //example items array
  /* const items = [
    [
      {
        text: 'Edit',
        icon: <EditIcon/>,
        onClick: () => alert('hey'),
        show: true/false
      },
      {
        text: 'Edit',
        icon: <EditIcon/>,
        onClick: () => alert('hey'),
        show: true/false
      },
    ],
    [
      {
        text: 'Edit',
        icon: <EditIcon/>,
        onClick: () => alert('hey'),
        show: true/false
      },
      {
        text: 'Edit',
        icon: <EditIcon/>,
        onClick: () => alert('hey'),
        show: true/false
      },
    ],
  ] */

  const handleClick = (event, item) => {
    item.onClick();
    event.stopPropagation();
  };

  return (
    <Menu>
      {({ open }) => (
        <>
          <div ref={setTargetElement} key={id}>
            <Menu.Button
              ref={menuButtonRef}
              className="cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
              onClick={e => e.stopPropagation()}
            >
              {horizontal ? (
                <DotsHorizontalIcon className={`${dotsClassName ? dotsClassName : 'h-6 w-6'}`} aria-hidden="true" />
              ) : (
                <DotsVerticalIcon className={`${dotsClassName ? dotsClassName : 'h-6 w-6'}`} aria-hidden="true" />
              )}
            </Menu.Button>
          </div>

          <Portal>
            <div ref={popperElRef} style={styles.popper} {...attributes.popper}>
              <Transition
                show={open}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
                beforeEnter={() => setPopperElement(popperElRef.current)}
                afterLeave={() => setPopperElement(null)}
              >
                <Menu.Items
                  className="origin-bottom-right z-50 absolute right-0 mt-2 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                  ref={menuItemsRef}
                >
                  {items.map((array, index) => (
                    <>
                      {index !== 0 && <div className="border-b" key={array} />}
                      {array.map((item, index) => {
                        return (
                          <Menu.Item key={item.text}>
                            {({ active }) =>
                              item.linkTo ? (
                                <Link
                                  to={item.linkTo}
                                  className={classNames(
                                    active && 'bg-gray-200',
                                    'flex w-full px-4 py-2 text-sm text-gray-700',
                                    index === 0 && 'rounded-t-md',
                                    index === array.length - 1 && 'rounded-b-md',
                                    item.show === false && 'hidden',
                                  )}
                                  key={item.text}
                                >
                                  {item.text}
                                  {item.icon}
                                </Link>
                              ) : (
                                <button
                                  className={classNames(
                                    active && 'bg-gray-200',
                                    'flex w-full px-4 py-2 text-sm text-gray-700',
                                    index === 0 && 'rounded-t-md',
                                    index === array.length - 1 && 'rounded-b-md',
                                    item.show === false && 'hidden',
                                  )}
                                  onClick={e => handleClick(e, item)}
                                  key={item.text}
                                >
                                  {item.icon}
                                  <span className="ml-1">{item.text}</span>
                                </button>
                              )
                            }
                          </Menu.Item>
                        );
                      })}
                    </>
                  ))}
                </Menu.Items>
              </Transition>
            </div>
          </Portal>
        </>
      )}
    </Menu>
  );
}
