import React from 'react';
import DeActiveUser from '../Icons/DeActiveUser';
import ProfilePicture from '../ProfilePicture/ProfilePicture';
import UserImagePlaceholder from '../UserImagePlaceholder/UserImagePlaceholder';

function DirectoryUserCard({ onClick, user, active, selected }) {
  const userAccountType = user?.accountType?.account_type || '-';
  const userFullName = user.full_name || '-';

  return (
    <ul className={`${selected && 'bg-gray-300'} relative divide-y divide-gray-200 cursor-pointer`}>
      <li onClick={onClick}>
        <div className="relative px-6 py-5 flex items-center space-x-3 hover:bg-gray-200 focus-within:ring-2 focus-within:ring-inset focus-within:ring-pink-500">
          <div className="flex-shrink-0">
            {active ? (
              <ProfilePicture
                src={user.profilepic_url}
                className="h-10 w-10 rounded-full"
                alt=""
                defaultimage={<UserImagePlaceholder className="h-10 w-10 rounded-full" />}
              />
            ) : (
              <div className="flex justify-center items-center h-10 w-10 rounded-full bg-gray-300">
                <DeActiveUser />
              </div>
            )}
          </div>
          <div className="flex-1 min-w-0">
            <div className="flex flex-col focus:outline-none">
              <span className="absolute inset-0" aria-hidden="true"></span>
              <p className="text-sm font-medium text-gray-900">{userFullName}</p>

              <p className="text-sm text-gray-500 truncate">
                {userAccountType} {active || <span>&bull; Not active</span>}
              </p>
            </div>
          </div>
        </div>
      </li>
    </ul>
  );
}

export default DirectoryUserCard;
