import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import AddCountry from '../../../../containers/AdminManager/AddOrEditCountry';
import PageWrapperV2 from '../../../../containers/App/PageWrapper';
import useApi from '../../../../hooks/useApi';
import { capitalizeFirstLetter } from '../../../../utils/capitalizeFirstLetter';
import countryListWithIds from '../../../../helpers/countryListWithIds';

export default function AddNewCountry(props) {
  const history = useHistory();
  const { id } = useParams();

  const {
    countries: { getAllCountries, createCountry, updateCountry },
  } = useApi();

  useEffect(() => {
    getAllCountries().then(r => {
      setCountries(r);
    });
  }, []);

  const [countries, setCountries] = useState([]);

  const countryName = countryListWithIds.find(country => country.value == id)?.label;

  const handleClick = form => {
    if (id === 'create') {
      createCountry(form).then(data => {
        data ? history.push('/admin-panel/admin/countries') : alert('Error while saving the details');
      });
    } else {
      updateCountry(id, form).then(data => {
        data ? history.push('/admin-panel/admin/countries') : alert('Error while saving the details');
      });
    }
  };

  const pages = [
    { name: 'Contracts Manager', href: '/admin-panel/contracts/purchase-orders', current: false },
    { name: 'Countries', href: '/admin-panel/admin/countries', current: false },
    { name: id == 'create' ? capitalizeFirstLetter(id) : countryName, href: '/admin-panel/admin/countries', current: false },
  ];

  return (
    <PageWrapperV2 pages={pages}>
      <AddCountry countries={countries} id={id} handleSave={handleClick} />
    </PageWrapperV2>
  );
}
