import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import TwoColumnForm from '../../components/Layouts/TwoColumnForm';
import SimpleAlert from '../../components/Modals/SimpleAlert';
import ReactSelect from '../../components/Inputs/ReactSelect';
import * as Yup from 'yup';
import { VALIDATION_MSG } from '../../helpers/enum/errorValidationMsgs';
import useApi from '../../hooks/useApi';
import extractObjectDifferences from '../../helpers/extractObjectDifferences';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../store/app/actions';
import notifications from '../../helpers/enum/notifications';
import AddHolidaysTable from '../../components/AddHolidaysTable';
import dayjs from 'dayjs';
import ButtonPrimary from '../../components/Buttons/ButtonPrimary';
import ButtonWhite from '../../components/Buttons/ButtonWhite';
import holidayTypes from '../../helpers/enum/holidayTypes';

function AddHoliday({ id, data, countryOptions, handleSave, getClients }) {
  const history = useHistory();

  const {
    holidays: { deleteHoliday },
  } = useApi();

  const dispatch = useDispatch();

  const TYPE_ERROR_MSG = 'Please select a type before saving';
  const CLIENT_TYPE_ERROR_MSG = 'Please select a client type before saving';
  const NAME_ERROR_MSG = 'Please write a name';
  const DATE_ERROR_MSG = 'Please select a date';

  const checkFormErrors = async () => {
    let errors = [];
    const typeAndScopeSchema = Yup.object().shape({
      type: Yup.number().integer().typeError(VALIDATION_MSG).required(TYPE_ERROR_MSG),
      client_type: Yup.number()
        .integer()
        .typeError(VALIDATION_MSG)
        .when('type', {
          is: 1,
          then: Yup.number().integer().typeError(VALIDATION_MSG).required(CLIENT_TYPE_ERROR_MSG),
          otherwise: Yup.number().integer().typeError(VALIDATION_MSG).nullable(),
        }),
      clients: Yup.array()
        .of(Yup.number().integer())
        .typeError(VALIDATION_MSG)
        .nullable()
        .when('type', {
          is: 0,
          then: Yup.mixed().validateSync(null),
          otherwise: Yup.array().of(Yup.number().integer()).typeError(VALIDATION_MSG).nullable(),
        }),
      countries: Yup.array().of(Yup.number().integer()).typeError(VALIDATION_MSG).nullable(),
    });

    const namesAndDatesSchema = Yup.array().of(
      Yup.object().shape(
        {
          name: Yup.string()
            .typeError(NAME_ERROR_MSG)
            .when('date', {
              is: null,
              then: Yup.string().min(2, 'Must be at least 2 characters').typeError(NAME_ERROR_MSG).nullable(),
              otherwise: Yup.string().min(2, 'Must be at least 2 characters').typeError(NAME_ERROR_MSG).required(),
            }),
          date: Yup.string()
            .typeError(DATE_ERROR_MSG)
            .when('name', {
              is: null,
              then: Yup.string().typeError(DATE_ERROR_MSG).nullable(),
              otherwise: Yup.string().typeError(DATE_ERROR_MSG).required(),
            }),
        },
        ['name', 'date'],
      ),
    );

    await typeAndScopeSchema.validate(typeAndScope, { strict: true, abortEarly: false }).catch(err => {
      err.inner.forEach(_err => {
        errors.push({
          field: _err.path,
          msg: _err.errors[0],
        });
      });
    });
    await namesAndDatesSchema.validate(namesAndDates, { strict: true, abortEarly: false }).catch(err => {
      err.inner.forEach(_err => {
        errors.push({
          field: _err.path,
          msg: _err.errors[0],
        });
      });
    });

    return errors;
  };

  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState([]);
  const [typeAndScope, setTypeAndScope] = useState({
    type: null,
    client_type: null,
    clients: null,
    countries: null,
  });
  const [namesAndDates, setNamesAndDates] = useState([{ name: null, date: null, key: 1 }]);
  const [showClientType, setShowClientType] = useState(false);
  const [showClients, setShowClients] = useState(false);
  const [clientOptions, setClientOptions] = useState([]);

  useEffect(() => {
    const type = typeAndScope.type;
    const clientType = typeAndScope.client_type;

    if (type == holidayTypes.numbers.closure_day) {
      setShowClientType(true);
      if (clientType != undefined) {
        getClients(clientType).then(res => {
          setClientOptions(res.map(client => ({ label: client.name, value: client.id })));
          setShowClients(true);
        });
      } else {
        setShowClients(false);
      }
    } else {
      setTypeAndScope(prev => ({ ...prev, client_type: null, clients: null }));
      setShowClientType(false);
      setShowClients(false);
    }
  }, [typeAndScope.type, typeAndScope.client_type]);

  useEffect(() => {
    if (id && id === 'create') return;
    if (!data) return;
    setTypeAndScope({
      type: data.type,
      client_type: data.client_type,
      clients: data.clients,
      countries: data.countries,
    });
    setNamesAndDates([
      {
        name: data.name,
        date: data.date,
        key: 1,
      },
    ]);
  }, [id, data, countryOptions]);

  const handleRemoveClick = () => {
    deleteHoliday(data.id)
      .then(d => {
        history.goBack();
        setShowAlert(false);
      })
      .catch(e => {
        setError(e.response.data.message);
        setShowAlert(false);
      });
  };

  const onSubmit = async () => {
    setError([]);
    let dataToSend = typeAndScope;

    const errors = await checkFormErrors();

    if (errors.length) {
      setError(errors);
      return;
    }

    if (id != 'create') {
      dataToSend.name = namesAndDates[0]?.name;
      dataToSend.date = namesAndDates[0]?.date;
      dataToSend = extractObjectDifferences(dataToSend, data);
    }
    if (id == 'create') dataToSend.holidays = namesAndDates;
    if (!dataToSend) {
      dispatch(
        setNotification(
          notifications.WARNING_YELLOW,
          'Unable to update Public Holiday',
          'No changes to the holiday were detected.',
        ),
      );
    } else {
      handleSave(dataToSend);
    }
  };

  const onNameChange = (e, index) => {
    setNamesAndDates(prev => {
      let newList = [...prev];
      newList[index].name = e.target.value;
      return newList;
    });
  };

  const onDateChange = (e, index) => {
    //TODO:Change the format back to the european version
    setNamesAndDates(prev => {
      let newList = [...prev];
      newList[index].date = dayjs(e).format('MM/DD/YYYY');
      return newList;
    });
  };

  const addRow = () => {
    setNamesAndDates(prev => {
      let newList = [...prev];
      newList.push({ name: null, date: null, key: Math.random() });
      return newList;
    });
  };

  const deleteRow = index => {
    setNamesAndDates(prev => {
      let prevList = [...prev];
      let newList = [...prev];
      newList.splice(index, 1);

      return newList;
    });
  };

  const handleCancelClick = () => {
    history.goBack();
  };

  const typeOptions = [
    { label: 'Public holiday', value: 0 },
    { label: 'Closure day', value: 1 },
  ];

  const clientTypeOptions = [
    { label: 'EU', value: 0 },
    { label: 'Private', value: 1 },
    { label: 'Public', value: 2 },
  ];

  return (
    <div>
      <TwoColumnForm label="Type and scope" description="Select type and applicable scope">
        <SimpleAlert
          errorTitle="Warning"
          errorMsg="Removing this will completely remove this holiday, and will delete it from the application. Are you sure you want to proceed?"
          onAcceptText="Proceed"
          onAcceptClick={handleRemoveClick}
          onDeclineText="Cancel"
          show={showAlert}
          hide={() => setShowAlert(false)}
        />
        <ReactSelect
          isSearchable
          options={typeOptions}
          selectedOptions={typeOptions.filter(option => option.value == typeAndScope.type)}
          label="Type*"
          onChange={obj => {
            setTypeAndScope(prev => ({ ...prev, type: obj.value }));
          }}
          error={error.find(e => e.field === 'type')?.msg}
        />

        {showClientType && (
          <ReactSelect
            isSearchable
            options={clientTypeOptions}
            selectedOptions={clientTypeOptions.filter(option => option.value == typeAndScope.client_type)}
            label={'Client type*'}
            onChange={obj => {
              setTypeAndScope(prev => ({ ...prev, client_type: obj.value }));
            }}
            error={error.find(e => e.field === 'client_type')?.msg}
          />
        )}
        {showClients && (
          <ReactSelect
            isSearchable
            options={clientOptions}
            selectedOptions={
              typeAndScope?.clients?.length ? typeAndScope.clients.map(id => clientOptions.find(obj => obj.value == id)) : null
            }
            isMulti
            label={'Clients'}
            onChange={array => {
              setTypeAndScope(prev => ({ ...prev, clients: array?.length ? array.map(obj => obj.value) : null }));
            }}
            placeholder={`All ${
              typeAndScope?.client_type ? clientTypeOptions.find(el => el.value == typeAndScope.client_type).label : ''
            } clients`}
            error={error.find(e => e.field === 'clients')?.msg}
          />
        )}

        <ReactSelect
          isSearchable
          options={countryOptions}
          selectedOptions={
            typeAndScope?.countries?.length ? typeAndScope.countries.map(id => countryOptions.find(obj => obj.value == id)) : null
          }
          isMulti
          label={'Countries'}
          onChange={array => {
            setTypeAndScope(prev => ({ ...prev, countries: array?.length ? array.map(obj => obj.value) : null }));
          }}
          placeholder="All countries"
          error={error.find(e => e.field === 'countries')?.msg}
          tooltip
          tooltipMsg={
            <>
              <p className="mb-2">
                When <strong>Public holiday</strong> this field refers to the country in the consultant's Staff Contract.
              </p>
              <p>
                When <strong>Closure day</strong> this field refers to the country in the consultant's Specific Contract.
              </p>
            </>
          }
        />
      </TwoColumnForm>
      <div class="py-5">
        <div class="border-t border-gray-200"></div>
      </div>
      <TwoColumnForm
        // onClick={handleSubmit(onSubmit)}

        label="Holidays"
        description="Add holidays name and date"
        // onClickRed={() => setShowAlert(true)}
        // buttonRed={data && data.id ? true : false}
        // buttonRedText={'Delete'}
      >
        <AddHolidaysTable
          dates={namesAndDates}
          onNameChange={(e, index) => onNameChange(e, index)}
          onDateChange={(e, index) => onDateChange(e, index)}
          addRow={() => addRow()}
          deleteRow={index => deleteRow(index)}
          errors={error}
          isEdit={id != 'create'}
        />
      </TwoColumnForm>
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1"></div>
        <div className="flex flex-row-reverse justify-between py-3 md:col-span-2">
          <div className="text-right">
            <ButtonWhite style="mr-2" onClick={handleCancelClick} text="Cancel" />
            <ButtonPrimary
              onClick={onSubmit}
              text={`${id != 'create' ? 'Edit' : 'Save'}`}
              disable={namesAndDates[0].name == null || namesAndDates[0].date == null}
            />
          </div>
          <div className="text-left">
            <ButtonPrimary color="red" style="mr-2" onClick={() => setShowAlert(true)} text="Delete" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddHoliday;
