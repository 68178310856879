import React from 'react';
import Modal from './Modal';
import ClipLoader from 'react-spinners/ClipLoader';
import Checkbox from '../Checkbox/Checkbox';

function SanitizeDataModal({ show, hide, onAcceptClick, isLoading, sanitizeTaskData, setSanitizeTaskData }) {
  return (
    <Modal show={show} hide={hide} size="md" clickOutsideToClose={false}>
      <div className="sm:flex sm:items-start ">
        <div className="mx-auto mt-2 flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          {/* Heroicon name: outline/exclamation */}
          <svg
            className="h-6 w-6 text-red-600"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
        </div>
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
            Sanitize data
          </h3>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              {isLoading
                ? 'This can take a few seconds, please wait...'
                : 'By clicking Continue, you will irreversibly update all sensitive and personal information from the myThaleria database.'}
            </p>
          </div>
          {!isLoading && (
            <div className="my-4">
              <Checkbox
                horizontal
                title="Keep and sanitize task-related data"
                value={sanitizeTaskData}
                onChange={e => setSanitizeTaskData(!sanitizeTaskData)}
              />
            </div>
          )}
        </div>
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          onClick={onAcceptClick}
          disabled={isLoading}
          className="min-w-[100px] inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          {isLoading ? <ClipLoader className="mr-10 h-5 w-5" size={18} color={'#FFFF'} /> : 'Continue'}
        </button>
        <button
          type="button"
          disabled={isLoading}
          onClick={() => hide()}
          className="min-w-[100px] mt-3 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
}

export default SanitizeDataModal;
