import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PageWrapperV2 from '../../../../containers/App/PageWrapper';
import ButtonWhite from '../../../../components/Buttons/ButtonWhite';
import ButtonPrimary from '../../../../components/Buttons/ButtonPrimary';
import DividerSimple from '../../../../components/Dividers/DividerSimple';
import AddSalesOrderAndSpecificContract from '../../../../containers/Contracts/AddStaffOrderEntry/AddSalesOrderAndSpecificContract';
import EntryCreatedModal from '../../../../containers/Contracts/AddStaffOrderEntry/EntryCreatedModal';
import AddPurchaseOrderAndUser from '../../../../containers/Contracts/AddStaffOrderEntry/AddPurchaseOrderAndUser';
import AddEndClientAndPrimeFrameworkContract from '../../../../containers/Contracts/AddStaffOrderEntry/AddEndClientAndPrimeFrameworkContract';
import SummaryModal from '../../../../containers/Contracts/AddStaffOrderEntry/SummaryModal';
import * as Yup from 'yup';
import { DATE_VALIDATION_MSG, VALIDATION_MSG } from '../../../../helpers/enum/errorValidationMsgs';
import useApi from '../../../../hooks/useApi';
import contractInvoicingTypes from '../../../../helpers/enum/contractInvoicingTypes';
import * as contractTypes from '../../../../helpers/enum/contractTypeIDs';
import billingUnits from '../../../../helpers/enum/billingUnits';

function AddDashboardEntry() {
  const [dateErrors, setDateErrors] = useState([]);
  const [formErrors, setFormErrors] = useState([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [clients, setClients] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [countries, setCountries] = useState([]);

  const {
    salesOrders: { getSalesOrderRefs },
    pointOfContacts: { getAllContacts },
    clients: { getAllClients },
    specificContracts: { getSpecificContractRefs },
    purchaseOrders: { getStaffOrderRefs },
    countries: { getAllCountries },
  } = useApi();

  const fetchAllContacts = () => getAllContacts().then(data => setContacts(data));
  const fetchAllClients = () => getAllClients().then(data => setClients(data));
  const fetchAllCountries = () => getAllCountries().then(data => setCountries(data));

  useEffect(() => {
    fetchAllClients();
    fetchAllContacts();
    fetchAllCountries();
  }, []);

  //------------------END-CLIENT AND PRIME FRAMEWORK CONTRACTS----------------------//

  const [endClientFWC, setEndClientFWC] = useState(null);
  const [primeFWC, setPrimeFWC] = useState(null);

  //------------------SPECIFIC CONTRACT AND SALES ORDER----------------------//

  const [specificContractOpenEnded, setSpecificContractOpenEnded] = useState(false);
  const [salesOrderOpenEnded, setSalesOrderOpenEnded] = useState(false);

  const [specificContract, setSpecificContract] = useState({
    contract_ref: null,
    description: null,
    order_start: null,
    order_end: null,
    daily_rate: null,
    days_ordered: null,
    department: null,
    client_id: null,
    point_of_contact_id: null,
    invoicing_type: 1,
    value: null,
  });

  const [salesOrder, setSalesOrder] = useState({
    order_ref: null,
    description: null,
    order_start: null,
    order_end: null,
    daily_rate: null,
    days_ordered: null,
    department: null,
    point_of_contact_id: null,
    client_id: null,
    invoicing_type: 1,
    billing_units: billingUnits.numbers.days,
    value: null,
  });

  //------------------PURCHASE ORDER AND CONSULTANT----------------------//

  const [purchaseOrderOpenEnded, setPurchaseOrderOpenEnded] = useState(false);

  const [purchaseOrder, setPurchaseOrder] = useState({
    order_ref: null,
    order_start: null,
    order_end: null,
    days_ordered: null,
    daily_rate: null,
    timesheet_type: null,
    point_of_contact_id: null,
    user_id: null,
    partner_contract_id: null,
    framework_contract_id: null,
    staff_contract_id: null,
    invoicing_type: 1,
    billing_units: billingUnits.numbers.days,
    value: null,
  });

  const [user, setUser] = useState({ value: 0, label: 'Select user...' });
  const [staffContract, setStaffContract] = useState({ value: 0, label: 'Select contract...' });

  //-------------SUMMARY MODAL----------//

  const [showSummaryModal, setShowSummaryModal] = useState(false);

  //--------------HELPERS---------------//
  //handle pagewrapper click
  const history = useHistory();
  const handleCancelClick = () => {
    history.goBack();
  };
  const checkDateErrors = () => {
    // verify date consistency
    const errors = [];

    if (new Date(purchaseOrder.order_start) < new Date(specificContract?.order_start))
      errors.push({
        errors: ['purchaseOrder', 'specificContract'],
        msg: "Purchase order start date cannot be before specific contract's start date",
      });
    if (!specificContractOpenEnded) {
      if (new Date(purchaseOrder.order_end) > new Date(specificContract?.order_end) || purchaseOrderOpenEnded) {
        if (!specificContractOpenEnded) {
          errors.push({
            errors: ['purchaseOrder', 'specificContract'],
            msg: "Purchase order end date cannot be after specific contract's end date",
          });
        }
      }
    }
    if (new Date(purchaseOrder.order_start) < new Date(salesOrder.order_start)) {
      errors.push({
        errors: ['purchaseOrder', 'salesOrder'],
        msg: "Purchase order start date cannot be before sales order's start date",
      });
    }
    if (!salesOrderOpenEnded) {
      if (new Date(purchaseOrder.order_end) > new Date(salesOrder.order_end) || purchaseOrderOpenEnded) {
        if (!salesOrderOpenEnded) {
          errors.push({
            errors: ['purchaseOrder', 'salesOrder'],
            msg: "Purchase order end date cannot be after sales order's end date",
          });
        }
      }
    }
    if (salesOrder.order_start < new Date(endClientFWC.contract_start)) {
      errors.push({
        errors: ['salesOrder', 'endClientFWC'],
        msg: "Sales order start date cannot be before framework contract's start date",
      });
    }
    if (endClientFWC?.contract_end) {
      if (new Date(salesOrder.order_end) > new Date(endClientFWC.contract_end) || salesOrderOpenEnded) {
        if (endClientFWC.contract_end)
          errors.push({
            errors: ['salesOrder', 'endClientFWC'],
            msg: "Sales order end date cannot be after framework contract's end date",
          });
      }
    }
    if (new Date(salesOrder.order_start) < new Date(primeFWC?.contract_start)) {
      errors.push({
        errors: ['salesOrder', 'primeFWC'],
        msg: "Sales order start date cannot be before partner contract's start date",
      });
    }
    if (primeFWC?.contract_end) {
      if (new Date(salesOrder.order_end) > new Date(primeFWC?.contract_end) || salesOrderOpenEnded) {
        if (primeFWC?.contract_end) {
          errors.push({
            errors: ['salesOrder', 'primeFWC'],
            msg: "Sales order end date cannot be after partner contract's end date",
          });
        }
      }
    }

    if (new Date(specificContract?.order_start) < new Date(endClientFWC.contract_start)) {
      errors.push({
        errors: ['specificContract', 'endClientFWC'],
        msg: "Specific contract start date cannot be before framework contract's start date",
      });
    }
    if (endClientFWC?.contract_end) {
      if (new Date(specificContract?.order_end) > new Date(endClientFWC.contract_end) || specificContractOpenEnded) {
        if (endClientFWC.contract_end) {
          errors.push({
            errors: ['specificContract', 'endClientFWC'],
            msg: "Specific contract end date cannot be after framework contract's end date",
          });
        }
      }
    }

    if (new Date(specificContract?.order_start) < new Date(primeFWC?.contract_start)) {
      errors.push({
        errors: ['specificContract', 'primeFWC'],
        msg: "Specific contract start date cannot be before partner contract's start date",
      });
    }
    if (primeFWC?.contract_end) {
      if (new Date(specificContract?.order_end) > new Date(primeFWC?.contract_end) || specificContractOpenEnded) {
        if (primeFWC?.contract_end) {
          errors.push({
            errors: ['specificContract', 'primeFWC'],
            msg: "Specific contract end date cannot be after partner contract's end date",
          });
        }
      }
    }
    if (new Date(staffContract?.contract_start) > new Date(purchaseOrder?.order_start)) {
      errors.push({
        errors: ['purchaseOrder', 'staffContract'],
        msg: 'Purchase order cannot start before staff contract',
      });
    }
    if (staffContract?.contract_end) {
      if (new Date(staffContract?.contract_end) < new Date(purchaseOrder?.order_end) || purchaseOrderOpenEnded) {
        errors.push({
          errors: ['purchaseOrder', 'staffContract'],
          msg: 'Staff contract cannot end before purchase order',
        });
      }
    }
    if (errors.length) {
      setDateErrors(errors);
      return false;
    } else {
      setDateErrors([]);
      return true;
    }
  };

  const checkFormErrors = async () => {
    let errors = [];
    //framework contracts validation
    if (!endClientFWC) errors.push({ field: 'endClientFWC', msg: 'Please select a framework contract' });
    if (!primeFWC) errors.push({ field: 'primeFWC', msg: 'Please select a framework contract' });

    //specific contract validation
    let specificContractRefs;
    await getSpecificContractRefs().then(res => (specificContractRefs = res.map(item => item.contract_ref)));

    const specificContractValidationSchema = Yup.object().shape({
      contract_ref: Yup.string()
        .required(VALIDATION_MSG)
        .trim()
        .notOneOf(specificContractRefs, 'Reference name already in use')
        .nullable(),
      order_start: Yup.date().required(VALIDATION_MSG).nullable(),
      order_end:
        specificContractOpenEnded || !specificContract?.order_start
          ? ''
          : Yup.date()
              .required(VALIDATION_MSG)
              .nullable()
              .when('order_start', (order_start, schema) => order_start && schema.min(order_start, DATE_VALIDATION_MSG)),
      daily_rate: Yup.string()
        .nullable()
        .when('invoicing_type', {
          is: contractInvoicingTypes.numbers.timeAndMeans,
          then: Yup.string().required(VALIDATION_MSG).nullable(),
          otherwise: Yup.string().nullable().oneOf([null], 'Daily rate must be null'),
        }),
      days_ordered: Yup.string().notRequired().nullable(true),
      department: Yup.string().nullable().notRequired(),
      client_id: Yup.number().required(VALIDATION_MSG).nullable(),
      country_id: Yup.number().required(VALIDATION_MSG).nullable(),
      point_of_contact_id: Yup.number().notRequired().nullable(),
      invoicing_type: Yup.number()
        .oneOf(
          [contractInvoicingTypes.numbers.timeAndMeans, contractInvoicingTypes.numbers.fixedPrice],
          'Invoicing type is invalid',
        )
        .required(VALIDATION_MSG)
        .nullable(),
      value: Yup.string().when('invoicing_type', {
        is: contractInvoicingTypes.numbers.fixedPrice,
        then: Yup.string().required(VALIDATION_MSG),
        otherwise: Yup.string().nullable(),
      }),
    });

    if (!specificContract?.id) {
      await specificContractValidationSchema.validate(specificContract, { strict: true, abortEarly: false }).catch(err => {
        err.inner.forEach(_err => {
          errors.push({
            field: 'specificContract-' + _err.path,
            msg: _err.errors[0],
          });
        });
      });
    }
    let endClientFWCClientIDs = endClientFWC.clients.map(el => el.id);
    if (!endClientFWCClientIDs.find(id => id === specificContract.client_id))
      errors.push({
        field: 'specificContract-client_id',
        msg: 'Client must belong to End Client FWC',
      });

    //sales order validation
    let salesOrderValidationSchema;
    let salesOrderRefs;
    await getSalesOrderRefs().then(res => (salesOrderRefs = res.map(item => item.order_ref)));

    salesOrderValidationSchema = Yup.object().shape({
      order_ref: Yup.string()
        .required(VALIDATION_MSG)
        .trim()
        .notOneOf(salesOrderRefs, 'Reference name already in use')
        .nullable(),
      order_start: Yup.date().required(VALIDATION_MSG).nullable(),
      order_end:
        salesOrderOpenEnded || !salesOrder?.order_start
          ? ''
          : Yup.date()
              .required(VALIDATION_MSG)
              .nullable()
              .when('order_start', (order_start, schema) => order_start && schema.min(order_start, DATE_VALIDATION_MSG)),
      daily_rate: Yup.string()
        .nullable()
        .when('invoicing_type', {
          is: contractInvoicingTypes.numbers.timeAndMeans,
          then: Yup.string().required(VALIDATION_MSG).nullable(),
          otherwise: Yup.string().nullable().oneOf([null], 'Daily rate must be null'),
        }),
      days_ordered: Yup.string().notRequired().nullable(true),
      client_id: Yup.number().required(VALIDATION_MSG).nullable(),
      invoicing_type: Yup.number()
        .oneOf(
          [contractInvoicingTypes.numbers.timeAndMeans, contractInvoicingTypes.numbers.fixedPrice],
          'Invoicing type is invalid',
        )
        .required(VALIDATION_MSG),
      billing_units: Yup.number()
        .nullable()
        .when('invoicing_type', {
          is: contractInvoicingTypes.numbers.timeAndMeans,
          then: Yup.number()
            .oneOf([1, 2], 'Billing units must be 1 or 2 when invoicing type is Time & Means')
            .required('Billing units are required when invoicing type is Time & Means'),
          otherwise: Yup.number().nullable().oneOf([null], 'Billing units must be null when invoicing type is not Time & Means'),
        }),
      value: Yup.string().when('invoicing_type', {
        is: contractInvoicingTypes.numbers.fixedPrice,
        then: Yup.string().required(VALIDATION_MSG),
        otherwise: Yup.string().nullable(),
      }),
    });

    if (!salesOrder?.id) {
      await salesOrderValidationSchema.validate(salesOrder, { strict: true, abortEarly: false }).catch(err => {
        err.inner.forEach(_err => {
          errors.push({
            field: 'salesOrder-' + _err.path,
            msg: _err.errors[0],
          });
        });
      });
    }
    let primeFWCClientIDs = primeFWC.clients.map(el => el.id);
    if (!primeFWCClientIDs.find(id => id === salesOrder.client_id))
      errors.push({
        field: 'salesOrder-client_id',
        msg: 'Client must belong to Sales FWC',
      });

    //purchase order validation
    let purchaseOrderRefs;
    await getStaffOrderRefs().then(res => (purchaseOrderRefs = res.map(item => item.order_ref)));

    const purchaseOrderValidationSchema = Yup.object().shape({
      order_ref: Yup.string()
        .required(VALIDATION_MSG)
        .trim()
        .notOneOf(purchaseOrderRefs, 'Reference name already in use')
        .nullable(),
      order_start: Yup.date().required(VALIDATION_MSG).nullable(),
      order_end:
        purchaseOrderOpenEnded || !purchaseOrder?.order_start
          ? ''
          : Yup.date()
              .required(VALIDATION_MSG)
              .nullable()
              .when('order_start', (order_start, schema) => order_start && schema.min(order_start, DATE_VALIDATION_MSG)),
      days_ordered: Yup.string()
        .nullable()
        .when('invoicing_type', {
          is: contractInvoicingTypes.numbers.timeAndMeans,
          then: Yup.string().required(VALIDATION_MSG).nullable(),
          otherwise: Yup.string().nullable().oneOf([null], 'Daily rate must be null'),
        }),
      days_ordered: Yup.string().notRequired().nullable(true),
      invoicing_type: Yup.number()
        .oneOf(
          [contractInvoicingTypes.numbers.timeAndMeans, contractInvoicingTypes.numbers.fixedPrice],
          'Invoicing type is invalid',
        )
        .required(VALIDATION_MSG)
        .nullable(),
      billing_units: Yup.number()
        .nullable()
        .when('invoicing_type', {
          is: contractInvoicingTypes.numbers.timeAndMeans,
          then: Yup.number().oneOf([1, 2]),
          otherwise: Yup.number()
            .nullable()
            .oneOf([null], 'Billing units must be null when invoicing type is not time and means'),
        }),
      value: Yup.string().when('invoicing_type', {
        is: contractInvoicingTypes.numbers.fixedPrice,
        then: Yup.string().required(VALIDATION_MSG),
        otherwise: Yup.string().nullable(),
      }),
    });

    await purchaseOrderValidationSchema.validate(purchaseOrder, { strict: true, abortEarly: false }).catch(err => {
      err.inner.forEach(_err => {
        errors.push({
          field: 'purchaseOrder-' + _err.path,
          msg: _err.errors[0],
        });
      });
    });

    //consultant and staff contract validation

    //framework contracts validation
    if (!user || user.value === 0) errors.push({ field: 'user', msg: 'Please select a user' });
    if (!staffContract || staffContract.value === 0)
      errors.push({ field: 'staffContract', msg: 'Please select a staff contract' });

    //days_ordered validation
    if (
      (specificContract?.days_ordered !== null &&
        new Number(salesOrder?.days_ordered) > new Number(specificContract?.days_ordered)) ||
      (salesOrder?.days_ordered == null && specificContract?.days_ordered !== null)
    ) {
      errors.push({
        field: 'salesOrder-days_ordered',
        msg: "Cannot exceed specific contract's days ordered",
      });
    }
    if (
      (salesOrder?.days_ordered !== null && new Number(purchaseOrder?.days_ordered) > new Number(salesOrder?.days_ordered)) ||
      (purchaseOrder?.days_ordered == null && salesOrder?.days_ordered !== null)
    ) {
      errors.push({
        field: 'purchaseOrder-days_ordered',
        msg: "Cannot exceed sales order's days ordered",
      });
    }

    // Validation of invoicing type between contracts
    if (
      specificContract?.invoicing_type === contractInvoicingTypes.numbers.timeAndMeans &&
      salesOrder?.invoicing_type !== contractInvoicingTypes.numbers.timeAndMeans
    ) {
      errors.push({
        field: 'specificContract-invoicing_type',
        msg: 'When Specific contract invoicing type is Time & Means, Sales order invoicing type must also be Time & Means',
      });
      errors.push({
        field: 'salesOrder-invoicing_type',
        msg: 'When Specific contract invoicing type is Time & Means, Sales order invoicing type must also be Time & Means',
      });
    }

    if (
      salesOrder?.invoicing_type === contractInvoicingTypes.numbers.timeAndMeans &&
      purchaseOrder?.invoicing_type !== contractInvoicingTypes.numbers.timeAndMeans
    ) {
      errors.push({
        field: 'purchaseOrder-invoicing_type',
        msg: 'When Sales order invoicing type is Time & Means, Purchase order invoicing type must also be Time & Means',
      });
      errors.push({
        field: 'salesOrder-invoicing_type',
        msg: 'When Sales order invoicing type is Time & Means, Purchase order invoicing type must also be Time & Means',
      });
    }

    return errors;
  };

  const handleAddContractClick = async () => {
    const errors = await checkFormErrors();
    if (errors.length) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      setFormErrors(errors);
      return;
    }
    checkDateErrors();
    setFormErrors([]);
    setShowSummaryModal(true);
  };

  const pages = [
    { name: 'Contracts Manager', href: '/admin-panel/contracts/purchase-orders', current: false },
    { name: `Create Purchase Order`, href: '', current: true },
  ];

  return (
    <PageWrapperV2 pages={pages}>
      <EntryCreatedModal show={showSuccessModal} hide={() => history.push('/admin-panel/contracts/purchase-orders')} />
      <SummaryModal
        setShowSuccessModal={setShowSuccessModal}
        show={showSummaryModal}
        hide={() => setShowSummaryModal(false)}
        endClientFWC={endClientFWC}
        primeFWC={primeFWC}
        specificContract={specificContract}
        salesOrder={salesOrder}
        purchaseOrder={purchaseOrder}
        user={user}
        staffContract={staffContract}
        dateErrors={dateErrors}
        clients={clients}
        contacts={contacts}
        countries={countries}
      />
      <AddEndClientAndPrimeFrameworkContract
        endClientFWC={endClientFWC}
        setEndClientFWC={setEndClientFWC}
        primeFWC={primeFWC}
        setPrimeFWC={setPrimeFWC}
        formErrors={formErrors}
      />
      <DividerSimple />
      <AddSalesOrderAndSpecificContract
        formErrors={formErrors}
        salesOrder={salesOrder}
        setSalesOrder={setSalesOrder}
        specificContract={specificContract}
        setSpecificContract={setSpecificContract}
        dateErrors={dateErrors}
        specificContractOpenEnded={specificContractOpenEnded}
        setSpecificContractOpenEnded={setSpecificContractOpenEnded}
        salesOrderOpenEnded={salesOrderOpenEnded}
        setSalesOrderOpenEnded={setSalesOrderOpenEnded}
        clients={clients}
        contacts={contacts}
        fetchAllClients={fetchAllClients}
        fetchAllContacts={fetchAllContacts}
        countries={countries}
        fetchAllCountries={fetchAllCountries}
      />
      <DividerSimple />
      <AddPurchaseOrderAndUser
        formErrors={formErrors}
        purchaseOrder={purchaseOrder}
        setPurchaseOrder={setPurchaseOrder}
        user={user}
        setUser={setUser}
        staffContract={staffContract}
        setStaffContract={setStaffContract}
        openEnded={purchaseOrderOpenEnded}
        setOpenEnded={setPurchaseOrderOpenEnded}
        contacts={contacts}
        fetchAllContacts={fetchAllContacts}
      />
      <div className="flex flex-row-reverse mt-6 ">
        <ButtonPrimary onClick={handleAddContractClick} text="Add contract" />
        <ButtonWhite style="mr-2" onClick={handleCancelClick} text="Cancel" />
      </div>
    </PageWrapperV2>
  );
}

export default AddDashboardEntry;
