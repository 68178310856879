import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import AddContact from '../../../../containers/AdminManager/AddOrEditPointOfContact';
import PageWrapper from '../../../../containers/App/PageWrapper';
import { capitalizeFirstLetter } from '../../../../utils/capitalizeFirstLetter';
import useApi from '../../../../hooks/useApi';

export default function AddClients() {
  const history = useHistory();
  const { id } = useParams();
  const {
    pointOfContacts: { getAllContacts, createContact, updateContact },
    clients: { getAllClients },
  } = useApi();

  useEffect(() => {
    getAllContacts().then(d => {
      setContacts(d);
    });
  }, []);

  const [contacts, setContacts] = useState([]);

  const fetchClients = async () => {
    getAllClients().then(r => setClients(r));
  };

  useEffect(() => {
    fetchClients();
  }, []);

  const [clients, setClients] = useState([]);

  const handleClick = form => {
    if (id === 'create') {
      createContact(form).then(data => {
        data ? history.push('/admin-panel/admin/points-of-contact') : alert('Error while saving the details');
      });
    } else {
      updateContact(id, form).then(data => {
        data ? history.push('/admin-panel/admin/points-of-contact') : alert('Error while saving the details');
      });
    }
  };

  const pages = [
    { name: 'Contracts Manager', href: '/admin-panel/contracts/purchase-orders', current: false },
    { name: 'Points of Contact', href: '/admin-panel/admin/points-of-contact', current: false },
    { name: capitalizeFirstLetter(id), href: '/admin-panel/admin/points-of-contact', current: false },
    { name: id === 'create' ? undefined : 'Edit', href: '', current: true },
  ];

  return (
    <PageWrapper pages={pages}>
      <AddContact contacts={contacts} clients={clients} fetchClients={fetchClients} id={id} handleSave={handleClick} />
    </PageWrapper>
  );
}
