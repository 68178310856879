import React, { useEffect, useState } from 'react';
import Modal from '../../components/Modals/Modal';
import useApi from '../../hooks/useApi';
import ReactSelect from '../../components/Inputs/ReactSelect';
import ButtonPrimary from '../../components/Buttons/ButtonPrimary';
import ButtonWhite from '../../components/Buttons/ButtonWhite';

function AddTagModal({ show, hide, tags, setTaskDetails, taskId, setLogs }) {
  const [selectedUser, setSelectedUser] = useState({});

  const handleHide = () => {
    hide();
    setTimeout(() => setSelectedUser({}), 500);
  };

  const [userOptions, setUserOptions] = useState([]);

  const { tasks: tasksApi, users: usersApi } = useApi();

  const fetchUserOptions = async () => {
    await usersApi.getStaffAndAdminUsers().then(data => {
      setUserOptions(
        data
          .filter(e => !tags?.find(l => l.user_id == e.id))
          .map(e => {
            return {
              label: e.full_name,
              value: e.id,
              profilepic_url: e.profilepic_url,
            };
          }),
      );
    });
  };

  useEffect(() => {
    fetchUserOptions();
  }, [show]);

  const handleAddTag = () => {
    tasksApi.addTag(taskId, selectedUser.value).then(r => {
      const updatedTags = [
        ...tags,
        {
          id: r.id,
          user_id: selectedUser.value,
          user: {
            full_name: selectedUser.label,
            profilepic_url: selectedUser.profilepic_url,
            first_names: selectedUser.label.split(' ')[0],
          },
        },
      ];

      setTaskDetails(prev => ({
        ...prev,
        tags: updatedTags,
      }));
      setLogs(prev => ({
        ...prev,
        data: prev.data.map(e => {
          if (e.id === taskId) {
            return { ...e, tags: updatedTags };
          } else return e;
        }),
      }));
      handleHide();
    });
  };

  return (
    <Modal show={show} hide={handleHide} title="Add tag" className={'space-y-4'}>
      <ReactSelect options={userOptions} selectedOptions={selectedUser} onChange={e => setSelectedUser(e)} label="Select user" />
      <div className="space-x-4">
        <ButtonPrimary text="Add" onClick={handleAddTag} disable={!selectedUser} />
        <ButtonWhite text="Cancel" onClick={handleHide} />
      </div>
    </Modal>
  );
}

export default AddTagModal;
