export const contractExtensions = {
  EXTENDED: true,
  CLOSED: false,
  PENDING: null,
};

export const contractExtensionLabels = {
  true: 'Extended',
  false: 'Closed',
  null: 'Pending',
};
