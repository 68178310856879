import React, { useState, useEffect } from 'react';
import Modal from '../../../components/Modals/Modal';
import ButtonPrimary from '../../../components/Buttons/ButtonPrimary';
import ReactSelect from '../../../components/Inputs/ReactSelect';
import useApi from '../../../hooks/useApi';
import Loader from '../../../components/Loading/Loader';
import months from '../../../helpers/enum/months';
import formatCurrency from '../../../utils/formatCurrency';
import { TimeSheetStatusLabels } from '../../../helpers/enum/timeSheet';
import InputSimple from '../../../components/Inputs/InputSimple';
import Error from '../../../components/Error/Error';
import { useParams } from 'react-router-dom';
import { lineItemTypesV2 } from '../../../helpers/enum/lineItemTypes';
import { format } from 'date-fns';
import formatNumber from '../../../utils/formatNumber';

function AddLineItemModal({
  setLineItems,
  showAddLineItemModal,
  setShowAddLineItemModal,
  clientId,
  selectedLineItemIndex,
  lineItems,
}) {
  const {
    accountsReceivables: { getContractAssociations },
    frameworkContracts: { getClientFrameworkContracts },
    lineItems: { getInvoicingLinesByStaffOrderId },
  } = useApi();

  const { id: accountReceivableId } = useParams();
  const [isLoadingInvoicingLines, setIsLoadingInvoicingLines] = useState(false);
  const [isLoadingContractAssociations, setIsLoadingContractAssociations] = useState(false);
  const [formErrors, setFormErrors] = useState([]);
  const [showCustomReferenceInput, setShowCustomReferenceInput] = useState(false);

  //return true even if index is 0;
  const _selectedLineItemIndex = selectedLineItemIndex === 0 || selectedLineItemIndex;

  const [selectOptions, setSelectOptions] = useState({
    users: [],
    staffOrders: [],
    salesOrders: [],
    specificContracts: [],
    partnerContracts: [],
    frameworkContracts: [],
    salesContracts: [], //used in the react select for select sales contract, assumes it could either be an endclientFWC (direct placement), or a partnerContract (through intermediary)
  });

  const [newLine, setNewLine] = useState({
    description: null,
    type: null,
    staff_order_id: null,
    framework_contract_id: null,
    partner_contract_id: null,
    specific_contract_id: null,
    sales_order_id: null,
    timesheet_id: null,
    units: null,
    uom: null,
    price: null,
    vat_rate: null,
    user_id: null,
    from_date: null,
    to_date: null,
    metadata: {
      fwc_ref: null,
      partner_ref: null,
      specific_contract_ref: null,
      sales_order_ref: null,
      custom_reference: null,
      period: null,
    },
  });

  const [invoicingLines, setInvoicingLines] = useState([]);

  const fetchContractAssociations = async updatedNewLine => {
    if (!clientId) return;
    setIsLoadingContractAssociations(true);
    setFormErrors([]);
    const query = {
      partnerContractId: updatedNewLine.partner_contract_id, //this will not change when fetching associated contracts since it is now being set on a prior react select just for sales contracts
      frameworkContractId: updatedNewLine.framework_contract_id,
      specificContractId: updatedNewLine.specific_contract_id,
      salesOrderId: updatedNewLine.sales_order_id,
      userId: updatedNewLine.user_id,
      staffOrderId: updatedNewLine.staff_order_id,
      clientId,
    };

    getContractAssociations(query)
      .then(data => {
        let _formatted = {};
        const labelKeys = {
          frameworkContracts: 'contract_ref',
          salesOrders: 'contract_ref',
          specificContracts: 'contract_ref',
          staffOrders: 'order_ref',
          users: 'full_name',
        };

        Object.keys(data).forEach(key => {
          //format array for ReactSelect component
          const arrayBeingChecked = data[key];
          _formatted[key] = arrayBeingChecked.map(el => ({ label: el[labelKeys[key]], value: el.id, ...el }));
        });

        let _salesContracts = [];
        if (!selectOptions?.salesContracts.length) {
          //this is only used on edit AR page. When we press line details, it will not have fetched sales contracts yet, so we assume current selected partnerContract sent in
          getClientFrameworkContracts(clientId).then(data => {
            data.forEach(el => {
              let _contract = { ...el, value: el.id, label: el.contract_ref };
              return _salesContracts.push(_contract);
            });
          });
        } else {
          //otherwise, we assume salesContract choice is independent from other contracts fetched, and we always enforce it stays the same
          _salesContracts = selectOptions.salesContracts;
        }
        _formatted['salesContracts'] = _salesContracts;
        setSelectOptions({ ..._formatted });

        if (isAnyTypeOfAllocation(updatedNewLine.type) && updatedNewLine.staff_order_id) {
          //if _selectedLineItemIndex means user is editing a line item already added to the invoice

          setIsLoadingInvoicingLines(true);

          getInvoicingLinesByStaffOrderId(updatedNewLine.staff_order_id, accountReceivableId || 0)
            .then(data => {
              if (!_selectedLineItemIndex) {
                //if adding new line
                //only keep invoicing line options that are not already included as line items on the form (when it is not the currently selected line item)
                let filtered = data.filter(
                  fetchedItem =>
                    !lineItems.find(itemAlreadyInForm => itemAlreadyInForm.id === fetchedItem.id) ||
                    fetchedItem.id === updatedNewLine.id,
                );
                setInvoicingLines(filtered);
                setIsLoadingInvoicingLines(false);
              } else {
                //if editing already added line
                let filtered = data.filter(fetchedItem => fetchedItem.id === updatedNewLine.id);
                setInvoicingLines(filtered);
                setIsLoadingInvoicingLines(false);
              }
            })
            .catch(() => setIsLoadingInvoicingLines(false));
        } else {
          setInvoicingLines([]);
        }

        setTimeout(() => setIsLoadingContractAssociations(false), 500);
      })

      .catch(() => setTimeout(() => setIsLoadingContractAssociations(false), 500));
    //any change in references resets the selected invoicing item
    let updatedBody = { ...updatedNewLine };

    if (updatedBody.metadata.custom_reference) setShowCustomReferenceInput(true);
    setNewLine(updatedBody);
  };

  useEffect(() => {
    if (showAddLineItemModal) {
      let foundLine = lineItems.find((l, i) => i === selectedLineItemIndex);
      if (foundLine) fetchContractAssociations(foundLine);
      else resetForm();
    }
  }, [selectedLineItemIndex, showAddLineItemModal]);

  const addLine = () => {
    let errors = [];

    if (!newLine.type) {
      errors.push({ field: 'type', msg: 'Required' });
    } else {
      //for allocations, staff order id and invoicing item id are minimal requirements
      if (isAnyTypeOfAllocation(newLine.type)) {
        if (!newLine.staff_order_id) errors.push({ field: 'staffOrder', msg: 'Required' });
        if (!newLine.id) errors.push({ field: 'invoicingLine', msg: 'Required' });
      } else {
        //except for project allocations, partner contract is minimal requirement
        if (!newLine.partner_contract_id) errors.push({ field: 'partnerContract', msg: 'Required' });
      }
    }

    if (errors.length) return setFormErrors(errors);
    //select missing references on mission placement if there was only one option possible
    let dataToAdd = { ...newLine };
    if (selectOptions.users.length === 1) dataToAdd.user_id = selectOptions.users[0].value;
    if (selectOptions.staffOrders.length === 1) dataToAdd.staff_order_id = selectOptions.staffOrders[0].value;
    if (selectOptions.salesOrders.length === 1) {
      dataToAdd.sales_order_id = selectOptions.salesOrders[0].value;
      dataToAdd.metadata.sales_order_ref = selectOptions.salesOrders[0].label;
    }
    if (selectOptions.specificContracts.length === 1) {
      dataToAdd.specific_contract_id = selectOptions.specificContracts[0].value;
      dataToAdd.metadata.specific_contract_ref = selectOptions.salesOrders[0].label;
    }
    if (selectOptions.frameworkContracts.length === 1) {
      dataToAdd.framework_contract_id = selectOptions.frameworkContracts[0].value;
      dataToAdd.metadata.fwc_ref = selectOptions.frameworkContracts[0].label;
    }

    setFormErrors([]);
    _selectedLineItemIndex
      ? setLineItems(prev => prev.map((el, i) => (i === selectedLineItemIndex ? dataToAdd : el)))
      : setLineItems(prev => [...prev, dataToAdd]);
    handleHide();
  };

  const handleHide = () => {
    setShowAddLineItemModal(false);
    setTimeout(resetForm, 500);
  };

  const resetForm = () => {
    setNewLine({
      description: null,
      type: null,
      staff_order_id: null,
      framework_contract_id: null,
      partner_contract_id: null,
      specific_contract_id: null,
      sales_order_id: null,
      timesheet_id: null,
      units: null,
      uom: null,
      price: null,
      vat_rate: null,
      user_id: null,
      from_date: null,
      to_date: null,
      total: null,
      vat: null,
      total_and_vat: null,
      metadata: {
        fwc_ref: null,
        partner_ref: null,
        specific_contract_ref: null,
        sales_order_ref: null,
        custom_reference: null,
        period: null,
      },
    });
    setShowCustomReferenceInput(false);
    setInvoicingLines([]);
    setFormErrors([]);
  };

  const handleLineItemTypeChange = async el => {
    let updated = {
      description: null,
      units: null,
      uom: null,
      price: null,
      vat_rate: null,
      staff_order_id: null,
      framework_contract_id: null,
      partner_contract_id: null,
      specific_contract_id: null,
      sales_order_id: null,
      timesheet_id: null,
      user_id: null,
      type: el.value,
      from_date: null,
      to_date: null,
      metadata: {
        sales_order_ref: null,
        fwc_ref: null,
        specific_contract_ref: null,
        partner_ref: null,
        period: null,
        custom_reference: null,
      },
    };
    if (el.value !== lineItemTypesV2.accountsReceivable.numbers.projectAllocations) {
      let description = lineItemTypesV2.accountsReceivable.strings[el.value];
      updated.description = description;
    }

    await getClientFrameworkContracts(clientId).then(data => {
      let _options = {
        frameworkContracts: [],
        salesOrders: [],
        specificContracts: [],
        staffOrders: [],
        users: [],
        salesContracts: [],
      };
      data.forEach(el => {
        //logic here is selecting a final sales contract, and it can be either an endClientFWC (in case of a direct placement) or a partnerContract (in case of a placement through a subco)
        //either way, the option selected (either endClientContract or partnerContract) would be the sales contract of the mission/placement, so it would be considered a partner_contract_id
        //since we are storing in the staffOrder/purchaseOrder model the sales contract as partner_contract_id. It would be perhaps worth it to review the nomenclature of the contract references
        //to reduce ambiguity
        let _contract = { ...el, value: el.id, label: el.contract_ref };
        return _options.salesContracts.push(_contract);
      });
      setSelectOptions(_options);
      setNewLine(updated);
    });
  };

  const handleSelectInvoicingLine = item => {
    //Removing some entries from the invoicing line that are not accepted (and are not necessary) in backend validation
    const {
      accounts_payable_id,
      accounts_receivable_id,
      credit_note_id,
      date,
      created_at,
      updated_at,
      deleted_at,
      staffOrder,
      timesheet,
      user,
      ...filteredItem
    } = item;

    setNewLine(prev => ({
      bank_account_id: prev.bank_account_id,
      ...filteredItem,
      metadata: JSON.parse(item?.metadata),
    }));
  };

  const handleToggleCustomReference = () => {
    setNewLine(prev => ({ ...prev, metadata: { ...prev.metadata, custom_reference: null } }));
    setShowCustomReferenceInput(!showCustomReferenceInput);
  };

  const handleSelectSalesContract = async el => {
    //logic here is selecting a final sales contract, and it can be either an endClientFWC (in case of a direct placement) or a partnerContract (in case of a placement through a subco)
    //either way, the option selected (either endClientContract or partnerContract) would be the sales contract of the mission/placement, so it would be considered a partner_contract_id
    //since we are storing in the staffOrder/purchaseOrder model the sales contract as partner_contract_id. It would be perhaps worth it to review the nomenclature of the contract references
    //to reduce ambiguity
    let updated = {
      description: newLine.description,
      units: null,
      uom: null,
      price: null,
      vat_rate: null,
      staff_order_id: null,
      framework_contract_id: null,
      partner_contract_id: el?.value || null,
      bank_account_id: el?.bank_account_id || null,
      specific_contract_id: null,
      sales_order_id: null,
      timesheet_id: null,
      user_id: null,
      type: newLine.type,
      from_date: null,
      to_date: null,
      metadata: {
        sales_order_ref: null,
        fwc_ref: null,
        specific_contract_ref: null,
        partner_ref: el?.label || null,
        period: null,
        custom_reference: null,
      },
    };
    await fetchContractAssociations(updated);
  };

  const isAnyTypeOfAllocation = type => {
    return (
      type === lineItemTypesV2.accountsReceivable.numbers.allocationsToBeInvoiced ||
      type === lineItemTypesV2.accountsReceivable.numbers.projectAllocations ||
      type === lineItemTypesV2.accountsReceivable.numbers.customAllocationsToBeInvoiced ||
      type === lineItemTypesV2.accountsReceivable.numbers.customProjectAllocations
    );
  };

  return (
    <Modal size="lg" show={showAddLineItemModal} hide={handleHide} title="Add line item">
      <label className="block text-md font-medium text-gray-700 mr-2 mt-4 mb-2">Select line item type</label>
      <ReactSelect
        options={lineItemTypesV2.accountsReceivable.reactSelectOptions}
        orderOptions={false}
        error={formErrors.find(e => e.field === 'type')?.msg}
        disabled={_selectedLineItemIndex}
        selectedOptions={[
          lineItemTypesV2.accountsReceivable.reactSelectOptions.find(el => {
            return (
              el.value === newLine.type ||
              //this means allocations to be invoiced and custom allocations to be invoiced will also show as type Project Allocations
              ((newLine.type === lineItemTypesV2.accountsReceivable.numbers.allocationsToBeInvoiced ||
                newLine.type === lineItemTypesV2.accountsReceivable.numbers.customAllocationsToBeInvoiced) &&
                el.value === lineItemTypesV2.accountsReceivable.numbers.projectAllocations)
            );
          }),
        ]}
        onChange={handleLineItemTypeChange}
      />

      <div className={newLine.type ? 'block' : 'hidden'}>
        <label className="block text-md font-medium text-gray-700 mr-2 mt-4 mb-2">Select sales contract</label>
        <ReactSelect
          isSearchable
          isClearable
          error={formErrors.find(e => e.field === 'partnerContract')?.msg}
          options={selectOptions.salesContracts}
          selectedOptionsIds={[newLine.partner_contract_id]}
          onChange={handleSelectSalesContract}
          disabled={_selectedLineItemIndex}
        />
      </div>

      <div className={newLine.partner_contract_id ? 'block' : 'hidden'}>
        <div className="flex mt-4 mb-2 items-center justify-between">
          <label className="block text-md font-medium text-gray-700">
            Select mission references {isAnyTypeOfAllocation(newLine.type) && '(optional)'}{' '}
          </label>
        </div>
        <Loader className={'flex justify-around p-11 rounded-lg'} isLoading={isLoadingContractAssociations}>
          <div className="w-full mb-2">
            <div className="grid grid-cols-3 gap-3 relative">
              <div className="col-span-1">
                <ReactSelect
                  isSearchable
                  isClearable
                  error={formErrors.find(e => e.field === 'user')?.msg}
                  label="User"
                  options={selectOptions.users}
                  selectedOptions={[selectOptions.users.find(u => u.value === newLine.user_id)]}
                  disabled={selectOptions.users.find(u => u.value === newLine.user_id)?.disabled || _selectedLineItemIndex}
                  onChange={async el => {
                    let updatedNewLine = { ...newLine, user_id: el?.value || null };
                    await fetchContractAssociations(updatedNewLine);
                  }}
                />
              </div>
              <div className="col-span-1">
                <ReactSelect
                  isSearchable
                  isClearable
                  error={formErrors.find(e => e.field === 'staffOrder')?.msg}
                  label={isAnyTypeOfAllocation(newLine.type) ? 'Purchase order*' : 'Purchase order'}
                  options={selectOptions.staffOrders}
                  selectedOptions={[selectOptions.staffOrders.find(u => u.value === newLine.staff_order_id)]}
                  onChange={async el => {
                    let updatedNewLine = { ...newLine, staff_order_id: el?.value || null };
                    await fetchContractAssociations(updatedNewLine);
                  }}
                  disabled={_selectedLineItemIndex}
                />
              </div>
              <div className="col-span-1">
                <ReactSelect
                  isSearchable
                  isClearable
                  error={formErrors.find(e => e.field === 'salesOrder')?.msg}
                  label={'Sales order'}
                  options={selectOptions.salesOrders}
                  selectedOptions={[selectOptions.salesOrders.find(u => u.value === newLine.sales_order_id)]}
                  onChange={async el => {
                    let updatedNewLine = {
                      ...newLine,
                      sales_order_id: el?.value || null,
                      metadata: {
                        ...newLine.metadata,
                        sales_order_ref: el?.label || null,
                      },
                    };
                    await fetchContractAssociations(updatedNewLine);
                  }}
                  disabled={_selectedLineItemIndex}
                />
              </div>
              <div className="col-span-1">
                <ReactSelect
                  isSearchable
                  isClearable
                  error={formErrors.find(e => e.field === 'specificContract')?.msg}
                  label={'Specific contract'}
                  options={selectOptions.specificContracts}
                  selectedOptions={[selectOptions.specificContracts.find(u => u.value === newLine.specific_contract_id)]}
                  onChange={async el => {
                    let updatedNewLine = {
                      ...newLine,
                      specific_contract_id: el?.value || null,
                      metadata: {
                        ...newLine.metadata,
                        specific_contract_ref: el?.label || null,
                      },
                    };
                    await fetchContractAssociations(updatedNewLine);
                  }}
                  disabled={_selectedLineItemIndex}
                />
              </div>
              <div className={`col-span-1`}>
                <ReactSelect
                  isSearchable
                  isClearable
                  error={formErrors.find(e => e.field === 'frameworkContract')?.msg}
                  label={'End client FWC'}
                  options={selectOptions.frameworkContracts}
                  selectedOptions={[selectOptions.frameworkContracts.find(u => u.value === newLine.framework_contract_id)]}
                  onChange={async el => {
                    let updatedNewLine = {
                      ...newLine,
                      framework_contract_id: el?.value || null,
                      metadata: { ...newLine.metadata, fwc_ref: el?.label || null },
                    };
                    await fetchContractAssociations(updatedNewLine);
                  }}
                  disabled={_selectedLineItemIndex}
                />
              </div>
              {showCustomReferenceInput ? (
                <div className={`col-span-2 col-start-1`}>
                  <InputSimple
                    error={formErrors.find(e => e.field === 'customReference')?.msg}
                    label={
                      <div className="w-full flex justify-between">
                        <span>Custom reference</span>
                        <span
                          className="underline  text-gray-500 ml-2 cursor-pointer"
                          onClick={handleToggleCustomReference}
                          style={{ fontSize: '0.7rem' }}
                        >
                          remove custom reference
                        </span>
                      </div>
                    }
                    value={newLine.metadata.custom_reference}
                    onChange={e => {
                      setNewLine(prev => ({ ...prev, metadata: { ...prev.metadata, custom_reference: e.target.value } }));
                    }}
                  />
                </div>
              ) : (
                <span
                  className="underline  text-gray-500 ml-2 cursor-pointer col-start-1"
                  onClick={handleToggleCustomReference}
                  style={{ fontSize: '0.7rem' }}
                >
                  add custom reference
                </span>
              )}
            </div>
          </div>
        </Loader>
      </div>
      {isAnyTypeOfAllocation(newLine.type) && newLine.partner_contract_id && (
        //CHECKWITHNUNO: without overflow-hidden here when values in the line items are too high the lines in the table overflow the modal. I also added overflow-x-auto to the table
        <div className="overflow-hidden">
          <div className="flex">
            <label className="block text-md font-medium text-gray-700 mr-2 mt-4 mb-2">{`${
              _selectedLineItemIndex ? 'Invoicing line' : 'Select invoicing lines*'
            }`}</label>
            {!!formErrors.find(e => e.field === 'invoicingLine') && (
              <Error message={formErrors.find(e => e.field === 'invoicingLine').msg} />
            )}
          </div>
          <Loader className={'flex justify-around p-11 rounded-lg'} isLoading={isLoadingInvoicingLines}>
            {invoicingLines.length ? (
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle s m:px-6 lg:px-8 overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Name
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Period
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Status
                        </th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                          Units
                        </th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                          Price
                        </th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                          Revenue
                        </th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                          VAT rate
                        </th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                          VAT owed
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {invoicingLines.map(item => (
                        <tr
                          key={item.id || 'n/a'}
                          className={`hover:bg-gray-50 hover:cursor-pointer ${
                            (newLine?.id === item?.id || item?.id === lineItems[selectedLineItemIndex]?.id) && 'bg-blue-50'
                          }`}
                          onClick={() => handleSelectInvoicingLine(item)}
                        >
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.user.full_name}</td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {format(new Date(item?.from_date), 'dd/MM/yy') + ' - ' + format(new Date(item?.to_date), 'dd/MM/yy')}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {TimeSheetStatusLabels[item.timesheet.status]}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                            {formatNumber(item.units, true, 6)}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                            {formatCurrency(item.price)}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                            {formatCurrency(item.total)}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                            {item.vat_rate || '-'}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                            {formatCurrency(item.vat) || '-'}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <span className="text-sm text-gray-400 mt-4">
                {newLine.staff_order_id
                  ? 'No invoicing lines to show for the selected purchase order.'
                  : 'Select a purchase order to view invoicing lines.'}
              </span>
            )}
          </Loader>
        </div>
      )}

      <div className="mt-6 text-right">
        <ButtonPrimary text={_selectedLineItemIndex ? 'Update line' : 'Add line'} onClick={addLine} />
      </div>
    </Modal>
  );
}

export default AddLineItemModal;
