const paidStatus = {
  strings: {
    0: 'Unpaid',
    1: 'Partially paid',
    2: 'Paid',
  },
  numbers: {
    unpaid: 0,
    partiallyPaid: 1,
    paid: 2,
  },
};

export default paidStatus;
