import React from 'react';
import { Link } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';

function PillShapedButton({
  size,
  style,
  accept,
  disable,
  icon,
  text,
  onClick,
  canDelete,
  color,
  type,
  title,
  id,
  isLoading,
  transparent,
}) {
  const BUTTON_STYLES = {
    minWidth: '140px',
  };
  let sizeStyle;

  switch (size) {
    case 'xs':
      sizeStyle = 'px-2.5 py-1.5 text-xs rounded-3xl ';
      break;
    case 's':
      sizeStyle = 'px-3 py-2 text-xs leading-4 rounded-3xl';
      break;
    case 'm':
      sizeStyle = 'px-4 py-2 text-sm rounded-3xl ';
      break;
    case 'l':
      sizeStyle = 'px-4 py-2 text-base rounded-3xl ';
      break;
    case 'xl':
      sizeStyle = 'px-6 py-3 text-base rounded-3xl';
      break;
    default:
      sizeStyle = 'px-4 py-2 text-sm rounded-3xl ';
      break;
  }

  let colorStyle;

  switch (color) {
    case 'red':
      colorStyle = 'bg-red-600 hover:bg-red-700 focus:ring-red-500';
      break;
    case 'orange':
      colorStyle = 'bg-orange-600 hover:bg-orange-700 focus:ring-orange-500';
      break;
    case 'blue':
      colorStyle = 'bg-thaleria-blue-500 hover:bg-thaleria-blue-400 focus: ring-thaleria-blue-500';
      break;
    case 'green':
      colorStyle = 'bg-green-600 hover:bg-green-700 focus:ring-green-500';
      break;
    default:
      colorStyle = 'bg-thaleria-orange-700 hover:bg-thaleria-orange-800 focus:ring-thaleria-orange-600';
      break;
  }

  return (
    <button
      id={id}
      onClick={onClick}
      style={BUTTON_STYLES}
      type={type ? type : 'button'}
      title={title}
      disabled={disable || isLoading}
      className={`${
        style ? style : ''
      } ${sizeStyle} border border-transparent text-white inline-flex justify-center items-center font-medium shadow-sm  ${
        canDelete
          ? 'bg-red-600 hover:bg-red-700 focus:ring-red-500'
          : `${
              accept
                ? 'bg-green-600 hover:bg-green-700 focus:ring-green-500'
                : disable
                ? 'bg-gray-400 cursor-not-allowed hover:bg-gray-400'
                : colorStyle
            }`
      } focus:outline-none focus:ring-2 focus:ring-offset-2 `}
    >
      {!isLoading ? (
        <>
          {icon}
          {text}
        </>
      ) : (
        <>
          <ClipLoader size={17} color="#ffffff" />
        </>
      )}
    </button>
  );
}

export default PillShapedButton;
