import React, { useEffect, useState, useMemo } from 'react';
import PageWrapperV2 from '../../../containers/App/PageWrapper';
import { TimeSheetStatusLabels } from '../../../helpers/enum/timeSheet';
import MyTimesheetsTabList from '../../../containers/Timesheets/MyTimesheetsTabList';
import useApi from '../../../hooks/useApi';

var months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

function MyTimesheet() {
  const [fetchingData, setFetchingData] = useState(true);

  const {
    timesheets: { getAllMyTimesheets },
  } = useApi();

  useEffect(() => {
    getAllMyTimesheets().then(d => {
      setTimesheets(d);
      setFetchingData(false);
    });
  }, []);

  const [timesheets, setTimesheets] = useState([]);

  const timesheetsData = useMemo(() => {
    let array = [];
    if (timesheets && timesheets.length) {
      timesheets.map(e => {
        array.push({
          id: e.id,
          month: e.month ? months[e.month - 1] : '',
          year: e.year,
          statusLabel: TimeSheetStatusLabels[e.status],
          statusId: e.status,
        });
      });
    }
    return array;
  }, [timesheets]);

  const pages = [{ name: 'Timesheets', href: '/timesheets', current: true }];

  return (
    <PageWrapperV2 pages={pages}>
      <div>
        <MyTimesheetsTabList isLoading={fetchingData} timesheets={timesheetsData} />
      </div>
    </PageWrapperV2>
  );
}

export default MyTimesheet;
