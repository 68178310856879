import React, { useState, useEffect } from 'react';
import ButtonPrimary from '../../../../components/Buttons/ButtonPrimary';
import Attachments from '../../../../containers/Attachments/Attachments';
import { useDispatch } from 'react-redux';
import { LeaveRequestType } from '../../../../helpers/enum/leaveRequest';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { VALIDATION_MSG, DATE_VALIDATION_MSG } from '../../../../helpers/enum/errorValidationMsgs';
import { useYupValidationResolver } from '../../../../utils/hooks/useYupValidationResolver';
import AdminCreateLRForm from '../../../../containers/LeaveRequests/AdminCreateLRForm';
import { showSuccessNotification } from '../../../../store/app/actions';
import useApi from '../../../../hooks/useApi';
import Modal from '../../../../components/Modals/Modal';

const AdminCreateLeaveRequestModal = ({ show, hide, userId }) => {
  const dispatch = useDispatch();
  const {
    leaveRequests: { uploadFile, createLeaveRequestByAdmin },
    users: { getUsersWithActiveContracts },
  } = useApi();

  const [data, _setData] = useState({
    user: null,
    status: null,
    startDate: null,
    startHalfDay: false,
    endDate: null,
    endHalfDay: false,
    deductedLeaveDays: null,
    requestType: null,
    description: '',
  });
  const [documents, setDocuments] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [users, setUsers] = useState([]);

  const validationSchema = Yup.object().shape({
    user: Yup.object().required(VALIDATION_MSG),
    status: Yup.number().required(VALIDATION_MSG),
    description: Yup.string().trim(),
    startDate: Yup.date().required(VALIDATION_MSG).nullable(),
    endDate: Yup.date()
      .required(VALIDATION_MSG)
      .nullable()
      .when('startDate', (startDate, schema) => startDate && schema.min(startDate, DATE_VALIDATION_MSG)),
    requestType: Yup.number().required(VALIDATION_MSG).nullable(),
  });

  const formOptions = { resolver: useYupValidationResolver(validationSchema) };

  useEffect(() => {
    //FIXME: This is used through Timesheet modal, so technically it should fetch only the user being viewed and get the active contract that user had at the moment of that timesheet... But this is unlikely to cause issue any time soon, because HR will use it generally in the current month (or previous or so, but not much more)
    getUsersWithActiveContracts().then(data => {
      setUsers(data);
    });
  }, []);

  useEffect(() => {
    if (!userId || !users.length) return;
    const selectedUser = users.find(el => el.id === userId);
    _setData(prev => ({ ...prev, user: selectedUser }));
  }, [userId, users]);

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    control,
    watch,
    formState: { errors },
  } = useForm(formOptions);

  const setNewData = newValue => {
    Object.entries(newValue).forEach(([name, value]) => {
      if (value || (name === 'requestType' && (value || value == 0))) {
        clearErrors(name);
      }
      setValue(name, value);
    });
    _setData({ ...data, ...newValue });
  };

  const resetData = () => {
    //resets all except user, for resetting form when admin changes selected user;
    Object.entries(data).forEach(([name, value]) => {
      if (name === 'startHalfDay' || name === 'endHalfDay') {
        setValue(name, false);
      } else if (name === 'description') {
        setValue(name, '');
      } else if (name === 'user') {
        setValue(name, value);
      } else {
        setValue(name, null);
      }
      clearErrors(name);
    });
    _setData(prev => ({
      user: prev.user,
      status: null,
      startDate: null,
      startHalfDay: false,
      endDate: null,
      endHalfDay: false,
      deductedLeaveDays: null,
      requestType: null,
      description: '',
    }));
  };

  const uploadFileHandler = fileInfo => {
    if (!fileInfo) return;
    if (!documents.find(d => d.resource === fileInfo.name))
      setDocuments(preValue => [
        ...preValue,
        {
          id: new Date().getTime(),
          resource: fileInfo.name,
          rawFile: fileInfo,
        },
      ]);
    setUploading(false);
  };

  const RemoveFileHandler = id => {
    setDocuments(pre => [...pre.filter(document => document.id != id)]);
  };

  const handleHide = () => {
    hide();
    setTimeout(() => resetData(), 500);
  };

  const sendRequestHandler = async () => {
    const userId = data.user.id;

    const dataToSend = {
      body: data.description || null,
      start_date: data.startDate,
      end_date: data.endDate,
      start_half_day: data.startHalfDay,
      end_half_day: data.endHalfDay,
      leave_type: data.requestType,
      status: data.status,
      staff_contract_id: data.user.StaffContracts[0].id,
    };

    createLeaveRequestByAdmin(userId, dataToSend).then(async res => {
      if (documents.length) {
        setUploading(true);
        for (let i = 0; i < documents.length; i++) {
          if (i != documents.length - 1) {
            uploadFile(documents[i].rawFile, res.uuid);
          } else {
            uploadFile(documents[i].rawFile, res.uuid).then(res => {
              setUploading(false);
              handleHide();
              dispatch(showSuccessNotification('Leave request created successfully'));
            });
          }
        }
      } else {
        handleHide();
        dispatch(showSuccessNotification('Leave request created successfully'));
      }
    });
  };

  return (
    <Modal show={show} hide={handleHide} size="xl" title="Add leave request">
      <div className="grid grid-cols-1 gap-y-8 max-w-3xl w-full mx-auto">
        <AdminCreateLRForm
          setData={setNewData}
          resetData={resetData}
          data={data}
          register={register}
          errors={errors}
          control={control}
          users={users}
          createThroughTimesheetCalendar={true}
        />
        <Attachments
          uploadHandler={uploadFileHandler}
          maxFileSizeInMb={10}
          enableDownload={false}
          removeHandler={RemoveFileHandler}
          documents={documents}
          uploading={uploading}
          setUploading={setUploading}
        />
        <ButtonPrimary text="Upload request" size="xl" onClick={handleSubmit(sendRequestHandler)} />
      </div>
    </Modal>
  );
};

export default AdminCreateLeaveRequestModal;
