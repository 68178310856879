import React from 'react';
import { ExclamationIcon } from '@heroicons/react/outline';

const WarningText = ({ text, className, iconClassName, onMouseOver }) => {
  return (
    <div
      className={`${className} col-span-3 bg-red-100 rounded-lg flex space-x-1 text-red-700 items-center text-md p-4 relative z-12`}
      onMouseOver={onMouseOver}
      onMou
    >
      <ExclamationIcon className={`${iconClassName} h-6 w-6 mr-2 text-red-500 z-12`} />
      <p className="z-12">{text}</p>
    </div>
  );
};

export default WarningText;
