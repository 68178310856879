import React from 'react';
import { ChevronRightIcon, CalendarIcon, CurrencyEuroIcon, ClockIcon } from '@heroicons/react/solid';
import { DocumentTextIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import paidStatus from '../../helpers/enum/paidStatus';
import formatCurrency from '../../utils/formatCurrency';
import InfoTooltip from '../../components/Tooltips/InfoTooltip';

function InvoiceTabItem({ invoice }) {
  const isPaid = invoice.paid === paidStatus.numbers.paid ? true : false;
  return (
    <li className="" key={invoice.id}>
      <Link to={`/my-invoices/${invoice.id}`} className="group block rounded-lg py-2 px-2 sm:py-3 sm:px-0">
        <div className="hover:bg-gray-100 flex items-center py-3 px-2 sm:py-3 sm:px-0 rounded-lg ">
          <div className="min-w-0 flex-1 flex items-center overflow-x-hidden text-gray-400">
            <DocumentTextIcon className="flex-shrink-0 h-12 w-12  rounded-full group-hover:opacity-75 group-hover:text-gray-700" />
            <div className="px-4 grid grid-cols-1 md:grid-cols-3 w-full overflow-hidden">
              <div className="col-span-3 md:col-span-2">
                <div className="flex gap-x-1 items-center">
                  <p className="text-sm font-medium text-thaleria-blue-600 truncate">
                    {invoice?.invoice_number
                      ? `Invoice #${invoice.invoice_number}`
                      : `Invoice for ${format(
                          new Date(new Date(invoice.due_date).setMonth(new Date(invoice.due_date).getMonth() - 2)),
                          'MMMM yyyy',
                        )}`}{' '}
                  </p>
                  {invoice.paid === paidStatus.numbers.partiallyPaid ? (
                    <InfoTooltip size="auto">A partial advance was processed. An outstanding amount remains.</InfoTooltip>
                  ) : (
                    ''
                  )}
                </div>

                <div className="flex gap-x-4">
                  <div className="flex text-sm">
                    <ClockIcon className="flex-shrink-0 h-5 w-5 " aria-hidden="true" />
                    <span className="truncate ml-1">Uploaded on {format(new Date(invoice?.created_at), 'dd/MM/yyyy')}</span>
                  </div>
                  <div className="flex text-sm">
                    <CurrencyEuroIcon className="flex-shrink-0 h-5 w-5 " aria-hidden="true" />
                    <span className="truncate ml-1">{formatCurrency(invoice?.total_and_vat, true)}</span>
                  </div>
                  {isPaid ? (
                    <div className="flex text-sm">
                      <ClockIcon className="flex-shrink-0 h-5 w-5 " aria-hidden="true" />
                      <span className="truncate ml-1">Processed on {format(new Date(invoice?.payment_date), 'dd/MM/yyyy')}</span>
                    </div>
                  ) : (
                    <div className="flex text-sm">
                      <CalendarIcon className="flex-shrink-0 h-5 w-5 " aria-hidden="true" />
                      <span className="truncate ml-1">Due on {format(new Date(invoice?.due_date), 'dd/MM/yyyy')}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="hidden md:block text-sm text-gray-900 ml-4 w-44"></div>
            </div>
          </div>
          <div>
            <ChevronRightIcon className="h-5 w-5 text-gray-400 group-hover:text-gray-700" aria-hidden="true" />
          </div>
        </div>
      </Link>
    </li>
  );
}

export default InvoiceTabItem;
