import React, { useState } from 'react';
import ShortToggle from '../Toggles/ShortToggle';
import ButtonPrimary from '../Buttons/ButtonPrimary';
import ButtonWhite from '../Buttons/ButtonWhite';
import Modal from './Modal';
import useApi from '../../hooks/useApi';

function FilterColumns({
  headerGroups,
  onClick,
  showColumnFilter,
  setShowColumnFilter,
  setAllFilters,
  showColumnGroup,
  show,
  setShow,
  setFilterContractsUpForExtension,
  filterContractsUpForExtension,
  filters,
  userSettings,
  setUserSettings,
  tableName,
}) {
  const {
    settings: { updateTableSettings },
  } = useApi();

  const handleResetFilters = () => {
    setAllFilters([]);
  };

  const updateFilterSettings = async () => {
    let updated = {
      ...userSettings,
      [tableName]: {
        ...userSettings[tableName],
        filters: filters,
        filterContractsUpForExtension: filterContractsUpForExtension,
      },
    };
    setUserSettings(updated);
    await updateTableSettings(updated);
  };

  const handleSaveFilters = () => {
    updateFilterSettings();
    setShow(false);
  };

  const handleToggle = (label, columnAccessor) => {
    setShowColumnFilter({ [label]: !showColumnFilter[label] });

    const columnArrayToFilter = [];
    headerGroups[1].headers.map(column =>
      column.parent?.id === columnAccessor ? columnArrayToFilter.push({ id: column.id }) : null,
    );
    if (showColumnFilter[label]) setAllFilters(columnArrayToFilter);
  };

  return (
    <Modal show={show} hide={() => setShow(false)} title="Filter results" size="lg">
      <div role="dialog" aria-modal="true" aria-labelledby="modal-headline">
        <div>
          <div className="bg-gray-100 flex justify-between items-center px-4 py-4">
            <span className="ml-2">Contracts up for extension</span>
            <ShortToggle
              bg={'bg-gray-100'}
              active={filterContractsUpForExtension}
              onClick={() => setFilterContractsUpForExtension(prev => !prev)}
            />
          </div>
          {showColumnGroup.consultant && (
            <>
              <div className="bg-gray-100 flex justify-between items-center px-4 py-4 mt-2">
                <span className="ml-2">Consultant</span>
                <ShortToggle
                  bg={'bg-gray-100'}
                  active={showColumnFilter.consultant}
                  onClick={() => handleToggle('consultant', 'consultant')}
                />
              </div>
              {showColumnFilter.consultant &&
                headerGroups[1].headers.map(column => {
                  if (column.id.match(/^consultant/)) {
                    if (column.isVisible)
                      return (
                        <div className="py-4 px-8">
                          <span className="block text-sm font-medium text-gray-700">{column.Header}</span>
                          {column.canFilter ? column.render('Filter') : null}
                        </div>
                      );
                  }
                })}
            </>
          )}
          {showColumnGroup.staffOrder && (
            <>
              <div className="bg-gray-100 mt-2 flex justify-between items-center px-4 py-4">
                <span className="ml-2">Purchase Order</span>
                <ShortToggle
                  bg={'bg-gray-100'}
                  active={showColumnFilter.staffOrder}
                  onClick={() => handleToggle('staffOrder', 'header_staffOrder')}
                />
              </div>
              {showColumnFilter.staffOrder &&
                headerGroups[1].headers.map(column => {
                  if (column.id.match(/^staffOrder_/)) {
                    if (column.isVisible)
                      return (
                        <div className="py-4 px-8">
                          <span className="block text-sm font-medium text-gray-700">{column.Header}</span>
                          {column.canFilter ? column.render('Filter') : null}
                        </div>
                      );
                  }
                })}
            </>
          )}
          {showColumnGroup.staffContract && (
            <>
              <div className="bg-gray-100 mt-2 flex justify-between items-center px-4 py-4">
                <span className="ml-2">Staff Contract</span>
                <ShortToggle
                  bg={'bg-gray-100'}
                  active={showColumnFilter.staffContract}
                  onClick={() => handleToggle('staffContract', 'header_staffContract')}
                />
              </div>
              {showColumnFilter.staffContract &&
                headerGroups[1].headers.map(column => {
                  if (column.id.match(/^staffContract/)) {
                    if (column.isVisible)
                      return (
                        <div className="py-4 px-8">
                          <span className="block text-sm font-medium text-gray-700">{column.Header}</span>
                          {column.canFilter ? column.render('Filter') : null}
                        </div>
                      );
                  }
                })}
            </>
          )}
          {showColumnGroup.frameworkContract && (
            <>
              <div className="bg-gray-100 mt-2 flex justify-between items-center px-4 py-4">
                <span className="ml-2">End-Client Framework Contract</span>
                <ShortToggle
                  bg={'bg-gray-100'}
                  active={showColumnFilter.frameworkContract}
                  onClick={() => handleToggle('frameworkContract', 'header_frameworkContract')}
                />
              </div>
              {showColumnFilter.frameworkContract &&
                headerGroups[1].headers.map(column => {
                  if (column.id.match(/^frameworkContract/)) {
                    if (column.isVisible)
                      return (
                        <div className="py-4 px-8">
                          <span className="block text-sm font-medium text-gray-700">{column.Header}</span>
                          {column.canFilter ? column.render('Filter') : null}
                        </div>
                      );
                  }
                })}
            </>
          )}
          {showColumnGroup.partnerContract && (
            <>
              <div className="bg-gray-100 mt-2 flex justify-between items-center px-4 py-4">
                <span className="ml-2">Sales Framework Contract</span>
                <ShortToggle
                  bg={'bg-gray-100'}
                  active={showColumnFilter.partnerContract}
                  onClick={() => handleToggle('partnerContract', 'header_partnerContract')}
                />
              </div>
              {showColumnFilter.partnerContract &&
                headerGroups[1].headers.map(column => {
                  if (column.id.match(/^partnerContract/)) {
                    if (column.isVisible)
                      return (
                        <div className="py-4 px-8">
                          <span className="block text-sm font-medium text-gray-700">{column.Header}</span>
                          {column.canFilter ? column.render('Filter') : null}
                        </div>
                      );
                  }
                })}
            </>
          )}
          {showColumnGroup.salesOrder && (
            <>
              <div className="bg-gray-100 mt-2 flex justify-between items-center px-4 py-4">
                <span className="ml-2">Sales Order</span>
                <ShortToggle
                  bg={'bg-gray-100'}
                  active={showColumnFilter.salesOrder}
                  onClick={() => handleToggle('salesOrder', 'header_salesOrder')}
                />
              </div>
              {showColumnFilter.salesOrder &&
                headerGroups[1].headers.map(column => {
                  if (column.id.match(/^salesOrder/)) {
                    if (column.isVisible)
                      return (
                        <div className="py-4 px-8">
                          <span className="block text-sm font-medium text-gray-700">{column.Header}</span>
                          {column.canFilter ? column.render('Filter') : null}
                        </div>
                      );
                  }
                })}
            </>
          )}
          {showColumnGroup.specificContract && (
            <>
              <div className="bg-gray-100 mt-2 flex justify-between items-center px-4 py-4">
                <span className="ml-2">Specific Contract</span>
                <ShortToggle
                  bg={'bg-gray-100'}
                  active={showColumnFilter.specificContract}
                  onClick={() => handleToggle('specificContract', 'header_specificContract')}
                />
              </div>
              {showColumnFilter.specificContract &&
                headerGroups[1].headers.map(column => {
                  if (column.id.match(/^specificContract/)) {
                    if (column.isVisible)
                      return (
                        <div className="py-4 px-8">
                          <span className="block text-sm font-medium text-gray-700">{column.Header}</span>
                          {column.canFilter ? column.render('Filter') : null}
                        </div>
                      );
                  }
                })}
            </>
          )}
        </div>
        <div className="flex flex-row-reverse mt-5 mx-6 sm:mt-6">
          <ButtonPrimary onClick={handleSaveFilters} text={'Save'} />
          <ButtonWhite onClick={handleResetFilters} style={'mr-2'} text={'Reset filters'} />
        </div>
      </div>
    </Modal>
  );
}

export default FilterColumns;
