import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ProfilePicture from '../ProfilePicture/ProfilePicture';
import UserImagePlaceholder from '../UserImagePlaceholder/UserImagePlaceholder';
import dayjs from 'dayjs';
import { TrashIcon } from '@heroicons/react/outline';
import { add, intervalToDuration, isBefore, sub } from 'date-fns';

const ChatMessage = ({ chatId, text, time, ownerImg, ownerName, ownerId, deleteMessage, createdAt }) => {
  const currentUser = useSelector(state => state.auth.currentUser);
  const momentOfPublish = new Date(createdAt);
  let interval;
  const [counter, setCounter] = useState();
  const [canDelete, setCanDelete] = useState(false);

  const checkCanDelete = () => {
    const now = new Date();
    if (!isBefore(momentOfPublish, sub(now, { minutes: 2 }))) {
      const minutesAndSeconds = intervalToDuration({ start: new Date(add(momentOfPublish, { minutes: 2 })), end: now });
      setCounter(
        `0${minutesAndSeconds.minutes}:${
          minutesAndSeconds.seconds < 10 ? `0${minutesAndSeconds.seconds}` : minutesAndSeconds.seconds
        }`,
      );
      if (!canDelete) setCanDelete(true);
    } else {
      setCanDelete(false);
      clearInterval(interval);
    }
  };

  useEffect(() => {
    if (!interval && ownerId === currentUser.id && !isBefore(momentOfPublish, sub(new Date(), { minutes: 2 }))) {
      interval = setInterval(checkCanDelete, 1000);
    }
    return () => clearInterval(interval);
  }, []);

  return (
    <li key={chatId}>
      <div className="flex space-x-3 group">
        <div className="flex-shrink-0">
          <ProfilePicture
            src={ownerImg}
            className="w-10 h-10 rounded-full"
            alt=""
            defaultimage={<UserImagePlaceholder className="w-10 h-10 rounded-full" />}
          />
        </div>
        <div className="w-full">
          <div className="flex justify-between">
            <div className="flex justify-start w-full items-center gap-x-1">
              <span className="font-medium text-gray-700">{ownerName}</span>
              <span className="text-gray-500 text-sm">&#x2022;</span>
              <span className="text-gray-500 text-sm">
                {dayjs(time).format('MMM DD, YYYY') + ', ' + dayjs(time).format('HH:mm')}
              </span>
            </div>
            {canDelete && (
              <div className="flex items-center">
                <p className="text-gray-400 text-sm w-10">{counter}</p>
                <TrashIcon
                  className={`${canDelete === true ? 'block' : 'hidden'} text-red-500 h-4 w-4 cursor-pointer hover:text-red-600`}
                  onClick={() => deleteMessage(chatId)}
                />
              </div>
            )}
          </div>
          <div className="flex justify-between w-full items-center">
            <span className="mt-1 text-sm text-gray-500" style={{ whiteSpace: 'pre-wrap' }}>
              {text}
            </span>
          </div>
        </div>
      </div>
    </li>
  );
};

export default ChatMessage;

ChatMessage.prototype = {
  ownerName: PropTypes.string,
  ownerImg: PropTypes.string,
  text: PropTypes.string,
  time: PropTypes.string,
};
