import React, { useState, useEffect } from 'react';
import Modal from './Modal';
import SimpleEntry from '../DescriptionEntries/SimpleEntry';
import InputSimple from '../Inputs/InputSimple';
import formatNumber from '../../utils/formatNumber';
import ButtonPrimary from '../Buttons/ButtonPrimary';
import ButtonWhite from '../Buttons/ButtonWhite';
import formatCurrency from '../../utils/formatCurrency';
import { lineItemTypesV2 } from '../../helpers/enum/lineItemTypes';

function EditLineItemDecimalPlaces({ show, setShow, lineItem, onConfirm }) {
  const [decimalPlaces, setDecimalPlaces] = useState(3);
  const [units, setUnits] = useState(null);
  const [total, setTotal] = useState(null);
  const [vat, setVat] = useState(null);
  const [totalAndVat, setTotalAndVat] = useState(null);

  useEffect(() => {
    if (!lineItem) return;
    let _decimal_places = lineItem?.metadata?.new_decimal_places || lineItem?.metadata?.decimal_places || 3;
    setDecimalPlaces(_decimal_places);
    setUnits(
      formatNumber(lineItem?.units, false, lineItem?.metadata?.new_decimal_places || lineItem?.metadata?.decimal_places || 3),
    );

    setTotal(lineItem?.total);
    setVat(lineItem?.vat);
    setTotalAndVat(lineItem?.total_and_vat);
  }, [lineItem]);

  const onChangeDecimals = value => {
    setDecimalPlaces(value);
    const newUnits = formatNumber(lineItem?.metadata?.hours / 8, false, value);
    setUnits(newUnits);
    let isNegativeAmount = lineItem.type === lineItemTypesV2.accountsPayable.numbers.projectAllocationCreditNoteAdjustment;
    const total = formatNumber(newUnits * Number(lineItem.price)) * (isNegativeAmount ? -1 : 1);
    const vat = formatNumber(newUnits * Number(lineItem.price) * (Number(lineItem?.vat_rate) / 100));
    const totalAndVat = formatNumber(total + vat);
    setTotal(total);
    setVat(vat);
    setTotalAndVat(totalAndVat);
  };

  const onCancel = () => {
    setShow(false);
    setTimeout(() => {
      setDecimalPlaces(3);
      setUnits(null);
      setTotal(null);
      setVat(null);
      setTotalAndVat(null);
    }, 500);
  };

  return (
    <Modal show={show} hide={() => setShow(false)} title="Edit decimal places" size="md">
      <div role="dialog" aria-modal="true" aria-labelledby="modal-headline">
        <div className="grid grid-cols-4 gap-2 sm:gap-8 mt-8">
          <div className="col-span-4 w-32">
            <InputSimple
              label="Decimal places"
              type="number"
              max={6}
              min={2}
              value={decimalPlaces}
              onChange={e => onChangeDecimals(e.target.value)}
              onKeyDown={e => e.preventDefault()}
            />
          </div>
          <SimpleEntry label="Units" data={units} />
          <SimpleEntry label="Total Excl VAT" data={formatCurrency(total)} />
          <SimpleEntry label="VAT" data={formatCurrency(vat)} />
          <SimpleEntry label="Total Incl VAT" data={formatCurrency(totalAndVat)} />
        </div>
        <div className="flex flex-row-reverse mt-5 sm:mt-8">
          <ButtonPrimary style={'ml-2'} text={'Confirm'} onClick={() => onConfirm(decimalPlaces)} />
          <ButtonWhite onClick={() => onCancel()} style={'mr-2'} text={'Cancel'} />
        </div>
      </div>
    </Modal>
  );
}

export default EditLineItemDecimalPlaces;
