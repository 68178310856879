import React, { useState } from 'react';
import InputDate from '../Inputs/InputDate/InputDate';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';

function MonthRangeColumnFilter({ column: { filterValue = [], preFilteredRows, setFilter, id, filterOptions } }) {
  dayjs.extend(utc);
  dayjs.extend(customParseFormat);
  const [min, max] = React.useMemo(() => {
    if (filterOptions) return [filterOptions.minDate, filterOptions.maxDate];
    else return [null, null];
    // let min = preFilteredRows.length ? dayjs.utc(preFilteredRows[0].values[id]) : 0;
    // let max = preFilteredRows.length ? dayjs.utc(preFilteredRows[0].values[id]) : 0;
    // preFilteredRows.forEach(row => {
    //   min = dayjs.utc(row.values[id]).isBefore(dayjs.utc(min)) ? dayjs.utc(row.values[id]) : min;
    //   max = dayjs.utc(row.values[id]).isAfter(dayjs.utc(max)) ? dayjs.utc(row.values[id]) : max;
    // });
    // return [min, max];
  }, [id, preFilteredRows]);

  const [beginning, setBeginning] = useState(null);
  const [end, setEnd] = useState(null);

  return (
    <>
      <div className={'flex items-center flex-col sm:flex-row'}>
        <span className="mx-2">From</span>
        <InputDate
          onlyMonth
          className={`${
            filterValue[0] ? '' : 'text-gray-500 '
          } shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md`}
          onChange={val => {
            setBeginning(val ? new Date(val) : undefined);
            setFilter((old = []) => {
              return [val ? new Date(val) : undefined, old[1]];
            });
          }}
          placeholder={min ? `Min (${dayjs(min).format('YYYY/MM')})` : null}
          minDate={min ? min : null}
          selected={filterValue[0] ? filterValue[0] : beginning}
        />
        <span className="mx-2">To</span>
        <InputDate
          onlyMonth
          className={`${
            filterValue[0] ? '' : 'text-gray-500 '
          } shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md`}
          onChange={val => {
            setEnd(val ? new Date(val) : undefined);
            setFilter((old = []) => [old[0], val ? new Date(val) : undefined]);
          }}
          placeholder={max ? `Max (${dayjs(max).format('YYYY/MM')})` : null}
          maxDate={max ? max : null}
          selected={filterValue[1] ? filterValue[1] : end}
        />
      </div>
    </>
  );
}

export default MonthRangeColumnFilter;
