import React from 'react';

function ShortToggle({ active, onClick, bg }) {
  return (
    <button
      type="button"
      onClick={onClick}
      className="flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      aria-pressed="false"
    >
      <span className="sr-only">Use setting</span>
      <span aria-hidden="true" className={`pointer-events-none absolute ${bg ? bg : 'bg-white'} w-full h-full rounded-md`}></span>
      {/* <!-- Enabled: "bg-indigo-600", Not Enabled: "bg-gray-200" --> */}
      <span
        aria-hidden="true"
        className={`${
          active ? 'bg-indigo-600' : 'bg-gray-200'
        } pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200`}
      ></span>
      {/* <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" --> */}
      <span
        aria-hidden="true"
        className={`${
          active ? 'translate-x-5' : 'translate-x-0'
        } pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200`}
      ></span>
    </button>
  );
}

export default ShortToggle;
