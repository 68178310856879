import React, { useEffect, useState } from 'react';
import InputDate from '../Inputs/InputDate/InputDate';
import InputSimple from '../Inputs/InputSimple';
import ButtonPrimary from '../Buttons/ButtonPrimary';
import ButtonWhite from '../Buttons/ButtonWhite';
import Modal from './Modal';
import paidStatus from '../../helpers/enum/paidStatus';
import ReactSelect from '../Inputs/ReactSelect';
import Checkbox from '../Checkbox/Checkbox';

function PayInvoicesInBulk({ onConfirm, show, setShow, type }) {
  const [paid, setPaid] = useState(null);
  const [paymentDate, setPaymentDate] = useState(new Date());
  const [sent, setSent] = useState(false);

  const onCancel = () => {
    setShow(false);
    setTimeout(() => {
      setPaid(null);
      setPaymentDate(new Date());
      setSent(false);
    }, 500); // Delay by 0.5 seconds (500ms)
  };

  const paidOptions = [
    {
      value: paidStatus.numbers.paid,
      label: paidStatus.strings[paidStatus.numbers.paid],
    },
    {
      value: paidStatus.numbers.unpaid,
      label: paidStatus.strings[paidStatus.numbers.unpaid],
    },
  ];

  const onPaidChange = e => {
    setPaid(e.value);
    setSent(e?.value === paidStatus.numbers.paid ? true : false);
  };

  const onClick = () => {
    if (paid === paidStatus.numbers.paid) {
      onConfirm(paid, paymentDate, sent);
    } else {
      onConfirm(paid, null, sent);
    }
    onCancel();
  };

  return (
    <Modal show={show} hide={() => onCancel()} title="Pay invoice" size="sm">
      <div role="dialog" aria-modal="true" aria-labelledby="modal-headline">
        <div className="grid grid-cols-2 gap-4 sm:gap-8 mt-8">
          <ReactSelect
            label="Status*"
            className="col-span-2"
            options={paidOptions}
            selectedOptionsIds={[paid]}
            onChange={e => onPaidChange(e)}
          />
          {type !== 'accountsPayable' && paid !== null && (
            <Checkbox title="Sent" value={sent} onChange={() => setSent(!sent)} disabled={paid === paidStatus.numbers.paid} />
          )}

          {paid === paidStatus.numbers.paid && (
            <InputDate
              className="col-span-1 w-full"
              label="Payment date*"
              selected={paymentDate}
              onChange={e => setPaymentDate(e)}
            />
          )}
        </div>
        <div className="flex flex-row-reverse mt-5 sm:mt-8">
          <ButtonPrimary style={'ml-2'} text={'Confirm'} disable={isNaN(paid)} onClick={() => onClick()} />
          <ButtonWhite onClick={() => onCancel()} style={'mr-2'} text={'Cancel'} />
        </div>
      </div>
    </Modal>
  );
}

export default PayInvoicesInBulk;
