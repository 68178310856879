import React from 'react';
import ButtonPrimary from '../../components/Buttons/ButtonPrimary';
import ButtonWhite from '../../components/Buttons/ButtonWhite';
import Modal from '../../components/Modals/Modal';
import useApi from '../../hooks/useApi';

function EditColumns({
  allColumns,
  toggleHideAllColumns,
  show,
  setShow,
  userSettings,
  setUserSettings,
  tableName,
  setColumnOrder,
  columnOrder,
}) {
  const {
    settings: { updateTableSettings },
  } = useApi();

  const handleResetColumns = () => {
    toggleHideAllColumns(false);
    setColumnOrder([]);
  };

  const saveColumnSettings = async () => {
    let updated = {
      ...userSettings,
      [tableName]: {
        ...userSettings[tableName],
        columns: allColumns.map(col => {
          const _col = {
            Header: col.Header,
            isVisible: col.isVisible,
            accessor: col.accessor,
          };

          if (col?.parent) _col['parent'] = col?.parent?.Header;
          return _col;
        }),
      },
    };
    if (userSettings[tableName] && JSON.stringify(userSettings[tableName].customColumnOrder) !== JSON.stringify(columnOrder)) {
      updated[tableName].customColumnOrder = columnOrder;
    }
    setUserSettings(updated);
    await updateTableSettings(updated);
  };

  const handleSaveColumns = () => {
    saveColumnSettings();
    setShow(false);
  };

  return (
    <Modal show={show} hide={() => setShow(false)} title="Edit columns" size="lg">
      {/* Modal */}
      <div role="dialog" aria-modal="true" aria-labelledby="modal-headline">
        <div className="flex flex-wrap gap-4">
          {allColumns.map(column => {
            return (
              <div className="relative flex flex-wrap mt-2 ml-4 items-start">
                <div key={column.id} className="ml-3 text-sm w-40">
                  <label className="font-medium text-gray-700">{column.Header}</label>
                </div>
                <div className="flex items-center ml-4 h-5">
                  <input
                    type="checkbox"
                    {...column.getToggleHiddenProps()}
                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                  />
                </div>
              </div>
            );
          })}
        </div>
        <div className="flex flex-row-reverse mt-5 mx-6 sm:mt-6">
          <ButtonPrimary onClick={handleSaveColumns} style={'ml-2'} text={'Save'} />
          <ButtonWhite onClick={handleResetColumns} style={'mr-2'} text={'Reset columns'} />
        </div>
      </div>
    </Modal>
  );
}

export default EditColumns;
