import React, { useState, useRef, useEffect } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import ButtonPrimary from '../Buttons/ButtonPrimary';

const ButtonWithDropdownMenu = ({ buttonText, buttonColor, buttonClassName, options }) => {
  const buttonRef = useRef(null);
  const [menuWidth, setMenuWidth] = useState('auto');

  useEffect(() => {
    if (buttonRef.current) {
      setMenuWidth(`${buttonRef.current.offsetWidth}px`);
    }
  }, [buttonRef]);

  const buttonColorStyle = () => {
    switch (buttonColor) {
      case 'red':
        return 'bg-red-600 hover:bg-red-700 focus:ring-red-500';
      case 'orange':
        return 'bg-orange-600 hover:bg-orange-700 focus:ring-orange-500';
      case 'green':
        return 'bg-green-600 hover:bg-green-700 focus:ring-green-500';
      default:
        return 'bg-thaleria-orange-700 hover:bg-thaleria-orange-800 focus:ring-thaleria-orange-600';
    }
  };

  if (!options || options.length === 0) {
    return null;
  }

  if (options.length === 1) {
    const option = options[0];
    //need to add margins to icon to correctly fit in the ButtonPrimary component, otherwise it looks different to other buttons
    const modifiedIcon = React.cloneElement(option.icon, { style: `${option.icon.props.style} -ml-1 mr-2` });
    return <ButtonPrimary text={option.text} icon={modifiedIcon} onClick={option.onClick} className={buttonClassName} />;
  }

  return (
    <div className="relative inline-block text-left">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button
            ref={buttonRef}
            className={`inline-flex justify-center items-center border border-transparent px-4 py-2 text-sm font-medium text-white ${buttonColorStyle()} ${buttonClassName} focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-md`}
          >
            <ChevronDownIcon className="-ml-1 mr-2 h-5 w-5 text-white" />
            {buttonText || 'More options'}
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className="origin-top-right absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
            style={{ minWidth: menuWidth }}
          >
            {options.map((option, index) => (
              <Menu.Item key={index}>
                {({ active }) => (
                  <button
                    onClick={option.onClick}
                    className={`${
                      active ? 'bg-gray-100' : 'text-gray-900'
                    } group flex rounded-none items-center w-full pl-2 pr-3 py-2 text-sm whitespace-nowrap ${
                      index === 0 ? 'rounded-t-md' : ''
                    } ${index === options.length - 1 ? 'rounded-b-md' : ''}`}
                  >
                    {option.icon && <span className="mr-2 h-5 w-5">{option.icon}</span>}
                    {option.text}
                  </button>
                )}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default ButtonWithDropdownMenu;
