export const AccountType = {
  ROOT: 1,
  ADMIN: 2,
  STAFF: 3,
  USER: 4,
  SYSTEM: 5,
};

export const ACCOUNT_TYPE = {
  ROOT: {
    id: 1,
    name: 'Root',
  },
  ADMIN: {
    id: 2,
    name: 'Admin',
  },
  STAFF: {
    id: 3,
    name: 'Staff',
  },
  USER: {
    id: 4,
    name: 'User',
  },
};
