import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import Card from '../../components/Cards/Card';
import PlusMedium from '../../components/Icons/PlusMediumIcon';
import Loader from '../../components/Loading/Loader';
import ClipLoader from 'react-spinners/ClipLoader';
import { DownloadIcon, EyeIcon, TrashIcon } from '@heroicons/react/outline';

const Attachments = ({
  removeHandler,
  downloadHandler,
  uploadHandler,
  documents,
  enableAdd = true,
  enableRemove = true,
  enableDownload = true,
  deleting,
  setDeleting,
  downloading,
  setDownloading,
  loadingFolder,
  uploading,
  setUploading,
  accept,
  maxFileSizeInMb,
  setPreparingPreview,
  previewHandler,
  preparingPreview,
}) => {
  const inputFile = useRef(null);

  const onChangeFile = event => {
    event.stopPropagation();
    event.preventDefault();
    let file = event.target.files[0];
    if (maxFileSizeInMb) {
      if (file?.size > maxFileSizeInMb * 1000000) {
        alert(`Maximum file size is ${maxFileSizeInMb}mb`);
        return null;
      }
    }
    setUploading && setUploading(true);
    uploadHandler && uploadHandler(file);
  };

  const onPreviewHandler = id => {
    setPreparingPreview && setPreparingPreview(id);
    previewHandler && previewHandler(id);
  };

  const onDownloadHandler = id => {
    setDownloading && setDownloading(id);
    downloadHandler && downloadHandler(id);
  };
  const onRemoveHandler = id => {
    setDeleting && setDeleting(id);
    removeHandler && removeHandler(id);
  };

  const handleClick = () => {
    inputFile.current.value = '';
    inputFile.current.click();
  };

  //accept images, PDF and MS Office files
  const defaultFileTypes = `.csv, image/png,
  image/jpg,
  image/jpeg,
  image/gif,
  image/x-png,
  application/pdf,
  application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document,
  application/vnd.openxmlformats-officedocument.wordprocessingml.template,
  application/vnd.ms-word.document.macroEnabled.12,
  application/vnd.ms-word.template.macroEnabled.12,
  application/vnd.ms-excel,
  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
  application/vnd.openxmlformats-officedocument.spreadsheetml.template,
  application/vnd.ms-excel.sheet.macroEnabled.12,
  application/vnd.ms-excel.template.macroEnabled.12,
  application/vnd.ms-excel.sheet.binary.macroEnabled.12,
  application/vnd.ms-excel.addin.macroEnabled.12,
  application/vnd.ms-powerpoint,
  application/vnd.openxmlformats-officedocument.presentationml.template,
  application/vnd.openxmlformats-officedocument.presentationml.slideshow,
  application/vnd.openxmlformats-officedocument.presentationml.presentation,
  application/vnd.ms-powerpoint.presentation.macroEnabled.12,
  application/vnd.ms-powerpoint.template.macroEnabled.12,
  application/vnd.ms-powerpoint.slideshow.macroEnabled.12`;

  const isPreviewable = fileName => {
    let bool = false;
    const extension = fileName.split('.')[fileName.split('.').length - 1].toLowerCase();
    if (extension === 'pdf' || extension === 'jpg' || extension === 'png' || extension === 'jpeg') bool = true;
    return bool;
  };

  return (
    <Card
      title="Attachments"
      onClick={handleClick}
      optionalButtonText={`${uploading ? '' : 'Add Document'}`}
      optionalButtonIcon={
        uploading ? (
          <ClipLoader className="mr-10 h-5 w-5" size={18} color={'#FFFF'} />
        ) : (
          <PlusMedium style={'-ml-1 mr-2 h-5 w-5 text-white'} />
        )
      }
      optionalButton={enableAdd}
    >
      <input
        type="file"
        id="file"
        accept={accept || defaultFileTypes}
        ref={inputFile}
        onChange={onChangeFile}
        style={{ display: 'none' }}
      />

      <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-3">
        <Loader isLoading={loadingFolder} className="flex justify-around rounded-lg h-full w-full">
          {(documents && documents.length === 0) || !documents ? (
            <span className="text-sm text-gray-500">No documents currently uploaded.</span>
          ) : (
            <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
              {documents &&
                documents.length > 0 &&
                documents.map(document => {
                  if (document.resource) {
                    return (
                      <li key={document.id} className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                        <div className="w-0 flex-1 flex items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
                            />
                          </svg>
                          <span className="ml-2 flex-1 w-0 truncate">
                            {document.resource.split('/')[document.resource.split('/').length - 1]}
                          </span>
                        </div>
                        <div className="ml-4 flex-shrink-0 flex space-x-1">
                          {enableDownload && (
                            <>
                              {isPreviewable(document.resource.split('/')[document.resource.split('/').length - 1]) &&
                                (preparingPreview === document.id ? (
                                  <>
                                    <ClipLoader className="mr-2" size={18} color={'#4f46e5'} />
                                    <span className="text-gray-300" aria-hidden="true">
                                      |
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <button
                                      onClick={() => onPreviewHandler(document.id)}
                                      type="button"
                                      disabled={downloading}
                                      className="bg-white rounded-md font-medium text-thaleria-blue-400 hover:text-thaleria-blue-800"
                                    >
                                      <EyeIcon className="w-4 h-4 text-thaleria-blue-400 hover:text-thaleria-blue-800" />
                                    </button>
                                    <span className="text-gray-300" aria-hidden="true">
                                      |
                                    </span>
                                  </>
                                ))}

                              {downloading === document.id ? (
                                <ClipLoader className="mr-2" size={18} color={'#4f46e5'} />
                              ) : (
                                <button
                                  onClick={onDownloadHandler.bind(this, document.id)}
                                  type="button"
                                  className="bg-white rounded-md font-medium text-thaleria-blue-400 hover:text-thaleria-blue-800"
                                >
                                  <DownloadIcon className="w-4 h-4 text-thaleria-blue-400 hover:text-thaleria-blue-800" />
                                </button>
                              )}
                            </>
                          )}

                          {enableRemove && enableDownload && (
                            <span className="text-gray-300" aria-hidden="true">
                              |
                            </span>
                          )}
                          {enableRemove &&
                            (deleting === document.id ? (
                              <ClipLoader className="mr-2" size={18} color={'#ef4444'} />
                            ) : (
                              <button
                                type="button"
                                onClick={onRemoveHandler.bind(this, document.id)}
                                className="bg-white rounded-md font-medium text-red-600 hover:text-red-500"
                              >
                                <TrashIcon className="h-4 w-4 text-red-600 hover:text-red-500" />
                              </button>
                            ))}
                        </div>
                      </li>
                    );
                  }
                })}
            </ul>
          )}
        </Loader>
      </dd>
    </Card>
  );
};

export default Attachments;

Attachments.prototype = {
  removeHandler: PropTypes.func,
  downloadHandler: PropTypes.func,
  uploadHandler: PropTypes.func,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      resource: PropTypes.string,
    }),
  ),
};
