import { PlusCircleIcon } from '@heroicons/react/outline';

const TimesheetFileNameMacros = ({ addMacroToTemplate }) => {
  return (
    <div className="grid grid-cols-2 w-full gap-6">
      {/* Macros Buttons */}
      <div
        className="col-span-1 flex justify-between items-center rounded-md border group border-gray-300 hover:bg-gray-50 cursor-pointer p-2"
        onClick={() => addMacroToTemplate('[ID]')}
      >
        <div className="flex flex-col">
          <span className="text-sm">Timesheet ID*</span>
          <span className="text-xs">[ID]</span>
        </div>
        <PlusCircleIcon className="w-6 h-6 text-thaleria-orange-400 cursor-pointer group-hover:text-thaleria-orange-600" />
      </div>
      <div
        className="col-span-1 flex justify-between items-center rounded-md border group border-gray-300 hover:bg-gray-50 cursor-pointer p-2"
        onClick={() => addMacroToTemplate('[Period]')}
      >
        <div className="flex flex-col">
          <span className="text-sm">Period</span>
          <span className="text-xs">[Period]</span>
        </div>
        <PlusCircleIcon className="w-6 h-6 text-thaleria-orange-400 cursor-pointer group-hover:text-thaleria-orange-600" />
      </div>
      <div
        className="col-span-1 flex justify-between items-center rounded-md border group border-gray-300 hover:bg-gray-50 cursor-pointer p-2"
        onClick={() => addMacroToTemplate('[Status]')}
      >
        <div className="flex flex-col">
          <span className="text-sm">Timesheet status</span>
          <span className="text-xs">[Status]</span>
        </div>
        <PlusCircleIcon className="w-6 h-6 text-thaleria-orange-400 cursor-pointer group-hover:text-thaleria-orange-600" />
      </div>
      <div
        className="col-span-1 flex justify-between items-center rounded-md border group border-gray-300 hover:bg-gray-50 cursor-pointer p-2"
        onClick={() => addMacroToTemplate('[User]')}
      >
        <div className="flex flex-col">
          <span className="text-sm">User name</span>
          <span className="text-xs">[User]</span>
        </div>
        <PlusCircleIcon className="w-6 h-6 text-thaleria-orange-400 cursor-pointer group-hover:text-thaleria-orange-600" />
      </div>
    </div>
  );
};

export default TimesheetFileNameMacros;
