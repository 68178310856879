import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import notifications from '../../helpers/enum/notifications';
import { hideNotification } from '../../store/app/actions';

const NOTIFICATION_STYLE = {
  '--tw-translate-x': '200%',
};

const Notification = () => {
  const dispatch = useDispatch();

  const notification = useSelector(state => state.app.notification);

  const resetNotification = () => {
    dispatch(hideNotification());
  };

  return (
    <div
      className={
        notification.show
          ? 'fixed z-50 top-0 right-0 mt-8 mr-8 flex flex-row shadow-lg transition duration-1000 ease-in-out'
          : 'fixed z-50 top-0 right-0 mt-8 mr-8 flex flex-row shadow-lg transform translate-x-96 transition duration-1000 ease-in-out'
      }
      style={NOTIFICATION_STYLE}
    >
      {notification.type === notifications.WARNING_YELLOW && (
        <div className="sm:p-6 p-2 flex rounded-l text-white items-center justify-center bg-yellow-400 h-auto w-auto rounded-tl rounded-bl">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="40" height="40" fill="currentColor">
            <path
              className="heroicon-ui"
              d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm0 9a1 1 0 0 1-1-1V8a1 1 0 0 1 2 0v4a1 1 0 0 1-1 1zm0 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
            />
          </svg>
        </div>
      )}
      {notification.type === notifications.SUCCESS && (
        <div className="sm:p-6 p-2 flex rounded-l text-white items-center justify-center bg-green-400 h-auto w-auto rounded-tl rounded-bl">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={40} height={40} fill="currentColor">
            <path
              className="heroicon-ui"
              d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-2.3-8.7l1.3 1.29 3.3-3.3a1 1 0 0 1 1.4 1.42l-4 4a1 1 0 0 1-1.4 0l-2-2a1 1 0 0 1 1.4-1.42z"
            />
          </svg>
        </div>
      )}
      {notification.type === notifications.WARNING_BLUE && (
        <div className="sm:p-6 p-2 flex rounded-l text-white items-center justify-center bg-blue-400 h-auto w-auto rounded-tl rounded-bl">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={40} height={40} fill="currentColor">
            <path
              className="heroicon-ui"
              d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9a1 1 0 0 1 1 1v4a1 1 0 0 1-2 0v-4a1 1 0 0 1 1-1zm0-4a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
            />
          </svg>
        </div>
      )}
      <div className="flex flex-col bg-white pl-4 sm:pt-6 pb-6 pr-4 pt-2 rounded-r w-[220px] sm:w-auto sm:min-w-[250px]">
        <p className="text-lg text-gray-800 dark:text-gray-800 font-semibold pb-1">{notification.title}</p>
        <p className="text-sm text-gray-600 dark:text-gray-400 font-normal">{notification.msg}</p>
      </div>
      <div
        role="alert"
        aria-label="Close"
        className="cursor-pointer absolute right-0 mr-2 mt-2 text-gray-500 hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-500 transition duration-150 ease-in-out"
        onClick={resetNotification}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-x"
          width={20}
          height={20}
          viewBox="0 0 24 24"
          strokeWidth="2.5"
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <line x1={18} y1={6} x2={6} y2={18} />
          <line x1={6} y1={6} x2={18} y2={18} />
        </svg>
      </div>
    </div>
  );
};
export default Notification;
