//config mirrored in back-end folder: don't change IDs
export const USER_CREATION_PW_RECIPIENT_IDs = [
  {
    label: 'Send to my own email',
    value: 1,
  },
  {
    label: "Send to user's email",
    value: 2,
  },
  {
    label: 'Set custom password',
    value: 3,
  },
];
