import React, { useEffect, useState, useRef } from 'react';
import PageWrapper from '../../../../containers/App/PageWrapper';
import Chat from '../../../../containers/Chat/Chat';
import Attachments from '../../../../containers/Attachments/Attachments';
import { useDispatch } from 'react-redux';
import useApi from '../../../../hooks/useApi';
import { useParams } from 'react-router-dom';
import { downloadBase64File } from '../../../../helpers';
import AdminLRDetails from '../../../../containers/LeaveRequests/AdminLRDetails';
import UserLeaveBalance from '../../../../containers/UserDirectory/UserInfoSections/UserLeaveBalance';
import Card from '../../../../components/Cards/Card';
import { LeaveRequestStatus } from '../../../../helpers/enum/leaveRequest';
import { useSelector } from 'react-redux';
import { ACCOUNT_TYPE } from '../../../../helpers/enum/accounts';
import ButtonPrimary from '../../../../components/Buttons/ButtonPrimary';
import { useHistory } from 'react-router-dom';
import { showSuccessNotification } from '../../../../store/app/actions';
import UserDetails from '../../../../containers/LeaveRequests/UserDetails';
import contractTypes from '../../../../helpers/enum/contractTypeIDs';
import WarningText from '../../../../components/Warnings/WarningText';
import { endOfDay, isBefore } from 'date-fns';
import Loader from '../../../../components/Loading/Loader';
import SimpleAlert from '../../../../components/Modals/SimpleAlert';
import PreviewBox from '../../../../containers/PDFViewer/PreviewBox';

const LeaveRequestAdminDetails = () => {
  const {
    files: { getFilesOnFolderForLeaveRequests, downloadLeaveRequestFile, deleteLeaveRequestFile },
    leaveRequests: { uploadFile, CreateAdminComment, getLeaveRequest, updateLeaveRequestStatusByAdmin },
    staffContracts: { getActiveStaffContracts, getStaffContractById },
  } = useApi();
  const currentUser = useSelector(state => state.auth.currentUser);
  const isRootOrAdminUser =
    currentUser.account_type_id === ACCOUNT_TYPE.ROOT.id || currentUser.account_type_id === ACCOUNT_TYPE.ADMIN.id;
  const dispatch = useDispatch();
  const history = useHistory();
  const { userId, id } = useParams();

  const [message, setMessage] = useState('');
  const [documents, setDocuments] = useState([]);
  const [refreshBalance, setRefreshBalance] = useState(0);
  const [leaveRequest, setLeaveRequest] = useState(null);

  const [downloading, setDownloading] = useState(false);
  const [loadingFolder, setLoadingFolder] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [userActiveContract, setUserActiveContract] = useState([]);
  const [LRContract, setLRContract] = useState();
  const [contractInactive, setContractInactive] = useState(undefined);
  const [showDuplicateAlert, setShowDuplicateAlert] = useState(false);
  const [preparingPreview, setPreparingPreview] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const leaveDayCardRef = useRef(null);

  const prepareChatData = res => {
    const messages = res.data.map(message => {
      const momentOfPublish = new Date(message.created_at);
      return {
        id: message.id,
        ownerName: message.user.first_names,
        ownerImg: message.user.profilepic_url,
        ownerId: message.user_id,
        text: message.comment,
        time: momentOfPublish.toLocaleString('en-us'),
      };
    });

    return messages;
  };

  const onMessageChange = e => setMessage(e.target.value);

  const sendMessageHandler = () => CreateAdminComment(id, message).then(res => setMessage(''));

  const uploadFileHandler = fileInfo => {
    if (documents.length) {
      if (documents.some(doc => doc.name == fileInfo.name)) {
        setUploading(false);
        return setShowDuplicateAlert(fileInfo.name);
      }
    }
    uploadFile(fileInfo, leaveRequest.uuid)
      .then(response => {
        if (!documents.find(d => d.id === response.key))
          setDocuments(preValue => [...preValue, { ...response, id: response.key, resource: response.key }]);
        setUploading(false);
      })
      .catch(() => {
        setUploading(false);
      });
  };

  const downloadFileHandler = id => {
    const file = documents.find(document => document.id == id);
    const fileName = file.resource.split('/')[file.resource.split('/').length - 1];
    downloadLeaveRequestFile(file.resource)
      .then(response => {
        downloadBase64File(response.data, fileName);
        setDownloading(false);
      })
      .catch(() => {
        setDownloading(false);
      });
  };

  const previewHandler = id => {
    const file = documents.find(document => document.id == id);
    const fileName = file.resource.split('/')[file.resource.split('/').length - 1];
    downloadLeaveRequestFile(file.resource)
      .then(response => {
        setPreparingPreview(false);
        setShowPreview({
          url: response.data,
          fileType: file.resource.split('.')[file.resource.split('.').length - 1],
          title: fileName.split('.')[fileName.split('.').length - 2],
          show: true,
        });
      })
      .catch(() => {
        setPreparingPreview(false);
      });
  };

  const RemoveFileHandler = id => {
    deleteLeaveRequestFile(id)
      .then(response => {
        setDocuments(preDocuments => [...preDocuments.filter(document => document.id != id)]);
        setDeleting(false);
      })
      .catch(() => {
        setDeleting(false);
      });
  };

  const STYLES_LOG = {
    height: '500px',
  };

  useEffect(() => {
    let activeContractId;
    getActiveStaffContracts(userId).then(activeStaffContracts => {
      setUserActiveContract(activeStaffContracts[0]);
      activeContractId = activeStaffContracts[0]?.id;
      getLeaveRequest(id)
        .then(data => {
          let LRData = {
            deductedLeaveDays: data.deducted_leave_days || null,
            startDate: data.start_date,
            endDate: data.end_date,
            endHalfDay: data.end_half_day,
            startHalfDay: data.start_half_day,
            status: data.status,
            requestType: data.request_type,
            description: data.description,
            weekendDates: data.weekendDates,
            holidays: data.holidays,
            id: data.id,
            userId: data.user_id,
            uuid: data.uuid,
          };
          setLeaveRequest(LRData);
          if (data.staff_contract_id != activeContractId) {
            getStaffContractById(data.staff_contract_id).then(staffContract => {
              setLRContract(staffContract);
            });
          }
          if (data.staff_contract_id == activeContractId) {
            setLRContract(activeStaffContracts[0]);
          }
          getFilesOnFolderForLeaveRequests(`leave-request/${data?.uuid}`).then(res => {
            const responseDate = res.data;
            setDocuments(responseDate.map(res => ({ ...res, id: res.key, resource: res.key })));
            setLoadingFolder(false);
          });
        })
        .catch(e => {
          history.goBack();
        });
    });
  }, []);

  useEffect(() => {
    if (!LRContract) return;
    const today = endOfDay(new Date());
    const contractEnd = LRContract.contract_end == null ? null : endOfDay(new Date(LRContract.contract_end));

    setContractInactive(contractEnd != null && isBefore(contractEnd, today));
  }, [LRContract]);

  const checkAttachmentActions = () => {
    if (isRootOrAdminUser) return true;
    if (!leaveRequest) return false;
    if (!leaveRequest.status) return false;
    if (leaveRequest.status === LeaveRequestStatus.approved) return false;
    return true;
  };

  const pages = [
    { name: 'Manage Leave Requests', href: '/admin-panel/hr/leave-requests', current: false },
    { name: `Leave Request #${id}`, href: '/admin-panel/hr/leave-requests', current: true },
  ];

  const handleUpdateStatus = status => {
    updateLeaveRequestStatusByAdmin(leaveRequest.id, { status }).then(() => {
      setRefreshBalance(refreshBalance + 1);
      setLeaveRequest(prev => ({ ...prev, status }));
      dispatch(showSuccessNotification('Status successfully updated'));
    });
  };

  return (
    <PageWrapper pages={pages}>
      <SimpleAlert
        errorTitle={'Unable to upload file'}
        errorMsg={`${showDuplicateAlert && showDuplicateAlert} file name already exists on this leave request."`}
        onAcceptClick={() => setShowDuplicateAlert(false)}
        onAcceptText={'Close'}
        show={showDuplicateAlert != false}
        hide={() => setShowDuplicateAlert(false)}
      />
      <PreviewBox
        filePath={showPreview.url}
        fileType={showPreview.fileType}
        showPreview={showPreview.show}
        handleHide={() => setShowPreview(false)}
        title={showPreview.title}
      />
      {contractInactive && (
        <WarningText
          text="It is not possible to edit this leave request because the staff contract associated to it is no longer active."
          className="mb-4"
        />
      )}
      <div className="grid grid-cols-5 gap-4">
        <div className="flex flex-col col-span-5 lg:col-span-3 space-y-4">
          <AdminLRDetails leaveRequest={leaveRequest} setLeaveRequest={setLeaveRequest} contractInactive={contractInactive} />
          <Card
            className="overflow-y-auto w-full"
            ref={leaveDayCardRef}
            noYPadding
            title="Leave days balance"
            auto
            style={STYLES_LOG}
          >
            <Loader loading={!LRContract}>
              {LRContract?.contract_type_id != contractTypes.employee ? (
                <div className="pt-6 flex flex-col h-full">
                  <div>Freelance/company contracts are not eligible for paid leave.</div>
                </div>
              ) : (
                <UserLeaveBalance
                  userId={userId}
                  noUpdate
                  refresh={refreshBalance}
                  containerRef={leaveDayCardRef}
                  selectedStaffContract={LRContract}
                />
              )}
            </Loader>
          </Card>
        </div>
        <div className="flex flex-col col-span-5 lg:col-span-2 space-y-4">
          <UserDetails userId={userId} LRContract={LRContract} />
          <Attachments
            uploadHandler={uploadFileHandler}
            maxFileSizeInMb={10}
            downloadHandler={downloadFileHandler}
            removeHandler={RemoveFileHandler}
            documents={documents}
            enableAdd={isRootOrAdminUser ? true : false}
            enableRemove={checkAttachmentActions()}
            deleting={deleting}
            setDeleting={setDeleting}
            downloading={downloading}
            setDownloading={setDownloading}
            loadingFolder={loadingFolder}
            uploading={uploading}
            setUploading={setUploading}
            preparingPreview={preparingPreview}
            setPreparingPreview={setPreparingPreview}
            previewHandler={previewHandler}
          />
          <Chat
            url={`/leave-request-comments/${id}`}
            prepare={prepareChatData}
            messageOnChange={onMessageChange}
            sendMessage={sendMessageHandler}
            messageText={message}
          />
        </div>
      </div>
      {!contractInactive && (
        <div className="mt-4 space-y-4 flex flex-col lg:flex-row-reverse lg:space-y-0 lg:gap-x-4">
          {leaveRequest?.status === LeaveRequestStatus.submitted && (
            <>
              <ButtonPrimary
                size="xl"
                text="Approve Leave Request"
                onClick={() => handleUpdateStatus(LeaveRequestStatus.approved)}
              />
              <ButtonPrimary size="xl" text="Deny Leave Request" onClick={() => handleUpdateStatus(LeaveRequestStatus.denied)} />
              <ButtonPrimary
                size="xl"
                color="orange"
                text="Request Changes"
                onClick={() => handleUpdateStatus(LeaveRequestStatus.actionRequired)}
              />
            </>
          )}
          {leaveRequest?.status === LeaveRequestStatus.actionRequired && (
            <>
              <ButtonPrimary
                size="xl"
                text="Move Back To Submitted"
                onClick={() => handleUpdateStatus(LeaveRequestStatus.submitted)}
              />
            </>
          )}
          {leaveRequest?.status === LeaveRequestStatus.approved && isRootOrAdminUser && (
            <>
              <ButtonPrimary
                size="xl"
                text="Move Back To Submitted"
                onClick={() => handleUpdateStatus(LeaveRequestStatus.submitted)}
              />
            </>
          )}
          {leaveRequest?.status === LeaveRequestStatus.denied && isRootOrAdminUser && (
            <>
              <ButtonPrimary
                size="xl"
                text="Move Back To Submitted"
                onClick={() => handleUpdateStatus(LeaveRequestStatus.submitted)}
              />
              <ButtonPrimary
                size="xl"
                color="red"
                text="Delete Leave Request"
                onClick={() => handleUpdateStatus(LeaveRequestStatus.deleted)}
              />
            </>
          )}
        </div>
      )}
    </PageWrapper>
  );
};

export default LeaveRequestAdminDetails;
