import React from 'react';
import axios from 'axios';

export default props => {
  const { src, defaultimage } = props;
  const [imgSrc, setImageSrc] = React.useState();

  React.useEffect(() => {
    if (!src || process.env.REACT_APP_ENV === 'development') {
      setImageSrc(null);
      return;
    }

    if (/(http(s?)):\/\//i.test(src)) {
      setImageSrc(src);
      return;
    }

    const userId = src.split('/')[src.split('/').length - 2];

    axios.get(`/files/profile-picture/download/?id=${userId}&base64=true`).then(response => {
      setImageSrc(response.data);
    });
  }, [src]);

  if (!imgSrc && defaultimage) return defaultimage;

  return <img {...props} src={imgSrc} />;
};
