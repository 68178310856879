import PropTypes from 'prop-types';
import React, { useState, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Card from '../../components/Cards/Card';
import Loader from '../../components/Loading/Loader';
import ClipLoader from 'react-spinners/ClipLoader';
import { ClipboardCheckIcon, ClipboardListIcon, DocumentAddIcon, ReceiptTaxIcon } from '@heroicons/react/outline';
import { BadgeCheckIcon as SolidBadgeCheckIcon } from '@heroicons/react/solid';
import { BadgeCheckIcon as OutlineBadgeCheckIcon } from '@heroicons/react/outline';
import { CurrencyEuroIcon as SolidCurrencyIcon } from '@heroicons/react/solid';
import { PlusCircleIcon } from '@heroicons/react/solid';
import AttachmentCard from '../../components/Attachments/AttachmentCard';
import { AccountType } from '../../helpers/enum/accounts';
import InfoTooltip from '../../components/Tooltips/InfoTooltip';
import { TimeSheetStatus } from '../../helpers/enum/timeSheet';
import { Link } from 'react-router-dom';
import paidStatus from '../../helpers/enum/paidStatus';
const TimesheetAttachments = ({
  removeHandler,
  downloadHandler,
  documents,
  enableAdd = true,
  enableRemove = true,
  enableDownload = true,
  deleting,
  setDeleting,
  downloading,
  setDownloading,
  loadingFolder,
  uploading,
  setUploading,
  accept,
  maxFileSizeInMb,
  previewHandler,
  preparingPreview,
  setPreparingPreview,
  draftTimesheets,
  signedTimesheets,
  invoice,
  timesheetId,
  invoiceFilesFromAP,
  userInvoices,
  uploadHandler,
  isFreelancerOrCompany,
  timesheetStatus,
  activeStaffOrders,
  onClickInvoiceLink,
  createInvoiceLink,
  createInvoiceState,
}) => {
  const inputFile = useRef(null);
  const inputDraftTimesheet = useRef(null);
  const inputSignedTimesheet = useRef(null);

  const onChangeFile = event => {
    event.stopPropagation();
    event.preventDefault();
    let file = event.target.files[0];
    if (maxFileSizeInMb) {
      if (file?.size > maxFileSizeInMb * 1000000) {
        alert(`Maximum file size is ${maxFileSizeInMb}mb`);
        return null;
      }
    }
    setUploading && setUploading('other');
    //send type as second argument to uploadHandler, if no type, it's 'other' file
    uploadHandler && uploadHandler(file);
  };

  const onChangeDraftTimesheet = e => {
    e.stopPropagation();
    e.preventDefault();
    let file = e.target.files[0];
    if (maxFileSizeInMb) {
      if (file?.size > maxFileSizeInMb * 1000000) {
        alert(`Maximum file size is ${maxFileSizeInMb}mb`);
        return null;
      }
    }
    setUploading && setUploading('draftTimesheets');
    //send type as second argument to uploadHandler, if no type, it's 'other' file
    uploadHandler && uploadHandler(file, 'draftTimesheets');
  };
  const onChangeSignedTimesheet = e => {
    e.stopPropagation();
    e.preventDefault();
    let file = e.target.files[0];
    if (maxFileSizeInMb) {
      if (file?.size > maxFileSizeInMb * 1000000) {
        alert(`Maximum file size is ${maxFileSizeInMb}mb`);
        return null;
      }
    }
    setUploading && setUploading('signedTimesheets');
    //send type as second argument to uploadHandler, if no type, it's 'other' file
    uploadHandler && uploadHandler(file, 'signedTimesheets');
  };

  const onPreviewHandler = id => {
    setPreparingPreview && setPreparingPreview(id);
    previewHandler && previewHandler(id);
  };

  const onDownloadHandler = id => {
    setDownloading && setDownloading(id);
    downloadHandler && downloadHandler(id);
  };
  const onRemoveHandler = id => {
    setDeleting && setDeleting(id);
    removeHandler && removeHandler(id);
  };
  const onLinkHandler = id => {
    onClickInvoiceLink(id);
  };

  const handleClickSignedTS = () => {
    inputSignedTimesheet.current.value = '';
    inputSignedTimesheet.current.click();
  };
  const handleClickDraftTS = () => {
    inputDraftTimesheet.current.value = '';
    inputDraftTimesheet.current.click();
  };

  const handleClickOtherFiles = () => {
    inputFile.current.value = '';
    inputFile.current.click();
  };

  //accept images, PDF and MS Office files
  const defaultFileTypes = `.csv, image/png,
  image/jpg,
  image/jpeg,
  image/gif,
  image/x-png,
  application/pdf,
  application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document,
  application/vnd.openxmlformats-officedocument.wordprocessingml.template,
  application/vnd.ms-word.document.macroEnabled.12,
  application/vnd.ms-word.template.macroEnabled.12,
  application/vnd.ms-excel,
  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
  application/vnd.openxmlformats-officedocument.spreadsheetml.template,
  application/vnd.ms-excel.sheet.macroEnabled.12,
  application/vnd.ms-excel.template.macroEnabled.12,
  application/vnd.ms-excel.sheet.binary.macroEnabled.12,
  application/vnd.ms-excel.addin.macroEnabled.12,
  application/vnd.ms-powerpoint,
  application/vnd.openxmlformats-officedocument.presentationml.template,
  application/vnd.openxmlformats-officedocument.presentationml.slideshow,
  application/vnd.openxmlformats-officedocument.presentationml.presentation,
  application/vnd.ms-powerpoint.presentation.macroEnabled.12,
  application/vnd.ms-powerpoint.template.macroEnabled.12,
  application/vnd.ms-powerpoint.slideshow.macroEnabled.12`;

  const isPreviewable = fileName => {
    let bool = false;
    const extension = fileName.split('.')[fileName.split('.').length - 1].toLowerCase();
    if (extension === 'pdf' || extension === 'jpg' || extension === 'png' || extension === 'jpeg') bool = true;
    return bool;
  };

  const reviewInvoiceClickHandler = () => {
    previewHandler(invoice?.id, true);
  };

  const invoiceIcon = ap_id => {
    if (!ap_id) {
      return (
        <div className="flex">
          <SolidBadgeCheckIcon className="w-5 h-5 text-green-400" />
          <SolidCurrencyIcon className="w-5 h-5 text-green-400" />
        </div>
      );
    } else {
      const ap = userInvoices.find(el => el.id === ap_id);
      if (ap) {
        return ap.paid === paidStatus.numbers.paid ? (
          <div className="flex">
            <SolidBadgeCheckIcon className="w-5 h-5 text-green-400" />
            <SolidCurrencyIcon className="w-5 h-5 text-green-400" />
          </div>
        ) : ap.approved ? (
          <SolidBadgeCheckIcon className="w-5 h-5 text-green-400 " />
        ) : (
          ''
        );
      }
    }
  };

  return (
    <Card title="Attachments">
      <input
        type="file"
        id="file"
        accept={accept || defaultFileTypes}
        ref={inputFile}
        onChange={onChangeFile}
        style={{ display: 'none' }}
      />
      <input
        type="file"
        id="signedTimesheets"
        accept={accept || defaultFileTypes}
        ref={inputSignedTimesheet}
        onChange={onChangeSignedTimesheet}
        style={{ display: 'none' }}
      />
      <input
        type="file"
        id="draftTimesheets"
        accept={accept || defaultFileTypes}
        ref={inputDraftTimesheet}
        onChange={onChangeDraftTimesheet}
        style={{ display: 'none' }}
      />

      <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-3">
        <Loader isLoading={loadingFolder} className="flex justify-around rounded-lg h-full w-full">
          <div className="flex flex-col justify-between space-y-2">
            <>
              <div className="">
                <span className="text-sm pl-2 my-1 flex">Draft timesheet(s)</span>
                <ul className="divide-y divide-gray-200/75 border border-gray-200 rounded-md">
                  {draftTimesheets?.length
                    ? draftTimesheets.map(doc => {
                        return (
                          <li key={doc?.id} className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                            <AttachmentCard
                              document={doc}
                              enableDownload={enableDownload}
                              isPreviewable={isPreviewable}
                              preparingPreview={preparingPreview}
                              onPreviewHandler={onPreviewHandler}
                              downloading={downloading}
                              onDownloadHandler={onDownloadHandler}
                              enableRemove={enableRemove}
                              deleting={deleting}
                              onRemoveHandler={onRemoveHandler}
                              fileIcon={'draftTimesheet'}
                            />
                          </li>
                        );
                      })
                    : ''}
                  {enableAdd && draftTimesheets?.length < activeStaffOrders.length ? (
                    <li key={'addDT'} className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                      <div className="flex justify-start">
                        <ClipboardListIcon className="w-5 h-5 text-gray-500" />
                        <span className="ml-2 text-gray-600">Add draft timesheet here</span>
                      </div>
                      {uploading === 'draftTimesheets' ? (
                        <ClipLoader size={18} color={'#FFA53B'} />
                      ) : (
                        <PlusCircleIcon
                          className="w-5 h-5 text-thaleria-orange-700 hover:text-thaleria-orange-800 cursor-pointer"
                          onClick={() => handleClickDraftTS()}
                        />
                      )}
                    </li>
                  ) : !draftTimesheets?.length ? (
                    <li key={'addDT'} className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                      <div className="flex justify-start">
                        <ClipboardListIcon className="w-5 h-5 text-gray-500" />
                        <span className="ml-2 text-gray-600">No draft timesheets uploaded</span>
                      </div>
                    </li>
                  ) : (
                    ''
                  )}
                </ul>
              </div>
              <div className="">
                <span className="text-sm pl-2 my-1 flex">Signed timesheet(s)</span>
                <ul className="divide-y divide-gray-200/75 border border-gray-200 rounded-md">
                  {signedTimesheets?.length
                    ? signedTimesheets.map(doc => {
                        return (
                          <li key={doc?.id} className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                            <AttachmentCard
                              document={doc}
                              enableDownload={enableDownload}
                              isPreviewable={isPreviewable}
                              preparingPreview={preparingPreview}
                              onPreviewHandler={onPreviewHandler}
                              downloading={downloading}
                              onDownloadHandler={onDownloadHandler}
                              enableRemove={enableRemove}
                              deleting={deleting}
                              onRemoveHandler={onRemoveHandler}
                              fileIcon={'signedTimesheet'}
                            />
                          </li>
                        );
                      })
                    : ''}
                  {enableAdd && signedTimesheets?.length < activeStaffOrders.length ? (
                    <li key={'addST'} className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                      <div className="flex justify-start">
                        <ClipboardCheckIcon className="w-5 h-5 text-gray-500" />
                        <span className="ml-2 text-gray-600">Add signed timesheet here</span>
                      </div>
                      {uploading === 'signedTimesheets' ? (
                        <ClipLoader size={18} color={'#FFA53B'} />
                      ) : (
                        <PlusCircleIcon
                          className="w-5 h-5 text-thaleria-orange-700 hover:text-thaleria-orange-800 cursor-pointer"
                          onClick={() => handleClickSignedTS()}
                        />
                      )}
                    </li>
                  ) : !signedTimesheets?.length ? (
                    <li key={'addST'} className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                      <div className="flex justify-start">
                        <ClipboardListIcon className="w-5 h-5 text-gray-500" />
                        <span className="ml-2 text-gray-600">No signed timesheets uploaded</span>
                      </div>
                    </li>
                  ) : (
                    ''
                  )}
                </ul>
              </div>
              {isFreelancerOrCompany ? (
                <div className="mb-4">
                  <span className="text-sm pl-2 my-1 flex items-center ">Invoice</span>
                  <ul className="divide-y divide-gray-200 border border-gray-200 rounded-md">
                    {invoice ? (
                      <li key={invoice?.id} className="pl-3 pr-4 py-3 flex items-center justify-between text-sm ">
                        <AttachmentCard
                          document={invoice}
                          enableDownload={enableDownload}
                          isPreviewable={isPreviewable}
                          preparingPreview={preparingPreview}
                          onPreviewHandler={onPreviewHandler}
                          downloading={downloading}
                          onDownloadHandler={onDownloadHandler}
                          enableRemove={false}
                          deleting={deleting}
                          onRemoveHandler={onRemoveHandler}
                          fileIcon={'invoice'}
                          timesheetStatus={timesheetStatus}
                          textIcon={invoiceIcon()}
                        />
                      </li>
                    ) : (
                      ''
                    )}
                    {invoiceFilesFromAP?.length
                      ? invoiceFilesFromAP.map(i => (
                          <li key={i?.id} className="pl-3 pr-4 py-3 flex items-center justify-between text-sm ">
                            <AttachmentCard
                              document={i}
                              enableDownload={enableDownload}
                              isPreviewable={isPreviewable}
                              preparingPreview={preparingPreview}
                              onPreviewHandler={onPreviewHandler}
                              downloading={downloading}
                              onDownloadHandler={onDownloadHandler}
                              fileIcon={'invoice'}
                              timesheetStatus={timesheetStatus}
                              enableLink={true}
                              onLinkHandler={onLinkHandler}
                              textIcon={invoiceIcon(i?.accounts_payable_id)}
                            />
                          </li>
                        ))
                      : ''}
                    <li key={'addButton'}>
                      <Link
                        to={{ pathname: createInvoiceLink, state: createInvoiceState ? createInvoiceState : null }}
                        className="group pl-3 pr-4 py-3 flex items-center justify-between text-sm "
                      >
                        <div className="flex justify-start">
                          <ReceiptTaxIcon className="w-5 h-5 text-gray-500 group-hover:text-gray-700" />
                          <span className="ml-2 text-gray-600 group-hover:text-gray-800">Add invoice here</span>
                        </div>
                        <PlusCircleIcon className="w-5 h-5 text-thaleria-orange-700 group-hover:text-thaleria-orange-800 cursor-pointer" />
                      </Link>
                    </li>
                  </ul>
                </div>
              ) : (
                ''
              )}
              <div className="mb-4">
                <span className="text-sm pl-2 my-1 flex">Other</span>
                <ul className="divide-y divide-gray-200/75 border border-gray-200 rounded-md">
                  {documents?.length
                    ? documents.map(doc => {
                        return (
                          <li key={doc?.id} className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                            <AttachmentCard
                              document={doc}
                              enableDownload={enableDownload}
                              isPreviewable={isPreviewable}
                              preparingPreview={preparingPreview}
                              onPreviewHandler={onPreviewHandler}
                              downloading={downloading}
                              onDownloadHandler={onDownloadHandler}
                              enableRemove={enableRemove}
                              deleting={deleting}
                              onRemoveHandler={onRemoveHandler}
                            />
                          </li>
                        );
                      })
                    : ''}
                  {enableAdd ? (
                    <li key={'other'} className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                      <div className="flex justify-start">
                        <DocumentAddIcon className="w-5 h-5 text-gray-500" />
                        <span className="ml-2 text-gray-600">Add other files here</span>
                      </div>
                      {uploading === 'other' ? (
                        <ClipLoader size={18} color={'#FFA53B'} />
                      ) : (
                        <PlusCircleIcon
                          className="w-5 h-5 text-thaleria-orange-700 hover:text-thaleria-orange-800 cursor-pointer"
                          onClick={() => handleClickOtherFiles()}
                        />
                      )}
                    </li>
                  ) : !documents?.length ? (
                    <li key={'other'} className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                      <div className="flex justify-start">
                        <DocumentAddIcon className="w-5 h-5 text-gray-500" />
                        <span className="ml-2 text-gray-600">No files uploaded</span>
                      </div>
                    </li>
                  ) : (
                    ''
                  )}
                </ul>
              </div>
            </>
          </div>
        </Loader>
      </dd>
    </Card>
  );
};

export default TimesheetAttachments;

TimesheetAttachments.prototype = {
  removeHandler: PropTypes.func,
  downloadHandler: PropTypes.func,
  uploadHandler: PropTypes.func,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      resource: PropTypes.string,
    }),
  ),
};
