import React, { useState, useRef, useEffect } from 'react';
import ProfilePicture from '../ProfilePicture/ProfilePicture';
import BlankProfilePictureIcon from '../Icons/BlankProfilePictureIcon';

function InputPhoto({ label, value, onChange, setCroppedImage, userProfilePicUrl }) {
  const [image, setImage] = useState(value);
  const inputRef = useRef(null);

  useEffect(() => {
    setImage(value);
  }, [value]);

  const handleInputChange = event => {
    if (event.target.files.length == 0) return alert('No File selected !!');

    if (onChange) {
      onChange(event.target.files[0]);
    } else {
      setImage(URL.createObjectURL(event.target.files[0]));
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <div>
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <div className="mt-1 flex items-center">
        <span className="flex h-12 w-12 rounded-full overflow-hidden bg-gray-100">
          {image && <ProfilePicture src={image} />}
          {!image && <BlankProfilePictureIcon />}
        </span>
        <button
          onClick={onButtonClick}
          type="button"
          className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Change
        </button>
        {image && (
          <button
            onClick={() => setCroppedImage('deleted')}
            type="button"
            className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Delete
          </button>
        )}
        {!image && userProfilePicUrl && (
          <button
            onClick={() => setCroppedImage(null)}
            type="button"
            className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Restore
          </button>
        )}
        <input type="file" accept="image/*" ref={inputRef} style={{ display: 'none' }} onChange={handleInputChange} />
      </div>
    </div>
  );
}

export default InputPhoto;
