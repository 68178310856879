import React from 'react';
import PropTypes from 'prop-types';
import useSWR from 'swr';
import Card from '../../components/Cards/Card';
import ChatMessage from '../../components/Chat/ChatMessage';
import ChatSendMessage from '../../components/Chat/ChatSendMessage';
import { useSelector } from 'react-redux';
import axios from 'axios';
import useApi from '../../hooks/useApi';

const fetcher = (url, prepareData) => axios.get(url).then(res => prepareData(res));

const Chat = ({ url, prepare, messageOnChange, sendMessage, messageText }) => {
  const currentUser = useSelector(state => state && state.auth.currentUser);
  const { data, error } = useSWR(url, url => fetcher(url, prepare), {
    refreshInterval: process.env.REACT_APP_ENV === 'development' ? 10000 : 2000,
  });

  const {
    comments: { deleteComment },
  } = useApi();

  const deleteMessage = id => {
    const urlAsArray = url.split('/').filter(el => el !== 'my');
    urlAsArray[urlAsArray.length - 1] = id;
    deleteComment(urlAsArray.join('/'));
  };

  return (
    <Card contentClassName="flex flex-col" title="Comments" noYPadding noXPadding className={''}>
      <div className="px-4 py-6 sm:px-6 max-h-80 overflow-y-auto flex flex-col-reverse grow">
        {data && data.length ? (
          <ul role="list" className="space-y-8">
            {data.map(message => (
              <ChatMessage
                chatId={message.id}
                text={message.text}
                time={message.time}
                ownerImg={message.ownerImg}
                ownerName={message.ownerName}
                ownerId={message.ownerId}
                deleteMessage={deleteMessage}
                createdAt={message.time}
              />
            ))}
          </ul>
        ) : (
          <div className="text-sm text-gray-500 mb-auto">No comments to display.</div>
        )}
      </div>
      <div className="bg-gray-50 px-4 py-6 sm:px-6 rounded-b-lg">
        <ChatSendMessage
          ImgUrl={currentUser.profilepic_url}
          onBodyChange={messageOnChange}
          sendMessage={sendMessage}
          body={messageText}
        />
      </div>
    </Card>
  );
};

export default Chat;

Chat.prototype = {
  url: PropTypes.string,
  prepare: PropTypes.func,
  messageOnChange: PropTypes.func,
  sendMessage: PropTypes.func,
  messageText: PropTypes.string,
};
