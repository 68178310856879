import React, { useEffect, useState, useMemo } from 'react';
import dayjs from 'dayjs';
import SimpleEntry from '../../../components/DescriptionEntries/SimpleEntry';
import ReactSelect from '../../../components/Inputs/ReactSelect';
import DividerWithLabel from '../../../components/Dividers/DividerWithLabel';
import DividerSimple from '../../../components/Dividers/DividerSimple';
import { ExternalLinkIcon } from '@heroicons/react/outline';
import formatNumber from '../../../utils/formatNumber';

function UserMissions({ staffOrders }) {
  const [selectedMission, setSelectedMission] = useState(null);
  const [staffOrderDaysRemaining, setStaffOrderDaysRemaining] = useState();
  const [salesOrderDaysRemaining, setSalesOrderDaysRemaining] = useState();
  const [specificContractDaysRemaining, setSpecificContractDaysRemaining] = useState();

  //prepare listbox data from user missions state
  let missionListBoxData = useMemo(() => {
    let array = [];
    if (staffOrders) staffOrders.forEach(e => array.push({ ...e, label: e.order_ref, value: e.id }));
    return array;
  }, [staffOrders]);

  useEffect(() => {
    setSelectedMission({ ...staffOrders[0], label: staffOrders[0]?.order_ref, value: staffOrders[0]?.id });
  }, [staffOrders]);

  useEffect(() => {
    if (selectedMission?.value) {
      setStaffOrderDaysRemaining(
        selectedMission.days_ordered
          ? new Number(selectedMission.days_ordered).valueOf() - new Number(selectedMission.days_consumed).valueOf()
          : new Number(selectedMission.days_consumed).valueOf(),
      );
      setSalesOrderDaysRemaining(
        selectedMission.salesOrder.days_ordered
          ? new Number(selectedMission.salesOrder.days_ordered).valueOf() -
              new Number(selectedMission.salesOrder.days_consumed).valueOf()
          : new Number(selectedMission.salesOrder.days_consumed).valueOf(),
      );
      setSpecificContractDaysRemaining(
        selectedMission.specificContract.days_ordered
          ? new Number(selectedMission.specificContract.days_ordered).valueOf() -
              new Number(selectedMission.specificContract.days_consumed).valueOf()
          : new Number(selectedMission.specificContract.days_consumed).valueOf(),
      );
    }
  }, [selectedMission]);

  if (!staffOrders?.length)
    return <div className="text-sm text-gray-500 mt-6 px-8">There are currently no missions for this user.</div>;

  const renderNameAndLink = (name, href) => {
    return (
      <div className="flex gap-x-2 mr-auto">
        {name}
        {name && (
          <ExternalLinkIcon
            className="h-5 w-5 text-gray-400 hover:text-thaleria-orange-700 cursor-pointer"
            onClick={() => window.open(href)}
          />
        )}
      </div>
    );
  };

  return (
    <div className="px-8">
      <div className="sticky top-0 w-full bg-white pt-4 z-10">
        <div className="w-64">
          <ReactSelect
            label="Select mission"
            isSearchable={false}
            labelClass="text-gray-500"
            options={missionListBoxData}
            selectedOptions={selectedMission}
            onChange={setSelectedMission}
          />
        </div>
        <DividerSimple />
      </div>
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 pb-6">
        <DividerWithLabel className="col-span-2" label={'Purchase Order'} />
        <SimpleEntry
          label={'Reference'}
          data={renderNameAndLink(
            selectedMission?.order_ref || '-',
            `/admin-panel/contracts/purchase-orders/${selectedMission?.id}`,
          )}
        />
        <SimpleEntry label={'Staff contract'} data={selectedMission?.staffContract?.contract_ref || '-'} />
        <SimpleEntry label={'Start date'} data={dayjs(selectedMission?.order_start).format('DD/MM/YYYY') || '-'} />
        <SimpleEntry
          label={'End date'}
          data={(selectedMission?.order_end && dayjs(selectedMission?.order_end).format('DD/MM/YYYY')) || '-'}
        />
        <SimpleEntry label={'Cost'} data={selectedMission?.daily_rate + ' €' || '-'} />
        <SimpleEntry
          label={'Days ordered'}
          data={selectedMission?.days_ordered ? formatNumber(selectedMission.days_ordered, true) : 'FTE'}
        />
        <SimpleEntry label={selectedMission?.days_ordered ? 'Remaining days' : 'Days consumed'} data={staffOrderDaysRemaining} />
        <SimpleEntry label={'Created'} data={dayjs(selectedMission?.created_at).format('DD/MM/YYYY') || '-'} />
        <SimpleEntry label={'Last updated'} data={dayjs(selectedMission?.updated_at).format('DD/MM/YYYY') || '-'} />
        <DividerWithLabel label={'End-client FWC'} className="col-span-2" />
        <SimpleEntry
          label={'Reference'}
          data={renderNameAndLink(
            selectedMission?.frameworkContract?.contract_ref || '-',
            `/admin-panel/contracts/framework-contracts/${selectedMission?.frameworkContract?.id}`,
          )}
        />
        <SimpleEntry
          label={'Start date'}
          data={dayjs(selectedMission?.frameworkContract?.contract_start).format('DD/MM/YYYY') || '-'}
        />
        <SimpleEntry
          label={'End date'}
          data={
            (selectedMission?.frameworkContract?.contract_end &&
              dayjs(selectedMission?.frameworkContract?.contract_end).format('DD/MM/YYYY')) ||
            '-'
          }
        />
        {/* <SimpleEntry label={'Client'} data={(selectedMission.client && selectedMission.client.name) || '-'} /> */}
        <SimpleEntry label={'Created'} data={dayjs(selectedMission?.frameworkContract?.created_at).format('DD/MM/YYYY') || '-'} />
        <SimpleEntry
          label={'Last updated'}
          data={dayjs(selectedMission?.frameworkContract?.updated_at).format('DD/MM/YYYY') || '-'}
        />

        <DividerWithLabel label={'Sales FWC'} className="col-span-2" />
        <SimpleEntry
          label={'Reference'}
          data={renderNameAndLink(
            selectedMission?.partnerContract?.contract_ref || '-',
            `/admin-panel/contracts/framework-contracts/${selectedMission?.partnerContract?.id}`,
          )}
        />
        <SimpleEntry
          label={'Start date'}
          data={dayjs(selectedMission?.partnerContract?.contract_start).format('DD/MM/YYYY') || '-'}
        />
        <SimpleEntry
          label={'End date'}
          data={
            (selectedMission?.partnerContract?.contract_end &&
              dayjs(selectedMission?.partnerContract?.contract_end).format('DD/MM/YYYY')) ||
            '-'
          }
        />
        <SimpleEntry label={'Created'} data={dayjs(selectedMission?.frameworkContract?.created_at).format('DD/MM/YYYY') || '-'} />
        <SimpleEntry
          label={'Last updated'}
          data={dayjs(selectedMission?.frameworkContract?.updated_at).format('DD/MM/YYYY') || '-'}
        />

        <DividerWithLabel label={'Sales Order'} className="col-span-2" />
        <SimpleEntry
          label={'Reference'}
          data={renderNameAndLink(
            selectedMission?.salesOrder?.order_ref || '-',
            `/admin-panel/contracts/purchase-orders/${selectedMission?.id}`,
          )}
        />
        <SimpleEntry label={'Start date'} data={dayjs(selectedMission?.salesOrder?.order_start).format('DD/MM/YYYY') || '-'} />
        <SimpleEntry
          label={'End date'}
          data={
            (selectedMission?.salesOrder?.order_end && dayjs(selectedMission?.salesOrder?.order_end).format('DD/MM/YYYY')) || '-'
          }
        />
        <SimpleEntry label={'Price'} data={selectedMission?.salesOrder?.daily_rate + ' €' || '-'} />
        <SimpleEntry
          label={'Days ordered'}
          data={selectedMission?.salesOrder?.days_ordered ? formatNumber(selectedMission.salesOrder.days_ordered, true) : 'FTE'}
        />
        <SimpleEntry
          label={selectedMission?.salesOrder?.days_ordered ? 'Remaining days' : 'Days consumed'}
          data={salesOrderDaysRemaining}
        />
        <SimpleEntry label={'Created'} data={dayjs(selectedMission?.salesOrder?.created_at).format('DD/MM/YYYY') || '-'} />
        <SimpleEntry label={'Last updated'} data={dayjs(selectedMission?.salesOrder?.updated_at).format('DD/MM/YYYY') || '-'} />
        <DividerWithLabel label={'Specific Contract'} className="col-span-2" />
        <SimpleEntry
          label={'Reference'}
          data={renderNameAndLink(
            selectedMission?.specificContract?.contract_ref || '-',
            `/admin-panel/contracts/purchase-orders/${selectedMission?.id}`,
          )}
        />
        <SimpleEntry label={'Specific contract'} data={selectedMission?.specificContract?.contract_ref || '-'} />
        <SimpleEntry label={'Institution'} data={selectedMission?.specificContract?.institution || '-'} />
        <SimpleEntry label={'Department'} data={selectedMission?.specificContract?.department || '-'} />
        <SimpleEntry
          label={'Start date'}
          data={dayjs(selectedMission?.specificContract?.contract_start).format('DD/MM/YYYY') || '-'}
        />
        <SimpleEntry
          label={'End date'}
          data={
            (selectedMission?.specificContract?.contract_end &&
              dayjs(selectedMission?.specificContract?.contract_end).format('DD/MM/YYYY')) ||
            '-'
          }
        />
        <SimpleEntry
          label={'Days ordered'}
          data={
            selectedMission?.specificContract?.days_ordered
              ? formatNumber(selectedMission.specificContract.days_ordered, true)
              : 'FTE'
          }
        />
        <SimpleEntry
          label={selectedMission?.specificContract?.days_ordered ? 'Remaining days' : 'Days consumed'}
          data={specificContractDaysRemaining}
        />
        <SimpleEntry label={'Created'} data={dayjs(selectedMission?.specificContract?.created_at).format('DD/MM/YYYY') || '-'} />
        <SimpleEntry
          label={'Last updated'}
          data={dayjs(selectedMission?.specificContract?.updated_at).format('DD/MM/YYYY') || '-'}
        />
      </dl>
    </div>
  );
}

export default UserMissions;
