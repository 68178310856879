import React from 'react';
import PageWrapper from '../../../containers/App/PageWrapper';
import CreateMyInvoiceForm from '../../../containers/MyInvoices/CreateInvoiceForm';

const CreateInvoice = () => {
  const pages = [
    { name: 'My invoices', href: '/my-invoices', current: false },
    { name: 'Create invoice', href: '/my-invoices/create', current: true },
  ];
  return (
    <PageWrapper pages={pages}>
      <CreateMyInvoiceForm />
    </PageWrapper>
  );
};

export default CreateInvoice;
