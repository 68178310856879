import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PageWrapperV2 from '../../../containers/App/PageWrapper';
import MultiDocuments from '../../../containers/Documents/MultiDocuments';
import MyDocumentHeader from '../../../containers/Documents/MyDocumentHeader';
import { DocumentType } from '../../../helpers/enum/documents';
import useApi from '../../../hooks/useApi';
import { downloadBase64File } from '../../../helpers';
import Loader from '../../../components/Loading/Loader';
import PreviewBox from '../../../containers/PDFViewer/PreviewBox';

const items = [
  { value: 0, label: 'Organisation documents', type: DocumentType.THALERIA },
  { value: 1, label: 'Personal documents', type: DocumentType.USER },
];

let pathStack = [];

const MyDocument = () => {
  const {
    files: { documentsFilesOnFolder, downloadDocumentsFiles },
  } = useApi();
  const [item, setItem] = useState(items[0]);
  const [filesInfo, setFilesInfo] = useState({ path: '/', files: [] });
  const [loading, setLoading] = useState(true);
  const [downloading, setDownloading] = useState(false);
  const [pages, setPages] = useState([{ name: 'My Documents', href: '/my-documents', current: false }]);
  const [selectedFile, setSelectedFile] = useState();
  const [preparingPreview, setPreparingPreview] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const checkRootDirectory = filesInfo.path !== '/';

  const changeDirectory = (type, redirectTo) => {
    setLoading(true);
    setFilesInfo({ path: redirectTo, files: [] });
    documentsFilesOnFolder(type, redirectTo).then(res => {
      let filesInfo = res.data.filter(file => {
        if (file.key == redirectTo) {
          return false;
        } else {
          return true;
        }
      });
      filesInfo = filesInfo.map(file => {
        const isFolder = file.key.slice(-1) === '/';
        return {
          path: file.key,
          name: file.name,
          type: isFolder ? 'folder' : 'file',
          capacity: isFolder ? null : file.size,
          onDoubleClick: isFolder
            ? () => {
                history.push(`/my-documents/${type == DocumentType.THALERIA ? 'organisation' : 'personal'}${file.key}`);
              }
            : () => downloadThaleriaDocument(type, file.key),
          onDownloadClick: isFolder
            ? () => console.log(`Cannot download folder`)
            : () => downloadThaleriaDocument(type, file.key),
          onPreviewClick: isFolder ? () => console.log('Cannot preview folder') : () => previewHandler(type, file.key),
        };
      });
      pathStack.push(redirectTo);
      setFilesInfo({ path: redirectTo, files: filesInfo });
      setLoading(false);
    });
  };

  const changeItem = item => {
    history.push(`${item.value == 0 ? '/my-documents/organisation' : '/my-documents/personal'}`);
  };

  const downloadThaleriaDocument = (type, filePath) => {
    setDownloading({ downloadFile: filePath });
    const fileName = filePath.split('/')[filePath.split('/').length - 1];
    downloadDocumentsFiles(type, filePath)
      .then(response => {
        downloadBase64File(response.data, fileName);
        setDownloading(false);
      })
      .catch(err => {
        setDownloading(false);
        throw err;
      });
  };

  const previewHandler = (type, filePath) => {
    downloadDocumentsFiles(type, filePath)
      .then(response => {
        setPreparingPreview(false);
        setShowPreview({
          show: true,
          url: response.data,
          fileType: filePath.split('.')[filePath.split('.').length - 1],
          title: filePath.split('.')[filePath.split('.').length - 2].replace('/', ''),
        });
      })
      .catch(() => {
        setPreparingPreview(false);
      });
  };

  // let pages = [{ name: 'My Documents', href: '/my-documents', current: true }];

  useEffect(() => {
    if (location.pathname.split('/').length < 3)
      history.push(`${item.value == 0 ? '/my-documents/organisation' : '/my-documents/personal'}`);
  }, []);

  useEffect(() => {
    const pathNameArray = location.pathname.split('/');
    const typeOfFile = pathNameArray[2];
    const typeForDispatch = typeOfFile == 'personal' ? DocumentType.USER : DocumentType.THALERIA;
    if (typeOfFile != 'organisation' && typeOfFile != 'personal') return history.push(`/my-documents/organisation`);
    let pages = [{ name: 'My Documents', href: '/my-documents', current: false }];

    //Manage breadcrumbs
    pages.push({
      name: typeOfFile == 'personal' ? items[1].label : items[0].label,
      href: `${typeOfFile == 'personal' ? '/my-documents/personal' : '/my-documents/organisation'}`,
      current: false,
    });
    let breadcrumbArray = [];
    pathNameArray.forEach((path, index) => {
      breadcrumbArray.push(path);
      const elementNotInBreadcrumb = index < 3;
      if (elementNotInBreadcrumb || path == '') return;
      pages.push({
        name: path,
        href: breadcrumbArray.join('/') + '/',
        current: false,
      });
    });

    //Manage fetch of docs
    const hasFolderPath = pathNameArray.length > 3;
    const path = [];
    if (hasFolderPath) {
      //Extract part of the url that is the path to the folder
      for (let i = 3; i < pathNameArray.length; i++) {
        if (!pathNameArray[i] == '') path.push(pathNameArray[i]);
      }
      const redirectPath = `/${path.join('/')}/`;
      changeDirectory(typeForDispatch, redirectPath);
    } else {
      changeDirectory(typeForDispatch, '/');
    }
    pages[pages.length - 1].current = true;
    setPages(pages);
    setItem(typeOfFile == 'personal' ? items[1] : items[0]);
  }, [location.pathname]);

  return (
    <PageWrapperV2 pages={pages}>
      <MyDocumentHeader
        path={item.label + filesInfo.path}
        items={items}
        onListChange={changeItem}
        showArrowLeftIcon={checkRootDirectory}
        loading={loading}
      />
      <PreviewBox
        filePath={showPreview.url}
        fileType={showPreview.fileType}
        showPreview={showPreview.show}
        handleHide={() => setShowPreview(false)}
        title={showPreview.title}
      />
      <Loader isLoading={loading} background={false}>
        <MultiDocuments
          files={filesInfo.files}
          deleteAccess={false}
          threeDotMenu
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          loading={downloading}
          preparingPreview={preparingPreview}
          setPreparingPreview={setPreparingPreview}
          previewHandler={previewHandler}
        />
      </Loader>
    </PageWrapperV2>
  );
};

export default MyDocument;
