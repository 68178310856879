import React from 'react';

function DividerWithLabel({ label, className, labelClass }) {
  return (
    <div className={`${className} relative`}>
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-200"></div>
      </div>
      <div className="relative flex justify-start">
        <span
          className={`${labelClass ? labelClass : 'text-sm font-medium text-gray-500'} tracking-wide uppercase pr-2 bg-white`}
        >
          {label}
        </span>
      </div>
    </div>
  );
}

export default DividerWithLabel;
