import React from 'react';
import PropTypes from 'prop-types';
import ButtonPrimary from '../../../components/Buttons/ButtonPrimary';
import Modal from '../../../components/Modals/Modal';
import DOMPurify from 'dompurify';

const ShowReminder = ({ show, body, title, onCancelClick }) => {
  const showComponents = show ? 'fixed' : 'hidden';

  return (
    <Modal show={show} hide={() => onCancelClick()}>
      <p className="font-bold text-black pb-8">{title}</p>
      <div
        className="text-sm text-justify"
        dangerouslySetInnerHTML={{ __html: body ? DOMPurify.sanitize(body).replace(/\n/g, '<br/>') : '' }}
      />
      <div className="pt-7 flex justify-end">
        <ButtonPrimary text="Back" style="ml-2" onClick={onCancelClick} />
      </div>
    </Modal>
  );
};

ShowReminder.prototype = {
  show: PropTypes.bool,
  onCancelClick: PropTypes.func,
  title: PropTypes.string,
  body: PropTypes.string,
};

export default ShowReminder;
