import { isDate, format } from 'date-fns';

const dateObjectsToString = obj => {
  const keysArray = Object.keys(obj);

  keysArray.forEach(key => {
    if (isDate(obj[key])) {
      obj[key] = format(obj[key], 'yyyy-MM-dd');
    }
  });

  return obj;
};

export default dateObjectsToString;
