import { useEffect, useMemo, useState } from 'react';
import useApi from '../../../../hooks/useApi';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import SelectColumnFilter from '../../../../components/TableItems/SelectColumnFilter';
import NumberRangeColumnFilter from '../../../../components/TableItems/NumberRangeColumnFilter';
import { ExternalLinkIcon, EyeIcon, PencilIcon, TrashIcon } from '@heroicons/react/outline';
import DateRangeColumnFilter from '../../../../components/TableItems/DateRangeColumnFilter';
import Finance from '../Finance';
import formatCurrency from '../../../../utils/formatCurrency';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import Table from '../../../../containers/ServerSideTable';
import CreditNotePDFPreview from '../../../../containers/Finance/CreditNotes/CreditNotePDFPreview';
import { showSuccessNotification } from '../../../../store/app/actions';
import { americanDate } from '../../../../helpers/date';
import AdminSidebar from '../../AdminSidebar';
import SimpleAlert from '../../../../components/Modals/SimpleAlert';
import { ClipLoader } from 'react-spinners';

function CreditNotes() {
  const today = new Date();
  const basicCreditNoteDataObject = {
    credit_note_number: null,
    issue_date: new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate())),
    client_id: null,
    amount: null,
    vat: null,
    amount_and_vat: null,
    sent: false,
    paid: null,
    bank_account_id: null,
    metadata: {
      to: {
        name: '',
        address: '',
        postal_code: '',
        city: '',
        country: '',
        vat_number: '',
      },
      from: {
        name: 'Thaleria S.A.',
        address: 'Avenue des Arts 56',
        postal_code: '1000',
        city: 'Brussels',
        country: 'Belgium',
        vat_number: 'BE 0704.867.821',
      },
      subject: 'Thaleria services',
      header_comment: `Please find below the details of your invoice containing the references of the framework contract and/or agreement, specific contract and/or purchase order, period covered, price per unit, quantity and unit of measurement.`,
      footer_comment: `Please pay the amount of {{total_incl_vat}} to the account {{receivables_iban}} by {{due_date}}.`,
      footer_data: {
        name: 'Thaleria S.A.',
        email: 'finance@thaleria.com',
        phone: '+32 474 95 74 11',
        vat_number: 'BE 0704.867.821',
        iban: '',
        bic_swift: '',
      },
      showReferences: {
        frameworkContract: true,
        partnerContract: true,
        salesOrder: true,
        specificContract: true,
      },
    },
  };

  const [creditNotesData, setCreditNotesData] = useState();
  const [filterOptions, setFilterOptions] = useState();
  const [previewData, setPreviewData] = useState(basicCreditNoteDataObject);
  const [showCreditNotePreview, setShowCreditNotePreview] = useState(false);
  const [lineItems, setLineItems] = useState([]);
  const [showBulkDownloadAlert, setShowBulkDownloadAlert] = useState(false);
  const [bulkDownloading, setBulkDownloading] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const {
    creditNotes: {
      getAllCreditNotesPaginated,
      updateCreditNoteSentStatus,
      getCreditNoteFilterOptions,
      deleteCreditNote,
      getCreditNoteById,
      bulkDownloadCN,
    },
  } = useApi();

  const history = useHistory();

  useEffect(() => {
    getCreditNoteFilterOptions().then(res => {
      setFilterOptions(res);
    });
  }, []);

  const fetchCreditNotes = async (
    page = 0,
    filters = [],
    sortByArray = [],
    globalFilter,
    downloadToCsv,
    source,
    bulkDownload,
    fileNameStructure,
  ) => {
    const creditNoteNumbers = filters.find(filter => filter.id === 'creditNoteNumber')?.value;
    const invoiceNumbers = filters.find(filter => filter.id === 'invoiceNumber')?.value;
    const clients = filters.find(filter => filter.id === 'client')?.value;
    const amount = filters.find(filter => filter.id === 'amount')?.value;
    const vat = filters.find(filter => filter.id === 'vat')?.value;
    const amountAndVat = filters.find(filter => filter.id === 'amountAndVat')?.value;
    const sent = filters.find(filter => filter.id === 'sent')?.value;
    let issueDate = filters.find(filter => filter.id === 'issueDate')?.value;
    const createdBy = filters.find(filter => filter.id === 'createdBy')?.value;
    const sortBy = sortByArray.length ? `${sortByArray[0].id.toString()},${sortByArray[0].desc.toString()}` : undefined;
    let csvData = [];

    if (issueDate !== undefined) {
      issueDate = issueDate.map(d => {
        if (d !== undefined) return americanDate(d);
      });
    }

    if (downloadToCsv) {
      await getAllCreditNotesPaginated(
        page + 1,
        creditNoteNumbers,
        invoiceNumbers,
        clients,
        amount,
        vat,
        amountAndVat,
        sent,
        createdBy,
        issueDate,
        sortBy,
        globalFilter,
        downloadToCsv,
        source,
      ).then(r => {
        r.forEach(e => {
          csvData.push({
            id: e?.id,
            client: e?.client?.name,
            vat: e?.vat || 0,
            amount: e?.amount,
            amountAndVat: e?.amount_and_vat,
            sent: { id: e?.id, sent: e?.sent },
            createdBy: e?.createdBy.full_name,
            issueDate: e?.issue_date ? format(new Date(e?.issue_date), 'dd/MM/yyyy') : '-',
          });
        });
      });
      return csvData;
    }

    if (bulkDownload) {
      setBulkDownloading(true);
      await getAllCreditNotesPaginated(
        page + 1,
        creditNoteNumbers,
        invoiceNumbers,
        clients,
        amount,
        vat,
        amountAndVat,
        sent,
        createdBy,
        issueDate,
        sortBy,
        globalFilter,
        downloadToCsv,
        source,
        bulkDownload,
      ).then(async r => {
        let idArray = r.map(el => el.id).join(',');
        await bulkDownloadCN(idArray, fileNameStructure, source)
          .then(res => {
            setBulkDownloading(false);
          })
          .catch(err => {
            setBulkDownloading(false);
          });
      });
      return;
    }

    if (!bulkDownload && !downloadToCsv) {
      getAllCreditNotesPaginated(
        page + 1,
        creditNoteNumbers,
        invoiceNumbers,
        clients,
        amount,
        vat,
        amountAndVat,
        sent,
        createdBy,
        issueDate,
        sortBy,
        globalFilter,
        downloadToCsv,
        source,
      ).then(r => {
        setCreditNotesData(r);
      });
    }
  };

  const tableData = useMemo(() => {
    let array = [];
    if (creditNotesData?.rows?.length) {
      creditNotesData.rows.forEach(e => {
        array.push({
          id: e?.id,
          client: e?.client?.name,
          vat: formatCurrency(e?.vat),
          amount: formatCurrency(e?.amount),
          amountAndVat: formatCurrency(e?.amount_and_vat),
          sent: { id: e?.id, sent: e?.sent },
          createdBy: e?.createdBy.full_name,
          issueDate: e?.issue_date ? format(new Date(e?.issue_date), 'dd/MM/yyyy') : '-',
          creditNoteNumber: { creditNoteNumber: e?.credit_note_number, id: e?.id } || '-',
          invoiceNumber: e?.accountsReceivable?.invoice_number || '-',
        });
      });
    }
    return array;
  }, [creditNotesData]);

  const columns = useMemo(() => {
    const cols = [
      {
        Header: 'Credit Note ID',
        accessor: 'creditNoteNumber',
        Filter: SelectColumnFilter,
        filter: 'includes',
        filterOptions: filterOptions
          ? filterOptions?.creditNoteNumberOptions?.map(el => ({
              value: el?.credit_note_number,
              label: el?.credit_note_number,
            }))
          : [],
        Cell: ({ value }) => {
          return (
            <div className="flex space-x-4 translate-y-0.5 mr-2 mb-1">
              <span>{value.creditNoteNumber}</span>
              <EyeIcon onClick={e => creditNotePreviewHandler(e, value.id)} className="w-5 h-5 cursor-pointer" />
            </div>
          );
        },
      },
      {
        Header: 'Invoice ID',
        accessor: 'invoiceNumber',
        Filter: SelectColumnFilter,
        filter: 'includes',
        filterOptions: filterOptions
          ? filterOptions?.invoiceNumberOptions?.map(el => ({
              value: el?.accountsReceivable?.invoice_number,
              label: el?.accountsReceivable?.invoice_number,
            }))
          : [],
      },
      {
        Header: 'Client',
        accessor: 'client',
        Filter: SelectColumnFilter,
        filter: 'includes',
        filterOptions: filterOptions
          ? filterOptions?.clientIdOptions?.map(el => ({
              value: el?.id,
              label: el?.name,
            }))
          : [],
      },
      {
        Header: 'VAT',
        accessor: 'vat',
        Filter: NumberRangeColumnFilter,
        filter: 'between',
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        Filter: NumberRangeColumnFilter,
        filter: 'between',
      },
      {
        Header: 'Total',
        accessor: 'amountAndVat',
        Filter: NumberRangeColumnFilter,
        filter: 'between',
      },
      {
        Header: 'Sent',
        accessor: 'sent',
        Filter: SelectColumnFilter,
        filter: 'includes',
        filterOptions: [
          { value: 1, label: 'Yes' },
          { value: 0, label: 'No' },
        ],
        Cell: ({ value }) => {
          return (
            <span className="flex justify-center divide-x-2 items-center">
              <Checkbox
                value={value?.sent}
                className="pb-1 pl-2"
                horizontal
                onChange={() => updateCreditNoteSent(value?.id, !value?.sent)}
              />
            </span>
          );
        },
      },
      {
        Header: 'Issue date',
        accessor: 'issueDate',
        Filter: DateRangeColumnFilter,
        filter: 'between',
      },
      {
        Header: 'Created by',
        accessor: 'createdBy',
        Filter: SelectColumnFilter,
        filter: 'includes',
        filterOptions: filterOptions
          ? filterOptions?.userOptions?.map(el => ({
              value: el?.user_id,
              label: el?.full_name,
            }))
          : [],
      },
    ];
    return cols;
  }, [creditNotesData, filterOptions]);

  const pages = [
    { name: 'Finance Manager', href: '/admin-panel/finance/invoicing-items', current: false },
    { name: 'Credit notes', href: '/admin-panel/finance/credit-notes', current: false },
  ];

  const renderRowMenu = row => {
    return [
      [
        {
          text: 'View/edit details',
          onClick: () => history.push(`/admin-panel/finance/credit-notes/${row.original.id}`),
          show: true,
          icon: PencilIcon,
        },
        {
          text: 'View/edit details in new tab',
          onClick: () => window.open(`/admin-panel/finance/credit-notes/${row.original.id}`),
          show: true,
          icon: ExternalLinkIcon,
        },
      ],
      [
        {
          text: 'Delete row',
          onClick: () => setShowDeleteAlert(row.original.id),
          show: true,
          icon: TrashIcon,
        },
      ],
    ];
  };

  const addButton = {
    link: '/admin-panel/finance/credit-notes/create',
  };

  const rowOnClick = row => {
    history.push(`/admin-panel/finance/credit-notes/${row.original.id}`);
  };

  const updateCreditNoteSent = (id, invoiceSent) => {
    updateCreditNoteSentStatus(id, { sent: invoiceSent }).then(res => {
      if (!creditNotesData?.rows?.length) return;
      const newRows = creditNotesData.rows.map(obj => {
        let newObj = obj;
        if (obj.id === id) {
          newObj.sent = invoiceSent;
        }
        return newObj;
      });

      setCreditNotesData(prev => ({
        ...prev,
        rows: newRows,
      }));
    });
  };

  const creditNotePreviewHandler = (event, id) => {
    if (!id) return;
    getCreditNoteById(id).then(res => {
      res.issue_date = new Date(res.issue_date);
      res.metadata = JSON.parse(res.metadata);

      setPreviewData(res || basicCreditNoteDataObject);
      setLineItems(
        res.lineItems.map(item => {
          item.metadata = JSON.parse(item.metadata);
          return item;
        }),
      );
      setShowCreditNotePreview(true);
    });
    event.stopPropagation();
  };

  const deleteRowHandler = id => {
    setIsDeleting(true);
    deleteCreditNote(id)
      .then(res => {
        showSuccessNotification('Successfully deleted credit note');
        if (creditNotesData) {
          const newRows = creditNotesData.rows.filter(obj => obj.id !== id);
          setCreditNotesData(prev => ({
            ...prev,
            rows: newRows,
          }));
        }
        setIsDeleting(false);
        setShowDeleteAlert(false);
      })
      .catch(err => {
        setIsDeleting(false);
      });
  };

  return (
    <AdminSidebar noPadding pages={pages}>
      <SimpleAlert
        errorTitle={'Delete Credit Note?'}
        errorMsg={'Deleting will completely remove this Credit Note from the application. Are you sure you want to proceed?'}
        onAcceptText={isDeleting ? <ClipLoader className="mr-2 w-20" color={'#FFFF'} size={17} /> : 'Confirm'}
        onAcceptClick={() => deleteRowHandler(showDeleteAlert)}
        onDeclineText="Cancel"
        show={showDeleteAlert ? true : false}
        hide={() => setShowDeleteAlert(false)}
      />
      <CreditNotePDFPreview
        showPreview={showCreditNotePreview}
        setShowPreview={setShowCreditNotePreview}
        creditNoteData={previewData}
        lineItems={lineItems}
      />
      <Table
        columns={columns}
        addButton={addButton}
        data={tableData}
        fetchData={fetchCreditNotes}
        contextMenuOptions={renderRowMenu}
        customContextMenu
        pageCount={creditNotesData?.totalPages}
        tableName="creditNotes"
        totalItems={creditNotesData?.totalItems}
        rowOnClick={rowOnClick}
        bulkDownloadButton={true}
        bulkDownloading={bulkDownloading}
        setBulkDownloading={setBulkDownloading}
        showBulkDownloadAlert={showBulkDownloadAlert}
        setShowBulkDownloadAlert={setShowBulkDownloadAlert}
      />
    </AdminSidebar>
  );
}

export default CreditNotes;
