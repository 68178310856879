import React, { useState, useEffect } from 'react';
import Card from '../../components/Cards/Card';
import dayjs from 'dayjs';
import useApi from '../../hooks/useApi';
import format from 'date-fns/format';
import endOfMonth from 'date-fns/endOfMonth';
import DividerWithLabel from '../../components/Dividers/DividerWithLabel';
import InfoTooltip from '../../components/Tooltips/InfoTooltip';
import { ExternalLinkIcon } from '@heroicons/react/outline';

function UserDetails({ userId, LRContract, timesheetDate }) {
  const [userData, setUserData] = useState(null);
  const [userStaffContracts, setUserStaffContracts] = useState([]);

  const {
    staffContracts: { getActiveStaffContracts },
    users: { getUser },
  } = useApi();

  useEffect(() => {
    if (!userId) return;
    getUser(userId).then(data => {
      setUserData(data);
    });
    if (LRContract) {
      return setUserStaffContracts([LRContract]);
    }
    if (timesheetDate) {
      let startDate = format(timesheetDate, 'yyyy-MM-dd');
      let endDate = format(endOfMonth(timesheetDate), 'yyyy-MM-dd');
      getActiveStaffContracts(userId, startDate, endDate).then(data => {
        if (data[0]?.id) {
          setUserStaffContracts(data);
        }
      });
    } else {
      getActiveStaffContracts(userId).then(data => {
        if (data[0]?.id) {
          setUserStaffContracts(data);
        }
      });
    }
  }, [userId, LRContract]);

  if (!userData) return '';

  const cardTitle = (
    <span className="flex items-center">
      User Details
      <InfoTooltip size="md" iconClassName="h-5 w-5 text-thaleria-orange-700 ml-1">
        Displayed staff contracts were active during this Timesheet's period.
      </InfoTooltip>
    </span>
  );

  return (
    <Card title={cardTitle}>
      <dl className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="col-span-1">
          <dt className="text-sm font-medium text-gray-500">Full name</dt>
          <dd className="mt-1 text-sm text-gray-900 flex items-center gap-x-1">
            {userData.full_name}
            <ExternalLinkIcon
              className="h-4 w-4 cursor-pointer text-thaleria-orange-500 hover:text-thaleria-orange-300"
              onClick={() => window.open(`/admin-panel/hr/user-directory/${userId}`)}
            />
          </dd>
        </div>
        <div className="col-span-1">
          <dt className="text-sm font-medium text-gray-500">Email</dt>
          <dd className="mt-1 text-sm text-gray-900">{userData.email}</dd>
        </div>
        {userStaffContracts.map(userStaffContract => (
          <>
            <DividerWithLabel
              label={
                <span className="flex items-center gap-x-1">
                  {userStaffContract.contract_ref}
                  <ExternalLinkIcon
                    className="h-4 w-4 cursor-pointer text-thaleria-orange-500 hover:text-thaleria-orange-300"
                    onClick={() => window.open(`/admin-panel/contracts/staff-contracts/${userStaffContract.id}`)}
                  />
                </span>
              }
              className="col-span-2"
            />
            <div className="col-span-1">
              <dt className="text-sm font-medium text-gray-500">Position</dt>
              <dd className="mt-1 text-sm text-gray-900">{userStaffContract?.position || '-'}</dd>
            </div>
            <div className="col-span-1">
              <dt className="text-sm font-medium text-gray-500">Contract type</dt>
              <dd className="mt-1 text-sm text-gray-900">{userStaffContract?.contractType.contract_type || '-'}</dd>
            </div>
            <div className="col-span-1">
              <dt className="text-sm font-medium text-gray-500">Contract start</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {userStaffContract ? dayjs(userStaffContract?.contract_start).format('DD/MM/YYYY') : '-'}
              </dd>
            </div>
            <div className="col-span-1">
              <dt className="text-sm font-medium text-gray-500">Contract end</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {userStaffContract
                  ? userStaffContract?.contract_end
                    ? dayjs(userStaffContract?.contract_end).format('DD/MM/YYYY')
                    : 'Open-ended'
                  : '-'}
              </dd>
            </div>
          </>
        ))}
      </dl>
    </Card>
  );
}

export default UserDetails;
