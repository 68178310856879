import React from 'react';
import PageWrapper from '../../../containers/App/PageWrapper';
import NotificationsTable from '../../../containers/Notifications/NotificationsTable';

const MyNotifications = () => {
  const pages = [{ name: 'Notifications', href: '/My', current: true }];
  return (
    <PageWrapper pages={pages}>
      <NotificationsTable />
    </PageWrapper>
  );
};

export default MyNotifications;
