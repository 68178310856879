import React, { useEffect, useState, useMemo } from 'react';
import { date } from '../../../../helpers/date';

import DateRangeColumnFilter from '../../../../components/TableItems/DateRangeColumnFilter';
//components
import Table from '../../../../containers/ServerSideTable';
import { useHistory } from 'react-router';

import SelectColumnFilter from '../../../../components/TableItems/SelectColumnFilter';
import { fwcTypeLabels } from '../../../../helpers/enum/fwcTypes';
import { ExternalLinkIcon, PencilIcon } from '@heroicons/react/outline';
import useApi from '../../../../hooks/useApi';
import { americanDate } from '../../../../helpers/date';
import axios from 'axios';
import AdminSidebar from '../../AdminSidebar';

function FrameworkContracts() {
  const {
    frameworkContracts: { getAllFrameworkContractsPaginated, getFrameworkContractOptions },
  } = useApi();

  const [fetchingData, setFetchingData] = React.useState(true);
  const [filterOptions, setFilterOptions] = useState({});
  const history = useHistory();

  useEffect(() => {
    getFrameworkContractOptions().then(res => {
      setFilterOptions(res);
    });
  }, []);

  const fetchFrameworkContracts = async (page = 0, filters = [], sortByArray = [], globalFilter, downloadToCsv, source) => {
    const reference = filters.find(filter => filter.id === 'reference')?.value;
    const clients = filters.find(filter => filter.id === 'clients')?.value;
    const types = filters.find(filter => filter.id === 'type')?.value;
    let startDates = filters.find(filter => filter.id === 'start')?.value;
    let endDates = filters.find(filter => filter.id === 'end')?.value;
    const sortBy = sortByArray.length ? `${sortByArray[0].id.toString()},${sortByArray[0].desc.toString()}` : undefined;

    if (startDates !== undefined) {
      startDates = startDates.map(d => {
        if (d !== undefined) return americanDate(d);
      });
    }
    if (endDates !== undefined) {
      endDates = endDates.map(d => {
        if (d !== undefined) {
          if (d === 0) return '0';
          return americanDate(d);
        }
      });
    }

    let csvData = [];

    if (downloadToCsv) {
      await getAllFrameworkContractsPaginated(
        page + 1,
        reference,
        startDates,
        endDates,
        clients,
        types,
        sortBy,
        globalFilter,
        downloadToCsv,
      ).then(r => {
        r.map(e => {
          csvData.push({
            id: e.id,
            reference: e.contract_ref,
            start: e.contract_start,
            end: e.contract_end,
            clients: e.clients.length && e.clients.map(i => i.name).join(', '),
            type: fwcTypeLabels[e.type],
          });
        });
      });
      return csvData;
    }

    getAllFrameworkContractsPaginated(
      page + 1,
      reference,
      startDates,
      endDates,
      clients,
      types,
      sortBy,
      globalFilter,
      null,
      source,
    ).then(r => {
      setContractsData(r);
    });
  };

  const [contractsData, setContractsData] = useState([]);
  const tableData = [];

  if (contractsData?.rows?.length) {
    contractsData.rows.map(e => {
      tableData.push({
        id: e.id,
        reference: e.contract_ref,
        start: e.contract_start,
        end: e.contract_end,
        clients: e.clients.length ? e.clients.map(i => i.name).join(', ') : '-',
        type: fwcTypeLabels[e.type],
      });
    });
  }

  const memoizedTableData = tableData;

  const columns = useMemo(() => {
    return [
      {
        Header: 'ID',
        accessor: 'id',
        disableFilters: true,
      },
      {
        Header: 'Reference',
        accessor: 'reference',
      },
      {
        Header: 'Start',
        accessor: 'start',
        Cell: ({ value }) => {
          if (value) return date(value);
          if (!value) return null;
        },
        Filter: DateRangeColumnFilter,
        filter: 'date',
        filterOptions: filterOptions?.contractStart,
      },
      {
        Header: 'End',
        accessor: 'end',
        Cell: ({ value }) => {
          if (value) return date(value);
          if (!value) return 'Open-ended';
        },
        Filter: DateRangeColumnFilter,
        filter: 'date',
        filterOptions: filterOptions?.contractEnd,
        openEndedOption: true,
      },
      {
        Header: 'Clients',
        accessor: 'clients',
        Filter: SelectColumnFilter,
        filter: 'includes',
        filterOptions: filterOptions?.clients?.length
          ? filterOptions?.clients.map(c => ({
              value: c.id,
              label: c.name,
            }))
          : [],
        disableSortBy: true,
      },
      {
        Header: 'Type',
        accessor: 'type',
        Filter: SelectColumnFilter,
        filter: 'includes',
        filterOptions: Object.keys(fwcTypeLabels).map(key => ({
          value: key,
          label: fwcTypeLabels[key],
        })),
      },
    ];
  }, [filterOptions]);

  const pages = [
    { name: 'Contracts Manager', href: '/admin-panel/contracts/purchase-orders', current: false },
    { name: `Framework Contracts`, href: '/admin-panel/contracts/framework-contracts', current: true },
  ];

  let renderRowMenu = row => {
    return [
      [
        {
          text: 'View/edit details',
          onClick: () => history.push(`/admin-panel/contracts/framework-contracts/${row.original.id}`),
          show: true,
          icon: PencilIcon,
        },
        {
          text: 'View/edit details in new tab',
          onClick: () => window.open(`/admin-panel/contracts/framework-contracts/${row.original.id}`),
          show: true,
          icon: ExternalLinkIcon,
        },
      ],
    ];
  };

  const addButton = {
    link: '/admin-panel/contracts/framework-contracts/create',
  };

  const rowOnClick = row => {
    history.push(`/admin-panel/contracts/framework-contracts/${row.values.id}`);
  };

  return (
    <AdminSidebar noPadding pages={pages}>
      <Table
        isLoading={fetchingData}
        columns={columns}
        data={memoizedTableData}
        tableName="frameworkContract"
        customContextMenu
        contextMenuOptions={renderRowMenu}
        addButton={addButton}
        rowOnClick={rowOnClick}
        pageCount={contractsData.totalPages}
        fetchData={fetchFrameworkContracts}
        totalItems={contractsData.totalItems}
      />
    </AdminSidebar>
  );
}

export default FrameworkContracts;
