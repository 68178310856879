import React, { useState, useRef, useEffect } from 'react';
import Modal from '../../components/Modals/Modal';
import ButtonPrimary from '../../components/Buttons/ButtonPrimary';
import FilesIcon from '../../components/Icons/FilesIcon';
import DividerSimple from '../../components/Dividers/DividerSimple';
import PlusMediumIcon from '../../components/Icons/PlusMediumIcon';
import { XIcon } from '@heroicons/react/outline';
import FileAccessManagement from './FileAccessManagement';
import ButtonWhite from '../../components/Buttons/ButtonWhite';
import { AccountType } from '../../helpers/enum/accounts';
import contractTypes from '../../helpers/enum/contractTypeIDs';
import ClipLoader from 'react-spinners/ClipLoader';

function UploadFileModal({
  title,
  onUploadClick,
  show,
  hide,
  selectedFiles,
  setSelectedFiles,
  isOrgDocument,
  parentFolder,
  currentUser,
  loading,
}) {
  const [isDragging, setIsDragging] = useState(false);
  const [acceptedAccountTypes, setAcceptedAccountTypes] = useState([AccountType.ADMIN, AccountType.STAFF, AccountType.USER]);
  const [acceptedContractTypes, setAcceptedContractTypes] = useState([
    contractTypes.company,
    contractTypes.employee,
    contractTypes.freelancer,
  ]);
  const [acceptedCountries, setAcceptedCountries] = useState();
  const [showRestrictions, setShowRestrictions] = useState(false);
  const [disabledCheckboxes, setDisabledCheckboxes] = useState([]);
  const [countryOptions, setCountryOptions] = useState(null);

  useEffect(() => {
    if (!show) {
      setTimeout(() => {
        setSelectedFiles([]);
        setAcceptedAccountTypes([AccountType.ADMIN, AccountType.STAFF, AccountType.USER]);
        setAcceptedContractTypes([contractTypes.employee, contractTypes.company, contractTypes.freelancer]);
        setAcceptedCountries();
        setShowRestrictions(false);
      }, [500]);
    }
  }, [show]);

  useEffect(() => {
    if (parentFolder?.restrictions) {
      let disabledCheckboxes = [];
      Object.keys(contractTypes).forEach(key => {
        !parentFolder.restrictions?.acceptedContractTypes.includes(contractTypes[key]) && disabledCheckboxes.push(key.toString());
      });
      if (!parentFolder.restrictions.acceptedAccountTypes.includes(AccountType.USER)) disabledCheckboxes.push('user');
      if (!parentFolder.restrictions.acceptedAccountTypes.includes(AccountType.STAFF)) disabledCheckboxes.push('staff');
      setDisabledCheckboxes(disabledCheckboxes);
      setAcceptedAccountTypes(parentFolder.restrictions.acceptedAccountTypes);
      setAcceptedContractTypes(parentFolder.restrictions.acceptedContractTypes);
      if (parentFolder.restrictions?.acceptedCountries) {
        setCountryOptions(parentFolder.restrictions.acceptedCountries);
        setAcceptedCountries(parentFolder.restrictions.acceptedCountries);
      } else {
        setCountryOptions(null);
        setAcceptedCountries();
      }
    } else {
      setCountryOptions(null);
      setAcceptedCountries();
    }
  }, [parentFolder, showRestrictions]);

  const inputFile = useRef(null);

  const onChangeFile = event => {
    let files;
    if (!event?.target) {
      files = event;
    } else {
      event.stopPropagation();
      event.preventDefault();
      // let file = event.target.files[0];
      files = event.target.files;
    }

    if (selectedFiles.length) {
      let newFilesArray = Object.keys(files).map(key => {
        return files[key];
      });

      newFilesArray = newFilesArray.filter(file => !selectedFiles.some(f => f.name === file.name));

      setSelectedFiles(selectedFiles.concat(newFilesArray));
    } else {
      const filesArray = Object.keys(files).map(key => {
        return files[key];
      });

      setSelectedFiles(filesArray);
    }
  };

  const removeFile = index => {
    setSelectedFiles(prev => {
      return prev.filter((value, i) => i != index);
    });
  };

  var dropzoneId = 'dropzone';

  window.addEventListener(
    'dragenter',
    function (e) {
      if (e.target.id != dropzoneId) {
        e.preventDefault();
        e.dataTransfer.effectAllowed = 'none';
        e.dataTransfer.dropEffect = 'none';
      }
    },
    false,
  );

  window.addEventListener('dragover', function (e) {
    if (e.target.id != dropzoneId) {
      e.preventDefault();
      e.dataTransfer.effectAllowed = 'none';
      e.dataTransfer.dropEffect = 'none';
    }
  });

  window.addEventListener('drop', function (e) {
    if (e.target.id != dropzoneId) {
      e.preventDefault();
      e.dataTransfer.effectAllowed = 'none';
      e.dataTransfer.dropEffect = 'none';
    }
    if (e.target.id == dropzoneId) {
      e.preventDefault();
      e.dataTransfer.effectAllowed = 'copy';
    }
  });

  const dropHandler = e => {
    e.stopPropagation();
    e.preventDefault();

    onChangeFile(e.dataTransfer.files);
    setIsDragging(false);
  };

  const dragEnterHandler = e => {
    setIsDragging(true);
  };

  const dragLeftHandler = e => {
    setIsDragging(false);
  };

  const dragOverHandler = e => {
    e.preventDefault();
  };

  const userHasSelectedFiles = selectedFiles.length;

  const onSelectClick = () => {
    inputFile.current.click();
  };

  const addRestrictionsAndUpload = () => {
    let addedRestrictions;
    if (isOrgDocument) {
      addedRestrictions = selectedFiles.map(file => {
        if (acceptedCountries || acceptedContractTypes || acceptedAccountTypes) {
          if (acceptedCountries?.length) file.acceptedCountries = acceptedCountries;
          file.acceptedContractTypes = acceptedContractTypes;
          file.acceptedAccountTypes = acceptedAccountTypes;
        }
        return file;
      });
      setSelectedFiles(addedRestrictions);
    }
    onUploadClick();
  };

  const onCountriesInputChangeHandler = event => {
    let newCountries;
    if (!event) newCountries = [];
    else newCountries = event.map(country => country.value);

    setAcceptedCountries(newCountries);
  };

  const onContractTypesChangeHandler = event => {
    if (event.target.id == 'employee') {
      event.target.checked
        ? setAcceptedContractTypes(prev => [...prev, contractTypes.employee])
        : setAcceptedContractTypes(acceptedContractTypes.filter(id => id != contractTypes.employee));
    }
    if (event.target.id == 'freelancer') {
      event.target.checked
        ? setAcceptedContractTypes(prev => [...prev, contractTypes.freelancer])
        : setAcceptedContractTypes(acceptedContractTypes.filter(id => id != contractTypes.freelancer));
    }
    if (event.target.id == 'company') {
      event.target.checked
        ? setAcceptedContractTypes(prev => [...prev, contractTypes.company])
        : setAcceptedContractTypes(acceptedContractTypes.filter(id => id != contractTypes.company));
    }
  };

  const onAccountTypesChangeHandler = event => {
    if (event.target.id == 'user') {
      event.target.checked
        ? setAcceptedAccountTypes(prev => [...prev, AccountType.USER])
        : setAcceptedAccountTypes(acceptedAccountTypes.filter(id => id != AccountType.USER));
    }
    if (event.target.id == 'staff') {
      event.target.checked
        ? setAcceptedAccountTypes(prev => [...prev, AccountType.STAFF])
        : setAcceptedAccountTypes(acceptedAccountTypes.filter(id => id != AccountType.STAFF));
    }
  };

  return (
    <Modal show={show} hide={hide} size="md">
      <div className="sm:flex sm:items-start ">
        <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
          <h2 className="leading-6 text-gray-900 pb-8" id="modal-title">
            {title}
          </h2>
          {showRestrictions ? (
            <FileAccessManagement
              acceptedCountries={acceptedCountries}
              acceptedContractTypes={acceptedContractTypes}
              acceptedAccountTypes={acceptedAccountTypes}
              countriesPlaceholder="All countries"
              onCountriesChange={onCountriesInputChangeHandler}
              onContractTypesChange={onContractTypesChangeHandler}
              onAccountTypesChange={onAccountTypesChangeHandler}
              isAdmin={currentUser.account_type_id == AccountType.ADMIN}
              countryOptions={countryOptions}
              disabledCheckboxes={disabledCheckboxes}
            />
          ) : (
            <div
              id="dropzone"
              onDrop={e => dropHandler(e)}
              onDragLeave={e => dragLeftHandler(e)}
              onDragEnter={e => dragEnterHandler(e)}
              onDragOver={e => dragOverHandler(e)}
              className={`w-full z-40 ${
                isDragging ? 'border-2 border-thaleria-orange-500/75' : 'border-2 border-dashed border-thaleria-blue-200/75'
              } rounded-lg min-h-80 flex flex-col justify-center`}
            >
              <input type="file" id="file" multiple ref={inputFile} onChange={onChangeFile} className="hidden" />
              {userHasSelectedFiles ? (
                <div className="m-4" id="dropzone">
                  <ul className="">
                    {selectedFiles.map((file, index) => {
                      return (
                        <>
                          {index != 0 && <DividerSimple />}
                          <li className="w-full flex justify-between items-center">
                            <span className="flex truncate">
                              <FilesIcon
                                fileType={file.name.split('.')[file.name.split('.').length - 1]}
                                className="w-5 h-5 mr-2"
                              />
                              <p className="truncate">{file.name}</p>
                            </span>
                            <span>
                              <XIcon
                                className="h-4 w-4 text-gray-600 ml-2 cursor-pointer hover:text-gray-700"
                                onClick={() => removeFile(index)}
                              />
                            </span>
                          </li>
                        </>
                      );
                    })}
                  </ul>
                  <PlusMediumIcon
                    style="h-10 w-10 mx-auto mt-4 text-thaleria-orange-700/75 cursor-pointer"
                    onClick={onSelectClick}
                  />
                </div>
              ) : (
                <div className="m-auto flex flex-col gap-1 my-6" id="dropzone">
                  <ButtonPrimary id="dropzone" text="Select file(s)" onClick={onSelectClick} size="xl" disabled={isDragging} />
                  <span id="dropzone">or drop them here</span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="pt-7 flex justify-end">
        <ButtonWhite
          text={!isOrgDocument ? 'Cancel' : showRestrictions ? 'Back' : 'Cancel'}
          onClick={!loading ? (!isOrgDocument ? hide : showRestrictions ? () => setShowRestrictions(false) : hide) : undefined}
        />
        <ButtonPrimary
          text={
            loading == 'uploadFile' ? (
              <ClipLoader className="mr-2" size={17} color={'#ffff'} />
            ) : !isOrgDocument ? (
              'Upload'
            ) : showRestrictions ? (
              'Upload'
            ) : (
              'Next'
            )
          }
          disable={
            !userHasSelectedFiles || !acceptedContractTypes?.length || (!acceptedCountries?.length && countryOptions != null)
          }
          style="ml-2"
          onClick={
            !loading
              ? !isOrgDocument
                ? () => addRestrictionsAndUpload()
                : !showRestrictions
                ? () => setShowRestrictions(true)
                : () => addRestrictionsAndUpload()
              : undefined
          }
        />
      </div>
    </Modal>
  );
}

export default UploadFileModal;
