import React, { useState, useEffect } from 'react';
import TwoColumnForm from '../../../components/Layouts/TwoColumnForm';
import DividerWithLabel from '../../../components/Dividers/DividerWithLabel';
import ReactSelect from '../../../components/Inputs/ReactSelect';
import RefreshButton from '../../../components/Buttons/RefreshButton';
import NewTabOpener from '../../../components/NewTabOpener/NewTabOpener';
import SimpleEntry from '../../../components/DescriptionEntries/SimpleEntry';
import { date } from '../../../helpers/date';
import { fwcTypeLabels, fwcTypes } from '../../../helpers/enum/fwcTypes';
import Toggle from '../../../components/Toggles/Toggle';
import useApi from '../../../hooks/useApi';
import { ExternalLinkIcon } from '@heroicons/react/outline';

function AddEndClientAndPrimeFrameworkContract({
  endClientFWC,
  setEndClientFWC,
  primeFWC,
  setPrimeFWC,
  formErrors,
  subcoPlacement,
  setSubcoPlacement,
  edit,
}) {
  const {
    frameworkContracts: { getAllFrameworkContracts },
  } = useApi();

  const [allFrameworkContracts, setAllFrameworkContracts] = useState([]);

  const fetchAllFrameworkContracts = () => {
    getAllFrameworkContracts().then(data => {
      setAllFrameworkContracts(data.map(el => ({ value: el.id, label: el.contract_ref, ...el })));
    });
  };

  //get all framework contracts
  useEffect(() => {
    fetchAllFrameworkContracts();
  }, []);

  useEffect(() => {
    if (!subcoPlacement && endClientFWC) {
      setPrimeFWC(endClientFWC);
    }
  }, [subcoPlacement, endClientFWC]);

  const renderNameAndLink = (name, href) => {
    return (
      <div className="flex gap-x-2 mr-auto">
        {name}
        {name && (
          <ExternalLinkIcon
            className="h-5 w-5 text-gray-400 hover:text-thaleria-orange-700 cursor-pointer"
            onClick={() => window.open(href)}
          />
        )}
      </div>
    );
  };

  return (
    <TwoColumnForm
      label="Framework contracts"
      description="Please select the end-client FWC as well as the sales FWC for this purchase order."
    >
      <DividerWithLabel label="End-client FWC" />
      <div className="flex flex-col">
        <div className="flex items-end">
          {edit ? (
            <ReactSelect
              isSearchable
              label="Contract"
              labelClass="text-gray-500"
              options={allFrameworkContracts.filter(c => c.type === fwcTypes.END_CLIENT)}
              selectedOptions={endClientFWC}
              onChange={setEndClientFWC}
              error={formErrors.find(e => e.field === 'endClientFWC')?.msg}
              disabled={!edit}
            />
          ) : (
            <SimpleEntry
              label={'Contract'}
              data={renderNameAndLink(
                endClientFWC?.contract_ref,
                `/admin-panel/contracts/framework-contracts/${endClientFWC.id}`,
              )}
            />
          )}
          {edit && <RefreshButton onClick={() => fetchAllFrameworkContracts()} />}
        </div>
        {edit && <NewTabOpener link={'/admin-panel/contracts/framework-contracts/create'} title="Add new contract" />}
      </div>
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        <SimpleEntry label={'Description'} data={endClientFWC?.description || '-'} style={`sm:col-span-1`} />
        <SimpleEntry label={'Start date'} data={date(endClientFWC?.contract_start || '-')} style={`sm:col-span-1`} />
        <SimpleEntry
          label={'End date'}
          data={endClientFWC?.contract_end ? date(endClientFWC?.contract_end) : 'Open-ended'}
          style={`sm:col-span-1`}
        />
        <SimpleEntry label={'Type'} data={fwcTypeLabels[endClientFWC?.type] || '-'} style={`sm:col-span-1`} />
        <SimpleEntry
          label={'Clients'}
          data={endClientFWC?.clients?.map(obj => obj.name).join(', ') || '-'}
          style={`sm:col-span-1`}
        />
        <SimpleEntry label={'Bank account'} data={endClientFWC?.bankAccount?.name || '-'} style={`sm:col-span-1`} />
        <Toggle
          className="col-span-2 mr-auto"
          enable={subcoPlacement}
          onChange={() => setSubcoPlacement(!subcoPlacement)}
          label="Invoicing done through an intermediary (Consortium Leader or Prime Contractor)"
          disabled={!edit}
        />
      </dl>
      {subcoPlacement && (
        <>
          <DividerWithLabel label="Sales FWC" />
          <div className="flex flex-col">
            <div className="flex items-end">
              {edit ? (
                <ReactSelect
                  isSearchable
                  error={formErrors.find(e => e.field === 'primeFWC')?.msg}
                  label="Contract"
                  labelClass="text-gray-500"
                  options={subcoPlacement ? allFrameworkContracts.filter(c => c.type === fwcTypes.SUBCO) : allFrameworkContracts}
                  selectedOptions={primeFWC}
                  onChange={setPrimeFWC}
                  disabled={!edit}
                />
              ) : (
                <SimpleEntry
                  label={'Contract'}
                  data={renderNameAndLink(primeFWC?.contract_ref, `/admin-panel/contracts/framework-contracts/${primeFWC.id}`)}
                />
              )}
              {edit && <RefreshButton onClick={() => fetchAllFrameworkContracts()} />}
            </div>
            {edit && <NewTabOpener link={'/admin-panel/contracts/framework-contracts/create'} title="Add new contract" />}
          </div>
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <SimpleEntry label={'Description'} data={primeFWC?.description || '-'} style={`sm:col-span-1`} />
            <SimpleEntry label={'Start date'} data={date(primeFWC?.contract_start || '-')} style={`sm:col-span-1`} />
            <SimpleEntry
              label={'End date'}
              data={primeFWC?.contract_end ? date(primeFWC?.contract_end) : 'Open-ended'}
              style={`sm:col-span-1`}
            />
            <SimpleEntry label={'Type'} data={fwcTypeLabels[primeFWC?.type] || '-'} style={`sm:col-span-1`} />
            <SimpleEntry
              label={'Clients'}
              data={primeFWC?.clients?.map(obj => obj.name).join(', ') || '-'}
              style={`sm:col-span-1`}
            />
            <SimpleEntry label={'Bank account'} data={primeFWC?.bankAccount?.name || '-'} style={`sm:col-span-1`} />
          </dl>
        </>
      )}
    </TwoColumnForm>
  );
}

export default AddEndClientAndPrimeFrameworkContract;
