import React from 'react';
import ButtonWhite from '../Buttons/ButtonWhite';
import { useHistory } from 'react-router';
import ProfilePicture from '../ProfilePicture/ProfilePicture';
import UserImagePlaceholder from '../UserImagePlaceholder/UserImagePlaceholder';

function WelcomeCard({ user }) {
  const history = useHistory();
  return (
    <section aria-labelledby="profile-overview-title">
      <div className="rounded-lg  bg-white overflow-hidden shadow ">
        <h2 className="sr-only" id="profile-overview-title">
          Profile Overview
        </h2>
        <div className="bg-white p-6 h-30">
          <div className="sm:flex sm:items-center sm:justify-between space-y-4">
            <div className="sm:flex sm:space-x-5">
              <div className="flex-shrink-0">
                <ProfilePicture
                  src={user.profilepic_url}
                  className="mx-auto h-20 w-20 rounded-full"
                  alt=""
                  defaultimage={<UserImagePlaceholder className="mx-auto h-20 w-20 rounded-full" />}
                />
              </div>
              <div className="mt-4 flex flex-col justify-center text-center sm:mt-0 sm:pt-1 sm:text-left">
                <p className="text-sm font-medium text-gray-600">Welcome back,</p>
                <p className="text-xl font-bold text-gray-900 sm:text-2xl">
                  {user?.preferred_name ? user.preferred_name : user.first_names}
                </p>
              </div>
            </div>
            <div className="flex justify-center">
              <ButtonWhite text="View profile" onClick={() => history.push('/my-profile')} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WelcomeCard;
