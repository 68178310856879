import React, { useRef, useState, useMemo, useEffect } from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import formatCurrency from '../../../utils/formatCurrency';
import format from 'date-fns/format';
import InvoiceTemplate from '../../../helpers/invoiceTemplate.js';
import SlideOver from '../../../components/SlideOvers/SlideOver';
import { DownloadIcon } from '@heroicons/react/outline';
import useApi from '../../../hooks/useApi';
import FileNameSettings from '../../Documents/FilenameSettings';
import replaceCommentVariables from '../../../helpers/replaceCommentVariables';

function AccountsReceivablePDFPreview({
  invoiceData,
  lineItems,
  showPreview,
  setShowPreview,
  canDownload = true,
  isEditing = false,
}) {
  const {
    files: { getInvoicePDFByID },
    accountsReceivables: { getInvoiceFileName },
  } = useApi();

  const iframeRef = useRef(null); // Reference to the iframe element

  const [pdfDataUrl, setPdfDataUrl] = useState(null); // State to hold the PDF data URL

  const [timeoutIdState, setTimeoutIdState] = useState();
  const [showFileNameSettings, setShowFileNameSettings] = useState(false);
  const [loading, setLoading] = useState(false);

  // Register fonts
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  pdfMake.fonts = {
    // Font definitions
    Roboto: {
      normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
      bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
      italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
      bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
    },
  };

  const debounceDelay = 1000; // Delay in milliseconds for debouncing

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutIdState) {
        clearTimeout(timeoutIdState);
      }
      timeoutId = setTimeout(() => {
        func();
      }, delay);
      setTimeoutIdState(timeoutId);
    };
  };

  const generatePDF = () => {
    if (invoiceData?.id && !isEditing) {
      getInvoicePDFByID(invoiceData.id)
        .then(res => {
          const base64Data = res.data;
          const contentType = 'application/pdf';
          const url = `data:${contentType};base64:${base64Data}`;
          const iframe = iframeRef.current;
          if (iframe) iframe.src = url + '#toolbar=0';
          setPdfDataUrl(url);
        })
        .catch(err => {
          //if invoice not available in S3, re-generate invoice based on data provided
          const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
          pdfDocGenerator.getDataUrl(dataUrl => {
            setPdfDataUrl(dataUrl); // Save the PDF data URL to state
            const iframe = iframeRef.current;
            if (iframe) iframe.src = dataUrl + '#toolbar=0';
          });
        });
    } else {
      const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
      pdfDocGenerator.getDataUrl(dataUrl => {
        setPdfDataUrl(dataUrl); // Save the PDF data URL to state
        const iframe = iframeRef.current;
        if (iframe) iframe.src = dataUrl + '#toolbar=0';
      });
    }
  };

  const debounceGeneratePDF = debounce(generatePDF, debounceDelay);

  const onDownloadClick = () => {
    setShowFileNameSettings(true);
  };

  const downloadPDF = fileNameStructure => {
    const { includeTS, ...structure } = fileNameStructure;
    if (pdfDataUrl) {
      setLoading(true);
      getInvoiceFileName(invoiceData.invoice_number, structure)
        .then(res => {
          const link = document.createElement('a');
          link.href = pdfDataUrl;
          link.download = res.data.fileName;
          link.click();
          setLoading(false);
          setShowFileNameSettings(false);
        })
        .catch(err => {
          setLoading(false);
          setShowFileNameSettings(false);
        });
    }
  };

  const documentDefinition = useMemo(() => {
    // Define the document definition object
    let _invoiceData = {
      ...invoiceData,
      metadata: {
        ...invoiceData.metadata,
        header_comment: replaceCommentVariables(invoiceData.metadata.header_comment, invoiceData),
        footer_comment: replaceCommentVariables(invoiceData.metadata.footer_comment, invoiceData),
      },
      issue_date: format(invoiceData.issue_date, 'MMM do, yyyy'),
      due_date: invoiceData.due_date ? format(invoiceData.due_date, 'MMM do, yyyy') : '',
    };

    const _documentDefinition = InvoiceTemplate({ invoiceData: _invoiceData, lineItems });
    return _documentDefinition;
  }, [lineItems, invoiceData, showPreview]);

  useEffect(() => {
    if (!showPreview) return;
    if (!invoiceData?.id && !pdfDataUrl) {
      generatePDF();
    } else {
      debounceGeneratePDF(); // Generate the PDF when the component mounts
    }
  }, [invoiceData, lineItems, showPreview]); // Regenerate PDF when inputs change

  return (
    <SlideOver
      show={showPreview}
      onHide={() => setShowPreview(false)}
      title={
        <div className="flex items-center">
          Invoice preview{' '}
          {canDownload ? (
            <DownloadIcon
              className="h-6 w-6 ml-2 text-gray-400 hover:text-thaleria-orange-700 hover:cursor-pointer"
              onClick={onDownloadClick}
            />
          ) : (
            ''
          )}
        </div>
      }
    >
      <FileNameSettings
        show={showFileNameSettings}
        setShow={setShowFileNameSettings}
        fileType="accountsReceivable"
        loading={loading}
        onCancel={() => setShowFileNameSettings(false)}
        onAccept={downloadPDF}
        disableIncludeTS={true}
      />
      <div className="flex-1 h-full overflow-auto">
        <iframe ref={iframeRef} title="PDF Preview" className="w-full h-full" />
      </div>
    </SlideOver>
  );
}

export default AccountsReceivablePDFPreview;
