const formatStringToIban = iban => {
  // Remove any non-alphanumeric characters
  iban = iban.replace(/[^a-zA-Z0-9]/g, '');

  // Convert lowercase letters to uppercase
  iban = iban.toUpperCase();

  // Insert a space every 4 characters
  let formattedIBAN = '';
  for (let i = 0; i < iban.length; i++) {
    if (i > 0 && i % 4 === 0) {
      formattedIBAN += ' ';
    }
    formattedIBAN += iban[i];
  }

  return formattedIBAN;
};

export default formatStringToIban;
