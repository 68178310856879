import React from 'react';

function ButtonWhite({ text, children, icon, onClick, active, style, disabled }) {
  const BUTTON_STYLES = {
    minWidth: '140px',
  };

  const flexJustify = icon ? 'justify-between' : 'justify-center';

  return (
    <button
      type="button"
      style={BUTTON_STYLES}
      disabled={disabled}
      onClick={onClick}
      className={`${active ? 'border-2 border-indigo-300' : 'border-gray-300 border '}  ${
        style ? style : ''
      } text-gray-700 inline-flex ${flexJustify} items-center px-4 py-2 shadow-sm text-sm font-medium rounded-md  ${
        disabled ? 'bg-gray-200 cursor-not-allowed' : 'bg-white hover:bg-gray-50'
      } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-thaleria-blue-500`}
    >
      {/* <!-- Heroicon name: solid/mail --> */}
      {icon}
      <span>{text}</span>
      <span>{children}</span>
    </button>
  );
}

export default ButtonWhite;
