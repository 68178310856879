import React from 'react';

function TabItem({ label, onClick, isActive }) {
  return (
    <button
      onClick={onClick}
      className={`${
        isActive
          ? 'border-thaleria-orange-700 text-gray-800'
          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
      }  pb-4 px-1 border-b-2 font-medium text-sm`}
    >
      {label}
    </button>
  );
}

export default TabItem;
