import { SET_NOTIFICATION, HIDE_NOTIFICATION, PERSIST_TABLE_FILTERS, REMOVE_TABLE_FILTERS } from './types';
import notifications from '../../helpers/enum/notifications';

export const setNotification = (type, title, msg) => dispatch => {
  dispatch({
    type: SET_NOTIFICATION,
    payload: {
      type: type,
      title: title,
      msg: msg,
    },
  });
  setTimeout(() => {
    dispatch({
      type: HIDE_NOTIFICATION,
    });
  }, 3000);
};

export const showSuccessNotification = msg => dispatch => {
  dispatch({
    type: SET_NOTIFICATION,
    payload: {
      type: notifications.SUCCESS,
      title: 'Success',
      msg: msg,
    },
  });
  setTimeout(() => {
    dispatch({
      type: HIDE_NOTIFICATION,
    });
  }, 3000);
};

export const showErrorNotification = msg => dispatch => {
  dispatch({
    type: SET_NOTIFICATION,
    payload: {
      type: notifications.WARNING_YELLOW,
      title: 'Error',
      msg: msg,
    },
  });
  setTimeout(() => {
    dispatch({
      type: HIDE_NOTIFICATION,
    });
  }, 3000);
};

export const hideNotification = () => dispatch => {
  dispatch({
    type: HIDE_NOTIFICATION,
  });
};
