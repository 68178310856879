import React, { useState, useMemo } from 'react';
import dayjs from 'dayjs';
import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon } from '@heroicons/react/solid';
import InputSimple from '../../../components/Inputs/InputSimple';
import formatNumber from '../../../utils/formatNumber';

const PaginatedTimesheetEntries = ({ timesheetEntries, setTimesheetEntries }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [toggle, setToggle] = useState(true);
  const itemsPerPage = 5;

  const currentItems = useMemo(() => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = timesheetEntries.slice(indexOfFirstItem, indexOfLastItem);

    return currentItems;
  }, [timesheetEntries, currentPage]);

  const pageNumbers = useMemo(() => {
    const totalItems = timesheetEntries.length;
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  }, [timesheetEntries]);

  const handlePageChange = pageNumber => {
    setCurrentPage(pageNumber);
  };

  const handleToggleAll = () => {
    let updatedArray = timesheetEntries.map(el => {
      const newHours = toggle ? Number(el.hours) : 0;
      return { ...el, adjustedHours: newHours, creditedHours: el.hours - newHours };
    });
    setTimesheetEntries(updatedArray);
    setToggle(!toggle);
  };

  function handleChangeHours(e, entry) {
    let newValue = parseFloat(e.target.value);

    // If entered value is not a value it must be 0 (only way would be to erase last number)
    if (isNaN(newValue)) newValue = 0;

    // Ensure that the entered value is within the range [0, entry.hours]
    const clampedValue = Math.min(Math.max(newValue, 0), entry.hours);

    // Update your state or do something with clampedValue
    let updatedArray = timesheetEntries.map(el => {
      if (el.id === entry.id) {
        return { ...el, adjustedHours: clampedValue, creditedHours: el.hours - clampedValue };
      } else return el;
    });

    setTimesheetEntries(updatedArray);
  }

  return (
    <div>
      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Day
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Original hours
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Adjusted hours
              <span
                onClick={handleToggleAll}
                className="underline  text-gray-500 ml-2 cursor-pointer"
                style={{ fontSize: '0.7rem' }}
              >
                toggle all
              </span>
            </th>
            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
              Credited hours
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 overflow-y-auto">
          {currentItems.map(entry => (
            <tr key={entry.id}>
              <td className="whitespace-nowrap px-2 py-1 text-sm text-gray-500">{dayjs(entry.date).format('ddd Do')}</td>
              <td className="whitespace-nowrap px-2 py-1 text-sm text-gray-500">{formatNumber(entry.hours, true)}</td>
              <td className="whitespace-nowrap px-2 py-1 text-sm text-gray-500">
                <div className="w-16">
                  <InputSimple
                    type="number" // Use number type for input
                    max={entry.hours}
                    min={0}
                    step="0.01" // Allow up to 2 decimal places
                    value={entry.adjustedHours}
                    onChange={e => handleChangeHours(e, entry)}
                    onBlur={e => handleChangeHours(e, entry)}
                  />
                </div>
              </td>
              <td className="whitespace-nowrap px-2 py-1 text-sm text-gray-500">{formatNumber(entry.creditedHours, true)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <nav className="flex items-center  justify-between border-t border-gray-200 px-4 sm:px-0">
        <div className="-mt-px flex w-0 flex-1">
          <button
            className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:text-gray-700"
            onClick={() => handlePageChange(currentPage > 1 ? currentPage - 1 : 1)}
          >
            <ArrowNarrowLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
            Previous
          </button>
        </div>
        <div className="hidden md:-mt-px md:flex">
          {pageNumbers.map(number => (
            <button
              className={`${
                currentPage === number
                  ? 'border-blue-400 text-blue-400'
                  : 'text-gray-500 hover:border-gray-300 hover:text-gray-700'
              } inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium `}
              key={number}
              onClick={() => handlePageChange(number)}
            >
              {number}
            </button>
          ))}
        </div>
        <div className="-mt-px flex w-0 flex-1 justify-end ">
          <button
            className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:text-gray-700"
            onClick={() => handlePageChange(currentPage < pageNumbers.length ? currentPage + 1 : pageNumbers.length)}
          >
            <ArrowNarrowRightIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
            Next
          </button>
        </div>
      </nav>
    </div>
  );
};

export default PaginatedTimesheetEntries;
