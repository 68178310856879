/**
 *
 * @param {*} number - Number. The value to be rounded
 * @param {*} toString - Boolean (optional). If true will return number as a string and also removes additional zeros at the end of the number. False by default
 * @param {*} decimalPlaces - Number (optional). Maximum possible decimal cases the number will return. 2 by default
 * @returns
 */

const formatNumber = (number, toString = false, decimalPlaces = 2) => {
  /// Convert number to float if it's a string
  const numericValue = typeof number === 'string' ? parseFloat(number) : number;

  // Round the number to the specified decimal places
  const roundedNumber = Math.round(numericValue * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);

  // Convert the rounded number to a string if toString is true
  if (toString) {
    return roundedNumber.toFixed(decimalPlaces).replace(/(?:\.0+|(\.\d+?)0+)$/, '$1');
  }

  return roundedNumber;
};

export default formatNumber;
