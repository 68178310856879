import { isDate, format } from 'date-fns';

function extractObjectDifferences(newObject, originalObject) {
  let differences = {};

  Object.keys(newObject).forEach(key => {
    let isDifferent = false;
    if (Array.isArray(newObject[key])) {
      if (originalObject[key] == null) {
        isDifferent = true;
      } else {
        const newElements = newObject[key].filter(
          el => !originalObject[key].some(i => (i?.value ? i.value : i) == (el?.value ? el.value : el)),
        );
        const elementsRemoved = originalObject[key].filter(
          el => !newObject[key].some(i => (i?.value ? i.value : i) == (el?.value ? el.value : el)),
        );
        isDifferent = newElements.length || elementsRemoved.length;
      }
    } else if (isDate(newObject[key])) {
      isDifferent = format(newObject[key], 'yyyy-MM-dd') !== format(new Date(originalObject[key]), 'yyyy-MM-dd');
    } else if (typeof newObject[key] === 'object') {
      isDifferent = JSON.stringify(newObject[key]) !== JSON.stringify(originalObject[key]);
    } else if (key === 'days_ordered' || key === 'daily_rate') {
      isDifferent = new Number(newObject[key]).valueOf() !== new Number(originalObject[key]).valueOf();
    } else {
      isDifferent = newObject[key] !== originalObject[key];
    }
    if (isDifferent) {
      differences[key] = newObject[key];
    }
  });

  if (!Object.keys(differences).length) return false;
  return differences;
}

export default extractObjectDifferences;
