import React from 'react';
import ButtonPrimary from '../../components/Buttons/ButtonPrimary';
import ButtonWhite from '../../components/Buttons/ButtonWhite';
import PropTypes from 'prop-types';
import Modal from '../../components/Modals/Modal';

const DeleteDocument = ({ show, setShow, onDeleteClick, onCancelClick, type }) => {
  return (
    <Modal show={show} hide={setShow(false)} title={`Are you sure you want to delete this ${type}?`}>
      {type === 'file' ? (
        <span className="block text-sm font-medium text-gray-700">
          By clicking confirm you will be permanently deleting this file.
        </span>
      ) : (
        <span className="block text-sm font-medium text-gray-700">
          By clicking confirm you will be permanently deleting this folder and all its contents.
        </span>
      )}
      <div className="flex flex-row-reverse w-full mt-8">
        <ButtonPrimary style="ml-2" text="Confirm" onClick={onDeleteClick} />
        <ButtonWhite text="Cancel" onClick={onCancelClick} />
      </div>
    </Modal>
  );
};

DeleteDocument.prototype = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onCancelClick: PropTypes.func,
};

export default DeleteDocument;
