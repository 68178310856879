export const extensionOptions = [
  {
    value: true,
    label: 'Extended',
  },
  {
    value: false,
    label: 'Closed',
  },
  {
    value: null,
    label: 'Pending',
  },
];
