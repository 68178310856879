import React from 'react';
import InputPhoto from '../../../components/Inputs/InputPhoto';
import InputSimple from '../../../components/Inputs/InputSimple';
import InputDate from '../../../components/Inputs/InputDate/InputDate';
import { Controller } from 'react-hook-form';
import ReactSelect from '../../../components/Inputs/ReactSelect';

function PersonalInfo({
  onChange,
  newUser,
  onProfilePicChange,
  croppedImage,
  setCroppedImage,
  register,
  control,
  errors,
  countries,
  userProfilePicUrl,
}) {
  return (
    <div className="mt-10 sm:mt-0 overflow-visible ">
      <div className="md:grid md:grid-cols-3 md:gap-6 ">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Personal Info</h3>
            <p className="mt-1 text-sm text-gray-600">Add user's personal information.</p>
          </div>
        </div>
        <div className="mt-5 md:mt-0 md:col-span-2 sm:rounded-md overflow-visible">
          <div className="sm:rounded-md">
            <div className="shadow sm:rounded-md">
              <div className="px-4 py-5 bg-white sm:p-6 sm:rounded-md">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <InputSimple
                      label={'First name(s)*'}
                      dataLabel={'first_names'}
                      register={register('first_names')}
                      error={errors['first_names']?.message}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <InputSimple
                      label={'Last name(s)*'}
                      dataLabel={'last_names'}
                      register={register('last_names')}
                      error={errors['last_names']?.message}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <InputSimple
                      label={'Preferred name'}
                      onChange={(e, key) => onChange(key, e.target.value)}
                      dataLabel={'preferred_name'}
                      value={newUser.data.preferred_name}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <Controller
                      control={control}
                      name={'birthday'}
                      render={({ field: { onChange: formOnChange, value, ref } }) => (
                        <InputDate
                          inputRef={ref}
                          className="w-full"
                          label={'Birthday'}
                          onChange={(value, key) => {
                            formOnChange(value);
                            onChange('birthday', value);
                          }}
                          selected={newUser.data.birthday ? new Date(newUser.data.birthday) : ''}
                          error={errors['birthday']?.message}
                        />
                      )}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <InputSimple
                      label={'ID number'}
                      onChange={(e, key) => onChange(key, e.target.value)}
                      dataLabel={'id_nb'}
                      value={newUser.data.id_nb}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <ReactSelect
                      isSearchable
                      label="Country"
                      options={countries}
                      selectedOptionsIds={newUser.data.country && [newUser.data.country]}
                      onChange={e => {
                        onChange('country', e.value);
                      }}
                    />
                  </div>
                </div>
                <div className="mt-6">
                  <InputPhoto
                    label="Photo"
                    value={
                      croppedImage
                        ? croppedImage == 'deleted'
                          ? null
                          : URL.createObjectURL(croppedImage)
                        : newUser.data.profilepic_url
                    }
                    onChange={onProfilePicChange}
                    setCroppedImage={setCroppedImage}
                    userProfilePicUrl={userProfilePicUrl}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonalInfo;
