import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import ButtonPrimary from '../../../components/Buttons/ButtonPrimary';
import ButtonWhite from '../../../components/Buttons/ButtonWhite';
import ReactSelect from '../../../components/Inputs/ReactSelect';
import TextArea from '../../../components/Inputs/TextArea';
import Modal from '../../../components/Modals/Modal';
import countryID from '../../../helpers/enum/CountryIDs';
import notifications from '../../../helpers/enum/notifications';
import useApi from '../../../hooks/useApi';
import { setNotification } from '../../../store/app/actions';
import InputSimple from '../../../components/Inputs/InputSimple';
import { PlusIcon, ArrowRightIcon } from '@heroicons/react/solid';
import formatNumber from '../../../utils/formatNumber';

function UpdateLeaveBalanceModal({
  open,
  setOpen,
  currentLeaveDays,
  userId,
  setLeaveDays,
  setRefresh,
  contractId,
  contractCountryId,
}) {
  const dispatch = useDispatch();

  const {
    leaveRequests: { updateLeaveRequestBalance },
  } = useApi();
  const [paidLeaveToAdd, setPaidLeaveToAdd] = useState();
  const [recuperationToAdd, setRecuperationToAdd] = useState();
  const [newPaidLeaveDays, setNewPaidLeaveDays] = useState();
  const [newRecuperationDays, setNewRecuperationDays] = useState();
  const [updateMessage, setUpdateMessage] = useState('');
  const [textError, setTextError] = useState('');

  useEffect(() => {
    const _newPaidLeaveDays = (currentLeaveDays?.paidLeaveDays || 0) + (new Number(paidLeaveToAdd).valueOf() || 0);
    const _newRecuperationDays = (currentLeaveDays?.recuperationDays || 0) + (new Number(recuperationToAdd).valueOf() || 0);
    setNewPaidLeaveDays(formatNumber(_newPaidLeaveDays));
    setNewRecuperationDays(formatNumber(_newRecuperationDays));
  }, [paidLeaveToAdd, recuperationToAdd]);

  useEffect(() => {
    let initialRecupDays;
    let initialLeaveDays;
    if (open) {
      initialLeaveDays = currentLeaveDays.paidLeaveDays || 0;
      initialRecupDays = currentLeaveDays.recuperationDays || 0;
      setNewPaidLeaveDays(new Number(initialLeaveDays).valueOf());
      setNewRecuperationDays(new Number(initialRecupDays).valueOf());
      setUpdateMessage('');
    }
  }, [open, currentLeaveDays]);

  const updateLeaveBalanceHandler = () => {
    if (newRecuperationDays === currentLeaveDays.recuperationDays && newPaidLeaveDays === currentLeaveDays.paidLeaveDays) {
      handleCloseModal();
      return;
    } else if (!updateMessage) {
      setTextError('Message must be filled');
    } else {
      const dataToSend = {
        recuperation_days_balance: newRecuperationDays,
        leave_days_balance: newPaidLeaveDays,
        comment: updateMessage,
        staff_contract_id: contractId,
      };

      updateLeaveRequestBalance(userId, dataToSend).then(data => {
        setRefresh(prev => prev + 1);
        dispatch(setNotification(notifications.SUCCESS, 'Success', 'Leave request balance successfully updated.'));
        handleCloseModal();
      });
    }
  };

  const handleCloseModal = () => {
    setTextError('');
    setOpen(false);
  };

  return (
    <Modal title={'Update leave days'} show={open} hide={() => handleCloseModal()}>
      <div className="bg-white flex flex-col items-center w-full ">
        <div className="space-y-4 w-full">
          <div>Leave days</div>
          <div className="w-full flex items-center justify-start space-x-4">
            <p>{currentLeaveDays?.paidLeaveDays || 0}</p>
            <PlusIcon className="w-4 h-4 text-gray-500" />
            <div className="w-20">
              <InputSimple
                type="number"
                className="w-10"
                value={paidLeaveToAdd}
                onChange={e => {
                  setPaidLeaveToAdd(e.target.value);
                }}
              />
            </div>
            <ArrowRightIcon className="w-4 h-4 text-gray-500" />
            <p>{newPaidLeaveDays}</p>
          </div>
          <div>Recuperation days</div>
          <div className="w-full flex items-center justify-start space-x-4">
            <p>{currentLeaveDays?.recuperationDays || 0}</p>
            <PlusIcon className="w-4 h-4 text-gray-500" />
            <div className="w-20">
              <InputSimple
                type="number"
                className="max-w-20"
                value={recuperationToAdd}
                onChange={e => {
                  setRecuperationToAdd(e.target.value);
                }}
                disabled={contractCountryId != countryID.belgium}
              />
            </div>
            <ArrowRightIcon className="w-4 h-4 text-gray-500" />
            <p>{newRecuperationDays}</p>
          </div>
          <TextArea
            errorMessage={textError}
            label="Message"
            rows={3}
            value={updateMessage}
            onChange={e => setUpdateMessage(e.target.value)}
          />
        </div>
        <div className="flex flex-row-reverse w-full mt-6">
          <ButtonPrimary style="ml-2" text="Update" onClick={updateLeaveBalanceHandler} />
          <ButtonWhite text="Cancel" onClick={handleCloseModal} />
        </div>
      </div>
    </Modal>
  );
}

export default UpdateLeaveBalanceModal;
