import React from 'react';
import { ExternalLinkIcon } from '@heroicons/react/outline';
import { format } from 'date-fns';
import { TimeSheetStatusLabels } from '../../helpers/enum/timeSheet';

function APDetailsTimesheetList({ timesheets }) {
  return (
    <div className="flex flex-col">
      <span className="font-semibold text-lg my-4">Timesheets</span>
      <ul className="space-y-2">
        {timesheets.map(ts => {
          return (
            <li className="flex w-full bg-white rounded-md justify-between text-sm gap-x-1 items-center py-1 px-2">
              <div>
                {format(new Date(`${ts.year}-${ts.month}-01`), 'MMM yy')} - {TimeSheetStatusLabels[ts.status]}
              </div>
              <ExternalLinkIcon
                className="h-5 w-5 cursor-pointer text-thaleria-orange-500 hover:text-thaleria-orange-300"
                onClick={() => window.open(`/admin-panel/hr/timesheets/${ts.id}`)}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default APDetailsTimesheetList;
