import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { downloadBase64File } from '../../../helpers';
import { DocumentType } from '../../../helpers/enum/documents';
import useApi from '../../../hooks/useApi';
import AddFolder from '../../Documents/AddFolder';
import UserDocumentHeader from '../../Documents/UserDocumentHeader';
import MultiDocuments from '../../Documents/MultiDocuments';
import Loader from '../../../components/Loading/Loader';
import SimpleAlert from '../../../components/Modals/SimpleAlert';
import PreviewBox from '../../../containers/PDFViewer/PreviewBox';
import checkFileName from '../../../utils/checkFileName';

const pathStack = [];
const UserDocuments = ({ userId, userName }) => {
  const {
    files: { createFolder, deleteObject, downloadUserDocumentFiles, uploadDocumentFile, userDocumentsFilesOnFolder },
  } = useApi();
  const userRootDirectory = 'Personal documents';

  const [newFolderName, setNewFolderName] = useState('');
  const [openAddFolder, _setOpenAddFolder] = useState(false);
  const [filesInfo, setFilesInfo] = useState({ path: '/', files: [] });
  const [loading, setLoading] = useState(true);
  const [loadingDocs, setLoadingDocs] = useState(false);
  const [pages, setPages] = useState([]);
  const [selectedFile, setSelectedFile] = useState();
  const [showDuplicateAlert, setShowDuplicateAlert] = useState(false);
  const [preparingPreview, setPreparingPreview] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [showInvalidNamesAlert, setShowInvalidNamesAlert] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const initialUrl = '/admin-panel/hr/user-directory';
  const homeLink = {
    href: `${initialUrl}/${userId}/documents`,
    text: `${userName.split(' ')[0]}'s files`,
  };

  const CheckIsRootDirectory = filesInfo.path !== '/';

  useEffect(() => {
    changeDirectory('/');
  }, []);

  const setOpenAddFolder = () => {
    setNewFolderName('');
    _setOpenAddFolder(!openAddFolder);
  };

  const changeDirectory = redirectTo => {
    if (!userId) return;
    setLoading(true);
    setFilesInfo({ path: redirectTo, files: [] });
    userDocumentsFilesOnFolder(userId, redirectTo).then(res => {
      let filesInfo = res.data.filter(file => {
        if (file.key == redirectTo) {
          return false;
        } else {
          return true;
        }
      });
      filesInfo = filesInfo.map(file => {
        return prepareObjectInfo(file.key, file.name, file?.size);
      });

      pathStack.push(redirectTo);
      setFilesInfo({ path: redirectTo, files: filesInfo });
      setLoading(false);
    });
  };

  const onFolderNameChange = e => {
    const text = e.target.value;
    setNewFolderName(text);
  };

  const CreateNewFolder = e => {
    const newFolderPath = filesInfo.path + newFolderName + '/';
    if (filesInfo.files.some(file => file.path == newFolderPath)) {
      setOpenAddFolder();
      return setShowDuplicateAlert(true);
    }
    setLoadingDocs('createFolder');
    createFolder(DocumentType.USER, newFolderPath, userId).then(res => {
      setFilesInfo(previous => ({
        path: previous.path,
        files: [...previous.files, prepareObjectInfo(newFolderPath, newFolderName)],
      }));
      setOpenAddFolder();
      setLoadingDocs(false);
    });
  };

  const goToPreviousDirectory = () => {
    if (pathStack.length == 1) return;
    pathStack.pop();
    const previousPath = pathStack.pop();

    changeDirectory(previousPath);
  };

  const prepareObjectInfo = (path, name, size) => {
    const isFolder = path.slice(-1) === '/';

    return {
      path: path,
      name: name,
      type: isFolder ? 'folder' : 'file',
      capacity: isFolder ? null : size,
      onDoubleClick: isFolder
        ? () => history.push(`${initialUrl}/${userId}/documents${path}`)
        : downloadThaleriaDocument.bind(this, path),
      deleteAccess: true,
      onDeleteClick: removeObject.bind(this, path, userId),
      onDownloadClick: isFolder ? console.log.bind(this, `Can not download folder`) : downloadThaleriaDocument.bind(this, path),
      userId: userId,
    };
  };

  const fileUploadHandler = fileUpload => {
    if (!checkFileName(fileUpload.name)) {
      setShowInvalidNamesAlert(true);
      return;
    }
    if (filesInfo.files.some(file => file.name == fileUpload.name)) {
      return setShowDuplicateAlert(true);
    }
    if (fileUpload?.size > 50000000) {
      alert(`Maximum file size is 50mb`);
      return null;
    }
    setLoadingDocs('uploadFile');
    uploadDocumentFile(fileUpload, DocumentType.USER, filesInfo.path, userId).then(res => {
      for (let r of res) {
        const { key, name } = r;
        setFilesInfo(previous => ({
          path: previous.path,
          files: [...previous.files, prepareObjectInfo(key, name, fileUpload?.size)],
        }));
      }
      setLoadingDocs(false);
    });
  };

  const downloadThaleriaDocument = filePath => {
    setLoadingDocs({ downloadFile: filePath });
    const fileName = filePath.split('/')[filePath.split('/').length - 1];
    downloadUserDocumentFiles(userId, filePath).then(response => {
      downloadBase64File(response.data, fileName);
      setLoadingDocs(false);
    });
  };

  const previewHandler = filePath => {
    downloadUserDocumentFiles(userId, filePath)
      .then(response => {
        setPreparingPreview(false);
        setShowPreview({
          show: true,
          url: response.data,
          fileType: filePath.split('.')[filePath.split('.').length - 1],
          title: filePath.split('.')[filePath.split('.').length - 2].replace('/', ''),
        });
      })
      .catch(() => {
        setPreparingPreview(false);
      });
  };

  const removeObject = (path, userId) => {
    setLoadingDocs({ deleteObj: path });
    return new Promise((resolve, reject) => {
      deleteObject(DocumentType.USER, path, userId).then(res => {
        setFilesInfo(previous => ({
          path: previous.path,
          files: previous.files.filter(file => file.path !== path),
        }));
        setLoadingDocs(false);
        resolve();
      });
    });
  };

  const showPathName = userRootDirectory + filesInfo.path;

  useEffect(() => {
    let pages = [];
    const initialUrl = `/admin-panel/hr/user-directory/${userId}/documents`;
    const pathNameArray = location.pathname.split('/');
    const isFolderPath = pathNameArray.length > 5 && pathNameArray[5] == 'documents';
    const mainFolder = pathNameArray[5];
    let breadcrumbArray = [];
    if (isFolderPath && mainFolder) {
      pathNameArray.splice(0, 6);
      let redirect = '/' + pathNameArray.join('/');
      changeDirectory(redirect);
      pathNameArray.forEach((path, index) => {
        breadcrumbArray.push(path);
        const elementNotInPages = path == '';
        if (elementNotInPages) return;
        pages.push({
          name: path,
          href: `${initialUrl}/${breadcrumbArray.join('/')}/`,
          current: false,
        });
      });
      if (pages.length) pages[pages.length - 1].current = true;
      setPages(pages);
    }
  }, [location.pathname, userId]);

  return (
    <div className="flex flex-col w-full overflow-hidden py-6">
      <div className="px-8 pb-4">
        <SimpleAlert
          errorTitle={'Unable to create folder/upload file'}
          errorMsg="Folder/file name already exists"
          onAcceptClick={() => setShowDuplicateAlert(false)}
          onAcceptText={'Close'}
          show={showDuplicateAlert}
          hide={() => setShowDuplicateAlert(false)}
        />
        <SimpleAlert
          errorTitle={'Unable to create upload file(s)'}
          errorMsg="File name contains special characters that are not accepted. Please remove all special characters before uploading."
          onAcceptClick={() => setShowInvalidNamesAlert(false)}
          onAcceptText={'Close'}
          show={showInvalidNamesAlert}
          hide={() => setShowInvalidNamesAlert(false)}
        />
        <PreviewBox
          filePath={showPreview.url}
          fileType={showPreview.fileType}
          showPreview={showPreview.show}
          handleHide={() => setShowPreview(false)}
          title={showPreview.title}
        />
        <UserDocumentHeader
          path={showPathName}
          onAddFolderClick={setOpenAddFolder}
          onBackClick={goToPreviousDirectory}
          uploadHandler={fileUploadHandler}
          showArrowLeftIcon={CheckIsRootDirectory}
          showBreadcrumbs={true}
          pages={pages}
          homeLink={homeLink}
        />
      </div>

      <Loader isLoading={loading}>
        <MultiDocuments
          small
          files={filesInfo.files}
          deleteAccess={true}
          threeDotMenu
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          forAdmins
          listDisplay
          loading={loadingDocs}
          downloadFile={downloadThaleriaDocument}
          preparingPreview={preparingPreview}
          setPreparingPreview={setPreparingPreview}
          previewHandler={previewHandler}
        />
      </Loader>
      <AddFolder
        folderNameValue={newFolderName}
        onFolderNameChange={onFolderNameChange}
        onCreateClick={CreateNewFolder}
        open={openAddFolder}
        hide={() => setOpenAddFolder()}
        onCancelClick={setOpenAddFolder}
        setFolderNameValue={() => setNewFolderName()}
      />
    </div>
  );
};

export default UserDocuments;
