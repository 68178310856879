import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isLastArrayElement from '../../utils/isLastArrayElement';
import ProfilePicture from '../../components/ProfilePicture/ProfilePicture';
import UserImagePlaceholder from '../../components/UserImagePlaceholder/UserImagePlaceholder';
import useApi from '../../hooks/useApi';
import { ArrowNarrowLeftIcon, ArrowNarrowDownIcon } from '@heroicons/react/solid';
import { format } from 'date-fns';

const LeaveBalanceLog = ({ userId, refresh, contractId }) => {
  const {
    leaveRequests: { getChangeLogs, getUserChangeLogs },
  } = useApi();

  const [storeLeaveBalanceLog, setStoreLeaveBalanceLog] = useState([]);
  const [page, setPage] = useState(1);
  const [canViewMore, setCanViewMore] = useState(false);

  useEffect(() => {
    let action;
    if (userId) {
      action = () => getUserChangeLogs(userId, contractId, 1);
    } else action = () => getChangeLogs(1);
    action().then(d => {
      if (d.totalPages > page) setCanViewMore(true);
      setStoreLeaveBalanceLog(d.rows);
    });
  }, [userId, refresh, contractId]);

  useEffect(() => {
    if (page === 1) return;
    let action;
    if (userId) {
      action = () => getUserChangeLogs(userId, contractId, page);
    } else action = () => getChangeLogs(page);
    action().then(d => {
      if (d.totalPages >= page) setCanViewMore(false);
      setStoreLeaveBalanceLog(prev => [...prev, ...d.rows]);
    });
  }, [page]);

  const rows = storeLeaveBalanceLog.map((log, index) => (
    <div
      key={log.created_at}
      className={`px-2 flex flex-row ${index !== 0 && 'border-t-2 border-gray-200'} ${index === 0 ? 'pb-6' : 'py-6'}`}
    >
      <div className="flex-shrink-0 mr-5">
        <ProfilePicture
          src={log.created_by.profilepic_url}
          className="w-10 h-10 rounded-full"
          alt=""
          defaultimage={<UserImagePlaceholder className="w-10 h-10 rounded-full" />}
        />
      </div>
      <div className="min-w-0 flex-1">
        <div className="flex flex-row justify-between text-sm">
          <div className="flex">
            <span className="font-medium text-gray-900">{log.created_by.first_names + ' ' + log.created_by.last_names}</span>
            <p className="text-sm text-gray-500">
              <span className="font-bold mx-1">&#8226;</span>
              Updated to {log.leave_days_balance + log.recuperation_days_balance} available days
            </p>
          </div>
          <span className="text-gray-500">{format(new Date(log.created_at), 'd/M/yyyy')}</span>
        </div>
        <div className=" text-sm text-gray-700 mt-2">
          <p>{log.comment}</p>
        </div>
      </div>
    </div>
  ));

  return (
    <>
      {storeLeaveBalanceLog.length === 0 && <span className="text-sm text-gray-500">There are no recent changes to display</span>}
      <div className="h-full pb-4">
        {rows}
        {canViewMore && (
          <p
            className="mx-auto w-max text-thaleria-orange-400 hover:text-thaleria-orange-700 flex items-center cursor-pointer"
            onClick={() => setPage(page + 1)}
          >
            {/* <ArrowNarrowDownIcon className="w-4 h-4" /> */}
            View more
            <ArrowNarrowDownIcon className="w-4 h-4" />
          </p>
        )}
      </div>
    </>
  );
};

LeaveBalanceLog.prototype = {
  userId: PropTypes.string,
};

export default LeaveBalanceLog;
