/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import {
  ClipboardIcon,
  OfficeBuildingIcon,
  CalendarIcon,
  ClockIcon,
  UserGroupIcon,
  UserIcon,
  DocumentTextIcon,
  GlobeIcon,
  ClipboardCheckIcon,
  CalculatorIcon,
  CreditCardIcon,
} from '@heroicons/react/outline';
import classNames from '../../../utils/classNames';
import { Link } from 'react-router-dom';

const companyContractsItems = [
  { name: 'Purchase Orders', href: '/admin-panel/contracts/purchase-orders', icon: ClipboardIcon },
  { name: 'Sales Orders', href: '/admin-panel/contracts/sales-orders', icon: ClipboardIcon },
  { name: 'Framework Contracts', href: '/admin-panel/contracts/framework-contracts', icon: DocumentTextIcon },
  { name: 'Staff Contracts', href: '/admin-panel/contracts/staff-contracts', icon: DocumentTextIcon },
];

const administrativeItems = [
  { name: 'Clients', href: '/admin-panel/admin/clients', icon: OfficeBuildingIcon },
  { name: 'Points of Contact', href: '/admin-panel/admin/points-of-contact', icon: UserIcon },
  { name: 'Countries', href: '/admin-panel/admin/countries', icon: GlobeIcon },
  { name: 'Holidays', href: '/admin-panel/admin/holidays', icon: CalendarIcon },
  { name: 'Bank Accounts', href: '/admin-panel/admin/bank-accounts', icon: CreditCardIcon },
];

const hrItems = [
  { name: 'User Directory', href: '/admin-panel/hr/user-directory', icon: UserGroupIcon },
  { name: 'Timesheets', href: '/admin-panel/hr/timesheets', icon: CalendarIcon },
  { name: 'Leave Requests', href: '/admin-panel/hr/leave-requests', icon: ClockIcon },
  { name: 'Documents', href: '/admin-panel/hr/documents', icon: DocumentTextIcon },
  { name: 'Task Manager', href: '/admin-panel/hr/task-manager', icon: ClipboardCheckIcon },
];

const financeItems = [
  { name: 'Invoicing Lines', href: '/admin-panel/finance/invoicing-lines', icon: DocumentTextIcon },
  { name: 'Accounts Receivable', href: '/admin-panel/finance/accounts-receivable', icon: DocumentTextIcon },
  { name: 'Accounts Payable', href: '/admin-panel/finance/accounts-payable', icon: DocumentTextIcon },
  { name: 'Credit Notes', href: '/admin-panel/finance/credit-notes', icon: DocumentTextIcon },
];

export default function FlyoutMenu() {
  return (
    <Popover className="z-100">
      {({ open }) => (
        <>
          <Popover.Button
            className={classNames(
              open && 'bg-thaleria-blue-400',
              'inline-flex items-center px-3 py-2 rounded-md text-sm font-medium text-white hover:bg-thaleria-blue-400 hover:bg-opacity-75',
            )}
          >
            <span>Admin Panel</span>
            <ChevronDownIcon className={classNames('text-white ml-2 h-5 w-5')} aria-hidden="true" />
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 -translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-1"
          >
            <Popover.Panel className="absolute z-30 inset-x-0 transform shadow-lg top-16 bg-white">
              <nav className="relative max-w-7xl mx-auto bg-white py-12 flex divide-x  ">
                <div className="px-4">
                  <p className="leading-10 text-sm font-medium tracking-wide text-gray-500 uppercase">Human Resources</p>
                  <ul className="grid grid-rows-5 grid-flow-col">
                    {hrItems.map(item => (
                      <li>
                        <Link
                          to={item.href}
                          className="w-60 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100 transition ease-in-out duration-150"
                        >
                          {item.icon && <item.icon className=" h-6 w-6 text-gray-400" aria-hidden="true" />}
                          <span className="ml-4">{item.name}</span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="px-4">
                  <p className="leading-10 text-sm font-medium tracking-wide text-gray-500 uppercase">Administrative</p>
                  <ul className="grid grid-rows-5 grid-flow-col">
                    {administrativeItems.map(item => (
                      <li>
                        <Link
                          to={item.href}
                          className="w-60 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100 transition ease-in-out duration-150"
                        >
                          {item.icon && <item.icon className=" h-6 w-6 text-gray-400" aria-hidden="true" />}
                          <span className="ml-4">{item.name}</span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="px-4">
                  <p className="leading-10 text-sm font-medium tracking-wide text-gray-500 uppercase">Company Contracts</p>
                  <ul className="grid grid-rows-5 grid-flow-col">
                    {companyContractsItems.map(item => (
                      <li>
                        <Link
                          to={item.href}
                          className="w-60 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100 transition ease-in-out duration-150"
                        >
                          {item.icon && <item.icon className=" h-6 w-6 text-gray-400" aria-hidden="true" />}
                          <span className="ml-4">{item.name}</span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="px-4">
                  <p className="leading-10 text-sm font-medium tracking-wide text-gray-500 uppercase">Finance</p>
                  <ul className="grid grid-rows-5 grid-flow-col">
                    {financeItems.map(item => (
                      <li>
                        <Link
                          to={item.href}
                          className="w-60 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100 transition ease-in-out duration-150"
                        >
                          {item.icon && <item.icon className=" h-6 w-6 text-gray-400" aria-hidden="true" />}
                          <span className="ml-4">{item.name}</span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </nav>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
