import React, { useEffect, useState } from 'react';
import InfoTooltip from '../../components/Tooltips/InfoTooltip';
import useApi from '../../hooks/useApi';

function LeaveBalance({ userId, onlyTotal }) {
  const [days, setDays] = useState({
    remainingRecuperationDays: 0,
    remainingPaidLeave: 0,
    daysToBeApproved: 0,
  });

  const {
    leaveRequests: { getAvailableLeave, getUserAvailableLeave },
  } = useApi();

  useEffect(() => {
    if (userId) {
      getUserAvailableLeave(userId).then(data => {
        setDays({
          ...days,
          remainingRecuperationDays: data?.recuperation_days_balance || 0,
          remainingPaidLeave: data?.leave_days_balance || 0,
        });
      });
    } else {
      getAvailableLeave().then(data => {
        setDays({
          ...days,
          remainingRecuperationDays: data?.recuperation_days_balance || 0,
          remainingPaidLeave: data?.leave_days_balance || 0,
          daysToBeApproved: data.leave_days_to_be_approved,
        });
      });
    }
  }, []);

  const stats = [
    { name: 'Leave days', stat: days?.remainingPaidLeave },
    { name: 'Recuperation days', stat: days?.remainingRecuperationDays },
    {
      name: 'Total available days',
      stat: days?.remainingPaidLeave + days?.remainingRecuperationDays - days?.daysToBeApproved,
    },
  ];

  const tooltipMsg = React.useMemo(() => {
    return days.daysToBeApproved
      ? `You have ${days.remainingPaidLeave + days.remainingRecuperationDays} available days, of which ${
          days.daysToBeApproved
        } have been requested and are still being processed`
      : null;
  }, [days]);

  if (onlyTotal)
    return (
      <div key={stats[2].name} className="px-4 py-5 bg-white shadow rounded-lg sm:p-6">
        <div className="flex">
          <dt className="text-sm font-medium text-gray-500 truncate flex">
            {stats.find(el => el.name == 'Total available days').name}
          </dt>
          {tooltipMsg && <InfoTooltip msg={tooltipMsg} />}
        </div>
        <dd className="mt-1 text-3xl font-semibold text-gray-900">{stats.find(el => el.name == 'Total available days').stat}</dd>
      </div>
    );

  return (
    <div>
      {/* <span className="text-lg leading-6 font-medium text-gray-900">Current balance</span> */}
      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
        {stats.map(item => (
          <div key={item.name} className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <dt className="text-sm font-medium text-gray-500 truncate">{item.name}</dt>
            <dd className="mt-1 text-3xl font-semibold text-gray-900">{item.stat}</dd>
          </div>
        ))}
      </dl>
    </div>
  );
}

export default LeaveBalance;
