import React from 'react';

import TwoColumnForm from '../../../components/Layouts/TwoColumnForm';
import TextArea from '../../../components/Inputs/TextArea';
import InputSimple from '../../../components/Inputs/InputSimple';

function ContactInfo({ onChange, newUser, register, errors }) {
  return (
    <TwoColumnForm saveButton label="Contact Info" description="Add user's contact information.">
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-4 sm:col-span-3">
          <InputSimple
            type="email"
            label="Email*"
            dataLabel="email"
            register={register('email')}
            error={errors['email']?.message}
          />
        </div>
        <div className="col-span-3">
          <InputSimple
            type="tel"
            label="Phone"
            onChange={(e, key) => onChange(key, e.target.value)}
            dataLabel="phone_nb"
            value={newUser.data.phone_nb}
          />
        </div>
        <div className="col-span-3">
          <InputSimple
            type="tel"
            label="Secondary phone"
            onChange={(e, key) => onChange(key, e.target.value)}
            dataLabel="secondary_phone_nb"
            value={newUser.data.secondary_phone_nb}
          />
        </div>

        <div className="col-span-6">
          <TextArea
            label="Full Address"
            placeholder="Avenue des Arts, 56, 1000, Brussels"
            rows={2}
            value={newUser.data.address}
            dataLabel="address"
            onChange={(e, key) => onChange(key, e.target.value)}
          />
        </div>
      </div>
    </TwoColumnForm>
  );
}

export default ContactInfo;
