import { useState } from 'react';
import { RefreshIcon } from '@heroicons/react/outline';

const RefreshButton = ({ onClick, disabled }) => {
  const [animate, setAnimate] = useState(false);
  const handleClick = () => {
    if (disabled) return;
    setAnimate(true);
    setTimeout(() => {
      setAnimate(false);
    }, [500]);
    onClick();
  };

  return (
    <div
      onClick={handleClick}
      className={`p-2 border bg-white border-gray-300 ml-2 rounded-md ${
        disabled ? 'bg-gray-200 cursor-not-allowed' : 'cursor-pointer hover:bg-gray-100'
      }`}
    >
      <RefreshIcon className={`${animate && 'animate-spin'} h-5 w-5 text-gray-700`} />
    </div>
  );
};

export default RefreshButton;
