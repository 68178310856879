import React, { useState, useEffect } from 'react';
import Attachments from '../../../containers/Attachments/Attachments';
import Chat from '../../../containers/Chat/Chat';
import PageWrapperV2 from '../../../containers/App/PageWrapper';
import RequestActions from '../../../containers/LeaveRequests/RequestActions';
import { useParams } from 'react-router-dom';
import { downloadBase64File } from '../../../helpers';
import { LeaveRequestStatus } from '../../../helpers/enum/leaveRequest';
import MyLRDetails from '../../../containers/LeaveRequests/MyLRDetails';
import useApi from '../../../hooks/useApi';
import ActionRequired from '../../../containers/LeaveRequests/ActionRequired';
import SimpleAlert from '../../../components/Modals/SimpleAlert';
import PreviewBox from '../../../containers/PDFViewer/PreviewBox';
import { useSelector } from 'react-redux';

const LeaveRequestDetails = () => {
  const {
    files: { getFilesOnFolderForLeaveRequests, downloadLeaveRequestFile, deleteLeaveRequestFile },
    leaveRequests: { getMyLeaveRequest, uploadFile, createUserComment },
  } = useApi();

  const currentUser = useSelector(state => state.auth.currentUser);

  const { id } = useParams();
  const [documents, setDocuments] = useState([]);
  const [requestStatus, setRequestStatus] = useState(null);
  const [leaveRequestUUID, setLeaveRequestUUID] = useState();
  const [refreshDetails, setRefreshDetails] = useState(0);
  const [downloading, setDownloading] = useState(false);
  const [loadingFolder, setLoadingFolder] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showDuplicateAlert, setShowDuplicateAlert] = useState(false);
  const [preparingPreview, setPreparingPreview] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    getMyLeaveRequest(id).then(data => {
      setRequestStatus(data.status);
      setLeaveRequestUUID(data.uuid);
      getFilesOnFolderForLeaveRequests(`leave-request/${data.uuid}`)
        .then(res => {
          const responseDate = res.data;
          setDocuments(responseDate.map(res => ({ ...res, id: res.key, resource: res.key })));
          setLoadingFolder(false);
        })
        .catch(() => {
          setLoadingFolder(false);
        });
    });
  }, []);

  const prepareChatData = res => {
    const messages = res.data.map(message => {
      const momentOfPublish = new Date(message.created_at);
      return {
        id: message.id,
        ownerName: message.user.first_names,
        ownerImg: message.user.profilepic_url,
        ownerId: message.user_id,
        text: message.comment,
        time: momentOfPublish.toLocaleString('en-us'),
      };
    });

    return messages;
  };

  const [message, setMessage] = useState('');

  const onMessageChange = e => setMessage(e.target.value);

  const sendMessageHandler = () => createUserComment(id, message).then(res => setMessage(''));

  const uploadFileHandler = async fileInfo => {
    if (documents.length) {
      if (documents.some(doc => doc.name == fileInfo.name)) {
        setUploading(false);
        return setShowDuplicateAlert(fileInfo.name);
      }
    }
    uploadFile(fileInfo, leaveRequestUUID)
      .then(response => {
        if (!documents.find(d => d.id === response.key))
          setDocuments(preValue => [...preValue, { ...response, id: response.key, resource: response.key }]);
        setUploading(false);
      })
      .catch(() => {
        setUploading(false);
      });
  };

  const downloadFileHandler = id => {
    const file = documents.find(document => document.id == id);
    const fileName = file.resource.split('/')[file.resource.split('/').length - 1];
    downloadLeaveRequestFile(file.resource)
      .then(response => {
        downloadBase64File(response.data, fileName);
        setDownloading(false);
      })
      .catch(() => {
        setDownloading(false);
      });
  };

  const previewHandler = id => {
    const file = documents.find(document => document.id == id);
    downloadLeaveRequestFile(file.resource)
      .then(response => {
        setPreparingPreview(false);
        setShowPreview({
          url: response.data,
          show: true,
          fileType: file.resource.split('.')[file.resource.split('.').length - 1],
          title: file.resource.split('.')[file.resource.split('.').length - 2].replace('/', ''),
        });
      })
      .catch(() => {
        setPreparingPreview(false);
      });
  };

  const RemoveFileHandler = id => {
    deleteLeaveRequestFile(id)
      .then(response => {
        setDocuments(preDocuments => [...preDocuments.filter(document => document.id != id)]);
        setDeleting(false);
      })
      .catch(() => {
        setDeleting(false);
      });
  };

  const cannotPerformAttachments =
    requestStatus === LeaveRequestStatus.approved ||
    requestStatus === LeaveRequestStatus.submitted ||
    requestStatus === LeaveRequestStatus.deleted ||
    requestStatus === LeaveRequestStatus.denied ||
    requestStatus === null;

  const handleRefreshStatus = status => {
    setRequestStatus(status);
    setRefreshDetails(refreshDetails + 1);
  };

  const pages = [
    { name: 'Leave Requests', href: '/leave-request', current: false },
    { name: `Request ID ${id}`, href: '', current: true },
  ];

  return (
    <PageWrapperV2 pages={pages}>
      <SimpleAlert
        errorTitle={'Unable to upload file'}
        errorMsg={`${showDuplicateAlert && showDuplicateAlert} file name already exists on this leave request.`}
        onAcceptClick={() => setShowDuplicateAlert(false)}
        onAcceptText={'Close'}
        show={showDuplicateAlert != false}
        hide={() => setShowDuplicateAlert(false)}
      />
      <PreviewBox
        filePath={showPreview.url}
        fileType={showPreview.fileType}
        showPreview={showPreview.show}
        handleHide={() => setShowPreview(false)}
        title={showPreview.title}
      />
      {requestStatus == LeaveRequestStatus.actionRequired && <ActionRequired />}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <MyLRDetails id={id} refresh={refreshDetails} />
        <div className="grid col-span-1 gap-8">
          <Attachments
            uploadHandler={uploadFileHandler}
            maxFileSizeInMb={10}
            downloadHandler={downloadFileHandler}
            removeHandler={RemoveFileHandler}
            documents={documents}
            enableAdd={!cannotPerformAttachments}
            enableRemove={!cannotPerformAttachments}
            deleting={deleting}
            setDeleting={setDeleting}
            downloading={downloading}
            setDownloading={setDownloading}
            loadingFolder={loadingFolder}
            uploading={uploading}
            setUploading={setUploading}
            preparingPreview={preparingPreview}
            setPreparingPreview={setPreparingPreview}
            previewHandler={previewHandler}
          />
          <Chat
            url={`/leave-request-comments/my/${id}`}
            prepare={prepareChatData}
            messageOnChange={onMessageChange}
            sendMessage={sendMessageHandler}
            messageText={message}
          />
        </div>
        <div className="lg:col-span-2">
          <RequestActions status={requestStatus} id={id} setLeaveStatus={handleRefreshStatus} />
        </div>
      </div>
    </PageWrapperV2>
  );
};

export default LeaveRequestDetails;
