import React from 'react';
import AllocationsTable from './AllocationsTable';
import Modal from '../../components/Modals/Modal';

export default ({ onClose, staffOrders, selectedDays, allocations, show, userInvoices }) => {
  const title = selectedDays.length > 1 ? 'Manage allocations' : 'Manage allocation';

  return (
    <Modal show={show} hide={onClose} title={title} size="lg">
      <AllocationsTable
        staffOrders={staffOrders}
        selectedDays={selectedDays}
        allocations={allocations}
        onClose={onClose}
        userInvoices={userInvoices}
      />
    </Modal>
  );
};
