import React from 'react';
import PropTypes from 'prop-types';
import ButtonPrimary from './ButtonPrimary';
import PlusMedium from '../Icons/PlusMediumIcon';

const ButtonAdd = props => (
  <ButtonPrimary
    onClick={props.onClick}
    size={props.size}
    text={props.text}
    style={props.style}
    icon={
      //TODO: we can write specific style for PlusMedium base on props.size
      <PlusMedium style={`${props.plusStyle} -ml-1 mr-2 h-5 w-5 text-white`} />
    }
  />
);

export default ButtonAdd;

ButtonAdd.prototype = {
  text: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl']).isRequired,
  onClick: PropTypes.func,
  style: PropTypes.string,
  plusStyle: PropTypes.string,
};
