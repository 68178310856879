import {
  AUTHENTICATE_USER,
  LOGOUT_USER,
  AUTHENTICATE_USER_FAILED,
  AUTHENTICATE_USER_PENDING,
  DELETE_MY_PROFILE_PICTURE,
  UPDATE_PROFILE_PICTURE,
  EDIT_MYSELF,
} from './types';

const initialState = {
  isAuthenticated: false,
  isLoading: false,
  currentUser: {
    CurrentLeaveDays: {},
  },
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case AUTHENTICATE_USER:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        currentUser: action.payload.user,
        token: action.payload.token,
      };
    case AUTHENTICATE_USER_PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case AUTHENTICATE_USER_FAILED:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        currentUser: 'loginFailed',
      };
    case LOGOUT_USER:
      return {
        ...state,
        isAuthenticated: false,
        currentUser: action.payload,
      };
    case UPDATE_PROFILE_PICTURE:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          profilepic_url: action.payload.profilepic_url,
        },
      };
    case EDIT_MYSELF:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...action.payload,
        },
      };
    case DELETE_MY_PROFILE_PICTURE:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          profilepic_url: null,
        },
      };
    default:
      return state;
  }
}
