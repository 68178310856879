import React, { useState, useMemo, useEffect } from 'react';
import classNames from '../../utils/classNames';
import { useLocation, useHistory } from 'react-router-dom';
import Loader from '../../components/Loading/Loader';
import { Link } from 'react-router-dom';
import ReactSelect from '../../components/Inputs/ReactSelect';
import NotificationItemForTable from '../../components/UserNotifications/NotificationItemForTable';
import { BsCheck2All } from 'react-icons/bs';
import useApi from '../../hooks/useApi';
import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon } from '@heroicons/react/solid';
import { useQuery } from '../../hooks/useQuery';

const NotificationsTable = () => {
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [notifications, setNotifications] = useState([]);

  const {
    notifications: { getAllMyNotifications, updateNotification, allNotificationsToRead },
  } = useApi();

  useEffect(() => {
    getAllMyNotifications(page).then(res => {
      setNotifications(res.rows);
      setPageCount(res.totalPages);
    });
  }, []);

  const setAllToRead = () => {
    allNotificationsToRead().then(res => {
      const newNotifications = notifications.map(n => {
        n.read = true;
        return n;
      });
      setNotifications(newNotifications);
    });
  };

  const fetchMoreNotifications = () => {
    setPage(page + 1);
    getAllMyNotifications(page + 1).then(res => {
      setNotifications(notifications.concat(res.rows));
    });
  };

  const setNotificationAsRead = id => {
    updateNotification(id, { read: true }).then(res => {
      const newNotifications = notifications.map(n => {
        if (n.id == id) n.read = true;
        return n;
      });
      setNotifications(newNotifications);
    });
  };

  return (
    <div
      className="shadow max-w-7xl mx-auto py-4 bg-white rounded-lg sm:px-6 lg:px-8 flex flex-col justify-between w-full"
      style={{ height: '80vh' }}
    >
      <Loader isLoading={isLoading}>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200 flex justify-between w-full items-center">
            <h3>Notifications</h3>
            <div>
              <p
                className="text-sm flex items-center text-thaleria-orange-700 cursor-pointer font-semibold hover:text-thaleria-orange-500 self-center"
                onClick={() => setAllToRead()}
              >
                <BsCheck2All className="w-5 h-5 mr-1" />
                Mark all as read
              </p>
            </div>
          </div>
        </div>
        <div style={{ height: '100%' }} className="overflow-hidden overflow-y-auto ">
          <ul role="list" className="mt-5 border-t border-gray-200 divide-y divide-gray-200 sm:mt-0 sm:border-t-0 divide-y-1 p-4">
            {notifications.map((item, index) => (
              <NotificationItemForTable index={index} item={item} setNotificationAsRead={setNotificationAsRead} />
            ))}
          </ul>
        </div>
        {page < pageCount && (
          <nav className="px-4 sm:px-0 border-t border-gray-200 flex items-center justify-center pt-4" aria-label="Pagination">
            <div
              onClick={() => fetchMoreNotifications()}
              className="rounded-full border-2 border-thaleria-orange-600 text-thaleria-orange-600 hover:border-thaleria-orange-400 hover:text-thaleria-orange-400 cursor-pointer py-1 px-4"
            >
              View more
            </div>
          </nav>
        )}
      </Loader>
    </div>
  );
};

export default NotificationsTable;
