import React, { useEffect, useState } from 'react';
import ButtonRound from '../../components/Buttons/ButtonRound';
import DirectoryUserCard from '../../components/Cards/DirectoryUserCard';
import useWindowSize from '../../hooks/useWindowSize';
import { SearchIcon } from '@heroicons/react/outline';
function UserList({
  users,
  onUserClick,
  onAddButtonClick,
  title,
  activeUser,
  filterUsers,
  numberOfUsers,
  userMode,
  setUserMode,
}) {
  const [userList, setUserList] = useState([]);
  const [searchValue, setSearchValue] = useState();

  const size = useWindowSize();

  let timeoutId;
  const timeout = () => {
    timeoutId = setTimeout(() => filterUsers(searchValue), 400);
    return timeoutId;
  };
  const cancelTimeout = () => clearTimeout(timeoutId);

  useEffect(() => {
    document.getElementById('search')?.addEventListener('input', e => {
      setSearchValue(e.target.value);
    });

    return () => {
      document.getElementById('search')?.removeEventListener('input', e => {
        setSearchValue(e.value);
      });
    };
  }, []);

  useEffect(() => {
    cancelTimeout();
    timeout();
    return () => cancelTimeout();
  }, [searchValue]);

  useEffect(() => {
    if (users.length) {
      //sort users
      let sortedUsers = {};
      users.forEach(user => {
        if (!user.full_name) return;
        let upperCaseFirstLetter = user.full_name[0].toUpperCase();
        sortedUsers[upperCaseFirstLetter] = [...(sortedUsers[upperCaseFirstLetter] || []), user];
      });
      //prepare final sorted list
      let formattedList = [];
      Object.keys(sortedUsers).forEach(key => {
        formattedList.push(
          <>
            <div className="sticky border-t border-b border-gray-200 bg-gray-50 px-6 py-1 text-sm font-medium text-gray-500">
              <span className="text-lg">{key}</span>
            </div>
            {sortedUsers[key].map(user => {
              const selectedUser = user.id == activeUser?.id;
              return (
                <DirectoryUserCard
                  key={user.id}
                  active={user.active}
                  selected={selectedUser}
                  onClick={() => onUserClick(user)}
                  user={user}
                />
              );
            })}
          </>,
        );
      });

      //pass list to state
      setUserList(formattedList);
    } else {
      setUserList([]);
    }
  }, [users, activeUser]);

  if (activeUser && size.width < size.tailwind.xl) return '';

  const leftButtonClick = () => {
    setUserMode(true);
  };

  const rightButtonClick = () => {
    setUserMode(false);
  };

  const footerHeight = document.getElementById('footer')?.offsetHeight;
  const headerHeight = document.getElementById('header')?.offsetHeight;
  const titleHeight = document.getElementById('title')?.offsetHeight;
  const tabsHeight = document.getElementById('tabs')?.offsetHeight;
  const searchBarHeight = document.getElementById('search-bar')?.offsetHeight;
  let _100vh = Math.round(window.innerHeight);
  let minWindowHeight = 850;
  let fullScreenHeight = _100vh > minWindowHeight ? _100vh : minWindowHeight;

  const STYLES = {
    height: `calc(${fullScreenHeight}px - ${footerHeight + headerHeight + titleHeight + tabsHeight + searchBarHeight}px)`,
  };

  return (
    <aside className="w-full xl:w-96 overflow-scroll scrollbar-hidden h-full  border-r border-gray-200">
      <div className="flex justify-between items-center px-6 py-2" id="title">
        <div>
          <h2 className="text-lg font-medium text-gray-900">{title}</h2>
          <p className="mt-1 text-sm text-gray-600">Search directory of {numberOfUsers} staff members</p>
        </div>
        {onAddButtonClick && (
          <div>
            <ButtonRound size="s" onClick={onAddButtonClick} />
          </div>
        )}
      </div>
      <div className="px-6" id="search-bar">
        <div className="relative rounded-md shadow-sm">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            type="search"
            name="search"
            id="search"
            className="block w-full rounded-md border border-gray-300 pl-10 focus:border-thaleria-orange-600 focus:ring-thaleria-orange-600 sm:text-sm focus-none"
            placeholder="Search"
          />
        </div>
      </div>
      <div className="pt-4" id="tabs">
        <ul className="relative z-0 rounded-lg shadow flex cursor-pointer">
          <li
            onClick={leftButtonClick}
            className={`group ${
              userMode ? 'text-gray-800' : 'text-gray-500 hover:text-gray-700'
            } relative min-w-0 flex-1 overflow-hidden bg-white py-2 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10`}
          >
            <span>Users</span>
            <span
              className={`${userMode ? 'bg-thaleria-orange-700' : 'bg-transparent'} absolute inset-x-0 bottom-0 h-0.5`}
            ></span>
          </li>
          <li
            onClick={rightButtonClick}
            className={`group ${
              !userMode ? 'text-gray-800' : 'text-gray-500 hover:text-gray-700'
            } relative min-w-0 flex-1 overflow-hidden bg-white py-2 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10`}
          >
            <span>Deleted users</span>
            <span
              className={`${!userMode ? 'bg-thaleria-orange-700' : 'bg-transparent'} absolute inset-x-0 bottom-0 h-0.5`}
            ></span>
          </li>
        </ul>
      </div>
      {/* <!-- Directory list --> */}
      <nav className="flex-1 min-h-0 relative overflow-y-auto scrollbar-hidden" style={STYLES} aria-label="Directory">
        {/* TODO: need to add key when using map */}
        {userList.map(e => e)}
      </nav>
    </aside>
  );
}

export default UserList;
