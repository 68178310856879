import React, { useEffect, useState, useMemo } from 'react';
import Card from '../../components/Cards/Card';
import Toggle from '../../components/Toggles/Toggle';
import { LeaveRequestType } from '../../helpers/enum/leaveRequest';
import InputDate from '../../components/Inputs/InputDate/InputDate';
import { Controller } from 'react-hook-form';
import Error from '../../components/Error/Error';
import calculateBusinessDays from '../../helpers/caclulateBusinessDays';
import { staffContractType } from '../../helpers/enum/staffContractType';
import TextArea from '../../components/Inputs/TextArea';
import { InformationCircleIcon } from '@heroicons/react/solid';
import useApi from '../../hooks/useApi';
import ReactSelect from '../../components/Inputs/ReactSelect';
import { LeaveRequestStatus, LeaveRequestStatusLabels } from '../../helpers/enum/leaveRequest';

function AdminCreateLRForm({ setData, resetData, data, register, errors, control, users, createThroughTimesheetCalendar }) {
  const {
    holidays: { getHolidaysForLR },
  } = useApi();

  const staffContract = useMemo(() => {
    return data.user?.StaffContracts[0] || null;
  }, [data.user]);

  const statusOptions = useMemo(() => {
    return [
      {
        label: LeaveRequestStatusLabels[LeaveRequestStatus.actionRequired],
        value: LeaveRequestStatus.actionRequired,
      },
      {
        label: LeaveRequestStatusLabels[LeaveRequestStatus.approved],
        value: LeaveRequestStatus.approved,
      },
      {
        label: LeaveRequestStatusLabels[LeaveRequestStatus.submitted],
        value: LeaveRequestStatus.submitted,
      },
    ];
  }, []);

  const leaveRequestOptions = useMemo(() => {
    if (
      staffContract?.contract_type_id == staffContractType.COMPANY ||
      staffContract?.contract_type_id == staffContractType.FREELANCER
    ) {
      return [
        { value: LeaveRequestType.unPaidLeave, label: 'Unpaid Leave' },
        { value: LeaveRequestType.trainingLeave, label: 'Training Leave' },
      ];
    } else if (staffContract?.contract_type_id == staffContractType.EMPLOYEE) {
      return [
        { value: LeaveRequestType.paidLeave, label: 'Paid Leave' },
        { value: LeaveRequestType.unPaidLeave, label: 'Unpaid Leave' },
        { value: LeaveRequestType.medicalLeave, label: 'Medical Leave' },
        { value: LeaveRequestType.trainingLeave, label: 'Training Leave' },
      ];
    } else {
      //if no user selected, no options
      return [];
    }
  }, [data.user]);

  useEffect(() => {
    resetData();
  }, [data.user?.id]);

  const userOptions = useMemo(() => {
    if (users.length) {
      return users.map(el => {
        return {
          ...el,
          value: el.id,
          label: el.full_name,
        };
      });
    } else return [];
  }, [users]);

  const [tooltipStatus, setTooltipStatus] = useState(false);

  const showEndHalfDay = () => {
    if (data?.startDate && data?.endDate) return new Date(data?.startDate).getTime() !== new Date(data?.endDate).getTime();
    return true;
  };

  useEffect(() => {
    if (data?.startDate && data?.endDate && data?.requestType == LeaveRequestType.paidLeave) {
      calculateBusinessDays(
        getHolidaysForLR,
        data?.startDate,
        data?.endDate,
        data?.startHalfDay,
        data?.endHalfDay,
        data?.user?.id,
      ).then(res => {
        return setData({
          deductedLeaveDays: res.deductedDays,
          holidays: res.holidays,
          weekendDates: res.weekends,
        });
      });
    } else {
      if (data.deductedLeaveDays)
        return setData({
          deductedLeaveDays: 0,
          holidays: [],
          weekendDates: [],
        });
    }
  }, [data?.startDate, data?.endDate, data?.startHalfDay, data?.endHalfDay]);

  return (
    <Card
      title={createThroughTimesheetCalendar ? '' : 'Request details'}
      noShadow={createThroughTimesheetCalendar}
      noYPadding={createThroughTimesheetCalendar}
      noXPadding={createThroughTimesheetCalendar}
    >
      <dl className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {!createThroughTimesheetCalendar && (
          <div className="col-span-1 md:col-span-2">
            <div className="flex">
              <dt className="text-sm font-medium text-gray-500 mr-2">User</dt>
              {errors?.['user']?.message && <Error message={errors?.['user']?.message} />}
            </div>
            <dd className="mt-1 text-sm text-gray-900 w-full sm:w-3/6">
              <Controller
                control={control}
                name={'user'}
                render={({ field: { onChange, value, ref } }) => (
                  <ReactSelect
                    options={userOptions}
                    selectedOptionsIds={data.user !== null ? [data.user.id] : []}
                    onChange={e => setData({ user: e })}
                    isSearchable
                  />
                )}
              />
            </dd>
          </div>
        )}
        <div className="col-span-1 md:col-span-2">
          <div className="flex">
            <dt className="text-sm font-medium text-gray-500 mr-2">Status</dt>
            {errors?.['status']?.message && <Error message={errors?.['status']?.message} />}
          </div>
          <dd className="mt-1 text-sm text-gray-900 w-full sm:w-3/6">
            <Controller
              control={control}
              name={'status'}
              render={({ field: { onChange, value, ref } }) => (
                <ReactSelect
                  options={statusOptions}
                  selectedOptionsIds={data.status !== null ? [+data.status] : []}
                  onChange={e => setData({ status: e.value })}
                />
              )}
            />
          </dd>
        </div>
        <div className="col-span-1 md:col-span-2">
          <div className="flex">
            <dt className="text-sm font-medium text-gray-500 mr-2">Leave type</dt>
            {errors?.['requestType']?.message && <Error message={errors?.['requestType']?.message} />}
          </div>
          <dd className="mt-1 text-sm text-gray-900 w-full sm:w-3/6">
            <Controller
              control={control}
              name={'requestType'}
              render={({ field: { onChange, value, ref } }) => (
                <ReactSelect
                  options={leaveRequestOptions}
                  selectedOptionsIds={data.requestType !== null ? [+data?.requestType] : []}
                  onChange={e => setData({ requestType: e.value })}
                />
              )}
            />
          </dd>
        </div>
        <div className="col-span-1">
          <div className="flex">
            <dt className="text-sm font-medium text-gray-500 mr-2">Start date</dt>
            {errors?.['startDate']?.message && <Error message={errors?.['startDate']?.message} />}
          </div>
          <dd className="mt-1 text-sm text-gray-900">
            <Controller
              control={control}
              name={'startDate'}
              render={({ field: { onChange, value, ref } }) => (
                <InputDate
                  inputRef={ref}
                  className="w-full"
                  onChange={e => setData({ startDate: e })}
                  selected={data?.startDate !== null ? new Date(data?.startDate) : undefined}
                  disableWeekends={data?.requestType === LeaveRequestType.trainingLeave ? false : true}
                  minDate={new Date(staffContract?.contract_start)}
                  maxDate={staffContract?.contract_end ? new Date(staffContract?.contract_end) : undefined}
                />
              )}
            />
          </dd>
        </div>
        <div className="col-span-1">
          <dt className="text-sm font-medium text-gray-500">Half start date</dt>
          <dd className="mt-1 text-sm text-gray-900">
            <Toggle enable={data?.startHalfDay} onChange={() => setData({ startHalfDay: !data?.startHalfDay })} />
          </dd>
        </div>
        <div className="col-span-1">
          <div className="flex">
            <dt className="text-sm font-medium text-gray-500 mr-2">End date (included)</dt>
            {errors?.['endDate']?.message && <Error message={errors?.['endDate']?.message} />}
          </div>
          <dd className="mt-1 text-sm text-gray-900">
            <Controller
              control={control}
              name={'endDate'}
              render={({ field: { onChange, value, ref } }) => (
                <InputDate
                  inputRef={ref}
                  className="w-full"
                  register={register('endDate')}
                  onChange={e => setData({ endDate: e })}
                  selected={data?.endDate !== null ? new Date(data?.endDate) : undefined}
                  disableWeekends={data?.requestType === LeaveRequestType.trainingLeave ? false : true}
                  minDate={new Date(staffContract?.contract_start)}
                  maxDate={staffContract?.contract_end ? new Date(staffContract?.contract_end) : undefined}
                />
              )}
            />
          </dd>
        </div>
        {showEndHalfDay() && (
          <div className="col-span-1">
            <dt className="text-sm font-medium text-gray-500">Half end date</dt>
            <dd className="mt-1 text-sm text-gray-900">
              <Toggle enable={data?.endHalfDay} onChange={() => setData({ endHalfDay: !data?.endHalfDay })} />
            </dd>
          </div>
        )}
        {data.requestType === LeaveRequestType.paidLeave && (
          <div className="col-span-1 md:col-span-2">
            <div className="flex items-center">
              <dt className="text-sm font-medium text-gray-500">Deducted leave days</dt>
              <div className="relative" onMouseEnter={() => setTooltipStatus(true)} onMouseLeave={() => setTooltipStatus(false)}>
                {(data.holidays?.length > 0 || data.weekendDates?.length > 0) && (
                  <div className="mr-2">
                    <InformationCircleIcon title={'abc'} fill="orange" className="ml-2 h-5 w-5 filled" />
                  </div>
                )}
                {tooltipStatus && (
                  <div
                    role="tooltip"
                    style={{ background: '#F3F4F6' }}
                    className={`z-50 -mt-20 w-64 absolute transition duration-150 ease-in-out left-0 ml-8 ${
                      !createThroughTimesheetCalendar && 'shadow-lg'
                    } bg-white p-4 rounded`}
                  >
                    {data.weekendDates?.length > 0 && (
                      <>
                        <p className="text-sm font-bold text-gray-800 pb-1">Weekends</p>
                        <p className="mb-2 text-xs leading-4 text-gray-600 pb-3">{data.weekendDates?.join('. ')}</p>
                      </>
                    )}
                    {data.holidays?.length > 0 && (
                      <>
                        <p className="text-sm font-bold text-gray-800 pb-1">Public holidays</p>
                        <p className="text-xs leading-4 text-gray-600 pb-3">{data.holidays?.join('. ')}</p>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
            <dd className="mt-1 text-sm text-gray-900">{!data.deductedLeaveDays ? '-' : data.deductedLeaveDays}</dd>
          </div>
        )}
        <div className="col-span-1 md:col-span-2">
          <div className="flex">
            <dt className="text-sm font-medium text-gray-500 mr-2">Message</dt>
            {errors?.description?.message && <Error message={errors?.description?.message} />}
          </div>
          <dd className="mt-1 text-sm text-gray-900 w-full">
            <TextArea
              rows={5}
              register={register('description')}
              placeholder="Enter a message (optional) ..."
              value={data.description}
              onChange={e => {
                setData({ description: e.target.value });
              }}
            />
          </dd>
        </div>
      </dl>
    </Card>
  );
}

export default AdminCreateLRForm;
