import { eachWeekendOfInterval, differenceInBusinessDays } from 'date-fns';
import isWeekend from 'date-fns/isWeekend';
import format from 'date-fns/format';
import dayjs from 'dayjs';

const calculateBusinessDays = async (getAllHolidays, startDate, endDate, startHalfDay, endHalfDay, userId) => {
  let _startDate = startDate;
  let _endDate = endDate;
  if (typeof startDate === 'object') {
    _startDate = format(startDate, 'yyyy-MM-dd');
  }
  if (typeof endDate === 'object') {
    _endDate = format(endDate, 'yyyy-MM-dd');
  }

  let deductedDays;
  let holidaysToDeduct = 0;
  let halfDays = 0;
  let incoherentInterval = new Date(_startDate).setHours(0, 0, 0, 0) > new Date(_endDate).setHours(0, 0, 0, 0);
  if (startHalfDay) halfDays += 0.5;
  if (endHalfDay) halfDays += 0.5;
  let dayBeforeStartDate = new Date(_startDate);
  dayBeforeStartDate.setDate(dayBeforeStartDate.getDate() - 1);
  deductedDays = Math.abs(differenceInBusinessDays(dayBeforeStartDate, new Date(_endDate)));
  let weekends = incoherentInterval
    ? []
    : eachWeekendOfInterval({ start: dayBeforeStartDate, end: new Date(_endDate) }).map(day => dayjs(day).format('DD/MM/YYYY'));
  const holidays = await getAllHolidays(_startDate, _endDate, userId).then(res => {
    if (res.data?.length) {
      res.data.forEach(holiday => {
        if (!isWeekend(new Date(holiday.date))) {
          holidaysToDeduct += 1;
        }
      });
    }
    return res.data.map(e => dayjs(e.date).format('DD/MM/YYYY')) || [];
  });

  //TODO: Need to return also weekend days and Holidays
  if (incoherentInterval)
    return {
      deductedDays: 0,
      weekends: [],
      holidays: [],
    };
  return {
    deductedDays: deductedDays - holidaysToDeduct - halfDays,
    weekends: weekends,
    holidays: holidays,
  };
};

export default calculateBusinessDays;
