/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useMemo, useState } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { LogoutIcon, MenuIcon, SupportIcon, UserIcon, XIcon, FingerPrintIcon } from '@heroicons/react/outline';
import classNames from '../../../utils/classNames';
import AdminPanel from './AdminPanel';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ACCOUNT_TYPE, AccountType } from '../../../helpers/enum/accounts';
import { logOutUser } from '../../../store/auth/actions';
import ProfilePicture from '../../../components/ProfilePicture/ProfilePicture';
import UserImagePlaceholder from '../../../components/UserImagePlaceholder/UserImagePlaceholder';
import MyThaleriaLogo from '../../../components/Icons/MyThaleriaLogo';
import UserNotifications from '../../UserNotifications/UserNotifications';
import useApi from '../../../hooks/useApi';
import { showSuccessNotification } from '../../../store/app/actions';
import SimpleAlert from '../../../components/Modals/SimpleAlert';
import SanitizeDataModal from '../../../components/Modals/SanitizeDataModal';
export default function Navbar({ constrainWidth }) {
  const [showSanitizeDataModal, setShowSanitizeDataModal] = useState(false);
  const [isSanitizing, setIsSanitizing] = useState(false);
  const [sanitizeTaskData, setSanitizeTaskData] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const currentLocation = location.pathname;
  const {
    system: { sanitizeData },
  } = useApi();

  const currentUser = useSelector(state => state.auth.currentUser);

  const shouldSeeAdminPanel = useMemo(
    () => ACCOUNT_TYPE.ROOT.id == currentUser.account_type_id || ACCOUNT_TYPE.ADMIN.id == currentUser.account_type_id,
    [],
  );

  const userLogOut = () => {
    dispatch(logOutUser());
  };

  const user = useMemo(() => {
    return {
      name: currentUser.full_name,
      email: currentUser.email,
      imageUrl: currentUser.profilepic_url,
    };
  }, [currentUser]);

  const navigation = [
    { name: 'Home', href: '/home', current: currentLocation.includes('/home') },
    {
      name: 'Leave Requests',
      href: '/leave-request',
      current: !currentLocation.includes('/admin-panel') && currentLocation.includes('/leave-request'),
    },
    {
      name: 'Timesheets',
      href: '/timesheets',
      current: !currentLocation.includes('/admin-panel') && currentLocation.includes('/timesheets'),
    },
    { name: 'Documents', href: '/my-documents', current: currentLocation.includes('/my-documents') },
    { name: 'Invoices', href: '/my-invoices', current: currentLocation.includes('/my-invoices') },
  ];

  const onSanitizeDataClick = () => {
    setIsSanitizing(true);
    sanitizeData({ sanitizeTaskData: sanitizeTaskData })
      .then(res => {
        setIsSanitizing(false);
        setShowSanitizeDataModal(false);
        dispatch(showSuccessNotification('Data sanitized.'));
      })
      .catch(err => {
        setIsSanitizing(false);
      });
  };

  const userNavigation = [
    { name: 'My Profile', href: '/my-profile', icon: UserIcon },
    { name: 'Sign out', onClick: userLogOut, icon: LogoutIcon },
    { name: 'Report a bug', href: '/report-bug', icon: SupportIcon },
  ];

  if (currentUser.account_type_id === AccountType.ADMIN && process.env.REACT_APP_ENV !== 'production')
    userNavigation.push({ name: 'Sanitize data', onClick: () => setShowSanitizeDataModal(true), icon: FingerPrintIcon });

  return (
    <>
      <SanitizeDataModal
        show={showSanitizeDataModal}
        hide={() => setShowSanitizeDataModal(false)}
        isLoading={isSanitizing}
        onAcceptClick={() => onSanitizeDataClick()}
        sanitizeTaskData={sanitizeTaskData}
        setSanitizeTaskData={setSanitizeTaskData}
      />
      <Disclosure as="nav" className="bg-thaleria-blue-600 relative">
        {({ open }) => (
          <>
            <div className={`${constrainWidth && 'max-w-7xl 2xl:px-0'} mx-auto px-4 sm:px-6 lg:px-8 `}>
              <div className="flex items-center justify-between h-16">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <MyThaleriaLogo white small />
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-10 flex items-baseline space-x-4">
                      {navigation.map(item => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className={classNames(
                            item.current
                              ? 'bg-thaleria-blue-400 text-white'
                              : 'text-white hover:bg-thaleria-blue-400 hover:bg-opacity-75',
                            'px-3 py-2 rounded-md text-sm font-medium',
                          )}
                          aria-current={item.current ? 'page' : undefined}
                        >
                          {item.name}
                        </Link>
                      ))}
                      {shouldSeeAdminPanel && <AdminPanel />}
                    </div>
                  </div>
                </div>
                <div className="hidden md:block">
                  <div className="ml-4 flex items-center md:ml-6">
                    <UserNotifications />
                    {/* Profile dropdown */}
                    <Menu as="div" className="ml-3 relative">
                      <div>
                        <Menu.Button className="max-w-xs bg-thaleria-blue-400 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-thaleria-blue-400 focus:ring-white">
                          <span className="sr-only">Open user menu</span>
                          <ProfilePicture
                            src={user.imageUrl}
                            className="h-10 w-10 rounded-full"
                            alt=""
                            defaultimage={<UserImagePlaceholder className="h-10 w-10 rounded-full" />}
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                          {userNavigation.map(item => (
                            <Menu.Item key={item.name}>
                              {({ active }) => {
                                if (item.href)
                                  return (
                                    <Link
                                      to={item.href}
                                      className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'flex  items-center px-4 py-2 text-sm text-gray-700',
                                      )}
                                    >
                                      {item.icon && <item.icon className="h-4 w-4 mr-2 text-gray-500" />}
                                      {item.name}
                                    </Link>
                                  );
                                if (item.onClick)
                                  return (
                                    <button
                                      onClick={item.onClick}
                                      className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'flex  items-center px-4 py-2 text-sm text-gray-700 w-full text-left',
                                      )}
                                    >
                                      {item.icon && <item.icon className="h-4 w-4 mr-2 text-gray-500" />}
                                      {item.name}
                                    </button>
                                  );
                                return alert('Please include either an onclick or an href in your user menu items');
                              }}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
                <div className="-mr-2 flex md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="bg-thaleria-orange-800 inline-flex items-center justify-center p-2 rounded-md text-white hover:text-white hover:bg-thaleria-orange-700 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-thaleria-orange-800 focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="md:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                {navigation.map(item => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current
                        ? 'bg-thaleria-blue-400 text-white'
                        : 'text-white hover:bg-thaleria-blue-400 hover:bg-opacity-75',
                      'block px-3 py-2 rounded-md text-base font-medium',
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
              <div className="pt-4 pb-3 border-t border-thaleria-blue-600">
                <div className="flex items-center px-5">
                  <div className="flex-shrink-0">
                    <ProfilePicture
                      src={user.imageUrl}
                      className="h-10 w-10 rounded-full"
                      alt=""
                      defaultimage={<UserImagePlaceholder className="h-10 w-10 rounded-full" />}
                    />
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium text-white">{user.name}</div>
                    <div className="text-sm font-medium text-thaleria-blue-200">{user.email}</div>
                  </div>
                  <button
                    type="button"
                    className="ml-auto bg-thaleria-orange-800 flex-shrink-0 p-1 rounded-full text-thaleria-orange-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-thaleria-blue-400 focus:ring-white"
                  >
                    <span className="sr-only">View notifications</span>
                    <UserNotifications />
                  </button>
                </div>
                <div className="mt-3 px-2 space-y-1">
                  {userNavigation.map(item => {
                    if (item.onClick)
                      return (
                        <button
                          onClick={item.onClick}
                          className="block px-3 py-2 rounded-md text-base font-medium text-white hover:bg-thaleria-blue-400 hover:bg-opacity-75"
                        >
                          {item.name}
                        </button>
                      );
                    if (item.href)
                      return (
                        <Link
                          key={item.name}
                          to={item.href}
                          className="block px-3 py-2 rounded-md text-base font-medium text-white hover:bg-thaleria-blue-400 hover:bg-opacity-75"
                          aria-current={item.current ? 'page' : undefined}
                        >
                          {item.name}
                        </Link>
                      );
                  })}
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
}
