import React, { useEffect, useState, useMemo } from 'react';
import { date } from '../../../../helpers/date';

import DateRangeColumnFilter from '../../../../components/TableItems/DateRangeColumnFilter';
//components
import Table from '../../../../containers/ServerSideTable';
import { useHistory } from 'react-router';

import SelectColumnFilter from '../../../../components/TableItems/SelectColumnFilter';
import { fwcTypeLabels } from '../../../../helpers/enum/fwcTypes';
import { ExternalLinkIcon, PencilIcon } from '@heroicons/react/outline';
import useApi from '../../../../hooks/useApi';
import { americanDate } from '../../../../helpers/date';
import axios from 'axios';
import AdminSidebar from '../../AdminSidebar';
import contractInvoicingTypes from '../../../../helpers/enum/contractInvoicingTypes';
import NumberRangeColumnFilter from '../../../../components/TableItems/NumberRangeColumnFilter';
import formatCurrency from '../../../../utils/formatCurrency';
import formatNumber from '../../../../utils/formatNumber';

function SalesOrders() {
  const {
    salesOrders: { getPaginatedSalesOrders, getFilterOptions },
  } = useApi();

  const [fetchingData, setFetchingData] = React.useState(true);
  const [filterOptions, setFilterOptions] = useState({});
  const history = useHistory();

  useEffect(() => {
    getFilterOptions().then(res => {
      setFilterOptions(res);
    });
  }, []);

  const fetchSalesOrders = async (page = 0, filters = [], sortByArray = [], globalFilter, downloadToCsv, source) => {
    //TODO: Addd filters
    const reference = filters.find(filter => filter.id === 'reference')?.value;
    const client = filters.find(filter => filter.id === 'client')?.value;
    const invoicingType = filters.find(filter => filter.id === 'invoicingType')?.value;
    let startDates = filters.find(filter => filter.id === 'start')?.value;
    let endDates = filters.find(filter => filter.id === 'end')?.value;
    const rate = filters.find(filter => filter.id === 'rate')?.value;
    const value = filters.find(filter => filter.id === 'value')?.value;
    const daysOrdered = filters.find(filter => filter.id === 'daysOrdered')?.value;
    const sortBy = sortByArray.length ? `${sortByArray[0].id.toString()},${sortByArray[0].desc.toString()}` : undefined;

    if (startDates !== undefined) {
      startDates = startDates.map(d => {
        if (d !== undefined) return americanDate(d);
      });
    }
    if (endDates !== undefined) {
      endDates = endDates.map(d => {
        if (d !== undefined) {
          if (d === 0) return '0';
          return americanDate(d);
        }
      });
    }

    let csvData = [];
    if (downloadToCsv) {
      await getPaginatedSalesOrders({
        page: page + 1,
        reference,
        startDates,
        endDates,
        client,
        invoicingType,
        rate,
        value,
        daysOrdered,
        sortBy,
        globalFilter,
        downloadToCsv,
        source,
      }).then(r => {
        r.forEach(e => {
          csvData.push({
            id: e.id,
            reference: e.order_ref,
            start: e.order_start,
            end: e.order_end,
            client: e.client.name,
            invoicingType: contractInvoicingTypes.strings[e.invoicing_type],
            rate: e.daily_rate,
            value: e.value,
            daysOrdered: e.days_ordered,
          });
        });
      });
      return csvData;
    } else {
      await getPaginatedSalesOrders({
        page: page + 1,
        reference,
        startDates,
        endDates,
        client,
        invoicingType,
        rate,
        value,
        daysOrdered,
        sortBy,
        globalFilter,
        downloadToCsv,
        source,
      }).then(r => {
        setContractsData(r);
      });
    }
  };

  const [contractsData, setContractsData] = useState([]);
  const tableData = [];

  if (contractsData?.rows?.length) {
    contractsData.rows.map(e => {
      tableData.push({
        id: e.id,
        reference: e.order_ref,
        start: e.order_start,
        end: e.order_end,
        client: e.client.name,
        invoicingType: contractInvoicingTypes.strings[e.invoicing_type],
        rate: e.daily_rate,
        value: e.value,
        daysOrdered: e?.days_ordered ? formatNumber(e.days_ordered, true) : 'FTE',
      });
    });
  }

  const memoizedTableData = tableData;

  const columns = useMemo(() => {
    return [
      {
        Header: 'ID',
        accessor: 'id',
        disableFilters: true,
      },
      {
        Header: 'Reference',
        accessor: 'reference',
      },
      {
        Header: 'Start',
        accessor: 'start',
        Cell: ({ value }) => {
          if (value) return date(value);
          if (!value) return null;
        },
        Filter: DateRangeColumnFilter,
        filter: 'date',
        filterOptions: filterOptions?.startDates,
      },
      {
        Header: 'End',
        accessor: 'end',
        Cell: ({ value }) => {
          if (value) return date(value);
          if (!value) return 'Open-ended';
        },
        Filter: DateRangeColumnFilter,
        filter: 'date',
        filterOptions: filterOptions?.endDates,
        openEndedOption: true,
      },
      {
        Header: 'Client',
        accessor: 'client',
        Filter: SelectColumnFilter,
        filter: 'includes',
        filterOptions: filterOptions?.clients?.length
          ? filterOptions?.clients.map(c => ({
              value: c.id,
              label: c.name,
            }))
          : [],
      },
      {
        Header: 'Rate',
        accessor: 'rate',
        Filter: NumberRangeColumnFilter,
        filter: 'between',
        Cell: ({ value }) => formatCurrency(value) || '-',
      },
      {
        Header: 'Value',
        accessor: 'value',
        Filter: NumberRangeColumnFilter,
        filter: 'between',
        Cell: ({ value }) => formatCurrency(value) || '-',
      },
      {
        Header: 'Days ordered',
        accessor: 'daysOrdered',
        Filter: NumberRangeColumnFilter,
        filter: 'between',
      },
      {
        Header: 'Invoicing',
        accessor: 'invoicingType',
        Filter: SelectColumnFilter,
        filter: 'includes',
        filterOptions: Object.keys(contractInvoicingTypes.strings).map(key => ({
          value: key,
          label: contractInvoicingTypes.strings[key],
        })),
      },
    ];
  }, [filterOptions]);

  const pages = [
    { name: 'Contracts Manager', href: '/admin-panel/contracts/purchase-orders', current: false },
    { name: `Framework Contracts`, href: '/admin-panel/contracts/framework-contracts', current: true },
  ];

  let renderRowMenu = row => {
    return [
      [
        {
          text: 'View/edit details',
          onClick: () => history.push(`/admin-panel/contracts/sales-orders/${row.original.id}`),
          show: true,
          icon: PencilIcon,
        },
        {
          text: 'View/edit details in new tab',
          onClick: () => window.open(`/admin-panel/contracts/sales-orders/${row.original.id}`),
          show: true,
          icon: ExternalLinkIcon,
        },
      ],
    ];
  };

  return (
    <AdminSidebar noPadding pages={pages}>
      <Table
        isLoading={fetchingData}
        columns={columns}
        data={memoizedTableData}
        tableName="salesOrders"
        customContextMenu
        contextMenuOptions={renderRowMenu}
        pageCount={contractsData.totalPages}
        fetchData={fetchSalesOrders}
        totalItems={contractsData.totalItems}
      />
    </AdminSidebar>
  );
}

export default SalesOrders;
