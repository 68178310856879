import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DescriptionListItem } from '../../components/DescriptionEntries/DescriptionList';
import ReactSelect from '../../components/Inputs/ReactSelect';
import Loader from '../../components/Loading/Loader';
//configs
import { staffContractType } from '../../helpers/enum/staffContractType';
import useApi from '../../hooks/useApi';
//actions

function ContractInfo() {
  //redux state
  const currentUser = useSelector(state => state.auth.currentUser);
  const [activeStaffContracts, setActiveStaffContracts] = useState([]);
  const [loading, setLoading] = useState(true);

  const {
    staffContracts: { getActiveStaffContracts },
  } = useApi();

  useEffect(() => {
    getActiveStaffContracts(currentUser.id).then(d => {
      setLoading(false);
      setActiveStaffContracts(d);
    });
  }, []);

  //listbox state and manager for active contracts
  let staffContractListboxData = [];
  if (activeStaffContracts) activeStaffContracts.map(e => staffContractListboxData.push({ value: e.id, label: e.contract_ref }));

  let initialStaffContract = { value: 0, label: 'No active contract available' };
  if (staffContractListboxData.length === 0) staffContractListboxData.push(initialStaffContract);

  const [selectedStaffContract, setSelectedStaffContract] = useState(initialStaffContract);

  useEffect(() => {
    if (!staffContractListboxData.length) return;
    setSelectedStaffContract(staffContractListboxData[0]);
  }, [activeStaffContracts]);

  let selectedStaffContractData;
  if (selectedStaffContract.value !== 0) {
    selectedStaffContractData = activeStaffContracts.find(contract => contract.id === selectedStaffContract.value);
  }

  return (
    <form className="divide-y divide-gray-200 lg:col-span-9">
      <div className="py-6 px-4 sm:p-6 lg:pb-8">
        <div>
          <h2 className="text-lg leading-6 font-medium text-gray-900">Staff Contract</h2>
          <p className="mt-1 text-sm text-gray-500">Information about active staff contracts.</p>
        </div>
        <Loader isLoading={loading}>
          <div className="mt-6">
            <div className="-my-2 w-64">
              <DescriptionListItem
                tag={'Contract'}
                data={
                  <ReactSelect
                    options={staffContractListboxData}
                    selectedOptions={[selectedStaffContract]}
                    setSelectedItem={i => setSelectedStaffContract(i)}
                  />
                }
              />
            </div>
            {selectedStaffContract.value !== 0 && (
              <>
                <DescriptionListItem tag={'Contract type'} data={selectedStaffContractData.contractType.contract_type || '-'} />
                <DescriptionListItem
                  tag={'Contract start'}
                  data={dayjs(selectedStaffContractData.contract_start).format('DD/MM/YYYY') || '-'}
                />
                <DescriptionListItem
                  tag={'Contract end'}
                  data={
                    selectedStaffContractData.contract_end
                      ? dayjs(selectedStaffContractData.contract_end).format('DD/MM/YYYY') || '-'
                      : '-'
                  }
                />
                <DescriptionListItem tag={'Country'} data={selectedStaffContractData?.country?.name || '-'} />
                <DescriptionListItem tag={'Position'} data={selectedStaffContractData.position || '-'} />
                <DescriptionListItem tag={'IBAN'} data={selectedStaffContractData.iban || '-'} />
                <DescriptionListItem tag={'BIC/SWIFT'} data={selectedStaffContractData.bic_swift || '-'} />
                {selectedStaffContractData.contractType.contract_type === staffContractType.COMPANY && (
                  <>
                    <DescriptionListItem tag={'Company name'} data={selectedStaffContractData.company_name || '-'} />
                    <DescriptionListItem tag={'Company address'} data={selectedStaffContractData.company_address || '-'} />
                    <DescriptionListItem tag={'VAT number'} data={selectedStaffContractData.vat_nb || '-'} />
                  </>
                )}
              </>
            )}
          </div>
        </Loader>
      </div>
    </form>
  );
}

export default ContractInfo;
