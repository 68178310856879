import dayjs from 'dayjs';

export const date = value => {
  if (value === '-') return value;
  return value ? dayjs(value).format('DD/MM/YYYY') : undefined;
};

export const americanDate = value => {
  if (value === '-') return value;
  return value ? dayjs(value).format('YYYY-MM-DD') : undefined;
};

// order base on month order
// Index start from zero, [0] equal to "January"
export const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const DAY_SHORT_NAMES = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
