import React from 'react';
import HashLoader from 'react-spinners/HashLoader';
import thaleriaColors from '../../helpers/thaleriaColors';

function Loader({ isLoading, children, className, background, table }) {
  if (!isLoading) return <>{children}</>;

  if (className)
    return (
      <div className={className}>
        <HashLoader color={thaleriaColors['thaleria-orange'][700]} />
      </div>
    );

  if (background)
    return (
      <div className="flex justify-around min-h-500 p-16 bg-white rounded-lg">
        <HashLoader color={thaleriaColors['thaleria-orange'][700]} />
      </div>
    );

  if (table)
    return (
      <td colSpan={100}>
        <div className="flex justify-around min-h-500 p-16 max-w-[1000px]">
          <HashLoader color={thaleriaColors['thaleria-orange'][700]} />
        </div>
      </td>
    );

  return (
    <div className="flex justify-around min-h-500 p-16 rounded-lg">
      <HashLoader color={thaleriaColors['thaleria-orange'][700]} />
    </div>
  );
}

export default Loader;
