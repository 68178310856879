import React from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

const NotificationMessage = ({ index, item, setToRead, active }) => {
  let resources = JSON.parse(item.resources);

  const MyLink = ({ children }) => {
    return (
      <a href={resources.href} className={`text-gray-600 text-sm w-full`}>
        {children}
      </a>
    );
  };

  return (
    <li className={`group hover:bg-gray-100 rounded-md mb-[-2px] flex items-center px-2`}>
      <div className="w-5 self-start mt-[5px] flex flex-col justify-center ">
        <div
          onClick={() => setToRead(index)}
          className={`rounded-full w-2.5 h-2.5 mt-4 ${
            item.read ? 'hidden' : 'bg-thaleria-orange-700'
          } hover:bg-thaleria-orange-500 cursor-pointer`}
        />
      </div>
      <Link
        to={resources.href}
        className={`text-gray-600 text-sm w-full`}
        onClick={() => setToRead(index)}
        onAuxClick={() => setToRead(index)}
      >
        {index !== 0 && <hr className="border-t border-gray-100"></hr>}
        <div className={` flex flex-col w-full space-y-0.5 py-4`}>
          <p className="flex-grow ">{item.message}</p>
          <p className="flex flex-shrink-0 justify-start text-xs text-gray-400">
            {dayjs(item.created_at).format('MMM DD, YYYY') + ' at ' + dayjs(item.created_at).format('HH:mm')}
          </p>
        </div>
      </Link>
    </li>
  );
};

export default NotificationMessage;
