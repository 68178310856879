import React, { useRef, useState } from 'react';
import { useAsyncDebounce } from 'react-table';

export default function GlobalFilter({ globalFilter, setGlobalFilter, totalItems, tableName }) {
  const overlayRef = useRef(null); // Ref to the dropdown div
  const [value, setValue] = React.useState(globalFilter);
  const [yOffset, setYOffset] = useState(0);
  const [xOffset, setXOffset] = useState(0);

  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
  }, 200);

  const checkOffset = () => {
    const element = overlayRef.current;
    if (element) {
      setYOffset(element.clientHeight + 10);
      setXOffset(element.clientWidth - 10);
    }
  };

  let filterableColumns;

  switch (tableName) {
    case 'staffOrders':
      filterableColumns = <div>Global filter searches for id, user name, contract references and rates only</div>;
      break;
    case 'staffContracts':
      filterableColumns = (
        <div>
          Global filter searches for user name, country, reference, position, VAT number, IBAN, BIC, company name, company
          address, contract start and contract end
        </div>
      );
      break;
    case 'clients':
      filterableColumns = <div>Global filter searches for client name and country</div>;
      break;
    case 'holidays':
      filterableColumns = <div>Global filter searches for id, name, client type, client name and country</div>;
      break;
    case 'approvedInvoicingItems':
      filterableColumns = (
        <div>
          Global filter searches for user, client, days worked, revenue and cost, daily rates, contract references, vat owed and
          recovered, rev + vat, vat balance and gross margin
        </div>
      );
      break;
    case 'invoicedInvoicingItems':
      filterableColumns = (
        <div>
          Global filter searches for user, client, days worked, revenue and cost, daily rates, contract references, vat owed and
          recovered, rev + vat, vat balance and gross margin
        </div>
      );
      break;
    case 'backlogInvoicingItems':
      filterableColumns = <div>Global filter searches for user, client, PO daily rate and contract references </div>;
      break;
    case 'unpaidAccountsPayable':
      filterableColumns = <div>Global filter searches for user, month, year, cost, VAT recovered and total cost </div>;
      break;
    case 'paidAccountsPayable':
      filterableColumns = <div>Global filter searches for user, month, year, cost, VAT recovered and total cost </div>;
      break;
    case 'backlogAccountsPayable':
      filterableColumns = <div>Global filter searches for user, month, year, cost, VAT recovered and total cost </div>;
      break;
    case 'leaveRequests':
      filterableColumns = <div>Global filter searches for id, type, requested days, user and status</div>;
      break;
    default:
      filterableColumns = null;
      break;
  }

  return (
    <span className="group relative" onMouseEnter={() => checkOffset()}>
      <input
        type="text"
        className="shadow-sm focus:ring-thaleria-orange-700  focus:border-thaleria-orange-700 block w-full sm:text-sm border p-2 border-gray-300 rounded-md"
        value={value || ''}
        onChange={e => {
          //This regex replaces ',' with '.' whenever ',' has a number next to it because our DB stores decimal values with '.' but we display currency values with ','
          const replacedValue = e.target.value.replace(/(\d),|,(\d)/g, '$1.$2');
          setValue(replacedValue);
          onChange(replacedValue);
        }}
        placeholder={totalItems ? `Search ${totalItems} records...` : ''}
      />
      {filterableColumns && (
        <div
          ref={overlayRef}
          className="z-5 hidden group-hover:inline-block absolute rounded-md bg-gray-400 text-white shadow-lg text-sm p-2"
          style={{
            transform: `translate(${xOffset}px, -${yOffset}px)`, // Adjust position to always be above to the right of the input
            minWidth: '200px',
          }}
        >
          {filterableColumns}
        </div>
      )}
    </span>
  );
}
