import React, { useState, useEffect } from 'react';
import PageWrapper from '../../../containers/App/PageWrapper';
import useApi from '../../../hooks/useApi';
import Loader from '../../../components/Loading/Loader';
import { useHistory, useParams } from 'react-router-dom';
import InvoiceDetails from '../../../containers/MyInvoices/InvoiceDetails';
import { format } from 'date-fns';
import ButtonPrimary from '../../../components/Buttons/ButtonPrimary';
import SimpleAlert from '../../../components/Modals/SimpleAlert';
import { useDispatch } from 'react-redux';
import { showSuccessNotification } from '../../../store/app/actions';
import { lineItemTypesV2 } from '../../../helpers/enum/lineItemTypes';

const MyInvoiceDetails = () => {
  const [invoice, setInvoice] = useState({});
  const [lineItems, setLineItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [invoiceFile, setInvoiceFile] = useState(null);
  const [loadingFile, setLoadingFile] = useState(true);
  const [loadingAP, setLoadingAP] = useState(true);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    accountsPayable: { getMyInvoice, getAccountsPayableFilesOnFolder, deleteAccountsPayable },
  } = useApi();

  useEffect(() => {
    getMyInvoice(id)
      .then(res => {
        const { lineItems, ...invoice } = res;
        setInvoice(invoice);
        setLineItems(
          lineItems.sort((a, b) => {
            //order line items by type (allocations and milestones first, then adjustment lines and finally custom lines) and by created_at date
            const typeOrder = [
              'projectAllocations',
              'unapprovedProjectAllocations',
              'milestone',
              'projectAllocationCreditNoteAdjustment',
              'projectAllocationAccountReceivableAdjustment',
              'customLineReinvoicingOfExpenses',
              'customLineRecruitmentFee',
              'customLineOther',
            ];

            const orderedTypeValues = typeOrder.map(type => lineItemTypesV2.accountsPayable.numbers[type]);

            function getTypeOrderValue(type) {
              return orderedTypeValues.indexOf(type);
            }

            const aTypeOrderValue = getTypeOrderValue(a.type);
            const bTypeOrderValue = getTypeOrderValue(b.type);

            // First, compare by type order
            if (aTypeOrderValue !== bTypeOrderValue) {
              return aTypeOrderValue - bTypeOrderValue;
            }

            // If types are the same, compare by createdAt date
            return new Date(a.created_at) - new Date(b.created_at);
          }),
        );
        setIsLoading(false);
        setLoadingAP(false);
      })
      .catch(err => {
        setLoadingAP(false);
        throw err;
      });
    getAccountsPayableFilesOnFolder(id)
      .then(res => {
        setLoadingFile(false);
        const files = res.data.map(obj => ({
          ...obj,
          id: obj.key,
          resource: obj.key,
        }));
        if (files.length) {
          setInvoiceFile(files[0]);
        } else {
          return;
        }
      })
      .catch(err => {
        setLoadingFile(false);
        return err;
      });
  }, [id]);

  const pages = [
    { name: 'My invoices', href: '/my-invoices', current: false },
    {
      name: invoice?.invoice_number
        ? `Invoice #${invoice.invoice_number}`
        : invoice?.period
        ? `Invoice for ${format(new Date(invoice?.period), 'MMMM yyyy')}`
        : 'Invoice',
      href: `/my-invoices/${id}`,
      current: true,
    },
  ];

  const deleteHandler = () => {
    deleteAccountsPayable(invoice.id).then(res => {
      dispatch(showSuccessNotification('Invoice deleted!'));
      history.replace('/my-invoices');
    });
  };

  return (
    <PageWrapper pages={pages}>
      <SimpleAlert
        errorTitle={'Delete invoice?'}
        errorMsg={
          'By continuing you will permanently delete this invoice including the file you have uploaded, any custom lines you may have created and all the comments. Other line items like allocations and milestones will still be available to invoice again.'
        }
        show={showDeleteAlert}
        hide={() => setShowDeleteAlert(false)}
        onAcceptText={'Delete'}
        onAcceptClick={() => deleteHandler()}
        onDeclineText={'Cancel'}
        onDeclineClick={() => setShowDeleteAlert(false)}
      />
      <Loader isLoading={isLoading}>
        <InvoiceDetails
          invoice={invoice}
          lineItems={lineItems}
          file={invoiceFile}
          loadingFile={loadingFile}
          loadingAP={loadingAP}
        />
        <div className="w-full justify-start mt-4">
          <ButtonPrimary canDelete color="red" style="mr-2" onClick={() => setShowDeleteAlert(true)} text="Delete" />
        </div>
      </Loader>
    </PageWrapper>
  );
};

export default MyInvoiceDetails;
