import React, { useEffect, useState } from 'react';
import ViewAnnouncement from '../../../containers/Home/Announcements/ViewAnnouncement';
import PageWrapperV2 from '../../../containers/App/PageWrapper';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import useApi from '../../../hooks/useApi';
import ButtonSecondary from '../../../components/Buttons/ButtonSecondary';
import ButtonPrimary from '../../../components/Buttons/ButtonPrimary';
import SimpleAlert from '../../../components/Modals/SimpleAlert';
import { useSelector } from 'react-redux';
import { AccountType } from '../../../helpers/enum/accounts';
import Loader from '../../../components/Loading/Loader';

const Announcement = () => {
  const [announcement, setAnnouncement] = useState({ title: ' ', body: '' });
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const {
    announcements: { deleteAnnouncement, getAnnouncement },
  } = useApi();

  const accountType = useSelector(state => state.auth.currentUser.account_type_id);

  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    getAnnouncement(id).then(res => {
      setAnnouncement(res);
      setIsLoading(false);
    });
  }, []);

  const goToEditPage = () => history.push(`/announcement/${id}/edit`);

  const onDeleteAlertHandler = () => {
    deleteAnnouncement(id).then(() => {
      history.goBack();
    });
  };

  const pages = [{ name: `${announcement.title}`, href: `/announcement/${id}`, current: true }];

  return (
    <PageWrapperV2 pages={pages}>
      <Loader isLoading={isLoading}>
        <ViewAnnouncement announcement={announcement} />
        {(accountType === AccountType.ADMIN || accountType === AccountType.ROOT) && (
          <>
            <div className="space-x-4 flex justify-end mt-4">
              <ButtonPrimary canDelete={true} text="Delete" onClick={() => setShowDeleteAlert(true)} />
              <ButtonSecondary text="Edit" onClick={goToEditPage} />
            </div>
            <SimpleAlert
              errorTitle="Delete Announcement"
              errorMsg="Are you sure you want to Delete announcement ?"
              onAcceptText="Delete"
              onDeclineText="Cancel"
              onAcceptClick={onDeleteAlertHandler}
              show={showDeleteAlert}
              hide={() => setShowDeleteAlert(false)}
            />
          </>
        )}
      </Loader>
    </PageWrapperV2>
  );
};

export default Announcement;
