import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router';
import AddOrEditFrameworkContract from '../../../../containers/Contracts/FrameworkContracts/AddOrEditFrameworkContract';
import FrameworkContractDetails from '../../../../containers/Contracts/FrameworkContracts/FrameworkContractDetails';
import PageWrapperV2 from '../../../../containers/App/PageWrapper';
import { showSuccessNotification } from '../../../../store/app/actions';
import useApi from '../../../../hooks/useApi';

export default function AddOrEditFrameworkContractPage() {
  const {
    clients: { getAllClients },
    frameworkContracts: { getAllFrameworkContracts, getFrameworkContractRefs, createFrameworkContract, updateFrameworkContract },
    bankAccounts: { getAllBankAccounts },
  } = useApi();

  const [pages, setPages] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();

  const pathNameArray = location.pathname.split('/');
  const displayPage = pathNameArray[pathNameArray.length - 1];

  const fetchPartners = async () => {
    getAllClients().then(d => setPartners(d));
  };

  useEffect(() => {
    fetchPartners();
  }, []);

  const [partners, setPartners] = useState([]);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [frameworkContracts, setFrameworkContracts] = useState([]);
  const [allFrameworkContractRefs, setAllFrameworkContractRefs] = useState([]);

  useEffect(() => {
    getAllFrameworkContracts().then(d => {
      setFrameworkContracts(d);
    });
    getFrameworkContractRefs().then(contracts => {
      setAllFrameworkContractRefs(contracts.map(c => c.contract_ref));
    });
    fetchAllBanks();
  }, []);

  const fetchAllBanks = () => {
    getAllBankAccounts().then(bankAccounts => {
      setBankAccounts(bankAccounts);
    });
  };

  const handleClick = form => {
    if (id === 'create') {
      createFrameworkContract(form).then(data => {
        dispatch(showSuccessNotification('Framework contract was created'));
        data ? history.push('/admin-panel/contracts/framework-contracts') : alert('Error while saving the details');
      });
    } else {
      updateFrameworkContract(id, form).then(data => {
        dispatch(showSuccessNotification('Framework contract was updated'));
        data ? history.push('/admin-panel/contracts/framework-contracts') : alert('Error while saving the details');
      });
    }
  };

  const handleEditClick = () => {
    history.push(`/admin-panel/contracts/framework-contracts/${id}/edit`);
  };

  useEffect(() => {
    if (!id) return;
    let _pages = [
      { name: 'Contracts Manager', href: '/admin-panel/contracts/purchase-orders', current: false },
      { name: 'Framework Contracts', href: '/admin-panel/contracts/framework-contracts', current: false },
    ];
    let additionalPages = [];
    const _thisFrameworkContract = id && frameworkContracts.find(contract => contract.id == id);

    if (displayPage === 'edit') {
      if (!frameworkContracts?.length) return;
      additionalPages = [
        {
          name: _thisFrameworkContract?.contract_ref,
          href: `/admin-panel/contracts/framework-contracts/${_thisFrameworkContract.id}`,
          current: false,
        },
        {
          name: 'Edit',
          href: `/admin-panel/contracts/framework-contracts/${_thisFrameworkContract.id}/edit`,
          current: false,
        },
      ];
    } else if (displayPage === 'create') {
      additionalPages = [
        {
          name: 'Create',
          href: `/admin-panel/contracts/framework-contracts/create`,
          current: false,
        },
      ];
    } else {
      if (!frameworkContracts?.length) return;
      additionalPages = [
        {
          name: _thisFrameworkContract.contract_ref,
          href: `/admin-panel/contracts/framework-contracts/${_thisFrameworkContract.id}`,
          current: false,
        },
      ];
    }
    additionalPages.forEach(page => _pages.push(page));
    _pages[_pages.length - 1].current = true;
    setPages(_pages);
  }, [location.pathname, frameworkContracts]);

  return (
    <PageWrapperV2 pages={pages}>
      {displayPage === 'create' || displayPage === 'edit' ? (
        <AddOrEditFrameworkContract
          fetchPartners={fetchPartners}
          frameworkContracts={frameworkContracts}
          partners={partners}
          banks={bankAccounts}
          id={id}
          handleSave={handleClick}
          allRefs={allFrameworkContractRefs}
          fetchAllBanks={fetchAllBanks}
        />
      ) : (
        frameworkContracts.length && (
          <FrameworkContractDetails
            frameworkContract={frameworkContracts.find(contract => contract.id == id)}
            handleEditClick={handleEditClick}
          />
        )
      )}
    </PageWrapperV2>
  );
}
