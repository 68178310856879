import React, { useState, useEffect } from 'react';
import Modal from '../../components/Modals/Modal';
import ButtonPrimary from '../../components/Buttons/ButtonPrimary';
import FileAccessManagement from './FileAccessManagement';
import ButtonWhite from '../../components/Buttons/ButtonWhite';
import { AccountType } from '../../helpers/enum/accounts';
import contractTypes from '../../helpers/enum/contractTypeIDs';
import ClipLoader from 'react-spinners/ClipLoader';

const EditAccess = ({ title, show, onEditClick, isOrgDocument, parentFolder, hide, currentUser, selectedFile, loading }) => {
  const [acceptedAccountTypes, setAcceptedAccountTypes] = useState(selectedFile?.restrictions?.acceptedAccountTypes);
  const [acceptedContractTypes, setAcceptedContractTypes] = useState(selectedFile?.restrictions?.acceptedContractTypes);
  const [acceptedCountries, setAcceptedCountries] = useState(selectedFile?.restrictions?.acceptedCountries);

  const [disabledCheckboxes, setDisabledCheckboxes] = useState([]);
  const [countryOptions, setCountryOptions] = useState(null);

  // useEffect(() => {
  //   if (!show) {
  //     setAcceptedAccountTypes();
  //     setAcceptedContractTypes();
  //     setAcceptedCountries();
  //   }
  // }, [show]);

  useEffect(() => {
    if (parentFolder?.restrictions) {
      let disabledCheckboxes = [];
      Object.keys(contractTypes).forEach(key => {
        !parentFolder.restrictions?.acceptedContractTypes.includes(contractTypes[key]) && disabledCheckboxes.push(key.toString());
      });
      if (!parentFolder.restrictions.acceptedAccountTypes.includes(AccountType.USER)) disabledCheckboxes.push('user');
      if (!parentFolder.restrictions.acceptedAccountTypes.includes(AccountType.STAFF)) disabledCheckboxes.push('staff');
      setDisabledCheckboxes(disabledCheckboxes);
      if (parentFolder.restrictions?.acceptedCountries) {
        setCountryOptions(parentFolder.restrictions.acceptedCountries);
      } else {
        setCountryOptions(null);
      }
    } else {
      setCountryOptions(null);
    }
  }, [parentFolder]);

  const onCountriesInputChangeHandler = event => {
    let newCountries;
    if (!event) newCountries = [];
    else newCountries = event.map(country => country.value);

    setAcceptedCountries(newCountries);
  };

  const onContractTypesChangeHandler = event => {
    if (event.target.id == 'employee') {
      event.target.checked
        ? setAcceptedContractTypes(prev => [...prev, contractTypes.employee])
        : setAcceptedContractTypes(acceptedContractTypes.filter(id => id != contractTypes.employee));
    }
    if (event.target.id == 'freelancer') {
      event.target.checked
        ? setAcceptedContractTypes(prev => [...prev, contractTypes.freelancer])
        : setAcceptedContractTypes(acceptedContractTypes.filter(id => id != contractTypes.freelancer));
    }
    if (event.target.id == 'company') {
      event.target.checked
        ? setAcceptedContractTypes(prev => [...prev, contractTypes.company])
        : setAcceptedContractTypes(acceptedContractTypes.filter(id => id != contractTypes.company));
    }
  };

  const onAccountTypesChangeHandler = event => {
    if (event.target.id == 'user') {
      event.target.checked
        ? setAcceptedAccountTypes(prev => [...prev, AccountType.USER])
        : setAcceptedAccountTypes(acceptedAccountTypes.filter(id => id != AccountType.USER));
    }
    if (event.target.id == 'staff') {
      event.target.checked
        ? setAcceptedAccountTypes(prev => [...prev, AccountType.STAFF])
        : setAcceptedAccountTypes(acceptedAccountTypes.filter(id => id != AccountType.STAFF));
    }
  };

  const addRestrictionsAndEdit = () => {
    let newAccessData = {
      path: selectedFile.path,
      restrictions: {
        acceptedAccountTypes: acceptedAccountTypes,
        acceptedContractTypes: acceptedContractTypes,
      },
    };
    if (acceptedCountries?.length) newAccessData.restrictions.acceptedCountries = acceptedCountries;

    onEditClick(newAccessData);
  };

  return (
    <Modal show={show} hide={hide} size="md">
      <div className="sm:flex sm:items-start ">
        <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
          <h2 className="leading-6 text-gray-900 pb-8" id="modal-title">
            {title}
          </h2>
          <FileAccessManagement
            acceptedCountries={acceptedCountries}
            acceptedContractTypes={acceptedContractTypes}
            acceptedAccountTypes={acceptedAccountTypes}
            countriesPlaceholder="All countries"
            onCountriesChange={onCountriesInputChangeHandler}
            onContractTypesChange={onContractTypesChangeHandler}
            onAccountTypesChange={onAccountTypesChangeHandler}
            isAdmin={currentUser.account_type_id == AccountType.ADMIN}
            countryOptions={countryOptions}
            disabledCheckboxes={disabledCheckboxes}
          />
        </div>
      </div>
      <div className="pt-7 flex justify-end">
        <ButtonWhite text={'Cancel'} onClick={hide} />
        <ButtonPrimary
          text={loading?.editAccess ? <ClipLoader className="mr-2" size={17} color={'#ffffff'} /> : 'Edit'}
          disable={!acceptedContractTypes?.length || (!acceptedCountries?.length && countryOptions)}
          style="ml-2"
          onClick={() => addRestrictionsAndEdit()}
        />
      </div>
    </Modal>
  );
};

export default EditAccess;
