import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import PageWrapper from '../../../../containers/App/PageWrapper';
import DaysLog from '../../../../containers/Contracts/DaysLog';
//actions
import { useQuery } from '../../../../hooks/useQuery';
import useApi from '../../../../hooks/useApi';

function RemainingDaysLog() {
  const {
    salesOrders: { fetchSalesOrderLogs },
    specificContracts: { fetchSpecificContractsLogs },
    timesheets: { fetchTimesheetEntriesForLog },
    purchaseOrders: { fetchStaffOrderLogs, getAssociatedContractIds },
  } = useApi();

  const query = useQuery();
  const { id } = useParams();

  const [scrollSetting, setScrollSetting] = useState({ page: 1 });
  const [logs, setLogs] = useState({ data: [], totalPages: null });
  const [salesOrderId, setSalesOrderId] = useState();
  const [specificContractId, setSpecificContractId] = useState();
  const [queryArguments, setQueryArguments] = useState();
  const [purchaseOrderRef, setPurchaseOrderRef] = useState();
  const [infoForDispatch, setInfoForDispatch] = useState({
    id: id,
    type: 'staffOrder',
  });
  const [selectedType, setSelectedType] = useState([1]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(async () => {
    if (id) {
      let idForDispatch;
      let typeForDispatch;
      let _salesOrderId = salesOrderId;
      let _specificContractId = specificContractId;
      if (!salesOrderId || !specificContractId) {
        getAssociatedContractIds(id).then(res => {
          setSalesOrderId(res.sales_order_id);
          _salesOrderId = res.sales_order_id;
          setSpecificContractId(res.specific_contract_id);
          _specificContractId = res.specific_contract_id;
        });
      }
      switch (selectedType[0]) {
        case 1:
          idForDispatch = id;
          typeForDispatch = 'staffOrder';
          fetchStaffOrderLogs(id).then(res => {
            setLogs(prev => ({
              ...prev,
              days_ordered: res.data.days_ordered,
              order_ref: res.data.order_ref,
              days_remaining: res.data.days_remaining,
              dates: res.data.dates,
              userNames: res.data.userNames,
            }));
            setPurchaseOrderRef(res.data.order_ref);
          });
          break;
        case 2:
          idForDispatch = salesOrderId;
          typeForDispatch = 'salesOrder';
          fetchSalesOrderLogs(salesOrderId).then(res => {
            setLogs(prev => ({
              ...prev,
              days_ordered: res.data.days_ordered,
              order_ref: res.data.order_ref,
              days_remaining: res.data.days_remaining,
              dates: res.data.dates,
              userNames: res.data.userNames,
            }));
          });
          break;
        case 3:
          idForDispatch = specificContractId;
          typeForDispatch = 'specificContract';
          fetchSpecificContractsLogs(specificContractId).then(res => {
            setLogs(prev => ({
              ...prev,
              days_ordered: res.data.days_ordered,
              order_ref: res.data.order_ref,
              days_remaining: res.data.days_remaining,
              dates: res.data.dates,
              userNames: res.data.userNames,
            }));
          });
      }
      setInfoForDispatch({
        id: idForDispatch,
        type: typeForDispatch,
      });
      setIsLoading(true);
      await fetchTimesheetEntriesForLog(
        idForDispatch,
        typeForDispatch,
        1,
        6,
        queryArguments?.users,
        queryArguments?.status,
        queryArguments?.fromDate,
        queryArguments?.toDate,
      )
        .then(res => {
          setLogs(prev => ({
            ...prev,
            data: res.data.rows,
            totalPages: res.data.totalPages,
          }));
          if (res.data.totalPages <= 1) {
            setScrollSetting(prev => ({ ...prev, totalPages: res.data.totalPages, page: res.data.currentPage }));
          } else {
            setScrollSetting(prev => ({ ...prev, totalPages: res.data.totalPages, page: res.data.currentPage }));
          }
          setIsLoading(false);
        })
        .catch(err => {
          setIsLoading(false);
        });
    }
  }, [id, selectedType]);

  useEffect(async () => {
    const users = query.get('users');
    const status = query.get('status');
    const fromDate = query.get('fromDate');
    const toDate = query.get('toDate');
    const page = query.get('page');
    setIsLoading(true);
    setQueryArguments({ users, status, fromDate, toDate, page });
    await fetchTimesheetEntriesForLog(
      infoForDispatch.id,
      infoForDispatch.type,
      page ? page : 1,
      6,
      users,
      status,
      fromDate,
      toDate,
    )
      .then(res => {
        setLogs(prev => ({
          ...prev,
          data: res.data.rows,
          totalPages: res.data.totalPages,
        }));
        setScrollSetting(prev => ({ ...prev, page: res.data.currentPage, totalPages: res.data.totalPages }));
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
      });
  }, [query]);

  const fetchData = async page => {
    setIsLoading(true);
    await fetchTimesheetEntriesForLog(
      infoForDispatch.id,
      infoForDispatch.type,
      page,
      6,
      queryArguments?.users,
      queryArguments?.status,
      queryArguments?.fromDate,
      queryArguments?.toDate,
    )
      .then(res => {
        setLogs(prev => ({
          ...prev,
          data: res.data.rows,
          totalPages: res.data.totalPages,
        }));
        setScrollSetting(prev => ({
          ...prev,
          page: res.data.currentPage,
          totalPages: res.data.totalPages,
        }));
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
      });
  };

  const pages = [
    { name: 'Contracts Manager', href: '/admin-panel/contracts/purchase-orders', current: false },
    { name: `Purchase Orders`, href: '/admin-panel/contracts/purchase-orders', current: false },
    { name: `${purchaseOrderRef}`, href: `/admin-panel/contracts/purchase-orders/${id}`, current: false },
    { name: `Remaining Days Log`, href: '', current: true },
  ];

  const typeChange = e => {
    setSelectedType([e.value]);
  };

  return (
    <PageWrapper pages={pages}>
      <DaysLog
        logs={logs}
        fetchData={fetchData}
        scrollSetting={scrollSetting}
        typeChange={typeChange}
        selectedType={selectedType}
        isLoading={isLoading}
      />
    </PageWrapper>
  );
}

export default RemainingDaysLog;
