import React, { useState, useEffect } from 'react';
import useApi from '../../hooks/useApi';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { VALIDATION_MSG } from '../../helpers/enum/errorValidationMsgs';
import { useYupValidationResolver } from '../../utils/hooks/useYupValidationResolver';
import extractObjectDifferences from '../../helpers/extractObjectDifferences';
import { setNotification } from '../../store/app/actions';
import notifications from '../../helpers/enum/notifications';
import TwoColumnForm from '../../components/Layouts/TwoColumnForm';
import SimpleAlert from '../../components/Modals/SimpleAlert';
import InputSimple from '../../components/Inputs/InputSimple';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import formatStringToIban from '../../helpers/formatStringToIban';
export default function AddOrEditBankAccount({ bankAccount, id, handleSave }) {
  const {
    bankAccounts: { deleteBankAccount },
  } = useApi();

  const history = useHistory();

  const [showAlert, setShowAlert] = useState(false);

  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(VALIDATION_MSG).trim(),
    iban: Yup.string(),
    bic_swift: Yup.string().nullable(),
  });

  const formOptions = { resolver: useYupValidationResolver(validationSchema) };

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm(formOptions);

  useEffect(() => {
    if (!bankAccount) return;
    setValue('name', bankAccount.name);
    setValue('iban', bankAccount.iban);
    setValue('bic_swift', bankAccount.bic_swift);
  }, [bankAccount]);

  useEffect(() => {
    const input = watch('iban');
    if (!input) return;
    if (input !== formatStringToIban(input)) setValue('iban', formatStringToIban(input));
  }, [watch('iban')]);

  const formLabels = {
    name: 'Name*',
    iban: 'IBAN*',
    bic_swift: 'BIC SWIFT',
  };

  const labelArray = Object.keys(formLabels);

  const handleRemoveClick = () => {
    deleteBankAccount(id).then(d => {
      history.goBack();
      setShowAlert(false);
    });
  };

  const onSubmit = data => {
    let dataToSend = data;

    if (id !== 'create') {
      dataToSend = extractObjectDifferences(data, bankAccount);
    }

    if (!dataToSend) {
      dispatch(
        setNotification(
          notifications.WARNING_YELLOW,
          'Unable to update Bank Account',
          'No changes to the account were detected.',
        ),
      );
    } else {
      handleSave(dataToSend);
    }
  };

  return (
    <TwoColumnForm
      onClick={handleSubmit(onSubmit)}
      button
      buttonText={'Save'}
      label="Bank account info"
      description=""
      onClickRed={() => setShowAlert(true)}
      buttonRed={id && id !== 'create' ? true : false}
      buttonRedText={'Delete'}
    >
      <SimpleAlert
        errorTitle="Warning"
        errorMsg="Removing this will completely remove the bank account, and will delete it from the application. Are you sure you want to proceed?"
        onAcceptText="Proceed"
        onAcceptClick={handleRemoveClick}
        onDeclineText="Cancel"
        show={showAlert}
        hide={() => setShowAlert(false)}
      />
      <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-1">
        <InputSimple
          error={errors[labelArray[0]]?.message}
          register={register(labelArray[0])}
          label={formLabels[labelArray[0]]}
          dataLabel={labelArray[0]}
        />
        <InputSimple
          error={errors[labelArray[1]]?.message}
          register={register(labelArray[1])}
          label={formLabels[labelArray[1]]}
          dataLabel={labelArray[1]}
        />
        <InputSimple
          error={errors[labelArray[2]]?.message}
          register={register(labelArray[2])}
          label={formLabels[labelArray[2]]}
          dataLabel={labelArray[2]}
        />
      </dl>
    </TwoColumnForm>
  );
}
