import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import ProfilePicture from '../ProfilePicture/ProfilePicture';
import UserImagePlaceholder from '../UserImagePlaceholder/UserImagePlaceholder';
import ButtonPrimary from '../Buttons/ButtonPrimary';
import TextArea from '../Inputs/TextArea';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
const ChatSendMessage = ({ ImgUrl, sendMessage, onBodyChange, body, textAreaErrorMessage, disabled }) => {
  const textAreaRef = useRef(null);

  document.onkeydown = function (evt) {
    if (textAreaRef.current !== document.activeElement) return;
    evt = evt || window.event;
    var isEscape = false;
    var isEnter = false;
    var isShift = evt.shiftKey;
    var isAlt = evt.altKey;
    var isCtrl = evt.ctrlKey;
    if ('key' in evt) {
      isEscape = evt.key === 'Escape' || evt.key === 'Esc';
      isEnter = evt.key === 'Enter' || evt.key === 'Ent';
    } else {
      isEscape = evt.keyCode === 27;
      isEnter = evt.keyCode === 13;
    }
    if (isEscape) {
      textAreaRef.current.blur();
    }
    if (isEnter && (isShift || isAlt || isCtrl)) {
      sendMessage();
    }
  };

  const isBodyTooLong = body.length > 500;

  return (
    <div className="flex space-x-3">
      <div className="flex-shrink-0">
        <ProfilePicture
          src={ImgUrl}
          className="w-10 h-10 rounded-full"
          alt=""
          defaultimage={<UserImagePlaceholder className="w-10 h-10 rounded-full" />}
        />
      </div>
      <div className="min-w-0 flex-1">
        <TextArea
          className="h-16"
          placeholder="Add a comment"
          onChange={onBodyChange}
          value={body}
          errorMessage={textAreaErrorMessage}
          reference={textAreaRef}
          disabled={disabled}
        />
        {isBodyTooLong && (
          <div className="text-red-500 text-sm mt-1 flex items-center">
            <ExclamationCircleIcon className="w-4 h-4 mr-1" />
            <span>500 characters max</span>
          </div>
        )}
        <div className="mt-3 flex items-center justify-end">
          <ButtonPrimary disable={body.trim() ? isBodyTooLong : true} size="l" text="Comment" onClick={sendMessage} />
        </div>
      </div>
    </div>
  );
};

export default ChatSendMessage;

ChatSendMessage.prototype = {
  ImgUrl: PropTypes.string,
  onBodyChange: PropTypes.func,
  body: PropTypes.string,
  textAreaErrorMessage: PropTypes.string,
  sendMessage: PropTypes.func,
};
