import React from 'react';

const TimesheetCalendarGuide = () => {
  const sizeOfSquare = 'h-6 w-6';
  const styleOfText = 'text-xs ml-2';
  return (
    <div className="flex gap-x-5 flex-wrap gap-y-2 justify-center">
      <div className="flex mt-0 w-max items-center">
        <div className={`${sizeOfSquare} bg-green-100/75 border border-gray-400`}></div>
        <div className={styleOfText}>Public holiday</div>
      </div>
      <div className="flex w-max items-center mt-0">
        <div className={`${sizeOfSquare} bg-orange-100/75 border border-gray-400`}></div>
        <div className={styleOfText}>Closure day</div>
      </div>
      <div className="flex w-max items-center mt-0">
        <div className={`${sizeOfSquare} bg-blue-50/75 border border-gray-400`}></div>
        <div className={styleOfText}>Weekend</div>
      </div>
      <div className="flex w-max items-center mt-0 ">
        <div
          className={`${sizeOfSquare} border border-gray-400`}
          style={{
            background:
              'linear-gradient(to top left, rgba(255, 255, 255, 1) calc(50% - 1px), rgba(128, 128, 128, 0.25) calc(50% - 1px), rgba(128, 128, 128, 0.25) calc(50% + 1px), rgba(255, 255, 255, 1) calc(50% + 1px))',
          }}
        ></div>
        <div className={styleOfText}>No mission</div>
      </div>
      <div className="flex w-max items-center mt-0">
        <div
          className={`${sizeOfSquare} border border-gray-400`}
          style={{
            backgroundImage:
              'linear-gradient(45deg, #f3f4f6 40%, #c5d4db 40%, #c5d4db 50%, #f3f4f6 50%, #f3f4f6 90%, #c5d4db 90%, #c5d4db 100%)',
            backgroundSize: '9.90px 9.90px',
          }}
        ></div>
        <div className={styleOfText}>Handover period</div>
      </div>
    </div>
  );
};

export default TimesheetCalendarGuide;
