import React, { useState } from 'react';
import useApi from '../../hooks/useApi';
import ButtonPrimary from '../../components/Buttons/ButtonPrimary';
import SimpleAlert from '../../components/Modals/SimpleAlert';
import { showSuccessNotification } from '../../store/app/actions';
import { useDispatch } from 'react-redux';
import { ClipLoader } from 'react-spinners';

const SettingsInfo = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const {
    settings: { resetSettings },
  } = useApi();
  const dispatch = useDispatch();
  const onResetClick = () => {
    setIsLoading(true);
    resetSettings().then(res => {
      dispatch(showSuccessNotification('Settings reset.'));
      setShowAlert(false);
      setIsLoading(false);
    });
  };
  return (
    <form className="divide-y divide-gray-200 lg:col-span-9">
      <SimpleAlert
        show={showAlert}
        hide={() => setShowAlert(false)}
        errorTitle="Reset settings"
        errorMsg="Resetting your settings will delete all saved filters, sorting, column editing and file naming templates. Are you sure you want to proceed?"
        onAcceptText={isLoading ? <ClipLoader className="h-3 w-3" size={12} color={'#FFFF'} /> : 'Continue'}
        onAcceptClick={() => onResetClick()}
        onDeclineClick={() => setShowAlert(false)}
        onDeclineText={'Cancel'}
      />
      <div className="py-6 px-4 sm:p-6 lg:pb-8">
        <div className="mb-4">
          <h2 className="text-lg leading-6 font-medium text-gray-900">My settings</h2>
          <p className="mt-1 text-sm text-gray-500">
            If you are encountering issues while opening tables you can try resetting your settings. This will delete all your
            saved table settings like filters, sorting, column ordering and file naming templates but can fix the table rendering
            issue.
          </p>
        </div>
        <ButtonPrimary className="w-full" text="Reset settings" onClick={() => setShowAlert(true)} />
      </div>
    </form>
  );
};

export default SettingsInfo;
