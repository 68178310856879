import React, { useMemo, useState } from 'react';
import Card from '../../components/Cards/Card';
import SimpleEntry from '../../components/DescriptionEntries/SimpleEntry';
import paidStatus from '../../helpers/enum/paidStatus';
import formatCurrency from '../../utils/formatCurrency';
import { format } from 'date-fns';
import Chat from '../Chat/Chat';
import useApi from '../../hooks/useApi';
import AttachmentCard from '../../components/Attachments/AttachmentCard';
import { ClipLoader } from 'react-spinners';
import { downloadBase64File } from '../../helpers';
import PreviewBox from '../PDFViewer/PreviewBox';
import { ReceiptTaxIcon } from '@heroicons/react/outline';
import Loader from '../../components/Loading/Loader';
import './FormTableStyle.css';
import AccountsPayableLineItemDetails from '../../components/Tables/AccountsPayableLineItemDetails';
import InfoTooltip from '../../components/Tooltips/InfoTooltip';

const InvoiceDetails = ({ invoice, lineItems, loadingFile, file, loadingAP }) => {
  const [message, setMessage] = useState('');
  const [preparingPreview, setPreparingPreview] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const totals = useMemo(() => {
    let _totals = {
      total: 0,
      vat: 0,
      total_and_vat: 0,
    };

    if (lineItems.length) {
      lineItems.forEach(line => {
        _totals.total += Number(line.total);
        if (line?.vat) _totals.vat += Number(line.vat);
      });
    }

    _totals.total_and_vat = _totals.total + _totals.vat;
    return _totals;
  }, [lineItems]);

  const {
    accountsPayable: { createAPComment, deleteAccountsPayable, downloadAPFile },
  } = useApi();

  const prepareChatData = res => {
    const messages = res.data.map(message => {
      const momentOfPublish = new Date(message.created_at);
      return {
        id: message.id,
        ownerName: message.user.first_names,
        ownerImg: message.user.profilepic_url,
        ownerId: message.user_id,
        text: message.comment,
        time: momentOfPublish.toLocaleString('en-us'),
      };
    });

    return messages;
  };

  const onMessageChange = e => setMessage(e.target.value);

  const sendMessageHandler = () =>
    createAPComment({ accounts_payable_id: invoice.id, comment: message }).then(res => setMessage(''));

  const isPreviewable = fileName => {
    let bool = false;
    const extension = fileName.split('.')[fileName.split('.').length - 1].toLowerCase();
    if (extension === 'pdf' || extension === 'jpg' || extension === 'png' || extension === 'jpeg') bool = true;
    return bool;
  };

  const onPreviewHandler = () => {
    downloadAPFile(invoice.id)
      .then(response => {
        setPreparingPreview(false);
        setShowPreview({
          url: response.data,
          show: true,
          fileType: file.resource.split('.')[file.resource.split('.').length - 1],
          title: file.resource.split('.')[file.resource.split('.').length - 2].replace('/', ''),
        });
      })
      .catch(() => {
        setPreparingPreview(false);
      });
  };

  const downloadHandler = () => {
    setDownloading(true);
    const fileName = file.resource.split('/')[file.resource.split('/').length - 1];
    downloadAPFile(invoice.id)
      .then(response => {
        downloadBase64File(response.data, fileName);
        setDownloading(false);
      })
      .catch(() => {
        setDownloading(false);
      });
  };
  return (
    <dl className="grid grid-cols-1 md:grid-cols-3 gap-4">
      <PreviewBox
        filePath={showPreview.url}
        fileType={showPreview.fileType}
        showPreview={showPreview.show}
        handleHide={() => setShowPreview(false)}
        title={showPreview.title}
      />
      <Card title="Invoice lines" className="col-span-3">
        <Loader isLoading={loadingAP} className="w-full flex justify-center m-8">
          <AccountsPayableLineItemDetails lineItems={lineItems} totals={totals} />
        </Loader>
      </Card>
      <Card title="Invoice details" className="col-span-3 md:col-span-2">
        <Loader isLoading={loadingAP} className="w-full flex justify-center m-8">
          <dl className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <SimpleEntry label="Invoice number" data={invoice.invoice_number || '-'} />
            <SimpleEntry label="Due date" data={format(new Date(invoice.due_date), 'dd/MM/yyyy')} />
            <SimpleEntry label="IBAN" data={invoice.iban ? invoice.iban : '-'} />
            <SimpleEntry label="BIC SWIFT" data={invoice.bic_swift ? invoice.bic_swift : '-'} />
            <SimpleEntry label="Company name" data={invoice.company_name ? invoice.company_name : '-'} />
            <SimpleEntry label="Amount match" data={invoice.amount_match ? 'Yes' : 'No'} />
            <SimpleEntry
              label="Uploaded on"
              data={invoice?.created_at ? format(new Date(invoice.created_at), 'dd/MM/yyyy') : '-'}
            />
            <SimpleEntry
              label="Status"
              data={
                !invoice.approved
                  ? 'Pending'
                  : invoice.paid !== paidStatus.numbers.paid
                  ? 'Approved'
                  : invoice.paid === paidStatus.numbers.paid
                  ? 'Processed'
                  : '-'
              }
            />
            {invoice.paid === paidStatus.numbers.partiallyPaid ? (
              <SimpleEntry
                label="Advance"
                data={
                  <div className="flex items-center gap-x-1">
                    <p>{formatCurrency(invoice.paid_amount)}</p>
                    <InfoTooltip size="auto">{`${formatCurrency(
                      Number(invoice.cost_and_vat) - Number(invoice.paid_amount),
                    )} left to process.`}</InfoTooltip>
                  </div>
                }
              />
            ) : (
              ''
            )}
            {invoice.paid !== paidStatus.numbers.unpaid && (
              <SimpleEntry
                label="Processed on"
                data={invoice?.payment_date ? format(new Date(invoice.payment_date), 'dd/MM/yyyy') : '-'}
              />
            )}
            <div className="col-span-1">
              <div className="flex items-center mb-1 gap-x-2">
                <span className="text-sm ">Invoice file</span>
              </div>
              <ul className="divide-y divide-thaleria-blue-200/75 border border-gray-200 rounded-md">
                <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm ">
                  {!loadingFile ? (
                    file ? (
                      <AttachmentCard
                        document={file}
                        enableDownload
                        isPreviewable={isPreviewable}
                        preparingPreview={preparingPreview}
                        onPreviewHandler={onPreviewHandler}
                        downloading={downloading}
                        onDownloadHandler={downloadHandler}
                        enableRemove={false}
                        fileIcon={'invoice'}
                      />
                    ) : (
                      <div className="flex justify-start">
                        <ReceiptTaxIcon className="w-5 h-5 text-gray-500" />
                        <span className="ml-2 text-gray-600">No invoice found</span>
                      </div>
                    )
                  ) : (
                    <div className="flex justify-center w-full">
                      <ClipLoader className="mr-10 h-5 w-5" size={18} color={'#FFA53B'} />
                    </div>
                  )}
                </li>
              </ul>
            </div>
          </dl>
        </Loader>
      </Card>
      <div className="col-span-3 md:col-span-1">
        <Chat
          url={`/accounts-payable-comments/${invoice?.id}`}
          messageText={message}
          prepare={prepareChatData}
          messageOnChange={onMessageChange}
          sendMessage={sendMessageHandler}
        />
      </div>
    </dl>
  );
};

export default InvoiceDetails;
