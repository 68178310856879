import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import PageWrapper from '../../../../containers/App/PageWrapper';
import ContactInfo from '../../../../containers/UserDirectory/AddUser/ContactInfo';
import PersonalInfo from '../../../../containers/UserDirectory/AddUser/PersonalInfo';
import DividerSimple from '../../../../components/Dividers/DividerSimple';
import AdminInfo from '../../../../containers/UserDirectory/AddUser/AdminInfo';
import ButtonPrimary from '../../../../components/Buttons/ButtonPrimary';
import ButtonWhite from '../../../../components/Buttons/ButtonWhite';
import useApi from '../../../../hooks/useApi';
import { ACCOUNT_TYPE } from '../../../../helpers/enum/accounts';
import { USER_CREATION_PW_RECIPIENT_IDs } from '../../../../helpers/enum/pwRecipientIds';
import { useHistory, useParams } from 'react-router';
import ModalImage from '../../../../components/Modals/ModalImage';
import { getCroppedImg } from '../../../../helpers';
import selectOptionCountries from '../../../../helpers/selectOptionCountries';

import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { VALIDATION_MSG } from '../../../../helpers/enum/errorValidationMsgs';
import { useYupValidationResolver } from '../../../../utils/hooks/useYupValidationResolver';
import ResetPassword from '../../../../containers/UserDirectory/ResetPassword';
import { showSuccessNotification } from '../../../../store/app/actions';
import Loader from '../../../../components/Loading/Loader';
import SimpleAlert from '../../../../components/Modals/SimpleAlert';

const CreateUser = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id: userIdFromURL } = useParams();

  const {
    users: { deActiveUser, getUser, updateUserInfo, createUser, deleteUsersProfilePicture },
  } = useApi();

  const currentUser = useSelector(state => state.auth.currentUser);
  const [userToEdit, setUserToEdit] = useState(null);
  const [showModalEditPicture, setShowModalEditPicture] = useState(false);
  const [showModalResetPassword, setShowModalResetPassword] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);

  const [newPicture, setNewPicture] = useState(null);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [countries, setCountries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);

  const validationSchema = Yup.object().shape({
    first_names: Yup.string().required(VALIDATION_MSG).trim(),
    last_names: Yup.string().required(VALIDATION_MSG).nullable(),
    birthday: Yup.date().nullable(),
    email: Yup.string().required(VALIDATION_MSG).nullable().email(),
    account_type_id: Yup.number().required(VALIDATION_MSG).nullable(),
    active: Yup.number().required(VALIDATION_MSG).nullable(),
  });

  const formOptions = { resolver: useYupValidationResolver(validationSchema) };

  const {
    register,
    handleSubmit,
    clearErrors,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm(formOptions);

  //new user state
  const [newUser, _setNewUser] = useState({
    data: {
      first_names: null,
      last_names: null,
      preferred_name: null,
      email: null,
      phone_nb: null,
      secondary_phone_nb: null,
      birthday: null,
      address: null,
      id_nb: null,
      hr_contact: null,
      sales_contact: null,
      account_type_id: null,
      profilepic_url: null,
      active: null,
      password: null,
      country: null,
    },
    pwEmailRecipient: 1,
  });

  const setNewUser = newVal => _setNewUser({ ...newUser, ...newVal });

  const handleDeActivateClick = async () => {
    await updateExistingUser();

    await deActiveUser(userIdFromURL);

    setShowWarningModal(false);

    history.push('/admin-panel/hr/user-directory/');
  };

  //prepare listbox data for accounts type selection
  const accountTypeListBoxData = [
    {
      value: ACCOUNT_TYPE.ADMIN.id,
      label: ACCOUNT_TYPE.ADMIN.name,
    },
    {
      value: ACCOUNT_TYPE.STAFF.id,
      label: ACCOUNT_TYPE.STAFF.name,
    },
    {
      value: ACCOUNT_TYPE.USER.id,
      label: ACCOUNT_TYPE.USER.name,
    },
  ];

  //prepare listbox data for account password selection
  const pwRecipientListBoxData = USER_CREATION_PW_RECIPIENT_IDs;

  //listbox data for account status
  let accountStatusListBoxData = [
    {
      value: 1,
      label: 'Not active',
    },
    {
      value: 2,
      label: 'Active',
    },
  ];

  function readFile(file) {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }

  const onProfilePicChange = async file => {
    file = await readFile(file);
    setNewPicture(file);
    setShowModalEditPicture(true);
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(newPicture, croppedAreaPixels, rotation);

      let newImage = new File([croppedImage], 'profile_picture', {
        type: croppedImage.type,
      });

      setCroppedImage(newImage);
      setShowModalEditPicture(false);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);

  useEffect(() => {
    if (!userIdFromURL) return setInitialLoading(false);

    const loadUser = async () => {
      const user = await getUser(userIdFromURL);

      if (user.active) user.active = 2;
      else user.active = 0;

      setValue('first_names', user.first_names);
      setValue('last_names', user.last_names);
      setValue('email', user.email);
      setValue('birthday', user.birthday);
      setValue('active', user.active);
      setValue('account_type_id', user.account_type_id);

      setNewUser({ data: user });
      setUserToEdit(user);
      setInitialLoading(false);
    };

    loadUser();
  }, [userIdFromURL]);

  useEffect(() => {
    setCountries(selectOptionCountries);
  }, []);

  const onSubmit = data => {
    setIsLoading(true);
    if (!userIdFromURL && !newUser.data.account_type_id) return alert('Please set account type before creating user');
    if (!userIdFromURL && !newUser.data.active) return alert('Please set user account status before creating user');

    if (!userIdFromURL) {
      createNewUser();
    } else {
      updateExistingUser();
    }
  };

  const createNewUser = async () => {
    let userData = { ...newUser };

    userData.data.active = getValues().active == 2;
    userData.profile_picture = croppedImage; //profilePic;
    userData.data.first_names = getValues().first_names;
    userData.data.last_names = getValues().last_names;
    userData.data.email = getValues().email;

    let user;

    await createUser(userData)
      .then(res => {
        history.push(`/admin-panel/hr/user-directory/${res.id}`);
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
      });

    return user;
  };

  const updateExistingUser = async () => {
    let userData = newUser;

    userData.data.active = getValues().active == 2;
    userData.data.first_names = getValues().first_names;
    userData.data.last_names = getValues().last_names;
    userData.data.email = getValues().email;
    userData.data.birthday = getValues().birthday;
    userData.data.account_type_id = getValues().account_type_id;

    delete userData.data.deleted_at;
    delete userData.data.tfa_enabled;
    delete userData.data.full_name;
    delete userData.data.updated_at;
    delete userData.data.login_count;
    delete userData.data.last_login;
    delete userData.data.created_at;
    delete userData.data.updated_at;
    //FIXME: Is this deleting anything? THe property seems to be account_type_id
    delete userData.data?.accountTypeId;
    delete userData.data?.StaffContracts;

    let dataToDispatch = { id: userToEdit.id, ...userData.data };
    if (croppedImage) {
      if (croppedImage == 'deleted') {
        delete dataToDispatch.profilepic_url;
        deleteUsersProfilePicture(userIdFromURL);
      } else {
        dataToDispatch.profile_picture = croppedImage;
      }
    }
    updateUserInfo(dataToDispatch)
      .then(() => {
        setIsLoading(false);
        dispatch(showSuccessNotification('User edited successfully'));
        history.push(`/admin-panel/hr/user-directory/${userIdFromURL}`);
      })
      .catch(err => {
        setIsLoading(false);
        return userData.data.active == getValues().active;
      });
  };

  const handleInputChange = (key, value) => {
    setNewUser({
      data: {
        ...newUser.data,
        [key]: value,
      },
    });
  };

  const onPwRecipientChange = PwRecipientItem => {
    setNewUser({
      ...newUser.data,
      pwEmailRecipient: PwRecipientItem.value,
    });
  };

  const onAccountStatusChange = accountStatusItem => {
    if (accountStatusItem?.value) {
      clearErrors('active');
    }
    setNewUser({
      data: {
        ...newUser.data,
        active: accountStatusItem?.value,
      },
    });
  };

  const onAccountTypeChange = accountTypeItem => {
    if (accountTypeItem?.value) {
      clearErrors('account_type_id');
    }
    setNewUser({
      data: {
        ...newUser.data,
        account_type_id: accountTypeItem?.value,
      },
    });
  };

  const selectedAccountType = accountTypeListBoxData.find(accountType => accountType.value == newUser.data.account_type_id);
  const selectedPwRecipient = pwRecipientListBoxData.find(pwRecipient => pwRecipient.value == newUser.pwEmailRecipient);
  const selectedAccountStatus = accountStatusListBoxData.find(accountStatus => accountStatus.value == getValues().active);

  const pages = [
    { name: 'User Directory', href: '/admin-panel/hr/user-directory', current: true },
    { name: userIdFromURL ? 'Update User' : 'Create New User', href: null, current: true },
  ];

  if (userIdFromURL)
    pages.splice(1, 0, { name: newUser.data.full_name, href: `/admin-panel/hr/user-directory/${userIdFromURL}`, current: false });

  return (
    <PageWrapper pages={pages}>
      <SimpleAlert
        onAcceptClick={handleSubmit(handleDeActivateClick)}
        onAcceptText={'Accept'}
        onDeclineClick={() => setShowWarningModal(false)}
        onDeclineText={'Cancel'}
        hide={() => setShowWarningModal(false)}
        show={showWarningModal}
        errorTitle={'Deactivate user'}
        errorMsg={
          'Deactivating a user account will block him from logging into the application, without deleting his data. You can always reactivate this account later.'
        }
      />
      <Loader isLoading={initialLoading}>
        <PersonalInfo
          register={register}
          errors={errors}
          control={control}
          croppedImage={croppedImage}
          onChange={handleInputChange}
          newUser={newUser}
          onProfilePicChange={onProfilePicChange}
          countries={countries}
          setCroppedImage={setCroppedImage}
          userProfilePicUrl={userToEdit?.profilepic_url}
        />

        <DividerSimple />
        <ContactInfo register={register} errors={errors} onChange={handleInputChange} newUser={newUser} />
        <DividerSimple />
        <AdminInfo
          control={control}
          errors={errors}
          onChange={handleInputChange}
          newUser={newUser}
          accountTypeListBoxData={accountTypeListBoxData}
          selectedAccountType={selectedAccountType}
          setSelectedAccountType={onAccountTypeChange}
          pwRecipientListBoxData={pwRecipientListBoxData}
          selectedPwRecipient={selectedPwRecipient}
          setSelectedPwRecipient={onPwRecipientChange}
          accountStatusListBoxData={accountStatusListBoxData}
          selectedAccountStatus={selectedAccountStatus}
          setSelectedAccountStatus={onAccountStatusChange}
        />
        <ResetPassword
          userId={userIdFromURL}
          pwRecipientListBoxData={pwRecipientListBoxData}
          onCancel={() => setShowModalResetPassword(false)}
          onSuccess={() => setShowModalResetPassword(false)}
          show={showModalResetPassword}
          hide={() => setShowModalResetPassword(false)}
        />
        <div className="flex flex-row-reverse mt-6">
          <div className="grid grid-cols-6 gap-6 w-full">
            <div className="col-span-6 sm:col-span-2 "></div>
            <div className="col-span-6 sm:col-span-4 flex flex-row-reverse justify-between">
              <div>
                <ButtonPrimary
                  onClick={handleSubmit(onSubmit)}
                  text={userIdFromURL ? 'Update user' : 'Create user'}
                  isLoading={isLoading}
                />
                <ButtonWhite onClick={history.goBack} style="ml-2" text="Cancel" />
              </div>
              <div>
                {!!newUser.data.active && userIdFromURL && (
                  <ButtonPrimary canDelete onClick={() => setShowWarningModal(true)} style="mr-2" text="Deactivate user" />
                )}
                {(ACCOUNT_TYPE.ADMIN.id == currentUser.account_type_id || ACCOUNT_TYPE.ROOT.id == currentUser.account_type_id) &&
                  userIdFromURL && <ButtonPrimary onClick={() => setShowModalResetPassword(true)} text={'Reset Password'} />}
              </div>
            </div>
          </div>
        </div>
        <ModalImage
          image={newPicture}
          title="Crop image"
          show={showModalEditPicture}
          onCancel={setShowModalEditPicture.bind(this, false)}
          onCropComplete={onCropComplete}
          onSuccess={showCroppedImage}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          setCrop={setCrop}
          setRotation={setRotation}
          setZoom={setZoom}
        />
      </Loader>
    </PageWrapper>
  );
};

export default CreateUser;
