import React from 'react';
import ReactSelect from '../../components/Inputs/ReactSelect';
import FolderOpenIcon from '../../components/Icons/FolderOpenIcon';
import PropTypes from 'prop-types';

const MyDocumentHeader = ({ path, onListChange, items, showArrowLeftIcon, loading }) => {
  let rootFolder = path.split(/\/(.*)/);
  return (
    <div className="flex justify-between items-center">
      <div className="flex flex-wrap items-center space-y-4 w-full">
        <div className="flex flex-row justify-between items-center w-full sm:w-max">
          <div className="w-64">
            <ReactSelect
              options={items}
              disabled={loading}
              orderOptions={false}
              inputClass="w-56"
              selectedOptions={[
                {
                  value: rootFolder[0] == 'Organisation documents' ? 0 : 1,
                  label: (
                    <div className="flex items-center">
                      <FolderOpenIcon /> <div className="ml-1">{rootFolder[0]}</div>
                    </div>
                  ),
                },
              ]}
              onChange={onListChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

MyDocumentHeader.prototype = {
  items: PropTypes.array,
  path: PropTypes.string,
  onListChange: PropTypes.func,
  onBackClick: PropTypes.func,
  showArrowLeftIcon: PropTypes.func,
};

export default MyDocumentHeader;
