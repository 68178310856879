import React from 'react';
import PageWrapper from '../../../containers/App/PageWrapper';
import ReactMarkdown from 'react-markdown';
import releaseNotes from '../../../releaseNotes';
import remarkGfm from 'remark-gfm';
import ButtonPrimary from '../../../components/Buttons/ButtonPrimary';
import styles from './ReleaseNotes.module.css';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { ACCOUNT_TYPE } from '../../../helpers/enum/accounts';

const ReleaseNotes = () => {
  const currentUser = useSelector(state => state && state.auth.currentUser);

  const breadCrumbs = [{ name: 'Release notes', href: '/release-notes', current: true }];

  const isStaffOrAbove = currentUser.account_type_id != ACCOUNT_TYPE.USER.id;
  return (
    <PageWrapper pages={breadCrumbs}>
      <section className="space-y-6 max-w-5xl mx-auto">
        {releaseNotes.map((notes, index) => {
          return (
            <div className="rounded-md inline-block bg-white p-6 w-full justify-self-center">
              <div className="flex flex-col-reverse gap-y-2 sm:flex-row justify-between">
                <div className="flex">
                  <span className="text-4xl font-bold place-self-center">v{notes.version}</span>
                  {index == 0 && (
                    <span className="border-2 text-sm rounded-3xl border-thaleria-orange-500 text-thaleria-orange-500 p-1 px-3 font-semibold my-1 ml-5 place-self-center">
                      Latest
                    </span>
                  )}
                </div>
                <span className="text-gray-500 my-auto">{moment(notes.date).format('D MMMM YYYY')}</span>
              </div>
              <ReactMarkdown
                children={isStaffOrAbove ? notes.markdownAdmin : notes.markdownUser}
                components={<ButtonPrimary text="latest" />}
                remarkPlugins={[remarkGfm]}
                className={styles.releasenotes}
              />
            </div>
          );
        })}
      </section>
    </PageWrapper>
  );
};

export default ReleaseNotes;
