import React from 'react';

const Delete = ({ style }) => {
  return (
    <svg
      className={`${style ? style : 'mr-3 h-4 w-4 text-gray-400'}`}
      viewBox="0 0 9 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.642857 8.63566C0.642857 9.22936 1.22143 9.71512 1.92857 9.71512H7.07143C7.77857 9.71512 8.35714 9.22936 8.35714 8.63566V2.15892H0.642857V8.63566ZM2.22429 4.79279L3.13071 4.03177L4.5 5.176L5.86286 4.03177L6.76929 4.79279L5.40643 5.93702L6.76929 7.08124L5.86286 7.84226L4.5 6.69803L3.13714 7.84226L2.23071 7.08124L3.59357 5.93702L2.22429 4.79279ZM6.75 0.539729L6.10714 0H2.89286L2.25 0.539729H0V1.61919H9V0.539729H6.75Z"
        fill="gray"
      />
    </svg>
  );
};

export default Delete;
