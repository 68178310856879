import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router';
import StaffContractDetails from '../../../../containers/Contracts/StaffContracts/StaffContractDetails';
import AddStaffContract from '../../../../containers/Contracts/StaffContracts/AddOrEditStaffContract';
import PageWrapper from '../../../../containers/App/PageWrapper';
import { showSuccessNotification } from '../../../../store/app/actions';
import useApi from '../../../../hooks/useApi';

export default function StaffContract() {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  const {
    contractTypes: { getAllContractTypes },
    countries: { getAllCountries },
    staffContracts: { getContractDetails, createStaffContract, editStaffContract },
    users: { getAllUsers },
  } = useApi();

  const [pages, setPages] = useState();

  const pathNameArray = location.pathname.split('/');
  const displayPage = pathNameArray[pathNameArray.length - 1];

  const updateCountries = async () => {
    getAllCountries().then(res => setCountries(res));
  };

  useEffect(() => {
    getAllUsers().then(d => setUsers(d));
    updateCountries();
    getAllContractTypes().then(res => setContractTypes(res));
    if (id && id !== 'create') {
      getContractDetails(id).then(d => {
        setStaffContract(d);
      });
    } else {
      setStaffContract(null);
    }
  }, [id]);

  const [users, setUsers] = useState([]);
  const [countries, setCountries] = useState([]);

  const [contractTypes, setContractTypes] = useState([]);
  const [staffContract, setStaffContract] = useState(null);

  const handleClick = form => {
    if (id === 'create') {
      Object.keys(form).forEach(key => {
        if (form[key] === '') form[key] = null;
      });
      createStaffContract(form).then(() => {
        dispatch(showSuccessNotification('Staff contract was created.'));
        history.push('/admin-panel/contracts/staff-contracts');
      });
    } else {
      editStaffContract(id, form).then(data => {
        Object.keys(form).forEach(key => {
          if (form[key] === '') form[key] = null;
        });
        data ? dispatch(showSuccessNotification('Staff contract has been updated')) : alert('Error while saving the details');
        setStaffContract(data);
        history.push('/admin-panel/contracts/staff-contracts/' + id);
      });
    }
  };

  const handleEditClick = () => {
    history.push(`/admin-panel/contracts/staff-contracts/${id}/edit`);
  };

  useEffect(() => {
    if (!id) return;
    const _pages = [
      { name: 'Contracts Manager', href: '/admin-panel/contracts/purchase-orders', current: false },
      { name: 'Staff Contracts', href: '/admin-panel/contracts/staff-contracts', current: false },
    ];
    let additionalPages = [];

    if (displayPage === 'edit') {
      if (!staffContract) return;
      additionalPages = [
        {
          name: staffContract?.contract_ref,
          href: `/admin-panel/contracts/staff-contracts/${staffContract.id}`,
        },
        { name: 'Edit', href: `/admin-panel/contracts/staff-contracts/${staffContract.id}/edit` },
      ];
    } else if (displayPage === 'create') {
      additionalPages = [
        {
          name: 'Create',
          href: `/admin-panel/contracts/staff-contracts/create`,
        },
      ];
    } else {
      if (!staffContract) return;
      additionalPages = [
        {
          name: staffContract.contract_ref,
          href: `/admin-panel/contracts/staff-contracts/${staffContract.id}`,
        },
      ];
    }
    additionalPages.forEach(page => _pages.push(page));
    _pages[_pages.length - 1].current = true;
    setPages(_pages);
  }, [location.pathname, staffContract]);

  return (
    <PageWrapper pages={pages}>
      {displayPage === 'create' || displayPage === 'edit' ? (
        <AddStaffContract
          staffContract={staffContract}
          contractTypes={contractTypes}
          countries={countries}
          users={users}
          id={id}
          handleSave={handleClick}
          updateCountries={updateCountries}
        />
      ) : (
        staffContract && <StaffContractDetails staffContract={staffContract} handleEditClick={handleEditClick} />
      )}
    </PageWrapper>
  );
}
