import React, { useEffect, useState, useMemo } from 'react';
import {
  LeaveRequestStatus,
  LeaveRequestStatusLabels,
  LeaveRequestTypeLabel,
  UserLeaveRequestTypeLabel,
} from '../../helpers/enum/leaveRequest';
import { useLocation } from 'react-router-dom';
import { useQuery } from '../../hooks/useQuery';
import TabList from '../TabList/TabList';
import LeaveRequestItem from './LeaveRequestItem';
import useApi from '../../hooks/useApi';
import { useSelector } from 'react-redux';

const MyLeaveRequestTabList = () => {
  const location = useLocation();
  const hash = location.hash;

  const currentUser = useSelector(state => state.auth.currentUser);

  const {
    leaveRequests: { getAllMyLeaveRequests },
  } = useApi();
  let query = useQuery();
  const page = Number(query.get('page')) || 1;

  const [leaveRequests, setLeaveRequests] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAllMyLeaveRequests().then(res => {
      setLoading(false);
      setLeaveRequests(res);
    });
  }, []);

  const leaveRequestsData = useMemo(() => {
    let data = [];
    if (leaveRequests && leaveRequests.length) {
      leaveRequests.forEach(leaveRequest =>
        data.push({
          id: leaveRequest.id,
          type: UserLeaveRequestTypeLabel(leaveRequest.request_type, currentUser?.StaffContracts[0]?.contract_type_id),
          start: leaveRequest.start_date,
          end: leaveRequest.end_date,
          deductedLeaveDays: leaveRequest.deducted_leave_days,
          statusLabel: LeaveRequestStatusLabels[leaveRequest.status],
          statusId: leaveRequest.status,
        }),
      );
    }
    return data;
  }, [leaveRequests]);

  const nbOfPages = useMemo(() => {
    let itemCount = 0;
    leaveRequestsData?.forEach(lr => {
      if (hash) {
        if (hash.includes(lr.statusId)) itemCount += 1;
      } else {
        if (lr.statusId === 0) itemCount += 1;
      }
    });

    let pageSize = 5;
    if (itemCount <= pageSize) return 1;
    else return Math.ceil(itemCount / pageSize);
  }, [leaveRequestsData, hash]);

  const tabs = useMemo(() => {
    let count = {
      [LeaveRequestStatus.pending]: 0,
      [LeaveRequestStatus.submitted]: 0,
      [LeaveRequestStatus.actionRequired]: 0,
      [LeaveRequestStatus.approved]: 0,
      [LeaveRequestStatus.denied]: 0,
      [LeaveRequestStatus.deleted]: 0,
    };

    leaveRequestsData?.forEach(el => {
      count[el.statusId] += 1;
    });

    return [
      {
        name: LeaveRequestStatusLabels[LeaveRequestStatus.pending],
        href: '/leave-request',
        count: count[LeaveRequestStatus.pending],
        current: !hash,
      },
      {
        name: LeaveRequestStatusLabels[LeaveRequestStatus.submitted],
        href: '#' + LeaveRequestStatus.submitted,
        count: count[LeaveRequestStatus.submitted],
        current: hash.includes(LeaveRequestStatus.submitted),
      },
      {
        name: LeaveRequestStatusLabels[LeaveRequestStatus.actionRequired],
        href: '#' + LeaveRequestStatus.actionRequired,
        count: count[LeaveRequestStatus.actionRequired],
        current: hash.includes(LeaveRequestStatus.actionRequired),
      },
      {
        name: LeaveRequestStatusLabels[LeaveRequestStatus.approved],
        href: '#' + LeaveRequestStatus.approved,
        count: count[LeaveRequestStatus.approved],
        current: hash.includes(LeaveRequestStatus.approved),
      },
      {
        name: LeaveRequestStatusLabels[LeaveRequestStatus.denied],
        href: '#' + LeaveRequestStatus.denied,
        count: count[LeaveRequestStatus.denied],
        current: hash.includes(LeaveRequestStatus.denied),
      },
      {
        name: LeaveRequestStatusLabels[LeaveRequestStatus.deleted],
        href: '#' + LeaveRequestStatus.deleted,
        // count: count[LeaveRequestStatus.deleted],
        current: hash.includes(LeaveRequestStatus.deleted),
      },
    ];
  }, [leaveRequestsData, hash]);

  const renderLeaveRequestsList = () => {
    if (!leaveRequestsData?.length) return <li className="mt-8">No leave requests to show</li>;
    const filteredLeaveRequests = leaveRequestsData.filter(lr => {
      if (hash) {
        if (hash.includes(lr.statusId)) return lr;
      } else {
        if (lr.statusId === 0) return lr;
      }
    });
    const paginatedLRs = filteredLeaveRequests.slice(((page || 1) - 1) * 5, (page || 1) * 5);

    const leaveRequestItems = paginatedLRs.map(lr => <LeaveRequestItem leaveRequest={lr} />);

    if (leaveRequestItems?.length) return leaveRequestItems;
    else return <li className="mt-8">No leave requests to show</li>;
  };

  return (
    <div>
      <TabList
        page={page}
        isLoading={loading}
        renderList={renderLeaveRequestsList}
        tabs={tabs}
        pageCount={nbOfPages}
        href="/leave-request"
      />
    </div>
  );
};

export default MyLeaveRequestTabList;
