import React from 'react';
import PageWrapperV2 from '../../../containers/App/PageWrapper';
import ButtonPrimary from '../../../components/Buttons/ButtonPrimary';
import { useHistory } from 'react-router-dom';
import MyInvoicesTabList from '../../../containers/MyInvoices/MyInvoicesTabList';

const MyInvoices = () => {
  const history = useHistory();

  const pages = [{ name: 'My invoices', href: '/my-invoices', current: true }];

  return (
    <PageWrapperV2 pages={pages}>
      <MyInvoicesTabList />
      <ButtonPrimary style="mt-4" text="Upload invoice" onClick={() => history.push('/my-invoices/create')} />
    </PageWrapperV2>
  );
};

export default MyInvoices;
