import React from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import './InputDate.css';
import Error from '../../Error/Error';
import { getDay } from 'date-fns';
import isSameDay from 'date-fns/isSameDay';
import FileIcon from '../../Icons/FileIcon';

const InputDate = ({
  onChange,
  className,
  label,
  error,
  disabled,
  selected,
  placeholder,
  minDate,
  maxDate,
  disableWeekends = false,
  onlyMonth,
  disabledDates,
}) => {
  const changeHandler = e => {
    if (e) {
      let UTCDate = new Date(Date.UTC(e.getFullYear(), e.getMonth(), e.getDate()));
      onChange(UTCDate);
    } else {
      onChange(null);
    }
  };

  const filterCustomDates = (date, disableWeekends, disabledDates) => {
    // Check if the date is a weekend (Saturday or Sunday)
    const isWeekend = date.getDay() === 0 || date.getDay() === 6;

    // Check if the date is in the additional filtered dates array
    let isDisabledDate;
    if (disabledDates?.length) {
      isDisabledDate = disabledDates.some(disabledDate => isSameDay(date, new Date(disabledDate)));
    }

    // Combine the conditions based on the props
    if (disableWeekends && isWeekend) {
      return false;
    }

    if (disabledDates && isDisabledDate) {
      return false;
    }

    // Allow all other dates
    return true;
  };

  return (
    <div className={`overflow-hidden w-full ${className}`}>
      <div className="flex">
        {label && <label className="block text-sm font-medium text-gray-700 mr-2 mb-1">{label}</label>}
        {error && <Error message={error} />}
      </div>
      <div className=" z-0">
        <DatePicker
          disabled={disabled}
          wrapperClassName={error ? `errorDatepicker ${disabled && 'disabled'}` : `datePicker border ${disabled && 'disabled'}`}
          selected={selected}
          onChange={changeHandler}
          dateFormat={onlyMonth ? 'MMMM yyyy' : 'dd/MM/yyyy'}
          showMonthYearPicker={onlyMonth}
          placeholderText={placeholder}
          minDate={minDate}
          maxDate={maxDate}
          filterDate={date => filterCustomDates(date, disableWeekends, disabledDates)}
          popperModifiers={{
            // offset: {
            //   enabled: true,
            //   offset: '5px, 10px',
            // },
            preventOverflow: {
              enabled: true,
              escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
              boundariesElement: 'viewport',
            },
          }}
        />
      </div>
    </div>
  );
};

InputDate.prototype = {
  onChange: PropTypes.func,
  selected: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
};

export default InputDate;
