import { ExclamationCircleIcon } from '@heroicons/react/outline';
import React, { useEffect, useState } from 'react';
import InputSimple from '../Inputs/InputSimple';

export function DescriptionListItem({ tag, data, optional, edit, editedData, onChange, errors }) {
  const [error, setError] = useState({});

  useEffect(() => {
    if (!errors?.length) return setError({});
    let myError = errors.find(error => error.field === tag);
    if (myError) setError(myError);
    if (!myError) setError({});
  }, [errors]);

  return (
    <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4 items-center">
      <dt className="text-sm font-medium text-gray-500">{tag}</dt>
      <dd className="mt-1 flex flex-col sm:flex-row text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        {edit ? (
          <div className="w-full">
            {error?.msg && (
              <span className="text-red-400 flex">
                {' '}
                <ExclamationCircleIcon className="h-5 w-5 ml-2 mr-1" /> {error.msg}
              </span>
            )}
            <InputSimple value={editedData === undefined ? data : editedData} onChange={onChange} />
          </div>
        ) : (
          <>
            <span className="mr-auto w-full">{data || '-'}</span>
            <span className="ml-auto">{optional}</span>
          </>
        )}
      </dd>
    </div>
  );
}
