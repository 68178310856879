import React, { useState, useMemo, useEffect } from 'react';
import InputSimple from '../../../components/Inputs/InputSimple';
import InputDate from '../../../components/Inputs/InputDate/InputDate';
import InputDateRange from '../../../components/Inputs/InputDate/InputDateRange';
import TextArea from '../../../components/Inputs/TextArea';
import ReactSelect from '../../../components/Inputs/ReactSelect';
import PlusMedium from '../../../components/Icons/PlusMediumIcon';
import ButtonWhite from '../../../components/Buttons/ButtonWhite';
import { XCircleIcon } from '@heroicons/react/outline';
import AddLineItemModal from './AddLineItemModal';
import AccountsReceivablePDFPreview from './AccountsReceivablePDFPreview';
import ButtonPrimary from '../../../components/Buttons/ButtonPrimary';
import { DotsCircleHorizontalIcon } from '@heroicons/react/outline';
import InputRadio from '../../../components/Inputs/InputRadio';
import Error from '../../../components/Error/Error';
import { lineItemTypesV2 } from '../../../helpers/enum/lineItemTypes';
import Checkbox from '../../../components/Checkbox/Checkbox';
import { format, isSameDay } from 'date-fns';
import formatCurrency from '../../../utils/formatCurrency';
import formatNumber from '../../../utils/formatNumber';
import * as countryID from '../../../helpers/enum/CountryIDs';
import clientTypes from '../../../helpers/enum/clientTypes';
import vatDisclaimers from '../../../helpers/vatDisclaimers';
import EditLineItemDecimalPlaces from '../../../components/Modals/EditLineItemDecimalPlaces';
import getDecimalPlaces from '../../../helpers/getDecimalPlaces';

function CreateAccountsReceivableForm({
  invoiceData,
  setInvoiceData,
  clientOptions,
  lineItems,
  setLineItems,
  bankAccountOptions,
  formErrors,
  handleAddAccountsReceivable,
  isLoading,
}) {
  const [showAddLineItemModal, setShowAddLineItemModal] = useState(false);
  const [selectedLineItemIndex, setSelectedLineItemIndex] = useState(null);
  const [paymentTermOptions, setPaymentTermOptions] = useState([
    { label: '30 days', value: 30, selected: true },
    { label: '60 days', value: 60, selected: false },
    { label: 'Custom', value: 'custom', selected: false },
  ]);
  const today = new Date();
  const [showPreview, setShowPreview] = useState(false);
  const [mergedItemsMains, setMergedItemsMains] = useState([]);
  const [showEditDecimalPlaces, setShowEditDecimalPlaces] = useState(false);

  useEffect(() => {
    //applies correct vat disclaimer depending on client
    if (invoiceData.client_id) {
      const client = clientOptions.find(c => c.value === invoiceData.client_id);

      if (client.clientDetails.country_id !== countryID.belgium && client.clientDetails.type === clientTypes.numbers.Private) {
        setInvoiceData(prev => ({
          ...prev,
          metadata: {
            ...prev.metadata,
            vat_disclaimer: vatDisclaimers.default,
          },
        }));
      } else {
        setInvoiceData(prev => ({
          ...prev,
          metadata: {
            ...prev.metadata,
            vat_disclaimer: null,
          },
        }));
      }
    }
  }, [invoiceData.client_id]);

  const paymentTermChangeHandler = e => {
    const updated = paymentTermOptions.map(el =>
      el.label === e.target.value ? { ...el, selected: true } : { ...el, selected: false },
    );
    const selected = updated.find(el => el.selected);
    setPaymentTermOptions(updated);
    if (selected.value !== 'custom') {
      setInvoiceData(prev => ({
        ...prev,
        due_date: new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate() + selected.value)),
      }));
    }
  };

  const editInvoiceMetadata = (property, value) => {
    if (property === 'to') {
      let obj = { ...value };
      //if changing client, I now need to remove country_id and type properties
      //these are only used to automatically set the vat disclaimer and should not be added to the invoice's metadata
      delete obj?.country_id;
      delete obj?.type;
      return setInvoiceData(prev => ({ ...prev, metadata: { ...prev.metadata, [property]: obj } }));
    } else {
      return setInvoiceData(prev => ({ ...prev, metadata: { ...prev.metadata, [property]: value } }));
    }
  };

  const editInvoiceData = (property, value) => {
    let newData = { ...invoiceData, [property]: value };
    if (property === 'issue_date') {
      const paymentTerm = paymentTermOptions.find(el => el.selected);
      if (paymentTerm.value !== 'custom')
        newData.due_date = new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate() + paymentTerm.value));
    }
    return setInvoiceData(newData);
  };

  const handleLineItemChange = (property, value, lineItemIndex) => {
    const updatedLineItems = lineItems.map((l, i) => {
      if (property === 'dateRange') {
        let periodString = '';

        if (value[0]) periodString = periodString + `${format(value[0], 'dd/MM/yy')}`;
        if (value[1] && !isSameDay(value[0], value[1])) periodString = periodString + ` - ${format(value[1], 'dd/MM/yy')}`;
        return i === lineItemIndex
          ? {
              ...l,
              ['from_date']: value[0],
              ['to_date']: value[1],
              ['metadata']: { ...l.metadata, period: periodString },
            }
          : l;
      } else {
        return i === lineItemIndex ? { ...l, [property]: value } : l;
      }
    });
    return setLineItems(updatedLineItems);
  };

  const handleShowAddLineItemModal = index => {
    setSelectedLineItemIndex(index);
    setShowAddLineItemModal(true);
  };

  const handleToggleReferences = (field, value) => {
    const updatedInvoiceMetadata = {
      ...invoiceData.metadata,
      showReferences: { ...invoiceData.metadata.showReferences, [field]: value },
    };

    return setInvoiceData(prev => ({ ...prev, metadata: updatedInvoiceMetadata }));
  };

  const handleToggleMergeLines = value => {
    if (value) {
      let lineItemWithMoreThan2DecimalPlaces = false;
      lineItems.forEach(li => {
        if (
          li.type !== lineItemTypesV2.accountsReceivable.numbers.allocationsToBeInvoiced &&
          li.type !== lineItemTypesV2.accountsReceivable.numbers.customAllocationsToBeInvoiced &&
          li.type !== lineItemTypesV2.accountsReceivable.numbers.customProjectAllocations &&
          li.type !== lineItemTypesV2.accountsReceivable.numbers.projectAllocations
        )
          return;
        if (getDecimalPlaces(Number(li?.units)) > 2) lineItemWithMoreThan2DecimalPlaces = true;
      });

      if (lineItemWithMoreThan2DecimalPlaces) {
        return alert(
          'To merge lines and avoid number rounding issues, set all relevant lines to 2 decimal places and try again please.',
        );
      }
    }

    const updatedInvoiceMetadata = {
      ...invoiceData.metadata,
      merge_lines: value,
    };
    if (value) {
      //if true, reorder lineItems by staff order/from_date
      const reOrderedLineItems = [...lineItems].sort((a, b) => {
        // First, group by staff_order_id
        if (a.staff_order_id !== b.staff_order_id) {
          return a.staff_order_id - b.staff_order_id;
        }

        // If staff_order_id is the same, sort by from_date
        if (a.from_date === null && b.from_date === null) {
          return 0; // Both dates are null, so they are equal
        } else if (a.from_date === null) {
          return 1; // Move null date to the end
        } else if (b.from_date === null) {
          return -1; // Move null date to the end
        }

        // Compare the dates
        return new Date(a.from_date).getTime() - new Date(b.from_date).getTime();
      });
      setLineItems(reOrderedLineItems);
      //mergedItemsMains allows us to identify which line's description corresponds to the "combined" line we see in the pdf
      const mergedItemsMains = [];
      reOrderedLineItems.forEach(item => {
        if (!mergedItemsMains.find(i => i.staff_order_id === item.staff_order_id))
          mergedItemsMains.push({ id: item.id, staff_order_id: item.staff_order_id });
      });
      setMergedItemsMains(mergedItemsMains);
    }
    return setInvoiceData(prev => ({ ...prev, metadata: updatedInvoiceMetadata }));
  };

  const handleToggleHidePeriod = value => {
    const updatedInvoiceMetadata = {
      ...invoiceData.metadata,
      hide_period: value,
    };

    return setInvoiceData(prev => ({ ...prev, metadata: updatedInvoiceMetadata }));
  };

  const isAllocationsOrMilestoneLine = type => {
    let isAllocationsOrMilestoneLine = false;
    if (
      type === lineItemTypesV2.accountsReceivable.numbers.customProjectAllocations ||
      type === lineItemTypesV2.accountsReceivable.numbers.projectAllocations ||
      type === lineItemTypesV2.accountsReceivable.numbers.customAllocationsToBeInvoiced ||
      type === lineItemTypesV2.accountsReceivable.numbers.allocationsToBeInvoiced ||
      type === lineItemTypesV2.accountsReceivable.numbers.milestone ||
      type === lineItemTypesV2.accountsReceivable.numbers.milestoneToBeInvoiced
    )
      isAllocationsOrMilestoneLine = true;
    return isAllocationsOrMilestoneLine;
  };

  const isAllocationLineWithDaysUoM = item => {
    let isAllocationLineWithDaysUoM = false;
    if (
      (item.type === lineItemTypesV2.accountsReceivable.numbers.customProjectAllocations ||
        item.type === lineItemTypesV2.accountsReceivable.numbers.projectAllocations ||
        item.type === lineItemTypesV2.accountsReceivable.numbers.customAllocationsToBeInvoiced ||
        item.type === lineItemTypesV2.accountsReceivable.numbers.allocationsToBeInvoiced) &&
      item.uom !== 'hours' &&
      //if merge_lines is ON, need to disable being able to modify decimal_places, they should be always 2.
      !invoiceData?.metadata?.merge_lines
    )
      isAllocationLineWithDaysUoM = true;
    return isAllocationLineWithDaysUoM;
  };

  const canMergeLines = useMemo(() => {
    const orderMap = new Map();

    for (const item of lineItems) {
      const { staff_order_id, uom, type } = item;

      // Only consider line items with the specified types
      if (
        ![
          lineItemTypesV2.accountsReceivable.numbers.allocationsToBeInvoiced,
          lineItemTypesV2.accountsReceivable.numbers.customAllocationsToBeInvoiced,
          lineItemTypesV2.accountsReceivable.numbers.customProjectAllocations,
          lineItemTypesV2.accountsReceivable.numbers.projectAllocations,
        ].includes(type)
      ) {
        continue;
      }

      if (!orderMap.has(staff_order_id)) {
        orderMap.set(staff_order_id, uom);
      } else if (orderMap.get(staff_order_id) !== uom) {
        if (invoiceData.metadata.merge_lines) handleToggleMergeLines(false);
        return false;
      }
    }

    return true;
  }, [lineItems]);

  const disableDescriptionForMergedLines = item => {
    //if line item is from an allocation and user has checked to merge lines,
    //check if the line is the main one that can have it's description edited
    let result = false;
    if (
      invoiceData.metadata.merge_lines &&
      (item.type === lineItemTypesV2.accountsReceivable.numbers.allocationsToBeInvoiced ||
        item.type === lineItemTypesV2.accountsReceivable.numbers.customAllocationsToBeInvoiced ||
        item.type === lineItemTypesV2.accountsReceivable.numbers.customProjectAllocations ||
        item.type === lineItemTypesV2.accountsReceivable.numbers.projectAllocations)
    ) {
      result = !mergedItemsMains.find(i => i.id === item.id);
    }
    return result;
  };

  const saveDecimalPlaces = decimalPlaces => {
    const newLineItems = lineItems.map((li, i) => {
      if (i === showEditDecimalPlaces) {
        const newUnits = formatNumber(li?.metadata?.hours / 8, false, decimalPlaces);
        let isNegativeAmount = li.type === lineItemTypesV2.accountsPayable.numbers.projectAllocationCreditNoteAdjustment;
        const total = newUnits * Number(li.price) * (isNegativeAmount ? -1 : 1);
        const vat = newUnits * Number(li.price) * (Number(li?.vat_rate) / 100);
        const totalAndVat = total + vat;
        return {
          ...li,
          units: newUnits,
          total: total,
          vat: vat,
          total_and_vat: totalAndVat,
          metadata: {
            ...li.metadata,
            decimal_places: decimalPlaces,
          },
        };
      }
      return li;
    });
    setLineItems(newLineItems);
    setShowEditDecimalPlaces(false);
  };

  return (
    <div className="flex-1 overflow-auto px-4 pb-4">
      <EditLineItemDecimalPlaces
        show={showEditDecimalPlaces !== false && !isNaN(showEditDecimalPlaces) ? true : false}
        setShow={setShowEditDecimalPlaces}
        lineItem={lineItems[showEditDecimalPlaces]}
        onConfirm={value => saveDecimalPlaces(value)}
      />
      <AccountsReceivablePDFPreview
        setShowPreview={setShowPreview}
        showPreview={showPreview}
        invoiceData={invoiceData}
        lineItems={lineItems}
        canDownload={false}
      />
      <AddLineItemModal
        showAddLineItemModal={showAddLineItemModal}
        setShowAddLineItemModal={setShowAddLineItemModal}
        setLineItems={setLineItems}
        clientId={invoiceData.client_id}
        selectedLineItemIndex={selectedLineItemIndex}
        setSelectedLineItemIndex={setSelectedLineItemIndex}
        lineItems={lineItems}
      />
      {/* Left side: Form */}
      <div className="w-full flex justify-between py-4">
        <span className="text-lg text-gray-700">Create invoice</span>
        <ButtonWhite text="Show preview" onClick={() => setShowPreview(true)} />
      </div>
      <form className="grid grid-cols-2 gap-4">
        <div className="col-span-2 md:col-span-1">
          <InputSimple
            label="Invoice number*"
            type="number"
            error={formErrors.find(e => e.field === 'invoice_number')?.msg}
            value={invoiceData.invoice_number}
            onChange={e => editInvoiceData('invoice_number', e.target.value)}
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <InputSimple
            label="Subject*"
            tooltip={true}
            tooltipMsg={
              'This field will update automatically each time the line items are updated. Whenever possible, it will add the Sales FWC descriptions to the subject. If none is available, the default "Thaleria Services" subject will appear.'
            }
            error={formErrors.find(e => e.field === 'subject')?.msg}
            value={invoiceData.metadata.subject}
            onChange={e => {
              editInvoiceMetadata('subject', e.target.value);
            }}
          />
        </div>
        <div className="col-span-2 md:col-span-1 gap-x-1 flex">
          <InputDate
            label="Issue date*"
            selected={invoiceData.issue_date}
            error={formErrors.find(e => e.field === 'issue_date')?.msg}
            onChange={date => editInvoiceData('issue_date', date)}
          />
          <InputDate
            label="Due date*"
            error={formErrors.find(e => e.field === 'due_date')?.msg}
            selected={invoiceData.due_date}
            disabled={!paymentTermOptions.find(el => el.value === 'custom' && el.selected)}
            onChange={date => editInvoiceData('due_date', date)}
          />
        </div>
        <div className="col-span-2 md:col-span-1 flex">
          <InputRadio
            label="Payment term"
            options={paymentTermOptions.map(el => el.label)}
            selectedValue={paymentTermOptions.find(el => el.selected).label}
            onChange={paymentTermChangeHandler}
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <ReactSelect
            label="Client*"
            error={formErrors.find(e => e.field === 'client_id')?.msg}
            selectedOptionsIds={[invoiceData.client_id]}
            onChange={e => {
              editInvoiceData('client_id', e.value);
              editInvoiceMetadata('to', e.clientDetails);
              setLineItems([]);
            }}
            options={clientOptions}
            isSearchable
          />
        </div>
        <div className="col-span-2 md:col-span-1 flex items-center space-x-4">
          <ReactSelect
            label="Bank account*"
            error={formErrors.find(e => e.field === 'bank_account_id')?.msg}
            selectedOptionsIds={[invoiceData.bank_account_id]}
            onChange={e => {
              editInvoiceData('bank_account_id', e.value);
              editInvoiceMetadata('footer_data', {
                ...invoiceData.metadata.footer_data,
                iban: e.iban,
                bic_swift: e.bic_swift,
              });
            }}
            options={bankAccountOptions}
            disabled={!lineItems.find(l => l?.partner_contract_id)}
            isSearchable
          />
        </div>
        <div className="col-span-2">
          <TextArea
            rows={3}
            label="Header comment*"
            error={formErrors.find(e => e.field === 'header_comment')?.msg}
            onChange={e => editInvoiceMetadata('header_comment', e.target.value)}
            value={invoiceData.metadata.header_comment}
          />
        </div>
        {/* Line items */}
        <div className="col-span-2">
          <div className="flex w-full justify-between items-center">
            <div className="flex">
              <label className="block text-md font-medium text-gray-700 mr-2 mb-2">Line items*</label>
              {formErrors.find(e => e.field === 'lineItems') ? (
                <Error message={formErrors.find(e => e.field === 'lineItems')?.msg} />
              ) : (
                ''
              )}
            </div>
          </div>
          {lineItems.length ? (
            <>
              <div className="w-full flex flex-col mb-2 gap-y-2">
                <div className={`gap-x-2 mb-1 ${lineItems?.length ? 'flex justify-between' : 'hidden'} items-center`}>
                  <label className="text-sm font-medium text-gray-700">Contract references</label>
                  <div className="flex space-x-4">
                    <Checkbox
                      className="w-[105px]"
                      horizontal
                      smallText
                      labelTitle
                      value={invoiceData.metadata?.showReferences?.partnerContract}
                      onChange={e => handleToggleReferences('partnerContract', e.target.checked)}
                      title="Sales contract"
                    />
                    <Checkbox
                      className="w-[140px]"
                      horizontal
                      smallText
                      labelTitle
                      value={invoiceData.metadata?.showReferences?.frameworkContract}
                      onChange={e => handleToggleReferences('frameworkContract', e.target.checked)}
                      title="Framework contract"
                    />
                    <Checkbox
                      className="w-[90px]"
                      horizontal
                      smallText
                      labelTitle
                      value={invoiceData.metadata?.showReferences?.salesOrder}
                      onChange={e => handleToggleReferences('salesOrder', e.target.checked)}
                      title="Sales order"
                    />
                    <Checkbox
                      className="w-[120px]"
                      horizontal
                      smallText
                      labelTitle
                      value={invoiceData.metadata?.showReferences?.specificContract}
                      onChange={e => handleToggleReferences('specificContract', e.target.checked)}
                      title="Specific contract"
                    />
                  </div>
                </div>
              </div>
              <div className="w-full mb-2">
                <div className="grid gap-1 grid-cols-9" style={{ gridTemplateColumns: 'repeat(16, minmax(0, 1fr))' }}>
                  <label className="block text-sm font-medium text-gray-700 col-span-5">Description</label>
                  <label className="block text-sm font-medium text-gray-700 col-span-2">Type</label>
                  <label className="block text-sm font-medium text-gray-700 col-span-2">Period</label>
                  <label className="block text-sm font-medium text-gray-700 col-span-1">Quantity</label>
                  <label className="block text-sm font-medium text-gray-700 col-span-2">UoM</label>
                  <label className="block text-sm font-medium text-gray-700 col-span-2">Price</label>
                  <label className="block text-sm font-medium text-gray-700 col-span-2">VAT</label>
                </div>
                {lineItems.map((item, index) => {
                  return (
                    <div className="grid gap-1 group relative" style={{ gridTemplateColumns: 'repeat(16, minmax(0, 1fr))' }}>
                      <div className="col-span-5">
                        <TextArea
                          value={item.description}
                          rows={1}
                          onChange={e => handleLineItemChange('description', e.target.value, index)}
                          disabled={disableDescriptionForMergedLines(item)}
                        />
                      </div>
                      <div className="col-span-2">
                        <InputSimple
                          label=""
                          value={lineItemTypesV2.accountsReceivable.strings[item?.type]}
                          type="text"
                          disabled
                          hideLockIcon
                        />
                      </div>
                      <div className="col-span-2 mt-1 ">
                        <InputDateRange
                          startDate={item?.from_date ? new Date(item.from_date) : null}
                          endDate={item?.to_date ? new Date(item.to_date) : null}
                          onChange={dateArray => {
                            (dateArray[0] !== item?.from_date || dateArray[1] !== item?.to_date) &&
                              handleLineItemChange('dateRange', dateArray, index);
                          }}
                          disabled={isAllocationsOrMilestoneLine(item?.type)}
                        />
                      </div>
                      <div className="col-span-1">
                        <input
                          readOnly={isAllocationsOrMilestoneLine(item?.type)}
                          type={isAllocationsOrMilestoneLine(item?.type) ? 'text' : 'number'}
                          label=""
                          value={formatNumber(item.units, true, 6)}
                          min={1}
                          onChange={e => handleLineItemChange('units', e.target.value, index)}
                          onClick={isAllocationLineWithDaysUoM(item) ? () => setShowEditDecimalPlaces(index) : undefined}
                          className={`
                               ${
                                 isAllocationsOrMilestoneLine(item?.type) &&
                                 `bg-gray-200 ${!isAllocationLineWithDaysUoM(item) && 'cursor-not-allowed'}`
                               } ${
                            isAllocationLineWithDaysUoM(item) &&
                            'text-blue-500 hover:text-blue-300 hover:underline hover:cursor-pointer'
                          } shadow-sm focus:ring-thaleria-orange-600 focus:border-thaleria-orange-600 mt-1 block w-full sm:text-sm border p-2 h-[38px] border-gray-300 rounded-md`}
                        />
                      </div>
                      <div className="col-span-2">
                        <InputSimple
                          value={item?.uom}
                          hideLockIcon
                          label=""
                          disabled={isAllocationsOrMilestoneLine(item?.type)}
                          type="text"
                          onChange={e => handleLineItemChange('uom', e.target.value, index)}
                        />
                      </div>
                      <div className="col-span-2">
                        <InputSimple
                          type="number"
                          disabled={isAllocationsOrMilestoneLine(item?.type)}
                          value={item.price}
                          label=""
                          hideLockIcon
                          onChange={e => handleLineItemChange('price', e.target.value, index)}
                        />
                      </div>
                      <div className={`col-span-1`}>
                        <InputSimple
                          type="number"
                          disabled={isAllocationsOrMilestoneLine(item?.type)}
                          value={item.vat_rate}
                          label=""
                          hideLockIcon
                          onChange={e => handleLineItemChange('vat_rate', e.target.value, index)}
                        />
                      </div>
                      <div className={`col-span-1 space-x-1 flex items-center mx-auto`}>
                        <DotsCircleHorizontalIcon
                          className="h-7 w-7 cursor-pointer text-gray-300 hover:text-thaleria-orange-700"
                          onClick={() => handleShowAddLineItemModal(index)}
                        />
                        <XCircleIcon
                          className="h-7 w-7 cursor-pointer text-gray-300 hover:text-red-400"
                          onClick={() => setLineItems(prev => prev.filter((e, i) => i !== index))}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <div className="col-span-2 text-sm text-gray-400 my-2">No line items added yet.</div>
          )}
          <div className="flex gap-x-4 justify-between w-full">
            <ButtonWhite
              text="Add line"
              disabled={!invoiceData.client_id}
              icon={<PlusMedium style="w-4 h-4" onClick={null} />}
              onClick={() => handleShowAddLineItemModal(null)}
            />
            <div className="flex space-x-4 justify-between">
              <Checkbox
                title="Merge lines"
                horizontal
                value={invoiceData.metadata?.merge_lines}
                onChange={e => handleToggleMergeLines(e.target.checked)}
                disabled={!canMergeLines}
                labelTitle
                smallText
              />
              <Checkbox
                title="Hide period"
                horizontal
                value={invoiceData.metadata?.hide_period}
                onChange={e => handleToggleHidePeriod(e.target.checked)}
                labelTitle
                smallText
              />
            </div>
          </div>
        </div>
        <div className="col-span-2">
          <TextArea
            rows={2}
            label="VAT disclaimer"
            onChange={e => editInvoiceMetadata('vat_disclaimer', e.target.value)}
            value={invoiceData.metadata?.vat_disclaimer || ''}
          />
        </div>
        <div className="col-span-2">
          <TextArea
            rows={2}
            label="Footer comment*"
            error={formErrors.find(e => e.field === 'footer_comment')?.msg}
            onChange={e => editInvoiceMetadata('footer_comment', e.target.value)}
            value={invoiceData.metadata.footer_comment}
          />
        </div>

        <div className="col-span-2 flex justify-end items-center gap-x-8">
          <div className="flex justify-between space-x-4 mr-8 mt-.5">
            <div>
              <strong>Total:</strong> {formatCurrency(invoiceData?.revenue)}
            </div>
            <div>
              <strong>Vat:</strong> {formatCurrency(invoiceData?.vat)}
            </div>
            <div>
              <strong>Grand total:</strong> {formatCurrency(invoiceData?.revenue_and_vat)}
            </div>
          </div>
          <ButtonPrimary text={'Create Invoice'} onClick={handleAddAccountsReceivable} isLoading={isLoading === 'create'} />
        </div>
      </form>
    </div>
  );
}

export default CreateAccountsReceivableForm;
