import React from 'react';

export default function TextColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
  return (
    <input
      type="text"
      className="mt-1 shadow-sm focus:ring-thaleria-orange-700  focus:border-thaleria-orange-700 block w-full sm:text-sm border p-2 border-gray-300 rounded-md"
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
    />
  );
}
