import React from 'react';
import { Link } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';

function ButtonPrimary({
  size,
  style,
  accept,
  disable,
  icon,
  text,
  onClick,
  canDelete,
  color,
  link,
  type,
  title,
  id,
  isLoading,
}) {
  const BUTTON_STYLES = {
    minWidth: '140px',
  };
  let sizeStyle;

  switch (size) {
    case 'xs':
      sizeStyle = 'px-2.5 py-1.5 text-xs rounded ';
      break;
    case 's':
      sizeStyle = 'px-3 py-2 text-xs leading-4 rounded-md';
      break;
    case 'm':
      sizeStyle = 'px-4 py-2 text-sm rounded-md ';
      break;
    case 'l':
      sizeStyle = 'px-4 py-2 text-base rounded-md ';
      break;
    case 'xl':
      sizeStyle = 'px-6 py-3 text-base rounded-md';
      break;
    default:
      sizeStyle = 'px-4 py-2 text-sm rounded-md ';
      break;
  }

  let colorStyle;

  switch (color) {
    case 'red':
      colorStyle = 'bg-red-600 hover:bg-red-700 focus:ring-red-500';
      break;
    case 'orange':
      colorStyle = 'bg-orange-600 hover:bg-orange-700 focus:ring-orange-500';
      break;
    case 'green':
      colorStyle = 'bg-green-600 hover:bg-green-700 focus:ring-green-500';
      break;
    default:
      colorStyle = 'bg-thaleria-orange-700 hover:bg-thaleria-orange-800 focus:ring-thaleria-orange-600';
      break;
  }

  if (!link) {
    return (
      <button
        id={id}
        onClick={onClick}
        style={BUTTON_STYLES}
        type={type ? type : 'button'}
        title={title}
        disabled={disable || isLoading}
        className={`${
          style ? style : ''
        } ${sizeStyle} inline-flex justify-center items-center border border-transparent text-white font-medium shadow-sm ${
          canDelete
            ? 'bg-red-600 hover:bg-red-700 focus:ring-red-500'
            : `${
                accept
                  ? 'bg-green-600 hover:bg-green-700 focus:ring-green-500'
                  : disable
                  ? 'bg-gray-400 cursor-not-allowed hover:bg-gray-400'
                  : colorStyle
              }`
        } focus:outline-none focus:ring-2 focus:ring-offset-2 `}
      >
        {!isLoading ? (
          <>
            {icon}
            {text}
          </>
        ) : (
          <>
            <ClipLoader size={17} color="#ffffff" />
          </>
        )}
      </button>
    );
  } else {
    return (
      <Link
        role="button"
        to={link}
        style={BUTTON_STYLES}
        type="Link"
        disabled={disable}
        className={`${
          style ? style : ''
        } ${sizeStyle} inline-flex justify-center items-center border border-transparent font-medium shadow-sm text-white ${
          canDelete
            ? 'bg-red-600 hover:bg-red-700 focus:ring-red-500'
            : `${
                accept
                  ? 'bg-green-600 hover:bg-green-700 focus:ring-green-500'
                  : disable
                  ? 'bg-gray-400 cursor-not-allowed hover:bg-gray-400'
                  : colorStyle
              }`
        } focus:outline-none focus:ring-2 focus:ring-offset-2 `}
      >
        {icon}
        {text}
      </Link>
    );
  }
}

export default ButtonPrimary;
