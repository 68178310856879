import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import ButtonPrimary from '../../components/Buttons/ButtonPrimary';
import ButtonWhite from '../../components/Buttons/ButtonWhite';
import InputSimple from '../../components/Inputs/InputSimple';
import ReactSelect from '../../components/Inputs/ReactSelect';
import Modal from '../../components/Modals/Modal';
import useApi from '../../hooks/useApi';
import { showSuccessNotification } from '../../store/app/actions';
import * as Yup from 'yup';
import { VALIDATION_MSG } from '../../helpers/enum/errorValidationMsgs';

export default function ResetPassword({ userId, pwRecipientListBoxData, onCancel, onSuccess, show, hide }) {
  const dispatch = useDispatch();
  const [type, setType] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [errors, setErrors] = useState([]);

  const {
    users: { resetPassword },
  } = useApi();

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
      .matches(/[0-9]/, 'Password must contain at least one number')
      .matches(/[!@#$%^&*()+_\-=}{[\]|:;'"/?.><,`«»~€£¥₽\\]/, 'Password must contain at least one symbol')
      .required(VALIDATION_MSG),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], 'Your passwords do not match.')
      .required(VALIDATION_MSG),
  });

  const validatePassword = async () => {
    let errors = [];
    validationSchema.validate();
    await validationSchema.validate({ password, confirmPassword }, { strict: true, abortEarly: false }).catch(err => {
      err.inner.forEach(_err => {
        errors.push({
          field: _err.path,
          msg: _err.errors[0],
        });
      });
    });
    return errors;
  };

  const handleResetPassword = async () => {
    if (type?.value == 3) {
      const errors = await validatePassword(password, confirmPassword);
      if (errors.length) {
        setErrors(errors);
        return;
      }
    }

    let data = {
      type: type.value,
      userId: userId,
      password: password,
    };

    resetPassword(data).then(res => {
      dispatch(showSuccessNotification('Password successfully reset'));
      onSuccess();
    });

    setErrors([]);
    setPassword('');
    setConfirmPassword('');
  };

  return (
    <Modal show={show} hide={hide} title="Reset password">
      <div className="w-full flex-col items-center sm:justify-center sm:flex-row gap-6 mt-4">
        <div>
          <ReactSelect
            isSearchable={false}
            label="Reset type"
            options={pwRecipientListBoxData}
            selectedOptions={type}
            onChange={setType}
          />
        </div>
        {type?.value == 3 && (
          <div className="space-y-4 mt-4">
            <InputSimple
              type="password"
              onChange={e => setPassword(e.target.value)}
              dataLabel={'password'}
              label={'Enter password'}
              value={password}
              error={errors.find(e => e.field === 'password')?.msg}
            />
            <InputSimple
              type="password"
              label="Confirm password"
              onChange={e => setConfirmPassword(e.target.value)}
              dataLabel={'confirmPassword'}
              value={confirmPassword}
              error={errors.find(e => e.field === 'confirmPassword')?.msg}
            />
          </div>
        )}
      </div>

      <div className="flex justify-end w-full text-right mt-10 pr-5">
        <div className="mx-5">
          <ButtonWhite onClick={onCancel} text={'Cancel'} />
        </div>
        <ButtonPrimary disable={!type} onClick={handleResetPassword} text={'Reset'} />
      </div>
    </Modal>
  );
}
