import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

function PrivateRoute({ component: Component, ...rest }) {
  const isAuthenticated = useSelector(state => state && state.auth.isAuthenticated);
  const tempPassword = useSelector(state => state && state.auth.currentUser.temp_password);
  const isLoading = useSelector(state => state && state.auth.isLoading);

  if (isLoading) return '';

  return (
    <Route
      {...rest}
      render={props =>
        !isAuthenticated ? <Redirect to="/login" /> : tempPassword ? <Redirect to="/temp-password" /> : <Component {...props} />
      }
    />
  );
}

export default PrivateRoute;
