import ButtonPrimary from '../../../components/Buttons/ButtonPrimary';
import ButtonWhite from '../../../components/Buttons/ButtonWhite';
import ReactSelect from '../../../components/Inputs/ReactSelect';
import TextArea from '../../../components/Inputs/TextArea';
import Modal from '../../../components/Modals/Modal';
import { extensionOptions } from '../../../helpers/selectOptionsExtensions';

const ExtensionModal = ({ show, hide, data, setData, onClick }) => {
  return (
    <Modal title="Contract extension" hide={hide} show={show} size="md">
      <div className="space-y-4">
        <ReactSelect
          label="Extension"
          options={extensionOptions}
          selectedOptionsIds={[data?.contract_extension]}
          onChange={obj => setData(prev => ({ ...prev, contract_extension: obj.value }))}
        />
        <TextArea
          label="Extension notes"
          value={data.extension_notes}
          onChange={e => {
            const { name, value } = e.target;
            setData(prev => ({ ...prev, extension_notes: value }));
          }}
        />
        <div className="flex justify-end gap-x-4">
          <ButtonWhite text="Cancel" onClick={hide} />
          <ButtonPrimary text="Save" onClick={onClick} />
        </div>
      </div>
    </Modal>
  );
};

export default ExtensionModal;
