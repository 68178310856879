import React, { useMemo } from 'react';
import TwoColumnForm from '../../../components/Layouts/TwoColumnForm';
import SimpleEntry from '../../../components/DescriptionEntries/SimpleEntry';
import { date } from '../../../helpers/date';
import { staffContractType, staffContractTypeLabels } from '../../../helpers/enum/staffContractType';
import { ExternalLinkIcon } from '@heroicons/react/outline';
import * as countryID from '../../../helpers/enum/CountryIDs';

function StaffContractDetails({ staffContract, handleEditClick }) {
  const isNotEmployee = useMemo(() => {
    return staffContract?.contract_type_id !== staffContractType.EMPLOYEE;
  }, [staffContract]);
  const isBelgium = useMemo(() => {
    return staffContract?.country_id === countryID.belgium;
  }, [staffContract]);
  const userNameAndLink = (
    <div className="flex gap-x-2 mr-auto">
      {staffContract?.user.full_name || '-'}
      {staffContract?.user && (
        <ExternalLinkIcon
          className="h-5 w-5 text-gray-400 hover:text-thaleria-orange-700 cursor-pointer"
          onClick={() => window.open(`/admin-panel/hr/user-directory/${staffContract.user.id}/profile`)}
        />
      )}
    </div>
  );
  return (
    <TwoColumnForm label={'Staff contract'} description="" button={true} buttonText={'Edit'} onClick={handleEditClick}>
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        <SimpleEntry label={'Reference'} data={staffContract.contract_ref || '-'} style={`sm:col-span-1`} />
        <SimpleEntry label={'User'} data={userNameAndLink} style={`sm:col-span-1`} />
        <SimpleEntry label={'Start date'} data={date(staffContract.contract_start || '-')} style={`sm:col-span-1`} />
        <SimpleEntry
          label={'End date'}
          data={(staffContract.contract_end && date(staffContract.contract_end)) || 'Open Ended'}
          style={`sm:col-span-1`}
        />
        <SimpleEntry
          label={'Contract type'}
          data={staffContractTypeLabels[staffContract.contract_type_id] || '-'}
          style={`sm:col-span-1`}
        />
        <SimpleEntry label={'Internal team'} data={staffContract?.internal_team ? 'Yes' : 'No'} style={`sm:col-span-1`} />
        <SimpleEntry label={'Position'} data={staffContract.position || '-'} style={`sm:col-span-1`} />
        <SimpleEntry label={'IBAN'} data={staffContract.iban || '-'} style={`sm:col-span-1`} />
        <SimpleEntry label={'BIC/Swift'} data={staffContract.bic_swift || '-'} style={`sm:col-span-1`} />
        <SimpleEntry
          label={`${isNotEmployee ? 'Billing country' : 'Country'}`}
          data={staffContract.country?.name || '-'}
          style={`sm:col-span-1`}
        />
        {isNotEmployee && (
          <SimpleEntry label={'Holiday calendar'} data={staffContract?.holiday_calendar?.name || '-'} style={`sm:col-span-1`} />
        )}
        <SimpleEntry label={'Company name'} data={staffContract.company_name || '-'} style={`sm:col-span-1`} />
        <SimpleEntry label={'Company address'} data={staffContract.company_address || '-'} style={`sm:col-span-1`} />
        <SimpleEntry label={'VAT number'} data={staffContract.vat_nb || '-'} style={`sm:col-span-1`} />
        <SimpleEntry label={'Created'} data={date(staffContract.created_at) || '-'} style={`sm:col-span-1`} />
        <SimpleEntry label={'Last updated'} data={date(staffContract.updated_at) || '-'} style={`sm:col-span-1`} />
        {!isNotEmployee && isBelgium && (
          <SimpleEntry label={'Hours per week'} data={staffContract.hours_per_week || '-'} style={`sm:col-span-1`} />
        )}
      </dl>
    </TwoColumnForm>
  );
}

export default StaffContractDetails;
