import React from 'react';

function ButtonRound({ size, onClick }) {
  let padding;
  let iconSize;
  switch (size) {
    case 'xs':
      padding = 'p-1 ';
      iconSize = 'w-4 h-4';
      break;
    case 's':
      padding = 'p-1.5';
      iconSize = 'w-4 h-4';
      break;
    case 'm':
      padding = 'p-2 ';
      iconSize = 'w-5 h-5';
      break;
    case 'l':
      padding = 'p-2 ';
      iconSize = 'w-5 h-5';
      break;
    case 'xl':
      padding = 'p-3 ';
      iconSize = 'w-6 h-6';
      break;
    default:
      break;
  }

  return (
    <button
      type="button"
      onClick={onClick}
      className={`${padding} inline-flex items-center border border-transparent rounded-full shadow-sm text-white bg-thaleria-orange-700 hover:bg-thaleria-orange-800 focus:ring-thaleria-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 `}
    >
      {/* <!-- If 'l' or 'xl' - Heroicon name: outline/plus || If 'xs', 's', 'm' or default - Heroicon name: solid/plus --> */}
      {size == 'xl' || size == 'l' ? (
        <svg
          className="h-6 w-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
        </svg>
      ) : (
        <svg className={iconSize} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path
            fillRule="evenodd"
            d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
            clipRule="evenodd"
          />
        </svg>
      )}
    </button>
  );
}

export default ButtonRound;
