import React, { useState, useEffect } from 'react';
import PageWrapperV2 from '../../../containers/App/PageWrapper';
import CreateOrEditAnnouncement from '../../../containers/Home/Announcements/CreateOrEditAnnouncement';
import { useParams } from 'react-router-dom';
import useApi from '../../../hooks/useApi';

const EditAnnouncement = () => {
  const { id } = useParams();

  const [announcement, setAnnouncement] = useState({ title: ' ', body: '' });

  const {
    announcements: { getAnnouncement },
  } = useApi();

  useEffect(() => {
    getAnnouncement(id).then(res => {
      setAnnouncement({ title: res.title, body: res.body });
    });
  }, []);

  const pages = [
    { name: `${announcement.title}`, href: `/announcement/${id}`, current: false },
    { name: `Edit`, href: `/announcement/${id}/edit`, current: true },
  ];

  return (
    <PageWrapperV2 pages={pages}>
      <div className="w-full px-4 py-5 bg-white sm:p-6 rounded">
        <CreateOrEditAnnouncement id={id} />
      </div>
    </PageWrapperV2>
  );
};

export default EditAnnouncement;
