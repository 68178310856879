import React, { useEffect, useState } from 'react';
//components and containers
import PageWrapperV2 from '../../../containers/App/PageWrapper';
//actions
import { ClipboardListIcon, DocumentTextIcon, KeyIcon, UserCircleIcon, CogIcon } from '@heroicons/react/outline';
import PersonalInfo from '../../../containers/MyProfile/PersonalInfo';
import ContractInfo from '../../../containers/MyProfile/ContractInfo';
import MyMissions from '../../../containers/MyProfile/MyMissions';
import classNames from '../../../utils/classNames';
import { useHistory, useLocation } from 'react-router-dom';
import SettingsInfo from '../../../containers/MyProfile/SettingsInfo';

function ProfilePage() {
  const history = useHistory();
  const { hash } = useLocation();

  const [navigation, setNavigation] = useState([
    { name: 'Personal info', icon: UserCircleIcon, current: false },
    { name: 'Contract info', icon: DocumentTextIcon, current: false },
    { name: 'My missions', icon: ClipboardListIcon, current: false },
    { name: 'Settings', icon: CogIcon, current: false },
  ]);

  const pages = [{ name: 'My Profile', href: '/my-profile', current: false }];

  useEffect(() => {
    if (!hash) {
      setNavigation(navigation.map(e => ({ ...e, current: e.name === navigation[0].name })));
      return history.push(`#${navigation[0].name.replace(' ', '-').toLowerCase()}`);
    }
    const currentTab = navigation.find(i => `#${i.name.replace(' ', '-').toLowerCase()}` === hash);
    if (!currentTab.current) {
      setNavigation(navigation.map(e => ({ ...e, current: e.name === currentTab.name })));
    }
    history.push(`#${currentTab.name.replace(' ', '-').toLowerCase()}`);
  }, [hash]);

  return (
    <PageWrapperV2 pages={pages}>
      <div className="divide-y divide-gray-200 bg-white flex-grow rounded-lg lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
        <aside className="py-6 lg:col-span-3">
          <nav className="space-y-1">
            {navigation.map(item => (
              <button
                key={item.name}
                onClick={() => history.push(`#${item.name.replace(' ', '-').toLowerCase()}`)}
                className={classNames(
                  item.current
                    ? 'bg-thaleria-orange-50 border-thaleria-orange-700 text-thaleria-orange-800 hover:bg-thaleria-orange-50 hover:text-thaleria-orange-700'
                    : 'border-transparent text-gray-900 hover:bg-blue-50 hover:text-gray-900',
                  'group border-l-4 px-3 py-2 flex items-center text-sm font-medium w-full',
                )}
              >
                <item.icon
                  className={classNames(
                    item.current
                      ? 'text-thaleria-orange-700 group-hover:text-thaleria-orange-700'
                      : 'text-gray-400 group-hover:text-gray-500',
                    'flex-shrink-0 -ml-1 mr-3 h-6 w-6',
                  )}
                  aria-hidden="true"
                />
                <span className="truncate">{item.name}</span>
              </button>
            ))}
          </nav>
        </aside>
        {navigation.map(el => {
          if (el.current && el.name === 'Personal info') return <PersonalInfo key={el.name} />;
          if (el.current && el.name === 'Contract info') return <ContractInfo key={el.name} />;
          if (el.current && el.name === 'My missions') return <MyMissions key={el.name} />;
          if (el.current && el.name === 'Settings') return <SettingsInfo key={el.name} />;
          return '';
        })}
      </div>
    </PageWrapperV2>
  );
}

export default ProfilePage;
