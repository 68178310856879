import React from 'react';
import Error from '../Error/Error';
import InfoTooltip from '../Tooltips/InfoTooltip';

function InputRadio({ selectedValue, defaultValue, options, onChange, label, whiteLabel, error, tooltip, tooltipMsg }) {
  return (
    <div className="w-full ">
      <div className="flex items-center">
        {label && (
          <label className={`block text-sm font-medium ${whiteLabel ? 'text-white' : 'text-gray-700'} mr-2`}>{label}</label>
        )}
        {error && <Error message={error} />}
        {tooltip && <InfoTooltip children={tooltipMsg} iconClassName="h-4 w-4 text-thaleria-orange-700" />}
      </div>
      <div className="mt-1 relative z-0 flex w-full">
        {options.map((option, index) => {
          return (
            <div className={`${index == 0 ? 'mr-2' : index == options.length - 1 ? 'ml-2' : 'mx-2'} flex items-center mt-2 `}>
              <input
                className={` h-4 w-4 border-gray-300 text-thaleria-blue-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-thaleria-blue-500"`}
                type="radio"
                value={option}
                checked={selectedValue == option || defaultValue == option}
                onChange={onChange}
              />
              <label htmlFor={option} className="ml-3 block text-sm font-medium text-gray-700">
                {option}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default InputRadio;
