import React, { useEffect, useState, useMemo } from 'react';

//components
import Table from '../../../../containers/ServerSideTable';
import { useHistory } from 'react-router';
import AdminManager from '../AdminManager';
import SelectColumnFilter from '../../../../components/TableItems/SelectColumnFilter';
import { ExternalLinkIcon, PencilIcon } from '@heroicons/react/outline';
import useApi from '../../../../hooks/useApi';
import AdminSidebar from '../../AdminSidebar';

function PointOfContacts() {
  const history = useHistory();

  const [clientOptions, setClientOptions] = useState();

  const {
    pointOfContacts: { getAllContactsPaginated, getClientOptions },
  } = useApi();

  useEffect(() => {
    getClientOptions().then(d => {
      setClientOptions(d);
    });
  }, []);

  const fetchData = async (page = 0, filters = [], sortByArray = [], globalFilter, downloadToCsv, source) => {
    const firstName = filters.find(filter => filter.id === 'first_name')?.value;
    const lastName = filters.find(filter => filter.id === 'last_name')?.value;
    const email = filters.find(filter => filter.id === 'email')?.value;
    const phoneNb = filters.find(filter => filter.id === 'phone_no')?.value;
    const clients = filters.find(filter => filter.id === 'client')?.value;
    const sortBy = sortByArray.length ? `${sortByArray[0].id.toString()},${sortByArray[0].desc.toString()}` : undefined;

    if (downloadToCsv) {
      let csvData = [];
      await getAllContactsPaginated(
        page + 1,
        firstName,
        lastName,
        email,
        phoneNb,
        clients,
        sortBy,
        globalFilter,
        downloadToCsv,
      ).then(res => {
        res.forEach(e => {
          csvData.push({
            id: e.id,
            first_name: e.first_name,
            last_name: e.last_name,
            email: e.email,
            phone_no: e.phone_no,
            client: e?.client?.name || '',
          });
        });
      });
      return csvData;
    }

    getAllContactsPaginated(page + 1, firstName, lastName, email, phoneNb, clients, sortBy, globalFilter, null, source).then(
      d => {
        setPointOfContacts(d);
      },
    );
  };

  const [pointOfContacts, setPointOfContacts] = useState([]);

  const tableData = useMemo(() => {
    let data = [];
    if (pointOfContacts && pointOfContacts?.rows?.length) {
      pointOfContacts.rows.map(e => {
        data.push({
          id: e.id,
          first_name: e.first_name,
          last_name: e.last_name,
          email: e.email,
          phone_no: e.phone_no,
          client: e?.client?.name || '',
        });
      });
    }

    return data;
  }, [pointOfContacts, clientOptions]);

  const columns = useMemo(() => [
    {
      Header: 'ID',
      accessor: 'id',
      disableFilters: true,
    },
    {
      Header: 'First Name',
      accessor: 'first_name',
    },
    {
      Header: 'Last Name',
      accessor: 'last_name',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Phone',
      accessor: 'phone_no',
    },
    {
      Header: 'Client',
      accessor: 'client',
      Filter: SelectColumnFilter,
      filter: 'includes',
      filterOptions: clientOptions
        ? clientOptions.map(o => ({
            label: o.name,
            value: o.id,
          }))
        : null,
    },
  ]);

  const pages = [
    { name: 'Admin Manager', href: '/admin-panel/admin/clients', current: false },
    { name: `Points of Contact`, href: '/admin-panel/admin/points-of-contact', current: true },
  ];

  let renderRowMenu = row => {
    return [
      [
        {
          text: 'View/edit details',
          onClick: () => history.push(`/admin-panel/admin/points-of-contact/${row.original.id}`),
          show: true,
          icon: PencilIcon,
        },
        {
          text: 'View/edit details in new tab',
          onClick: () => window.open(`/admin-panel/admin/points-of-contact/${row.original.id}`),
          show: true,
          icon: ExternalLinkIcon,
        },
      ],
    ];
  };

  const addButton = {
    link: '/admin-panel/admin/points-of-contact/create',
  };

  const rowOnClick = row => {
    history.push(`/admin-panel/admin/points-of-contact/${row.values.id}`);
  };

  return (
    <AdminSidebar noPadding pages={pages}>
      <Table
        columns={columns}
        data={tableData}
        customContextMenu
        contextMenuOptions={renderRowMenu}
        addButton={addButton}
        rowOnClick={rowOnClick}
        pageCount={pointOfContacts?.totalPages}
        fetchData={fetchData}
        totalItems={pointOfContacts?.totalItems}
        tableName="pointsOfContact"
      />
    </AdminSidebar>
  );
}

export default PointOfContacts;
