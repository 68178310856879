const holidayTypes = {
  strings: {
    0: 'Public holiday',
    1: 'Closure day',
  },
  numbers: {
    public_holiday: 0,
    closure_day: 1,
  },
};

export default holidayTypes;
