import React from 'react';

const DotsVerticalIcon = ({ onClick }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-5 text-gray-600" fill="none" stroke="currentColor" onClick={onClick}>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
      />
    </svg>
  );
};

export default DotsVerticalIcon;
