import React from 'react';
import { BellIcon } from '@heroicons/react/outline';
const NotificationIcon = ({ onClick, numberOfNotifications }) => {
  return (
    <button
      type="button"
      className="relative p-1 bg-thaleria-orange-800 rounded-full text-thaleria-orange-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-thaleria-blue-400 focus:ring-white"
      onClick={onClick}
    >
      <span className="sr-only">View notifications</span>
      <span
        className={`${
          numberOfNotifications == 0 && 'hidden'
        } absolute w-5 h-5 rounded-xl bg-red-500 right-[-4px] top-[-6px] text-sm text-white flex items-center justify-center`}
      >
        {numberOfNotifications}
      </span>
      <BellIcon className="h-6 w-6" aria-hidden="true" />
    </button>
  );
};

export default NotificationIcon;
