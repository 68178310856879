import React from 'react';
import Error from '../Error/Error';

function TextArea({
  value,
  onChange,
  dataLabel,
  rows,
  label,
  description,
  placeholder,
  errorMessage,
  className,
  error,
  reference,
  disabled,
  classNameOverride,
}) {
  const handleChange = e => {
    onChange(e, dataLabel);
  };

  return (
    <div>
      <div className="flex">
        {label && <label className="block text-sm font-medium text-gray-700 mr-2">{label}</label>}
        {error && <Error message={error} />}
      </div>
      <div className="mt-1">
        <textarea
          rows={rows}
          value={value}
          onChange={handleChange}
          className={
            classNameOverride
              ? classNameOverride
              : `${className} text-2xl shadow-sm focus:ring-thaleria-orange-600 focus:border-thaleria-orange-600 mt-1 block w-full sm:text-sm border p-2 border-gray-300 rounded-md ${
                  disabled && 'bg-gray-200 cursor-not-allowed'
                }`
          }
          placeholder={placeholder}
          ref={reference}
          disabled={disabled}
        />
      </div>
      {errorMessage && <span className="text-xs text-red-500 ml-4">{errorMessage}</span>}
      {description && <p className="mt-2 text-sm text-gray-500">{description}</p>}
    </div>
  );
}

export default TextArea;
