import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Checkbox from '../../components/Checkbox/Checkbox';
import ReactSelect from '../../components/Inputs/ReactSelect';
import { AccountType } from '../../helpers/enum/accounts';
import useApi from '../../hooks/useApi';
import Error from '../../components/Error/Error';
const FileAccessManagement = ({
  onAccountTypesChange,
  acceptedContractTypes,
  acceptedAccountTypes,
  acceptedCountries,
  onContractTypesChange,
  onCountriesChange,
  countriesPlaceholder,
  isAdmin,
  countryOptions,
  disabledCheckboxes,
}) => {
  const [allCountries, setAllCountries] = useState([]);
  const [allContractTypes, setAllContractTypes] = useState([]);
  const {
    userTypes: { getAllUserTypes },
    contractTypes: { getAllContractTypes },
    countries: { getAllCountries },
  } = useApi();

  const countries = countryOptions
    ? allCountries
        .filter(country => countryOptions.includes(country.id))
        .map(country => ({ value: country.id, label: country.name }))
    : allCountries.map(country => ({ value: country.id, label: country.name }));
  const contractTypes = allContractTypes.map(contractType => ({ value: contractType.id, label: contractType.contract_type }));

  useEffect(() => {
    getAllCountries().then(res => setAllCountries(res));
    getAllContractTypes().then(res => setAllContractTypes(res));
  }, []);

  const contractTypeError = !acceptedContractTypes?.length;
  const countriesError = !acceptedCountries?.length && countryOptions;

  return (
    <>
      <h4 className="text-md-center pb-4">Who can view these files?</h4>
      <div className="mb-4">
        <label className={`block text-sm font-medium text-gray-700 mr-2 mb-2`}>Account types</label>
        <div className="flex justify-start items-center px-3">
          <Checkbox title={'Admins'} value={true} disabled={true} horizontal />
          <Checkbox
            title={'Staff'}
            value={acceptedAccountTypes.includes(AccountType.STAFF)}
            id={'staff'}
            onChange={e => onAccountTypesChange(e)}
            horizontal
            disabled={isAdmin ? disabledCheckboxes.includes('staff') : true}
          />
          <Checkbox
            title={'User'}
            horizontal
            id={'user'}
            value={acceptedAccountTypes.includes(AccountType.USER)}
            onChange={e => onAccountTypesChange(e)}
            disabled={disabledCheckboxes.includes('user')}
          />
        </div>
      </div>
      <div className="mb-4">
        <div className="flex space-x-2 mr-2 mb-2">
          <label className={`block text-sm font-medium text-gray-700 `}>Contract types</label>
          {contractTypeError && <Error message="Must have at least one option selected" />}
        </div>

        <div className="flex justify-start items-center px-3">
          {contractTypes.map(type => {
            return (
              <Checkbox
                title={type.label}
                horizontal
                id={type.label.toLowerCase()}
                value={acceptedContractTypes.includes(type.value)}
                onChange={e => onContractTypesChange(e)}
                disabled={disabledCheckboxes.includes(type.label.toLowerCase())}
              />
            );
          })}
        </div>
      </div>
      <div className="mb-4">
        <ReactSelect
          selectedOptionsIds={acceptedCountries}
          options={countries}
          isMulti={true}
          label="Countries"
          placeholder={countriesPlaceholder}
          onChange={onCountriesChange}
          error={countriesError ? 'Must have at least one option selected' : false}
        />
      </div>
    </>
  );
};

FileAccessManagement.prototype = {
  show: PropTypes.bool,
  onSuccessClick: PropTypes.func,
  onCancelClick: PropTypes.func,
  onCountriesChange: PropTypes.func,
  countriesDefaultId: PropTypes.array,
  onContractTypesChange: PropTypes.func,
  contractTypeDefaultId: PropTypes.array,
  onAccountTypesChange: PropTypes.func,
  AccountTypesDefaultId: PropTypes.array,
  countriesPlaceholder: PropTypes.string,
  contractTypePlaceholder: PropTypes.string,
  AccountTypesPlaceholder: PropTypes.string,
  title: PropTypes.string,
  successButtonName: PropTypes.string,
};

export default FileAccessManagement;
