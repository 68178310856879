const thaleriaColors = {
  'thaleria-blue': {
    900: '#0B1524',
    800: '#131F37',
    700: '#1E2F4F',
    600: '#2C3F62',
    500: '#3C5377',
    400: '#506B8E',
    300: '#6989A9',
    200: '#83A2BB',
    100: '#A4BFCF',
    50: '#C5D4DB',
  },
  'thaleria-orange': {
    900: '#BE691F',
    800: '#E1882A',
    700: '#FFA53B',
    600: '#FFB54A',
    500: '#FFC35A',
    400: '#FFD069',
    300: '#FFDD7D',
    200: '#FFE893',
    100: '#FFF1AA',
    50: '#FFF7BE',
  },
};

export default thaleriaColors;
