import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Delete from '../Icons/DeleteIcon';
import Edit from '../Icons/EditIcon';
import DropdownMenuDots from '../Menus/DropdownMenuDots';
const ReminderCard = props => {
  const [dropdownMenu, setDropdownMenu] = useState([]);

  const onDeleteHandler = event => {
    props.onDeleteClick();
  };

  const onEditHandler = event => {
    props.onEditClick();
  };

  useEffect(() => {
    let items = [];

    if (props.showEditButton) {
      items.push({
        text: 'Edit',
        icon: <Edit />,
        onClick: onEditHandler,
      });
    }
    if (props.showDeleteButton) {
      items.push({
        text: 'Delete',
        icon: <Delete />,
        onClick: onDeleteHandler,
      });
    }
    setDropdownMenu([items]);
  }, [props.showEditButton, props.showDeleteButton]);

  return (
    <>
      {!!props.index && (
        <div className="px-2">
          <div className={`my-4 border-t-2 border-gray-300 }`} />
        </div>
      )}
      <div className={`px-2 py-2 rounded-lg hover:bg-gray-100 cursor-pointer transition duration-200`} onClick={props.onClick}>
        <div className="flex justify-between items-center">
          <p class="text-sm font-semibold text-gray-900">{props.title}</p>
          {(props.showEditButton || props.showDeleteButton) && <DropdownMenuDots horizontal items={dropdownMenu} />}
        </div>
        <p className="mt-1 text-sm text-gray-600 line-clamp-2">{props.text}</p>
      </div>
    </>
  );
};

export default ReminderCard;

ReminderCard.prototype = {
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.string,
  showDeleteButton: PropTypes.bool,
  showEditButton: PropTypes.bool,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
};
