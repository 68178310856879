import React from 'react';
import PropTypes from 'prop-types';
import InputSimple from '../../../components/Inputs/InputSimple';
import ButtonPrimary from '../../../components/Buttons/ButtonPrimary';
import ButtonWhite from '../../../components/Buttons/ButtonWhite';
import RichTextEditor from '../../../components/Inputs/RichTextEditor/RichTextEditor';
import InputBanner from '../../../components/Inputs/InputBanner';

const AddTitleBodyAnnouncement = props => {
  return (
    <div className="space-y-4">
      <InputSimple
        placeholder="Enter announcement title"
        onChange={props.onTitleChange}
        value={props.title}
        errorMessage={props.inputErrorMessage}
      />

      <InputBanner
        image={props.image}
        setImage={props.setImage}
        croppedImage={props.croppedImage}
        setCroppedImage={props.setCroppedImage}
        applyCropToImage={props.applyCropToImage}
        crop={props.crop}
        setCrop={props.setCrop}
        rotation={props.rotation}
        setRotation={props.setRotation}
        zoom={props.zoom}
        setZoom={props.setZoom}
        croppedAreaPixels={props.croppedAreaPixels}
        setCroppedAreaPixels={props.setCroppedAreaPixels}
      />

      {props.croppedImage && <img src={props?.croppedImage?.base64} alt="Base64 Image" />}

      <RichTextEditor editorState={props.body} setEditorState={props.onBodyChange} placeholder="Enter announcement text" />

      {props.textAreaErrorMessage && <span className="text-xs text-red-500 ml-4">{props.textAreaErrorMessage}</span>}

      <div className="mt-10 flex flex-row-reverse justify-start">
        <ButtonPrimary text="Next" onClick={props.onSuccessHandler} isLoading={props.isUploading} />
        <ButtonWhite text="Cancel" style="mr-2" onClick={props.onCancelHandler} />
      </div>
    </div>
  );
};

export default AddTitleBodyAnnouncement;

AddTitleBodyAnnouncement.propTypes = {
  onSuccessHandler: PropTypes.func,
  onCancelHandler: PropTypes.func,
  title: PropTypes.string,
  body: PropTypes.string,
  onTitleChange: PropTypes.func,
  onBodyChange: PropTypes.func,
  inputErrorMessage: PropTypes.string,
  textAreaErrorMessage: PropTypes.string,
};
