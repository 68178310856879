import React, { useEffect, useMemo, useState } from 'react';
import Table from '../../../../containers/ServerSideTable';
import useApi from '../../../../hooks/useApi';
import SelectColumnFilter from '../../../../components/TableItems/SelectColumnFilter';
import NumberRangeColumnFilter from '../../../../components/TableItems/NumberRangeColumnFilter';
import PreviewBox from '../../../../containers/PDFViewer/PreviewBox';
import TableTabs from '../../../../components/TableItems/TableTabs';
import CreateAccountsReceivableOverlay from '../../../../containers/Finance/AccountsReceivable/CreateAccountsReceivableOverlay';
import CreateAccountsReceivableModal from '../../../../containers/Finance/AccountsReceivable/CreateAccountsReceivableModal';
import formatCurrency from '../../../../utils/formatCurrency';
import { TimeSheetStatus, TimeSheetStatusLabels } from '../../../../helpers/enum/timeSheet';
import { Link } from 'react-router-dom';
import { ExternalLinkIcon, TrashIcon } from '@heroicons/react/outline';
import { showSuccessNotification } from '../../../../store/app/actions';
import AdminSidebar from '../../AdminSidebar';
import { lineItemTypesV2 } from '../../../../helpers/enum/lineItemTypes';
import { americanDate } from '../../../../helpers/date';
import { format, isSameDay } from 'date-fns';
import DateRangeColumnFilter from '../../../../components/TableItems/DateRangeColumnFilter';
import formatNumber from '../../../../utils/formatNumber';

function InvoicingLines() {
  const {
    lineItems: { getInvoicingLinesPaginated, getCounterparts, getInvoicingLineOptions, deleteInvoicingLineById },
  } = useApi();

  const [invoicingLinesData, setInvoicingLinesData] = useState([]);
  const [previewFile, setPreviewFile] = useState(false);
  const [loadingPreview, setLoadingPreview] = useState();
  const [filterOptions, setFilterOptions] = useState();
  const [activeTab, setActiveTab] = useState('To be invoiced');
  const [selectedRows, setSelectedRows] = useState([]);
  const [showAccountsReceivable, setShowAccountsReceivable] = useState(false);
  const [accountsReceivableTotals, setAccountsReceivableTotals] = useState();
  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState();
  const [tableSettings, setTableSettings] = useState({
    pageIndex: 0,
    filters: [],
    sortBy: [],
    globalFilter: null,
  });

  useEffect(() => {
    setSelectedRows([]);
    //Pass the filter invoiced as argument when fetching options for "Invoiced" tab
    getInvoicingLineOptions(activeTab === 'Invoiced' ? true : false, activeTab === 'Backlog' ? false : true).then(res => {
      setFilterOptions(res);
    });
  }, [activeTab]);

  useEffect(() => {
    if (selectedRows.length) {
      setShowAccountsReceivable(true);

      let totalDaysWorked = 0;
      let totalVatOwed = 0;
      let totalRevenue = 0;

      selectedRows.forEach(row => {
        totalRevenue += Number(row.original.total);
        totalDaysWorked += Number(row.original.units);
        totalVatOwed += Number(row.original.vat) || 0;
      });
      setAccountsReceivableTotals({
        totalDaysWorked,
        totalRevenue,
        totalVatOwed,
      });
    } else {
      setShowAccountsReceivable(false);
    }
  }, [selectedRows]);

  const fetchInvoicingLines = async (page = 0, filters = [], sortByArray = [], globalFilter, downloadToCsv, source) => {
    setTableSettings({ pageIndex: page, filters: filters, sortBy: sortByArray, globalFilter: globalFilter });
    const users = filters.find(filter => filter.id === 'user')?.value;
    let dates = filters.find(filter => filter.id === 'period')?.value;
    const purchaseOrderRef = filters.find(filter => filter.id === 'purchaseOrderRef')?.value;
    const salesOrderRef = filters.find(filter => filter.id === 'salesOrderRef')?.value;
    const specificContractRef = filters.find(filter => filter.id === 'contractRef')?.value;
    const partnerContract = filters.find(filter => filter.id === 'partnerContract')?.value;
    const clients = filters.find(filter => filter.id === 'client')?.value;
    const units = filters.find(filter => filter.id === 'units')?.value;
    const total = filters.find(filter => filter.id === 'total')?.value;
    const vat = filters.find(filter => filter.id === 'vat')?.value;
    const accountsReceivableIds = filters.find(filter => filter.id === 'accountsReceivableId')?.value;
    const invoiceNumbers = filters.find(filter => filter.id === 'invoiceNumber')?.value;
    const timesheetStatus = filters.find(filter => filter.id === 'timesheetStatus')?.value;
    const type = filters.find(filter => filter.id === 'type')?.value;
    const totalAndVat = filters.find(filter => filter.id === 'totalAndVat')?.value;
    const sortBy = sortByArray.length ? `${sortByArray[0].id.toString()},${sortByArray[0].desc.toString()}` : undefined;
    const invoiced = activeTab === 'Invoiced' ? true : false;
    const price = filters.find(filter => filter.id === 'price')?.value;
    const uom = filters.find(filter => filter.id === 'uom')?.value;
    const approved = activeTab === 'To be invoiced' || activeTab === 'Invoiced';
    let csvData = [];

    if (dates !== undefined) {
      dates = dates.map(d => {
        if (d !== undefined) return americanDate(d);
      });
    }

    if (downloadToCsv) {
      await getInvoicingLinesPaginated(
        page + 1,
        users,
        dates,
        purchaseOrderRef,
        salesOrderRef,
        specificContractRef,
        clients,
        units,
        price,
        total,
        invoiced,
        partnerContract,
        vat,
        totalAndVat,
        accountsReceivableIds,
        invoiceNumbers,
        timesheetStatus,
        type,
        uom,
        approved,
        sortBy,
        globalFilter,
        downloadToCsv,
        source,
      ).then(r => {
        r.forEach(e => {
          let period = '';
          let client;
          let clientId;
          if (activeTab === 'To be invoiced') {
            client = e?.salesOrder?.client?.name ? e.salesOrder.client.name : '-';
            clientId = e?.salesOrder?.client_id;
          } else {
            client = e?.invoice?.client?.name ? e.invoice.client.name : '-';
            clientId = e?.invoice?.client_id;
          }
          if (e?.from_date) period = period + `${format(new Date(e?.from_date), 'dd/MM/yy')}`;
          if (e?.to_date && !isSameDay(new Date(e?.from_date), new Date(e?.to_date)))
            period = period + ` - ${format(new Date(e?.to_date), 'dd/MM/yy')}`;
          csvData.push({
            id: e?.id,
            user: e?.user?.full_name ? e?.user?.full_name : null,
            period: period,
            timesheetStatus: {
              status: e?.timesheet?.status !== undefined ? TimeSheetStatusLabels[e?.timesheet?.status] : null,
              timesheetId: e?.timesheet_id,
            },
            purchaseOrderRef: e?.staffOrder?.order_ref ? e.staffOrder.order_ref : '-',
            salesOrderRef: e?.salesOrder?.order_ref ? e.salesOrder.order_ref : '-',
            contractRef: e?.specificContract?.contract_ref ? e.specificContract.contract_ref : '-',
            client: client,
            client_id: clientId,
            units: formatNumber(Number(e?.units), true, 6),
            vat: e?.vat || 0,
            price: e?.price,
            total: e?.total,
            totalAndVat: e?.total_and_vat,
            accountsReceivableId: e?.accounts_receivable_id,
            partnerContract: e?.salesFwc?.contract_ref,
            invoiceNumber: e?.accountsReceivable?.invoice_number || '-',
            type: lineItemTypesV2.accountsReceivable.strings[e?.type],
          });
        });
      });
      return csvData;
    }

    //Depending on the active tab either fetch invoicingLines with different filter or Pre-approved items
    getInvoicingLinesPaginated(
      page + 1,
      users,
      dates,
      purchaseOrderRef,
      salesOrderRef,
      specificContractRef,
      clients,
      units,
      price,
      total,
      invoiced,
      partnerContract,
      vat,
      totalAndVat,
      accountsReceivableIds,
      invoiceNumbers,
      timesheetStatus,
      type,
      uom,
      approved,
      sortBy,
      globalFilter,
      downloadToCsv,
      source,
    ).then(r => {
      setInvoicingLinesData(r);
    });
  };

  const tableData = useMemo(() => {
    let array = [];
    if (invoicingLinesData?.rows?.length) {
      invoicingLinesData.rows.forEach(e => {
        let period = '';
        let client;
        let clientId;
        if (activeTab === 'To be invoiced' || activeTab === 'Backlog') {
          client = e?.salesOrder?.client?.name ? e.salesOrder.client.name : '-';
          clientId = e?.salesOrder?.client_id;
        } else {
          client = e?.invoice?.client?.name ? e.invoice.client.name : '-';
          clientId = e?.invoice?.client_id;
        }

        if (e?.from_date) period = period + `${format(new Date(e?.from_date), 'dd/MM/yy')}`;
        if (e?.to_date && !isSameDay(new Date(e?.from_date), new Date(e?.to_date)))
          period = period + ` - ${format(new Date(e?.to_date), 'dd/MM/yy')}`;

        array.push({
          id: e?.id ? e.id : '-',
          user: e?.user?.full_name ? e?.user?.full_name : '-',
          period: period,
          timesheetStatus: {
            status: e?.timesheet?.status !== undefined ? TimeSheetStatusLabels[e?.timesheet?.status] : null,
            timesheetId: e?.timesheet_id,
          },
          purchaseOrderRef: e?.staffOrder?.order_ref ? e.staffOrder.order_ref : '-',
          salesOrderRef: e?.salesOrder?.order_ref ? e.salesOrder.order_ref : '-',
          contractRef: e?.specificContract?.contract_ref ? e.specificContract.contract_ref : '-',
          client: client,
          client_id: clientId,
          units: formatNumber(Number(e?.units), true, 6),
          price: e?.price,
          total: e?.total,
          vat: e?.vat || formatCurrency(0),
          totalAndVat: e?.total_and_vat || null,
          accountsReceivableId: e?.accounts_receivable_id ? e.accounts_receivable_id : '-',
          partnerContract: e?.salesFwc?.contract_ref,
          invoiceNumber: { number: e?.invoice?.invoice_number ? e.invoice.invoice_number : '-', id: e?.accounts_receivable_id },
          // type needs the ? in lineItemTypes because of fetching non-approved items that do not have a type
          type: e?.type,
          uom: e?.uom,
        });
      });
    }
    return array;
  }, [invoicingLinesData]);

  const columns = useMemo(() => {
    let timesheetStatusOptions = [];

    Object.keys(TimeSheetStatusLabels).forEach(key => {
      if (activeTab === 'To be invoiced' || activeTab === 'Invoiced') {
        if (Number(key) === TimeSheetStatus.APPROVED || Number(key) === TimeSheetStatus.PREAPPROVE) {
          timesheetStatusOptions.push({
            value: key,
            label: TimeSheetStatusLabels[key],
          });
        }
      } else {
        if (Number(key) !== TimeSheetStatus.APPROVED && Number(key) !== TimeSheetStatus.PREAPPROVE) {
          timesheetStatusOptions.push({
            value: key,
            label: TimeSheetStatusLabels[key],
          });
        }
      }
    });

    const cols = [
      {
        Header: 'User',
        accessor: 'user',
        Filter: SelectColumnFilter,
        filter: 'includes',
        filterOptions: filterOptions ? filterOptions?.userOptions?.map(el => ({ value: el.id, label: el.full_name })) : [],
      },
      {
        Header: 'Period',
        accessor: 'period',
        Filter: DateRangeColumnFilter,
        filter: 'date',
      },
      {
        Header: 'TS status',
        accessor: 'timesheetStatus',
        Filter: SelectColumnFilter,
        filter: 'includes',
        filterOptions: timesheetStatusOptions,
        Cell: ({ value }) => {
          return (
            <span className="flex justify-center divide-x-2 gap-x-2 items-center">
              {value?.status ? (
                <>
                  {value?.status}
                  <Link to={{ pathname: `/admin-panel/hr/timesheets/${value.timesheetId}` }}>
                    <ExternalLinkIcon className="w-5 h-5 text-thaleria-orange-500 cursor-pointer hover:text-thaleria-orange-300" />
                  </Link>
                </>
              ) : (
                <>-</>
              )}
            </span>
          );
        },
      },
      {
        Header: 'Client',
        accessor: 'client',
        disableFilters: selectedRows.length ? true : false,
        Filter: SelectColumnFilter,
        filter: 'includes',
        filterOptions: filterOptions ? filterOptions?.clientOptions?.map(el => ({ value: el.id, label: el.name })) : [],
      },
      {
        Header: activeTab === 'Backlog' ? 'Days worked' : 'Units',
        accessor: 'units',
        Filter: NumberRangeColumnFilter,
        filter: 'between',
      },
      {
        Header: 'Total',
        accessor: 'total',
        Filter: NumberRangeColumnFilter,
        filter: 'between',
        Cell: ({ value }) => formatCurrency(value) || '-',
      },
      {
        Header: 'Price',
        accessor: 'price',
        Filter: NumberRangeColumnFilter,
        filter: 'between',
        Cell: ({ value }) => formatCurrency(value) || '-',
      },
      {
        Header: 'Partner contract',
        accessor: 'partnerContract',
        Filter: SelectColumnFilter,
        filter: 'includes',
        filterOptions: filterOptions
          ? filterOptions?.partnerContractOptions?.map(el => ({
              value: el.id,
              label: el.contract_ref,
            }))
          : [],
      },
      {
        Header: 'Specific contract',
        accessor: 'contractRef',
        Filter: SelectColumnFilter,
        filter: 'includes',
        filterOptions: filterOptions
          ? filterOptions?.specificContractOptions?.map(el => ({ value: el.id, label: el.contract_ref }))
          : [],
      },
      {
        Header: 'Sales order',
        accessor: 'salesOrderRef',
        Filter: SelectColumnFilter,
        filter: 'includes',
        filterOptions: filterOptions ? filterOptions?.salesOrderOptions?.map(el => ({ value: el.id, label: el.order_ref })) : [],
      },
      {
        Header: 'Purchase order',
        accessor: 'purchaseOrderRef',
        Filter: SelectColumnFilter,
        filter: 'includes',
        filterOptions: filterOptions ? filterOptions?.staffOrderOptions?.map(el => ({ value: el.id, label: el.order_ref })) : [],
      },
      {
        Header: 'VAT',
        accessor: 'vat',
        Filter: NumberRangeColumnFilter,
        filter: 'between',
        disableFilters: activeTab === 'Backlog' ? true : false,
        disableSortBy: activeTab === 'Backlog' ? true : false,
        Cell: ({ value }) => formatCurrency(value) || '-',
      },
      {
        Header: 'Grand total',
        accessor: 'totalAndVat',
        Filter: NumberRangeColumnFilter,
        filter: 'between',
        disableFilters: activeTab === 'Backlog' ? true : false,
        disableSortBy: activeTab === 'Backlog' ? true : false,
        Cell: ({ value }) => formatCurrency(value) || '-',
      },
    ];

    if (activeTab === 'To be invoiced') {
      const optionsKeys = Object.keys(lineItemTypesV2.accountsReceivable.strings).filter(key => {
        return (
          Number(key) === lineItemTypesV2.accountsReceivable.numbers.allocationsToBeInvoiced ||
          Number(key) === lineItemTypesV2.accountsReceivable.numbers.customAllocationsToBeInvoiced
        );
      });
      cols.push({
        Header: 'Type',
        accessor: 'type',
        Filter: SelectColumnFilter,
        filter: 'include',
        filterOptions: filterOptions ? filterOptions?.typeOptions : [],
        Cell: ({ value }) => (value ? lineItemTypesV2.accountsReceivable.strings[value] : '-'),
      });
    }

    if (activeTab === 'Invoiced') {
      cols.push({
        Header: 'Type',
        accessor: 'type',
        Filter: SelectColumnFilter,
        filter: 'include',
        filterOptions: filterOptions ? filterOptions?.typeOptions : [],
        Cell: ({ value }) => (value ? lineItemTypesV2.accountsReceivable.strings[value] : '-'),
      });
    }

    if (activeTab === 'Invoiced') {
      //Add accounts receivable ID and invoice ID only in Invoiced tab
      cols.unshift({
        Header: 'Invoice number',
        Filter: SelectColumnFilter,
        filter: 'includes',
        accessor: 'invoiceNumber',
        filterOptions: filterOptions
          ? filterOptions?.invoiceNumberOptions?.map(el => ({ value: el.id, label: el.invoice_number }))
          : [],
        Cell: ({ value }) => {
          return (
            <span className="flex justify-center divide-x-2 gap-x-2 items-center">
              {value?.number ? (
                <>
                  {value?.number}
                  <Link to={{ pathname: `/admin-panel/finance/accounts-receivable/${value?.number}` }}>
                    <ExternalLinkIcon className="w-5 h-5 text-thaleria-orange-500 cursor-pointer hover:text-thaleria-orange-300" />
                  </Link>
                </>
              ) : (
                <>-</>
              )}
            </span>
          );
        },
      });
      cols.unshift({
        Header: 'Accounts receivable id',
        accessor: 'accountsReceivableId',
        Filter: SelectColumnFilter,
        filter: 'includes',
        filterOptions: filterOptions
          ? filterOptions?.accountsReceivableIdOptions?.map(el => ({
              value: el.accounts_receivable_id,
              label: el.accounts_receivable_id,
            }))
          : [],
      });
    }

    if (activeTab !== 'Backlog') {
      const unitsIndex = cols.findIndex(col => col.accessor === 'units');

      cols.splice(unitsIndex + 1, 0, {
        Header: 'Uom',
        accessor: 'uom',
      });
    }
    return cols;
  }, [filterOptions, loadingPreview, invoicingLinesData, selectedRows]);

  const pages = [
    { name: 'Finance Manager', href: '/admin-panel/finance/invoicing-lines', current: false },
    { name: 'Invoicing lines', href: '/admin-panel/finance/invoicing-lines', current: false },
  ];

  const tabs = [
    {
      name: 'To be invoiced',
      onClick: () => activeTab !== 'To be invoiced' && setActiveTab('To be invoiced'),
    },
    {
      name: 'Invoiced',
      onClick: () => activeTab !== 'Invoiced' && setActiveTab('Invoiced'),
    },
    {
      name: 'Backlog',
      onClick: () => activeTab !== 'Backlog' && setActiveTab('Backlog'),
    },
  ];

  const tableName = useMemo(() => {
    const _tableName =
      activeTab === 'To be invoiced'
        ? 'approvedInvoicingItems'
        : activeTab === 'Backlog'
        ? 'backlogInvoicingItems'
        : activeTab === 'Invoiced' && 'invoicedInvoicingItems';
    return _tableName;
  }, [activeTab]);

  const CreateAccountsReceivableLink = useMemo(() => {
    if (!selectedRows) return;

    let invoicingLinesIds = selectedRows.map(row => row.original.id);

    const link = (
      <Link
        className=" px-4 py-2 text-sm rounded-md  inline-flex justify-center Lines-center border border-transparent text-white font-medium shadow-sm bg-thaleria-orange-700 hover:bg-thaleria-orange-800 focus:ring-thaleria-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 "
        to={{
          pathname: '/admin-panel/finance/accounts-receivable/create',
          state: { invoicingLinesIds },
        }}
      >
        Create invoice
      </Link>
    );
    return link;
  }, [selectedRows]);

  const addButton = {
    link: `/admin-panel/finance/invoicing-lines/create`,
  };

  const deleteRowHandler = id => {
    deleteInvoicingLineById(id).then(res => {
      showSuccessNotification('Successfully deleted invoicing line');
      if (invoicingLinesData) {
        const newRows = invoicingLinesData.rows.filter(obj => obj.id !== id);
        setInvoicingLinesData(prev => ({
          ...prev,
          rows: newRows,
        }));
      }
    });
  };

  const renderRowMenu = row => {
    if (activeTab !== 'To be invoiced') return null;
    return [
      [
        {
          text: 'Delete row',
          onClick: () => deleteRowHandler(row?.original?.id),
          show: true,
          icon: TrashIcon,
          disabled:
            row?.original?.type !== lineItemTypesV2.accountsReceivable.numbers.customAllocationsToBeInvoiced ? true : false,
        },
      ],
    ];
  };

  const onShowSummaryPageClick = () => {
    getCounterparts(selectedRows.map(row => row.original.id)).then(res => {
      let totalCost = 0;
      let totalVatRecovered = 0;
      res.forEach(item => {
        item.childLineItems.forEach(child => {
          totalCost += Number(child.total);
          totalVatRecovered += Number(child.vat);
        });
      });
      setAccountsReceivableTotals(prev => ({
        ...prev,
        totalCost: totalCost,
        totalVatRecovered: totalVatRecovered,
        totalGrossMargin: prev.totalRevenue - totalCost,
        totalVatBalance: prev.totalVatOwed - totalVatRecovered,
      }));
      setShowAccountsReceivable(false);
      setShowSummaryModal(true);
    });
  };

  return (
    <AdminSidebar noPadding pages={pages}>
      <TableTabs tabs={tabs} />
      <PreviewBox
        filePath={previewFile.path}
        fileType={previewFile.type}
        title={previewFile.title}
        showPreview={previewFile ? true : false}
        handleHide={() => setPreviewFile(false)}
      />
      <CreateAccountsReceivableModal
        show={showSummaryModal}
        setShow={setShowSummaryModal}
        totals={accountsReceivableTotals}
        setShowOverlay={setShowAccountsReceivable}
        invoiceNumber={invoiceNumber}
        setInvoiceNumber={setInvoiceNumber}
        CreateAccountsReceivableLink={CreateAccountsReceivableLink}
      />
      <CreateAccountsReceivableOverlay
        show={showAccountsReceivable}
        number={selectedRows.length}
        setShow={setShowAccountsReceivable}
        onClick={() => {
          onShowSummaryPageClick();
        }}
        clearSelection={() => setSelectedRows([])}
        totalRevenue={accountsReceivableTotals?.totalRevenue}
      />
      <Table
        columns={columns}
        data={tableData}
        fetchData={fetchInvoicingLines}
        pageCount={invoicingLinesData?.totalPages}
        rowCheckbox={activeTab === 'To be invoiced' ? true : false}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        tableName={tableName}
        totalItems={invoicingLinesData?.totalItems}
        activeTab={activeTab}
        addButton={addButton}
        customContextMenu
        contextMenuOptions={renderRowMenu}
      />
    </AdminSidebar>
  );
}

export default InvoicingLines;
