import React, { useEffect, useState } from 'react';
import InputDate from '../Inputs/InputDate/InputDate';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import Checkbox from '../Checkbox/Checkbox';

function DateRangeColumnFilter({ column: { filterValue = [], preFilteredRows, setFilter, id, filterOptions, openEndedOption } }) {
  dayjs.extend(utc);
  dayjs.extend(customParseFormat);
  const [checked, setChecked] = useState(filterValue[0] === 0 && filterValue[1] === 0 ? true : false);
  const [min, max] = React.useMemo(() => {
    if (filterOptions) return [filterOptions.minDate, filterOptions.maxDate];
    else return [null, null];
    // DEACTIVATED FOR SERVER SIDE TABLE
    // let min = preFilteredRows?.length ? dayjs.utc(preFilteredRows[0].values[id], 'DD/MM/YYYY') : null;
    // let max = preFilteredRows?.length ? dayjs.utc(preFilteredRows[0].values[id], 'DD/MM/YYYY') : null;
    // preFilteredRows?.forEach(row => {
    //   const currentDate = dayjs.utc(row.values[id], 'DD/MM/YYYY');
    //   min = currentDate.isBefore(min) ? currentDate : min;
    //   max = currentDate.isAfter(max) ? currentDate : max;
    // });
    // return [min, max];
  }, [id, preFilteredRows, filterOptions]);

  const [beginning, setBeginning] = useState(null);
  const [end, setEnd] = useState(null);

  return (
    <>
      <div className={'flex items-center flex-col sm:flex-row'}>
        <span className="mx-2">From</span>
        <InputDate
          className={`${
            filterValue[0] ? '' : 'text-gray-500 '
          } shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md`}
          onChange={val => {
            setBeginning(val ? new Date(val) : undefined);
            setFilter((old = []) => {
              let endValue = old.length === 2 ? old[1] : undefined;
              if (!val && !endValue) return null;
              return [val ? new Date(val).getTime() : undefined, endValue];
            });
          }}
          placeholder={min ? `Min (${dayjs(min).format('DD/MM/YYYY')})` : false}
          minDate={min ? min : null}
          selected={filterValue[0] ? filterValue[0] : beginning}
          disabled={filterValue[0] === 0}
        />
        <span className="mx-2">To</span>
        <InputDate
          className={`${
            filterValue[0] ? '' : 'text-gray-500 '
          } shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md`}
          onChange={val => {
            setEnd(val ? new Date(val) : undefined);
            setFilter((old = []) => {
              //Code below prevents errors if filters are reset
              let beginningValue = old.length ? old[0] : undefined;
              if (!val && !beginningValue) return null;
              return [beginningValue, val ? new Date(val).getTime() : undefined];
            });
          }}
          placeholder={max ? `Max (${dayjs(max).format('DD/MM/YYYY')})` : false}
          maxDate={max ? max : null}
          selected={filterValue[1] ? filterValue[1] : end}
          disabled={filterValue[0] === 0}
        />
        {openEndedOption && (
          <span className="w-full">
            <Checkbox
              title="Open-ended"
              horizontal
              className="w-full px-6"
              value={checked}
              onChange={e => {
                if (e.target.checked) {
                  setChecked(true);
                  setFilter([0, 0]);
                } else {
                  setChecked(false);
                  setFilter(beginning || end ? [beginning, end] : null);
                }
              }}
              // value={filterValue[0] === 1 && filterValue[1] === 0 ? true : false}
            />
          </span>
        )}
      </div>
    </>
  );
}

export default DateRangeColumnFilter;
