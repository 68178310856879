import contractTypes from './contractTypeIDs';

export const LeaveRequestStatus = {
  pending: 0,
  submitted: 1,
  actionRequired: 2,
  approved: 3,
  denied: 4,
  deleted: 5,
};

export const LeaveRequestType = {
  paidLeave: 0,
  unPaidLeave: 1,
  medicalLeave: 2,
  trainingLeave: 3,
};

export const LeaveRequestStatusLabels = {
  0: 'Draft',
  1: 'Submitted',
  2: 'Action required',
  3: 'Approved',
  4: 'Denied',
  5: 'Deleted',
};

export const LeaveRequestTypeLabel = {
  0: 'Paid leave',
  1: 'Unpaid leave',
  2: 'Medical leave',
  3: 'Training leave',
};

export const UserLeaveRequestTypeLabel = (index, staffContractTypeId) => {
  let employeeLabels = {
    0: 'Paid leave',
    1: 'Unpaid leave',
    2: 'Medical leave',
    3: 'Training leave',
  };
  let freelancerLabels = {
    0: 'Paid leave',
    1: 'Leave',
    2: 'Medical leave',
    3: 'Training leave',
  };

  if (index !== undefined) {
    if (staffContractTypeId === contractTypes.freelancer || staffContractTypeId === contractTypes.company)
      return freelancerLabels[index];
    else return employeeLabels[index];
  } else {
    if (staffContractTypeId === contractTypes.freelancer || staffContractTypeId === contractTypes.company)
      return freelancerLabels;
    else return employeeLabels;
  }
};
