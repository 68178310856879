import { useState } from 'react';
import * as React from 'react';

interface Tab {
  name: string;
  onClick: () => void;
}

interface TableTabsProps {
  tabs: Tab[];
}

const TableTabs: React.FC<TableTabsProps> = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (tabIndex: number) => {
    setActiveTab(tabIndex);
    tabs[tabIndex].onClick();
  };

  return (
    <div>
      <ul className="flex w-full bg-white divide-x divide-gray-300">
        {tabs.map((tab: Tab, index: number) => (
          <li
            key={index}
            onClick={() => handleTabClick(index)}
            className={`grow text-center py-2 cursor-pointer text-sm ${activeTab === index ? 'active relative' : 'relative'}`}
          >
            {tab.name}
            {activeTab === index ? (
              <div className="absolute bottom-0 left-0 right-0 h-1 border-b-2 border-thaleria-orange-500" />
            ) : (
              <div className="absolute bottom-0 left-0 right-0 h-1 border-b border-gray-300" />
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TableTabs;
