import React from 'react';
import PageWrapperV2 from '../../../containers/App/PageWrapper';
import MyLeaveRequestTabList from '../../../containers/LeaveRequests/MyLeaveRequestTabList';
import Card from '../../../components/Cards/Card';
import LeaveBalance from '../../../containers/LeaveRequests/LeaveBalance';
import LeaveBalanceLog from '../../../containers/LeaveRequests/LeaveBalanceLog';
import ButtonPrimary from '../../../components/Buttons/ButtonPrimary';
import { useHistory } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/solid';
import { useSelector } from 'react-redux';
import contractTypes from '../../../helpers/enum/contractTypeIDs';
import { ExclamationCircleIcon } from '@heroicons/react/outline';

const MyLeaveRequests = () => {
  const history = useHistory();

  const currentUser = useSelector(state => state.auth.currentUser);

  const canCreateLeaveRequests = currentUser.StaffContracts.length;
  const displayBalanceAndChanges = currentUser.StaffContracts[0]?.contract_type_id === contractTypes.employee;

  const STYLES_LOG = {
    height: '500px',
  };

  const pages = [{ name: 'Leave Requests', href: '/leave-request', current: true }];

  return (
    <PageWrapperV2 pages={pages}>
      <div className="grid grid-cols-1 lg:grid-cols-9 gap-4">
        <div className={`flex flex-col ${displayBalanceAndChanges ? 'lg:col-span-5' : 'lg:col-span-9'}`}>
          <MyLeaveRequestTabList />
        </div>
        {displayBalanceAndChanges && (
          <div className="flex flex-col lg:col-span-4 gap-4">
            <LeaveBalance onlyTotal />
            <Card className="overflow-y-auto w-full" title="History" auto style={STYLES_LOG}>
              <LeaveBalanceLog staffContractId={currentUser.StaffContracts[0].id} />
            </Card>
          </div>
        )}
      </div>
      <div className="flex justify-end w-full my-4 gap-4">
        {/* {!canCreateLeaveRequests && (
          <span className="text-orange-400 flex items-center text-sm">
            <ExclamationCircleIcon className="h-5 w-5 ml-2 mr-1" /> Can't create leave requests without an active Staff contract.
          </span>
        )} */}
        <ButtonPrimary
          onClick={() => history.push('/leave-request/create')}
          text="New Leave Request"
          size="xs"
          title={!canCreateLeaveRequests && 'You need an active staff contract to be able to submit leave requests'}
          icon={<PlusIcon className="h-5 w-5 mr-2" />}
          disable={!canCreateLeaveRequests}
        />
      </div>
    </PageWrapperV2>
  );
};

export default MyLeaveRequests;
