import { useMemo, useState, useEffect } from 'react';
import ButtonPrimary from '../../../components/Buttons/ButtonPrimary';
import ButtonWhite from '../../../components/Buttons/ButtonWhite';
import InputDate from '../../../components/Inputs/InputDate/InputDate';
import Modal from '../../../components/Modals/Modal';
import Toggle from '../../../components/Toggles/Toggle';

const UpdateMilestoneModal = ({ show, hide, data, onClick }) => {
  const [status, setStatus] = useState(data ? true : false);
  const [date, setDate] = useState(null);
  const [error, setError] = useState(null);

  const onToggle = () => {
    if (status) {
      setDate(null);
    }
    setStatus(!status);
  };

  const onDateChange = newDate => {
    if (!status) {
      setStatus(!status);
    }

    if (!newDate && status) {
      setStatus(!status);
    }

    if (newDate && error) setError(null);

    setDate(newDate);
  };

  const onSave = () => {
    if (status && !date) {
      setError('Date is required');
    } else {
      onClick(date);
    }
  };

  const contractType = useMemo(() => {
    let _type;

    switch (data?.contract) {
      case 'po':
        _type = 'Purchase Order';
        break;
      case 'so':
        _type = 'Sales Order';
        break;
      case 'sc':
        _type = 'Specific Contract';
        break;
    }
    return _type;
  }, [show]);

  useEffect(() => {
    setDate(data?.date ? new Date(data.date) : null);
    setStatus(data?.date ? true : false);
  }, [show]);

  return (
    <Modal title={`Update ${contractType} milestone`} hide={hide} show={show} size="md">
      <div className="space-y-4">
        <Toggle label="Milestone completed" enable={status} onChange={() => onToggle()} />
        <InputDate label="Completion date" onChange={value => onDateChange(value)} selected={date} error={error} />
        <div className="flex justify-end gap-x-4">
          <ButtonWhite text="Cancel" onClick={hide} />
          <ButtonPrimary text="Save" onClick={() => onSave()} />
        </div>
      </div>
    </Modal>
  );
};

export default UpdateMilestoneModal;
