import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import classNames from '../../utils/classNames';
// import useAppNotification from '../../store/appNotification/appNotificationContext';

function ContextMenu({ data, elementClassNameTag = 'context-menu', menuOptions, contextMenu, setContextMenu, setRowHighlight }) {
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
  const [anchorX, setAnchorX] = useState();
  const [anchorY, setAnchorY] = useState();

  const menuRef = useRef(null);

  const contextMenuHandler = useCallback(
    event => {
      event.preventDefault();
      let item;

      var path = event.path || (event.composedPath && event.composedPath());

      if (path) {
        item = path.find(el => (el?.className?.length ? el?.className?.includes(elementClassNameTag) : false));
      } else {
        alert('Your browser does not support this context menu');
      }

      let id = item?.id;
      let selectedItem;
      setAnchorX(event.pageX);
      setAnchorY(event.pageY);

      setAnchorPoint({ x: 0, y: 0 });

      if (data[0]?.values) {
        selectedItem = data.find(object => id == object?.values?.id || id == object?.original?.id);
      } else {
        selectedItem = data.find(object => id == object.id);
      }
      setContextMenu(menuOptions(selectedItem));
      setRowHighlight(item.id);
    },
    [data, menuRef],
  );

  const handleClick = useCallback(() => {
    if (contextMenu) {
      setContextMenu(null);
      setRowHighlight(false);
      return;
    } else return null;
  }, [contextMenu]);

  useEffect(() => {
    const items = document.getElementsByClassName(elementClassNameTag);
    Array.from(items).forEach(item => {
      item.addEventListener('contextmenu', contextMenuHandler);
    });
    document.addEventListener('click', handleClick);
    return () => {
      Array.from(items).forEach(item => {
        item.removeEventListener('contextmenu', contextMenuHandler);
      });
      document.removeEventListener('click', handleClick);
    };
  });

  useEffect(() => {
    //Check if click happened close to the bottom or right edge, than add offset to anchorpoint
    let x = anchorX;
    let y = anchorY;
    if (anchorX + menuRef.current.clientWidth > window.window.innerWidth) x = anchorX - menuRef.current.clientWidth;
    if (anchorY + menuRef.current.clientHeight > window.window.innerHeight) y = anchorY - menuRef.current.clientHeight;
    setAnchorPoint({ x: x, y: y });
  }, [contextMenu]);

  return (
    <div
      style={{
        top: anchorPoint.y,
        left: anchorPoint.x,
      }}
      ref={menuRef}
      className={`absolute ${
        !contextMenu && 'hidden'
      } border border-gray-200/50 border-dotted z-50 text-sm font-medium text-gray-300 bg-gray-800 rounded-lg shadow-md divide-y divide-gray-600`}
    >
      {contextMenu?.length &&
        contextMenu.map((array, index) => (
          <div className="p-2">
            {/* {index !== 0 && <div className="border-b border-gray-700" />} */}
            {array.map((item, index) => (
              <div>
                {item.linkTo ? (
                  <Link
                    to={item.linkTo}
                    className={classNames(
                      'p-2 group flex w-full rounded-lg text-sm hover:bg-gray-700 hover:text-white ',
                      item.show === false && 'hidden',
                    )}
                    key={item.text}
                  >
                    {item?.icon && <item.icon className="mr-1 group-hover:text-white text-gray-400 w-5 h-5" />}
                    <span className="ml-1">{item.text}</span>
                  </Link>
                ) : (
                  <button
                    className={classNames(
                      `p-2 group flex w-full rounded-lg text-sm ${
                        item?.disabled ? 'cursor-default' : 'hover:bg-gray-700 hover:text-white'
                      }`,
                      item.show === false && 'hidden',
                    )}
                    onClick={() => {
                      setContextMenu(false);
                      return item.onClick();
                    }}
                    disabled={item?.disabled ? item?.disabled : false}
                  >
                    {item?.icon && (
                      <item.icon className={`mr-1 ${item?.disabled ? '' : 'group-hover:text-white'} text-gray-400  w-5 h-5`} />
                    )}
                    <span className="ml-1">{item.text}</span>
                  </button>
                )}
              </div>
            ))}
          </div>
        ))}
    </div>
  );
}

export default ContextMenu;
