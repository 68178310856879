import React from 'react';
import PageWrapperV2 from '../../../containers/App/PageWrapper';
import CreateOrEditAnnouncement from '../../../containers/Home/Announcements/CreateOrEditAnnouncement';

const CreateAnnouncement = () => {
  const pages = [{ name: 'Create announcement', href: '/announcement/create', current: true }];
  return (
    <PageWrapperV2 pages={pages}>
      <div className="w-full px-4 py-5 bg-white sm:p-6 rounded">
        <CreateOrEditAnnouncement />
      </div>
    </PageWrapperV2>
  );
};

export default CreateAnnouncement;
