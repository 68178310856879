import format from 'date-fns/format';
import formatCurrency from '../utils/formatCurrency';

export default function replaceCommentVariables(text, invoiceData) {
  const replacements = {
    issue_date: invoiceData.issue_date ? format(invoiceData.issue_date, 'MMM do, yyyy') : '{{issue_date}}',
    total_incl_vat: invoiceData?.revenue_and_vat ? formatCurrency(invoiceData.revenue_and_vat, true, true) : '{{total_incl_vat}}',
    receivables_iban: invoiceData.metadata.footer_data.iban || '{{receivables_iban}}',
    due_date: invoiceData.due_date ? format(invoiceData.due_date, 'MMM do, yyyy') : '{{due_date}}',
  };
  const regex = /\{\{([^{}]+)\}\}/g;
  const replacedText = text.replace(regex, (match, placeholder) => {
    const replacement = replacements[placeholder.trim()];
    return replacement !== undefined ? replacement : match;
  });
  return replacedText;
}
