import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Document from '../../components/Document/Document';
import FilesIcon from '../../components/Icons/FilesIcon';
import { bytesToSize } from '../../helpers';
import dayjs from 'dayjs';
import { TrashIcon, DownloadIcon } from '@heroicons/react/outline';
import ClipLoader from 'react-spinners/ClipLoader';
import SimpleAlert from '../../components/Modals/SimpleAlert';
import { EyeIcon } from '@heroicons/react/outline';

const MultiDocuments = ({
  files: filesProp,
  deleteAccess,
  small,
  selectedFile,
  setSelectedFile,
  threeDotMenu,
  listDisplay = false,
  forAdmins,
  loading,
  downloadFile,
  preparingPreview,
  setPreparingPreview,
  previewHandler,
}) => {
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [folderToDelete, setFolderToDelete] = useState({});

  const files = useMemo(() => {
    if (!filesProp) return [];
    return filesProp.sort((fileA, fileB) => {
      if (fileA.type === fileB.type) {
        return fileA.name.toLowerCase().localeCompare(fileB.name.toLowerCase());
      }
      if (fileA.type === 'file' && fileB.type === 'folder') {
        return 1;
      } else return -1;
    });
  }, [filesProp]);

  const onClick = file => {
    if (selectedFile?.path != file?.path) {
      setSelectedFile(file);
    } else {
      setSelectedFile();
    }
  };

  const footerHeight = document.getElementById('footer')?.offsetHeight;
  const headerHeight = document.getElementById('header')?.offsetHeight;
  const docHeaderHeight = document?.getElementById('document-header')?.offsetHeight;

  const STYLES = {
    height: `calc(100vh - ${footerHeight + headerHeight + docHeaderHeight}px)`,
  };

  const checkFileType = fileName => {
    let fileType;
    switch (fileName.split('.')[fileName.split('.').length - 1]) {
      case 'pdf':
        fileType = 'PDF';
        break;
      case 'docx':
      case 'doc':
        fileType = 'Word';
        break;
      case 'pptx':
        fileType = 'PowerPoint';
        break;
      case 'xlsx':
      case 'xls':
      case 'xlsm':
        fileType = 'Excel';
        break;
      case 'xml':
      case 'csv':
        fileType = 'Excel';
        break;
      case 'txt':
        fileType = 'Text';
        break;
      case 'zip':
      case 'rar':
        fileType = 'Zip file';
        break;
      case 'png':
      case 'jpg':
      case 'jpeg':
      case 'tiff':
        fileType = 'Image';
        break;
      case 'folder':
      case '':
        fileType = 'Folder';
        break;
      default:
        fileType = fileName.split('.')[fileName.split('.').length - 1];
        break;
    }

    return fileType;
  };

  const onDelete = file => {
    if (file?.capacity == null) {
      //is folder so confirm delete modal needed
      setShowDeleteAlert(true);
      setFolderToDelete(file);
    } else {
      confirmDelete(file);
    }
  };

  const confirmDelete = file => {
    setShowDeleteAlert(false);
    if (file?.onDeleteClick) {
      file.onDeleteClick();
    } else {
      folderToDelete.onDeleteClick();
    }
  };

  const onPreviewHandler = (filePath, e) => {
    e.stopPropagation();
    setPreparingPreview && setPreparingPreview(filePath);
    previewHandler && previewHandler(filePath);
  };

  const onDownloadHandler = (filePath, e) => {
    e.stopPropagation();
    downloadFile(filePath);
  };

  const onDeleteHandler = (filePath, e) => {
    e.stopPropagation();
    onDelete(filePath);
  };

  const isPreviewable = fileName => {
    let bool = false;
    const extension = fileName.split('.')[fileName.split('.').length - 1].toLowerCase();
    if (extension === 'pdf' || extension === 'jpg' || extension === 'png' || extension === 'jpeg') bool = true;
    return bool;
  };

  const regularDisplay = (
    <div className={`py-3 block relative overflow-y-auto max-h-full h-full ${forAdmins ? 'bg-white' : ''}`} style={STYLES}>
      <SimpleAlert
        show={showDeleteAlert}
        hide={() => setShowDeleteAlert(false)}
        errorTitle="Delete folder?"
        errorMsg="Deleting this folder will also delete all files and folders contained within. Are you sure you want to proceed?"
        onAcceptText="Delete"
        onDeclineText="Cancel"
        onAcceptClick={() => confirmDelete()}
        onDeclineClick={() => setShowDeleteAlert(false)}
      />
      {files?.length === 0 && <h2 className="ml-8 mt-4 text-gray-500">No files to display.</h2>}
      <div className={`flex flex-wrap gap-2 2xl:gap-6 pl-4`}>
        {files?.map((file, index) => {
          return (
            <>
              <div
                key={file.path}
                onClick={() => onClick(file)}
                className={`${
                  selectedFile?.path == file?.path
                    ? 'rounded-md bg-thaleria-blue-50 px-1 py-2'
                    : 'rounded-md hover:bg-thaleria-blue-50 px-1 py-2'
                }`}
              >
                <Document
                  name={file?.name}
                  type={file.type}
                  capacity={file.capacity}
                  onDoubleClick={file.onDoubleClick}
                  onDeleteClick={file.onDeleteClick}
                  onDownloadClick={file.onDownloadClick}
                  onPreviewClick={file.onPreviewClick}
                  deleteAccess={deleteAccess}
                  threeDotMenu={threeDotMenu}
                  index={index}
                  listDisplay={listDisplay}
                  forAdmins={forAdmins}
                  small={small}
                  loading={loading}
                  filePath={file?.path}
                />
              </div>
            </>
          );
        })}
      </div>
    </div>
  );

  const tableDisplay = (
    <div className="block relative overflow-y-auto bg-white" style={STYLES}>
      <SimpleAlert
        show={showDeleteAlert}
        hide={() => setShowDeleteAlert(false)}
        errorTitle="Delete folder?"
        errorMsg="Deleting this folder will also delete all files and folders contained within. Are you sure you want to proceed?"
        onAcceptText="Delete"
        onDeclineText="Cancel"
        onAcceptClick={() => confirmDelete()}
        onDeclineClick={() => setShowDeleteAlert(false)}
      />
      {files?.length === 0 ? (
        <h2 className="ml-8 mt-4 text-gray-500">No files to display.</h2>
      ) : (
        <table className="w-full space-x-2">
          <thead className="bg-gray-100 h-10 sticky top-0">
            <tr className="">
              {/* <th className="w-10"></th> */}
              <th className="text-left pl-9 p-3">Name</th>
              <th className="text-left  p-3">Uploaded</th>
              <th className="text-left  p-3">Size</th>
              <th className="text-left  p-3">Type</th>
              <th className="text-left p-3 w-20"></th>
            </tr>
          </thead>
          <tbody className="px-3">
            {files?.map((file, index) => {
              return (
                <>
                  <tr
                    key={file?.path}
                    onClick={() => onClick(file)}
                    onDoubleClick={() => file?.onDoubleClick()}
                    className={`gap-y-4
                  ${
                    selectedFile?.path == file?.path
                      ? 'rounded-md bg-gray-100 px-1 py-2'
                      : 'rounded-md group hover:bg-blue-50 px-1 py-2'
                  }`}
                  >
                    {/* <td></td> */}
                    <td className="p-3 text-sm text-gray-500 cursor-default">
                      <div className="truncate flex justify-start gap-x-4 items-center">
                        <FilesIcon
                          fileType={file?.capacity ? file?.name.split('.')[file?.name.split('.').length - 1] : 'folder'}
                          className="w-4 h-4 shrink-0"
                        />
                        {file?.name}
                      </div>
                    </td>
                    <td className="p-3 text-sm text-gray-500 cursor-default">
                      {file?.uploadedAt && dayjs(file.uploadedAt).format('DD/MM/YYYY')}
                    </td>
                    <td className="p-3 text-sm text-gray-500 cursor-default">{file?.capacity && bytesToSize(file.capacity)}</td>
                    <td className="p-3 text-sm text-gray-500 cursor-default">
                      {file?.capacity ? checkFileType(file?.name) : 'Folder'}
                    </td>

                    <td className="p-3 text-sm text-gray-500 cursor-default">
                      <div className="flex justify-end space-x-4 items-center">
                        {isPreviewable(file.name) &&
                          (preparingPreview === file?.path ? (
                            <ClipLoader className="mr-2" size={18} color={'#4f46e5'} />
                          ) : (
                            <button
                              onClick={e => onPreviewHandler(file?.path, e)}
                              type="button"
                              disabled={loading?.downloadFile}
                              className="bg-white rounded-md font-medium text-thaleria-blue-400 hover:text-thaleria-blue-500"
                            >
                              <EyeIcon
                                className={`${
                                  selectedFile?.path == file?.path ? 'bg-gray-100' : 'group-hover:bg-blue-50'
                                } w-5 h-5  text-thaleria-blue-400 hover:text-thaleria-blue-500`}
                              />
                            </button>
                          ))}
                        {file.capacity &&
                          (loading?.downloadFile == file.path ? (
                            <ClipLoader className="mr-10 h-5 w-5" size={18} color={'#506B8E'} />
                          ) : (
                            <DownloadIcon
                              className="h-5 w-5 text-thaleria-blue-400 cursor-pointer hover:text-thaleria-blue-500"
                              onClick={e => onDownloadHandler(file?.path, e)}
                            />
                          ))}
                        {loading?.deleteObj == file.path ? (
                          <ClipLoader className="mr-10 h-5 w-5" size={18} color={'#ef4444'} />
                        ) : (
                          <TrashIcon
                            className="h-5 w-5 text-red-500 cursor-pointer hover:text-red-600"
                            onClick={e => onDeleteHandler(file, e)}
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );

  return listDisplay ? tableDisplay : regularDisplay;
};

export default MultiDocuments;

Document.prototype = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      type: PropTypes.oneOf(['file', 'folder']),
      name: PropTypes.string,
      capacity: PropTypes.string,
      onDoubleClick: PropTypes.func,
      onDeleteClick: PropTypes.func,
      onDownloadClick: PropTypes.func,
      deleteAccess: PropTypes.bool,
    }),
  ),
};
