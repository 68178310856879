import React, { useEffect, useState, useRef } from 'react';

import Loader from '../../components/Loading/Loader';
import moment from 'moment';
import { bytesToSize } from '../../helpers';
import useApi from '../../hooks/useApi';
import { PencilIcon } from '@heroicons/react/solid';
import FilesIcon from '../../components/Icons/FilesIcon';
import InputSimple from '../../components/Inputs/InputSimple';
import ClipLoader from 'react-spinners/ClipLoader';
import SimpleAlert from '../../components/Modals/SimpleAlert';

const FileSideBar = ({
  file,
  downloadFile,
  users,
  setShowEditModal,
  editDocumentName,
  editNameInput,
  setEditNameInput,
  loading,
  isOrgDocument,
}) => {
  const [allCountries, setAllCountries] = useState([]);
  const [allContractTypes, setAllContractTypes] = useState([]);
  const [allUserTypes, setAllUserTypes] = useState([]);
  const {
    userTypes: { getAllUserTypes },
    contractTypes: { getAllContractTypes },
    countries: { getAllCountries },
  } = useApi();

  const [acceptedAccounts, setAcceptedAccounts] = useState('');
  const [acceptedContracts, setAcceptedContracts] = useState('');
  const [acceptedCountries, setAcceptedCountries] = useState('');
  const [error, setError] = useState(false);
  const [newName, setNewName] = useState(file?.name);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    getAllCountries().then(res => setAllCountries(res));
    getAllContractTypes().then(res => setAllContractTypes(res));
    getAllUserTypes().then(res => setAllUserTypes(res));
  }, []);

  useEffect(() => {
    if (!file?.restrictions || !isOrgDocument) return;
    setAcceptedAccounts(
      file?.restrictions?.acceptedAccountTypes && file.restrictions.acceptedAccountTypes.length >= 3
        ? 'All'
        : file.restrictions?.acceptedAccountTypes.map(num => allUserTypes.find(obj => obj.id == num).account_type).join(', '),
    );
    setAcceptedContracts(
      file?.restrictions.acceptedContractTypes && file.restrictions.acceptedContractTypes.length == 3
        ? 'All'
        : file.restrictions.acceptedContractTypes
            .map(num => allContractTypes.find(obj => obj.id == num).contract_type)
            .join(', '),
    );
    setAcceptedCountries(
      file?.restrictions.acceptedCountries
        ? file.restrictions.acceptedCountries.map(num => allCountries.find(obj => obj.id == num).name).join(', ')
        : 'All',
    );
  }, [allCountries, allContractTypes, allUserTypes, file]);

  useEffect(() => {
    setEditNameInput(false);
  }, [file]);

  const saveNewName = () => {
    if (newName.includes('/') || newName.includes('.')) {
      //FIXME: Error handling on name validation should be only done in one place. Currently there is modal for special characters + error message here. Also, modal is not working properly on speciali characters. Bad request is received from backend instead. Ideally, either modal or badrequest should capture everything related to name validation.
      setError("File and folder names cannot have '.' or '/' characters.");
    }
    if (newName == '') {
      setError('Name cannot be empty');
    } else {
      setError(false);
      let path = file?.path.split('/');
      const editedName =
        file?.name.split('.').length > 1 ? newName + '.' + file?.name.split('.')[file?.name.split('.').length - 1] : newName;
      const lastString = path.pop();
      if (lastString == '') {
        path.pop();
        path.push(editedName);
        path.push('');
      } else {
        path.push(editedName);
      }
      const data = {
        path: file?.path,
        newPath: path.join('/'),
        newName: editedName,
      };

      editDocumentName(data);
      setEditNameInput(false);
    }
  };

  const updateName = () => {
    setNewName(file?.name.split('.').length > 1 ? file?.name.split('.')[0] : file?.name);
    setEditNameInput(true);
  };

  const onChangeNewName = e => {
    setNewName(e.target.value);
  };

  document.onkeydown = function (evt) {
    evt = evt || window.event;
    var isEscape = false;
    var isEnter = false;
    if ('key' in evt) {
      isEscape = evt.key === 'Escape' || evt.key === 'Esc';
      isEnter = evt.key === 'Enter' || evt.key === 'Ent';
    } else {
      isEscape = evt.keyCode === 27;
      isEnter = evt.keyCode === 13;
    }
    if (isEscape) {
      if (editNameInput) setEditNameInput(false);
    }
    if (isEnter && document.activeElement === inputRef.current) {
      if (editNameInput) saveNewName();
    }
  };

  const isFolder = file?.capacity == null || file.path.split('/')[file.path.split('/').length - 1] == '';

  const confirmDelete = () => {
    file.onDeleteClick();
    setShowDeleteAlert(false);
  };

  return (
    <div className="w-[300px] border-l border-gray-200 p-4 bg-gray-100 shrink-0 ">
      <SimpleAlert
        show={showDeleteAlert}
        hide={() => setShowDeleteAlert(false)}
        errorTitle="Delete folder?"
        errorMsg="Deleting this folder will also delete all files and folders contained within. Are you sure you want to proceed?"
        onAcceptText="Delete"
        onDeclineText="Cancel"
        onAcceptClick={() => confirmDelete()}
        onDeclineClick={() => setShowDeleteAlert(false)}
      />

      {file ? (
        <aside className="hidden border-gray-200 lg:block">
          <div className="space-y-4">
            <div>
              <div className="w-full overflow-hidden rounded-lg flex flex-col justify-center items-center mb-8">
                <FilesIcon
                  className="w-28 h-28"
                  fileType={file?.capacity ? file?.name.split('.')[file?.name.split('.').length - 1] : 'folder'}
                />
              </div>
            </div>
            <div>
              {editNameInput ? (
                <div className="w-full">
                  <InputSimple value={newName} onChange={e => onChangeNewName(e)} error={error} reference={inputRef} />
                </div>
              ) : (
                <div className="flex w-full justify-between items-center">
                  <h3 className="font-medium text-gray-900 my-0 truncate pr-1 grow">{file?.name}</h3>
                  {loading?.editName == file.path ? (
                    <ClipLoader className="mr-2" size={17} color={'#4b5563'} />
                  ) : (
                    file?.capacity && (
                      <PencilIcon
                        className="h-5 w-5 cursor-pointer text-gray-600 hover:text-gray-900 shrink-0"
                        onClick={() => updateName()}
                        aria-hidden="true"
                      />
                    )
                  )}
                </div>
              )}
              <Loader isLoading={!users.length}>
                <dl className="mt-2 divide-y divide-gray-200 border-gray-200">
                  {file.capacity ? (
                    <div key={'uploadedBy'} className="flex justify-between py-3 text-sm font-medium">
                      <dt className="text-gray-500">Uploaded by</dt>
                      <dd className="whitespace-nowrap text-gray-900">
                        {file?.uploadedBy ? users.find(user => user.id == file.uploadedBy)?.full_name : 'n/a'}
                      </dd>
                    </div>
                  ) : (
                    <div key={'createdBy'} className="flex justify-between py-3 text-sm font-medium">
                      <dt className="text-gray-500">Created by</dt>
                      <dd className="whitespace-nowrap text-gray-900">
                        {file?.uploadedBy ? users.find(user => user.id == file.uploadedBy)?.full_name : 'n/a'}
                      </dd>
                    </div>
                  )}
                  {file.capacity ? (
                    <div key={'uploadedAt'} className="flex justify-between py-3 text-sm font-medium">
                      <dt className="text-gray-500">Uploaded at</dt>
                      <dd className="whitespace-nowrap text-gray-900">
                        {file?.uploadedAt ? moment(file.uploadedAt).format('DD/MM/YY') : 'n/a'}
                      </dd>
                    </div>
                  ) : (
                    <div key={'createdAt'} className="flex justify-between py-3 text-sm font-medium">
                      <dt className="text-gray-500">Created at</dt>
                      <dd className="whitespace-nowrap text-gray-900">
                        {file?.uploadedAt ? moment(file.uploadedAt).format('DD/MM/YY') : 'n/a'}
                      </dd>
                    </div>
                  )}
                  <div key={'size'} className="flex justify-between py-3 text-sm font-medium">
                    <dt className="text-gray-500">Size</dt>
                    <dd className="whitespace-nowrap text-gray-900">{file?.capacity ? bytesToSize(file.capacity) : 'n/a'}</dd>
                  </div>
                </dl>
              </Loader>
            </div>
            {isOrgDocument && (
              <div>
                <div className="flex w-full justify-between items-center">
                  <h3 className="font-medium text-gray-900 my-0">Access</h3>
                  {file?.capacity && (
                    <PencilIcon
                      className="h-5 w-5 cursor-pointer text-gray-600 hover:text-gray-900"
                      onClick={() => setShowEditModal(true)}
                      aria-hidden="true"
                    />
                  )}
                </div>
                <Loader isLoading={!users.length}>
                  <dl className="mt-2 divide-y divide-gray-200 border-gray-200">
                    <div key={'uploadedBy'} className="flex flex-col justify-between space-y-2 py-3 text-sm font-medium">
                      <dt className="text-gray-500">Account types</dt>
                      <dd className="whitespace-nowrap text-gray-900 truncate">{acceptedAccounts}</dd>
                    </div>
                    <div key={'uploadedBy'} className="flex flex-col justify-between space-y-2 py-3 text-sm font-medium">
                      <dt className="text-gray-500">Contract types</dt>
                      <dd className="whitespace-nowrap text-gray-900 truncate">{acceptedContracts}</dd>
                    </div>
                    <div key={'uploadedBy'} className="flex flex-col justify-between space-y-2 py-3 text-sm font-medium">
                      <dt className="text-gray-500">Countries</dt>
                      <dd className="whitespace-nowrap text-gray-900 truncate">{acceptedCountries}</dd>
                    </div>
                  </dl>
                </Loader>
              </div>
            )}
            <div className={`flex`}>
              {file?.capacity ? (
                <button
                  type="button"
                  className="flex-1 rounded-md border border-transparent bg-thaleria-orange-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-thaleria-orange-800 focus:outline-none focus:ring-2 focus:ring-thaleria-orange-600 focus:ring-offset-2"
                  onClick={() => !loading && downloadFile(file.path)}
                >
                  {loading?.downloadFile == file.path ? <ClipLoader className="mr-2" size={17} color={'#ffff'} /> : 'Download'}
                </button>
              ) : (
                <button
                  type="button"
                  className="flex-1 rounded-md border border-transparent bg-thaleria-orange-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-thaleria-orange-800 focus:outline-none focus:ring-2 focus:ring-thaleria-orange-600 focus:ring-offset-2"
                  onClick={() => !loading && file?.onDoubleClick()}
                >
                  {loading == 'downloadFile' ? <ClipLoader className="mr-2" size={17} color={'#ffff'} /> : 'Open'}
                </button>
              )}
              <button
                type="button"
                className="ml-3 flex-1 rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-thaleria-orange-600 focus:ring-offset-2"
                onClick={() => !loading && (isFolder ? setShowDeleteAlert(true) : file.onDeleteClick())}
              >
                {loading == 'deleteObj' ? <ClipLoader className="mr-2" size={17} color={'#4b5563'} /> : 'Delete'}
              </button>
            </div>
          </div>
        </aside>
      ) : (
        <span>No file selected</span>
      )}
    </div>
  );
};

export default FileSideBar;
