import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import ButtonPrimary from '../../components/Buttons/ButtonPrimary';
import { LeaveRequestStatus } from '../../helpers/enum/leaveRequest';
import SimpleAlert from '../../components/Modals/SimpleAlert';
import { useHistory } from 'react-router';
import { showSuccessNotification } from '../../store/app/actions';
import useApi from '../../hooks/useApi';

const RequestActions = ({ status, id, setLeaveStatus }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    leaveRequests: { updateMyLeaveRequest, deleteMyLeaveRequest, submitMyLeaveRequest },
  } = useApi();
  const [showAlert, setShowAlert] = useState(false);

  const handleStatusChange = status => {
    updateMyLeaveRequest(id, { status }).then(res => {
      setLeaveStatus(status);
    });
  };

  const submitLr = () => {
    submitMyLeaveRequest(id).then(res => {
      setLeaveStatus(LeaveRequestStatus.submitted);
      dispatch(showSuccessNotification('Leave request submitted successfully'));
    });
  };

  const handleRemoveRequest = () => {
    deleteMyLeaveRequest(id, { status: LeaveRequestStatus.deleted }).then(res => {
      setLeaveStatus(LeaveRequestStatus.deleted);
      dispatch(showSuccessNotification('Leave request deleted successfully'));
      history.push('/leave-request');
    });
  };

  const cancelSubmission = () => {
    deleteMyLeaveRequest(id, { status: LeaveRequestStatus.pending }).then(res => {
      setLeaveStatus(LeaveRequestStatus.pending);
      dispatch(showSuccessNotification('Leave request moved back to draft status'));
    });
  };

  return (
    <>
      <SimpleAlert
        errorTitle="Warning"
        errorMsg="Removing this will completely remove this leave request, and will delete it from the application. Are you sure you want to proceed?"
        onAcceptText="Proceed"
        onAcceptClick={() => handleRemoveRequest()}
        onDeclineText="Cancel"
        show={showAlert}
        hide={() => setShowAlert(false)}
      />
      {status === LeaveRequestStatus.pending && (
        <div className="flex justify-end">
          <ButtonPrimary size="xl" canDelete text="Delete" style="ml-2" onClick={() => setShowAlert(true)} />
          <ButtonPrimary size="xl" text="Submit" style="ml-2" onClick={submitLr} />
        </div>
      )}
      {status === LeaveRequestStatus.submitted && (
        <div className="flex justify-end">
          <ButtonPrimary size="xl" canDelete text="Cancel Submission" style="ml-2" onClick={cancelSubmission} />
        </div>
      )}
      {status === LeaveRequestStatus.actionRequired && (
        <div className="flex justify-end">
          <ButtonPrimary size="xl" canDelete text="Cancel Submission" style="ml-2" onClick={cancelSubmission} />
          <ButtonPrimary size="xl" text="Submit" style="ml-2" onClick={submitLr} />
        </div>
      )}
    </>
  );
};

export default RequestActions;
