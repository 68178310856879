import React, { useState, useEffect } from 'react';
import Modal from '../../components/Modals/Modal';
import { useDispatch } from 'react-redux';
import ReactSelect from '../../components/Inputs/ReactSelect';
import ButtonPrimary from '../../components/Buttons/ButtonPrimary';
import ButtonWhite from '../../components/Buttons/ButtonWhite';
import { differenceInCalendarMonths, addMonths } from 'date-fns';
import { showSuccessNotification } from '../../store/app/actions';
import useApi from '../../hooks/useApi';

var months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

function AddTimesheetModal({ fetchTimesheets, timesheets, show, hide }) {
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  const {
    staffContracts: { getUserStaffContracts },
    timesheets: { createTimesheet, getAllUserTimesheets },
    users: { getAllUsers },
  } = useApi();

  const [timesheetsToAddOptions, setTimesheetsToAddOptions] = useState([]);
  const [timesheetsToAdd, setTimesheetsToAdd] = useState([]);

  useEffect(() => {
    if (show) {
      getAllUsers().then(data => {
        setUsers(data.map(el => ({ ...el, label: el.full_name, value: el.id })));
      });
    }
  }, [show]);

  useEffect(() => {
    if (!selectedUser) return;
    // const userTimesheets = timesheets.filter(t => t.user_id === selectedUser.id);
    getAllUserTimesheets(selectedUser.id).then(userTimesheets => {
      getUserStaffContracts(selectedUser.id).then(data => {
        if (!data.length) return;

        //determine possible timesheets for user and add them to options
        const latestContract = data[0];
        const contractStartDate = new Date(latestContract.contract_start);
        const today = new Date();
        const numberOfDueTimesheets = differenceInCalendarMonths(today, contractStartDate);

        const totalDueTimesheets = [];
        for (var i = 0; i <= numberOfDueTimesheets; i++) {
          const timesheetPeriod = addMonths(contractStartDate, i);
          const timesheetMonth = timesheetPeriod.getMonth() + 1;
          const timesheetYear = timesheetPeriod.getFullYear();
          totalDueTimesheets.push({ month: timesheetMonth, year: timesheetYear, user_id: selectedUser.id, status: 0 });
        }

        const effectiveDueTimesheets = totalDueTimesheets.filter(
          t1 => !userTimesheets.some(t2 => t2.month === t1.month && t2.year === t1.year),
        );

        setTimesheetsToAddOptions(
          effectiveDueTimesheets.map(t => ({
            ...t,
            label: `${months[t.month - 1]} ${t.year}`,
            value: `${months[t.month - 1]} ${t.year}`,
          })),
        );
      });
    });
  }, [selectedUser]);

  const handleHide = () => {
    setUsers([]);
    setSelectedUser(null);
    setTimesheetsToAdd([]);
    setTimesheetsToAddOptions([]);
    hide();
  };

  const handleAddTimesheets = async () => {
    let i = 0;
    let createdTimesheets = [];
    for (const ts of timesheetsToAdd) {
      let tsToAdd = { ...ts };
      delete tsToAdd.label;
      delete tsToAdd.value;
      await createTimesheet(tsToAdd).then(data => {
        createdTimesheets.push(data);
      });
      i++;
    }
    fetchTimesheets();
    dispatch(showSuccessNotification('Timesheet(s) created successfully'));
    handleHide();
  };

  return (
    <Modal title="Add timesheet" size="lg" show={show} hide={handleHide}>
      <ReactSelect isSearchable label="Select user" options={users} selectedOptions={selectedUser} onChange={setSelectedUser} />

      <ReactSelect
        labelClass={'mt-4 text-gray-700'}
        isSearchable
        label="Select timesheet to add"
        isMulti
        options={timesheetsToAddOptions}
        selectedOptions={timesheetsToAdd}
        onChange={setTimesheetsToAdd}
        disabled={!selectedUser}
        orderOptions={false}
      />

      <div className="flex flex-row-reverse gap-x-4 mt-4">
        <ButtonPrimary text="Add timesheets" disable={timesheetsToAdd?.length === 0} onClick={handleAddTimesheets} />
        <ButtonWhite text="Cancel" onClick={handleHide} />
      </div>
    </Modal>
  );
}

export default AddTimesheetModal;
