import React, { useState, useEffect, useRef } from 'react';
import useApi from '../../../../hooks/useApi';
import { useHistory, useParams } from 'react-router-dom';
import { showSuccessNotification } from '../../../../store/app/actions';
import { useDispatch } from 'react-redux';
import EditAccountsReceivableForm from '../../../../containers/Finance/AccountsReceivable/EditAccountsReceivableForm';
import AccountsReceivablePDFDetails from '../../../../containers/Finance/AccountsReceivable/AccountsReceivablePDFDetails';
import paidStatus from '../../../../helpers/enum/paidStatus';
import AdminSidebar from '../../AdminSidebar';
import { differenceInDays } from 'date-fns';
import formatNumber from '../../../../utils/formatNumber';

function AccountsReceivableDetails() {
  const paidRef = useRef(null);
  const {
    clients: { getAllClients },
    countries: { getAllCountries },
    bankAccounts: { getBankAccountById },
    accountsReceivables: {
      getLatestInvoiceNumber,
      updateAccountsReceivable,
      getAccountsReceivableByInvoiceNb,
      updatePaidOrSentStatus,
      deleteAccountsReceivable,
    },
  } = useApi();

  const today = new Date();

  const { id } = useParams();

  const {
    location: { state },
  } = useHistory();
  const history = useHistory();

  const dispatch = useDispatch();

  const [paymentTermOptions, setPaymentTermOptions] = useState([
    { label: '30 days', value: 30, selected: false },
    { label: '60 days', value: 60, selected: false },
    { label: 'Custom', value: 'custom', selected: false },
  ]);
  const [clientOptions, setClientOptions] = useState([]);
  const [bankAccountOptions, setBankAccountOptions] = useState([]);
  const [formErrors, setFormErrors] = useState([]);
  const [lineItems, setLineItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [editInvoice, setEditInvoice] = useState(false);
  const [showPayModal, setShowPayModal] = useState(false);
  const [creditNotes, setCreditNotes] = useState([]);
  const [invoiceData, setInvoiceData] = useState({
    invoice_number: null,
    issue_date: new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate())),
    due_date: new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate() + 30)),
    client_id: null,
    revenue: null,
    vat: null,
    revenue_and_vat: null,
    sent: false,
    paid: null,
    bank_account_id: null,
    metadata: {
      to: {
        name: '',
        address: '',
        postal_code: '',
        city: '',
        country: '',
        vat_number: '',
      },
      from: {
        name: 'Thaleria S.A.',
        address: 'Avenue des Arts 56',
        postal_code: '1000',
        city: 'Brussels',
        country: 'Belgium',
        vat_number: 'BE 0704.867.821',
      },
      subject: 'Thaleria services',
      header_comment: `Please find below the details of your invoice containing the references of the framework contract and/or agreement, specific contract and/or purchase order, period covered, price per unit, quantity and unit of measurement.`,
      footer_comment: `Please pay the amount of {{total_incl_vat}} to the account {{receivables_iban}} by {{due_date}}.`,
      footer_data: {
        name: 'Thaleria S.A.',
        email: 'finance@thaleria.com',
        phone: '+32 474 95 74 11',
        vat_number: 'BE 0704.867.821',
        iban: '',
        bic_swift: '',
      },
      showReferences: {
        frameworkContract: true,
        partnerContract: true,
        salesOrder: true,
        specificContract: true,
      },
      merge_lines: false,
      hide_period: false,
    },
  });

  const getInitialData = async () => {
    let countries = [];
    await getAllCountries().then(data => (countries = data));

    let clients = [];
    await getAllClients().then(data => {
      clients = data.map(c => {
        return {
          label: c.name,
          value: c.id,
          clientDetails: {
            name: c.name,
            address: c.address,
            postal_code: c.postal_code,
            city: c.city,
            country: countries.find(ct => ct.id === c.country_id)?.name,
            vat_number: c.vat_number,
            country_id: c.country_id,
            type: c.type,
          },
        };
      });
    });

    let invoiceNumber;
    await getLatestInvoiceNumber().then(res => {
      let latestNumber = res?.invoice_number ? res.invoice_number + 1 : 1;
      invoiceNumber = latestNumber;
    });

    return {
      countries,
      clients,
      invoiceNumber,
    };
  };

  const fetchBankDetails = async arrayOfIds => {
    const bankAccounts = await Promise.all(
      arrayOfIds.map(async id => {
        const d = await getBankAccountById(id);
        return { ...d, label: d.name, value: d.id };
      }),
    );

    const uniqueBankAccounts = Array.from(new Map(bankAccounts.map(item => [item.id, item])).values());

    return uniqueBankAccounts;
  };

  useEffect(() => {
    //when line items change

    //update bank accounts
    const newBankAccountIds = [];
    const removedBankAccountIds = [];

    //update invoice total
    const newTotals = {
      revenue: 0,
      vat: 0,
      revenue_and_vat: 0,
    };

    bankAccountOptions.forEach(account => {
      if (!lineItems.find(item => item.bank_account_id === account.id)) {
        removedBankAccountIds.push(account.id);
      }
    });
    lineItems.forEach(line => {
      let lineTotal = formatNumber(line.units * line.price);
      newTotals.revenue += lineTotal;
      newTotals.vat += formatNumber((lineTotal * line.vat_rate) / 100);

      if (!bankAccountOptions.find(account => account.id === line.bank_account_id)) {
        newBankAccountIds.push(line.bank_account_id);
      }
    });

    //update bank accounts if some were added or removed
    if (newBankAccountIds.length || removedBankAccountIds.length) {
      //need to iterate manually between the three scnearios because i was having issues with the async fetch and this was the quickest fix
      if (newBankAccountIds.length && removedBankAccountIds.length) {
        fetchBankDetails(newBankAccountIds).then(newBankAccounts => {
          //add and remove accounts
          const updatedBankAccounts = [...bankAccountOptions, ...newBankAccounts].filter(account => {
            return removedBankAccountIds.find(id => id === account.id) ? false : true;
          });
          setBankAccountOptions(updatedBankAccounts);
        });
      } else if (newBankAccountIds.length) {
        //add accounts
        fetchBankDetails(newBankAccountIds).then(newBankAccounts => {
          const updatedBankAccounts = [...bankAccountOptions, ...newBankAccounts];
          setBankAccountOptions(updatedBankAccounts);
        });
      } else if (removedBankAccountIds.length) {
        //remove accounts
        const updatedBankAccounts = bankAccountOptions.filter(account => {
          return removedBankAccountIds.find(id => id === account.id) ? false : true;
        });
        setBankAccountOptions(updatedBankAccounts);
      }
    }

    newTotals.revenue_and_vat = newTotals.revenue + newTotals.vat;

    setInvoiceData(prev => ({ ...prev, ...newTotals }));
  }, [lineItems, bankAccountOptions]);

  useEffect(() => {
    if (id) {
      getInitialData().then(({ clients }) => {
        setClientOptions(clients);
        getAccountsReceivableByInvoiceNb(id).then(data => {
          const _invoiceData = {
            id: data.id,
            invoice_number: data.invoice_number,
            client_id: data.client_id,
            client: data.client,
            overdue: data.overdue,
            issue_date: new Date(data.issue_date),
            due_date: new Date(data.due_date),
            revenue: data.revenue,
            vat: data.vat,
            revenue_and_vat: data.revenue_and_vat,
            adjusted_revenue: data.adjusted_revenue,
            adjusted_vat: data.adjusted_vat,
            adjusted_revenue_and_vat: data.adjusted_revenue_and_vat,
            paid_amount: data.paid_amount,
            sent: data.sent,
            paid: data.paid,
            bank_account_id: data.bank_account_id,
            metadata: JSON.parse(data.metadata),
            payment_date: data?.payment_date ? new Date(data.payment_date) : null,
          };
          let _lineItems = data.lineItems.map(l => {
            return {
              id: l.id,
              description: l.description,
              type: l.type,
              staff_order_id: l.staff_order_id,
              framework_contract_id: l.framework_contract_id,
              partner_contract_id: l.partner_contract_id,
              specific_contract_id: l.specific_contract_id,
              sales_order_id: l.sales_order_id,
              timesheet_id: l.timesheet_id,
              units: l.units,
              uom: l.uom,
              price: l.price,
              vat_rate: l.vat_rate,
              user_id: l.user_id,
              bank_account_id: l.salesFwc.bank_account_id,
              metadata: JSON.parse(l.metadata),
              to_date: l.to_date,
              from_date: l.from_date,
              total: l.total,
              vat: l.vat,
              total_and_vat: l.total_and_vat,
              timesheet: l.timesheet,
            };
          });

          // Define two date objects
          const date1 = new Date(data.due_date);
          const date2 = new Date(data.issue_date);

          // Calculate the time difference in milliseconds
          const timeDifference = date1 - date2;

          // Convert milliseconds to days and subtract 2 because it includes beginning and end day of given interval that we dont want to account for
          const daysDifference = differenceInDays(date1, date2);

          let customIndex;
          let _paymentTerms = paymentTermOptions.map((el, i) => {
            if (el.value === 'custom') customIndex = i;
            return daysDifference === el.value ? { ...el, selected: true } : { ...el, selected: false };
          });
          if (!_paymentTerms.find(el => el.selected)) {
            _paymentTerms[customIndex] = { label: 'Custom', value: 'custom', selected: true };
          }

          const _creditNotes = data.creditNotes.map(cn => ({
            ...cn,
            issue_date: new Date(cn.issue_date),
            metadata: JSON.parse(cn.metadata),
            lineItems: cn.lineItems.map(item => ({ ...item, metadata: JSON.parse(item.metadata) })),
          }));

          setPaymentTermOptions(_paymentTerms);

          if (_invoiceData.metadata.merge_lines) {
            //if merged, reorder lines by staff order/from_date
            _lineItems = [..._lineItems].sort((a, b) => {
              // First, group by staff_order_id
              if (a.staff_order_id !== b.staff_order_id) {
                return a.staff_order_id - b.staff_order_id;
              }

              // If staff_order_id is the same, sort by from_date
              if (a.from_date === null && b.from_date === null) {
                return 0; // Both dates are null, so they are equal
              } else if (a.from_date === null) {
                return 1; // Move null date to the end
              } else if (b.from_date === null) {
                return -1; // Move null date to the end
              }

              // Compare the dates
              return new Date(a.from_date).getTime() - new Date(b.from_date).getTime();
            });
          }

          setInvoiceData(_invoiceData);
          setLineItems(_lineItems);
          setCreditNotes(_creditNotes);
          setIsLoading(false);
          if (_invoiceData.paid === paidStatus.numbers.partiallyPaid) paidRef.current.indeterminate = true;
        });
      });
    }
  }, [id]);

  const handleEditAccountsReceivable = () => {
    setIsLoading('edit');
    let errors = [];
    if (!invoiceData.invoice_number) errors.push({ field: 'invoice_number', msg: 'Required' });
    if (!invoiceData.issue_date) errors.push({ field: 'issue_date', msg: 'Required' });
    if (!invoiceData.due_date) errors.push({ field: 'due_date', msg: 'Required' });
    if (!invoiceData.client_id) errors.push({ field: 'client_id', msg: 'Required' });
    if (!invoiceData.bank_account_id) errors.push({ field: 'bank_account_id', msg: 'Required' });
    if (!invoiceData.metadata.subject) errors.push({ field: 'subject', msg: 'Required' });
    if (!invoiceData.metadata.footer_comment) errors.push({ field: 'footer_comment', msg: 'Required' });
    if (!invoiceData.metadata.header_comment) errors.push({ field: 'header_comment', msg: 'Required' });
    if (!lineItems.length || lineItems.find(l => !l.units || !l.price || !l.uom || !l.from_date))
      errors.push({ field: 'lineItems', msg: 'Required' });
    if (errors.length) return setFormErrors(errors);
    else {
      setFormErrors([]);
      const formattedLineItems = lineItems.map(el => {
        if (!el?.to_date) el.to_date = el.from_date;
        const _el = { ...el, price: Number(el.price), units: Number(el.units) };
        delete _el.bank_account_id;
        delete _el.line_item_id;
        delete _el.ts_entry_adjustment;
        delete _el.timesheet;
        //not required in backend since it is calculated there
        delete _el.totals;
        delete _el.total;
        delete _el.vat;
        delete _el.total_and_vat;
        delete _el.metadata.hours;
        return _el;
      });
      const {
        paid,
        paid_amount,
        sent,
        id,
        client,
        overdue,
        adjusted_revenue,
        adjusted_vat,
        adjusted_revenue_and_vat,
        payment_date,
        ...invoiceDataToEdit
      } = invoiceData;

      const formattedData = {
        invoice: {
          ...invoiceDataToEdit,
        },

        lineItems: formattedLineItems,
      };
      updateAccountsReceivable(id, formattedData)
        .then(res => {
          dispatch(showSuccessNotification('Account receivable successfully edited'));
          history.push('/admin-panel/finance/accounts-receivable');
          setIsLoading(false);
        })
        .catch(err => {
          setIsLoading(false);
        });
    }
  };

  const pages = [
    { name: 'Finance Manager', href: '/admin-panel/finance/invoicing-items', current: false },
    { name: `Accounts Receivable`, href: '/admin-panel/finance/accounts-receivable', current: false },
    { name: `Invoice #${id} details`, href: `/admin-panel/finance/accounts-receivable/${id}`, current: true },
  ];

  const toggleSentHandler = () => {
    updatePaidOrSentStatus(invoiceData.id, { sent: !invoiceData.sent }).then(res => {
      setInvoiceData(prev => ({
        ...prev,
        sent: !prev.sent,
      }));
    });
  };
  const updateInvoicePaid = (id, invoicePaid, date, paidAmount) => {
    let dataToUpdate = {
      paid: invoicePaid,
      paid_amount: paidAmount,
      payment_date: date,
    };

    if (paidAmount && invoicePaid) dataToUpdate.paid_amount = paidAmount;
    updatePaidOrSentStatus(id, dataToUpdate).then(res => {
      if (res.paid === paidStatus.numbers.partiallyPaid) {
        paidRef.current.indeterminate = true;
      } else {
        paidRef.current.indeterminate = false;
      }
      setInvoiceData(prev => ({
        ...prev,
        paid: res.paid,
        paid_amount: res.paid_amount,
        payment_date: res.payment_date ? new Date(res.payment_date) : null,
        overdue: res.overdue,
      }));
      setShowPayModal(false);
    });
  };

  const deleteHandler = () => {
    setIsLoading('delete');
    deleteAccountsReceivable(invoiceData.id)
      .then(res => {
        history.push(`/admin-panel/finance/accounts-receivable`);
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
      });
  };

  return (
    <AdminSidebar noPadding pages={pages}>
      {editInvoice ? (
        <EditAccountsReceivableForm
          invoiceData={invoiceData}
          setInvoiceData={setInvoiceData}
          clientOptions={clientOptions}
          lineItems={lineItems}
          setLineItems={setLineItems}
          bankAccountOptions={bankAccountOptions}
          paymentTermOptions={paymentTermOptions}
          setPaymentTermOptions={setPaymentTermOptions}
          formErrors={formErrors}
          setFormErrors={setFormErrors}
          handleEditAccountsReceivable={handleEditAccountsReceivable}
          isLoading={isLoading}
          editInvoice={editInvoice}
          setEditInvoice={setEditInvoice}
        />
      ) : (
        <AccountsReceivablePDFDetails
          invoiceData={invoiceData}
          lineItems={lineItems}
          creditNotes={creditNotes}
          toggleSentHandler={toggleSentHandler}
          updateInvoicePaid={updateInvoicePaid}
          showPayModal={showPayModal}
          setShowPayModal={setShowPayModal}
          setEditInvoice={setEditInvoice}
          deleteHandler={deleteHandler}
          paidRef={paidRef}
          isLoading={isLoading}
        />
      )}
    </AdminSidebar>
  );
}

export default AccountsReceivableDetails;
