import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import FileIcon from '../Icons/FileIcon';
import FilesIcon from '../Icons/FilesIcon';
import ColourFulExcelIcon from '../Icons/ColourFulExcelIcon';
import DeleteDocument from '../../containers/Documents/DeleteDocument';
import { bytesToSize } from '../../helpers';
import { useLocation } from 'react-router-dom';
import { DownloadIcon, EyeIcon } from '@heroicons/react/outline';
import { TrashIcon } from '@heroicons/react/outline';
import ColourFulBlueFile from '../Icons/ColourFulBlueFile';
import DropdownMenuDots from '../Menus/DropdownMenuDots';
import useWindowSize from '../../hooks/useWindowSize';
import ClipLoader from 'react-spinners/ClipLoader';

const Document = ({
  type,
  name,
  capacity,
  onDoubleClick,
  onDeleteClick,
  onDownloadClick,
  deleteAccess,
  small,
  threeDotMenu,
  forAdmins,
  loading,
  filePath,
  onPreviewClick,
}) => {
  let fileIcon = <FileIcon />;

  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const dropDownRef = useRef(null);
  const containerRef = useRef(null);

  switch (name.split('.')[name.split('.').length - 1]) {
    case 'pdf':
      fileIcon = 'pdf';
      break;
    case 'docx':
      fileIcon = 'docx';
      break;
    case 'pptx':
      fileIcon = 'pptx';
      break;
    case 'xlsx':
      fileIcon = <ColourFulExcelIcon />;
      break;
    default:
      fileIcon = <ColourFulBlueFile />;
      break;
  }

  const doubleClick = () => onDoubleClick();

  const deleteDocumentHandler = () => {
    const deleteClicked = onDeleteClick();
    if (deleteClicked instanceof Promise) deleteClicked.then(res => setShowDeleteAlert(false));
    else setShowDeleteAlert(false);
  };

  const isPreviewable = fileName => {
    let bool = false;
    const extension = fileName.split('.')[fileName.split('.').length - 1].toLowerCase();
    if (extension === 'pdf' || extension === 'jpg' || extension === 'png' || extension === 'jpeg') bool = true;
    return bool;
  };

  const dropdownItems = [[]];

  if (type === 'file') {
    dropdownItems[0].push({
      text: 'Download',
      onClick: onDownloadClick,
      icon: <DownloadIcon className="h-4 w-4 mr-2 my-auto" />,
    });
    if (isPreviewable(name)) {
      dropdownItems[0].push({
        text: 'Preview',
        onClick: onPreviewClick,
        icon: <EyeIcon className="h-4 w-4 mr-2 my-auto" />,
      });
    }
  }

  if (deleteAccess)
    dropdownItems[0].push({
      text: 'Delete',
      onClick: () => setShowDeleteAlert(true),
      icon: <TrashIcon className="h-4 w-4 mr-2 my-auto" />,
    });

  const isShowDotsVertical = threeDotMenu && dropdownItems[0].length ? true : false;

  const windowSize = useWindowSize();

  const isSmallScreen = windowSize.width <= windowSize.tailwind.md;

  const shouldRenderSmall = small || isSmallScreen;

  const noThreeDotMenu = (
    <div
      onDoubleClick={doubleClick}
      ref={containerRef}
      className={`inline-flex flex-col justify-between p-2 ${shouldRenderSmall ? 'w-36 h-36' : 'w-36 h-36 2xl:w-56 2xl:h-56'}`}
    >
      <div className={`${shouldRenderSmall ? 'w-20 h-20' : 'w-20 h-20 2xl:w-40 2xl:h-40'} mx-auto`}>
        {type === 'file' && <FilesIcon fileType={name.split('.')[name.split('.').length - 1]} />}
        {type == 'folder' && <FilesIcon fileType="folder" />}
      </div>
      <div className="flex justify-between align-items-center mt-2 w-full">
        <div className="w-full">
          <p
            className={` ${
              shouldRenderSmall ? 'w-24 text-xs' : 'w-full text-xs 2xl:w-full 2xl:text-sm'
            } whitespace-nowrap overflow-hidden overflow-ellipsis font-semibold text-gray-600 select-none`}
          >
            {name}
          </p>
        </div>
        <div ref={dropDownRef} className="my-auto align-middle relative cursor-pointer">
          {isShowDotsVertical &&
            (loading?.downloadFile == filePath ? (
              <ClipLoader className="mr-10 h-5 w-5" size={18} color={'#000000'} />
            ) : (
              <DropdownMenuDots items={dropdownItems} xOffset={100} />
            ))}
          <DeleteDocument
            show={showDeleteAlert}
            setShow={() => setShowDeleteAlert}
            onCancelClick={setShowDeleteAlert.bind(this, false)}
            onDeleteClick={deleteDocumentHandler}
            type={type}
          />
        </div>
      </div>
    </div>
  );

  const withThreeDotMenu = (
    <div
      onDoubleClick={doubleClick}
      ref={containerRef}
      className={`inline-flex flex-col justify-between p-2 ${
        shouldRenderSmall ? 'w-36 h-36' : 'w-56 h-56'
      } rounded-md hover:bg-thaleria-blue-50`}
    >
      <div className={`${shouldRenderSmall ? 'w-20 h-20' : 'w-40 h-40'} mx-auto`}>
        {type === 'file' && <FilesIcon fileType={name.split('.')[name.split('.').length - 1]} />}
        {type == 'folder' && <FilesIcon fileType="folder" />}
      </div>
      <div className="flex justify-between align-items-center mt-2 w-full">
        <div className="">
          <p
            className={` ${
              shouldRenderSmall ? 'w-24 text-xs' : 'w-44 text-sm'
            } whitespace-nowrap overflow-hidden overflow-ellipsis font-semibold text-gray-600 select-none`}
          >
            {name}
          </p>
          <p className={`${type === 'folder' && 'invisible'} text-xs text-gray-500 select-none`}>
            {capacity ? bytesToSize(capacity) : ''}
          </p>
        </div>
        <div ref={dropDownRef} className="my-auto align-middle relative cursor-pointer">
          {isShowDotsVertical &&
            (loading?.downloadFile == filePath ? (
              <ClipLoader className="mr-10 h-5 w-5" size={18} color={'#3F3F46'} />
            ) : (
              <DropdownMenuDots items={dropdownItems} xOffset={100} />
            ))}
          <DeleteDocument
            show={showDeleteAlert}
            setShow={() => setShowDeleteAlert}
            onCancelClick={setShowDeleteAlert.bind(this, false)}
            onDeleteClick={deleteDocumentHandler}
            type={type}
          />
        </div>
      </div>
    </div>
  );

  return forAdmins ? noThreeDotMenu : withThreeDotMenu;
};

Document.prototype = {
  type: PropTypes.oneOf(['file', 'folder']),
  name: PropTypes.string,
  capacity: PropTypes.string,
  onDoubleClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onDownloadClick: PropTypes.func,
  deleteAccess: PropTypes.bool,
};

export default Document;
