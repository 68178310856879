import React, { useEffect, useState } from 'react';
import Notification from './Notification';
import Navbar from './Navbar/Navbar';
import usePageTitle from '../../hooks/usePageTitle';
import Breadcrumbs from './Breadcrumbs';
import { Link } from 'react-router-dom';
import releaseNotes from '../../releaseNotes';
import { differenceInWeeks } from 'date-fns';
import PreviewBox from '../PDFViewer/PreviewBox';
import { THALERIA_WEBSITE_URL } from '../../configs';

function PageWrapper({ children, pages, rightDiv, tabTitle, constrainWidth, noXPadding, noYPadding, override }) {
  const defaultFont = 'text-gray-600';
  const defaultBg = 'bg-gray-100';

  const [showToSModal, setShowToSModal] = useState(false);

  const { changeTitle } = usePageTitle();

  let title = tabTitle || (pages?.length && pages[0]?.name);

  useEffect(() => {
    changeTitle(title ? `MyThaleria - ${title}` : 'MyThaleria');
  }, []);

  const currentYear = new Date().getFullYear();
  const isNewRelease = differenceInWeeks(new Date(), new Date(releaseNotes[0].date)) <= 2;
  if (override) return <>{children}</>;

  return (
    <div
      id="page-wrapper"
      className={`relative overflow-x-hidden  no-scrollbar ${defaultFont} ${defaultBg} min-h-screen flex flex-col justify-between`}
    >
      <PreviewBox
        showPreview={showToSModal}
        handleHide={() => setShowToSModal(false)}
        fileType={'pdf'}
        filePath={'https://thaleria-files-public.s3.eu-west-2.amazonaws.com/myThaleria/terms-and-conditions.pdf'}
        title="Terms and Conditions"
      />
      <Notification />
      <header className="bg-white shadow-sm" id="header">
        <Navbar constrainWidth={constrainWidth} />
        <Breadcrumbs pages={pages} rightDiv={rightDiv} constrainWidth={constrainWidth} />
      </header>
      <main
        className={`grow  ${constrainWidth && 'max-w-7xl 2xl:px-0'} mx-auto ${!noXPadding && 'px-4 sm:px-6 lg:px-8'} ${
          !noYPadding && 'py-4'
        } w-full flex flex-col `}
        id="main"
      >
        {children}
      </main>
      <div className="w-full bg-white border-y border-gray-200 z-10">
        <footer
          className={`${constrainWidth && 'max-w-7xl 2xl:px-0'} mx-auto ${!noXPadding && 'sm:px-6 lg:px-8'} w-full`}
          id="footer"
        >
          <div
            className={` py-2 text-sm text-gray-500 text-center sm:flex sm:justify-between ${noXPadding && 'sm:px-6 lg:px-8'}`}
          >
            <div>
              <div className="inline">
                <span className="inline">&copy; {currentYear} Thaleria S.A.</span>
                <span className="inline"> all rights reserved</span>
              </div>
              <span className="mx-2">&#x2022;</span>
              <div
                onClick={() => setShowToSModal(!showToSModal)}
                className="cursor-pointer text-gray-500 hover:text-blue-400 hover:underline inline"
              >
                Terms and Conditions
              </div>
              <span className="mx-2">&#x2022;</span>
              <a
                href={`${THALERIA_WEBSITE_URL}/privacy-policy`}
                className="text-gray-500 hover:text-blue-400 cursor-pointer hover:underline inline"
              >
                Privacy policy
              </a>
            </div>
            <div>
              {isNewRelease && (
                <span className="border-2 text-xs rounded-3xl border-thaleria-orange-500 text-thaleria-orange-500 p-0.5 px-3 font-semibold mr-2 place-self-center">
                  New Release
                </span>
              )}
              <Link to={'/release-notes'} className="text-gray-500 hover:text-blue-400 hover:underline">
                v{releaseNotes[0].version}
              </Link>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}

PageWrapper.defaultProps = {
  requireAuth: true,
  constrainWidth: true,
  noXPadding: false,
  noYPadding: false,
};

export default PageWrapper;
