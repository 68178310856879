function getDecimalPlaces(number) {
  // Convert the number to a string and split it by the decimal point
  const str = number.toString();

  // Check if the number contains a decimal point
  if (str.includes('.')) {
    // Remove trailing zeros on the right using a regular expression
    const decimalPart = str.split('.')[1].replace(/0+$/, '');
    // Return the length of the remaining decimal part
    return decimalPart.length;
  }

  // If there is no decimal point, return 0
  return 0;
}

export default getDecimalPlaces;
