import React, { useState, useRef, useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import { useSelector, useDispatch } from 'react-redux';

import { logOutUser as logout, refreshUserToken as refreshToken } from '../../store/auth/actions';
import SimpleAlert from '../../components/Modals/SimpleAlert';

const TIME_INTERVAL_TO_CHECK_TOKEN = 15 * 1000;
const TIME_DELAY_UNTIL_LOGOUT = 60;

function TokenExpiry() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state && state.auth.isAuthenticated);
  const token = useSelector(state => state && state.auth.token);

  const [seconds, setSeconds] = React.useState(null);
  const [startCountdown, setStartCountdown] = useState(null);
  const [tokenRemainingTime, setTokenRemainingTime] = useState(null);
  const [tokenIntervalChecks, setTokenIntervalChecks] = useState(null);

  const _onClickExtend = () => {
    dispatch(refreshToken());
    setStartCountdown(null);
    setTimeout(() => setSeconds(null), 1000);
  };

  const _logout = () => {
    if (tokenIntervalChecks) clearInterval(tokenIntervalChecks);
    setTokenIntervalChecks(null);
    setStartCountdown(null);
    dispatch(logout());
  };

  const _onClickCancel = () => {
    _logout();
  };

  const _checkExpirationTime = () => {
    if (!token || !isAuthenticated) return;
    const decoded = jwt_decode(token);
    const difference = decoded.exp - parseInt(new Date().getTime() / 1000);
    setTokenRemainingTime(difference);
  };

  const refreshSecondsTimeout = useRef(null);

  //if countdown is activated, decreases seconds and eventually logs user out; otherwise clears timer
  useEffect(() => {
    if (startCountdown === true) {
      refreshSecondsTimeout.current = setTimeout(() => setSeconds(seconds - 1), 1000);
      if (typeof seconds == 'number' && seconds <= 0) _logout();
    }
    if (startCountdown === false) {
      clearTimeout(refreshSecondsTimeout.current);
      setTimeout(() => setSeconds(null), 1000);
    }
    if (startCountdown === null) return;
  }, [startCountdown, seconds]);

  useEffect(() => {
    if (tokenRemainingTime === null) return;
    if (tokenRemainingTime <= 0) {
      _logout();
    }
    if (tokenRemainingTime <= TIME_DELAY_UNTIL_LOGOUT) {
      setSeconds(tokenRemainingTime);
      if (startCountdown !== false) {
        setStartCountdown(true);
        window.focus();
      }
    }
  }, [tokenRemainingTime]);

  //on each token change, sets current remaining time and sets a repeating function to calculate remaining time on each interval
  useEffect(() => {
    if (tokenIntervalChecks) clearInterval(tokenIntervalChecks);

    _checkExpirationTime();

    const interval = setInterval(_checkExpirationTime, TIME_INTERVAL_TO_CHECK_TOKEN);
    setTokenIntervalChecks(interval);

    return () => clearInterval(tokenRemainingTime);
  }, [token]);

  return (
    <SimpleAlert
      show={startCountdown || false}
      errorTitle="Token about to expire"
      errorMsg={`Do you want to renew your access token? If you do not extend your token, you will be logged out automatically in ${seconds} seconds.`}
      onAcceptClick={_onClickExtend}
      onDeclineClick={_onClickCancel}
      onAcceptText="Extend"
      onDeclineText={'Log out'}
    />
  );
}

export default TokenExpiry;
