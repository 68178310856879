import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

//containers
import UserInfo from '../../../../containers/UserDirectory/UserInfo';
import UserList from '../../../../containers/UserDirectory/UserList.js';

import { setNotification } from '../../../../store/app/actions';
import notifications from '../../../../helpers/enum/notifications';
import HrManager from '../HrManager';
import useApi from '../../../../hooks/useApi';
import AdminSidebar from '../../AdminSidebar';

function UserRegistry() {
  const {
    staffContracts: { getUserStaffContracts },
    users: { getAllUsers, getAllDeletedUsers, deleteUser, undoDeleteUser, activateUser },
    purchaseOrders: { getUserStaffOrders },
  } = useApi();

  const history = useHistory();
  const location = useLocation();

  const activeUserIdFromURL = location.pathname.split('/')[4];
  const [users, setUsers] = useState([]);
  const [deletedUser, setDeletedUser] = useState([]);
  const [userMode, setUserMode] = useState(true);
  const [pages, setPages] = useState([
    { name: 'HR Manager', href: '/admin-panel/hr/user-directory', current: true },
    { name: 'User Directory', href: '/admin-panel/hr/user-directory', current: true },
  ]);
  const [activeSearch, setActiveSearch] = useState(false);
  const dispatch = useDispatch();

  const [filteredDeletedUsers, setFilteredDeletedUsers] = useState();
  const [filteredSortedUsers, setFilteredSortedUsers] = useState();

  const fetchUsers = () => {
    getAllUsers().then(data => setUsers(data));
    getAllDeletedUsers().then(res => {
      setDeletedUser(res);
    });
  };

  //on mount
  useEffect(() => {
    fetchUsers();
  }, [userMode]);

  //react state
  const [showFullScreenUserList, setShowFullScreenUserList] = useState(true);

  //helpers
  const handleUserClick = user => {
    history.push(`/admin-panel/hr/user-directory/${user.id}/profile`);
    if (showFullScreenUserList) setShowFullScreenUserList(false);
  };
  const handleCreateUserClick = () => {
    history.push('/admin-panel/hr/user-directory/create/');
  };

  //redux state contd

  useEffect(() => {
    const _filteredUsers = users.filter(user => user?.full_name.toLowerCase().includes(activeSearch));
    const _filteredDeletedUsers = deletedUser.filter(user => user?.full_name.toLowerCase().includes(activeSearch));
    const _sortedUsers = _filteredUsers.sort((a, b) => (a.full_name.toLowerCase() > b.full_name.toLowerCase() ? 1 : -1));
    setFilteredSortedUsers(_sortedUsers);
    setFilteredDeletedUsers(_filteredDeletedUsers);
  }, [activeSearch]);

  const sortedUsers = users.sort((a, b) => (a.full_name.toLowerCase() > b.full_name.toLowerCase() ? 1 : -1));
  const sortedDeletedUsers = deletedUser.sort((a, b) => (a.full_name.toLowerCase() > b.full_name.toLowerCase() ? 1 : -1));

  const filterUsers = search => {
    search && search != '' ? setActiveSearch(search.toLowerCase()) : setActiveSearch(false);
  };

  const _getActiveUser = () => {
    const foundUser = users.find(e => e.id === activeUserIdFromURL);
    const nextFoundUser = deletedUser.find(e => e.id === activeUserIdFromURL);
    if (showFullScreenUserList) return;
    if (foundUser) return foundUser;
    if (nextFoundUser) return nextFoundUser;
    return emptyUser;
  };

  const emptyUser = {
    accountType: {
      account_type: '',
      created_at: '',
      deleted_at: null,
      description: '',
      id: null,
      updated_at: '',
    },
    accountTypeId: null,
    account_type_id: null,
    active: null,
    address: '',
    birthday: '',
    created_at: '',
    deleted_at: null,
    email: '',
    first_names: '',
    full_name: '',
    hr_contact: '',
    id: '',
    id_nb: '',
    last_names: '',
    phone_nb: '',
    preferred_name: '',
    profilepic_url: '',
    sales_contact: '',
    tfa_enabled: null,
    updated_at: '',
  };

  const [userContracts, setUserContracts] = useState([]);
  const [userStaffOrders, setUserStaffOrders] = useState([]);
  const isTabletOrMobile = window.window.innerWidth < 1280;

  useEffect(() => {
    if (!sortedUsers[0] || activeUserIdFromURL) return;

    if (!isTabletOrMobile) {
      history.replace('/admin-panel/hr/user-directory/' + sortedUsers[0].id);
    }
  }, [users]);

  useEffect(() => {
    if (!activeUserIdFromURL) setShowFullScreenUserList(true);
    if (!activeUserIdFromURL) return;
    getUserStaffContracts(activeUserIdFromURL).then(d => setUserContracts(d));
    getUserStaffOrders(activeUserIdFromURL).then(d => setUserStaffOrders(d));
    setShowFullScreenUserList(false);
  }, [activeUserIdFromURL]);

  const handleShowUserListClick = () => {
    setShowFullScreenUserList(true);
    history.push('/admin-panel/hr/user-directory/');
  };
  const activeUser = _getActiveUser();

  const handleUndoDeleteUser = userId => {
    undoDeleteUser(userId).then(res => {
      setUserMode(true);
      fetchUsers();
      dispatch(setNotification(notifications.SUCCESS, 'Success', 'User removed from deleted users.'));
      history.push('/admin-panel/hr/user-directory/' + sortedUsers[0].id);
    });
  };

  const handleDeleteClick = userId => {
    deleteUser(userId).then(() => {
      setUsers(users.filter(user => user.id != userId));
      dispatch(setNotification(notifications.SUCCESS, 'Success', 'User successfully deleted.'));
      history.push('/admin-panel/hr/user-directory/' + sortedUsers[0].id);
    });
  };

  const handleActivateClick = userId => {
    activateUser(userId).then(() => {
      fetchUsers();
      dispatch(setNotification(notifications.SUCCESS, 'Success', 'User successfully activated.'));
    });
  };

  const showUser = activeSearch
    ? userMode
      ? filteredSortedUsers
      : filteredDeletedUsers
    : userMode
    ? sortedUsers
    : sortedDeletedUsers;

  useEffect(() => {
    if (!users.length) return;
    const pathNameArray = location.pathname.split('/');
    let pages = [
      { name: 'HR Manager', href: '/admin-panel/hr/user-directory', current: true },
      { name: 'User Directory', href: '/admin-panel/hr/user-directory', current: true },
    ];
    let breadcrumbArray = [];
    pathNameArray.forEach((path, index) => {
      const elementNotInBreadcrumb = index < 4 || index > 5;
      const isUserId = index == 4;
      const isUserTabName = index == 5;
      const isFolderPath = index > 5;
      breadcrumbArray.push(path);
      if (elementNotInBreadcrumb || path == '') return;
      if (isUserId) {
        pages.push({
          name: [...users, ...deletedUser].find(user => user.id == path)?.full_name,
          href: breadcrumbArray.join('/'),
          current: false,
        });
      } else if (isUserTabName) {
        let breadcrumbName = '';
        switch (path) {
          case 'staff-contracts':
            breadcrumbName = 'Staff Contracts';
            break;
          case 'missions':
            breadcrumbName = 'Missions';
            break;
          case 'leave-balance':
            breadcrumbName = 'Leave balance';
            break;
          case 'documents':
            breadcrumbName = 'Documents';
            break;
          case undefined:
            breadcrumbName = 'Profile';
            break;
          default:
            breadcrumbName = 'Profile';
            break;
        }
        pages.push({
          name: breadcrumbName,
          href: breadcrumbArray.join('/'),
          current: false,
        });
      } else if (isFolderPath) {
        pages.push({
          name: path,
          href: `${breadcrumbArray.join('/')}/`,
          current: false,
        });
      }
    });
    pages[pages.length - 1].current = true;
    setPages(pages);
  }, [location.pathname, users, deletedUser]);

  return (
    <AdminSidebar pages={pages} noPadding>
      <div className="w-full h-full flex grow">
        <div className={`${activeUser ? 'hidden' : 'block'} xl:block w-full xl:w-96 scrollbar-hidden`}>
          <UserList
            title="User Directory"
            onAddButtonClick={handleCreateUserClick}
            onUserClick={handleUserClick}
            users={showUser}
            activeUser={activeUser}
            filterUsers={filterUsers}
            setUserMode={setUserMode}
            userMode={userMode}
            numberOfUsers={userMode ? users.length : deletedUser.length}
          />
        </div>
        {activeUser && (
          <div id="" className="flex flex-col flex-grow w-full">
            {/* for smaller screens only, allows to toggle between full directory view or single user view */}
            <nav
              onClick={handleShowUserListClick}
              className="flex sticky top-0 z-10 bg-white items-start px-4 py-3 sm:px-6 lg:px-8 xl:hidden"
              aria-label="Breadcrumb"
            >
              <div className="inline-flex items-center space-x-3 text-sm font-medium text-gray-900">
                {/* <!-- Heroicon name: solid/chevron-left --> */}
                <svg
                  className="-ml-2 h-5 w-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
                <span>User Directory</span>
              </div>
            </nav>
            <UserInfo
              isActive={!showFullScreenUserList}
              user={activeUser}
              userContracts={userContracts}
              userStaffOrders={userStaffOrders}
              undoDeleteUser={handleUndoDeleteUser}
              activateUser={handleActivateClick}
              deleteUser={handleDeleteClick}
            />
          </div>
        )}
      </div>
    </AdminSidebar>
  );
}

export default UserRegistry;
