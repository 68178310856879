import React from 'react';
import './AppRouter.css';
import { Switch, Route } from 'react-router-dom';
import PrivateRouteCommon from './PrivateRouteCommon';
import PrivateRouteAdmin from './PrivateRouteAdmin';
import HomeRedirect from '../containers/App/HomeRedirect';
import HomePage from '../pages/Common/HomePage/HomePage';
import ProfilePage from '../pages/Common/ProfilePage/ProfilePage';
import LoginPage from '../pages/Common/LoginPage/LoginPage';
import MyTimesheets from '../pages/Common/TimesheetsPage/MyTimesheets';
import CreateLeaveRequest from '../pages/Common/LeaveRequest/CreateLeaveRequest';
import MyLeaveRequests from '../pages/Common/LeaveRequest/MyLeaveRequests';
import LeaveRequestDetails from '../pages/Common/LeaveRequest/LeaveRequestDetails';
import MyDocument from '../pages/Common/Document/MyDocument';
import TimesheetDetails from '../pages/Common/TimesheetsPage/TimesheetDetails';
import ViewAnnouncement from '../pages/Common/Announcement/Announcement';
import UserDirectory from '../pages/Admin/HrManager/UserDirectory/UserDirectory';
import CreateOrEditUser from '../pages/Admin/HrManager/UserDirectory/CreateOrEditUser';
import TimesheetManager from '../pages/Admin/HrManager/Timesheets/TimesheetManager';
import ContractsDashboard from '../pages/Admin/ContractsManager/StaffOrders/ServerSideContractsDashboard';
import AddDashboardEntry from '../pages/Admin/ContractsManager/StaffOrders/AddDashboardEntry';
import EditDashboardEntry from '../pages/Admin/ContractsManager/StaffOrders/EditDashboardEntry';
import FrameworkContracts from '../pages/Admin/ContractsManager/FrameworkContracts/FrameworkContracts';
import StaffContracts from '../pages/Admin/ContractsManager/StaffContracts/StaffContracts';
import AddOrEditFrameworkContractPage from '../pages/Admin/ContractsManager/FrameworkContracts/AddOrEditFrameworkContractPage';
import AddStaffContract from '../pages/Admin/ContractsManager/StaffContracts/AddStaffContract';
import AddClient from '../pages/Admin/AdminManager/Clients/AddClients';
import AddContact from '../pages/Admin/AdminManager/PointOfContacts/AddPointOfContact';
import AddCountry from '../pages/Admin/AdminManager/Countries/AddCountry';
import AddOrEditHoliday from '../pages/Admin/AdminManager/Holidays/AddOrEditHoliday';
import TimesheetAdminDetail from '../pages/Admin/HrManager/Timesheets/TimesheetAdminDetail';
import CreateAnnouncement from '../pages/Admin/HomeDashboard/CreateAnnouncement';
import EditAnnouncement from '../pages/Admin/HomeDashboard/EditAnnouncement';
import Holidays from '../pages/Admin/AdminManager/Holidays/Holidays';
import Clients from '../pages/Admin/AdminManager/Clients/Clients';
import Countries from '../pages/Admin/AdminManager/Countries/Countries';
import PointOfContacts from '../pages/Admin/AdminManager/PointOfContacts/PointOfContacts';
import LeaveRequestManager from '../pages/Admin/HrManager/LeaveRequests/LeaveRequestManager';
import LeaveRequestAdminDetails from '../pages/Admin/HrManager/LeaveRequests/LeaveRequestAdminDetails';
import ReleaseNotes from '../pages/Common/ReleaseNotes/ReleaseNotes';
import DocumentManager from '../pages/Admin/HrManager/Documents/DocumentManager';
import RemainingDaysLog from '../pages/Admin/ContractsManager/StaffOrders/RemainingDaysLog';
import TaskManager from '../pages/Admin/HrManager/Tasks/TaskManager';
import MyNotifications from '../pages/Common/NotificationsPage/MyNotifications';
import ResetPasswordPage from '../pages/Common/ResetPasswordPage/ResetPasswordPage';
import TemporaryPasswordPage from '../pages/Common/TemporaryPasswordPage/TemporaryPasswordPage';
import AdminCreateLeaveRequest from '../pages/Admin/HrManager/LeaveRequests/AdminCreateLeaveRequest';
import BugReportPage from '../pages/Common/BugReportPage/BugReportPage';
import AccountsReceivables from '../pages/Admin/Finance/AccountsReceivable/AccountsReceivable';
import AccountsPayable from '../pages/Admin/Finance/AccountsPayable/AccountsPayable';
import CreateAccountsReceivable from '../pages/Admin/Finance/AccountsReceivable/CreateAccountsReceivable';
import BankAccounts from '../pages/Admin/AdminManager/BankAccounts/BankAccounts';
import AddBankAccount from '../pages/Admin/AdminManager/BankAccounts/AddBankAccounts';
import AccountsReceivableDetails from '../pages/Admin/Finance/AccountsReceivable/AccountsReceivableDetails';
import CreateCreditNote from '../pages/Admin/Finance/CreditNotes/CreateCreditNote';
import CreditNotes from '../pages/Admin/Finance/CreditNotes/CreditNotes';
import CreditNoteDetails from '../pages/Admin/Finance/CreditNotes/CreditNoteDetails';
import InvoicingLines from '../pages/Admin/Finance/InvoicingLines/InvoicingLines';
import CreateInvoicingLine from '../pages/Admin/Finance/InvoicingLines/CreateInvoicingLine';
import MyInvoices from '../pages/Common/MyInvoices/MyInvoices';
import CreateInvoice from '../pages/Common/MyInvoices/CreateInvoice';
import MyInvoiceDetails from '../pages/Common/MyInvoices/MyInvoiceDetails';
import AccountsPayableDetails from '../pages/Admin/Finance/AccountsPayable/AccountsPayableDetails';
import CreateAccountsPayable from '../pages/Admin/Finance/AccountsPayable/CreateAccountsPayable';
import SalesOrders from '../pages/Admin/ContractsManager/SalesOrders/SalesOrders';

export default function AppRouter() {
  return (
    <Switch>
      {/* non-authenticated routes */}
      <Route component={LoginPage} path="/login" exact />
      <Route component={ResetPasswordPage} path="/forgot-password/:token" />

      {/* Temporary password reset (authentication managed inside component) */}
      <Route component={TemporaryPasswordPage} path="/temp-password" />

      {/* Home Dashboard Routes */}
      <PrivateRouteCommon component={HomePage} path="/home" exact />
      <PrivateRouteAdmin component={CreateAnnouncement} path="/announcement/create" />
      <PrivateRouteAdmin component={EditAnnouncement} path="/announcement/:id/edit" />
      <PrivateRouteCommon component={ViewAnnouncement} path="/announcement/:id" />

      {/* User Routes */}
      <PrivateRouteCommon component={ProfilePage} path="/my-profile" />
      <PrivateRouteCommon component={MyDocument} path="/my-documents" />
      <PrivateRouteCommon component={TimesheetDetails} path="/timesheets/:id" />
      <PrivateRouteCommon component={MyTimesheets} path="/timesheets" />
      <PrivateRouteCommon component={MyLeaveRequests} path="/leave-request" exact />
      <PrivateRouteCommon component={CreateLeaveRequest} path="/leave-request/create" />
      <PrivateRouteCommon component={LeaveRequestDetails} path="/leave-request/:id" />
      <PrivateRouteCommon component={MyNotifications} path="/notifications" />
      <PrivateRouteCommon component={BugReportPage} path="/report-bug" />
      <PrivateRouteCommon component={MyInvoices} path="/my-invoices" exact />
      <PrivateRouteCommon component={CreateInvoice} path="/my-invoices/create" />
      <PrivateRouteCommon component={MyInvoiceDetails} path="/my-invoices/:id" />

      {/* HR Manager Routes */}
      <PrivateRouteAdmin
        component={CreateOrEditUser}
        path={['/admin-panel/hr/user-directory/create', '/admin-panel/hr/user-directory/update/:id/']}
      />
      <PrivateRouteAdmin component={UserDirectory} path="/admin-panel/hr/user-directory/" />
      <PrivateRouteAdmin component={DocumentManager} path="/admin-panel/hr/documents" />
      <PrivateRouteAdmin component={TimesheetAdminDetail} path="/admin-panel/hr/timesheets/:id" />
      <PrivateRouteAdmin component={TimesheetManager} path="/admin-panel/hr/timesheets" />
      <PrivateRouteAdmin component={LeaveRequestManager} path="/admin-panel/hr/leave-requests" exact />
      <PrivateRouteAdmin component={LeaveRequestAdminDetails} path="/admin-panel/hr/leave-requests/:userId/:id" />
      <PrivateRouteAdmin component={AdminCreateLeaveRequest} path="/admin-panel/hr/leave-requests/create" />
      <PrivateRouteAdmin component={TaskManager} path="/admin-panel/hr/task-manager" exact />

      {/* Contracts Manager Routes */}
      <PrivateRouteAdmin component={RemainingDaysLog} path="/admin-panel/contracts/days-log/:id" />
      <PrivateRouteAdmin component={ContractsDashboard} path="/admin-panel/contracts/purchase-orders" exact />
      <PrivateRouteAdmin component={AddDashboardEntry} path="/admin-panel/contracts/purchase-orders/create" />
      <PrivateRouteAdmin component={EditDashboardEntry} path="/admin-panel/contracts/purchase-orders/:id" />
      <PrivateRouteAdmin component={FrameworkContracts} path="/admin-panel/contracts/framework-contracts" exact />
      <PrivateRouteAdmin component={SalesOrders} path="/admin-panel/contracts/sales-orders" exact />
      <PrivateRouteAdmin component={AddOrEditFrameworkContractPage} path="/admin-panel/contracts/framework-contracts/:id" />
      <PrivateRouteAdmin component={StaffContracts} path="/admin-panel/contracts/staff-contracts" exact />
      <PrivateRouteAdmin component={AddStaffContract} path="/admin-panel/contracts/staff-contracts/:id" />

      {/* Administrative Manager Routes */}
      <PrivateRouteAdmin component={Clients} path="/admin-panel/admin/clients" exact />
      <PrivateRouteAdmin component={AddClient} path="/admin-panel/admin/clients/:id" />
      <PrivateRouteAdmin component={PointOfContacts} path="/admin-panel/admin/points-of-contact" exact />
      <PrivateRouteAdmin component={AddContact} path="/admin-panel/admin/points-of-contact/:id" />
      <PrivateRouteAdmin component={Countries} path="/admin-panel/admin/countries" exact />
      <PrivateRouteAdmin component={AddCountry} path="/admin-panel/admin/countries/:id" />
      <PrivateRouteAdmin component={Holidays} path="/admin-panel/admin/holidays" exact />
      <PrivateRouteAdmin component={AddOrEditHoliday} path="/admin-panel/admin/holidays/:id" />
      <PrivateRouteAdmin component={BankAccounts} path="/admin-panel/admin/bank-accounts" exact />
      <PrivateRouteAdmin component={AddBankAccount} path="/admin-panel/admin/bank-accounts/:id" />

      {/* Finance Routes */}
      <PrivateRouteAdmin component={AccountsReceivables} path="/admin-panel/finance/accounts-receivable" exact />
      <PrivateRouteAdmin component={AccountsPayable} path="/admin-panel/finance/accounts-payable" exact />
      <PrivateRouteAdmin component={CreateAccountsReceivable} path="/admin-panel/finance/accounts-receivable/create" exact />
      <PrivateRouteAdmin component={AccountsReceivableDetails} path="/admin-panel/finance/accounts-receivable/:id" />
      <PrivateRouteAdmin component={CreditNotes} path="/admin-panel/finance/credit-notes" exact />
      <PrivateRouteAdmin component={CreateCreditNote} path="/admin-panel/finance/credit-notes/create" exact />
      <PrivateRouteAdmin component={CreditNoteDetails} path="/admin-panel/finance/credit-notes/:id" />
      <PrivateRouteAdmin component={CreateInvoicingLine} path="/admin-panel/finance/invoicing-lines/create" exact />
      <PrivateRouteAdmin component={InvoicingLines} path="/admin-panel/finance/invoicing-lines" exact />
      <PrivateRouteAdmin component={CreateAccountsPayable} path="/admin-panel/finance/accounts-payable/create" exact />
      <PrivateRouteAdmin component={AccountsPayableDetails} path="/admin-panel/finance/accounts-payable/:id" />

      {/* Release note Route */}
      <PrivateRouteCommon component={ReleaseNotes} path="/release-notes" exact />

      {/* Redirect nonexistant routes */}
      <Route component={HomeRedirect} path="/" />
    </Switch>
  );
}
