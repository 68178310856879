import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import SimpleAlert from '../../../components/Modals/SimpleAlert';
import ReminderCard from '../../../components/Cards/ReminderCard';
import { ACCOUNT_TYPE, AccountType } from '../../../helpers/enum/accounts';
import useApi from '../../../hooks/useApi';
import AddBodyReminder from './AddBodyReminder';
import AddViewerReminder from './AddViewerReminder';
import ShowReminder from './ShowReminder';
import Modal from '../../../components/Modals/Modal';
import contractTypes from '../../../helpers/enum/contractTypeIDs';

const Reminders = ({ remindersData, refreshReminder }) => {
  const {
    reminders: { deleteReminder, getReminder, updateReminder },
  } = useApi();
  const [showReminder, setShowReminder] = useState({ status: false, body: null, title: null });

  const [showDeleteAlert, setShowDeleteAlert] = useState({ status: false, reminderId: null });
  const [showEditReminderModal, setShowEditReminderModal] = useState(false);
  const [step, setStep] = useState(0);

  const [inputs, setInputs] = useState({
    id: null,
    textArea: null,
    countries: [],
    accountTypes: [],
    contractTypes: [],
  });

  const currentUser = useSelector(state => state && state.auth.currentUser);
  const isAdmin = ACCOUNT_TYPE.ROOT.id == currentUser.account_type_id || ACCOUNT_TYPE.ADMIN.id == currentUser.account_type_id;

  const showReminderHandler = (body, title, event) => {
    setShowReminder({ status: true, body, title });
  };

  const clearShowReminderHandler = () => setShowReminder(prev => ({ ...prev, status: false }));

  const onCancelDeleteAlertHandler = () => setShowDeleteAlert({ status: false });

  const onDeleteReminderHandler = reminderId => setShowDeleteAlert({ status: true, reminderId: reminderId });

  const DeleteReminderHandler = () => {
    deleteReminder(showDeleteAlert.reminderId).then(() => {
      setShowDeleteAlert({ status: false });
      refreshReminder();
    });
  };

  const onEditHandler = reminderId => {
    setShowEditReminderModal(true);
    getReminder(reminderId).then(data => {
      const { id, body, title, countries, contract_types, account_types } = data;

      setInputs(preInput => ({
        ...preInput,
        id: id,
        title,
        textArea: body,
        countries: countries,
        accountTypes: account_types,
        contractTypes: contract_types,
      }));

      setStep(1);
    });
  };

  const showAddReminderStepTwo = () => setStep(2);

  const onTextAreaChangeHandler = event => {
    const text = event.target.value;
    setInputs(preInput => ({
      ...preInput,
      textArea: text,
    }));
  };

  const onTitleChangeHandler = event => {
    const text = event.target.value;
    setInputs(preInput => ({
      ...preInput,
      title: text,
    }));
  };

  const onCountriesInputChangeHandler = event => {
    let newCountries;
    if (!event) newCountries = [];
    else newCountries = event.map(country => country.value);

    setInputs(preInput => ({ ...preInput, countries: newCountries }));
  };

  const onContractTypesChangeHandler = event => {
    let acceptedContractTypes = inputs.contractTypes;
    if (event.target.id == 'employee') {
      event.target.checked
        ? acceptedContractTypes.push(contractTypes.employee)
        : (acceptedContractTypes = acceptedContractTypes.filter(id => id != contractTypes.employee));
    }
    if (event.target.id == 'freelancer') {
      event.target.checked
        ? acceptedContractTypes.push(contractTypes.freelancer)
        : (acceptedContractTypes = acceptedContractTypes.filter(id => id != contractTypes.freelancer));
    }
    if (event.target.id == 'company') {
      event.target.checked
        ? acceptedContractTypes.push(contractTypes.company)
        : (acceptedContractTypes = acceptedContractTypes.filter(id => id != contractTypes.company));
    }

    setInputs(preInput => ({ ...preInput, contractTypes: acceptedContractTypes }));
  };

  const onAccountTypesChangeHandler = event => {
    let acceptedAccountTypes = inputs.accountTypes;
    if (event.target.id == 'user') {
      event.target.checked
        ? acceptedAccountTypes.push(AccountType.USER)
        : (acceptedAccountTypes = acceptedAccountTypes.filter(id => id != AccountType.USER));
    }
    if (event.target.id == 'staff') {
      event.target.checked
        ? acceptedAccountTypes.push(AccountType.STAFF)
        : (acceptedAccountTypes = acceptedAccountTypes.filter(id => id != AccountType.STAFF));
    }

    setInputs(preInput => ({ ...preInput, accountTypes: acceptedAccountTypes }));
  };

  const completeReminder = () => {
    const data = {
      body: inputs.textArea,
      title: inputs.title,
      countries: inputs.countries,
      account_types: inputs.accountTypes,
      contract_types: inputs.contractTypes,
    };

    updateReminder(inputs.id, data).then(() => {
      closeEditReminderModal();
      setInputs({
        id: null,
        textArea: null,
        countries: [],
        accountTypes: [],
        contractTypes: [],
      });
      refreshReminder();
    });
  };

  const textToDisplay = reminder => {
    if (reminder.body.length > 95) {
      const str = reminder.body.substring(0, 95) + '...';
      return str;
    } else {
      return reminder.body;
    }
  };

  const closeEditReminderModal = () => {
    setShowEditReminderModal(false);
    setTimeout(function () {
      setStep(0);
    }, 500);
  };

  return (
    <div className="bg-white overflow-y-auto">
      {remindersData.length ? (
        remindersData.map((reminder, index) => {
          return (
            <ReminderCard
              key={reminder.id}
              onDeleteClick={onDeleteReminderHandler.bind(this, reminder.id)}
              onEditClick={onEditHandler.bind(this, reminder.id)}
              text={textToDisplay(reminder)}
              title={reminder.title}
              showDeleteButton={isAdmin}
              showEditButton={isAdmin}
              onClick={showReminderHandler.bind(this, reminder.body, reminder.title)}
              index={index}
            />
          );
        })
      ) : (
        <span className="text-gray-500 text-sm">There are no reminders to show.</span>
      )}

      <ShowReminder
        show={showReminder.status}
        body={showReminder.body}
        title={showReminder.title}
        onCancelClick={clearShowReminderHandler}
      />

      <SimpleAlert
        errorTitle="Delete Reminder"
        errorMsg="Are you sure to Delete reminder ?"
        onAcceptText="Delete"
        onDeclineText="Cancel"
        onAcceptClick={DeleteReminderHandler}
        onDeclineClick={onCancelDeleteAlertHandler}
        show={showDeleteAlert.status}
        hide={() => setShowDeleteAlert(prev => ({ ...prev, status: false }))}
      />
      <Modal show={showEditReminderModal} hide={closeEditReminderModal}>
        {step === 1 && (
          <AddBodyReminder
            onSuccessClick={showAddReminderStepTwo}
            onCancelClick={closeEditReminderModal}
            onTextAreaChange={onTextAreaChangeHandler}
            onInputChange={onTitleChangeHandler}
            textAreaValue={inputs.textArea}
            inputValue={inputs.title}
            title="Edit"
          />
        )}
        {step === 2 && (
          <AddViewerReminder
            onSuccessClick={completeReminder}
            onCancelClick={closeEditReminderModal}
            onCountriesChange={onCountriesInputChangeHandler}
            onContractTypesChange={onContractTypesChangeHandler}
            onAccountTypesChange={onAccountTypesChangeHandler}
            countriesDefaultId={inputs.countries}
            acceptedContractTypes={inputs.contractTypes}
            acceptedAccountTypes={inputs.accountTypes}
            countriesPlaceholder="All countries"
            title="Edit"
            successButtonName="Update"
            isAdmin={currentUser.account_type_id == AccountType.ADMIN}
          />
        )}
      </Modal>
    </div>
  );
};

export default Reminders;
