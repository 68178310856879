import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { ACCOUNT_TYPE } from '../helpers/enum/accounts';

function PrivateRoute({ component: Component, ...rest }) {
  const isAuthenticated = useSelector(state => state && state.auth.isAuthenticated);
  const isLoading = useSelector(state => state && state.auth.isLoading);
  const currentUser = useSelector(state => state && state.auth.currentUser);
  const tempPassword = useSelector(state => state && state.auth.currentUser.temp_password);

  if (isLoading) return '';

  const haveAccessToAdminPanel =
    currentUser?.account_type_id == ACCOUNT_TYPE.ADMIN.id || currentUser?.account_type_id == ACCOUNT_TYPE.ROOT.id;

  return (
    <Route
      {...rest}
      render={props =>
        !isAuthenticated || !haveAccessToAdminPanel ? (
          <Redirect to="/login" />
        ) : tempPassword ? (
          <Redirect to="/temp-password" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
}

export default PrivateRoute;
