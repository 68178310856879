import React from 'react';
import ButtonPrimary from '../../components/Buttons/ButtonPrimary';
import ButtonWhite from '../../components/Buttons/ButtonWhite';
import Modal from '../../components/Modals/Modal';

const ConfirmUploadLRModal = props => {
  const showComponents = props.show ? 'fixed' : 'hidden';

  return (
    <Modal show={props.show} size="md" hide={() => props.hide}>
      <h2 className="pb-10">{props.title} Upload leave request</h2>
      <p>Do you wish to submit your leave request as well, or upload it now and submit it later?</p>
      <div className="pt-7 flex flex-wrap-reverse justify-center lg:justify-between gap-y-3">
        <ButtonWhite text="Cancel" delete={true} onClick={props.onCancelClick} />
        <div className="flex flex-row">
          <ButtonPrimary text="Upload" style="ml-2" onClick={props.onUploadClick} />
          <ButtonPrimary text="Upload & submit" style="ml-2" onClick={props.onSuccessClick} />
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmUploadLRModal;
