import React, { useEffect, useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '../../hooks/useQuery';
import TabList from '../TabList/TabList';
import useApi from '../../hooks/useApi';
import paidStatus from '../../helpers/enum/paidStatus';
import InvoiceTabItem from './InvoiceTabItem';

const MyInvoicesTabList = () => {
  const location = useLocation();
  const hash = location.hash;

  const {
    accountsPayable: { getMyInvoices },
  } = useApi();
  let query = useQuery();
  const page = Number(query.get('page')) || 1;

  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getMyInvoices().then(res => {
      setLoading(false);
      setInvoices(res);
    });
  }, []);

  const invoicesData = useMemo(() => {
    let data = [];
    if (invoices && invoices.length) {
      invoices.forEach(invoice =>
        data.push({
          id: invoice.id,
          paid: invoice.paid,
          paid_amount: invoice.paid_amount,
          total_and_vat: invoice.cost_and_vat,
          due_date: invoice.due_date,
          invoice_number: invoice.invoice_number,
          approved: invoice.approved,
          payment_date: invoice.payment_date,
          created_at: invoice.created_at,
        }),
      );
    }
    return data;
  }, [invoices]);

  const tabs = useMemo(() => {
    let count = {
      pending: 0,
      approved: 0,
      processed: 0,
    };

    invoicesData?.forEach(el => {
      if (!el.approved) count.pending += 1;
      if (el.approved && el.paid !== paidStatus.numbers.paid) count.approved += 1;
      if (el.paid === paidStatus.numbers.paid) count.processed += 1;
    });

    return [
      {
        name: 'Pending',
        href: '/my-invoices',
        count: count['pending'],
        current: !hash,
      },
      {
        name: 'Approved',
        href: '#approved',
        count: count['approved'],
        current: hash.includes('approved'),
      },
      {
        name: 'Processed',
        href: '#processed',
        count: count['processed'],
        current: hash.includes('processed'),
      },
    ];
  }, [invoicesData, hash]);

  const nbOfPages = useMemo(() => {
    let itemCount = hash ? tabs.find(t => t.href === hash).count : tabs.find(t => t.href === '/my-invoices').count;

    let pageSize = 5;
    if (itemCount <= pageSize) return 1;
    else return Math.ceil(itemCount / pageSize);
  }, [tabs]);

  const renderInvoicesList = () => {
    if (!invoicesData?.length) return <li className="mt-8">No invoices to show</li>;
    const filterInvoices = invoicesData
      .filter(i => {
        if (hash) {
          if (hash === '#approved') {
            return i.approved && i.paid !== paidStatus.numbers.paid;
          }
          if (hash === '#processed') {
            return i.paid === paidStatus.numbers.paid;
          }
        } else {
          return !i.approved;
        }
      })
      .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

    const paginatedInvoices = filterInvoices.slice(((page || 1) - 1) * 5, (page || 1) * 5);

    const invoiceItems = paginatedInvoices.map(i => <InvoiceTabItem invoice={i} />);

    if (invoiceItems?.length) return invoiceItems;
    else return <li className="mt-8">No invoices to show</li>;
  };

  return (
    <div>
      <TabList
        page={page}
        isLoading={loading}
        renderList={renderInvoicesList}
        tabs={tabs}
        pageCount={nbOfPages}
        href="/leave-request"
      />
    </div>
  );
};

export default MyInvoicesTabList;
