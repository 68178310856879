import React, { useState, useEffect } from 'react';
import ButtonPrimary from '../../../components/Buttons/ButtonPrimary';
import PageWrapperV2 from '../../../containers/App/PageWrapper';
import Attachments from '../../../containers/Attachments/Attachments';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmUploadLRModal from '../../../containers/LeaveRequests/ConfirmUploadLRModal';
import { LeaveRequestStatus, LeaveRequestType } from '../../../helpers/enum/leaveRequest';
import { Redirect, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { VALIDATION_MSG, DATE_VALIDATION_MSG } from '../../../helpers/enum/errorValidationMsgs';
import { useYupValidationResolver } from '../../../utils/hooks/useYupValidationResolver';
import CreateLRForm from '../../../containers/LeaveRequests/CreateLRForm';
import { showSuccessNotification } from '../../../store/app/actions';
import useApi from '../../../hooks/useApi';

const CreateLeaveRequest = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    leaveRequests: { uploadFile, createLeaveRequest, submitMyLeaveRequest },
    staffContracts: { getActiveStaffContracts },
  } = useApi();

  const [showPopUp, setShowPopUp] = useState(false);
  const [data, _setData] = useState({
    status: null,
    startDate: null,
    startHalfDay: false,
    endDate: null,
    endHalfDay: false,
    deductedLeaveDays: null,
    requestType: null,
    description: '',
  });
  const [documents, setDocuments] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [staffContract, setStaffContract] = useState();
  const [LRConflict, setLRConflict] = useState(false);
  const currentUser = useSelector(state => state.auth.currentUser);
  const canCreateLeaveRequests = currentUser.StaffContracts.length;

  const validationSchema = Yup.object().shape({
    description: Yup.string().trim(),
    startDate: Yup.date()
      .required(VALIDATION_MSG)
      .nullable()
      .when('requestType', {
        is: requestType => requestType !== LeaveRequestType.trainingLeave,
        then: Yup.date().test('is-weekend', 'Leave request dates cannot be on weekends', function (value) {
          if (value && (value.getDay() === 0 || value.getDay() === 6)) {
            return false;
          }
          return true;
        }),
      }),
    endDate: Yup.date()
      .required(VALIDATION_MSG)
      .nullable()
      .when(['startDate', 'requestType'], {
        is: (startDate, requestType) => startDate && requestType !== LeaveRequestType.trainingLeave,
        then: Yup.date().test('is-weekend', 'Leave request dates cannot be on weekends', function (value) {
          if (value && (value.getDay() === 0 || value.getDay() === 6)) {
            return false;
          }
          return true;
        }),
      })
      .when('startDate', (startDate, schema) => startDate && schema.min(startDate, DATE_VALIDATION_MSG)),
    requestType: Yup.number().required(VALIDATION_MSG).nullable(),
  });

  const formOptions = { resolver: useYupValidationResolver(validationSchema) };

  useEffect(() => {
    getActiveStaffContracts(currentUser.id).then(res => {
      setStaffContract(res[0]);
    });
  }, [currentUser]);

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    control,
    formState: { errors },
  } = useForm(formOptions);

  const setNewData = newValue => {
    Object.entries(newValue).forEach(([name, value]) => {
      if (value || (name === 'requestType' && (value || value == 0))) {
        clearErrors(name);
      }
      setValue(name, value);
    });
    _setData({ ...data, ...newValue });
  };

  const uploadFileHandler = fileInfo => {
    if (!fileInfo) return;

    if (!documents.find(d => d.resource === fileInfo.name))
      setDocuments(preValue => [
        ...preValue,
        {
          id: new Date().getTime(),
          resource: fileInfo.name,
          rawFile: fileInfo,
        },
      ]);
    setUploading(false);
  };

  const RemoveFileHandler = id => {
    setDocuments(pre => [...pre.filter(document => document.id != id)]);
  };

  const onSubmit = async data => {
    setShowPopUp(true);
  };

  const sendRequestHandler = async leaveRequestStatus => {
    const dataToSend = {
      body: data.description || null,
      start_date: data.startDate,
      end_date: data.endDate,
      start_half_day: data.startHalfDay,
      end_half_day: data.endHalfDay,
      leave_type: data.requestType,
      status: documents.length ? LeaveRequestStatus.pending : leaveRequestStatus,
      staff_contract_id: staffContract.id,
    };
    setShowPopUp(false);

    createLeaveRequest(dataToSend).then(async newLeaveRequest => {
      if (documents.length) {
        setUploading(true);
        for (let i = 0; i < documents.length; i++) {
          if (i != documents.length - 1) {
            uploadFile(documents[i].rawFile, newLeaveRequest.uuid);
          } else {
            uploadFile(documents[i].rawFile, newLeaveRequest.uuid).then(res => {
              if (leaveRequestStatus == LeaveRequestStatus.submitted) {
                submitMyLeaveRequest(newLeaveRequest.id).then(response => {
                  setUploading(false);
                  history.push('/leave-request');
                  dispatch(showSuccessNotification('Leave request created successfully'));
                });
              } else {
                setUploading(false);
                history.push('/leave-request');
                dispatch(showSuccessNotification('Leave request created successfully'));
              }
            });
          }
        }
      } else {
        history.push('/leave-request');
        dispatch(showSuccessNotification('Leave request created successfully'));
      }
    });
  };

  const pages = [
    { name: 'Leave Requests', href: '/leave-request', current: false },
    { name: `Create Leave Request`, href: '', current: true },
  ];

  if (!canCreateLeaveRequests) return <Redirect to="/home" />;

  return (
    <PageWrapperV2 pages={pages}>
      <ConfirmUploadLRModal
        show={showPopUp}
        hide={setShowPopUp}
        onCancelClick={() => setShowPopUp(false)}
        onSuccessClick={() => sendRequestHandler(LeaveRequestStatus.submitted)}
        onUploadClick={() => sendRequestHandler(LeaveRequestStatus.pending)}
      />
      <div className="grid grid-cols-1 gap-y-8 max-w-3xl w-full mx-auto">
        <CreateLRForm
          setData={setNewData}
          data={data}
          register={register}
          errors={errors}
          control={control}
          staffContractTypeId={currentUser.StaffContracts[0].contract_type_id}
          staffContract={staffContract}
        />
        <Attachments
          uploadHandler={uploadFileHandler}
          maxFileSizeInMb={10}
          enableDownload={false}
          removeHandler={RemoveFileHandler}
          documents={documents}
          uploading={uploading}
          setUploading={setUploading}
        />
        <ButtonPrimary text="Upload request" size="xl" onClick={handleSubmit(onSubmit)} />
      </div>
    </PageWrapperV2>
  );
};

export default CreateLeaveRequest;
