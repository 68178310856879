import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import AddHoliday from '../../../../containers/AdminManager/AddHoliday';
import PageWrapper from '../../../../containers/App/PageWrapper';
import { capitalizeFirstLetter } from '../../../../utils/capitalizeFirstLetter';
import useApi from '../../../../hooks/useApi';

export default function AddOrEditHoliday() {
  const {
    holidays: { getHolidayByID, createHolidays, updateHoliday },
    countries: { getAllCountries },
    clients: { getClientsByType },
  } = useApi();

  const history = useHistory();
  const { id } = useParams();
  const [holidayData, setHolidayData] = useState();

  useEffect(() => {
    getAllCountries().then(r => setAllCountries(r));
    if (id !== 'create')
      getHolidayByID(id).then(res => {
        setHolidayData(res.data);
      });
  }, []);

  const [allCountries, setAllCountries] = useState([]);
  const countries = allCountries.map(country => ({ value: country.id, label: country.name }));

  const handleClick = form => {
    if (!form) return;

    if (id === 'create') {
      createHolidays(form).then(data => {
        data ? history.push('/admin-panel/admin/holidays') : alert('Error while saving the details');
      });
    } else {
      updateHoliday(id, form).then(data => {
        data ? history.push('/admin-panel/admin/holidays') : alert('Error while saving the details');
      });
    }
  };

  const pages = [
    { name: 'Holidays', href: '/admin-panel/admin/holidays', current: true },
    { name: capitalizeFirstLetter(id), href: '/admin-panel/admin/holidays', current: false },
    { name: id === 'create' ? undefined : 'Edit', href: '', current: true },
  ];

  return (
    <PageWrapper pages={pages}>
      <AddHoliday id={id} countryOptions={countries} data={holidayData} handleSave={handleClick} getClients={getClientsByType} />
    </PageWrapper>
  );
}
