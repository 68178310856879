import React, { useRef } from 'react';
import ButtonWhite from '../../components/Buttons/ButtonWhite';
import ArrowLeftIcon from '../../components/Icons/ArrowLeftIcon';
import FileAdd from '../../components/Icons/FileAdd';
import FolderAdd from '../../components/Icons/FolderAdd';
import PropTypes from 'prop-types';
import MiniBreadcrumbs from '../App/MiniBreadcrumbs';

const AdminDocumentHeader = ({
  path,
  onAddFolderClick,
  uploadHandler,
  onBackClick,
  showArrowLeftIcon,
  showBreadcrumbs,
  pages,
  homeLink,
}) => {
  const inputFile = useRef(null);

  const onChangeFile = event => {
    event.stopPropagation();
    event.preventDefault();
    let file = event.target.files[0];

    uploadHandler(file);
  };

  return (
    <>
      <input type="file" id="file" ref={inputFile} onChange={onChangeFile} className="hidden" />
      <div
        className={`flex flex-col md:flex-row  ${
          showBreadcrumbs ? 'justify-start md:justify-between' : 'justify-end'
        } space-y-2 md:space-y-0 w-full`}
      >
        {showBreadcrumbs && (
          <div className="self-start md:self-center shrink">
            <MiniBreadcrumbs pages={pages} homeLink={homeLink} folderIcon />
          </div>
        )}
        <div className="flex justify-start md:justify-center lg:m-0 space-x-6 flex-none">
          <ButtonWhite text="Add folder" icon={<FolderAdd />} onClick={onAddFolderClick} />
          <ButtonWhite text="Upload file" icon={<FileAdd />} onClick={() => inputFile.current.click()} />
        </div>
      </div>
    </>
  );
};

AdminDocumentHeader.prototype = {
  path: PropTypes.string,
  onAddFolderClick: PropTypes.func,
  uploadHandler: PropTypes.func,
  onBackClick: PropTypes.func,
  showArrowLeftIcon: PropTypes.bool,
};

export default AdminDocumentHeader;
