import React from 'react';
import PageWrapper from '../../../containers/App/PageWrapper';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

function HrManager({ children, pages, noPadding }) {
  const baseUrl = '/admin-panel/hr';
  const location = useLocation();

  const TYPES = [
    {
      name: 'User Directory',
      url: `${baseUrl}/user-directory`,
      isActive: location.pathname.includes(`${baseUrl}/user-directory`),
    },
    {
      name: 'Documents',
      url: `${baseUrl}/documents`,
      isActive: location.pathname.includes(`${baseUrl}/documents`),
    },
    {
      name: 'Timesheets',
      url: `${baseUrl}/timesheets`,
      isActive: location.pathname.includes(`${baseUrl}/timesheets`),
    },
    {
      name: 'Leave Requests',
      url: `${baseUrl}/leave-requests`,
      isActive: location.pathname.includes(`${baseUrl}/leave-requests`),
    },
    {
      name: 'Task Manager',
      url: `${baseUrl}/task-manager`,
      isActive: location.pathname.includes(`${baseUrl}/task-manager`),
    },
  ];

  return (
    <PageWrapper pages={pages} noXPadding noYPadding constrainWidth={false}>
      <div className="flex grow">
        <div className="flex shrink-0 w-56 flex-col inset-y-0 min-h-0 border-r border-gray-200 px-4 py-8 space-y-4 bg-gray-700">
          {TYPES.map(el => (
            <Link
              to={el.url}
              className={`${
                el.isActive ? 'bg-gray-800 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white'
              } group flex items-center px-2 py-2 text-sm font-medium rounded-md transition duration-200`}
            >
              {el.name}
            </Link>
          ))}
        </div>
        <div className="grow flex flex-col items-center" id="main-container">
          <div className={`w-full grow  h-[700px] ${noPadding ? '' : 'p-8'}`}>{children}</div>
        </div>
      </div>
    </PageWrapper>
  );
}

export default HrManager;
