export const lineItemTypesV2 = {
  accountsReceivable: {
    numbers: {
      projectAllocations: 11,
      reinvoicingOfExpenses: 12,
      recruitmentFee: 13,
      other: 14,
      customProjectAllocations: 15,
      milestone: 16,
      milestoneToBeInvoiced: 26,
      unapprovedProjectAllocations: 27,
      allocationsToBeInvoiced: 28,
      customAllocationsToBeInvoiced: 29,
    },
    strings: {
      11: 'Project allocations',
      12: 'Reinvoicing of expenses',
      13: 'Recruitment fee',
      14: 'Other',
      15: 'Custom allocations', //'Custom project allocations',
      16: 'Milestone',
      26: 'Milestone', //'Milestone to be invoiced',
      27: 'Unapproved project allocations',
      28: 'Project allocations', //'Allocations to be invoiced',
      29: 'Custom allocations', //'Custom allocations to be invoiced',
    },
    reactSelectOptions: [
      { label: 'Project allocations', value: 11 },
      { label: 'Reinvoicing of expenses', value: 12 },
      { label: 'Recruitment fee', value: 13 },
      { label: 'Other', value: 14 },
    ],
  },
  creditNotes: {
    numbers: {
      projectAllocations: 31,
      reinvoicingOfExpenses: 32,
      recruitmentFee: 33,
      other: 34,
      milestone: 35,
    },
    strings: {
      31: 'Project allocations', //line item id will reference accounts receivable project allocations line item (type 11 or 15)
      32: 'Reinvoicing of expenses', //line item id will reference accounts receivable reinvoicing of expenses line item (type 12)
      33: 'Recruitment fee', //line item id will reference accounts receivable recruitment fee line item (type 13)
      34: 'Other', //line item id will reference accounts receivable other line item (type 14)
      35: 'Milestone',
    },
    reactSelectOptions: [
      { label: 'Project allocations', value: 31 },
      { label: 'Reinvocing of expenses', value: 32 },
      { label: 'Recruitment fee', value: 33 },
      { label: 'Other', value: 34 },
      { label: 'Milestone', value: 35 },
    ],
  },
  convertTypeFromARtoCN: function (accountReceivableTypeId) {
    let matchId;
    switch (accountReceivableTypeId) {
      case this.accountsReceivable.numbers.projectAllocations:
        matchId = this.creditNotes.numbers.projectAllocations;
        break;
      case this.accountsReceivable.numbers.customProjectAllocations:
        matchId = this.creditNotes.numbers.projectAllocations;
        break;
      case this.accountsReceivable.numbers.reinvoicingOfExpenses:
        matchId = this.creditNotes.numbers.reinvoicingOfExpenses;
        break;
      case this.accountsReceivable.numbers.recruitmentFee:
        matchId = this.creditNotes.numbers.recruitmentFee;
        break;
      case this.accountsReceivable.numbers.other:
        matchId = this.creditNotes.numbers.other;
        break;
      case this.accountsReceivable.numbers.milestone:
        matchId = this.creditNotes.numbers.milestone;
        break;
      default:
        alert('Error converting line item type from AR to CN');
        break;
    }
    return matchId;
  },
  accountsPayable: {
    numbers: {
      projectAllocations: 51,
      customLineReinvoicingOfExpenses: 52,
      customLineRecruitmentFee: 53,
      customLineOther: 54,
      projectAllocationCreditNoteAdjustment: 55, //FYI, this is a negative amount line
      projectAllocationAccountReceivableAdjustment: 56,
      milestone: 57,
      unapprovedProjectAllocations: 61,
    },
    strings: {
      51: 'Project allocations', //line item id will reference accounts receivable project allocations line item (type 11 or 28)
      52: 'Reinvoicing of expenses', //line item id not used
      53: 'Recruitment fee', //line item id not used
      54: 'Other', //line item id not used
      55: 'Project allocations (CN adjustment)', //line item id will reference credit note allocations line item (type 31)
      56: 'Project allocations (AR adjustment)', //line item id will reference accounts receivable custom project allocations line item (type 15 or 29)
      57: 'Milestone', //line item id will reference accounts receivable milestone line item (type 16 or 30)
      61: 'Allocations to be approved', //line item id will reference unapproved project allocations in accounts receivable line item
    },
    reactSelectOptions: [
      { label: 'Reinvocing of expenses', value: 52 },
      { label: 'Recruitment fee', value: 53 },
      { label: 'Other', value: 54 },
    ],
  },
  employees: {
    numbers: {
      employeeCost: 71, //line item id will reference accounts receivable project allocations line item (type 11 or 28)
      projectAllocationCreditNoteAdjustment: 72, //line item id will reference credit note allocations line item (type 31). FYI, this is a negative amount line
      projectAllocationAccountReceivableAdjustment: 73, //line item id will reference accounts receivable custom project allocations line item (type 15 or 29)
      milestone: 74,
      unapprovedProjectAllocations: 81, //line item id will reference unapproved project allocations in accounts receivable line item
    },
  },
};
