import React from 'react';
import Notification from '../../../containers/App/Notification.js';
import TemporaryPasswordSplitScreen from '../../../containers/ResetPassword/TemporaryPasswordSplitScreen.js';
import logo from '../../../resources/logos/Thaleria-Horizontal-Logo blue.png';
import PageWrapper from '../../../containers/App/PageWrapper.js';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min.js';
import { useSelector } from 'react-redux';

function TemporaryPasswordPage() {
  const isAuthenticated = useSelector(state => state && state.auth.isAuthenticated);
  const isLoading = useSelector(state => state && state.auth.isLoading);
  const tempPassword = useSelector(state => state && state.auth.currentUser.temp_password);

  if (isLoading) return '';
  if (!isAuthenticated) return <Redirect to="/login" />;
  if (!tempPassword) return <Redirect to="/home" />;

  return (
    <PageWrapper override tabTitle="Reset your password">
      <div className="relative z-10 flex flex-col w-screen h-screen bg-gray-100 overflow-hidden">
        <TemporaryPasswordSplitScreen logo={logo} />
      </div>
    </PageWrapper>
  );
}

export default TemporaryPasswordPage;
