import React, { useState, useEffect, useRef } from 'react';
import DividerWithLabel from '../../../components/Dividers/DividerWithLabel';
import LeaveBalanceLog from '../../LeaveRequests/LeaveBalanceLog';
import SimpleEntry from '../../../components/DescriptionEntries/SimpleEntry';
import UpdateLeaveBalanceModal from './UpdateLeaveBalanceModal';
import useWindowSize from '../../../hooks/useWindowSize';
import useApi from '../../../hooks/useApi';

function UserLeaveBalance({
  userId,
  containerRef,
  updateLeaveBalance,
  setUpdateLeaveBalance,
  noUpdate,
  refresh,
  setRefresh,
  selectedStaffContract,
}) {
  const size = useWindowSize();

  const staffContractId = selectedStaffContract?.id;

  const {
    leaveRequests: { getUserAvailableLeave },
  } = useApi();

  const logRef = useRef(null);
  const listRef = useRef(null);

  const [leaveDays, setLeaveDays] = useState({
    recuperationDays: null,
    paidLeaveDays: null,
  });

  const mainDiv = React.useRef(null);

  const totalLeaveDays = leaveDays.recuperationDays + leaveDays.paidLeaveDays || null;

  useEffect(() => {
    if (userId) {
      getUserAvailableLeave(userId, staffContractId).then(data => {
        setLeaveDays({
          recuperationDays: data?.recuperation_days_balance,
          paidLeaveDays: data?.leave_days_balance,
        });
        !noUpdate && setUpdateLeaveBalance(false);
      });
    }
  }, [userId, refresh, staffContractId]);

  let responsiveLogHeight;
  if (logRef.current && logRef.current?.offsetTop && !containerRef)
    responsiveLogHeight = size.height - logRef.current.offsetTop - 30;
  if (listRef.current && listRef.current?.clientHeight && containerRef)
    responsiveLogHeight = containerRef.current?.clientHeight - listRef.current?.clientHeight - 190;

  const STYLES_LOG = {
    maxHeight: responsiveLogHeight,
  };

  return (
    <div ref={mainDiv} className="pt-6 flex flex-col h-full">
      {!noUpdate && (
        <UpdateLeaveBalanceModal
          open={updateLeaveBalance}
          setOpen={setUpdateLeaveBalance}
          currentLeaveDays={leaveDays}
          userId={userId}
          setLeaveDays={setLeaveDays}
          setRefresh={setRefresh}
          contractId={staffContractId}
          contractCountryId={selectedStaffContract?.country_id}
        />
      )}

      <>
        <dl ref={listRef} className=" grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <SimpleEntry label={'Remaining leave days'} data={leaveDays.paidLeaveDays} />
          <SimpleEntry label={'Remaining recuperation days'} data={leaveDays.recuperationDays} />
          <SimpleEntry label={'Total remaining days'} data={totalLeaveDays} />
        </dl>
        <DividerWithLabel className="mt-6 " label="History" />
        <div ref={logRef} className="mt-6 px-4 overflow-auto" style={STYLES_LOG}>
          <LeaveBalanceLog userId={userId} refresh={refresh} contractId={staffContractId} />
        </div>
      </>
    </div>
  );
}

export default UserLeaveBalance;
