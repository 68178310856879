import React, { useEffect, useState } from 'react';
import InputDate from '../Inputs/InputDate/InputDate';
import InputSimple from '../Inputs/InputSimple';
import ButtonPrimary from '../Buttons/ButtonPrimary';
import ButtonWhite from '../Buttons/ButtonWhite';
import Modal from './Modal';
import paidStatus from '../../helpers/enum/paidStatus';
import ReactSelect from '../Inputs/ReactSelect';

function PayInvoice({ id, _paid, _paidAmount, _paymentDate, totalAmount, onConfirm, show, setShow, _dueDate }) {
  const [paid, setPaid] = useState(paidStatus.numbers.paid);
  const [paidAmount, setPaidAmount] = useState(totalAmount);
  const [paymentDate, setPaymentDate] = useState(new Date());

  useEffect(() => {
    _paid === paidStatus.numbers.unpaid ? setPaid(paidStatus.numbers.paid) : setPaid(_paid);
    !_paid && setPaidAmount(totalAmount);
    _paymentDate ? setPaymentDate(new Date(_paymentDate)) : _dueDate && setPaymentDate(new Date(_dueDate));
    _paidAmount && setPaidAmount(_paidAmount);
  }, [id, _paid, _paidAmount, _paymentDate, totalAmount]);

  const onCancel = () => {
    setShow(false);
  };

  const onChangePaid = value => {
    if (value === paidStatus.numbers.unpaid) {
      setPaidAmount(null);
    } else {
      setPaidAmount(totalAmount);
    }
    setPaid(value);
  };

  const paidOptions = Object.keys(paidStatus.strings).map(key => ({
    label: paidStatus.strings[key],
    value: Number(key),
  }));

  const onClick = () => {
    if (paid === paidStatus.numbers.unpaid) {
      onConfirm(id, paid, null, paidAmount);
    } else {
      onConfirm(id, paid, paymentDate, paidAmount);
    }
  };

  return (
    <Modal show={show} hide={() => setShow(false)} title="Pay invoice" size="sm">
      <div role="dialog" aria-modal="true" aria-labelledby="modal-headline">
        <div className="grid grid-cols-2 gap-4 sm:gap-8 mt-8">
          <ReactSelect
            label="Status"
            className="col-span-2"
            options={paidOptions}
            selectedOptionsIds={[paid]}
            onChange={e => onChangePaid(e.value)}
          />

          {paid !== paidStatus.numbers.unpaid && (
            <InputDate
              className="col-span-1 w-full"
              label="Payment date"
              selected={paymentDate}
              onChange={e => setPaymentDate(e)}
            />
          )}

          {paid !== paidStatus.numbers.unpaid && (
            <InputSimple
              className="col-span-1"
              label="Amount paid"
              type="number"
              onChange={e => setPaidAmount(e.target.value)}
              value={paidAmount}
              disabled={paid === paidStatus.numbers.paid}
            />
          )}
        </div>
        <div className="flex flex-row-reverse mt-5 sm:mt-8">
          <ButtonPrimary style={'ml-2'} text={'Confirm'} onClick={() => onClick()} />
          <ButtonWhite onClick={() => onCancel()} style={'mr-2'} text={'Cancel'} />
        </div>
      </div>
    </Modal>
  );
}

export default PayInvoice;
