import React from 'react';

const Edit = () => {
  return (
    <svg className="mr-3 h-4 w-4 text-gray-400" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.125 10.2186V12.8748H2.78125L10.6154 5.04065L7.95917 2.3844L0.125 10.2186ZM12.6696 2.98648C12.9458 2.71023 12.9458 2.26398 12.6696 1.98773L11.0121 0.330234C10.7358 0.0539844 10.2896 0.0539844 10.0133 0.330234L8.71708 1.62648L11.3733 4.28273L12.6696 2.98648Z"
        fill="gray"
      />
    </svg>
  );
};

export default Edit;
