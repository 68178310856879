import React, { useMemo } from 'react';
import InputDate from './Inputs/InputDate/InputDate';
import InputSimple from './Inputs/InputSimple';
import { XIcon, PlusIcon } from '@heroicons/react/outline';
import Error from './Error/Error';
const AddHolidaysTable = ({ dates, onNameChange, onDateChange, addRow, deleteRow, errors, isEdit }) => {
  const rows = useMemo(() => {
    let _rows = dates.map(obj => obj);
    return _rows;
  }, [dates]);

  const nameError = index => {
    return errors.find(e => e.field === `[${index}].name`)?.msg;
  };
  const dateError = index => {
    return errors.find(e => e.field === `[${index}].date`)?.msg;
  };

  return (
    <div>
      <div className="flex justify-between w-full">
        <div className="w-3/4">Name</div>
        <div className="w-1/4">Date</div>
        <div className="w-5 ml-4"></div>
      </div>
      <div className="flex-col gap-y-8">
        {rows.map((date, index) => {
          return (
            <div className="flex justify-between w-full items-center my-2" key={date.key}>
              <div className="w-3/4 pr-8">
                {nameError(index) ? <Error message={nameError(index)} /> : dateError(index) && <div className="h-5" />}
                <InputSimple value={date.name} onChange={e => onNameChange(e, index)} />
              </div>
              <div className="w-1/4">
                {dateError(index) ? <Error message={dateError(index)} /> : nameError(index) && <div className="h-5" />}
                <InputDate selected={date.date ? new Date(date.date) : null} onChange={e => onDateChange(e, index)} />
              </div>
              <div className="ml-4">
                {(dateError(index) || nameError(index)) && <div className="h-5" />}
                {index != 0 ? (
                  <XIcon className="w-5 h-5 text-gray-500 hover:text-gray-400 cursor-pointer" onClick={() => deleteRow(index)} />
                ) : (
                  <div className="w-5 h-5" />
                )}
              </div>
            </div>
          );
        })}
        {!isEdit && (
          <div className="flex items-center gap-x-2 mt-6 group cursor-pointer w-28" onClick={() => addRow()}>
            <span className="flex h-8 w-8 items-center justify-center bg-white rounded-full border-2 border-dashed border-thaleria-orange-700 group-hover:border-thaleria-orange-500 text-gray-400">
              <PlusIcon className="h-5 w-5 text-thaleria-orange-700 group-hover:text-thaleria-orange-500" aria-hidden="true" />
            </span>
            <p className="text-thaleria-orange-700 group-hover:text-thaleria-orange-600">Add row</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddHolidaysTable;
