import React, { useState, useEffect } from 'react';
import './TableControlPanel.css';
import GlobalFilter from '../../components/TableItems/GlobalFilter';
import { useAsyncDebounce } from 'react-table';
function TableControlPanel({
  pageIndex,
  pageCount,
  previousPage,
  nextPage,
  canNextPage,
  canPreviousPage,
  gotoPage,
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  totalItems,
  tableName,
}) {
  const [currentPage, setCurrentPage] = useState(0);
  useEffect(() => {
    if (pageIndex === undefined || pageIndex === null) return;
    if (pageIndex !== currentPage - 1) setCurrentPage(pageIndex + 1);
  }, [pageIndex]);

  const onChange = useAsyncDebounce(currentPage => {
    if (currentPage) gotoPage(currentPage - 1);
  }, 300);

  return (
    <div id="bottom-table-control-panel" className="flex mt-auto justify-between bg-gray-50 w-full px-4 h-16 items-center">
      <div>
        <GlobalFilter
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          totalItems={totalItems}
          tableName={tableName}
        />
      </div>
      <div className="flex space-x-4">
        <div className="flex items-center">
          <span className="mr-2 text-gray-500">Go to page:</span>
          <input
            className="mr-2 w-16 h-11 pl-1 text-gray-500 border-2 border-gray-100 rounded-md"
            type="number"
            min={1}
            max={pageCount}
            value={currentPage}
            onChange={e => {
              const page = Number(e.target.value) || null;
              setCurrentPage(page);
              onChange(page);
            }}
          />
          {/* Disabled select number of rows */}
          {/* <select
          className="text-gray-500 w-36 h-11 bg-white border-2 border-gray-100 rounded-md px-1"
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select> */}
        </div>
        <div className="flex items-center">
          <button
            className="flex items-center cursor-pointer justify-center rounded-md px-2 h-6 mr-1 bg-gray-200 hover:bg-primary icon-container"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="icon-style" viewBox="0 0 20 20">
              <path
                fillRule="evenodd"
                d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          <button
            className="flex items-center cursor-pointer justify-center rounded-md px-2 h-6 mr-1 bg-gray-200 hover:bg-primary icon-container"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="icon-style" viewBox="0 0 20 20">
              <path
                fillRule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          <button
            className="flex items-center cursor-pointer justify-center rounded-md px-2 h-6 mr-1 bg-gray-200 hover:bg-primary icon-container"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="icon-style" viewBox="0 0 20 20">
              <path
                fillRule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          <button
            className="flex items-center cursor-pointer justify-center rounded-md px-2 h-6 mr-1 bg-gray-200 hover:bg-primary icon-container"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="icon-style" viewBox="0 0 20 20">
              <path
                fillRule="evenodd"
                d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              />
              <path
                fillRule="evenodd"
                d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          <span className="px-2 text-center text-gray-500">
            Page
            <strong> {pageIndex + 1}</strong> of <strong>{pageCount}</strong>
          </span>
        </div>
      </div>
    </div>
  );
}

export default TableControlPanel;
