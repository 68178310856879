const fwcTypes = {
  END_CLIENT: 1,
  SUBCO: 2,
};

const fwcTypeLabels = {
  1: 'End-client',
  2: 'Subco',
};

module.exports = { fwcTypes, fwcTypeLabels };
