import React, { useMemo } from 'react';

import { TimeSheetStatusLabels, TimeSheetStatus } from '../../helpers/enum/timeSheet';
import TimesheetItem from './TimesheetItem';
import { useLocation } from 'react-router-dom';
import { useQuery } from '../../hooks/useQuery';
import TabList from '../TabList/TabList';

function MyTimesheetsTabList({ isLoading, timesheets }) {
  const location = useLocation();
  const hash = location.hash;

  let query = useQuery();
  const page = Number(query.get('page')) || 1;

  const tabs = useMemo(() => {
    let count = {
      [TimeSheetStatus.PENDING]: 0,
      [TimeSheetStatus.SUBMITTED]: 0,
      [TimeSheetStatus.CHANGESREQUESTED]: 0,
      [TimeSheetStatus.PREAPPROVE]: 0,
      [TimeSheetStatus.APPROVED]: 0,
    };

    timesheets?.forEach(el => {
      count[el.statusId] += 1;
    });

    return [
      {
        name: TimeSheetStatusLabels[TimeSheetStatus.PENDING],
        href: '/timesheets',
        count: count[TimeSheetStatus.PENDING],
        current: !hash,
      },
      {
        name: TimeSheetStatusLabels[TimeSheetStatus.SUBMITTED],
        href: '#' + TimeSheetStatus.SUBMITTED,
        count: count[TimeSheetStatus.SUBMITTED],
        current: hash.includes(TimeSheetStatus.SUBMITTED),
      },
      {
        name: TimeSheetStatusLabels[TimeSheetStatus.CHANGESREQUESTED],
        href: '#' + TimeSheetStatus.CHANGESREQUESTED,
        count: count[TimeSheetStatus.CHANGESREQUESTED],
        current: hash.includes(TimeSheetStatus.CHANGESREQUESTED),
      },
      {
        name: TimeSheetStatusLabels[TimeSheetStatus.PREAPPROVE],
        href: '#' + TimeSheetStatus.PREAPPROVE,
        count: count[TimeSheetStatus.PREAPPROVE],
        current: hash.includes(TimeSheetStatus.PREAPPROVE),
      },
      {
        name: TimeSheetStatusLabels[TimeSheetStatus.APPROVED],
        href: '#' + TimeSheetStatus.APPROVED,
        count: count[TimeSheetStatus.APPROVED],
        current: hash.includes(TimeSheetStatus.APPROVED),
      },
    ];
  }, [timesheets, hash]);

  const pages = useMemo(() => {
    let itemCount = 0;
    timesheets?.forEach(timesheet => {
      if (hash) {
        if (hash.includes(timesheet.statusId)) itemCount += 1;
      } else {
        if (timesheet.statusId === 0) itemCount += 1;
      }
      return '';
    });

    let pageSize = 5;
    if (itemCount <= pageSize) return 1;
    else return Math.ceil(itemCount / pageSize);
  }, [timesheets, hash]);

  const renderTimesheetList = () => {
    if (!timesheets?.length) return <li className="mt-8">No timesheets to show</li>;
    const filteredTimesheets = timesheets.filter(ts => {
      if (hash) {
        if (hash.includes(ts.statusId)) return ts;
      } else {
        if (ts.statusId === 0) return ts;
      }
    });
    const paginatedTimesheets = filteredTimesheets.slice(((page || 1) - 1) * 5, (page || 1) * 5);

    const timesheetItems = paginatedTimesheets.map(ts => <TimesheetItem timesheet={ts} />);

    if (timesheetItems?.length) return timesheetItems;
    else return <li className="mt-8">No timesheets to show</li>;
  };

  return (
    <TabList
      page={page}
      isLoading={isLoading}
      renderList={renderTimesheetList}
      tabs={tabs}
      pageCount={pages}
      href="/timesheets"
    />
  );
}

export default MyTimesheetsTabList;
