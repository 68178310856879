import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import TokenExpiry from './containers/App/TokenExpiry';
import AppRouter from './router/AppRouter';
import configureStore from './store';

// Configure redux store
const store = configureStore();

const render = () => {
  ReactDOM.render(
    <Router>
      <Provider store={store}>
        <TokenExpiry />
        <AppRouter />
      </Provider>
    </Router>,
    document.getElementById('root'),
  );
};

render();
