import React, { useEffect, useMemo } from 'react';
import Select from 'react-select';
import { LockClosedIcon, XIcon } from '@heroicons/react/outline';
import Error from '../Error/Error';
import InfoTooltip from '../Tooltips/InfoTooltip';
function ReactSelect({
  label,
  options,
  isMulti = false,
  onChange,
  selectedOptions,
  customStyles,
  labelClass,
  error,
  disabled,
  selectedOptionsIds,
  isSearchable = false,
  orderOptions = true,
  createOption = false,
  hideLockIcon = false,
  tooltip,
  tooltipMsg,
  ...rest
}) {
  useEffect(() => {
    if (selectedOptions?.length && selectedOptionsIds?.length)
      alert('React Select cannot receive selectedOptions and selectedOptionsIds');
  });

  const selectStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '' : '',
      backgroundColor: state.isSelected ? 'rgba(229, 231, 235)' : '',
      ':hover': {
        backgroundColor: state.isSelected ? 'rgb(229 231 235)' : 'rgb(229 231 235)',
      },
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: `${disabled ? 'rgb(229 231 235)' : 'white'}`,
      outline: state.isFocused ? '0px solid red' : '',
      outlineOffset: state.isFocused ? '16px' : '8px',
      borderRadius: '0.5rem',
      fontSize: '0.85rem',
      marginTop: '0.25rem',
      height: 'auto',
      boxShadow: 'none',
      borderStyle: '1px solid rgb(209 213 219)',
      border: state.isFocused ? `1px solid #FFA53B` : '1px solid rgb(209 213 219)',
      boxShadow: state.isFocused ? `0 0 0 1px #FFA53B` : '1px solid rgb(209 213 219)',
      '&:hover': {
        border: state.isFocused ? `1px solid #FFA53B` : '1px solid rgb(209 213 219)',
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: '20',
    }),
    singleValue: provided => ({
      ...provided,
      lineHeight: '1.25',
      marginTop: '1px',
      color: 'rgb(55 65 81)',
    }),
  };

  /* Expected options format
  const options = [{
    value: 'value',
    label: 'label',
  }] */

  const value = useMemo(() => {
    let _value = null;
    if (selectedOptionsIds?.length && options?.length) {
      _value = options.filter(i => {
        return selectedOptionsIds?.some(id => id === i.value);
      });
    }
    if (selectedOptions) {
      _value = selectedOptions;
    }
    return _value;
  }, [selectedOptions, selectedOptionsIds, options]);

  if (orderOptions) {
    options.sort(function (a, b) {
      let aValue = a.label;
      let bValue = b.label;
      if (typeof aValue === 'string') aValue = aValue.toLowerCase();
      if (typeof bValue === 'string') bValue = bValue.toLowerCase();

      if (aValue > bValue) {
        return -1;
      }

      if (aValue < bValue) {
        return 1;
      }
      return 0;
    });
  }

  //the following helper function is to identify if an object contains any circular references because if it does it cannot be converted to JSON and used as a unique key. This is the case in the page of my documents module because we are passing in a div element that contains an icon. HTML elements are circular and it was causing an error.
  function isCircular(obj) {
    const seenObjects = new WeakSet();

    function detect(obj) {
      if (obj && typeof obj === 'object') {
        if (seenObjects.has(obj)) {
          return true;
        }
        seenObjects.add(obj);

        for (const key in obj) {
          if (obj.hasOwnProperty(key) && detect(obj[key])) {
            return true;
          }
        }
      }
      return false;
    }

    return detect(obj);
  }

  const myUniqueKey = isCircular(value)
    ? `my_unique_select_key__${JSON.stringify({ my: 'fake object' })}`
    : `my_unique_select_key__${JSON.stringify(value)}`;

  return (
    <div className="w-full">
      <div className="flex items-center mb-1">
        {label && (
          <label className={`block text-sm font-medium ${labelClass ? labelClass : 'text-gray-700'} mr-2 `}>{label}</label>
        )}
        {tooltip && <InfoTooltip children={tooltipMsg} iconClassName="h-4 w-4 text-thaleria-orange-700" />}
        {error && <Error message={error} />}
      </div>
      <div className={`w-full relative ${disabled && 'cursor-not-allowed'}`}>
        <Select
          key={myUniqueKey}
          options={createOption ? [{ value: 0, label: 'Create new' }, ...options] : options}
          value={value}
          styles={customStyles ? customStyles : selectStyles}
          isMulti={isMulti}
          onChange={onChange}
          className="basic-multi-select"
          classNamePrefix="select"
          isDisabled={disabled}
          isSearchable={isSearchable}
          components={disabled ? { DropdownIndicator: () => null, IndicatorSeparator: () => null } : ''}
          {...rest}
        />
        {disabled && !hideLockIcon && (
          <LockClosedIcon className="h-5 w-5 my-auto mr-2 text-gray-500 absolute inset-y-0 right-0" />
        )}
      </div>
    </div>
  );
}

export default ReactSelect;
