import { SET_NOTIFICATION, HIDE_NOTIFICATION } from './types';

const initialState = {
  notification: {
    show: false,
    type: '',
    title: '',
    message: '',
  },
  tableFilters: {
    table: '',
    filters: [],
  },
};

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case SET_NOTIFICATION:
      return {
        ...state,
        notification: {
          show: true,
          type: action.payload.type,
          title: action.payload.title,
          msg: action.payload.msg,
        },
      };
    case HIDE_NOTIFICATION:
      return {
        ...state,
        notification: {
          ...state.notification,
          show: false,
        },
      };
    default:
      return state;
  }
}
