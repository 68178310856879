function formatCurrency(number, euro = true, euroBefore = false) {
  if (number === null || number === undefined) {
    return null;
  }

  if (typeof number === 'object') {
    number = number.valueOf();
  }

  if (typeof number === 'string') {
    number = parseFloat(number);
  }

  if (typeof number !== 'number' || isNaN(number)) {
    return false;
  }

  const sign = Math.sign(number) === -1 ? '- ' : '';
  const absoluteNumber = Math.abs(number);
  const roundedNumber = Math.round(absoluteNumber * 100) / 100; // Round to 2 decimal places

  const [integerPart, decimalPart] = String(roundedNumber).split('.');

  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  const formattedDecimal = decimalPart !== undefined ? decimalPart.padEnd(2, '0') : '00';

  let result = sign + formattedInteger + ',' + formattedDecimal;

  result = euro ? (euroBefore ? '€' + result : result + ' €') : result;

  return result;
}

export default formatCurrency;
