import React from 'react';

function NumberRangeColumnFilter({ column: { filterValue = [], preFilteredRows, setFilter, filterOptions, id } }) {
  const [min, max] = React.useMemo(() => {
    if (filterOptions) return [filterOptions.minNumber, filterOptions.maxNumber];
    else return [null, null];
    // DEACTIVATED FOR SERVER SIDE TABLE
    // let min = preFilteredRows?.length ? preFilteredRows[0].values[id] : null;
    // let max = preFilteredRows?.length ? preFilteredRows[0].values[id] : null;
    // preFilteredRows?.forEach(row => {
    //   min = Math.min(row.values[id], min);
    //   max = Math.max(row.values[id], max);
    // });
    // return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div className={'flex items-center flex-col sm:flex-row'}>
      <input
        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
        value={typeof filterValue[0] === 'number' ? filterValue[0] : ''}
        type="number"
        onChange={e => {
          const val = e.target.value;
          setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]]);
        }}
        placeholder={typeof min === 'number' ? `Min (${min})` : false}
      />
      <span className="mx-2">to</span>
      <input
        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
        value={typeof filterValue[1] === 'number' ? filterValue[1] : ''}
        type="number"
        onChange={e => {
          const val = e.target.value;
          setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined]);
        }}
        placeholder={typeof max === 'number' ? `Max (${max})` : false}
      />
    </div>
  );
}

export default NumberRangeColumnFilter;
