import React, { useState, useEffect } from 'react';
import classNames from '../../utils/classNames';
import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon } from '@heroicons/react/solid';
import Loader from '../../components/Loading/Loader';
import { Link, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import ReactSelect from '../../components/Inputs/ReactSelect';
import useWindowSize from '../../hooks/useWindowSize';

function TabList({ tabs, renderList, pageCount, isLoading, page, href }) {
  const windowSize = useWindowSize();

  const isSmallScreen = windowSize.width <= windowSize.tailwind.md;
  const history = useHistory();
  const location = useLocation();
  const hash = location.hash;

  const [tabOptions, setTabOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState({});

  useEffect(() => {
    if (!tabs.length) return;
    const array = tabs.map(t => ({
      label: t.name,
      value: t.href,
    }));
    setTabOptions(array);
    setSelectedOption(array[0]);
  }, []);

  const handleTabChange = e => {
    setSelectedOption(e);
    history.push(e.value);
  };

  const renderPages = () => {
    const cannotPrevPage = page === 1 || !page;
    const cannotNextPage = page === pageCount || pageCount === 1;

    return (
      <nav
        className="px-4 sm:px-0 w-full flex-shrink-0 border-t border-gray-300 flex items-center justify-between"
        aria-label="Pagination"
      >
        <div className="w-full flex justify-between pt-4 items-center">
          {!isSmallScreen && (
            <div className="flex items-center">
              <span className="mr-2 text-gray-500">Go to page:</span>
              <input
                className="mr-2 w-16 h-11 pl-1 text-gray-500 border-2 border-gray-100 rounded-md"
                type="number"
                min={1}
                max={pageCount}
                value={page}
                onChange={e => {
                  const page = Number(e.target.value) || null;
                  history.push(`?page=${page}${hash}`);
                }}
              />
            </div>
          )}
          <div className="flex items-center">
            <Link
              to={`?page=${0}${hash}`}
              className="flex items-center cursor-pointer justify-center rounded-md px-2 h-6 mr-1 bg-gray-200 hover:bg-primary icon-container"
              onClick={cannotPrevPage ? e => e.preventDefault() : undefined}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="icon-style" viewBox="0 0 20 20">
                <path
                  fillRule="evenodd"
                  d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </Link>
            <Link
              className="flex items-center cursor-pointer justify-center rounded-md px-2 h-6 mr-1 bg-gray-200 hover:bg-primary icon-container"
              onClick={cannotPrevPage ? e => e.preventDefault() : undefined}
              to={`?page=${page - 1}${hash}`}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="icon-style" viewBox="0 0 20 20">
                <path
                  fillRule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </Link>
            <Link
              className="flex items-center cursor-pointer justify-center rounded-md px-2 h-6 mr-1 bg-gray-200 hover:bg-primary icon-container"
              onClick={cannotNextPage ? e => e.preventDefault() : undefined}
              to={`?page=${page + 1}${hash}`}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="icon-style" viewBox="0 0 20 20">
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </Link>
            <Link
              className="flex items-center cursor-pointer justify-center rounded-md px-2 h-6 mr-1 bg-gray-200 hover:bg-primary icon-container"
              onClick={cannotNextPage ? e => e.preventDefault() : undefined}
              to={`?page=${pageCount}${hash}`}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="icon-style" viewBox="0 0 20 20">
                <path
                  fillRule="evenodd"
                  d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
                <path
                  fillRule="evenodd"
                  d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </Link>
            <span className="px-2 text-center text-gray-500">
              Page
              <strong> {page}</strong> of <strong>{pageCount}</strong>
            </span>
          </div>
        </div>
      </nav>
    );
  };

  return (
    <div
      className="shadow max-w-7xl mx-auto py-4 bg-white rounded-lg sm:px-6 lg:px-8 flex flex-col justify-between"
      style={{ minHeight: '620px' }}
    >
      <Loader isLoading={isLoading}>
        <div>
          {/* Tabs */}
          <div className="px-4 mb-6 sm:hidden">
            <label htmlFor="tabs" className="sr-only">
              Select a tab
            </label>
            {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
            <ReactSelect
              options={tabOptions}
              selectedOptions={selectedOption}
              onChange={handleTabChange}
              defaultValue={tabs.find(tab => tab.current).name}
            >
              {tabs.map(tab => (
                <option onClick={() => history.push(tab.href)} key={tab.name}>
                  {tab.name}
                </option>
              ))}
            </ReactSelect>
          </div>
          <div className="hidden sm:block">
            <div className="border-b border-gray-200">
              <nav className="mt-2 -mb-px flex space-x-4 overflow-x-auto no-scrollbar" aria-label="Tabs">
                {tabs.map(tab => (
                  <Link
                    key={tab.name}
                    to={tab.href}
                    className={classNames(
                      tab.current
                        ? 'border-thaleria-orange-500 text-thaleria-orange-700'
                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                      'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm',
                    )}
                  >
                    {tab.name}
                    {tab.count ? (
                      <span
                        className={classNames(
                          tab.current ? 'bg-thaleria-orange-100 text-thaleria-orange-600' : 'bg-gray-100 text-gray-900',
                          'hidden ml-2 py-0.5 px-1.5 rounded-full text-xs font-medium md:inline-block',
                        )}
                      >
                        {tab.count}
                      </span>
                    ) : null}
                  </Link>
                ))}
              </nav>
            </div>
          </div>
          {/* Stacked list */}
          <ul role="list" className="mt-5 border-t border-gray-200 divide-y divide-gray-200 sm:mt-0 sm:border-t-0 px-4">
            {renderList()}
          </ul>
        </div>
        {renderPages()}
      </Loader>
    </div>
  );
}

export default TabList;
