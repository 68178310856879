const clientTypes = {
  strings: {
    0: 'EU',
    1: 'Private',
    2: 'Public',
  },
  numbers: {
    EU: 0,
    Private: 1,
    Public: 2,
  },
};

export default clientTypes;
