import React from 'react';

function NewTabOpener({ link, title }) {
  return (
    <div>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={link}
        className="text-xs mt-2 ml-2 text-thaleria-blue-300 hover:text-thaleria-blue-500 cursor-pointer"
      >
        {title}
      </a>
    </div>
  );
}

export default NewTabOpener;
