import React, { useMemo, useState } from 'react';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import ShortToggle from '../Toggles/ShortToggle';
import ButtonPrimary from '../Buttons/ButtonPrimary';
import ButtonWhite from '../Buttons/ButtonWhite';
import Modal from './Modal';
import useApi from '../../hooks/useApi';
function EditColumns({
  allColumns,
  showColumnGroup,
  setShowColumnGroup,
  onClick,
  toggleHideAllColumns,
  setColumnOrder,
  columnOrder,
  show,
  setShow,
  userSettings,
  setUserSettings,
  tableName,
}) {
  const animatedComponents = makeAnimated();

  const {
    settings: { updateTableSettings },
  } = useApi();

  const saveColumnSettings = async () => {
    let updated = {
      ...userSettings,
      [tableName]: {
        ...userSettings[tableName],
        columns: allColumns.map(col => {
          const _col = {
            Header: col.Header,
            isVisible: col.isVisible,
            accessor: col.accessor,
          };

          if (col?.parent) _col['parent'] = col?.parent?.Header;
          return _col;
        }),
      },
    };
    if (userSettings[tableName] && JSON.stringify(userSettings[tableName].customColumnOrder) !== JSON.stringify(columnOrder)) {
      updated[tableName].customColumnOrder = columnOrder;
    }
    setUserSettings(updated);
    await updateTableSettings(updated);
  };

  const handleSaveColumns = () => {
    saveColumnSettings();
    setShow(false);
  };

  const handleToggle = label => {
    var regex = new RegExp(`^${label}_`);

    setShowColumnGroup({ [label]: !showColumnGroup[label] });
    if (!showColumnGroup[label]) {
      allColumns.map(column => {
        if (column.id.match(regex)) {
          if (!column.isVisible) column.toggleHidden();
        }
      });
    }
    if (showColumnGroup[label]) {
      allColumns.map(column => {
        if (column.id.match(regex)) {
          if (column.isVisible) column.toggleHidden();
        }
      });
    }
  };

  const handleResetColumns = () => {
    toggleHideAllColumns(false);
    setShowColumnGroup({
      consultant: true,
      staffOrder: true,
      staffContract: true,
      partnerContract: true,
      frameworkContract: true,
      salesOrder: true,
      specificContract: true,
    });
    setColumnOrder([]);
  };

  const optionLabels = {
    consultant: {
      label: 'Consultant',
      accessor: 'header_consultant',
    },
    staffOrder: {
      label: 'Purchase Order',
      accessor: 'header_staffOrder',
    },
    staffContract: { label: 'Staff Contract', accessor: 'header_staffContract' },
    frameworkContract: {
      label: 'End-Client Framework Contract',
      accessor: 'header_frameworkContract',
    },
    partnerContract: {
      label: 'Sales Framework Contract',
      accessor: 'header_partnerContract',
    },
    salesOrder: {
      label: 'Sales Order',
      accessor: 'header_salesOrder',
    },
    specificContract: {
      label: 'Specific Contract',
      accessor: 'header_specificContract',
    },
  };

  const [numberOfVisibleColumnGroups, setNumberOfVisibleColumnGroups] = useState(0);

  var orderColumnOptions = useMemo(() => {
    const array = [];
    var countVisible = 0;
    Object.keys(showColumnGroup).forEach(key => {
      if (showColumnGroup[key]) {
        countVisible++;
        array.push({
          label: optionLabels[key].label,
          value: optionLabels[key].accessor,
        });
      }
    });
    setNumberOfVisibleColumnGroups(countVisible);
    return array;
  }, [showColumnGroup]);

  const [orderedColumns, setOrderedColumns] = useState([]);

  const handleColumnOrderChange = value => {
    if (!value) return;
    setOrderedColumns(value.map(value => value.value));
  };

  const handleOrderColumnsClick = () => {
    if (orderedColumns.length !== numberOfVisibleColumnGroups)
      return alert('Please order all columns in order to apply this selection'); //TODO: Replace by proper warning modal
    let fullColumnsArray = [];
    orderedColumns.map(columnGroup => {
      allColumns.map(column => {
        if (column.parent?.id === columnGroup) {
          fullColumnsArray.push(column.id);
        }
      });
    });
    setColumnOrder(fullColumnsArray);
    onClick();
  };

  return (
    <Modal show={show} hide={() => setShow(false)} title="Edit columns" size="lg">
      <div role="dialog" aria-modal="true" aria-labelledby="modal-headline">
        <div>
          <div className="flex justify-between p-4 space-x-4">
            {' '}
            <div className="w-10/12">
              <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                placeholder={'Order columns...'}
                isMulti
                options={orderColumnOptions}
                onChange={handleColumnOrderChange}
              />
            </div>
            {/* </div> */}
            <div className="flex justify-center items-center">
              <ButtonWhite onClick={handleOrderColumnsClick} text={'Apply'} />
            </div>
          </div>
          <div className="bg-gray-100 flex justify-between items-center px-4 py-4">
            <span className="ml-2">Consultant</span>
            <ShortToggle bg={'bg-gray-100'} active={showColumnGroup.consultant} onClick={() => handleToggle('consultant')} />
          </div>
          <div className="grid grid-cols-2 gap-x-10">
            {showColumnGroup.consultant &&
              allColumns.map(column => {
                if (column.id.match(/^consultant_/)) {
                  return (
                    <div className="relative flex mt-2 mx-4 justify-between">
                      <div key={column.id} className="text-sm">
                        <label className="font-medium text-gray-700">{column.Header}</label>
                      </div>
                      <div className="flex items-center ml-4 h-5">
                        <input
                          type="checkbox"
                          {...column.getToggleHiddenProps()}
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        />
                      </div>
                    </div>
                  );
                }
              })}
          </div>
          <div className="bg-gray-100 mt-2 flex justify-between items-center px-4 py-4">
            <span className="ml-2">Purchase Order</span>
            <ShortToggle bg={'bg-gray-100'} active={showColumnGroup.staffOrder} onClick={() => handleToggle('staffOrder')} />
          </div>
          {showColumnGroup.staffOrder && (
            //THIS IS WHERE WE'RE TESTING THE NEW CHECKBOX DISPLAY
            <div className="grid grid-cols-2 gap-x-10">
              {allColumns.map(column => {
                if (column.id.match(/^staffOrder_/)) {
                  return (
                    <div className="relative flex mt-2 mx-4 justify-between">
                      <div key={column.id} className="text-sm">
                        <label className="font-medium text-gray-700">{column.Header}</label>
                      </div>
                      <div className="flex items-center ml-4 h-5">
                        <input
                          type="checkbox"
                          {...column.getToggleHiddenProps()}
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        />
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          )}
          <div className="bg-gray-100 mt-2 flex justify-between items-center px-4 py-4">
            <span className="ml-2">Staff Contract</span>
            <ShortToggle
              bg={'bg-gray-100'}
              active={showColumnGroup.staffContract}
              onClick={() => handleToggle('staffContract')}
            />
          </div>
          {showColumnGroup.staffContract && (
            <div className="grid grid-cols-2 gap-x-10">
              {allColumns.map(column => {
                if (column.id.match(/^staffContract/)) {
                  return (
                    <div className="relative flex mt-2 mx-4 justify-between">
                      <div key={column.id} className="text-sm">
                        <label className="font-medium text-gray-700">{column.Header}</label>
                      </div>
                      <div className="flex items-center ml-4 h-5">
                        <input
                          type="checkbox"
                          {...column.getToggleHiddenProps()}
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        />
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          )}
          <div className="bg-gray-100 mt-2 flex justify-between items-center px-4 py-4">
            <span className="ml-2">End-Client Framework Contract</span>
            <ShortToggle
              bg={'bg-gray-100'}
              active={showColumnGroup.frameworkContract}
              onClick={() => handleToggle('frameworkContract')}
            />
          </div>
          {showColumnGroup.frameworkContract && (
            <div className="grid grid-cols-2 gap-x-10">
              {allColumns.map(column => {
                if (column.id.match(/^frameworkContract/)) {
                  return (
                    <div className="relative flex mt-2 mx-4 justify-between">
                      <div key={column.id} className="text-sm">
                        <label className="font-medium text-gray-700">{column.Header}</label>
                      </div>
                      <div className="flex items-center ml-4 h-5">
                        <input
                          type="checkbox"
                          {...column.getToggleHiddenProps()}
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        />
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          )}
          <div className="bg-gray-100 mt-2 flex justify-between items-center px-4 py-4">
            <span className="ml-2">Sales Framework Contract</span>
            <ShortToggle
              bg={'bg-gray-100'}
              active={showColumnGroup.partnerContract}
              onClick={() => handleToggle('partnerContract')}
            />
          </div>
          {showColumnGroup.partnerContract && (
            <div className="grid grid-cols-2 gap-x-10">
              {allColumns.map(column => {
                if (column.id.match(/^partnerContract/)) {
                  return (
                    <div className="relative flex mt-2 mx-4 justify-between">
                      <div key={column.id} className="text-sm">
                        <label className="font-medium text-gray-700">{column.Header}</label>
                      </div>
                      <div className="flex items-center ml-4 h-5">
                        <input
                          type="checkbox"
                          {...column.getToggleHiddenProps()}
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        />
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          )}
          <div className="bg-gray-100 mt-2 flex justify-between items-center px-4 py-4">
            <span className="ml-2">Sales Order</span>
            <ShortToggle bg={'bg-gray-100'} active={showColumnGroup.salesOrder} onClick={() => handleToggle('salesOrder')} />
          </div>
          {showColumnGroup.salesOrder && (
            <div className="grid grid-cols-2 gap-x-10">
              {allColumns.map(column => {
                if (column.id.match(/^salesOrder/)) {
                  return (
                    <div className="relative flex mt-2 mx-4 justify-between">
                      <div key={column.id} className="text-sm">
                        <label className="font-medium text-gray-700">{column.Header}</label>
                      </div>
                      <div className="flex items-center ml-4 h-5">
                        <input
                          type="checkbox"
                          {...column.getToggleHiddenProps()}
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        />
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          )}
          <div className="bg-gray-100 mt-2 flex justify-between items-center px-4 py-4">
            <span className="ml-2">Specific Contract</span>
            <ShortToggle
              bg={'bg-gray-100'}
              active={showColumnGroup.specificContract}
              onClick={() => handleToggle('specificContract')}
            />
          </div>
          {showColumnGroup.specificContract && (
            <div className="grid grid-cols-2 gap-x-10">
              {allColumns.map(column => {
                if (column.id.match(/^specificContract/)) {
                  return (
                    <div className="relative flex mt-2 mx-4 justify-between">
                      <div key={column.id} className="text-sm">
                        <label className="font-medium text-gray-700">{column.Header}</label>
                      </div>
                      <div className="flex items-center ml-4 h-5">
                        <input
                          type="checkbox"
                          {...column.getToggleHiddenProps()}
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        />
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          )}
        </div>
        <div className="flex flex-row-reverse mt-5 mx-6 sm:mt-6">
          <ButtonPrimary onClick={handleSaveColumns} style={'ml-2'} text={'Save'} />
          <ButtonWhite onClick={handleResetColumns} style={'mr-2'} text={'Reset columns'} />
        </div>
      </div>
    </Modal>
  );
}

export default EditColumns;
