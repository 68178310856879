import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import AddClient from '../../../../containers/AdminManager/AddOrEditClient';
import PageWrapper from '../../../../containers/App/PageWrapper';
import { capitalizeFirstLetter } from '../../../../utils/capitalizeFirstLetter';
import useApi from '../../../../hooks/useApi';

export default function AddClients() {
  const history = useHistory();

  const {
    clients: { getAllClients, createClient, updateClient },
    countries: { getAllCountries },
  } = useApi();
  const { id } = useParams();

  useEffect(() => {
    getAllClients().then(d => setClients(d));
    updateCountries();
  }, []);

  const [clients, setClients] = useState([]);
  const [countries, setCountries] = useState([]);

  const updateCountries = async () => {
    getAllCountries().then(res => setCountries(res));
  };

  const handleClick = form => {
    if (id === 'create') {
      createClient(form).then(data => {
        data ? history.push('/admin-panel/admin/clients') : alert('Error while saving the details');
      });
    } else {
      updateClient(id, form).then(data => {
        data ? history.push('/admin-panel/admin/clients') : alert('Error while saving the details');
      });
    }
  };
  //FIXME: Why is the first button on the header a link to go to contracts manager?
  const pages = [
    { name: 'Contracts Manager', href: '/admin-panel/contracts/purchase-orders', current: false },
    { name: 'Clients', href: '/admin-panel/admin/clients', current: false },
    { name: capitalizeFirstLetter(id), href: '/admin-panel/admin/clients', current: false },
    { name: id === 'create' ? undefined : 'Edit', href: '', current: true },
  ];

  return (
    <PageWrapper pages={pages}>
      <AddClient clients={clients} id={id} handleSave={handleClick} countries={countries} />
    </PageWrapper>
  );
}
