import React from 'react';
import ButtonPrimary from '../../components/Buttons/ButtonPrimary';
import ButtonWhite from '../../components/Buttons/ButtonWhite';
import Modal from '../../components/Modals/Modal';
import useApi from '../../hooks/useApi';

function FilterColumns({ headerGroups, setAllFilters, show, setShow, filters, userSettings, setUserSettings, tableName }) {
  const {
    settings: { updateTableSettings },
  } = useApi();

  const handleResetFilters = () => {
    setAllFilters([]);
  };

  const updateFilterSettings = async () => {
    let updated = {
      ...userSettings,
      [tableName]: {
        ...userSettings[tableName],
        filters: filters,
      },
    };
    setUserSettings(updated);
    await updateTableSettings(updated);
  };
  const handleSaveFilters = () => {
    updateFilterSettings();
    setShow(false);
  };

  return (
    <Modal show={show} hide={() => setShow(false)} title="Filter results" size="lg">
      <div role="dialog" aria-modal="true" aria-labelledby="modal-headline">
        <div>
          {headerGroups[headerGroups.length - 1].headers.map(column => {
            if (column.isVisible && column.canFilter)
              return (
                <div className="py-4 px-8">
                  <span className="block text-sm font-medium text-gray-700">{column.Header}</span>
                  {column.render('Filter')}
                </div>
              );
          })}
        </div>
        <div className="flex flex-row-reverse mt-5 mx-6 sm:mt-6">
          <ButtonPrimary onClick={handleSaveFilters} text={'Save'} />
          <ButtonWhite onClick={handleResetFilters} style={'mr-2'} text={'Reset filters'} />
        </div>
      </div>
    </Modal>
  );
}

export default FilterColumns;
