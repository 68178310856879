import React from 'react';
import Notification from '../../../containers/App/Notification.js';
import SignInSplitScreen from '../../../containers/Login/SignInSplitScreen.js';
import logo from '../../../resources/logos/Thaleria-Horizontal-Logo blue.png';
import PageWrapper from '../../../containers/App/PageWrapper';
import { API_BASE_URL } from '../../../configs.js';

function LoginPage() {
  return (
    <PageWrapper override tabTitle="Login">
      <div className="relative z-10 flex flex-col w-screen h-screen bg-gray-100 overflow-hidden">
        <Notification />
        <SignInSplitScreen logo={logo} />
      </div>
    </PageWrapper>
  );
}

export default LoginPage;
