import React from 'react';
import InfoTooltip from '../Tooltips/InfoTooltip';
import Error from '../Error/Error';
function SimpleEntry({ label, data, style, tooltip, tooltipMsg, error }) {
  return (
    <div className={style}>
      <div className="flex items-center">
        <dt className="text-sm font-medium text-gray-500 mr-2">{label}</dt>
        {error && <Error message={error} />}
        {tooltip && <InfoTooltip children={tooltipMsg} iconClassName="h-4 w-4 text-thaleria-orange-700" />}
      </div>
      <dd className="mt-1 text-sm text-gray-900">{data !== null && data !== undefined ? data : '-'}</dd>
    </div>
  );
}

export default SimpleEntry;
