import React, { useEffect, useState } from 'react';
import Card from '../../components/Cards/Card';
import Toggle from '../../components/Toggles/Toggle';
import { LeaveRequestStatus, LeaveRequestType } from '../../helpers/enum/leaveRequest';
import InputDate from '../../components/Inputs/InputDate/InputDate';
import dayjs from 'dayjs';
import { useHistory } from 'react-router';
import { InformationCircleIcon } from '@heroicons/react/solid';
import { Controller } from 'react-hook-form';
import Error from '../../components/Error/Error';
import { LeaveRequestStatusLabels, LeaveRequestTypeLabel, UserLeaveRequestTypeLabel } from '../../helpers/enum/leaveRequest';
import { useForm } from 'react-hook-form';
import calculateBusinessDays from '../../helpers/caclulateBusinessDays';
import { useYupValidationResolver } from '../../utils/hooks/useYupValidationResolver';
import * as Yup from 'yup';
import { VALIDATION_MSG, DATE_VALIDATION_MSG } from '../../helpers/enum/errorValidationMsgs';
import useApi from '../../hooks/useApi';
import ReactSelect from '../../components/Inputs/ReactSelect';
import { useSelector } from 'react-redux';
import { staffContractType } from '../../helpers/enum/staffContractType';

function MyLRDetails({ id, refresh }) {
  const {
    holidays: { getMyHolidaysForLR },
    leaveRequests: { getMyLeaveRequest, updateMyLeaveRequest },
  } = useApi();
  const history = useHistory();
  const currentUser = useSelector(state => state.auth.currentUser);

  const validationSchema = Yup.object().shape({
    description: Yup.string().trim(),
    startDate: Yup.date()
      .required(VALIDATION_MSG)
      .nullable()
      .when('requestType', {
        is: requestType => requestType !== LeaveRequestType.trainingLeave,
        then: Yup.date().test('is-weekend', 'Leave request dates cannot be on weekends', function (value) {
          if (value && (value.getDay() === 0 || value.getDay() === 6)) {
            return false;
          }
          return true;
        }),
      }),
    endDate: Yup.date()
      .required(VALIDATION_MSG)
      .nullable()
      .when(['startDate', 'requestType'], {
        is: (startDate, requestType) => startDate && requestType !== LeaveRequestType.trainingLeave,
        then: Yup.date().test('is-weekend', 'Leave request dates cannot be on weekends', function (value) {
          if (value && (value.getDay() === 0 || value.getDay() === 6)) {
            return false;
          }
          return true;
        }),
      })
      .when('startDate', (startDate, schema) => {
        return startDate ? schema.min(startDate, DATE_VALIDATION_MSG) : undefined;
      }),
    requestType: Yup.number().required(VALIDATION_MSG).nullable(),
  });

  const formOptions = { resolver: useYupValidationResolver(validationSchema) };

  const {
    control,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm(formOptions);

  const leaveRequestTypeOptions =
    currentUser?.StaffContracts[0]?.contract_type_id === staffContractType.EMPLOYEE
      ? [
          { value: LeaveRequestType.paidLeave, label: 'Paid Leave' },
          { value: LeaveRequestType.unPaidLeave, label: 'Unpaid Leave' },
          { value: LeaveRequestType.medicalLeave, label: 'Medical Leave' },
          { value: LeaveRequestType.trainingLeave, label: 'Training Leave' },
        ]
      : [
          { value: LeaveRequestType.unPaidLeave, label: 'Leave' },
          { value: LeaveRequestType.trainingLeave, label: 'Training Leave' },
        ];

  const showEndHalfDay = () => {
    if (data.startDate && data.endDate) return new Date(data.startDate).getTime() !== new Date(data.endDate).getTime();
    return true;
  };

  const [edit, setEdit] = useState(false);
  const [enableEdit, setEnableEdit] = useState(false);

  const [data, setData] = useState({
    deductedLeaveDays: null,
    startDate: null,
    endDate: null,
    endHalfDay: null,
    startHalfDay: null,
    status: null,
    requestType: null,
    weekendDates: null,
    holidays: null,
  });

  const [editedData, _setEditedData] = useState({
    deductedLeaveDays: null,
    startDate: null,
    endDate: null,
    endHalfDay: null,
    startHalfDay: null,
    requestType: null,
    weekendDates: null,
    holidays: null,
  });

  const setEditedData = newValue => {
    Object.entries(newValue).forEach(([name, value]) => {
      if (value || (name === 'requestType' && (value || value == 0))) {
        clearErrors(name);
      }
      setValue(name, value);
    });
    _setEditedData({ ...editedData, ...newValue });
  };

  const [tooltipStatus, setTooltipStatus] = useState(false);

  const fetchLRData = async () => {
    await getMyLeaveRequest(id)
      .then(data => {
        setData({
          deductedLeaveDays: data.deducted_leave_days,
          startDate: data.start_date,
          endDate: data.end_date,
          endHalfDay: data.end_half_day,
          startHalfDay: data.start_half_day,
          status: data.status,
          requestType: data.request_type,
          weekendDates: data.weekendDates,
          holidays: data.holidays,
        });
        setEditedData({
          startDate: data.start_date,
          endDate: data.end_date,
          endHalfDay: data.end_half_day,
          startHalfDay: data.start_half_day,
          requestType: data.request_type,
          weekendDates: data.weekendDates,
          holidays: data.holidays,
          deductedLeaveDays: data.deducted_leave_days,
        });
        if (data.status == LeaveRequestStatus.actionRequired || data.status == LeaveRequestStatus.pending) setEnableEdit(true);
        else setEnableEdit(false);
      })
      .catch(e => {
        history.goBack();
      });
  };

  useEffect(() => {
    fetchLRData();
  }, [id, refresh]);

  const handleSaveEdit = async () => {
    const dataToSend = {
      start_date: editedData.startDate,
      end_date: editedData.endDate,
      start_half_day: editedData.startHalfDay,
      end_half_day: editedData.endHalfDay,
      leave_type: editedData.requestType,
    };

    await updateMyLeaveRequest(id, dataToSend)
      .then(() => {
        setData(prev => ({ ...prev, ...editedData }));
      })
      .catch(() => {
        setEditedData({
          startDate: data.startDate,
          endDate: data.endDate,
          endHalfDay: data.endHalfDay,
          startHalfDay: data.startHalfDay,
          requestType: data.requestType,
          weekendDates: data.weekendDates,
          holidays: data.holidays,
          deductedLeaveDays: data.deductedLeaveDays,
        });
      });
    setEdit(false);
  };

  const toggleEdit = () => {
    setEdit(!edit);
    setEditedData({
      startDate: data.startDate,
      endDate: data.endDate,
      endHalfDay: data.endHalfDay,
      startHalfDay: data.startHalfDay,
      requestType: data.requestType,
      weekendDates: data.weekendDates,
      holidays: data.holidays,
      deductedLeaveDays: data.deductedLeaveDays,
    });
  };

  useEffect(() => {
    if (editedData?.startDate && editedData?.endDate && editedData.requestType == LeaveRequestType.paidLeave) {
      calculateBusinessDays(
        getMyHolidaysForLR,
        editedData?.startDate,
        editedData?.endDate,
        editedData?.startHalfDay,
        editedData?.endHalfDay,
      ).then(res => {
        return setEditedData({
          deductedLeaveDays: res.deductedDays,
          holidays: res.holidays,
          weekendDates: res.weekends,
        });
      });
    } else {
      if (editedData.deductedLeaveDays)
        return setEditedData({
          deductedLeaveDays: 0,
          holidays: [],
          weekendDates: [],
        });
    }
  }, [editedData?.startDate, editedData?.endDate, editedData?.startHalfDay, editedData?.endHalfDay, editedData.requestType]);

  return (
    <Card
      title="Request details"
      editButton={enableEdit}
      onEditClick={toggleEdit}
      onSaveEdit={handleSaveEdit}
      handleSubmit={handleSubmit}
      onCancelEdit={toggleEdit}
    >
      <dl className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="col-span-1 md:col-span-2">
          <dt className="text-sm font-medium text-gray-500">Leave status</dt>
          <dd className="mt-1 text-sm text-gray-900">{LeaveRequestStatusLabels[data.status]}</dd>
        </div>
        <div className="col-span-1 md:col-span-2">
          <div className="flex">
            <dt className="text-sm font-medium text-gray-500 mr-2">Leave type</dt>
          </div>
          {edit ? (
            <dd className="mt-1 ml-1 text-sm text-gray-900 w-full sm:w-3/6">
              <ReactSelect
                options={leaveRequestTypeOptions}
                selectedOptionsIds={editedData.requestType !== null && [editedData.requestType]}
                onChange={e => setEditedData({ requestType: e.value })}
              />
            </dd>
          ) : (
            <dd className="mt-1 text-sm text-gray-900">
              {UserLeaveRequestTypeLabel(data.requestType, currentUser?.StaffContracts[0]?.contract_type_id)}
            </dd>
          )}
        </div>
        <div className="col-span-1">
          <div className="flex">
            <dt className="text-sm font-medium text-gray-500 mr-2">Start date</dt>
            {errors?.['startDate']?.message && <Error message={errors?.['startDate']?.message} />}
          </div>
          {edit ? (
            <dd className="mt-1 text-sm text-gray-900">
              <Controller
                control={control}
                name={'startDate'}
                render={({ field: { onChange, value, ref } }) => (
                  <InputDate
                    inputRef={ref}
                    onChange={e => setEditedData({ startDate: e })}
                    selected={editedData.startDate !== null && new Date(editedData.startDate)}
                    disableWeekends={editedData.requestType === LeaveRequestType.trainingLeave ? false : true}
                  />
                )}
              />
            </dd>
          ) : (
            <dd className="mt-1 text-sm text-gray-900">{dayjs(data.startDate).format('DD/MM/YYYY')}</dd>
          )}
        </div>
        <div className="col-span-1">
          <dt className="text-sm font-medium text-gray-500">Half start date</dt>
          <dd className="mt-1 text-sm text-gray-900">
            {edit ? (
              <Toggle
                enable={editedData.startHalfDay}
                onChange={() => setEditedData({ startHalfDay: !editedData.startHalfDay })}
              />
            ) : (
              <Toggle enable={data.startHalfDay} />
            )}
          </dd>
        </div>
        <div className="col-span-1">
          <div className="flex">
            <dt className="text-sm font-medium text-gray-500 mr-2">End date</dt>
            {errors?.['endDate']?.message && <Error message={errors?.['endDate']?.message} />}
          </div>
          {edit ? (
            <dd className="mt-1 text-sm text-gray-900">
              <Controller
                control={control}
                name={'endDate'}
                render={({ field: { ref } }) => (
                  <InputDate
                    inputRef={ref}
                    onChange={e => setEditedData({ endDate: e })}
                    selected={editedData.endDate !== null && new Date(editedData.endDate)}
                    disableWeekends={editedData.requestType === LeaveRequestType.trainingLeave ? false : true}
                  />
                )}
              />
            </dd>
          ) : (
            <dd className="mt-1 text-sm text-gray-900">{dayjs(data.endDate).format('DD/MM/YYYY')}</dd>
          )}
        </div>
        {showEndHalfDay() && (
          <div className="col-span-1">
            <dt className="text-sm font-medium text-gray-500">Half end date</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {edit ? (
                <Toggle enable={editedData.endHalfDay} onChange={() => setEditedData({ endHalfDay: !editedData.endHalfDay })} />
              ) : (
                <Toggle enable={data.endHalfDay} />
              )}
            </dd>
          </div>
        )}
        {data.requestType === LeaveRequestType.paidLeave && !edit && (
          <div className="col-span-1 md:col-span-2">
            <div className="flex items-center">
              <dt className="text-sm font-medium text-gray-500">Deducted leave days</dt>
              <div className="relative" onMouseEnter={() => setTooltipStatus(true)} onMouseLeave={() => setTooltipStatus(false)}>
                {(data.holidays?.length > 0 || data.weekendDates?.length > 0) && (
                  <div className="mr-2">
                    <InformationCircleIcon title={'abc'} fill="orange" className="ml-2 h-5 w-5 filled" />
                  </div>
                )}
                {tooltipStatus && (
                  <div
                    role="tooltip"
                    style={{ background: '#F3F4F6' }}
                    className="z-50 -mt-20 w-64 absolute transition duration-150 ease-in-out left-0 ml-8 shadow-lg bg-white p-4 rounded"
                  >
                    {data.weekendDates?.length > 0 && (
                      <>
                        <p className="text-sm font-bold text-gray-800 pb-1">Weekends</p>
                        <p className="mb-2 text-xs leading-4 text-gray-600 pb-3">{data.weekendDates?.join('. ')}</p>
                      </>
                    )}
                    {data.holidays?.length > 0 && (
                      <>
                        <p className="text-sm font-bold text-gray-800 pb-1">Public Holidays</p>
                        <p className="text-xs leading-4 text-gray-600 pb-3">{data.holidays?.join('. ')}</p>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
            <dd className="mt-1 text-sm text-gray-900">{!data.deductedLeaveDays ? '-' : data.deductedLeaveDays}</dd>
          </div>
        )}
        {editedData.requestType === LeaveRequestType.paidLeave && edit && (
          <div className="col-span-1 md:col-span-2">
            <div className="flex items-center">
              <dt className="text-sm font-medium text-gray-500">Deducted leave days</dt>
              <div className="relative" onMouseEnter={() => setTooltipStatus(true)} onMouseLeave={() => setTooltipStatus(false)}>
                {(editedData.holidays?.length > 0 || editedData.weekendDates?.length > 0) && (
                  <div className="mr-2">
                    <InformationCircleIcon title={'abc'} fill="orange" className="ml-2 h-5 w-5 filled" />
                  </div>
                )}
                {tooltipStatus && (
                  <div
                    role="tooltip"
                    style={{ background: '#F3F4F6' }}
                    className="z-50 -mt-20 w-64 absolute transition duration-150 ease-in-out left-0 ml-8 shadow-lg bg-white p-4 rounded"
                  >
                    {editedData.weekendDates?.length > 0 && (
                      <>
                        <p className="text-sm font-bold text-gray-800 pb-1">Weekends</p>
                        <p className="mb-2 text-xs leading-4 text-gray-600 pb-3">{editedData.weekendDates?.join('. ')}</p>
                      </>
                    )}
                    {editedData.holidays?.length > 0 && (
                      <>
                        <p className="text-sm font-bold text-gray-800 pb-1">Public Holidays</p>
                        <p className="text-xs leading-4 text-gray-600 pb-3">{editedData.holidays?.join('. ')}</p>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
            <dd className="mt-1 text-sm text-gray-900">{!editedData.deductedLeaveDays ? '-' : editedData.deductedLeaveDays}</dd>
          </div>
        )}
      </dl>
    </Card>
  );
}

export default MyLRDetails;
