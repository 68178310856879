/* This example requires Tailwind CSS v2.0+ */
import { HomeIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';

export default function Breadcrumbs({ rightDiv, pages, constrainWidth }) {
  const ArrowIcon = () => (
    <svg
      className="flex-shrink-0 w-6 h-full text-gray-200"
      viewBox="0 0 24 44"
      preserveAspectRatio="none"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
    </svg>
  );

  return (
    <nav className={`mx-auto flex justify-between overflow-x-auto w-full border-y border-gray-200`} aria-label="Breadcrumb">
      <ol
        role="list"
        className={`${constrainWidth && 'max-w-7xl 2xl:px-0'} px-2 sm:px-6 lg:px-8 w-full mx-auto flex space-x-1 sm:space-x-4`}
      >
        <li className="flex">
          <div className="flex items-center">
            <Link to="/home" className="text-gray-400 hover:text-gray-500">
              <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </Link>
          </div>
        </li>
        <ArrowIcon />
        {pages?.map((page, i) => {
          if (!page?.name) return '';
          return (
            <>
              {i !== 0 && <ArrowIcon />}
              <li key={page.name} className="flex">
                <div className="flex items-center">
                  {page.href ? (
                    <Link
                      to={page.href}
                      className="text-sm font-medium text-gray-500 hover:text-gray-700"
                      aria-current={page.current ? 'page' : undefined}
                    >
                      {page.name}
                    </Link>
                  ) : page.onClick ? (
                    <div
                      className="cursor-pointer text-sm font-medium text-gray-500 hover:text-gray-700"
                      aria-current={page.current ? 'page' : undefined}
                      onClick={page.onClick}
                    >
                      {page.name}
                    </div>
                  ) : (
                    <div
                      className="text-sm font-medium text-gray-500 hover:text-gray-700"
                      aria-current={page.current ? 'page' : undefined}
                    >
                      {page.name}
                    </div>
                  )}
                </div>
              </li>
            </>
          );
        })}
      </ol>
      <div className="flex space-x-4 items-center">{rightDiv}</div>
    </nav>
  );
}
