import React from 'react';
import excel from '../Images/excel.png';
import bluedoc from '../Images/bluedoc.png';
import doc from '../Images/doc.png';
import folder from '../Images/folder.png';
import image from '../Images/image.png';
import pdf from '../Images/pdf.png';
import ppt from '../Images/ppt.png';
import txt from '../Images/txt.png';
import xls from '../Images/xls.png';
import zip from '../Images/zip.png';

const FilesIcon = ({ fileType, className }) => {
  let imgSrc;
  let imgAlt = 'File icon';

  switch (fileType.toLowerCase()) {
    case 'pdf':
      imgSrc = pdf;
      break;
    case 'docx':
    case 'doc':
      imgSrc = doc;
      break;
    case 'pptx':
      imgSrc = ppt;
      break;
    case 'xlsx':
    case 'xls':
    case 'xlsm':
      imgSrc = xls;
      break;
    case 'xml':
    case 'csv':
      imgSrc = excel;
      break;
    case 'txt':
      imgSrc = txt;
      break;
    case 'zip':
    case 'rar':
      imgSrc = zip;
      break;
    case 'png':
    case 'jpg':
    case 'jpeg':
    case 'tiff':
      imgSrc = image;
      break;
    case 'folder':
    case '':
      imgSrc = folder;
      imgAlt = 'Folder icon';
      break;
    default:
      imgSrc = bluedoc;
      break;
  }

  return <img src={imgSrc} alt={imgAlt} className={`${className ? className : ''}`} />;
};

export default FilesIcon;
