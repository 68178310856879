import React from 'react';
import PageWrapper from '../../../containers/App/PageWrapper';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

function Finance({ children, pages, noPadding, navbar = true }) {
  const baseUrl = '/admin-panel/finance';
  const location = useLocation();

  const TYPES = [
    {
      name: 'Invoicing Items',
      url: `${baseUrl}/invoicing-items`,
      isActive: location.pathname.includes(`${baseUrl}/invoicing-items`),
    },
    {
      name: 'Accounts Receivable',
      url: `${baseUrl}/accounts-receivable`,
      isActive: location.pathname.includes(`${baseUrl}/accounts-receivable`),
    },
    {
      name: 'Credit Notes',
      url: `${baseUrl}/credit-notes`,
      isActive: location.pathname.includes(`${baseUrl}/credit-notes`),
    },
    {
      name: 'Accounts Payable',
      url: `${baseUrl}/accounts-payable`,
      isActive: location.pathname.includes(`${baseUrl}/accounts-payable`),
    },
  ];

  return (
    <PageWrapper pages={pages} noXPadding noYPadding constrainWidth={false}>
      <div className="flex grow">
        {navbar && (
          <div className="flex shrink-0 w-56 flex-col inset-y-0 min-h-0 border-r border-gray-200 px-4 py-8 space-y-4 bg-gray-700">
            {TYPES.map(el => (
              <Link
                to={el.url}
                className={`${
                  el.isActive ? 'bg-gray-800 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white'
                } group flex items-center px-2 py-2 text-sm font-medium rounded-md transition duration-200`}
              >
                {el.name}
              </Link>
            ))}
          </div>
        )}
        <div className="grow flex flex-col items-center" id="main-container">
          <div className={`w-full grow h-[700px] ${noPadding ? '' : 'p-8'} flex flex-col`}>{children}</div>
        </div>
      </div>
    </PageWrapper>
  );
}

export default Finance;
