import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ButtonPrimary from '../../components/Buttons/ButtonPrimary';
import ButtonWhite from '../../components/Buttons/ButtonWhite';
import InputSimple from '../../components/Inputs/InputSimple';
import Modal from '../../components/Modals/Modal';
import notifications from '../../helpers/enum/notifications';
import { setNotification } from '../../store/app/actions';
import useApi from '../../hooks/useApi';
import { useForm } from 'react-hook-form';
import { VALIDATION_MSG } from '../../helpers/enum/errorValidationMsgs';
import { useYupValidationResolver } from '../../utils/hooks/useYupValidationResolver';
import * as Yup from 'yup';

function UpdatePasswordModal({ open, setOpen }) {
  const dispatch = useDispatch();
  const {
    users: { updatePassword },
  } = useApi();

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required(VALIDATION_MSG),
    newPassword: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
      .matches(/[0-9]/, 'Password must contain at least one number')
      .matches(/[!@#$%^&*()+_\-=}{[\]|:;'"/?.><,`«»~€£¥₽\\]/, 'Password must contain at least one symbol')
      .required(VALIDATION_MSG),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref('newPassword')], 'Your passwords do not match.')
      .required(VALIDATION_MSG),
  });

  const formOptions = { resolver: useYupValidationResolver(validationSchema) };

  const {
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm(formOptions);

  useEffect(() => {}, [open]);

  const updatePasswordHandler = data => {
    const dataToSend = {
      currentPassword: data.currentPassword,
      newPassword: data.newPassword,
    };
    updatePassword(dataToSend).then(data => {
      dispatch(setNotification(notifications.SUCCESS, 'Success', 'Password successfully updated.'));
      handleCloseModal();
    });
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  return (
    <Modal title={'Update Password'} show={open} hide={() => handleCloseModal()}>
      <div className="bg-white flex flex-col items-center w-full ">
        <div className="space-y-4 w-full">
          <InputSimple
            type="password"
            label="Current password"
            error={errors.currentPassword?.message}
            register={register('currentPassword')}
            dataLabel={'currentPassword'}
          />
          <InputSimple
            type="password"
            label="New password"
            error={errors.newPassword?.message}
            register={register('newPassword')}
            dataLabel={'newPassword'}
          />
          <InputSimple
            type="password"
            label="Confirm new password"
            error={errors.confirmNewPassword?.message}
            register={register('confirmNewPassword')}
            dataLabel={'confirmNewPassword'}
          />
        </div>
        <div className="flex flex-row-reverse w-full mt-6">
          <ButtonPrimary style="ml-2" text="Update" onClick={handleSubmit(updatePasswordHandler)} />
          <ButtonWhite text="Cancel" onClick={handleCloseModal} />
        </div>
      </div>
    </Modal>
  );
}

export default UpdatePasswordModal;
