import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Card from '../../components/Cards/Card';
import ReactSelect from '../../components/Inputs/ReactSelect';
import Toggle from '../../components/Toggles/Toggle';
import {
  LeaveRequestStatus,
  LeaveRequestStatusLabels,
  LeaveRequestType,
  LeaveRequestTypeLabel,
} from '../../helpers/enum/leaveRequest';
import InputDate from '../../components/Inputs/InputDate/InputDate';
import { staffContractType as _staffContractType } from '../../helpers/enum/staffContractType';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { ACCOUNT_TYPE } from '../../helpers/enum/accounts';
import { InformationCircleIcon } from '@heroicons/react/solid';
import { Controller, useForm } from 'react-hook-form';
import Error from '../../components/Error/Error';
import * as Yup from 'yup';
import { useYupValidationResolver } from '../../utils/hooks/useYupValidationResolver';
import { VALIDATION_MSG, DATE_VALIDATION_MSG } from '../../helpers/enum/errorValidationMsgs';
import calculateBusinessDays from '../../helpers/caclulateBusinessDays';
import Checkbox from '../../components/Checkbox/Checkbox';
import useApi from '../../hooks/useApi';

let deductedLeaveDaysOptions = [];

for (let i = 0; i < 20; i = i + 0.5) {
  deductedLeaveDaysOptions.push({
    value: i + 0.5,
    label: i + 0.5,
  });
}

const leaveRequestTypeOptions = [
  { value: LeaveRequestType.paidLeave, label: 'Paid leave' },
  { value: LeaveRequestType.unPaidLeave, label: 'Unpaid leave' },
  { value: LeaveRequestType.medicalLeave, label: 'Medical leave' },
  { value: LeaveRequestType.trainingLeave, label: 'Training leave' },
];

const AdminLRDetails = ({ leaveRequest, setLeaveRequest, contractInactive }) => {
  const {
    holidays: { getHolidaysForLR },
    staffContracts: { getActiveStaffContracts },
    leaveRequests: { updateLeaveRequestByAdmin },
  } = useApi();

  //FIXME: Get actual staff contract Id
  const [staffContractType, setStaffContractType] = useState(null);

  useEffect(() => {
    if (leaveRequest?.userId) {
      getActiveStaffContracts(leaveRequest.userId).then(data => {
        setStaffContractType(data[0]?.contract_type_id);
      });
    }
  }, [leaveRequest]);

  const validationSchema = Yup.object().shape({
    startDate: Yup.date()
      .required(VALIDATION_MSG)
      .nullable()
      .when('requestType', {
        is: requestType => requestType !== LeaveRequestType.trainingLeave,
        then: Yup.date().test('is-weekend', 'Leave request dates cannot be on weekends', function (value) {
          if (value && (value.getDay() === 0 || value.getDay() === 6)) {
            return false;
          }
          return true;
        }),
      }),
    endDate: Yup.date()
      .required(VALIDATION_MSG)
      .nullable()
      .when(['startDate', 'requestType'], {
        is: (startDate, requestType) => startDate && requestType !== LeaveRequestType.trainingLeave,
        then: Yup.date().test('is-weekend', 'Leave request dates cannot be on weekends', function (value) {
          if (value && (value.getDay() === 0 || value.getDay() === 6)) {
            return false;
          }
          return true;
        }),
      })
      .when('startDate', (startDate, schema) => startDate && schema.min(startDate, DATE_VALIDATION_MSG)),
    requestType: Yup.number().required(VALIDATION_MSG).nullable(),
    deductedLeaveDays: Yup.number().required(VALIDATION_MSG),
  });

  const formOptions = { resolver: useYupValidationResolver(validationSchema) };

  const {
    handleSubmit,
    setValue,
    clearErrors,
    control,
    formState: { errors },
  } = useForm(formOptions);

  const currentUser = useSelector(state => state.auth.currentUser);

  const isRootOrAdminAccount =
    currentUser.account_type_id === ACCOUNT_TYPE.ADMIN.id || currentUser.account_type_id === ACCOUNT_TYPE.ROOT.id;

  const [enableEdit, setEnableEdit] = useState(false);
  const [customDeductedLeaveDays, setCustomDeductedLeaveDays] = useState(false);
  const [LRTypeOptions, setLRTypeOptions] = useState([]);

  const [editedData, _setData] = useState({
    status: null,
    startDate: null,
    startHalfDay: false,
    endDate: null,
    endHalfDay: false,
    deductedLeaveDays: null,
    requestType: null,
    description: '',
    holidays: [],
    weekendDates: [],
  });

  useEffect(() => {
    if (!leaveRequest) return;
    setEditedData(leaveRequest);
  }, [leaveRequest]);

  const [tooltipStatus, setTooltipStatus] = useState(0);

  const setEditedData = newValue => {
    Object.entries(newValue).forEach(([name, value]) => {
      //required to capture value '0' of requestType
      if (value || (name === 'requestType' && (value || value == 0))) {
        clearErrors(name);
      }
      setValue(name, value);
    });
    const newData = { ...editedData, ...newValue };
    _setData(newData);
  };

  // const showEndHalfDay = () => {
  //   if (editedData?.startDate && editedData?.endDate)
  //     return new Date(editedData?.startDate).getTime() !== new Date(editedData?.endDate).getTime();
  //   return true;
  // };

  const handleSaveEdit = () => {
    const updatedAdminData = {
      start_date: editedData?.startDate,
      end_date: editedData?.endDate,
      start_half_day: editedData?.startHalfDay,
      end_half_day: editedData?.endHalfDay,
      leave_type: editedData.requestType,
    };

    updateLeaveRequestByAdmin(leaveRequest.id, updatedAdminData).then(() => {
      setLeaveRequest(prev => ({ ...prev, ...editedData }));
      setEnableEdit(false);
    });
  };

  const handleCancelEdit = () => {
    setEnableEdit(false);
    setEditedData(leaveRequest);
  };

  const handleEdit = () => {
    const requestType =
      staffContractType == _staffContractType?.FREELANCER
        ? leaveRequestTypeOptions.filter(
            lrt => lrt.value == LeaveRequestType.unPaidLeave || lrt.value == LeaveRequestType.trainingLeave,
          )
        : leaveRequestTypeOptions;
    setLRTypeOptions(requestType);
    setEnableEdit(true);
  };

  const renderEditButton = () => {
    if (
      isRootOrAdminAccount &&
      !contractInactive &&
      ![LeaveRequestStatus.denied, LeaveRequestStatus.deleted, LeaveRequestStatus.approved].includes(leaveRequest.status)
    )
      return true;
    return false;
  };

  useEffect(() => {
    if (editedData?.startDate && editedData?.endDate && editedData.requestType == LeaveRequestType.paidLeave) {
      calculateBusinessDays(
        getHolidaysForLR,
        editedData?.startDate,
        editedData?.endDate,
        editedData?.startHalfDay,
        editedData?.endHalfDay,
        leaveRequest?.userId,
      ).then(res => {
        return setEditedData({
          deductedLeaveDays: res.deductedDays,
          holidays: res.holidays,
          weekendDates: res.weekends,
        });
      });
    } else {
      if (editedData.deductedLeaveDays)
        return setEditedData({
          deductedLeaveDays: 0,
        });
    }
  }, [editedData?.startDate, editedData?.endDate, editedData?.startHalfDay, editedData?.endHalfDay, editedData.requestType]);

  if (!leaveRequest) return '';

  return (
    <Card
      title="Request details"
      editButton={renderEditButton()}
      onEditClick={handleEdit}
      onSaveEdit={handleSaveEdit}
      handleSubmit={handleSubmit}
      onCancelEdit={handleCancelEdit}
    >
      <dl className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="col-span-1 md:col-span-2">
          <dt className="text-sm font-medium text-gray-500">Leave status</dt>
          <dd className="mt-1 text-sm text-gray-900">{LeaveRequestStatusLabels[editedData.status]}</dd>
        </div>
        <div className="col-span-1 md:col-span-2">
          <div className="flex">
            <dt className="text-sm font-medium text-gray-500 mr-2">Leave type</dt>
            {errors?.['requestType']?.message && <Error message={errors?.['requestType']?.message} />}
          </div>
          {enableEdit ? (
            <dd className="mt-1 ml-1 text-sm text-gray-900 w-full sm:w-3/6">
              <Controller
                control={control}
                name={'requestType'}
                render={() => (
                  <ReactSelect
                    options={LRTypeOptions}
                    selectedOptionsIds={editedData.requestType !== null && [editedData.requestType]}
                    onChange={e => setEditedData({ requestType: e.value })}
                    error={errors['requestType']?.message ? true : ''}
                  />
                )}
              />
            </dd>
          ) : (
            <dd className="mt-1 text-sm text-gray-900">{LeaveRequestTypeLabel[leaveRequest.requestType] || '-'}</dd>
          )}
        </div>
        <div className="col-span-1">
          <div className="flex">
            <dt className="text-sm font-medium text-gray-500 mr-2">Start date</dt>
            {errors?.['startDate']?.message && <Error message={errors?.['startDate']?.message} />}
          </div>
          {enableEdit ? (
            <dd className="mt-1 text-sm text-gray-900">
              <Controller
                control={control}
                name={'startDate'}
                render={() => (
                  <InputDate
                    className="w-full"
                    onChange={e => setEditedData({ startDate: e })}
                    selected={editedData?.startDate !== null && new Date(editedData?.startDate)}
                    disableWeekends={editedData.requestType === LeaveRequestType.trainingLeave ? false : true}
                  />
                )}
              />
            </dd>
          ) : (
            <dd className="mt-1 text-sm text-gray-900">{dayjs(leaveRequest?.startDate).format('DD/MM/YYYY')}</dd>
          )}
        </div>
        <div className="col-span-1">
          <dt className="text-sm font-medium text-gray-500">Half start date</dt>
          <dd className="mt-1 text-sm text-gray-900">
            {enableEdit ? (
              <Toggle
                enable={editedData?.startHalfDay}
                onChange={setEditedData.bind(this, { startHalfDay: !editedData?.startHalfDay })}
              />
            ) : (
              <Toggle enable={leaveRequest?.startHalfDay} />
            )}
          </dd>
        </div>
        <div className="col-span-1">
          <div className="flex">
            <dt className="text-sm font-medium text-gray-500 mr-2">End date</dt>
            {errors?.['endDate']?.message && <Error message={errors?.['endDate']?.message} />}
          </div>
          {enableEdit ? (
            <dd className="mt-1 text-sm text-gray-900">
              <Controller
                control={control}
                name={'endDate'}
                render={() => (
                  <InputDate
                    className="w-full"
                    onChange={e => setEditedData({ endDate: e })}
                    selected={editedData?.endDate !== null && new Date(editedData?.endDate)}
                    disableWeekends={editedData.requestType === LeaveRequestType.trainingLeave ? false : true}
                  />
                )}
              />
            </dd>
          ) : (
            <dd className="mt-1 text-sm text-gray-900">{dayjs(leaveRequest?.endDate).format('DD/MM/YYYY')}</dd>
          )}
        </div>
        <div className="col-span-1">
          <dt className="text-sm font-medium text-gray-500">Half end date</dt>
          <dd className="mt-1 text-sm text-gray-900">
            {enableEdit ? (
              <Toggle
                enable={editedData?.endHalfDay}
                onChange={setEditedData.bind(this, { endHalfDay: !editedData?.endHalfDay })}
              />
            ) : (
              <Toggle enable={leaveRequest?.endHalfDay} />
            )}
          </dd>
        </div>
        {!enableEdit && leaveRequest.requestType == LeaveRequestType.paidLeave && (
          <div className="col-span-1 md:col-span-2">
            <div className="flex items-center">
              <dt className="text-sm font-medium text-gray-500">Deducted leave days</dt>
              <div className="relative" onMouseEnter={() => setTooltipStatus(1)} onMouseLeave={() => setTooltipStatus(0)}>
                {leaveRequest.weekendDates?.length > 0 && (
                  <div className="mr-2">
                    <InformationCircleIcon title={'abc'} fill="orange" className="ml-2 h-5 w-5 filled" />
                  </div>
                )}
                {tooltipStatus == 1 && (
                  <div
                    role="tooltip"
                    style={{ background: '#F3F4F6' }}
                    className="z-50 -mt-20 w-64 absolute transition duration-150 ease-in-out left-0 ml-8 shadow-lg bg-white p-4 rounded"
                  >
                    {leaveRequest.weekendDates?.length > 0 && (
                      <>
                        <p className="text-sm font-bold text-gray-800 pb-1">Weekends</p>
                        <p className="mb-2 text-xs leading-4 text-gray-600 pb-3">{leaveRequest.weekendDates?.join('. ')}</p>
                      </>
                    )}
                    {leaveRequest.holidays?.length > 0 && (
                      <>
                        <p className="text-sm font-bold text-gray-800 pb-1">Public Holidays</p>
                        <p className="text-xs leading-4 text-gray-600 pb-3">{leaveRequest.holidays?.join('. ')}</p>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
            <dd className="mt-1 ml-1 mr-4 text-sm text-gray-900">{leaveRequest.deductedLeaveDays}</dd>
          </div>
        )}
        {enableEdit && editedData.requestType == LeaveRequestType.paidLeave && (
          <>
            <div className="col-span-1">
              <div className="flex items-center">
                <dt className="text-sm font-medium text-gray-500">Deducted leave days</dt>
                <div className="relative" onMouseEnter={() => setTooltipStatus(1)} onMouseLeave={() => setTooltipStatus(0)}>
                  {editedData.weekendDates?.length > 0 && (
                    <div className="mr-2">
                      <InformationCircleIcon title={'abc'} fill="orange" className="ml-2 h-5 w-5 filled" />
                    </div>
                  )}
                  {tooltipStatus == 1 && (
                    <div
                      role="tooltip"
                      style={{ background: '#F3F4F6' }}
                      className="z-50 -mt-20 w-64 absolute transition duration-150 ease-in-out left-0 ml-8 shadow-lg bg-white p-4 rounded"
                    >
                      {editedData.weekendDates?.length > 0 && (
                        <>
                          <p className="text-sm font-bold text-gray-800 pb-1">Weekends</p>
                          <p className="mb-2 text-xs leading-4 text-gray-600 pb-3">{editedData.weekendDates?.join('. ')}</p>
                        </>
                      )}
                      {editedData.holidays?.length > 0 && (
                        <>
                          <p className="text-sm font-bold text-gray-800 pb-1">Public Holidays</p>
                          <p className="text-xs leading-4 text-gray-600 pb-3">{editedData.holidays?.join('. ')}</p>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
              {!customDeductedLeaveDays ? (
                <div className="flex items-center">
                  <dd className="mt-1 ml-1 mr-4 text-sm text-gray-900">{editedData.deductedLeaveDays}</dd>
                </div>
              ) : (
                <dd className="mt-1 ml-1 text-sm text-gray-900 w-3/6">
                  <Controller
                    control={control}
                    name={'deductedLeaveDays'}
                    render={() => (
                      <ReactSelect
                        options={deductedLeaveDaysOptions}
                        selectedOptionsIds={editedData.deductedLeaveDays && [+editedData.deductedLeaveDays]}
                        onChange={e => setEditedData({ deductedLeaveDays: e.value })}
                        error={errors['deductedLeaveDays']?.message ? true : ''}
                      />
                    )}
                  />
                </dd>
              )}
            </div>
            <div className="col-span-1">
              <Checkbox
                value={customDeductedLeaveDays}
                title="Custom days"
                onChange={() => setCustomDeductedLeaveDays(!customDeductedLeaveDays)}
              />
            </div>
          </>
        )}
      </dl>
    </Card>
  );
};

AdminLRDetails.prototype = {
  action: PropTypes.oneOf(['create', 'update']),
  staffContractType: PropTypes.number,
};

export default AdminLRDetails;
