import React, { useRef, useState, useMemo, useEffect } from 'react';
import formatCurrency from '../../../utils/formatCurrency';
import format from 'date-fns/format';
import SimpleEntry from '../../../components/DescriptionEntries/SimpleEntry';
import ButtonPrimary from '../../../components/Buttons/ButtonPrimary';
import SimpleAlert from '../../../components/Modals/SimpleAlert';
import useApi from '../../../hooks/useApi';
import Checkbox from '../../../components/Checkbox/Checkbox';
import { useParams } from 'react-router-dom';
import InvoiceBalance from '../../../components/Finance/InvoiceBalance';
import { ClipLoader } from 'react-spinners';
import replaceCommentVariables from '../../../helpers/replaceCommentVariables';
import CreditNoteTemplate from '../../../helpers/creditNoteTemplate';
import pdfMake from 'pdfmake/build/pdfmake';
import { ExternalLinkIcon } from '@heroicons/react/solid';
import InvoiceDetailsTimesheetList from '../InvoiceDetailsTimesheetList';

function CreditNotePDFDetails({
  creditNoteData,
  setCreditNoteData,
  lineItems,
  setEditCreditNote,
  deleteHandler,
  accountsReceivableData,
  isLoading,
}) {
  const iframeRef = useRef(null); // Reference to the iframe element
  const { id } = useParams();
  const [showAlert, setShowAlert] = useState(false);
  const {
    creditNotes: { updateCreditNoteSentStatus },
    files: { getCreditNotePDFByID },
  } = useApi();

  const downloadAndDisplayPDF = () => {
    if (!id) return;

    getCreditNotePDFByID(id)
      .then(res => {
        const base64Data = res.data;
        const contentType = 'application/pdf';
        const url = `data:${contentType};base64:${base64Data}`;
        const iframe = iframeRef.current;
        if (iframe) iframe.src = url + '#toolbar=0';
      })
      .catch(err => {
        //in case credit note is not found in S3, re-create Pdf based on existing data
        const formattedData = {
          ...creditNoteData,
          metadata: {
            ...creditNoteData.metadata,
            header_comment: replaceCommentVariables(creditNoteData.metadata.header_comment, creditNoteData),
            footer_comment: replaceCommentVariables(creditNoteData.metadata.footer_comment, creditNoteData),
          },
          issue_date: format(creditNoteData.issue_date, 'MMM do, yyyy'),
          due_date: creditNoteData.due_date ? format(creditNoteData.due_date, 'MMM do, yyyy') : '',
        };
        const documentDefinition = CreditNoteTemplate({ creditNoteData: formattedData, lineItems });
        const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
        pdfDocGenerator.getDataUrl(dataUrl => {
          const iframe = iframeRef.current;
          if (iframe) iframe.src = dataUrl + '#toolbar=0';
        });
      });
  };

  useEffect(() => {
    downloadAndDisplayPDF();
  }, [creditNoteData.id, lineItems]); // Regenerate PDF when inputs change

  const canEditAndDelete = useMemo(() => {
    let _canEditAndDelete = true;
    if (creditNoteData.sent) _canEditAndDelete = false;
    return _canEditAndDelete;
  }, [creditNoteData]);

  const updateSentHandler = () => {
    updateCreditNoteSentStatus(id, { sent: !creditNoteData.sent }).then(res => {
      setCreditNoteData(prev => ({
        ...prev,
        sent: !creditNoteData.sent,
      }));
    });
  };

  const invoicedTimesheets = useMemo(() => {
    let _invoicedTimesheets = [];
    lineItems.forEach(li => {
      if (li.timesheet && !_invoicedTimesheets.find(it => it.id === li.timesheet.id)) _invoicedTimesheets.push(li.timesheet);
    });
    return _invoicedTimesheets;
  }, [lineItems]);

  return (
    <div className="flex h-full ">
      <SimpleAlert
        errorTitle={'Delete Credit Note?'}
        errorMsg={'Deleting will completely remove this Credit Note from the application. Are you sure you want to proceed?'}
        onAcceptText={isLoading === 'delete' ? <ClipLoader className="mr-2 w-20" color={'#FFFF'} size={17} /> : 'Confirm'}
        onAcceptClick={deleteHandler}
        onDeclineText="Cancel"
        show={showAlert}
        hide={() => setShowAlert(false)}
      />
      {/* Left-side: creditNote preview */}
      <iframe ref={iframeRef} title="PDF Preview" className="w-full h-full" />
      {/* Right-side: action bar */}
      <div className="w-[22rem] bg-gray-200 flex flex-col justify-between px-2 pb-2 border-l border-gray-500">
        <div className="flex flex-col">
          <span className="font-semibold text-lg my-4">Credit Note details</span>
          <div className="grid grid-cols-2 gap-x-2 gap-y-4">
            <SimpleEntry label="Client" data={creditNoteData?.metadata?.to?.name} className="col-span-1" />
            <SimpleEntry label="Issue date" data={format(creditNoteData?.issue_date, 'dd/MM/yyyy')} className="col-span-1" />
            <SimpleEntry label={'Sent'} data={<Checkbox value={creditNoteData.sent} onChange={updateSentHandler} />} />
            <SimpleEntry label="Total" data={formatCurrency(creditNoteData?.amount_and_vat)} />
          </div>
          {invoicedTimesheets.length ? <InvoiceDetailsTimesheetList timesheets={invoicedTimesheets} /> : ''}
          <InvoiceBalance accountsReceivableId={accountsReceivableData?.invoice_number || null} creditNote={true} />
        </div>
        <div className="flex justify-center space-x-2">
          <ButtonPrimary text="Delete" disable={!canEditAndDelete} color="red" onClick={() => setShowAlert(true)} />
          <ButtonPrimary text="Edit" disable={!canEditAndDelete} onClick={() => setEditCreditNote(true)} />
        </div>
      </div>
    </div>
  );
}

export default CreditNotePDFDetails;
