import React from 'react';
import ReactSelect from 'react-select';

function SelectColumnFilter({ column: { filterValue, setFilter, id, filterOptions } }) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const selectedOptions = React.useMemo(() => {
    if (!filterValue?.length) return null;
    return filterValue.map(value => filterOptions.find(option => option.value == value));
  }, [filterValue]);

  const options = React.useMemo(() => {
    let _options = [];
    if (!filterOptions) return;
    _options = filterOptions;
    return _options;
  }, [id, filterOptions]);

  // Render a multi-select box
  return (
    <ReactSelect
      className="w-full rounded-md py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      theme={theme => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: 'black ',
        },
      })}
      isClearable
      isMulti
      value={selectedOptions}
      onChange={e => {
        e ? setFilter(e.map(e => e.value)) : setFilter(undefined);
      }}
      options={options}
    />
  );
}

export default SelectColumnFilter;
