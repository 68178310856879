import React from 'react';
import { Link } from 'react-router-dom';
import { FolderIcon } from '@heroicons/react/solid';
import { HomeIcon } from '@heroicons/react/solid';

export default function MiniBreadcrumbs({ pages, constrainWidth = false, homeLink, folderIcon }) {
  const iconClassName = `h-5 w-5 text-gray-400 group-hover:text-gray-500`;
  return (
    <nav className={`flex  justify-start md:justify-center ${constrainWidth && 'max-w-7xl'}`} aria-label="Breadcrumb">
      <ol role="list" className="flex items-center w-full mx-auto flex-wrap">
        {homeLink && (
          <>
            <li>
              <div>
                {homeLink?.href ? (
                  <Link
                    to={homeLink.href}
                    className=" text-sm w-max font-medium text-gray-500 hover:text-gray-700 hover:underline underline-offset-2 flex group"
                  >
                    {folderIcon ? <FolderIcon className={iconClassName} /> : <HomeIcon className={iconClassName} />}
                    <span className="ml-1 ">{homeLink.text}</span>
                  </Link>
                ) : homeLink.onClick ? (
                  <div
                    className="cursor-pointer text-sm w-max font-medium text-gray-500 hover:text-gray-700 hover:underline underline-offset-2"
                    onClick={homeLink.onClick}
                  >
                    {folderIcon ? <FolderIcon className={iconClassName} /> : <HomeIcon className={iconClassName} />}
                  </div>
                ) : (
                  <div
                    className="cursor-pointer text-sm w-max font-medium text-gray-500 hover:text-gray-700 hover:underline underline-offset-2"
                    onClick={homeLink.onClick}
                  >
                    {folderIcon ? <FolderIcon className={iconClassName} /> : <HomeIcon className={iconClassName} />}
                  </div>
                )}
              </div>
            </li>
            <svg
              className="h-6 w-6 flex-shrink-0 text-gray-300"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
              aria-hidden="true"
            >
              <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
            </svg>
          </>
        )}
        {pages.map((page, i) => {
          if (!page?.name) return '';
          return (
            <li key={page.name} className="flex mx-0.5">
              <div className="flex items-center space-x-0.5">
                {i !== 0 && (
                  <svg
                    className="h-6 w-6 flex-shrink-0 text-gray-300"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                  >
                    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                  </svg>
                )}
                {page?.href ? (
                  <Link
                    to={page.href}
                    className=" text-sm w-max font-medium text-gray-500 hover:text-gray-700 hover:underline underline-offset-2"
                    aria-current={page.current ? 'page' : undefined}
                  >
                    {page.name}
                  </Link>
                ) : page.onClick ? (
                  <div
                    className="cursor-pointer text-sm w-max font-medium text-gray-500 hover:text-gray-700 hover:underline underline-offset-2"
                    aria-current={page.current ? 'page' : undefined}
                    onClick={page.onClick}
                  >
                    {page.name}
                  </div>
                ) : (
                  <div
                    className=" text-sm w-max font-medium text-gray-500 hover:text-gray-700 hover:underline underline-offset-2"
                    aria-current={page.current ? 'page' : undefined}
                  >
                    {page.name}
                  </div>
                )}
              </div>
            </li>
          );
        })}
      </ol>
    </nav>
  );
}
