export const staffContractType = {
  EMPLOYEE: 1,
  FREELANCER: 2,
  COMPANY: 3,
};

export const staffContractTypeLabels = {
  1: 'Employee',
  2: 'Freelancer',
  3: 'Company',
};
