import { LockClosedIcon } from '@heroicons/react/outline';
import React, { useState } from 'react';
import Error from '../Error/Error';
import InfoTooltip from '../Tooltips/InfoTooltip';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
function InputSimple({
  dataLabel,
  label,
  errorMessage,
  disabled,
  value,
  className,
  placeholder,
  onChange,
  type,
  override,
  children,
  register,
  whiteLabel,
  error,
  onKeyPress,
  tooltip,
  tooltipMsg,
  reference,
  classNameOverride,
  hideLockIcon,
  ...rest
}) {
  const handleChange = e => {
    e.persist();
    onChange(e, dataLabel);
  };

  let valueToDisplay = value === null ? '' : value;

  const [showPassword, setShowPassword] = useState(false);

  const typeOfInput = type ? (type == 'password' ? (showPassword ? 'text' : 'password') : type) : 'text';

  return (
    <div className="w-full">
      <div className="flex items-center">
        {label && (
          <label className={`block text-sm font-medium ${whiteLabel ? 'text-white' : 'text-gray-700'} mr-2`}>{label}</label>
        )}
        {error && <Error message={error} />}
        {tooltip && <InfoTooltip children={tooltipMsg} iconClassName="h-4 w-4 text-thaleria-orange-700" />}
      </div>

      {!override && (
        <div className={`${label === undefined ? '' : 'mt-1'} relative z-0`}>
          {register ? (
            <input
              ref={reference}
              onKeyPress={onKeyPress}
              disabled={disabled}
              type={typeOfInput}
              label={label}
              value={valueToDisplay}
              onChange={handleChange}
              className={
                classNameOverride
                  ? classNameOverride
                  : `${className} ${
                      disabled && 'bg-gray-200 cursor-not-allowed'
                    } shadow-sm focus:ring-thaleria-orange-600 focus:border-thaleria-orange-600 block w-full sm:text-sm border p-2 h-[38px] border-gray-300 rounded-md`
              }
              placeholder={placeholder}
              onFocus={e =>
                e.target.addEventListener(
                  'wheel',
                  function (e) {
                    e.preventDefault();
                  },
                  { passive: false },
                )
              }
              {...register}
              {...rest}
            />
          ) : (
            <input
              ref={reference}
              onKeyPress={onKeyPress}
              disabled={disabled}
              type={typeOfInput}
              label={label}
              value={valueToDisplay}
              onChange={handleChange}
              onFocus={e =>
                e.target.addEventListener(
                  'wheel',
                  function (e) {
                    e.preventDefault();
                  },
                  { passive: false },
                )
              }
              className={
                classNameOverride
                  ? classNameOverride
                  : `${className} ${
                      disabled && 'bg-gray-200 cursor-not-allowed'
                    } shadow-sm focus:ring-thaleria-orange-600 focus:border-thaleria-orange-600 block w-full sm:text-sm border p-2 h-[38px] border-gray-300 rounded-md`
              }
              placeholder={placeholder}
              {...rest}
            />
          )}
          {type == 'password' &&
            (showPassword ? (
              <EyeOffIcon
                className="h-6 w-6 text-gray-500 absolute top-2 right-2 cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
              />
            ) : (
              <EyeIcon
                className="h-6 w-6 text-gray-500 absolute top-2 right-2 cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
              />
            ))}
          {errorMessage && <span className="text-xs text-red-500 ml-4">{errorMessage}</span>}
          {disabled && !hideLockIcon && (
            <LockClosedIcon className="h-5 w-5 my-auto mr-2 text-gray-500 absolute inset-y-0 right-0" />
          )}
        </div>
      )}
      {override && (
        <div className="mt-1">
          <div className="">{children}</div>
        </div>
      )}
    </div>
  );
}

export default InputSimple;
