import { MinusCircleIcon } from '@heroicons/react/outline';
import { PlusIcon } from '@heroicons/react/solid';
import React, { useState, useEffect, useRef } from 'react';
import ChatMessage from '../../../../components/Chat/ChatMessage';
import ChatSendMessage from '../../../../components/Chat/ChatSendMessage';
import HrManager from '../HrManager';
import useApi from '../../../../hooks/useApi';
import dayjs from 'dayjs';
import ProfilePicture from '../../../../components/ProfilePicture/ProfilePicture';
import UserImagePlaceholder from '../../../../components/UserImagePlaceholder/UserImagePlaceholder';
import { useSelector } from 'react-redux';
import AddTagModal from '../../../../containers/Tasks/AddTagModal';
import { useQuery } from '../../../../hooks/useQuery';
import TaskLog from '../../../../containers/Tasks/TaskLog';
import Toggle from '../../../../components/Toggles/Toggle';
import Loader from '../../../../components/Loading/Loader';
import axios from 'axios';
import AdminSidebar from '../../AdminSidebar';

function TaskManager() {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingNextPage, setIsLoadingNextPage] = useState(false);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);

  const [taskDetails, setTaskDetails] = useState(null);

  const { tasks: tasksApi, users: usersApi } = useApi();

  const [logs, setLogs] = useState();

  const [scrollSetting, setScrollSetting] = useState({ hasMore: true, page: 1 });

  const query = useQuery();

  const [comment, setComment] = useState('');

  const [showAddTagModal, setShowAddTagModal] = useState(false);

  const loggedUser = useSelector(state => state.auth.currentUser);

  const [userOptions, setUserOptions] = useState([]);

  const fetchUserOptions = async () => {
    await usersApi.getStaffAndAdminUsers().then(data => {
      setUserOptions(
        data.map(e => {
          return {
            label: e.full_name,
            value: e.id,
            profilepic_url: e.profilepic_url,
          };
        }),
      );
    });
  };

  useEffect(() => {
    fetchUserOptions();
  }, []);

  const fetchTaskLog = async (currentPage, closed, module, usertags, source) => {
    return await tasksApi.getTaskLog(currentPage, closed, module, usertags, source);
  };

  const refreshLogAndDetails = async () => {
    setIsLoading(true);
    setIsLoadingDetails(true);
    await fetchTaskLog(1, query.get('closed'), query.get('module'), query.get('tags')).then(async data => {
      setLogs(prev => ({
        ...prev,
        data: data.rows,
        totalPages: data.totalPages,
      }));
      setScrollSetting(prev => ({ ...prev, page: 1 }));
      if (data.totalPages <= 1) {
        setScrollSetting(prev => ({ ...prev, hasMore: false }));
      } else {
        setScrollSetting(prev => ({ ...prev, hasMore: true }));
      }
      if (data.rows.find(task => task.id === taskDetails?.id)) {
        const details = await tasksApi.getTaskDetails(taskDetails.id);
        setTaskDetails(details);
      } else {
        setTaskDetails(null);
      }
    });
    setIsLoadingDetails(false);
    setIsLoading(false);
  };

  useEffect(() => {
    let source = axios.CancelToken.source();
    setIsLoading(true);
    fetchTaskLog(1, query.get('closed'), query.get('module'), query.get('tags'), source)
      .then(data => {
        setLogs(prev => ({
          ...prev,
          data: data.rows,
          totalPages: data.totalPages,
        }));
        setScrollSetting(prev => ({ ...prev, page: 1 }));
        if (data.totalPages <= 1) {
          setScrollSetting(prev => ({ ...prev, hasMore: false }));
        } else {
          setScrollSetting(prev => ({ ...prev, hasMore: true }));
        }

        if (!data.rows.find(task => task.id === taskDetails?.id)) {
          setTaskDetails(null);
        }
        setIsLoading(false);
      })
      .catch(e => setIsLoading(false));
    return () => {
      source.cancel();
    };
  }, [query.get('closed'), query.get('module'), query.get('tags')]);

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const fetchNextPage = () => {
    const page = scrollSetting.page;
    setIsLoadingNextPage(true);
    fetchTaskLog(page + 1, query.get('closed'), query.get('module'), query.get('tags')).then(data => {
      setLogs(prev => ({
        ...prev,
        data: prev.data.concat(data.rows),
        totalPages: data.totalPages,
      }));
      // if (data.totalPages <= 1 || logs.totalPages <= page) setScrollSetting(prev => ({ ...prev, hasMore: false }));
      setScrollSetting(() => ({
        page: page + 1,
        hasMore: data.totalPages <= 1 || logs.totalPages <= page + 1 ? false : true,
      }));
    });
    setIsLoadingNextPage(false);
  };

  const handleTaskClick = async taskId => {
    setIsLoadingDetails(true);
    if (taskId !== taskDetails?.id) {
      const details = await tasksApi.getTaskDetails(taskId);
      setTaskDetails(details);
    } else {
      setTaskDetails(null);
    }
    setIsLoadingDetails(false);
  };

  const handleAddComment = async () => {
    await tasksApi.addComment(comment, taskDetails.id).then(data => {
      setTaskDetails(prev => ({
        ...prev,
        comments: [
          ...prev.comments,
          { ...data, user: { full_name: loggedUser.full_name, profilepic_url: loggedUser.profilepic_url } },
        ],
      }));
      setComment('');
      scrollToBottom();
    });
  };

  const handleAddTag = () => {
    setShowAddTagModal(true);
  };

  const handleRemoveTag = async tagId => {
    await tasksApi.removeTag(tagId).then(() => {
      const updatedDetails = { ...taskDetails, tags: taskDetails.tags.filter(t => t.id !== tagId) };
      const updatedTasks = logs.data.map(task => {
        if (task.id === taskDetails.id) {
          task.tags = task.tags.filter(t => t.id !== tagId);
        }
        return task;
      });
      setTaskDetails(updatedDetails);
      setLogs(prev => ({ ...prev, data: updatedTasks }));
    });
  };

  const handleToggleCloseTask = async task => {
    await tasksApi.toggleClosed(task.id).then(() => {
      let newStatus;
      if (task.closed === null || task.closed === true) newStatus = false;
      if (task.closed === false) newStatus = true;
      setLogs(prev => {
        return {
          ...prev,
          data: prev.data.map(e => {
            if (e.id === task.id) {
              return { ...e, closed: newStatus };
            } else return e;
          }),
        };
      });
      setTaskDetails(prev => ({ ...prev, closed: newStatus }));
    });
  };

  const pages = [
    { name: 'HR Manager', href: '/admin-panel/hr/user-directory', current: false },
    { name: 'Task Manager', href: '/admin-panel/hr/task-manager', current: true },
  ];

  return (
    <AdminSidebar noPadding pages={pages}>
      <AddTagModal
        show={showAddTagModal}
        hide={() => setShowAddTagModal(false)}
        tags={taskDetails?.tags}
        taskId={taskDetails?.id}
        setTaskDetails={setTaskDetails}
        setLogs={setLogs}
        userOptions={userOptions}
      />

      <div className="flex h-full ">
        <TaskLog
          isLoadingNextPage={isLoadingNextPage}
          isLoading={isLoading}
          refreshLogAndDetails={refreshLogAndDetails}
          logs={logs}
          fetchNextPage={fetchNextPage}
          scrollSetting={scrollSetting}
          userOptions={userOptions}
          onTaskClick={handleTaskClick}
          activeTaskId={taskDetails?.id}
        />
        <div className="w-[22rem] bg-gray-100 flex flex-col px-4 pb-4 border-l border-gray-200  ">
          <Loader isLoading={isLoadingDetails}>
            {taskDetails ? (
              <div className="grow overflow-y-auto scrollbar-hidden ">
                <div className="flex justify-between my-4">
                  <p className="font-medium text-xl text-gray-900">Closed</p>
                  <Toggle
                    onChange={() => handleToggleCloseTask(taskDetails)}
                    enable={taskDetails.closed || taskDetails.closed === null}
                  />
                </div>
                <div>
                  <div className="flex justify-between items-center mb-2">
                    <p className="font-medium text-xl text-gray-900">Tags</p>
                  </div>
                  <ul role="list" className="divide-y divide-gray-200 ">
                    {taskDetails?.tags?.length ? (
                      taskDetails.tags.map(tag => (
                        <li key={tag.id} className="flex items-center justify-between py-2">
                          <div className="flex items-center">
                            <div className="flex-shrink-0">
                              <ProfilePicture
                                src={tag.user.profilepic_url}
                                className="w-8 h-8 rounded-full"
                                alt=""
                                defaultimage={<UserImagePlaceholder className="w-8 h-8 rounded-full" />}
                              />
                            </div>
                            <p className="ml-4 text-sm font-medium text-gray-900">
                              {tag.user.full_name ? tag.user.full_name : tag.user.first_names + ' ' + tag.user.last_names}
                            </p>
                          </div>
                          <MinusCircleIcon
                            className="h-5 w-5 text-red-500 cursor-pointer"
                            onClick={() => handleRemoveTag(tag.id)}
                          />
                        </li>
                      ))
                    ) : (
                      <li className="text-sm text-gray-500 py-3">No tags placed on this task</li>
                    )}
                    <li className="flex items-center justify-between py-2">
                      <button
                        type="button"
                        className="group -ml-1 flex items-center rounded-md p-1 focus:outline-none focus:ring-2 focus:ring-thaleria-orange-700"
                        onClick={handleAddTag}
                      >
                        <span className="flex h-8 w-8 items-center justify-center bg-white rounded-full border-2 border-dashed border-thaleria-orange-700 text-gray-400">
                          <PlusIcon className="h-5 w-5 text-thaleria-orange-700" aria-hidden="true" />
                        </span>
                        <span className="ml-4 text-sm font-medium text-thaleria-orange-700 group-hover:text-thaleria-orange-700">
                          Add tag
                        </span>
                      </button>
                    </li>
                  </ul>
                </div>

                <div className="mt-4">
                  <p className="font-medium text-xl text-gray-900 mb-4">Past activity</p>
                  <ul role="list" className="divide-y divide-gray-200 max-h-[14rem] overflow-y-auto">
                    {taskDetails?.events?.map((event, index) => (
                      <li key={event.id} className={`${index && 'pt-2'} pb-2`}>
                        <div className="relative">
                          <p className="text-sm font-semibold text-gray-400">
                            {dayjs(event.created_at).format("MMM D, 'YY HH:mm")}
                          </p>
                          <p className="mt-1 text-sm text-gray-600 line-clamp-2">{event.message}</p>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-4">
                  <p className="font-medium text-xl text-gray-900 mb-6">Comments</p>
                  <ul role="list" id="chat-container" className="space-y-6 max-h-[14rem] overflow-y-auto">
                    {taskDetails.comments.map(c => (
                      <ChatMessage
                        key={c.id}
                        text={c.comment}
                        time={dayjs(c.created_at).format("MMM D, 'YY HH:mm")}
                        ownerImg={c.user.profilepic_url}
                        ownerName={c.user.full_name ? c.user.full_name : c.user.first_names + ' ' + c.user.last_names}
                      />
                    ))}
                  </ul>
                  <div ref={messagesEndRef} />
                  <div className="pt-4 mt-4 border-t border-gray-200">
                    <ChatSendMessage
                      sendMessage={handleAddComment}
                      onBodyChange={e => setComment(e.target.value)}
                      body={comment}
                      textAreaErrorMessage={null}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="grow mt-4">No task selected</div>
            )}
          </Loader>
        </div>
      </div>
    </AdminSidebar>
  );
}

export default TaskManager;
