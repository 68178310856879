import React, { useState, useEffect } from 'react';
import NotificationsDropdown from '../../components/UserNotifications/NotificationsDropdown';
import useApi from '../../hooks/useApi';

const UserNotifications = () => {
  const {
    notifications: { getLatestNotifications },
  } = useApi();
  const [show, setShow] = useState(false);
  const [notifications, setNotifications] = useState();

  useEffect(() => {
    getLatestNotifications()
      .then(res => {
        setNotifications(res);
      })
      .catch(e => {
        console.log(e, 'error');
      });
  }, [show]);

  return (
    <>
      <NotificationsDropdown items={notifications} id="userNotifications" />
    </>
  );
};

export default UserNotifications;
