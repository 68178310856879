import React, { useState, forwardRef } from 'react';
import ButtonPrimary from '../Buttons/ButtonPrimary';
import ButtonSecondary from '../Buttons/ButtonSecondary';
import ButtonWhite from '../Buttons/ButtonWhite';

function Card(
  {
    title,
    optionalButton,
    editButton,
    children,
    onClick,
    optionalButtonText,
    auto,
    noYPadding,
    noXPadding,
    style,
    optionalButtonIcon,
    onSaveEdit,
    onCancelEdit,
    onEditClick,
    className,
    titleClassName,
    handleSubmit,
    contentClassName,
    noShadow,
    optionalButtonColor,
  },
  ref,
) {
  const overFlowAuto = auto ? 'overflow-auto' : '';
  const noVerticalPadding = noYPadding ? 'py-0' : 'py-6';
  const noHorizontalPadding = noXPadding ? 'px-0' : 'px-4 sm:px-6';

  const [edit, setEdit] = useState(false);

  const handleEdit = () => {
    onEditClick();
    setEdit(true);
  };

  const handleCancelEdit = () => {
    onCancelEdit();
    setEdit(false);
  };

  const handleSaveEdit = () => {
    onSaveEdit();
    setEdit(false);
  };

  return (
    <div
      ref={ref}
      className={`w-full flex flex-col bg-white ${!noShadow && 'shadow'} rounded-md sm:rounded-lg ${className}`}
      style={style}
    >
      {title && (
        <div className={`px-4 py-5 sm:px-6 h-20 flex items-center justify-between border-b border-gray-200 ${titleClassName}`}>
          <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
          <div className="space-x-2">
            {optionalButton && (
              <ButtonPrimary icon={optionalButtonIcon} onClick={onClick} text={optionalButtonText} color={optionalButtonColor} />
            )}
            {editButton && <ButtonPrimary onClick={handleEdit} text={'Edit'} disable={edit} />}
          </div>
        </div>
      )}
      <div className={`${overFlowAuto} ${noVerticalPadding} ${noHorizontalPadding} h-full w-full ${contentClassName}`}>
        {children}
      </div>
      {edit && (
        <div className="bg-gray-50 mt-auto px-4 py-5 sm:px-6 flex flex-row-reverse sm:rounded-b-lg">
          <ButtonPrimary text="Save" style="ml-2" onClick={handleSubmit ? handleSubmit(handleSaveEdit) : handleSaveEdit} />
          <ButtonWhite text="Cancel" onClick={handleCancelEdit} />
        </div>
      )}
    </div>
  );
}

export default forwardRef(Card);
