import React from 'react';
import TwoColumnForm from '../../../components/Layouts/TwoColumnForm';
import SimpleEntry from '../../../components/DescriptionEntries/SimpleEntry';
import { date } from '../../../helpers/date';
import { fwcTypeLabels } from '../../../helpers/enum/fwcTypes';

function FrameworkContractDetails({ frameworkContract, handleEditClick }) {
  return (
    <TwoColumnForm label={'Framework contract'} description="" button={true} buttonText={'Edit'} onClick={handleEditClick}>
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        <SimpleEntry label={'Reference'} data={frameworkContract.contract_ref || '-'} style={`sm:col-span-1`} />
        <SimpleEntry label={'Description'} data={frameworkContract.description || '-'} style={`sm:col-span-1`} />
        <SimpleEntry label={'Start date'} data={date(frameworkContract.contract_start || '-')} style={`sm:col-span-1`} />
        <SimpleEntry
          label={'End date'}
          data={(frameworkContract.contract_end && date(frameworkContract.contract_end)) || 'Open Ended'}
          style={`sm:col-span-1`}
        />
        <SimpleEntry
          label={'Clients'}
          data={frameworkContract.clients.map(obj => obj.name).join(', ') || '-'}
          style={`sm:col-span-1`}
        />
        {/* <SimpleEntry label={'Intermediary'} data={frameworkContract.has_intermediary ? 'Yes' : 'No'} style={`sm:col-span-1`} /> */}
        <SimpleEntry label={'Type'} data={fwcTypeLabels[frameworkContract.type] || '-'} style={`sm:col-span-1`} />
        <SimpleEntry label={'Bank account'} data={frameworkContract.bankAccount?.name || '-'} style={`sm:col-span-1`} />
        <SimpleEntry label={'IBAN'} data={frameworkContract.bankAccount?.iban || '-'} style={`sm:col-span-1`} />
        <SimpleEntry label={'Created'} data={date(frameworkContract.created_at) || '-'} style={`sm:col-span-1`} />
        <SimpleEntry label={'Last updated'} data={date(frameworkContract.updated_at) || '-'} style={`sm:col-span-1`} />
      </dl>
    </TwoColumnForm>
  );
}

export default FrameworkContractDetails;
