import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authenticateUser, forgotPassword } from '../../store/auth/actions';
import { useHistory } from 'react-router-dom';
import InputSimple from '../../components/Inputs/InputSimple';
import { useForm } from 'react-hook-form';
import { useYupValidationResolver } from '../../utils/hooks/useYupValidationResolver';
import * as Yup from 'yup';
import { VALIDATION_MSG } from '../../helpers/enum/errorValidationMsgs';
import MyThaleriaLogo from '../../components/Icons/MyThaleriaLogo';
import ButtonPrimary from '../../components/Buttons/ButtonPrimary';
import img from '../../resources/eu-commission.jpeg';
import { RECAPTCHA_SITE_KEY } from '../../configs';

function SignInSplitScreen(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [emailSentNotice, setEmailSentNotice] = useState();
  const [captchaScriptAppended, setCaptchaScriptAppended] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string().required(VALIDATION_MSG).email().trim(),
    password: Yup.string().required(VALIDATION_MSG),
  });

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string().required(VALIDATION_MSG).email().trim(),
  });

  useEffect(() => {
    //Add recaptcha api script
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`;
    script.async = true;
    script.id = 'captchaScript';

    document.body.appendChild(script);

    const checkForBadge = () => {
      const badge = document.querySelector('.grecaptcha-badge');
      if (badge) {
        badge.style.zIndex = '100'; // Apply styling if badge exists
      } else {
        // Retry after a short delay if badge doesn't exist yet
        setTimeout(checkForBadge, 500);
      }
    };

    checkForBadge();

    setCaptchaScriptAppended(true);
  }, []);

  const formOptions = { resolver: useYupValidationResolver(validationSchema) };

  const {
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm(formOptions);

  const loggedIn = useSelector(state => state.auth.isAuthenticated);

  useEffect(() => {
    if (loggedIn) {
      history.push('/home');
    }
  }, [loggedIn]);

  const handleKeyPress = e => {
    if (e.key === 'Enter' && e.shiftKey === false) {
      return handleSubmit(onSubmit)();
    }
  };

  const onSubmit = async data => {
    data.captchaToken = await window.grecaptcha.execute(RECAPTCHA_SITE_KEY.toString(), { action: 'submit' });
    data.script = document.getElementById('captchaScript');
    dispatch(authenticateUser(data));
  };

  const handleForgotPassword = async () => {
    clearErrors();
    await forgotPasswordSchema
      .validate(
        {
          email: getValues('email'),
        },
        { strict: true, abortEarly: false },
      )
      .then(res => {
        forgotPassword(getValues('email'));
        setEmailSentNotice('If the address is valid, an e-mail will be sent with instructions to reset your password.');
      })
      .catch(err => {
        err.inner.forEach(_err => {
          setError('email', { type: 'custom', message: _err.errors[0] });
        });
      });
  };

  return (
    <div className="min-h-screen bg-thaleria-blue-700 flex">
      <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 relative">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <div>
              <MyThaleriaLogo white size="xl" />
            </div>
            <p className="mt-6 text-3xl font-extrabold text-white mb-6">Sign in to your account</p>
          </div>
          <div className="grid grid-cols-1 gap-y-6 w-full mx-auto">
            <InputSimple
              onKeyPress={handleKeyPress}
              error={errors.email?.message}
              register={register('email')}
              label={'Email address'}
              dataLabel={'email'}
              whiteLabel
            />
            <div className="relative">
              <InputSimple
                onKeyPress={handleKeyPress}
                type={'password'}
                error={errors['password']?.message}
                register={register('password')}
                label={'Password'}
                dataLabel={'password'}
                whiteLabel
              />
            </div>

            <div className="flex flex-col gap-y-2">
              <ButtonPrimary text="Sign in" onClick={handleSubmit(onSubmit)} />
              {emailSentNotice ? (
                <span className="text-thaleria-orange-700 text-sm">{emailSentNotice}</span>
              ) : (
                <span
                  className="text-thaleria-orange-700 text-sm cursor-pointer hover:underline"
                  onClick={() => handleForgotPassword()}
                >
                  Forgot password?
                </span>
              )}
            </div>
          </div>
        </div>
        <p className="text-sm text-white max-w-sm mt-80 absolute bottom-4">
          * MyThaleria is an internal platform. Its use is reserved for Thaleria's staff members.
        </p>
      </div>
      <div className="hidden lg:block relative w-0 flex-1">
        <img className="absolute inset-0 h-full w-full object-cover" src={img} alt="" />
      </div>
    </div>
  );
}

export default SignInSplitScreen;
