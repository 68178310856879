import React from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import ButtonPrimary from '../../components/Buttons/ButtonPrimary';
import ButtonWhite from '../../components/Buttons/ButtonWhite';

function TwoColumnForm({
  style,
  label,
  red,
  errors,
  description,
  button,
  button2,
  button3,
  children,
  buttonRed,
  onClick,
  buttonText,
  onClick2,
  onClick3,
  onClickRed,
  buttonText2,
  buttonText3,
  buttonRedText,
  onlyLayout,
  disableButton2 = false,
}) {
  return (
    <form className={`${style}`}>
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900 flex flex-row">
              {label}
              {red && <ExclamationCircleIcon className="h-5 w-5 ml-2 mr-1 self-center text-red-500" />}
            </h3>
            {red ? (
              errors.map((error, index) => {
                return (
                  <p key={index} className="mt-1 text-sm text-red-500">
                    {error}
                  </p>
                );
              })
            ) : (
              <p className={`mt-1 text-sm text-gray-600`}>{description}</p>
            )}
          </div>
        </div>
        <div className={`mt-5 sm:rounded-md md:mt-0 md:col-span-2`}>
          <div className="sm:rounded-md">
            <div className={`${button ? '' : 'mb-6 sm:mb-0'} shadow sm:rounded-md`}>
              <div className={`${onlyLayout ? '' : 'px-4 py-5 sm:rounded-md sm:p-6 bg-white'} space-y-6`}>{children}</div>
              {(button || button2 || button3 || buttonRed) && (
                <div className="px-4 py-3 bg-gray-50 sm:px-6 flex flex-row-reverse justify-between">
                  <div className="text-right">
                    {button && <ButtonPrimary type="submit" onClick={onClick} text={buttonText} />}
                    {button3 && <ButtonWhite onClick={onClick3} style="mr-2" text={buttonText3} />}
                    {button2 && <ButtonPrimary onClick={onClick2} text={buttonText2} disable={disableButton2} />}
                  </div>
                  <div className="text-left">
                    {buttonRed && <ButtonPrimary canDelete onClick={onClickRed} text={buttonRedText} />}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default TwoColumnForm;
