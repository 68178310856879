import React from 'react';
import PropTypes from 'prop-types';

const ArrowLeftIcon = ({ onClick }) => {
  return (
    <svg
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      fill="none"
      viewBox="0 0 20 20"
      stroke="currentColor"
    >
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
    </svg>
  );
};

ArrowLeftIcon.prototype = {
  onClick: PropTypes.func,
};

export default ArrowLeftIcon;
