import React, { useState, useMemo, useRef, useEffect } from 'react';
import { useTable, useSortBy, useGlobalFilter, useFilters, useColumnOrder, usePagination, useAsyncDebounce } from 'react-table';
import TextColumnFilter from '../../components/TableItems/TextColumnFilter';
import Loader from '../../components/Loading/Loader';
import downloadDataToCsvFile from '../../utils/downloadDataToCsvFile';
import ButtonPrimary from '../../components/Buttons/ButtonPrimary';
import ButtonSecondary from '../../components/Buttons/ButtonSecondary';
import PlusMedium from '../../components/Icons/PlusMediumIcon';
import FilterMedium from '../../components/Icons/FilterMediumIcon';
import DownloadMedium from '../../components/Icons/DownloadMediumIcon';
import TableSmall from '../../components/Icons/TableSmallIcon';
import SortAscSmall from '../../components/Icons/SortAscSmallIcon';
import SortDescSmall from '../../components/Icons/SortDescSmallIcon';
import EditColumns from './EditColumns';
import EditColumnsForStaffOrders from '../../components/Modals/EditColumns';
import FilterColumns from './FilterColumns';
import FilterColumnsForStaffOrders from '../../components/Modals/FilterColumns';
import ContextMenu from '../../components/Menus/ContextMenu';
import TableControlPanel from './TableControlPanel';
import { FilterIcon } from '@heroicons/react/outline';
import { isBefore, add } from 'date-fns';
import axios from 'axios';
import Checkbox from '../../components/Checkbox/Checkbox';
import useApi from '../../hooks/useApi';
import { useDrag, useDrop } from 'react-dnd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TimeSheetStatus } from '../../helpers/enum/timeSheet';
import PillShapedButton from '../../components/Buttons/PillShapedButton';
import PillShapedTransparentButton from '../../components/Buttons/PillShapedTransparentButton';
import { ChevronDownIcon, XIcon } from '@heroicons/react/solid';
import { PresentationChartLineIcon } from '@heroicons/react/outline';
import SimpleAlert from '../../components/Modals/SimpleAlert';
import FileNameSettings from '../Documents/FilenameSettings';
import ButtonWithDropdownMenu from '../../components/DropdownMenus/ButtonWithDropdownMenu';
import { ClipLoader } from 'react-spinners';

function Table({
  columns,
  data,
  tableName,
  initialFilters,
  canDownloadCSV = true,
  showFilterButton = true,
  showEditButton = true,
  customContextMenu = false,
  contextMenuOptions,
  rowOnClick,
  addButton,
  rounded = true,
  highlightRowOnClick = false,
  additionalButtons,
  setFilterContractsUpForExtension,
  filterContractsUpForExtension,
  pageCount,
  fetchData,
  totalItems,
  rowCheckbox,
  selectedRows,
  setSelectedRows,
  activeTab,
  bulkDownloadButton = false,
  bulkDownloading = false,
  setBulkDownloading,
  showBulkDownloadAlert,
  setShowBulkDownloadAlert,
  kpiButton = false,
  setShowKPI,
}) {
  //react state
  const [showFilterColumns, setShowFilterColumns] = useState(false);
  const [showEditColumns, setShowEditColumns] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showColumnFilter, _setShowColumnFilter] = useState();
  const setShowColumnFilter = newVal => _setShowColumnFilter({ ...showColumnFilter, ...newVal });
  const [firstRender, setFirstRender] = useState(true);
  const [userSettings, setUserSettings] = useState();
  const [isLoadingSettings, setIsLoadingSettings] = useState(true);
  const [contextMenu, setContextMenu] = useState(false);
  const [rowHighlight, setRowHighlight] = useState(false);
  const [showFileNameSettings, setShowFileNameSettings] = useState(false);
  const [showColumnGroup, _setShowColumnGroup] = useState({
    consultant: true,
    staffOrder: true,
    staffContract: true,
    frameworkContract: true,
    partnerContract: true,
    salesOrder: true,
    specificContract: true,
  });

  const setShowColumnFilterForStaffOrders = newVal => _setShowColumnFilterForStaffOrders({ ...showColumnFilter, ...newVal });

  const [showColumnFilterForStaffOrders, _setShowColumnFilterForStaffOrders] = useState({
    consultant: false,
    staffOrder: false,
    staffContract: false,
    frameworkContract: false,
    partnerContract: false,
    salesOrder: false,
    specificContract: false,
  });

  const [appliedFilters, setAppliedFilters] = useState({});

  const [sourceState, setSourceState] = useState();

  const {
    settings: { fetchTableSettings, updateTableSettings },
  } = useApi();

  const setShowColumnGroup = newVal => _setShowColumnGroup({ ...showColumnGroup, ...newVal });

  //buttons and functionality

  const handleFilterClick = () => {
    setShowFilterColumns(true);
  };
  const handleEditColumnsClick = () => {
    setShowEditColumns(true);
  };

  const handleDownloadCsvClick = async () => {
    //fetch data without pagination and already
    const data = await fetchData(pageIndex, filters, sortBy, globalFilter, true);
    const columnsTitles = {};

    allColumns.map(column => {
      if (column.isVisible) {
        columnsTitles[column.id] = column.id;
      }
    });
    downloadDataToCsvFile(data, Object.keys(columnsTitles), tableName);
  };

  const handleBulkDownloadClick = async fileNameStructure => {
    let source = sourceState || axios.CancelToken.source();
    !sourceState && setSourceState(source);
    //fetch ids without pagination and trigger bulk download within parent container
    await fetchData(pageIndex, filters, sortBy, globalFilter, false, source, true, fileNameStructure)
      .then(res => {
        setShowBulkDownloadAlert(false);
        return setShowFileNameSettings(false);
      })
      .catch(err => {
        setShowBulkDownloadAlert(false);
        setShowFileNameSettings(false);
        setBulkDownloading(false);
      });
  };

  //React-table

  const defaultColumn = useMemo(() => {
    return {
      Filter: TextColumnFilter,
    };
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    allColumns,
    setGlobalFilter,
    preGlobalFilteredRows,
    setAllFilters,
    setColumnOrder,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    gotoPage,
    setFilter,
    setSortBy,
    state: { globalFilter, pageIndex, filters, sortBy, columnOrder },
    toggleHideAllColumns,
  } = useTable(
    {
      columns,
      initialState: {
        pageIndex: 0,
      },
      data,
      defaultColumn,
      pageCount,
      manualPagination: true,
      manualFilters: true,
      manualGlobalFilter: true,
      manualSortBy: true,
      autoResetPage: false,
      autoResetSortBy: false,
      autoResetExpanded: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useColumnOrder,
    usePagination,
  );

  useEffect(() => {
    if (selectedRows && !selectedRows?.length) {
      let filterValue = null;
      if (userSettings && userSettings[tableName]?.filters) {
        filterValue = userSettings[tableName].filters.find(filter => filter.id === 'client')?.value || null;
      }
      setFilter('client', filterValue);
    }
  }, [selectedRows]);

  const handleCheckRowChange = (event, row) => {
    event.stopPropagation();
    if (!selectedRows.length) {
      //TODO: Should we keep the filters that user chose before selecting a row? What if only the client filter was activated, we should probably not reset the filter
      setFilter('client', [row.original.client_id]);
    }
    if (selectedRows?.some(el => el.original?.id === row.original?.id)) {
      setSelectedRows(selectedRows.filter(r => r.original?.id !== row.original?.id));
    } else {
      setSelectedRows(prev => [...prev, row]);
    }
  };

  const _fetchData = source => {
    let _source = source || sourceState;
    fetchData(pageIndex, filters, sortBy, globalFilter, null, _source)
      .then(r => {
        setIsLoading(false);
      })
      .catch(err => {
        alert('There was an error', err);

        setIsLoading(false);
      });
  };

  const fetchDataDebounced = useAsyncDebounce(_fetchData, 1000);

  useEffect(() => {
    let source = axios.CancelToken.source();
    if (!isLoading) setIsLoading(true);
    //this is to ensure that on first load only this fetch data fires
    if (firstRender) setFirstRender(false);
    setSourceState(source);
    fetchDataDebounced(source);
    gotoPage(0);
    return () => {
      source.cancel();
      setSourceState();
    };
  }, [filters, globalFilter, tableName]);

  useEffect(() => {
    //this is to ensure that on first load this fetch data does not fire, so as to not have duplicated requests
    if (firstRender) return;
    let source = axios.CancelToken.source();
    if (!isLoading) setIsLoading(true);
    _fetchData(source);
    return () => {
      source.cancel();
    };
  }, [pageIndex, sortBy, filterContractsUpForExtension]);

  // this persists filters through a state refresh
  useEffect(() => {
    if (globalFilter) setAppliedFilters(prev => ({ ...prev, globalFilter }));
    else setAppliedFilters(prev => ({ ...prev, globalFilter: null }));
    const allFilters = [];
    if (filters) allFilters.push(...filters);
    if (initialFilters) allFilters.push(...initialFilters);
    if (allFilters.length) setAppliedFilters(prev => ({ ...prev, filters: allFilters }));
    else setAppliedFilters(prev => ({ ...prev, filters: null }));
  }, [globalFilter, filters, initialFilters]);

  useEffect(() => {
    if (appliedFilters?.filters?.length && !filters?.length) {
      setAllFilters(appliedFilters.filters);
    }
    if (appliedFilters?.globalFilter && !globalFilter) setGlobalFilter(appliedFilters.globalFilter);
  }, [appliedFilters]);

  const handleHideColumns = columns => {
    if (!columns || !columns.length) return;
    allColumns.map(column => {
      let found = columns.find(col => {
        if (col?.parent) {
          return col?.parent === column.parent.Header && col.Header === column.Header;
        } else {
          return col.Header === column.Header;
        }
      });
      found.isVisible ? column.toggleHidden(false) : column.toggleHidden(true);
    });
  };

  useEffect(() => {
    fetchTableSettings().then(res => {
      if (res) {
        let allTableSettings = JSON.parse(res?.table_settings);
        setUserSettings(allTableSettings);
        let thisTableSettings = allTableSettings[tableName];
        if (!allTableSettings[tableName]) {
          setUserSettings({ ...allTableSettings, [tableName]: {} });
          updateTableSettings({ ...allTableSettings, [tableName]: {} });
        }
        if (thisTableSettings?.sortBy?.length) {
          setSortBy(thisTableSettings?.sortBy);
        }
        if (thisTableSettings?.filters?.length) {
          let filtersToApply = thisTableSettings?.filters;
          //if it is a date filter, convert date string to date object || In myThaleria we can also use a filter type 'month' for timesheet related tables
          filtersToApply = filtersToApply.map(el => {
            if (
              columns.find(col => col.accessor === el.id)?.filter === 'date' ||
              columns.find(col => col.accessor === el.id)?.filter === 'month'
            ) {
              let formattedArray = el.value.map(unformattedDate => {
                if (unformattedDate) {
                  let formattedDate = new Date(unformattedDate);
                  return formattedDate;
                }
              });
              el.value = formattedArray;
              return el;
            }
            return el;
          });
          //apply filters
          setAllFilters(filtersToApply);
        }
        if (thisTableSettings?.filterContractsUpForExtension) {
          setFilterContractsUpForExtension(thisTableSettings?.filterContractsUpForExtension);
        }

        if (thisTableSettings?.columns?.length) {
          handleHideColumns(thisTableSettings?.columns);
        }

        if (thisTableSettings?.customColumnOrder?.length) {
          setColumnOrder(thisTableSettings?.customColumnOrder);
        }
      } else {
        setUserSettings({ [tableName]: {} });
        updateTableSettings({ [tableName]: {} });
      }
      setIsLoadingSettings(false);
    });
  }, []);

  //FIXME: THis code probably doesn't work
  useEffect(() => {
    if (!userSettings) return;
    handleHideColumns(userSettings[tableName]?.columns);
  }, [columns]);

  useEffect(() => {
    if (!userSettings) return;
    let thisTableSettings = userSettings[tableName];
    if (!thisTableSettings) {
      thisTableSettings = {};
      setUserSettings({ ...userSettings, [tableName]: {} });
      updateTableSettings({ ...userSettings, [tableName]: {} });
    }
    if (thisTableSettings?.sortBy && JSON.stringify(sortBy) !== JSON.stringify(thisTableSettings?.sortBy)) {
      setSortBy(thisTableSettings.sortBy);
    }
    if (thisTableSettings?.customColumnOrder) {
      setColumnOrder(thisTableSettings?.customColumnOrder);
    }
    if (thisTableSettings?.filters && JSON.stringify(filters) !== JSON.stringify(thisTableSettings?.filters)) {
      let filtersToApply = thisTableSettings.filters;
      filtersToApply = filtersToApply.map(el => {
        if (
          columns.find(col => col.accessor === el.id)?.filter === 'date' ||
          columns.find(col => col.accessor === el.id)?.filter === 'month'
        ) {
          let formattedArray = el.value.map(unformattedDate => {
            if (unformattedDate) {
              let formattedDate = new Date(unformattedDate);
              return formattedDate;
            }
          });
          el.value = formattedArray;
          return el;
        }
        return el;
      });
      setAppliedFilters(prev => ({ ...prev, filters: filtersToApply }));
    }
  }, [tableName, userSettings]);

  useEffect(() => {
    if (!userSettings) return;
    let thisTableSettings = userSettings[tableName];

    //FIXME: In thaleriaCATS the if statement below is active. Here it seems to create an issue where if there are no settings for the table in question, it will not save the sortBy the user chose
    // if (!thisTableSettings) return;

    if (JSON.stringify(thisTableSettings?.sortBy) != JSON.stringify(sortBy) || (!thisTableSettings && sortBy?.length)) {
      let updated = {
        ...userSettings,
        [tableName]: {
          ...thisTableSettings,
          sortBy: sortBy,
        },
      };
      setUserSettings(updated);
      updateTableSettings(updated);
    }
  }, [sortBy]);

  const defaultPropGetter = () => ({});

  let fullTableComponentHeight = document.getElementById('full-table-component')?.offsetHeight;
  let tableHeaderHeight = document.getElementById('table-header')?.offsetHeight;
  let bottomTableControlPanelHeight = document.getElementById('bottom-table-control-panel')?.offsetHeight;
  let topTableControlPanelHeight = document.getElementById('top-table-control-panel')?.offsetHeight;
  let tableHasXScrollBar =
    document.getElementById('table-x-scrollbar-div')?.scrollWidth > document.getElementById('table-x-scrollbar-div')?.clientWidth;

  let correctRowHeight =
    (fullTableComponentHeight -
      tableHeaderHeight -
      bottomTableControlPanelHeight -
      topTableControlPanelHeight -
      (tableHasXScrollBar * 8 || 2)) /
    10;

  let formattedRowHeight = correctRowHeight.toString() + 'px';

  const aboutToExpire = original => {
    if (
      (original.staffOrder_end &&
        isBefore(new Date(original.staffOrder_end), add(new Date(), { months: 2 })) &&
        original.staffOrder_contract_extension === null) ||
      (new Number(original.staffOrder_days_ordered).valueOf() - new Number(original.staffOrder_days_consumed).valueOf() <= 50 &&
        original.staffOrder_contract_extension === null)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const moveColumn = async (dragIndex, hoverIndex) => {
    let newColumnOrder = [];
    allColumns.forEach(column => {
      if (column.isVisible) newColumnOrder.push(column.id);
    });
    newColumnOrder.splice(hoverIndex, 0, newColumnOrder.splice(dragIndex, 1)[0]);
    setColumnOrder(newColumnOrder);
  };

  const saveColumnOrderSettings = (column, index) => {
    //This if statement prevents issues when reordering columns that include a checkbox.
    if (!column) return;
    const prevIndex = allColumns.findIndex(col => col.id === column.id);
    let newColumnOrder = allColumns.map(col => col.id);
    newColumnOrder.splice(index, 0, newColumnOrder.splice(prevIndex, 1)[0]);
    let updated = {
      ...userSettings,
      [tableName]: {
        ...userSettings[tableName],
        customColumnOrder: newColumnOrder,
      },
    };
    setUserSettings(updated);
    updateTableSettings(updated);
  };

  const _moveColumnDebounced = useAsyncDebounce((item, index, monitor, dropRef) => {
    //This debounced function correct issue where the drop target was being moved so fast dnd couldn't find target at certain points.
    const dragIndex = item.index;
    const hoverIndex = index;
    //The !dropRef?.current?.getBoundingClientRect() below was added to resolve issue where there was no hoverBoundingRect.
    if (dragIndex === hoverIndex || !dropRef?.current?.getBoundingClientRect()) return;

    const hoverBoundingRect = dropRef.current.getBoundingClientRect();
    const hoverMiddleX = hoverBoundingRect.width / 2;
    const clientOffset = monitor.getClientOffset();
    const hoverClientX = clientOffset.x - hoverBoundingRect.left;

    if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) return;
    if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) return;

    moveColumn(dragIndex, hoverIndex);

    item.index = hoverIndex;
  }, 0);

  const ColumnHead = ({ column, index, moveColumn, getColumnProps, getHeaderProps, isDraggable }) => {
    const dropRef = useRef();
    const dragRef = useRef();

    const [{ canDrop, isOverCurrent }, drop] = useDrop(() => ({
      // The type (or types) to accept - strings or symbols
      accept: 'BOX',
      hover(item, monitor) {
        if (!dropRef.current) {
          return;
        }

        _moveColumnDebounced(item, index, monitor, dropRef);
      },
      drop: item => ({ column: column, index: index }),
      // Props to collect
      collect: monitor => ({
        canDrop: monitor.canDrop(),
        isOverCurrent: monitor.isOver({ shallow: true }),
      }),
    }));

    const [{ isDragging }, drag, preview] = useDrag(() => ({
      type: 'BOX',
      item: { index },
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult();
        saveColumnOrderSettings(dropResult?.column, dropResult?.index);
      },

      // The collect function utilizes a "monitor" instance (see the Overview for what this is)
      // to pull important pieces of state from the DnD system.
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
    }));

    //Disables reorder dragging in parent headers
    if (isDraggable) {
      preview(drop(dropRef.current));
      drag(dragRef);
    }

    return (
      <th
        // Return an array of prop objects and react-table will merge them appropriately
        {...column.getHeaderProps([
          {
            className: column.className,
          },
          getColumnProps(column),
          getHeaderProps(column, dropRef),
          column.getSortByToggleProps(),
        ])}
      >
        <span ref={dragRef} className="relative ">
          {column.render('Header')}
          {/* Add a sort direction indicator */}
          <span
            className={`absolute right-0 flex transform -translate-y-4 ${
              column?.filterValue?.length && column?.isSorted ? 'translate-x-14' : 'translate-x-8'
            }`}
          >
            {column.filterValue ? (
              Array.isArray(column.filterValue) ? (
                !!column.filterValue.length && <FilterIcon className={'-ml-1 mr-2 h-5 w-5 text-thaleria-orange-700'} />
              ) : (
                <FilterIcon className={'-ml-1 mr-2 h-5 w-5 text-thaleria-orange-700'} />
              )
            ) : (
              ''
            )}
            {column.isSorted ? (
              column.isSortedDesc ? (
                <SortAscSmall style={'-ml-1 mr-2 h-5 w-5 text-thaleria-orange-700'} />
              ) : (
                <SortDescSmall style={'-ml-1 mr-2 h-5 w-5 text-thaleria-orange-700'} />
              )
            ) : (
              ''
            )}
          </span>
          {/* Add a filter */}
        </span>
      </th>
    );
  };

  function Table({
    getHeaderProps = defaultPropGetter,
    getHeaderGroupProps = defaultPropGetter,
    getColumnProps = defaultPropGetter,
    getRowProps = defaultPropGetter,
    getCellProps = defaultPropGetter,
  }) {
    return (
      <table className="min-w-full" {...getTableProps()}>
        <thead id={'table-header'} className="border-t border-b border-gray-200">
          {headerGroups.map((headerGroup, groupIndex) => (
            <tr {...headerGroup.getHeaderGroupProps(getHeaderGroupProps(headerGroup))}>
              {groupIndex === 0 && rowCheckbox && (
                <th className="pl-3 divide-x border-b border-gray-200 bg-gray-50 px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider items-center">
                  <div className="w-4"></div>
                </th>
              )}
              {headerGroup.headers.map((column, index) => {
                //Parent headers shouldn't be draggable to reorder columns, only for data headers
                const shouldBeDraggable =
                  headerGroups?.length === 1 || (headerGroups?.length > 1 && groupIndex === headerGroups?.length - 1);
                return (
                  <ColumnHead
                    key={column.id}
                    column={column}
                    index={index}
                    moveColumn={moveColumn}
                    getColumnProps={getColumnProps}
                    getHeaderProps={getHeaderProps}
                    isDraggable={shouldBeDraggable}
                  />
                );
              })}
            </tr>
          ))}
        </thead>

        <tbody id="table-body" className="" {...getTableBodyProps()}>
          <Loader isLoading={isLoading || isLoadingSettings} table>
            {page.map(row => {
              prepareRow(row);
              return (
                // Merge user row props in
                <tr {...row.getRowProps([getRowProps(row)])}>
                  {rowCheckbox && (
                    <td className={`relative pl-3 z-9`}>
                      {selectedRows?.some(el => el.original?.id == row.original?.id) && (
                        <div className="absolute inset-y-0 left-0 w-0.5 bg-thaleria-orange-600" />
                      )}
                      <Checkbox
                        value={selectedRows?.some(el => el.original?.id == row.original?.id)}
                        onChange={e => handleCheckRowChange(e, row)}
                        horizontal
                      />
                    </td>
                  )}
                  {row.cells.map(cell => {
                    return (
                      <td
                        style={{
                          height: formattedRowHeight,
                        }}
                        // Return an array of prop objects and react-table will merge them appropriately
                        {...cell.getCellProps([
                          {
                            className: cell.column.className,
                          },
                          getColumnProps(cell.column),
                          getCellProps(cell),
                        ])}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </Loader>
        </tbody>
      </table>
    );
  }

  const handleFilterToggle = toggledValue => {
    const otherValue = toggledValue === TimeSheetStatus.APPROVED ? TimeSheetStatus.PREAPPROVE : TimeSheetStatus.APPROVED;

    const currentFilter = filters.find(filter => filter.id === 'timesheetStatus')?.value || null;

    let newFilterValue = [];
    if (!currentFilter) {
      //no filter means both values are searched for. We need to filter results based on the OTHER value
      newFilterValue = [otherValue];
    } else {
      if (currentFilter.includes(toggledValue)) {
        if (currentFilter.length === 1) {
          //both filters OFF should not be possible as the query will never have any results so we activate only the other value to switch them
          newFilterValue = [otherValue];
        } else {
          //remove toggledValue from filter
          newFilterValue = currentFilter.filter(el => el !== toggledValue);
        }
      } else {
        //if the value is not in current filter, add it
        newFilterValue = [...currentFilter, toggledValue];
      }
    }
    setFilter('timesheetStatus', newFilterValue);
  };

  const handleRemoveExpiryFilter = () => {
    setFilterContractsUpForExtension(false);
    if (!userSettings) return;
    let thisTableSettings = userSettings[tableName];

    if (thisTableSettings?.filterContractsUpForExtension !== false || !thisTableSettings) {
      let updated = {
        ...userSettings,
        [tableName]: {
          ...thisTableSettings,
          filterContractsUpForExtension: false,
        },
      };
      setUserSettings(updated);
      updateTableSettings(updated);
    }
  };

  const cancelBulkDownload = () => {
    let source = sourceState || axios.CancelToken.source();
    setBulkDownloading(false);
    setShowFileNameSettings(false);
    source.cancel();
    setSourceState();
  };

  const bulkDownloadAlertOnAccept = () => {
    if (totalItems < 400) {
      if (tableName === 'accountsReceivable' || tableName === 'creditNotes' || tableName === 'timesheets') {
        setShowBulkDownloadAlert(false);
        return setShowFileNameSettings(true);
      }
      return handleBulkDownloadClick();
    }
  };

  const dropdownOptions = useMemo(() => {
    const _items = [];

    if (canDownloadCSV) {
      _items.push({
        text: 'Download CSV',
        icon: <DownloadMedium style={'h-5 w-5'} />,
        onClick: handleDownloadCsvClick,
      });
    }

    if (bulkDownloadButton) {
      _items.push({
        text: 'Download PDFs',
        icon: <DownloadMedium style={'h-5 w-5'} />,
        onClick: () => setShowBulkDownloadAlert(true),
      });
    }

    if (kpiButton) {
      _items.push({
        text: 'KPI Dashboard',
        icon: <PresentationChartLineIcon className={'h-5 w-5'} />,
        onClick: () => setShowKPI(true),
      });
    }
    if (additionalButtons && additionalButtons.length) {
      additionalButtons.forEach(button => {
        _items.push({
          text: button.text,
          icon: button.icon,
          onClick: button.onClick,
        });
      });
    }

    return _items;
    //filters dependency is necessary to make sure handleDownloadCsvClick fetches data with the new filters applied
  }, [canDownloadCSV, kpiButton, bulkDownloadButton, additionalButtons, filters]);

  return (
    <DndProvider backend={HTML5Backend}>
      <div
        id="full-table-component"
        className={`bg-banner-pattern flex flex-col h-full overflow-hidden shadow ${rounded ? 'rounded-lg' : ''}`}
        style={{ minWidth: '900px', maxWidth: 'calc(100vw - 14rem)' }}
      >
        {bulkDownloadButton && (
          <SimpleAlert
            errorTitle={'Download PDFs'}
            errorMsg={
              totalItems > 400
                ? `Your selection is too large (400 max) to download in bulk, please refine your search and try again.`
                : `This will download a zip folder containing the file(s) of the ${totalItems} currently listed entries. Do you wish to proceed?`
            }
            onAcceptText={
              totalItems > 400 ? (
                'Accept'
              ) : bulkDownloading ? (
                <ClipLoader className="mr-2 w-20" color={'#FFFF'} size={17} />
              ) : (
                'Continue'
              )
            }
            onDeclineText={'Cancel'}
            onDeclineClick={() => setShowBulkDownloadAlert(false)}
            onAcceptClick={() => bulkDownloadAlertOnAccept()}
            show={showBulkDownloadAlert}
            hide={() => setShowBulkDownloadAlert(false)}
            clickOutsideToClose={false}
          />
        )}
        {bulkDownloadButton && (
          <FileNameSettings
            show={showFileNameSettings}
            setShow={setShowFileNameSettings}
            fileType={tableName}
            loading={bulkDownloading}
            onAccept={handleBulkDownloadClick}
            onCancel={cancelBulkDownload}
            isLoadingSettings={isLoadingSettings}
          />
        )}
        {tableName == 'staffOrders' ? (
          <EditColumnsForStaffOrders
            toggleHideAllColumns={toggleHideAllColumns}
            allColumns={allColumns}
            setColumnOrder={setColumnOrder}
            showColumnGroup={showColumnGroup}
            setShowColumnGroup={setShowColumnGroup}
            show={showEditColumns}
            setShow={setShowEditColumns}
            userSettings={userSettings}
            setUserSettings={setUserSettings}
            columnOrder={columnOrder}
            tableName={tableName}
          />
        ) : (
          <EditColumns
            columnMatchName={tableName}
            toggleHideAllColumns={toggleHideAllColumns}
            allColumns={allColumns}
            setColumnOrder={setColumnOrder}
            show={showEditColumns}
            setShow={setShowEditColumns}
            userSettings={userSettings}
            setUserSettings={setUserSettings}
            tableName={tableName}
            columnOrder={columnOrder}
          />
        )}
        {tableName == 'staffOrders' ? (
          <FilterColumnsForStaffOrders
            filterContractsUpForExtension={filterContractsUpForExtension}
            setFilterContractsUpForExtension={setFilterContractsUpForExtension}
            columnMatchName={tableName}
            allColumns={allColumns}
            headerGroups={headerGroups}
            setAllFilters={setAllFilters}
            showColumnFilter={showColumnFilterForStaffOrders}
            setShowColumnFilter={setShowColumnFilterForStaffOrders}
            showColumnGroup={showColumnGroup}
            show={showFilterColumns}
            setShow={setShowFilterColumns}
            filters={filters}
            userSettings={userSettings}
            setUserSettings={setUserSettings}
            tableName={tableName}
          />
        ) : (
          <FilterColumns
            columnMatchName={tableName}
            allColumns={allColumns}
            headerGroups={headerGroups}
            setAllFilters={setAllFilters}
            showColumnFilter={showColumnFilter}
            setShowColumnFilter={setShowColumnFilter}
            show={showFilterColumns}
            setShow={setShowFilterColumns}
            filters={filters}
            userSettings={userSettings}
            setUserSettings={setUserSettings}
            tableName={tableName}
          />
        )}
        {/* Modals */}

        {/* Buttons above table */}
        <div id="top-table-control-panel" className="px-4 justify-between py-5 sm:px-6 grid grid-cols-1 lg:flex">
          <div className="grid grid-cols-1 mb-4 lg:mb-0 lg:flex">
            {showFilterButton && (
              <ButtonSecondary
                icon={<FilterMedium style={'-ml-1 mr-2 h-5 w-5 text-thaleria-blue-500'} />}
                style={'mb-4 lg:mb-0 lg:mr-2'}
                onClick={handleFilterClick}
                text={'Filter results'}
              />
            )}
            {showEditButton && (
              <ButtonSecondary
                icon={<TableSmall style={'-ml-1 mr-2 h-5 w-5 text-thaleria-blue-500'} />}
                onClick={handleEditColumnsClick}
                text={'Edit columns'}
              />
            )}
            {(tableName === 'approvedInvoicingItems' || tableName === 'invoicedInvoicingItems') && (
              <div className="flex px-4 gap-x-3">
                {filters.find(f => f.id === 'timesheetStatus') &&
                !filters.find(f => f.id === 'timesheetStatus')?.value?.includes(TimeSheetStatus.APPROVED) ? (
                  <PillShapedTransparentButton
                    icon={<FilterIcon className="h-4 w-4 mr-1 text-thaleria-blue-500 hover:text-thaleria-blue-300" />}
                    text="Approved"
                    color="blue"
                    size="m"
                    onClick={() => handleFilterToggle(TimeSheetStatus.APPROVED)}
                  />
                ) : (
                  <PillShapedButton
                    text="Approved"
                    color="blue"
                    onClick={() => handleFilterToggle(TimeSheetStatus.APPROVED)}
                    icon={<FilterIcon className="h-4 w-4 mr-1 text-white" />}
                  />
                )}
                {filters.find(f => f.id === 'timesheetStatus') &&
                !filters.find(f => f.id === 'timesheetStatus')?.value?.includes(TimeSheetStatus.PREAPPROVE) ? (
                  <PillShapedTransparentButton
                    icon={<FilterIcon className="h-4 w-4 mr-1 text-thaleria-blue-500 hover:text-thaleria-blue-300" />}
                    text="Pre-approved"
                    color="blue"
                    size="m"
                    onClick={() => handleFilterToggle(TimeSheetStatus.PREAPPROVE)}
                  />
                ) : (
                  <PillShapedButton
                    icon={<FilterIcon className="h-4 w-4 mr-1 text-white" />}
                    text="Pre-approved"
                    color="blue"
                    size="m"
                    onClick={() => handleFilterToggle(TimeSheetStatus.PREAPPROVE)}
                  />
                )}
              </div>
            )}
            {tableName === 'staffOrders' && filterContractsUpForExtension && (
              <div className="text-sm flex items-center align-middle px-4 ml-4 rounded-full bg-thaleria-blue-500 text-white relative ">
                <FilterIcon className="h-4 w-4 mr-1" />
                Contracts up for extension
                <XIcon className="cursor-pointer ml-2 h-4 w-4" onClick={() => handleRemoveExpiryFilter()} />
              </div>
            )}
          </div>
          <div className="flex gap-x-4">
            <ButtonWithDropdownMenu options={dropdownOptions} />
            {addButton && (
              <ButtonPrimary
                icon={<PlusMedium style={'-ml-1 mr-2 h-5 w-5 text-white'} />}
                onClick={addButton.onClick ? addButton.onClick : false}
                text={addButton.text ? addButton.text : 'Add New'}
                link={addButton.link ? addButton.link : false}
              />
            )}
          </div>
        </div>
        {/* Commented margined version for table */}
        <div className="h-full overflow-y-auto">
          {/* px-4 py-5 sm:p-6 */}
          <div className="flex flex-col">
            <div id="table-x-scrollbar-div" className="overflow-x-auto">
              {/* -my-2  sm:-mx-6 lg:-mx-8 */}
              <div className="align-middle inline-block min-w-full ">
                {/* py-2 sm:px-6 lg:px-8 */}
                <div className="shadow border-b border-gray-200">
                  {/* sm:rounded-lg */}
                  <Loader isLoading={!columns.length}>
                    {customContextMenu && (
                      <ContextMenu
                        data={page}
                        menuOptions={contextMenuOptions}
                        contextMenu={contextMenu}
                        setContextMenu={setContextMenu}
                        setRowHighlight={setRowHighlight}
                      />
                    )}
                    <Table
                      getHeaderGroupProps={headerGroup => {
                        const hasParents = headerGroup.headers.find(h => h.parent !== undefined);
                        return {
                          //FIXME:I think this is causing the column headers to lose their border if there are hidden columns
                          className: hasParents
                            ? headerGroup.headers.length === columns.length
                              ? 'divide-x border-b border-gray-200'
                              : ''
                            : 'divide-x border-b border-gray-200',
                        };
                      }}
                      getHeaderProps={(column, ref) => {
                        const props = {
                          /* onClick: () => alert('Header!'), */
                          className: 'bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
                        };
                        if (ref) props.ref = ref;
                        return props;
                      }}
                      getColumnProps={column => ({
                        /* onClick: () => alert('Column!'), */
                      })}
                      getRowProps={row => {
                        return {
                          onClick: () => {
                            if (!contextMenu && rowOnClick) {
                              if (highlightRowOnClick) {
                                const value = rowHighlight === row?.original?.id ? null : row;
                                setRowHighlight(value?.original?.id);
                                rowOnClick(value);
                              } else {
                                rowOnClick(row);
                              }
                            } else {
                              setContextMenu(false);
                            }
                          },
                          className: `
                          context-menu 
                          ${
                            rowOnClick && (!highlightRowOnClick ? !rowHighlight : true)
                              ? `cursor-pointer ${
                                  aboutToExpire(row.original)
                                    ? 'bg-thaleria-orange-100 hover:bg-thaleria-orange-200'
                                    : 'odd:bg-white even:bg-gray-50 hover:bg-blue-50'
                                } `
                              : `${
                                  aboutToExpire(row.original)
                                    ? 'bg-thaleria-orange-100 hover:bg-thaleria-orange-200'
                                    : 'odd:bg-white even:bg-gray-50 hover:bg-blue-50'
                                } `
                          } 
                          ${rowHighlight == row.values.id ? 'bg-gray-50' : undefined}
                        `,
                          id: row.values.id || row.original.id,
                        };
                      }}
                      getCellProps={cellInfo => {
                        return {
                          className: 'px-6 py-4 whitespace-nowrap text-sm text-gray-500',
                        };
                      }}
                    />
                  </Loader>
                </div>
              </div>
            </div>
          </div>
        </div>
        <TableControlPanel
          pageIndex={pageIndex}
          pageCount={pageCount < 1 ? 1 : pageCount}
          previousPage={previousPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          gotoPage={gotoPage}
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          totalItems={totalItems}
          tableName={tableName}
        />
      </div>
    </DndProvider>
  );
}

export default Table;
