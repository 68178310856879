import React, { useRef, useCallback, useEffect } from 'react';
import { XIcon } from '@heroicons/react/outline';
import ButtonPrimary from '../Buttons/ButtonPrimary';
import Cropper from 'react-easy-crop';
import InputSimple from './InputSimple';
import { Button } from 'react-bootstrap';

const InputBanner = ({
  image,
  setImage,
  applyCropToImage,
  crop,
  setCrop,
  rotation,
  setRotation,
  zoom,
  setZoom,
  croppedAreaPixels,
  setCroppedAreaPixels,
  setCroppedImage,
}) => {
  const fileInputRef = useRef(null);

  const handleDrop = e => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file?.size > 10000000) {
      alert(`Maximum file size is 10mb`);
      return null;
    }
    if (file && file.type.match('image/(jpeg|png|gif|bmp|webp)')) {
      const imageObject = {
        url: URL.createObjectURL(file),
        buffer: file,
        name: file.name,
        type: file.type,
        size: file.size,
      };

      setImage(imageObject);
    }
  };

  const handleDragOver = e => {
    e.preventDefault();
  };

  function readFile(file) {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }

  const handleFileInput = async e => {
    const file = e.target.files[0];
    if (file?.size > 10000000) {
      alert(`Maximum file size is 10mb`);
      return null;
    }
    let base64;
    base64 = await readFile(file);
    if (file && file.type.match('image/(jpeg|png|gif|bmp|webp)')) {
      const imageObject = {
        url: URL.createObjectURL(file),
        buffer: file,
        base64,
        name: file.name,
        type: file.type,
        size: file.size,
      };
      setImage(imageObject);
    }
  };

  const handleDelete = () => {
    setImage(null);
    setCroppedImage(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  useEffect(() => {
    applyCropToImage(croppedAreaPixels, rotation);
  }, [croppedAreaPixels, zoom, rotation, image]);

  return (
    <div className={`w-full relative rounded-lg overflow-hidden ${image ? '' : 'border border-gray-300 p-3'}`}>
      <div
        className={`w-full flex flex-col items-center justify-center rounded-md ${
          image ? '' : 'border-2 border-dashed border-gray-300'
        }`}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        {image ? (
          <>
            <div className="relative w-full h-[425px] lg:h-[650px] flex justify-center items-center">
              <Cropper
                image={image.base64}
                crop={crop}
                rotation={rotation}
                zoom={zoom}
                aspect={16 / 9}
                onCropChange={setCrop}
                onRotationChange={setRotation}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
              <button
                onClick={handleDelete}
                className="absolute top-2 right-2 text-white bg-red-500 rounded-full p-1 hover:bg-red-600 focus:outline-none"
              >
                <XIcon className="h-5 w-5" />
              </button>
            </div>
            <div className="flex flex-row justify-between w-full py-4 items-center">
              <div className="flex space-x-8 w-">
                <InputSimple override label="Zoom">
                  <input
                    type="range"
                    step={0.1}
                    min={1}
                    max={3}
                    defaultValue={0}
                    className="w-full"
                    onChange={e => setZoom(e.target.value)}
                  />
                </InputSimple>
                <InputSimple override label="Rotation">
                  <input
                    type="range"
                    min={0}
                    max={360}
                    defaultValue={0}
                    className="w-full indigo-600"
                    onChange={e => setRotation(e.target.value)}
                  />
                </InputSimple>
              </div>
            </div>
          </>
        ) : (
          <>
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileInput}
              accept="image/jpeg, image/png, image/gif, image/bmp, image/webp"
              className="hidden"
            />
            <div className="m-auto flex flex-col gap-1 my-6 justify-center" id="dropzone">
              <ButtonPrimary
                id="dropzone"
                text="Upload announcement banner"
                onClick={() => fileInputRef.current.click()}
                size="xl"
              />
              <span id="dropzone" className="w-full text-center">
                or drop it here
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default InputBanner;
