import React, { useState, useReducer, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import AddTitleBodyAnnouncement from './AddTitleBodyAnnouncement';
import AddViewerAnnouncement from './AddViewerAnnouncement';
import { useHistory } from 'react-router-dom';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import useApi from '../../../hooks/useApi';
import { AccountType } from '../../../helpers/enum/accounts';
import contractTypes from '../../../helpers/enum/contractTypeIDs';
import { getCroppedImg } from '../../../helpers';
import Loader from '../../../components/Loading/Loader';

const initialState = {
  title: '',
  body: EditorState.createEmpty(),
  countries: [],
  accountTypes: [AccountType.ADMIN, AccountType.STAFF, AccountType.USER],
  contractTypes: [contractTypes.company, contractTypes.employee, contractTypes.freelancer],
  titleErrorMessage: '',
  bodyErrorMessage: '',
};

function reducer(state, action) {
  switch (action.type) {
    case 'title':
      const resultTitle = { ...state, title: action.title };
      if (action.title.length > 3) return { ...resultTitle, titleErrorMessage: '' };
      return resultTitle;
    case 'body':
      const resultBody = { ...state, body: action.body };
      if (action.body.length > 3) return { ...resultBody, bodyErrorMessage: '' };
      return resultBody;
    case 'countries':
      return { ...state, countries: action.countries };
    case 'accountTypes':
      return { ...state, accountTypes: action.accountTypes };
    case 'contractTypes':
      return { ...state, contractTypes: action.contractTypes };
    case 'updateAll':
      return { ...state, ...action.payload };
    case 'showTitleError':
      return { ...state, titleErrorMessage: action.payload };
    case 'showTextAreaError':
      return { ...state, bodyErrorMessage: action.payload };
    default:
      throw new Error();
  }
}

const CreateOrEditAnnouncement = props => {
  const currentUser = useSelector(state => state.auth.currentUser);
  const history = useHistory();
  const {
    announcements: { createAnnouncement, getAnnouncement, updateAnnouncement },
  } = useApi();

  const [step, setStep] = useState(1);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [disabledCheckboxes, setDisabledCheckboxes] = useState([]);
  const [image, setImage] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [croppedImage, setCroppedImage] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const onTitleHandler = e => dispatch({ type: 'title', title: e.target.value });

  const onBodyHandler = value => dispatch({ type: 'body', body: value });

  useEffect(() => {
    if (props.id) {
      getAnnouncement(props.id).then(res => {
        if (res.body) {
          var convertedJSON = JSON.parse(res.body);
        }
        dispatch({
          type: 'updateAll',
          payload: {
            title: res.title,
            body: EditorState.createWithContent(convertFromRaw(convertedJSON)),
            accountTypes: res.account_types,
            contractTypes: res.contract_types,
            countries: res.countries,
          },
        });

        // Check if there is an image object in the response and handle it
        if (res.image) {
          setImage({
            base64: `data:image/jpeg;base64,${Buffer.from(res.image).toString('base64')}`,
            url: URL.createObjectURL(new Blob([res.image], { type: 'image/jpeg' })),
            name: 'banner.jpg',
            type: 'image/jpeg',
          });
        }
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  }, [props.id]);

  const onCountriesChange = selectedItems => {
    let countriesIds;
    if (!selectedItems) countriesIds = [];
    else countriesIds = selectedItems.map(item => item.value);
    dispatch({ type: 'countries', countries: countriesIds });
  };

  const onContractTypesChange = event => {
    let acceptedContractTypes = state.contractTypes;
    if (event.target.id == 'employee') {
      event.target.checked
        ? acceptedContractTypes.push(contractTypes.employee)
        : (acceptedContractTypes = acceptedContractTypes.filter(id => id != contractTypes.employee));
    }
    if (event.target.id == 'freelancer') {
      event.target.checked
        ? acceptedContractTypes.push(contractTypes.freelancer)
        : (acceptedContractTypes = acceptedContractTypes.filter(id => id != contractTypes.freelancer));
    }
    if (event.target.id == 'company') {
      event.target.checked
        ? acceptedContractTypes.push(contractTypes.company)
        : (acceptedContractTypes = acceptedContractTypes.filter(id => id != contractTypes.company));
    }
    dispatch({ type: 'contractTypes', contractTypes: acceptedContractTypes });
  };

  const onAccountTypesChange = event => {
    let acceptedAccountTypes = state.accountTypes;
    if (event.target.id == 'user') {
      event.target.checked
        ? acceptedAccountTypes.push(AccountType.USER)
        : (acceptedAccountTypes = acceptedAccountTypes.filter(id => id != AccountType.USER));
    }
    if (event.target.id == 'staff') {
      event.target.checked
        ? acceptedAccountTypes.push(AccountType.STAFF)
        : (acceptedAccountTypes = acceptedAccountTypes.filter(id => id != AccountType.STAFF));
    }
    dispatch({ type: 'accountTypes', accountTypes: acceptedAccountTypes });
  };

  const onNextHandler = () => {
    const bodyJSON = state.body.getCurrentContent().getPlainText(' ');

    if (state.title.length < 3 || state.title.length > 150)
      dispatch({ type: 'showTitleError', payload: 'Title must be Greater than 3 characters and Less thant 150 characters' });
    else if (bodyJSON && bodyJSON.length < 10)
      dispatch({ type: 'showTextAreaError', payload: 'Text must be Greater than 10 characters' });
    else setStep(2);
  };

  const onSuccessHandler = () => {
    setIsUploading(true);
    if (props.id) onUpdateAnnouncement();
    else onCreateAnnouncement();
  };

  const onCreateAnnouncement = () => {
    var rawContentState = convertToRaw(state.body.getCurrentContent());
    var bodyJSON = JSON.stringify(rawContentState);

    const sendData = {
      title: state.title,
      body: bodyJSON,
      countries: state.countries,
      account_types: state.accountTypes,
      contract_types: state.contractTypes,
    };

    createAnnouncement(sendData, croppedImage?.file).then(res => {
      setIsUploading(false);
      history.goBack();
    });
  };

  const onUpdateAnnouncement = () => {
    var rawContentState = convertToRaw(state.body.getCurrentContent());
    var bodyJSON = JSON.stringify(rawContentState);
    const sendData = {
      title: state.title,
      body: bodyJSON,
      countries: state.countries,
      account_types: state.accountTypes,
      contract_types: state.contractTypes,
    };
    updateAnnouncement(props.id, sendData, croppedImage?.file).then(res => {
      setIsUploading(false);
      history.goBack();
    });
  };

  const onCancelHandler = () => {
    history.goBack();
  };

  const successButtonName = props.id ? 'Update' : 'Create';

  const applyCropToImage = useCallback(
    async (croppedAreaPixels, rotation) => {
      try {
        if (!image) setCroppedImage(null);
        const croppedImage = await getCroppedImg(image.base64, croppedAreaPixels, rotation);

        // let newImage = new File([croppedImage], image.name, {
        //   type: croppedImage.type,
        // });

        const url = URL.createObjectURL(croppedImage);

        const base64 = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(croppedImage);
        });

        let newImage = {
          file: new File([croppedImage], image.name, {
            type: croppedImage.type,
          }),
          base64,
          url,
        };

        setCroppedImage(newImage);
      } catch (e) {
        console.error(e);
      }
    },
    [croppedAreaPixels, rotation, image],
  );

  const announcementElement =
    step == 1 ? (
      <AddTitleBodyAnnouncement
        onCancelHandler={onCancelHandler}
        onSuccessHandler={onNextHandler}
        title={state.title}
        body={state.body}
        onTitleChange={onTitleHandler}
        onBodyChange={onBodyHandler}
        image={image}
        setImage={setImage}
        inputErrorMessage={state.titleErrorMessage}
        textAreaErrorMessage={state.bodyErrorMessage}
        isUploading={isUploading}
        croppedImage={croppedImage}
        setCroppedImage={setCroppedImage}
        applyCropToImage={applyCropToImage}
        crop={crop}
        setCrop={setCrop}
        rotation={rotation}
        setRotation={setRotation}
        zoom={zoom}
        setZoom={setZoom}
        croppedAreaPixels={croppedAreaPixels}
        setCroppedAreaPixels={setCroppedAreaPixels}
      />
    ) : (
      <AddViewerAnnouncement
        successButtonName={successButtonName}
        onCancelHandler={onCancelHandler}
        acceptedContractTypes={state.contractTypes}
        acceptedAccountTypes={state.accountTypes}
        countriesDefaultId={state.countries}
        onSuccessHandler={onSuccessHandler}
        onCountriesChange={onCountriesChange}
        onContractTypesChange={onContractTypesChange}
        onAccountTypesChange={onAccountTypesChange}
        countriesPlaceholder="All countries"
        isAdmin={currentUser.account_type_id == AccountType.ADMIN}
        disabledCheckboxes={disabledCheckboxes}
        isUploading={isUploading}
      />
    );

  return <Loader isLoading={isLoading}>{announcementElement}</Loader>;
};

export default CreateOrEditAnnouncement;

CreateOrEditAnnouncement.prototype = {
  id: PropTypes.number,
};
