import { ExclamationIcon, PlusIcon } from '@heroicons/react/outline';
import React from 'react';
import { useState } from 'react';
import Card from '../../components/Cards/Card';
import { LeaveRequestType, LeaveRequestStatus } from '../../helpers/enum/leaveRequest';
import dayjs from 'dayjs';
import { ArrowSmRightIcon } from '@heroicons/react/solid';
import AdminCreateLeaveRequestModal from '../../pages/Admin/HrManager/LeaveRequests/AdminCreateLeaveRequestModal';
import { Link } from 'react-router-dom';
import WarningText from '../../components/Warnings/WarningText';

function TimesheetSummary({ leaveRequests, isAdmin = false, userId, showAddLeaveRequestModal, setShowAddLeaveRequestModal }) {
  const paidLeaves = leaveRequests.filter(lr => lr.type === LeaveRequestType.paidLeave);
  const unPaidLeaves = leaveRequests.filter(lr => lr.type === LeaveRequestType.unPaidLeave);
  const medicalLeaves = leaveRequests.filter(lr => lr.type === LeaveRequestType.medicalLeave);
  const trainingLeaves = leaveRequests.filter(lr => lr.type === LeaveRequestType.trainingLeave);

  let unapprovedLeave = false;

  leaveRequests.map(lr => {
    if (lr.status !== LeaveRequestStatus.approved) {
      unapprovedLeave = true;
    }
  });

  const renderTitle = () => {
    return <span className="flex">Leave requests</span>;
  };

  return (
    <Card
      title={renderTitle()}
      noYPadding
      noXPadding
      optionalButton={isAdmin}
      onClick={() => setShowAddLeaveRequestModal(true)}
      optionalButtonText={'Add new'}
      optionalButtonIcon={<PlusIcon className="w-5 h-5 mr-2" />}
      className="w-full"
    >
      <AdminCreateLeaveRequestModal
        show={showAddLeaveRequestModal}
        hide={() => setShowAddLeaveRequestModal(false)}
        userId={userId}
      />
      <div className="flex flex-col px-6 py-6">
        {unapprovedLeave && <WarningText text="Includes not yet approved leave requests." className="mb-2" />}
        <dl className="">
          {paidLeaves?.length > 0 || unPaidLeaves?.length > 0 || medicalLeaves?.length > 0 || trainingLeaves?.length > 0 ? (
            <>
              {paidLeaves?.length > 0 && (
                <>
                  <dd className="mt-1 text-sm text-gray-500 mb-1">- Paid leave: {paidLeaves.length} request(s)</dd>
                  <ul className="text-xs text-gray-400">
                    {paidLeaves.map((l, i) => (
                      <Link
                        className="ml-2 text-gray-400 flex align-middle hover:text-thaleria-orange-700"
                        to={isAdmin ? `/admin-panel/hr/leave-requests/${userId}/${l.id}` : `/leave-request/${l.id}`}
                      >
                        <ArrowSmRightIcon className="w-4 h-4" /> {i + 1}) from {dayjs(l.startDate).format('DD/MM/YYYY')} to{' '}
                        {dayjs(l.endDate).format('DD/MM/YYYY')}
                      </Link>
                    ))}
                  </ul>
                </>
              )}
              {unPaidLeaves?.length > 0 && (
                <>
                  <dd className="mt-1 text-sm text-gray-500 mb-1">- Unpaid leave: {unPaidLeaves.length} request(s)</dd>
                  <ul className="text-xs text-gray-400">
                    {unPaidLeaves.map((l, i) => (
                      <Link
                        className="ml-2 text-gray-400 flex align-middle hover:text-thaleria-orange-700"
                        to={isAdmin ? `/admin-panel/hr/leave-requests/${userId}/${l.id}` : `/leave-request/${l.id}`}
                      >
                        <ArrowSmRightIcon className="w-4 h-4" /> {i + 1}) from {dayjs(l.startDate).format('DD/MM/YYYY')} to{' '}
                        {dayjs(l.endDate).format('DD/MM/YYYY')}
                      </Link>
                    ))}
                  </ul>
                </>
              )}
              {medicalLeaves?.length > 0 && (
                <>
                  <dd className="mt-1 text-sm text-gray-500 mb-1">- Medical leave: {medicalLeaves.length} request(s)</dd>
                  <ul className="text-xs ">
                    {medicalLeaves.map((l, i) => (
                      <Link
                        className="ml-2 text-gray-400 flex align-middle hover:text-thaleria-orange-700"
                        to={isAdmin ? `/admin-panel/hr/leave-requests/${userId}/${l.id}` : `/leave-request/${l.id}`}
                      >
                        <ArrowSmRightIcon className="w-4 h-4" /> {i + 1}) from {dayjs(l.startDate).format('DD/MM/YYYY')} to{' '}
                        {dayjs(l.endDate).format('DD/MM/YYYY')}
                      </Link>
                    ))}
                  </ul>
                </>
              )}
              {trainingLeaves?.length > 0 && (
                <>
                  <dd className="mt-1 text-sm text-gray-500 mb-1">- Training leave: {trainingLeaves.length} request(s)</dd>
                  <ul className="text-xs text-gray-400">
                    {trainingLeaves.map((l, i) => (
                      <Link
                        className="ml-2 text-gray-400 flex align-middle hover:text-thaleria-orange-700"
                        to={isAdmin ? `/admin-panel/hr/leave-requests/${userId}/${l.id}` : `/leave-request/${l.id}`}
                      >
                        <ArrowSmRightIcon className="w-4 h-4" /> {i + 1}) from {dayjs(l.startDate).format('DD/MM/YYYY')} to{' '}
                        {dayjs(l.endDate).format('DD/MM/YYYY')}
                      </Link>
                    ))}
                  </ul>
                </>
              )}
            </>
          ) : (
            <span className="text-sm text-gray-500">No leave taken.</span>
          )}
        </dl>
      </div>
    </Card>
  );
}

export default TimesheetSummary;
