import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import InputSimple from '../../components/Inputs/InputSimple';
import TwoColumnForm from '../../components/Layouts/TwoColumnForm';
import SimpleAlert from '../../components/Modals/SimpleAlert';
import { useForm, Controller } from 'react-hook-form';
import { useYupValidationResolver } from '../../utils/hooks/useYupValidationResolver';
import * as Yup from 'yup';
import { VALIDATION_MSG } from '../../helpers/enum/errorValidationMsgs';
import useApi from '../../hooks/useApi';
import extractObjectDifferences from '../../helpers/extractObjectDifferences';
import { setNotification } from '../../store/app/actions';
import notifications from '../../helpers/enum/notifications';
import { useDispatch } from 'react-redux';
import clientTypes from '../../helpers/enum/clientTypes';
import ReactSelect from '../../components/Inputs/ReactSelect';

function AddClients({ clients, id, handleSave, countries }) {
  const history = useHistory();

  const {
    clients: { deleteClient },
  } = useApi();

  const [showAlert, setShowAlert] = useState(false);
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(VALIDATION_MSG).trim(),
  });

  const formOptions = { resolver: useYupValidationResolver(validationSchema) };

  const typeOptions = Object.keys(clientTypes.strings).map((key, index) => {
    return {
      label: clientTypes.strings[key],
      value: key,
    };
  });

  const countryOptions = countries?.length ? countries.map(c => ({ label: c.name, value: c.id })) : [];

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm(formOptions);

  useEffect(() => {
    if (id && id !== 'create') {
      clients.length &&
        clients.forEach(p => {
          if (p.id == id) {
            setValue('id', p.id);
            setValue('name', p.name);
            setValue('type', p.type);
            setValue('description', p.description);
            setValue('country_id', p.country_id);
            setValue('address', p.address);
            setValue('postal_code', p.postal_code);
            setValue('city', p.city);
            setValue('vat_number', p.vat_number);
          }
        });
    }
  }, [id, clients]);

  const formLabels = {
    name: 'Name*',
    type: 'Type*',
    description: 'Description',
    country_id: 'Country*',
    address: 'Address',
    postal_code: 'Postal code',
    city: 'City',
    vat_number: 'VAT number',
  };

  const labelArray = Object.keys(formLabels);

  const handleRemoveClick = () => {
    deleteClient(id)
      .then(d => {
        history.goBack();
        setShowAlert(false);
      })
      .catch(e => {
        setShowAlert(false);
      });
  };

  const onSubmit = data => {
    let dataToSend = data;
    if (id != 'create') {
      dataToSend = extractObjectDifferences(
        data,
        clients.find(client => client.id == id),
      );
    }
    if (!dataToSend) {
      dispatch(
        setNotification(notifications.WARNING_YELLOW, 'Unable to update Client', 'No changes to the client were detected.'),
      );
    } else {
      handleSave(dataToSend);
    }
  };

  return (
    <TwoColumnForm
      onClick={handleSubmit(onSubmit)}
      button={true}
      buttonText={'Save'}
      label="Client Info"
      description=""
      onClickRed={() => setShowAlert(true)}
      buttonRed={id && id !== 'create' ? true : false}
      buttonRedText={'Delete'}
    >
      <SimpleAlert
        errorTitle="Warning"
        errorMsg="Removing this will completely remove the client, and will delete it from the application. Are you sure you want to proceed?"
        onAcceptText="Proceed"
        onAcceptClick={handleRemoveClick}
        onDeclineText="Cancel"
        show={showAlert}
        hide={() => setShowAlert(false)}
      />
      <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-1">
        <InputSimple
          error={errors[labelArray[0]]?.message}
          register={register(labelArray[0])}
          label={formLabels[labelArray[0]]}
          dataLabel={labelArray[0]}
        />
        <Controller
          control={control}
          name={'type'}
          render={({ field: { onChange, value } }) => (
            <ReactSelect
              isSearchable
              options={typeOptions}
              selectedOptions={typeOptions.find(type => type.value == value)}
              label={formLabels[labelArray[1]]}
              onChange={e => onChange(e.value)}
            />
          )}
        />
        <InputSimple register={register(labelArray[2])} label={formLabels[labelArray[2]]} dataLabel={labelArray[2]} />
        <Controller
          control={control}
          name={'country_id'}
          render={({ field: { onChange, value } }) => (
            <ReactSelect
              isSearchable
              options={countryOptions}
              selectedOptions={countryOptions.find(c => c.value == value)}
              label={formLabels[labelArray[3]]}
              onChange={e => onChange(e.value)}
            />
          )}
        />
        <InputSimple register={register(labelArray[4])} label={formLabels[labelArray[4]]} dataLabel={labelArray[4]} />
        <InputSimple register={register(labelArray[5])} label={formLabels[labelArray[5]]} dataLabel={labelArray[5]} />
        <InputSimple register={register(labelArray[6])} label={formLabels[labelArray[6]]} dataLabel={labelArray[6]} />
        <InputSimple register={register(labelArray[7])} label={formLabels[labelArray[7]]} dataLabel={labelArray[7]} />
      </dl>
    </TwoColumnForm>
  );
}

export default AddClients;
