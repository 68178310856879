import axios from 'axios';

import {
  AUTHENTICATE_USER,
  LOGOUT_USER,
  AUTHENTICATE_USER_PENDING,
  AUTHENTICATE_USER_FAILED,
  UPDATE_PROFILE_PICTURE,
  DELETE_MY_PROFILE_PICTURE,
  EDIT_MYSELF,
} from './types';

export const authenticateUser =
  ({ email, password, captchaToken, script }) =>
  async dispatch => {
    try {
      const { data: userData } = await axios.post(`/auth/login`, { email, password, captchaToken });

      axios.defaults.headers.common['x-access-token'] = userData.token;

      localStorage.setItem('jwtToken', userData.token);

      dispatch({
        type: AUTHENTICATE_USER,
        payload: userData,
      });
    } catch (err) {
      throw err;
    }
  };

export const forgotPassword = async email => {
  return axios
    .post(`/auth/forgot-password`, { email: email })
    .then(res => {})
    .catch(err => {
      return err;
    });
};

export const verifyPasswordToken = async token => {
  return axios
    .get(`/auth/forgot-password/verify/${token}`)
    .then(res => {
      const { data } = res;
      return data;
    })
    .catch(err => {
      return err;
    });
};

export const getCurrentUser = () => dispatch => {
  dispatch({
    type: AUTHENTICATE_USER_PENDING,
  });

  return axios
    .get(`/users/me`)
    .then(res => {
      dispatch({
        type: AUTHENTICATE_USER,
        payload: {
          user: res.data,
          token: localStorage.getItem('jwtToken'),
        },
      });
    })
    .catch(err => {
      dispatch({
        type: AUTHENTICATE_USER_FAILED,
      });
    });
};

export const logOutUser = () => dispatch => {
  return axios.get('/auth/logout').then(r => {
    localStorage.removeItem('jwtToken');
    dispatch({
      type: LOGOUT_USER,
      payload: {},
    });
  });
};

export const refreshUserToken = () => dispatch => {
  return axios.get(`/auth/refresh`).then(({ data: userData }) => {
    axios.defaults.headers.common['x-access-token'] = userData.token;

    localStorage.setItem('jwtToken', userData.token);

    dispatch({
      type: AUTHENTICATE_USER,
      payload: userData,
    });
  });
};

export const deleteMyProfilePicture = () => dispatch => {
  return axios
    .delete('/users/my/user-picture')
    .then(res => {
      dispatch({
        type: DELETE_MY_PROFILE_PICTURE,
      });
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const updateProfilePicture = file => dispatch => {
  const formData = new FormData();
  formData.append('profile_picture', file);

  return axios
    .put('/users/my/profile-picture', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(res => {
      dispatch({
        type: UPDATE_PROFILE_PICTURE,
        payload: res.data,
      });
      return res.data;
    })
    .catch(err => {
      throw err;
    });
};

export const updateMyself = data => async dispatch => {
  return axios
    .put('/users/my/edit', data)
    .then(res => {
      const { data } = res;
      dispatch({
        type: EDIT_MYSELF,
        payload: data,
      });
      return data;
    })
    .catch(err => {
      throw err;
    });
};
