import React, { useEffect, useState } from 'react';
import InfoTooltip from '../../components/Tooltips/InfoTooltip';
import { Link } from 'react-router-dom';
import formatNumber from '../../utils/formatNumber';
import paidStatus from '../../helpers/enum/paidStatus';
import { lineItemTypesV2 } from '../../helpers/enum/lineItemTypes';

function RemainingDays({ timesheetEntries, staffOrders, isAdmin = false }) {
  const [fieldsToRender, setFieldsToRender] = useState([]);
  const [totalHours, setTotalHours] = useState(null);

  useEffect(() => {
    const _fieldsToRender = [];
    let _totalHours = 0;
    let groupedTimesheetEntries = [];
    if (timesheetEntries.length) {
      groupedTimesheetEntries = timesheetEntries.reduce((group, product) => {
        _totalHours = _totalHours + new Number(product.adjusted_hours ? product.adjusted_hours : product.hours);
        const { staffOrder } = product;
        group[staffOrder.order_ref] = group[staffOrder.order_ref] ?? [];
        group[staffOrder.order_ref].push(product.adjusted_hours ? product.adjusted_hours : product.hours);
        return group;
      }, {});
    }
    for (var so of staffOrders) {
      const correspondingRef = Object.keys(groupedTimesheetEntries).find(key => key === so.order_ref);
      let days = 0;
      let hours = 0;
      if (correspondingRef) {
        hours = groupedTimesheetEntries[correspondingRef].reduce(function (a, b) {
          return new Number(a) + new Number(b);
        }, 0);
        days = formatNumber(new Number(hours / 8), true);
      }
      let currentDaysRemaining;
      let currentDaysConsumed;
      let newDaysRemaining;
      let newDaysConsumed;
      let totalDaysRemaining;
      let newTotalDaysRemaining;
      let totalDaysConsumed;
      if (so?.days_ordered !== null) {
        totalDaysRemaining = formatNumber(new Number(so.days_ordered).valueOf() - new Number(so.days_consumed).valueOf(), true);
        newTotalDaysRemaining = formatNumber(
          new Number(so.days_ordered).valueOf() - new Number(so.days_consumed).valueOf() - hours / 8,
          true,
        );
        currentDaysRemaining = formatNumber(so?.daysRemainingUntilThisMonth, true);
        newDaysRemaining = formatNumber(so?.daysRemainingUntilThisMonth - days, true);
      } else if (so?.days_ordered === null) {
        totalDaysConsumed = formatNumber(new Number(so.days_consumed).valueOf(), true);
        currentDaysConsumed = formatNumber(new Number(so?.daysConsumedUntilThisMonth).valueOf(), true);
        newDaysConsumed = formatNumber(new Number(so?.daysConsumedUntilThisMonth) + new Number(days), true);
      }

      let invoiceStatus;

      if (!so.lineItem || so.lineItem.type === lineItemTypesV2.accountsReceivable.numbers.unapprovedProjectAllocations) {
        // No lineItem property or unapproved project allocations
        invoiceStatus = '-';
      } else if (so.lineItem.type === lineItemTypesV2.accountsReceivable.numbers.allocationsToBeInvoiced) {
        // Allocations to be invoiced or lineItem exists but no invoice property
        invoiceStatus = 'To be invoiced';
      } else if (!so.lineItem.invoice.sent) {
        // invoice exists but 'sent' is false
        invoiceStatus = `To be sent`;
      } else if (so.lineItem.invoice.sent && (so.lineItem.invoice.paid === 0 || so.lineItem.invoice.paid === null)) {
        // 'sent' is true, and 'paid' is either 0 or null
        invoiceStatus = `Sent, to be paid`;
      } else if (so.lineItem.invoice.paid === 1 || so.lineItem.invoice.paid === 2) {
        // 'paid' status is either 1 or 2
        invoiceStatus = `Paid by client`;
      } else {
        invoiceStatus = '-';
      }

      // Add Link for invoice_number if invoice exists
      if (so.lineItem && so.lineItem.invoice) {
        const invoiceLink = (
          <Link
            to={`/admin-panel/finance/accounts-receivable/${so.lineItem.invoice.invoice_number}`}
            className="text-blue-500 hover:text-blue-700"
          >
            #{so.lineItem.invoice.invoice_number}
          </Link>
        );
        invoiceStatus = (
          <>
            {invoiceStatus} ({invoiceLink})
          </>
        );
      }

      const field = (
        <tr key={so.order_ref}>
          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-6">
            {isAdmin ? (
              <div className="flex justify-start items-center">
                <Link
                  className="cursor-pointer hover:text-thaleria-orange-700 text-gray-900"
                  to={`/admin-panel/contracts/purchase-orders/${so?.id}`}
                >
                  {so.order_ref}
                </Link>
                <InfoTooltip iconClassName="h-4 w-4 text-thaleria-orange-700 ml-1" size="md">
                  <ul className="flex flex-col items-start">
                    <li>
                      <span className="font-semibold">End-client FWC - </span> {so?.frameworkContract?.contract_ref}
                    </li>
                    {so?.frameworkContract?.id !== so?.partnerContract?.id && (
                      <li>
                        <span className="font-semibold">Partner FWC - </span> {so?.partnerContract?.contract_ref}
                      </li>
                    )}
                    <li>
                      <span className="font-semibold">Specific contract - </span> {so?.specificContract?.contract_ref}
                    </li>
                    <li>
                      <span className="font-semibold">Sales order - </span> {so?.salesOrder?.order_ref}
                    </li>
                  </ul>
                </InfoTooltip>
              </div>
            ) : (
              so.order_ref
            )}
          </td>

          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            {so.days_ordered ? `${formatNumber(so.days_ordered, true)}` : 'FTE'}
          </td>
          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ">
            {(currentDaysRemaining || currentDaysConsumed) + (so.days_ordered ? ' days remaining' : ' days consumed')}
          </td>
          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            {days + ' days'} ({formatNumber(hours, true)}h)
          </td>
          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            {(newDaysRemaining || newDaysConsumed) + (so.days_ordered ? ' days remaining' : ' days consumed')}
          </td>
          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            {(totalDaysRemaining || totalDaysConsumed) + (so.days_ordered ? ' days remaining' : ' days consumed')}
          </td>
          {isAdmin ? (
            <td className="whitespace-nowrap py-4 pl-3 pr-4 text-left text-sm font-medium sm:pr-6 text-gray-500">
              {invoiceStatus}
            </td>
          ) : (
            ''
          )}
        </tr>
      );

      _fieldsToRender.push(field);
    }
    setFieldsToRender(_fieldsToRender);
    setTotalHours(_totalHours);
  }, [timesheetEntries, staffOrders]);

  const AllocationsSummaryTable = ({ fieldsToRender, totalHours }) => {
    const table = (
      <table class="w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
              Order reference
            </th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Days ordered
            </th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Month start
            </th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Monthly allocations
            </th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Month end
            </th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Current
            </th>
            {isAdmin ? (
              <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                AR Invoice
              </th>
            ) : (
              ''
            )}
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-200 bg-white">{fieldsToRender}</tbody>
        <tfoot>
          <tr className="bg-white">
            <td className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Total</td>
            <td className=""></td>
            <td className="text-center text-sm pt-1"></td>
            <td className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              {totalHours === null ? 0 : formatNumber(totalHours / 8, true)} days (
              {totalHours === null ? 0 : formatNumber(totalHours, true)}h)
            </td>
            <td className="text-center text-sm pt-1"></td>
            <td className="text-center text-sm pt-1"></td>
            <td className=" pt-1"></td>
          </tr>
        </tfoot>
      </table>
    );
    return table;
  };
  if (!fieldsToRender.length || !staffOrders?.length) return '';

  return (
    <div className={`w-full overflow-hidden overflow-x-auto shadow rounded-md sm:rounded-lg`}>
      {<AllocationsSummaryTable fieldsToRender={fieldsToRender} totalHours={totalHours} />}
    </div>
  );
}

export default RemainingDays;
