import React, { useEffect, useState } from 'react';
import SimpleEntry from '../../../components/DescriptionEntries/SimpleEntry';
import dayjs from 'dayjs';
import DividerSimple from '../../../components/Dividers/DividerSimple';
import { staffContractType } from '../../../helpers/enum/staffContractType';
import ReactSelect from '../../../components/Inputs/ReactSelect';
import { ExternalLinkIcon } from '@heroicons/react/outline';

function UserContracts({ userContracts }) {
  const [selectedContract, setSelectedContract] = useState(
    { ...userContracts[0], label: userContracts[0]?.contract_ref, value: userContracts[0]?.id } || null,
  );

  useEffect(() => {
    setSelectedContract({ ...userContracts[0], label: userContracts[0]?.contract_ref, value: userContracts[0]?.id });
  }, [userContracts]);

  let contractListBoxData = [];
  if (userContracts) userContracts.forEach(e => contractListBoxData.push({ ...e, label: e.contract_ref, value: e.id }));

  if (!userContracts.length)
    return <div className="text-sm text-gray-500 mt-6 px-8">There are currently no staff contracts for this user.</div>;

  const renderNameAndLink = (name, href) => {
    return (
      <div className="flex gap-x-2 mr-auto">
        {name}
        {name && (
          <ExternalLinkIcon
            className="h-5 w-5 text-gray-400 hover:text-thaleria-orange-700 cursor-pointer"
            onClick={() => window.open(href)}
          />
        )}
      </div>
    );
  };

  return (
    <div className="px-8">
      <div className="sticky top-0 w-full bg-white pt-4 ">
        <div className="w-56">
          <ReactSelect
            label={'Select contract'}
            labelClass="text-gray-500"
            selectedOptions={selectedContract}
            onChange={setSelectedContract}
            options={contractListBoxData}
          />
        </div>
        <DividerSimple />
      </div>
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 pb-6">
        <SimpleEntry
          label={'Contract reference'}
          data={renderNameAndLink(
            selectedContract?.contract_ref || '-',
            `/admin-panel/contracts/staff-contracts/${selectedContract?.id}`,
          )}
        />
        <SimpleEntry label={'Contract reference'} data={selectedContract?.contract_ref || '-'} />
        <SimpleEntry label={'Contract type'} data={selectedContract?.contractType?.contract_type || '-'} />
        <SimpleEntry label={'Country'} data={selectedContract?.country?.name || '-'} />
        <SimpleEntry label={'Calendar country'} data={selectedContract?.holiday_calendar?.name || '-'} />
        <SimpleEntry label={'Start date'} data={dayjs(selectedContract?.contract_start).format('DD/MM/YYYY') || '-'} />
        <SimpleEntry
          label={'End date'}
          data={(selectedContract?.contract_end && dayjs(selectedContract?.contract_end).format('DD/MM/YYYY')) || '-'}
        />
        <SimpleEntry label={'Position'} data={selectedContract?.position || '-'} />
        <SimpleEntry label={'IBAN'} data={selectedContract?.iban || '-'} />
        <SimpleEntry label={'BIC Swift'} data={selectedContract?.bic_swift || '-'} />
        {selectedContract?.contract_type_id != staffContractType.EMPLOYEE && (
          <>
            <SimpleEntry label={'VAT number'} data={selectedContract?.vat_nb || '-'} />
          </>
        )}
        {selectedContract?.contract_type_id == staffContractType.COMPANY && (
          <>
            <SimpleEntry label={'Company name'} data={selectedContract?.company_name || '-'} />
            <SimpleEntry label={'Company address'} data={selectedContract?.company_address || '-'} />
          </>
        )}
        <SimpleEntry label={'Created'} data={dayjs(selectedContract?.created_at).format('DD/MM/YYYY') || '-'} />
        <SimpleEntry label={'Last updated'} data={dayjs(selectedContract?.updated_at).format('DD/MM/YYYY') || '-'} />
      </dl>
    </div>
  );
}

export default UserContracts;
