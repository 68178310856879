import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useApi from '../../../hooks/useApi';
import { AccountType, ACCOUNT_TYPE } from '../../../helpers/enum/accounts';
import ReminderList from './ReminderList';
import ButtonAdd from '../../../components/Buttons/ButtonAdd';
import AddBodyReminder from './AddBodyReminder';
import AddViewerReminder from './AddViewerReminder';
import Modal from '../../../components/Modals/Modal';
import contractTypes from '../../../helpers/enum/contractTypeIDs';

const CompanyReminders = () => {
  const {
    reminders: { createReminder, getAllReminders },
  } = useApi();

  const [reminderData, setReminderData] = useState({ allReminders: [], totalPages: null });
  const [showReminderModal, setShowReminderModal] = useState(false);

  const [step, setStep] = useState(0);

  const [inputs, setInputs] = useState({
    title: '',
    textArea: '',
    countries: [],
    accountTypes: [AccountType.ADMIN, AccountType.STAFF, AccountType.USER],
    contractTypes: [contractTypes.employee, contractTypes.freelancer, contractTypes.company],
    bodyTextError: '',
    titleTextError: '',
  });

  const currentUser = useSelector(state => state && state.auth.currentUser);
  const isAdmin = ACCOUNT_TYPE.ROOT.id == currentUser.account_type_id || ACCOUNT_TYPE.ADMIN.id == currentUser.account_type_id;

  const fetchAllReminder = () => {
    getAllReminders().then(res => {
      setReminderData({
        allReminders: res,
      });
    });
  };

  const refreshReminder = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    fetchAllReminder(1);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    fetchAllReminder();
  }, []);

  const showAddReminderStepTwo = () => {
    if (inputs.textArea.length < 10)
      setInputs(preInputs => ({ ...preInputs, bodyTextError: 'Text must be Greater than 10 characters' }));
    if (inputs.title.length < 5)
      setInputs(preInput => ({ ...preInput, titleTextError: 'Text must be Greater than 5 characters' }));
    else setStep(2);
  };

  const completeReminder = () => {
    const data = {
      title: inputs.title,
      body: inputs.textArea,
      countries: inputs.countries,
      account_types: inputs.accountTypes,
      contract_types: inputs.contractTypes,
    };

    createReminder(data).then(() => {
      setShowReminderModal(false);
      setInputs(preInput => ({
        ...preInput,
        title: '',
        textArea: null,
        countries: [],
        accountTypes: [AccountType.ADMIN, AccountType.STAFF, AccountType.USER],
        contractTypes: [contractTypes.employee, contractTypes.freelancer, contractTypes.company],
      }));
      refreshReminder();
    });
  };

  const showAddReminder = () => {
    setShowReminderModal(true);
    setStep(1);
    setInputs(preInput => ({ ...preInput, bodyTextError: '', titleTextError: '' }));
  };

  const onTextAreaChangeHandler = event => {
    const text = event.target.value;
    if (text?.length > 10) setInputs(preInput => ({ ...preInput, textArea: text, bodyTextError: '' }));
    else setInputs(preInput => ({ ...preInput, textArea: text }));
  };

  const onInputChangeHandler = event => {
    const title = event.target.value;
    if (title?.length > 5) setInputs(preInput => ({ ...preInput, title, titleTextError: '' }));
    else setInputs(preInput => ({ ...preInput, title }));
  };

  const onCountriesInputChangeHandler = event => {
    let newCountries;
    if (!event) newCountries = [];
    else newCountries = event.map(country => country.value);

    setInputs(preInput => ({ ...preInput, countries: newCountries }));
  };

  const onContractTypesChangeHandler = event => {
    let acceptedContractTypes = inputs.contractTypes;
    if (event.target.id == 'employee') {
      event.target.checked
        ? acceptedContractTypes.push(contractTypes.employee)
        : (acceptedContractTypes = acceptedContractTypes.filter(id => id != contractTypes.employee));
    }
    if (event.target.id == 'freelancer') {
      event.target.checked
        ? acceptedContractTypes.push(contractTypes.freelancer)
        : (acceptedContractTypes = acceptedContractTypes.filter(id => id != contractTypes.freelancer));
    }
    if (event.target.id == 'company') {
      event.target.checked
        ? acceptedContractTypes.push(contractTypes.company)
        : (acceptedContractTypes = acceptedContractTypes.filter(id => id != contractTypes.company));
    }

    setInputs(preInput => ({ ...preInput, contractTypes: acceptedContractTypes }));
  };

  const onAccountTypesChangeHandler = event => {
    let acceptedAccountTypes = inputs.accountTypes;
    if (event.target.id == 'user') {
      event.target.checked
        ? acceptedAccountTypes.push(AccountType.USER)
        : (acceptedAccountTypes = acceptedAccountTypes.filter(id => id != AccountType.USER));
    }
    if (event.target.id == 'staff') {
      event.target.checked
        ? acceptedAccountTypes.push(AccountType.STAFF)
        : (acceptedAccountTypes = acceptedAccountTypes.filter(id => id != AccountType.STAFF));
    }

    setInputs(preInput => ({ ...preInput, accountTypes: acceptedAccountTypes }));
  };

  const closeReminderModal = () => {
    setShowReminderModal(false);
    setTimeout(function () {
      setStep(0);
    }, 500);
  };

  return (
    <div className=" flex flex-col lg:h-[500px] 2xl:h-[600px] bg-white py-1 rounded-lg shadow overflow-auto">
      <div className="w-full px-2 lg:px-6 bg-white flex flex-row justify-between items-center">
        <h2 className="font-semibold text-base lg:text-xl">Reminders</h2>
        {isAdmin && <ButtonAdd text="Add" onClick={showAddReminder} />}
      </div>
      <div className="h-full px-4 overflow-y-auto pb-4 pt-2">
        <ReminderList remindersData={reminderData.allReminders} refreshReminder={refreshReminder} />
        <Modal show={showReminderModal} hide={closeReminderModal}>
          {step === 1 && (
            <AddBodyReminder
              show={true}
              onSuccessClick={showAddReminderStepTwo}
              onCancelClick={closeReminderModal}
              onTextAreaChange={onTextAreaChangeHandler}
              onInputChange={onInputChangeHandler}
              bodyErrorMessage={inputs.bodyTextError}
              inputErrorMessage={inputs.titleTextError}
              title="Create"
            />
          )}
          {step === 2 && (
            <AddViewerReminder
              show={true}
              onSuccessClick={completeReminder}
              onCancelClick={closeReminderModal}
              onCountriesChange={onCountriesInputChangeHandler}
              onContractTypesChange={onContractTypesChangeHandler}
              onAccountTypesChange={onAccountTypesChangeHandler}
              acceptedAccountTypes={inputs.accountTypes}
              acceptedContractTypes={inputs.contractTypes}
              countriesDefaultId={inputs.countries}
              countriesPlaceholder="All countries"
              title="Create"
              successButtonName="Create"
              isAdmin={currentUser.account_type_id == AccountType.ADMIN}
            />
          )}
        </Modal>
      </div>
    </div>
  );
};

export default CompanyReminders;
