import { useEffect, useState } from 'react';
import * as React from 'react';
import { Link, useLocation } from 'react-router-dom'; // Assuming you're using React Router
import useApi from '../../hooks/useApi';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';

interface Props {
  userId: string;
  month: number;
  year: number;
}

const PreviousAndNext: React.FC<Props> = ({ userId, month, year }) => {
  const location = useLocation();
  const [previousId, setPreviousId] = useState();
  const [nextId, setNextId] = useState();
  const {
    timesheets: { getPreviousAndNext },
  } = useApi();

  useEffect(() => {
    if (!userId || !month || !year) return;
    getPreviousAndNext(userId, month, year).then(res => {
      setPreviousId(res.previous);
      setNextId(res.next);
    });
  }, [userId, month, year]);

  const currentPath = location.pathname;
  const lastIndex = currentPath.lastIndexOf('/');
  const timesheetUrl = currentPath.slice(0, lastIndex) + '/';

  return (
    <div className="flex space-x-1 items-center">
      <Link
        to={`${previousId ? timesheetUrl + previousId : '#'}`}
        className={`w-5 h-5 ${previousId ? 'cursor-pointer' : 'cursor-default'}`}
      >
        <ChevronLeftIcon
          className={`"w-5 h-5 ${
            previousId ? 'text-thaleria-blue-500 hover:text-thaleria-blue-300' : 'text-gray-300'
          } transition"`}
        />
      </Link>
      <Link to={`${nextId ? timesheetUrl + nextId : '#'}`} className={`w-5 h-5 ${nextId ? 'cursor-pointer' : 'cursor-default'}`}>
        <ChevronRightIcon
          className={`"w-5 h-5 ${nextId ? 'text-thaleria-blue-500 hover:text-thaleria-blue-300' : 'text-gray-300'} transition"`}
        />
      </Link>
    </div>
  );
};

export default PreviousAndNext;
