export const TimeSheetStatus = {
  PENDING: 0,
  SUBMITTED: 1,
  CHANGESREQUESTED: 2,
  PREAPPROVE: 3,
  APPROVED: 4,
};

export const TimeSheetStatusLabels = {
  [TimeSheetStatus.PENDING]: 'Pending',
  [TimeSheetStatus.SUBMITTED]: 'Submitted',
  [TimeSheetStatus.CHANGESREQUESTED]: 'Changes requested',
  [TimeSheetStatus.PREAPPROVE]: 'Pre-approved',
  [TimeSheetStatus.APPROVED]: 'Approved',
};

export const TimeSheetStatusList = Object.entries(TimeSheetStatusLabels).map(([key, val]) => ({
  value: Number(key),
  label: val,
}));
