import React from 'react';
import Notification from '../../../containers/App/Notification.js';
import ResetPasswordSplitScreen from '../../../containers/ResetPassword/ResetPasswordSplitScreen.js';
import logo from '../../../resources/logos/Thaleria-Horizontal-Logo blue.png';
import PageWrapper from '../../../containers/App/PageWrapper';

function ResetPassword() {
  return (
    <PageWrapper override tabTitle="Reset your password">
      <div className="relative z-10 flex flex-col w-screen h-screen bg-gray-100 overflow-hidden">
        <ResetPasswordSplitScreen logo={logo} />
      </div>
    </PageWrapper>
  );
}

export default ResetPassword;
