import React from 'react';
import { ChevronRightIcon, CalendarIcon } from '@heroicons/react/solid';
import { ClockIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

function LeaveRequestItem({ leaveRequest }) {
  const isPaidLeave = leaveRequest.type == 'Paid leave';
  return (
    <li className="" key={leaveRequest.id}>
      <Link to={`/leave-request/${leaveRequest.id}`} className="group block rounded-lg py-2 px-2 sm:py-3 sm:px-0">
        <div className="hover:bg-gray-200 flex items-center py-3 px-2 sm:py-3 sm:px-0 rounded-lg">
          <div className="min-w-0 flex-1 flex items-center">
            <ClockIcon className="flex-shrink-0 h-12 w-12 text-gray-400 rounded-full group-hover:opacity-75 group-hover:text-gray-700" />
            <div className="px-4 grid grid-cols-1 md:grid-cols-3">
              <div className="col-span-3 md:col-span-2">
                <p className="text-sm font-medium text-thaleria-blue-600 truncate">{leaveRequest.type}</p>
                <p className="text-sm font-medium text-gray-400 truncate">
                  From <time dateTime={leaveRequest.start}>{dayjs(leaveRequest.start).format('DD/MM/YYYY')}</time> to{' '}
                  <time dateTime={leaveRequest.end}>{dayjs(leaveRequest.end).format('DD/MM/YYYY')}</time>
                </p>
              </div>
              <div className="hidden md:block text-sm text-gray-900 ml-4 w-44">
                {isPaidLeave && (
                  <>
                    <CalendarIcon className="flex-shrink-0 mr-1.5 h-4 w-4 mb-1 text-gray-400" aria-hidden="true" />
                    <p className="text-sm font-medium text-gray-400 truncate">
                      {leaveRequest.deductedLeaveDays} leave days deducted
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
          <div>
            <ChevronRightIcon className="h-5 w-5 text-gray-400 group-hover:text-gray-700" aria-hidden="true" />
          </div>
        </div>
      </Link>
    </li>
  );
}

export default LeaveRequestItem;
