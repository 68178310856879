import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import Table from '../../../../containers/ServerSideTable';
import { TimeSheetStatus, TimeSheetStatusLabels } from '../../../../helpers/enum/timeSheet';
import HrManager from '../HrManager';
import SelectColumnFilter from '../../../../components/TableItems/SelectColumnFilter';
import AddTimesheetModal from '../../../../containers/Timesheets/AddTimesheetModal';
import { ExternalLinkIcon, PencilIcon, TrashIcon } from '@heroicons/react/outline';
import { showSuccessNotification } from '../../../../store/app/actions';
import useApi from '../../../../hooks/useApi';
import SimpleAlert from '../../../../components/Modals/SimpleAlert';
import MonthRangeColumnFilter from '../../../../components/TableItems/MonthRangeColumnFilter';
import AdminSidebar from '../../AdminSidebar';
import { endOfMonth, isBetween } from 'date-fns';
import contractTypesToString from '../../../../helpers/enum/contractTypesToString';

var months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

function MyTimesheet(props) {
  const [timesheets, setTimesheets] = useState([]);
  const [totalItems, setTotalItems] = useState();
  const [totalPages, setTotalPages] = useState();
  const [showAddtimesheet, setShowAddTimesheet] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    users: undefined,
    period: {
      minDate: undefined,
      maxDate: undefined,
    },
  });
  const [showBulkDownloadAlert, setShowBulkDownloadAlert] = useState(false);
  const [bulkDownloading, setBulkDownloading] = useState(false);

  const {
    timesheets: { deleteTimesheet, getAllTimeSheetsPaginated, getFilterOptions, bulkDownloadTS },
  } = useApi();

  const [showDeleteTimesheet, setShowDeleteTimesheet] = useState({ show: false, id: 0 });

  const dispatch = useDispatch();
  const history = useHistory();
  const {
    location: { state },
  } = useHistory();

  const fetchData = async (
    page = 0,
    filters = [],
    sortByArray = [],
    globalFilter,
    downloadToCsv,
    source,
    bulkDownload,
    fileNameStructure,
  ) => {
    const id = filters.find(filter => filter.id === 'id')?.value;
    const startDate = filters.find(filter => filter.id === 'period')?.value[0];
    const endDate = filters.find(filter => filter.id === 'period')?.value[1];
    const users = filters.find(filter => filter.id === 'requester')?.value;
    const status = filters.find(filter => filter.id === 'status')?.value;
    const sortBy = sortByArray.length ? `${sortByArray[0].id.toString()},${sortByArray[0].desc.toString()}` : undefined;

    if (downloadToCsv) {
      let csvData = [];
      await getAllTimeSheetsPaginated(page + 1, id, startDate, endDate, users, status, sortBy, globalFilter, downloadToCsv).then(
        res => {
          res.forEach(timesheet => {
            let contractTypes = [];

            timesheet.user.StaffContracts.forEach(sc => {
              const timesheetStart = new Date(`${timesheet.year}-${timesheet.month}-01`);
              const timesheetEnd = endOfMonth(timesheetStart);
              if (
                new Date(sc.contract_start) <= timesheetEnd &&
                (sc.contract_end === null || new Date(sc.contract_end) >= timesheetStart)
              ) {
                contractTypes.push(sc.contract_type_id);
              }
            });

            let periodDate = new Date(`${timesheet.month}/01/${timesheet.year?.toString()}`);
            csvData.push({
              id: timesheet.id,
              period: `${periodDate.getFullYear()} - ${months[periodDate.getMonth()]}`,
              status: TimeSheetStatusLabels[timesheet.status],
              requester: timesheet?.user?.first_names + ' ' + timesheet?.user?.last_names,
              user_id: timesheet?.user_id,
              contractTypes: [...new Set(contractTypes)].map(ct => contractTypesToString[ct]).join(', '),
            });
          });
        },
      );
      return csvData;
    }

    if (bulkDownload) {
      setBulkDownloading(true);
      await getAllTimeSheetsPaginated(
        page + 1,
        id,
        startDate,
        endDate,
        users,
        status,
        sortBy,
        globalFilter,
        null,
        source,
        bulkDownload,
      ).then(async r => {
        await bulkDownloadTS(
          r.map(el => el.id),
          fileNameStructure,
          source,
        )
          .then(res => {
            return setBulkDownloading(false);
          })
          .catch(err => {
            throw err;
          });
      });
    }

    if (!bulkDownload && !downloadToCsv) {
      getAllTimeSheetsPaginated(
        page + 1,
        id,
        startDate,
        endDate,
        users,
        status,
        sortBy,
        globalFilter,
        null,
        source,
        bulkDownload,
      ).then(d => {
        setTimesheets(d.rows);
        setTotalPages(d.totalPages);
        setTotalItems(d.totalItems);
      });
    }
  };

  useEffect(() => {
    //fetch filter options for users w/ timesheets + max and min period
    getFilterOptions().then(d => setFilterOptions(d));
  }, []);

  const memoizedTableData = useMemo(() => {
    let tableData = [];
    if (timesheets && timesheets?.length) {
      timesheets.forEach(timesheet => {
        let contractTypes = [];

        timesheet.user.StaffContracts.forEach(sc => {
          const timesheetStart = new Date(`${timesheet.year}-${timesheet.month}-01`);
          const timesheetEnd = endOfMonth(timesheetStart);
          if (
            new Date(sc.contract_start) <= timesheetEnd &&
            (sc.contract_end === null || new Date(sc.contract_end) >= timesheetStart)
          ) {
            contractTypes.push(sc.contract_type_id);
          }
        });
        tableData.push({
          id: timesheet.id,
          period: new Date(`${timesheet.month}/01/${timesheet.year?.toString()}`),
          status: TimeSheetStatusLabels[timesheet.status],
          requester: timesheet?.user?.first_names + ' ' + timesheet?.user?.last_names,
          user_id: timesheet.user_id,
          contractTypes: [...new Set(contractTypes)].map(ct => contractTypesToString[ct]).join(', '),
        });
      });
    }
    return tableData;
  }, [timesheets, filterOptions]);

  let columns = useMemo(() => [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: 'Period',
      accessor: 'period',
      Filter: MonthRangeColumnFilter,
      filter: 'month',
      Cell: ({ value }) => {
        if (value) return `${value.getFullYear()} - ${months[value.getMonth()]}`;
      },
      filterOptions: filterOptions?.period,
    },
    {
      Header: 'Requester',
      accessor: 'requester',
      Filter: SelectColumnFilter,
      filter: 'includes',
      filterOptions: filterOptions?.users?.length
        ? filterOptions.users.map(o => ({
            label: o.first_names + ' ' + o.last_names,
            value: o.id,
          }))
        : null,
    },
    {
      Header: 'Status',
      accessor: 'status',
      Filter: SelectColumnFilter,
      filter: 'includes',
      filterOptions: Object.values(TimeSheetStatus).map(el => ({
        label: TimeSheetStatusLabels[el],
        value: el,
      })),
    },
    {
      Header: 'Contract type(s)',
      accessor: 'contractTypes',
      disableSortBy: true,
      disableFilters: true,
    },
  ]);

  const pages = [
    { name: 'HR Manager', href: '/admin-panel/hr/user-directory', current: false },
    { name: 'Manage Timesheets', href: '/admin-panel/hr/timesheets', current: false },
  ];

  let renderRowMenu = row => {
    return [
      [
        {
          text: 'View/edit details',
          onClick: () => history.push(`/admin-panel/hr/timesheets/${row.original.id}`),
          show: true,
          icon: PencilIcon,
        },
        {
          text: 'View/edit details in new tab',
          onClick: () => window.open(`/admin-panel/hr/timesheets/${row.original.id}`),
          show: true,
          icon: ExternalLinkIcon,
        },
      ],
      [
        {
          text: 'Delete timesheet',
          onClick: () => setShowDeleteTimesheet({ show: true, id: row.original.id }),
          show: true,
          icon: TrashIcon,
        },
      ],
    ];
  };

  const rowOnClick = row => {
    history.push(`/admin-panel/hr/timesheets/${row.values.id}`);
  };

  const handleDeleteTimesheet = () => {
    deleteTimesheet(showDeleteTimesheet?.id)
      .then(res => {
        //remove timesheet from table
        setTimesheets(prev => prev.filter(ts => ts.id !== showDeleteTimesheet?.id));
        dispatch(showSuccessNotification('Timesheet successfully deleted'));
        setShowDeleteTimesheet({ show: false, id: 0 });
      })
      .catch(err => {
        handleCancelDeleteTimesheet();
      });
  };

  const handleCancelDeleteTimesheet = () => {
    setShowDeleteTimesheet({ show: false, id: 0 });
  };

  return (
    <AdminSidebar noPadding pages={pages}>
      <SimpleAlert
        errorTitle={'Delete timesheet'}
        errorMsg={
          "You can only delete timesheets that are in 'Pending' status and don't have any allocations assigned to it. Are you sure you wish to proceed?"
        }
        onAcceptClick={handleDeleteTimesheet}
        onAcceptText="Delete"
        onDeclineText={'Cancel'}
        show={showDeleteTimesheet?.show}
        hide={handleCancelDeleteTimesheet}
      />
      <AddTimesheetModal
        timesheets={timesheets}
        fetchTimesheets={fetchData}
        show={showAddtimesheet}
        hide={() => setShowAddTimesheet(false)}
      />
      <Table
        addButton={{ onClick: () => setShowAddTimesheet(true) }}
        columns={columns}
        data={memoizedTableData}
        customContextMenu
        contextMenuOptions={renderRowMenu}
        rowOnClick={rowOnClick}
        initialFilters={state?.filters}
        pageCount={totalPages}
        fetchData={fetchData}
        totalItems={totalItems}
        tableName="timesheets"
        bulkDownloadButton={true}
        bulkDownloading={bulkDownloading}
        setBulkDownloading={setBulkDownloading}
        showBulkDownloadAlert={showBulkDownloadAlert}
        setShowBulkDownloadAlert={setShowBulkDownloadAlert}
      />
    </AdminSidebar>
  );
}

export default MyTimesheet;
